/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root{
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00adbb;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --tlg-green: #a4ca61;
  --tlg-teal: #00adbb;
  --tlg-purple: #685BC7;
  --tlg-purple-light: #af7cb6;
  --tlg-orange: #E87722;
  --tlg-orange-light: #f6c7a4;
  --tlg-orange-500: #ff7f41;
  --tlg-green-dark: #76b84e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 640px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,*::before,*::after{
  box-sizing:border-box
}

html{
  font-family:sans-serif;
  line-height:1.15;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{
  display:block
}

body{
  margin:0;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#212529;
  text-align:left;
  background-color:#fff
}

[tabindex="-1"]:focus{
  outline:0 !important
}

hr{
  box-sizing:content-box;
  height:0;
  overflow:visible
}

h1,h2,h3,h4,h5,h6{
  margin-top:0;
  margin-bottom:.5rem
}

p{
  margin-top:0;
  margin-bottom:1rem
}

abbr[title],abbr[data-original-title]{
  text-decoration:underline;
  -webkit-text-decoration:underline dotted;
          text-decoration:underline dotted;
  cursor:help;
  border-bottom:0;
  -webkit-text-decoration-skip-ink:none;
          text-decoration-skip-ink:none
}

address{
  margin-bottom:1rem;
  font-style:normal;
  line-height:inherit
}

ol,ul,dl{
  margin-top:0;
  margin-bottom:1rem
}

ol ol,ul ul,ol ul,ul ol{
  margin-bottom:0
}

dt{
  font-weight:700
}

dd{
  margin-bottom:.5rem;
  margin-left:0
}

blockquote{
  margin:0 0 1rem
}

b,strong{
  font-weight:bolder
}

small{
  font-size:80%
}

sub,sup{
  position:relative;
  font-size:75%;
  line-height:0;
  vertical-align:baseline
}

sub{
  bottom:-0.25em
}

sup{
  top:-0.5em
}

a{
  color:#00adbb;
  text-decoration:none;
  background-color:transparent
}

a:hover{
  color:#00666f;
  text-decoration:underline
}

a:not([href]):not([tabindex]){
  color:inherit;
  text-decoration:none
}

a:not([href]):not([tabindex]):hover,a:not([href]):not([tabindex]):focus{
  color:inherit;
  text-decoration:none
}

a:not([href]):not([tabindex]):focus{
  outline:0
}

pre,code,kbd,samp{
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size:1em
}

pre{
  margin-top:0;
  margin-bottom:1rem;
  overflow:auto
}

figure{
  margin:0 0 1rem
}

img{
  vertical-align:middle;
  border-style:none
}

svg{
  overflow:hidden;
  vertical-align:middle
}

table{
  border-collapse:collapse
}

caption{
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#6c757d;
  text-align:left;
  caption-side:bottom
}

th{
  text-align:inherit
}

label{
  display:inline-block;
  margin-bottom:.5rem
}

button{
  border-radius:0
}

button:focus{
  outline:1px dotted;
  outline:5px auto -webkit-focus-ring-color
}

input,button,select,optgroup,textarea{
  margin:0;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit
}

button,input{
  overflow:visible
}

button,select{
  text-transform:none
}

select{
  word-wrap:normal
}

button,[type=button],[type=reset],[type=submit]{
  -webkit-appearance:button
}

button:not(:disabled),[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled){
  cursor:pointer
}

button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{
  padding:0;
  border-style:none
}

input[type=radio],input[type=checkbox]{
  box-sizing:border-box;
  padding:0
}

input[type=date],input[type=time],input[type=datetime-local],input[type=month]{
  -webkit-appearance:listbox
}

textarea{
  overflow:auto;
  resize:vertical
}

fieldset{
  min-width:0;
  padding:0;
  margin:0;
  border:0
}

legend{
  display:block;
  width:100%;
  max-width:100%;
  padding:0;
  margin-bottom:.5rem;
  font-size:1.5rem;
  line-height:inherit;
  color:inherit;
  white-space:normal
}

progress{
  vertical-align:baseline
}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{
  height:auto
}

[type=search]{
  outline-offset:-2px;
  -webkit-appearance:none
}

[type=search]::-webkit-search-decoration{
  -webkit-appearance:none
}

::-webkit-file-upload-button{
  font:inherit;
  -webkit-appearance:button
}

output{
  display:inline-block
}

summary{
  display:list-item;
  cursor:pointer
}

template{
  display:none
}

[hidden]{
  display:none !important
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  margin-bottom:.5rem;
  font-weight:500;
  line-height:1.2
}

h1,.h1{
  font-size:2.5rem
}

h2,.h2{
  font-size:2rem
}

h3,.h3{
  font-size:1.75rem
}

h4,.h4{
  font-size:1.5rem
}

h5,.h5{
  font-size:1.25rem
}

h6,.h6{
  font-size:1rem
}

.lead{
  font-size:1.25rem;
  font-weight:300
}

.display-1{
  font-size:6rem;
  font-weight:300;
  line-height:1.2
}

.display-2{
  font-size:5.5rem;
  font-weight:300;
  line-height:1.2
}

.display-3{
  font-size:4.5rem;
  font-weight:300;
  line-height:1.2
}

.display-4{
  font-size:3.5rem;
  font-weight:300;
  line-height:1.2
}

hr{
  margin-top:1rem;
  margin-bottom:1rem;
  border:0;
  border-top:1px solid rgba(0,0,0,.1)
}

small,.small{
  font-size:80%;
  font-weight:400
}

mark,.mark{
  padding:.2em;
  background-color:#fcf8e3
}

.list-unstyled{
  padding-left:0;
  list-style:none
}

.list-inline{
  padding-left:0;
  list-style:none
}

.list-inline-item{
  display:inline-block
}

.list-inline-item:not(:last-child){
  margin-right:.5rem
}

.initialism{
  font-size:90%;
  text-transform:uppercase
}

.blockquote{
  margin-bottom:1rem;
  font-size:1.25rem
}

.blockquote-footer{
  display:block;
  font-size:80%;
  color:#6c757d
}

.blockquote-footer::before{
  content:"— "
}

.img-fluid{
  max-width:100%;
  height:auto
}

.img-thumbnail{
  padding:.25rem;
  background-color:#fff;
  border:1px solid #dee2e6;
  border-radius:.25rem;
  max-width:100%;
  height:auto
}

.figure{
  display:inline-block
}

.figure-img{
  margin-bottom:.5rem;
  line-height:1
}

.figure-caption{
  font-size:90%;
  color:#6c757d
}

code{
  font-size:87.5%;
  color:#e83e8c;
  word-break:break-word
}

a>code{
  color:inherit
}

kbd{
  padding:.2rem .4rem;
  font-size:87.5%;
  color:#fff;
  background-color:#212529;
  border-radius:.2rem
}

kbd kbd{
  padding:0;
  font-size:100%;
  font-weight:700
}

pre{
  display:block;
  font-size:87.5%;
  color:#212529
}

pre code{
  font-size:inherit;
  color:inherit;
  word-break:normal
}

.pre-scrollable{
  max-height:340px;
  overflow-y:scroll
}

.container{
  width:100%;
  padding-right:15px;
  padding-left:15px;
  margin-right:auto;
  margin-left:auto
}

@media(min-width: 640px){
  .container{
    max-width:540px
  }
}

@media(min-width: 768px){
  .container{
    max-width:720px
  }
}

@media(min-width: 1024px){
  .container{
    max-width:960px
  }
}

@media(min-width: 1280px){
  .container{
    max-width:1140px
  }
}

.container-fluid{
  width:100%;
  padding-right:15px;
  padding-left:15px;
  margin-right:auto;
  margin-left:auto
}

.row{
  display:flex;
  flex-wrap:wrap;
  margin-right:-15px;
  margin-left:-15px
}

.no-gutters{
  margin-right:0;
  margin-left:0
}

.no-gutters>.col,.no-gutters>[class*=col-]{
  padding-right:0;
  padding-left:0
}

.col-xl,.col-xl-auto,.col-xl-12,.col-xl-11,.col-xl-10,.col-xl-9,.col-xl-8,.col-xl-7,.col-xl-6,.col-xl-5,.col-xl-4,.col-xl-3,.col-xl-2,.col-xl-1,.col-lg,.col-lg-auto,.col-lg-12,.col-lg-11,.col-lg-10,.col-lg-9,.col-lg-8,.col-lg-7,.col-lg-6,.col-lg-5,.col-lg-4,.col-lg-3,.col-lg-2,.col-lg-1,.col-md,.col-md-auto,.col-md-12,.col-md-11,.col-md-10,.col-md-9,.col-md-8,.col-md-7,.col-md-6,.col-md-5,.col-md-4,.col-md-3,.col-md-2,.col-md-1,.col-sm,.col-sm-auto,.col-sm-12,.col-sm-11,.col-sm-10,.col-sm-9,.col-sm-8,.col-sm-7,.col-sm-6,.col-sm-5,.col-sm-4,.col-sm-3,.col-sm-2,.col-sm-1,.col,.col-auto,.col-12,.col-11,.col-10,.col-9,.col-8,.col-7,.col-6,.col-5,.col-4,.col-3,.col-2,.col-1{
  position:relative;
  width:100%;
  padding-right:15px;
  padding-left:15px
}

.col{
  flex-basis:0;
  flex-grow:1;
  max-width:100%
}

.col-auto{
  flex:0 0 auto;
  width:auto;
  max-width:100%
}

.col-1{
  flex:0 0 8.3333333333%;
  max-width:8.3333333333%
}

.col-2{
  flex:0 0 16.6666666667%;
  max-width:16.6666666667%
}

.col-3{
  flex:0 0 25%;
  max-width:25%
}

.col-4{
  flex:0 0 33.3333333333%;
  max-width:33.3333333333%
}

.col-5{
  flex:0 0 41.6666666667%;
  max-width:41.6666666667%
}

.col-6{
  flex:0 0 50%;
  max-width:50%
}

.col-7{
  flex:0 0 58.3333333333%;
  max-width:58.3333333333%
}

.col-8{
  flex:0 0 66.6666666667%;
  max-width:66.6666666667%
}

.col-9{
  flex:0 0 75%;
  max-width:75%
}

.col-10{
  flex:0 0 83.3333333333%;
  max-width:83.3333333333%
}

.col-11{
  flex:0 0 91.6666666667%;
  max-width:91.6666666667%
}

.col-12{
  flex:0 0 100%;
  max-width:100%
}

.order-first{
  order:-1
}

.order-last{
  order:13
}

.order-0{
  order:0
}

.order-1{
  order:1
}

.order-2{
  order:2
}

.order-3{
  order:3
}

.order-4{
  order:4
}

.order-5{
  order:5
}

.order-6{
  order:6
}

.order-7{
  order:7
}

.order-8{
  order:8
}

.order-9{
  order:9
}

.order-10{
  order:10
}

.order-11{
  order:11
}

.order-12{
  order:12
}

.offset-1{
  margin-left:8.3333333333%
}

.offset-2{
  margin-left:16.6666666667%
}

.offset-3{
  margin-left:25%
}

.offset-4{
  margin-left:33.3333333333%
}

.offset-5{
  margin-left:41.6666666667%
}

.offset-6{
  margin-left:50%
}

.offset-7{
  margin-left:58.3333333333%
}

.offset-8{
  margin-left:66.6666666667%
}

.offset-9{
  margin-left:75%
}

.offset-10{
  margin-left:83.3333333333%
}

.offset-11{
  margin-left:91.6666666667%
}

@media(min-width: 640px){
  .col-sm{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .col-sm-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-sm-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-sm-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-sm-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-sm-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-sm-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-sm-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-sm-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-sm-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-sm-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-sm-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-sm-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-sm-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-sm-first{
    order:-1
  }

  .order-sm-last{
    order:13
  }

  .order-sm-0{
    order:0
  }

  .order-sm-1{
    order:1
  }

  .order-sm-2{
    order:2
  }

  .order-sm-3{
    order:3
  }

  .order-sm-4{
    order:4
  }

  .order-sm-5{
    order:5
  }

  .order-sm-6{
    order:6
  }

  .order-sm-7{
    order:7
  }

  .order-sm-8{
    order:8
  }

  .order-sm-9{
    order:9
  }

  .order-sm-10{
    order:10
  }

  .order-sm-11{
    order:11
  }

  .order-sm-12{
    order:12
  }

  .offset-sm-0{
    margin-left:0
  }

  .offset-sm-1{
    margin-left:8.3333333333%
  }

  .offset-sm-2{
    margin-left:16.6666666667%
  }

  .offset-sm-3{
    margin-left:25%
  }

  .offset-sm-4{
    margin-left:33.3333333333%
  }

  .offset-sm-5{
    margin-left:41.6666666667%
  }

  .offset-sm-6{
    margin-left:50%
  }

  .offset-sm-7{
    margin-left:58.3333333333%
  }

  .offset-sm-8{
    margin-left:66.6666666667%
  }

  .offset-sm-9{
    margin-left:75%
  }

  .offset-sm-10{
    margin-left:83.3333333333%
  }

  .offset-sm-11{
    margin-left:91.6666666667%
  }
}

@media(min-width: 768px){
  .col-md{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .col-md-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-md-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-md-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-md-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-md-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-md-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-md-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-md-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-md-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-md-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-md-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-md-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-md-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-md-first{
    order:-1
  }

  .order-md-last{
    order:13
  }

  .order-md-0{
    order:0
  }

  .order-md-1{
    order:1
  }

  .order-md-2{
    order:2
  }

  .order-md-3{
    order:3
  }

  .order-md-4{
    order:4
  }

  .order-md-5{
    order:5
  }

  .order-md-6{
    order:6
  }

  .order-md-7{
    order:7
  }

  .order-md-8{
    order:8
  }

  .order-md-9{
    order:9
  }

  .order-md-10{
    order:10
  }

  .order-md-11{
    order:11
  }

  .order-md-12{
    order:12
  }

  .offset-md-0{
    margin-left:0
  }

  .offset-md-1{
    margin-left:8.3333333333%
  }

  .offset-md-2{
    margin-left:16.6666666667%
  }

  .offset-md-3{
    margin-left:25%
  }

  .offset-md-4{
    margin-left:33.3333333333%
  }

  .offset-md-5{
    margin-left:41.6666666667%
  }

  .offset-md-6{
    margin-left:50%
  }

  .offset-md-7{
    margin-left:58.3333333333%
  }

  .offset-md-8{
    margin-left:66.6666666667%
  }

  .offset-md-9{
    margin-left:75%
  }

  .offset-md-10{
    margin-left:83.3333333333%
  }

  .offset-md-11{
    margin-left:91.6666666667%
  }
}

@media(min-width: 1024px){
  .col-lg{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .col-lg-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-lg-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-lg-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-lg-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-lg-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-lg-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-lg-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-lg-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-lg-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-lg-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-lg-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-lg-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-lg-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-lg-first{
    order:-1
  }

  .order-lg-last{
    order:13
  }

  .order-lg-0{
    order:0
  }

  .order-lg-1{
    order:1
  }

  .order-lg-2{
    order:2
  }

  .order-lg-3{
    order:3
  }

  .order-lg-4{
    order:4
  }

  .order-lg-5{
    order:5
  }

  .order-lg-6{
    order:6
  }

  .order-lg-7{
    order:7
  }

  .order-lg-8{
    order:8
  }

  .order-lg-9{
    order:9
  }

  .order-lg-10{
    order:10
  }

  .order-lg-11{
    order:11
  }

  .order-lg-12{
    order:12
  }

  .offset-lg-0{
    margin-left:0
  }

  .offset-lg-1{
    margin-left:8.3333333333%
  }

  .offset-lg-2{
    margin-left:16.6666666667%
  }

  .offset-lg-3{
    margin-left:25%
  }

  .offset-lg-4{
    margin-left:33.3333333333%
  }

  .offset-lg-5{
    margin-left:41.6666666667%
  }

  .offset-lg-6{
    margin-left:50%
  }

  .offset-lg-7{
    margin-left:58.3333333333%
  }

  .offset-lg-8{
    margin-left:66.6666666667%
  }

  .offset-lg-9{
    margin-left:75%
  }

  .offset-lg-10{
    margin-left:83.3333333333%
  }

  .offset-lg-11{
    margin-left:91.6666666667%
  }
}

@media(min-width: 1280px){
  .col-xl{
    flex-basis:0;
    flex-grow:1;
    max-width:100%
  }

  .col-xl-auto{
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-xl-1{
    flex:0 0 8.3333333333%;
    max-width:8.3333333333%
  }

  .col-xl-2{
    flex:0 0 16.6666666667%;
    max-width:16.6666666667%
  }

  .col-xl-3{
    flex:0 0 25%;
    max-width:25%
  }

  .col-xl-4{
    flex:0 0 33.3333333333%;
    max-width:33.3333333333%
  }

  .col-xl-5{
    flex:0 0 41.6666666667%;
    max-width:41.6666666667%
  }

  .col-xl-6{
    flex:0 0 50%;
    max-width:50%
  }

  .col-xl-7{
    flex:0 0 58.3333333333%;
    max-width:58.3333333333%
  }

  .col-xl-8{
    flex:0 0 66.6666666667%;
    max-width:66.6666666667%
  }

  .col-xl-9{
    flex:0 0 75%;
    max-width:75%
  }

  .col-xl-10{
    flex:0 0 83.3333333333%;
    max-width:83.3333333333%
  }

  .col-xl-11{
    flex:0 0 91.6666666667%;
    max-width:91.6666666667%
  }

  .col-xl-12{
    flex:0 0 100%;
    max-width:100%
  }

  .order-xl-first{
    order:-1
  }

  .order-xl-last{
    order:13
  }

  .order-xl-0{
    order:0
  }

  .order-xl-1{
    order:1
  }

  .order-xl-2{
    order:2
  }

  .order-xl-3{
    order:3
  }

  .order-xl-4{
    order:4
  }

  .order-xl-5{
    order:5
  }

  .order-xl-6{
    order:6
  }

  .order-xl-7{
    order:7
  }

  .order-xl-8{
    order:8
  }

  .order-xl-9{
    order:9
  }

  .order-xl-10{
    order:10
  }

  .order-xl-11{
    order:11
  }

  .order-xl-12{
    order:12
  }

  .offset-xl-0{
    margin-left:0
  }

  .offset-xl-1{
    margin-left:8.3333333333%
  }

  .offset-xl-2{
    margin-left:16.6666666667%
  }

  .offset-xl-3{
    margin-left:25%
  }

  .offset-xl-4{
    margin-left:33.3333333333%
  }

  .offset-xl-5{
    margin-left:41.6666666667%
  }

  .offset-xl-6{
    margin-left:50%
  }

  .offset-xl-7{
    margin-left:58.3333333333%
  }

  .offset-xl-8{
    margin-left:66.6666666667%
  }

  .offset-xl-9{
    margin-left:75%
  }

  .offset-xl-10{
    margin-left:83.3333333333%
  }

  .offset-xl-11{
    margin-left:91.6666666667%
  }
}

.table{
  width:100%;
  margin-bottom:1rem;
  color:#212529
}

.table th,.table td{
  padding:.75rem;
  vertical-align:top;
  border-top:1px solid #dee2e6
}

.table thead th{
  vertical-align:bottom;
  border-bottom:2px solid #dee2e6
}

.table tbody+tbody{
  border-top:2px solid #dee2e6
}

.table-sm th,.table-sm td{
  padding:.3rem
}

.table-bordered{
  border:1px solid #dee2e6
}

.table-bordered th,.table-bordered td{
  border:1px solid #dee2e6
}

.table-bordered thead th,.table-bordered thead td{
  border-bottom-width:2px
}

.table-borderless th,.table-borderless td,.table-borderless thead th,.table-borderless tbody+tbody{
  border:0
}

.table-striped tbody tr:nth-of-type(odd){
  background-color:rgba(0,0,0,.05)
}

.table-hover tbody tr:hover{
  color:#212529;
  background-color:rgba(0,0,0,.075)
}

.table-primary,.table-primary>th,.table-primary>td{
  background-color:#b8e8ec
}

.table-primary th,.table-primary td,.table-primary thead th,.table-primary tbody+tbody{
  border-color:#7ad4dc
}

.table-hover .table-primary:hover{
  background-color:#a4e1e7
}

.table-hover .table-primary:hover>td,.table-hover .table-primary:hover>th{
  background-color:#a4e1e7
}

.table-secondary,.table-secondary>th,.table-secondary>td{
  background-color:#d6d8db
}

.table-secondary th,.table-secondary td,.table-secondary thead th,.table-secondary tbody+tbody{
  border-color:#b3b7bb
}

.table-hover .table-secondary:hover{
  background-color:#c8cbcf
}

.table-hover .table-secondary:hover>td,.table-hover .table-secondary:hover>th{
  background-color:#c8cbcf
}

.table-success,.table-success>th,.table-success>td{
  background-color:#c3e6cb
}

.table-success th,.table-success td,.table-success thead th,.table-success tbody+tbody{
  border-color:#8fd19e
}

.table-hover .table-success:hover{
  background-color:#b1dfbb
}

.table-hover .table-success:hover>td,.table-hover .table-success:hover>th{
  background-color:#b1dfbb
}

.table-info,.table-info>th,.table-info>td{
  background-color:#bee5eb
}

.table-info th,.table-info td,.table-info thead th,.table-info tbody+tbody{
  border-color:#86cfda
}

.table-hover .table-info:hover{
  background-color:#abdde5
}

.table-hover .table-info:hover>td,.table-hover .table-info:hover>th{
  background-color:#abdde5
}

.table-warning,.table-warning>th,.table-warning>td{
  background-color:#ffeeba
}

.table-warning th,.table-warning td,.table-warning thead th,.table-warning tbody+tbody{
  border-color:#ffdf7e
}

.table-hover .table-warning:hover{
  background-color:#ffe8a1
}

.table-hover .table-warning:hover>td,.table-hover .table-warning:hover>th{
  background-color:#ffe8a1
}

.table-danger,.table-danger>th,.table-danger>td{
  background-color:#f5c6cb
}

.table-danger th,.table-danger td,.table-danger thead th,.table-danger tbody+tbody{
  border-color:#ed969e
}

.table-hover .table-danger:hover{
  background-color:#f1b0b7
}

.table-hover .table-danger:hover>td,.table-hover .table-danger:hover>th{
  background-color:#f1b0b7
}

.table-light,.table-light>th,.table-light>td{
  background-color:#fdfdfe
}

.table-light th,.table-light td,.table-light thead th,.table-light tbody+tbody{
  border-color:#fbfcfc
}

.table-hover .table-light:hover{
  background-color:#ececf6
}

.table-hover .table-light:hover>td,.table-hover .table-light:hover>th{
  background-color:#ececf6
}

.table-dark,.table-dark>th,.table-dark>td{
  background-color:#c6c8ca
}

.table-dark th,.table-dark td,.table-dark thead th,.table-dark tbody+tbody{
  border-color:#95999c
}

.table-hover .table-dark:hover{
  background-color:#b9bbbe
}

.table-hover .table-dark:hover>td,.table-hover .table-dark:hover>th{
  background-color:#b9bbbe
}

.table-tlg-green,.table-tlg-green>th,.table-tlg-green>td{
  background-color:#e6f0d3
}

.table-tlg-green th,.table-tlg-green td,.table-tlg-green thead th,.table-tlg-green tbody+tbody{
  border-color:#d0e3ad
}

.table-hover .table-tlg-green:hover{
  background-color:#dbeac0
}

.table-hover .table-tlg-green:hover>td,.table-hover .table-tlg-green:hover>th{
  background-color:#dbeac0
}

.table-tlg-teal,.table-tlg-teal>th,.table-tlg-teal>td{
  background-color:#b8e8ec
}

.table-tlg-teal th,.table-tlg-teal td,.table-tlg-teal thead th,.table-tlg-teal tbody+tbody{
  border-color:#7ad4dc
}

.table-hover .table-tlg-teal:hover{
  background-color:#a4e1e7
}

.table-hover .table-tlg-teal:hover>td,.table-hover .table-tlg-teal:hover>th{
  background-color:#a4e1e7
}

.table-tlg-purple,.table-tlg-purple>th,.table-tlg-purple>td{
  background-color:#d5d1ef
}

.table-tlg-purple th,.table-tlg-purple td,.table-tlg-purple thead th,.table-tlg-purple tbody+tbody{
  border-color:#b0aae2
}

.table-hover .table-tlg-purple:hover{
  background-color:#c4bee8
}

.table-hover .table-tlg-purple:hover>td,.table-hover .table-tlg-purple:hover>th{
  background-color:#c4bee8
}

.table-tlg-purple-light,.table-tlg-purple-light>th,.table-tlg-purple-light>td{
  background-color:#e9daeb
}

.table-tlg-purple-light th,.table-tlg-purple-light td,.table-tlg-purple-light thead th,.table-tlg-purple-light tbody+tbody{
  border-color:#d5bbd9
}

.table-hover .table-tlg-purple-light:hover{
  background-color:#dfc9e2
}

.table-hover .table-tlg-purple-light:hover>td,.table-hover .table-tlg-purple-light:hover>th{
  background-color:#dfc9e2
}

.table-tlg-orange,.table-tlg-orange>th,.table-tlg-orange>td{
  background-color:#f9d9c1
}

.table-tlg-orange th,.table-tlg-orange td,.table-tlg-orange thead th,.table-tlg-orange tbody+tbody{
  border-color:#f3b88c
}

.table-hover .table-tlg-orange:hover{
  background-color:#f7cbaa
}

.table-hover .table-tlg-orange:hover>td,.table-hover .table-tlg-orange:hover>th{
  background-color:#f7cbaa
}

.table-tlg-orange-light,.table-tlg-orange-light>th,.table-tlg-orange-light>td{
  background-color:#fcefe6
}

.table-tlg-orange-light th,.table-tlg-orange-light td,.table-tlg-orange-light thead th,.table-tlg-orange-light tbody+tbody{
  border-color:#fae2d0
}

.table-hover .table-tlg-orange-light:hover{
  background-color:#f9e0cf
}

.table-hover .table-tlg-orange-light:hover>td,.table-hover .table-tlg-orange-light:hover>th{
  background-color:#f9e0cf
}

.table-tlg-orange-500,.table-tlg-orange-500>th,.table-tlg-orange-500>td{
  background-color:#ffdbca
}

.table-tlg-orange-500 th,.table-tlg-orange-500 td,.table-tlg-orange-500 thead th,.table-tlg-orange-500 tbody+tbody{
  border-color:#ffbc9c
}

.table-hover .table-tlg-orange-500:hover{
  background-color:#ffcab1
}

.table-hover .table-tlg-orange-500:hover>td,.table-hover .table-tlg-orange-500:hover>th{
  background-color:#ffcab1
}

.table-tlg-green-dark,.table-tlg-green-dark>th,.table-tlg-green-dark>td{
  background-color:#d9ebcd
}

.table-tlg-green-dark th,.table-tlg-green-dark td,.table-tlg-green-dark thead th,.table-tlg-green-dark tbody+tbody{
  border-color:#b8daa3
}

.table-hover .table-tlg-green-dark:hover{
  background-color:#cbe4bb
}

.table-hover .table-tlg-green-dark:hover>td,.table-hover .table-tlg-green-dark:hover>th{
  background-color:#cbe4bb
}

.table-active,.table-active>th,.table-active>td{
  background-color:rgba(0,0,0,.075)
}

.table-hover .table-active:hover{
  background-color:rgba(0,0,0,.075)
}

.table-hover .table-active:hover>td,.table-hover .table-active:hover>th{
  background-color:rgba(0,0,0,.075)
}

.table .thead-dark th{
  color:#fff;
  background-color:#343a40;
  border-color:#454d55
}

.table .thead-light th{
  color:#495057;
  background-color:#e9ecef;
  border-color:#dee2e6
}

.table-dark{
  color:#fff;
  background-color:#343a40
}

.table-dark th,.table-dark td,.table-dark thead th{
  border-color:#454d55
}

.table-dark.table-bordered{
  border:0
}

.table-dark.table-striped tbody tr:nth-of-type(odd){
  background-color:rgba(255,255,255,.05)
}

.table-dark.table-hover tbody tr:hover{
  color:#fff;
  background-color:rgba(255,255,255,.075)
}

@media(max-width: 639.98px){
  .table-responsive-sm{
    display:block;
    width:100%;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch
  }

  .table-responsive-sm>.table-bordered{
    border:0
  }
}

@media(max-width: 767.98px){
  .table-responsive-md{
    display:block;
    width:100%;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch
  }

  .table-responsive-md>.table-bordered{
    border:0
  }
}

@media(max-width: 1023.98px){
  .table-responsive-lg{
    display:block;
    width:100%;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch
  }

  .table-responsive-lg>.table-bordered{
    border:0
  }
}

@media(max-width: 1279.98px){
  .table-responsive-xl{
    display:block;
    width:100%;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch
  }

  .table-responsive-xl>.table-bordered{
    border:0
  }
}

.table-responsive{
  display:block;
  width:100%;
  overflow-x:auto;
  -webkit-overflow-scrolling:touch
}

.table-responsive>.table-bordered{
  border:0
}

.form-control{
  display:block;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  padding:.375rem .75rem;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid #ced4da;
  border-radius:.25rem;
  -webkit-transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce){
  .form-control{
    -webkit-transition:none;
    transition:none
  }
}

.form-control::-ms-expand{
  background-color:transparent;
  border:0
}

.form-control:focus{
  color:#495057;
  background-color:#fff;
  border-color:#3cf0ff;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.25)
}

.form-control::-webkit-input-placeholder{
  color:#6c757d;
  opacity:1
}

.form-control::-moz-placeholder{
  color:#6c757d;
  opacity:1
}

.form-control:-ms-input-placeholder{
  color:#6c757d;
  opacity:1
}

.form-control::-ms-input-placeholder{
  color:#6c757d;
  opacity:1
}

.form-control::placeholder{
  color:#6c757d;
  opacity:1
}

.form-control:disabled,.form-control[readonly]{
  background-color:#e9ecef;
  opacity:1
}

select.form-control:focus::-ms-value{
  color:#495057;
  background-color:#fff
}

.form-control-file,.form-control-range{
  display:block;
  width:100%
}

.col-form-label{
  padding-top:calc(0.375rem + 1px);
  padding-bottom:calc(0.375rem + 1px);
  margin-bottom:0;
  font-size:inherit;
  line-height:1.5
}

.col-form-label-lg{
  padding-top:calc(0.5rem + 1px);
  padding-bottom:calc(0.5rem + 1px);
  font-size:1.25rem;
  line-height:1.5
}

.col-form-label-sm{
  padding-top:calc(0.25rem + 1px);
  padding-bottom:calc(0.25rem + 1px);
  font-size:0.875rem;
  line-height:1.5
}

.form-control-plaintext{
  display:block;
  width:100%;
  padding-top:.375rem;
  padding-bottom:.375rem;
  margin-bottom:0;
  line-height:1.5;
  color:#212529;
  background-color:transparent;
  border:solid transparent;
  border-width:1px 0
}

.form-control-plaintext.form-control-sm,.form-control-plaintext.form-control-lg{
  padding-right:0;
  padding-left:0
}

.form-control-sm{
  height:calc(1.5em + 0.5rem + 2px);
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5;
  border-radius:.2rem
}

.form-control-lg{
  height:calc(1.5em + 1rem + 2px);
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}

select.form-control[size],select.form-control[multiple]{
  height:auto
}

textarea.form-control{
  height:auto
}

.form-group{
  margin-bottom:1rem
}

.form-text{
  display:block;
  margin-top:.25rem
}

.form-row{
  display:flex;
  flex-wrap:wrap;
  margin-right:-5px;
  margin-left:-5px
}

.form-row>.col,.form-row>[class*=col-]{
  padding-right:5px;
  padding-left:5px
}

.form-check{
  position:relative;
  display:block;
  padding-left:1.25rem
}

.form-check-input{
  position:absolute;
  margin-top:.3rem;
  margin-left:-1.25rem
}

.form-check-input:disabled~.form-check-label{
  color:#6c757d
}

.form-check-label{
  margin-bottom:0
}

.form-check-inline{
  display:inline-flex;
  align-items:center;
  padding-left:0;
  margin-right:.75rem
}

.form-check-inline .form-check-input{
  position:static;
  margin-top:0;
  margin-right:.3125rem;
  margin-left:0
}

.valid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:80%;
  color:#28a745
}

.valid-tooltip{
  position:absolute;
  top:100%;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.25rem .5rem;
  margin-top:.1rem;
  font-size:0.875rem;
  line-height:1.5;
  color:#fff;
  background-color:rgba(40,167,69,.9);
  border-radius:.25rem
}

.was-validated .form-control:valid,.form-control.is-valid{
  border-color:#28a745;
  padding-right:calc(1.5em + 0.75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:center right calc(0.375em + 0.1875rem);
  background-size:calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:valid:focus,.form-control.is-valid:focus{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}

.was-validated .form-control:valid~.valid-feedback,.was-validated .form-control:valid~.valid-tooltip,.form-control.is-valid~.valid-feedback,.form-control.is-valid~.valid-tooltip{
  display:block
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid{
  padding-right:calc(1.5em + 0.75rem);
  background-position:top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .custom-select:valid,.custom-select.is-valid{
  border-color:#28a745;
  padding-right:calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .custom-select:valid:focus,.custom-select.is-valid:focus{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}

.was-validated .custom-select:valid~.valid-feedback,.was-validated .custom-select:valid~.valid-tooltip,.custom-select.is-valid~.valid-feedback,.custom-select.is-valid~.valid-tooltip{
  display:block
}

.was-validated .form-control-file:valid~.valid-feedback,.was-validated .form-control-file:valid~.valid-tooltip,.form-control-file.is-valid~.valid-feedback,.form-control-file.is-valid~.valid-tooltip{
  display:block
}

.was-validated .form-check-input:valid~.form-check-label,.form-check-input.is-valid~.form-check-label{
  color:#28a745
}

.was-validated .form-check-input:valid~.valid-feedback,.was-validated .form-check-input:valid~.valid-tooltip,.form-check-input.is-valid~.valid-feedback,.form-check-input.is-valid~.valid-tooltip{
  display:block
}

.was-validated .custom-control-input:valid~.custom-control-label,.custom-control-input.is-valid~.custom-control-label{
  color:#28a745
}

.was-validated .custom-control-input:valid~.custom-control-label::before,.custom-control-input.is-valid~.custom-control-label::before{
  border-color:#28a745
}

.was-validated .custom-control-input:valid~.valid-feedback,.was-validated .custom-control-input:valid~.valid-tooltip,.custom-control-input.is-valid~.valid-feedback,.custom-control-input.is-valid~.valid-tooltip{
  display:block
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,.custom-control-input.is-valid:checked~.custom-control-label::before{
  border-color:#34ce57;
  background-color:#34ce57
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,.custom-control-input.is-valid:focus~.custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before{
  border-color:#28a745
}

.was-validated .custom-file-input:valid~.custom-file-label,.custom-file-input.is-valid~.custom-file-label{
  border-color:#28a745
}

.was-validated .custom-file-input:valid~.valid-feedback,.was-validated .custom-file-input:valid~.valid-tooltip,.custom-file-input.is-valid~.valid-feedback,.custom-file-input.is-valid~.valid-tooltip{
  display:block
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,.custom-file-input.is-valid:focus~.custom-file-label{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}

.invalid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:80%;
  color:#dc3545
}

.invalid-tooltip{
  position:absolute;
  top:100%;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.25rem .5rem;
  margin-top:.1rem;
  font-size:0.875rem;
  line-height:1.5;
  color:#fff;
  background-color:rgba(220,53,69,.9);
  border-radius:.25rem
}

.was-validated .form-control:invalid,.form-control.is-invalid{
  border-color:#dc3545;
  padding-right:calc(1.5em + 0.75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat:no-repeat;
  background-position:center right calc(0.375em + 0.1875rem);
  background-size:calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:invalid:focus,.form-control.is-invalid:focus{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}

.was-validated .form-control:invalid~.invalid-feedback,.was-validated .form-control:invalid~.invalid-tooltip,.form-control.is-invalid~.invalid-feedback,.form-control.is-invalid~.invalid-tooltip{
  display:block
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid{
  padding-right:calc(1.5em + 0.75rem);
  background-position:top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .custom-select:invalid,.custom-select.is-invalid{
  border-color:#dc3545;
  padding-right:calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .custom-select:invalid:focus,.custom-select.is-invalid:focus{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}

.was-validated .custom-select:invalid~.invalid-feedback,.was-validated .custom-select:invalid~.invalid-tooltip,.custom-select.is-invalid~.invalid-feedback,.custom-select.is-invalid~.invalid-tooltip{
  display:block
}

.was-validated .form-control-file:invalid~.invalid-feedback,.was-validated .form-control-file:invalid~.invalid-tooltip,.form-control-file.is-invalid~.invalid-feedback,.form-control-file.is-invalid~.invalid-tooltip{
  display:block
}

.was-validated .form-check-input:invalid~.form-check-label,.form-check-input.is-invalid~.form-check-label{
  color:#dc3545
}

.was-validated .form-check-input:invalid~.invalid-feedback,.was-validated .form-check-input:invalid~.invalid-tooltip,.form-check-input.is-invalid~.invalid-feedback,.form-check-input.is-invalid~.invalid-tooltip{
  display:block
}

.was-validated .custom-control-input:invalid~.custom-control-label,.custom-control-input.is-invalid~.custom-control-label{
  color:#dc3545
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,.custom-control-input.is-invalid~.custom-control-label::before{
  border-color:#dc3545
}

.was-validated .custom-control-input:invalid~.invalid-feedback,.was-validated .custom-control-input:invalid~.invalid-tooltip,.custom-control-input.is-invalid~.invalid-feedback,.custom-control-input.is-invalid~.invalid-tooltip{
  display:block
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,.custom-control-input.is-invalid:checked~.custom-control-label::before{
  border-color:#e4606d;
  background-color:#e4606d
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,.custom-control-input.is-invalid:focus~.custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before{
  border-color:#dc3545
}

.was-validated .custom-file-input:invalid~.custom-file-label,.custom-file-input.is-invalid~.custom-file-label{
  border-color:#dc3545
}

.was-validated .custom-file-input:invalid~.invalid-feedback,.was-validated .custom-file-input:invalid~.invalid-tooltip,.custom-file-input.is-invalid~.invalid-feedback,.custom-file-input.is-invalid~.invalid-tooltip{
  display:block
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,.custom-file-input.is-invalid:focus~.custom-file-label{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}

.form-inline{
  display:flex;
  flex-flow:row wrap;
  align-items:center
}

.form-inline .form-check{
  width:100%
}

@media(min-width: 640px){
  .form-inline label{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:0
  }

  .form-inline .form-group{
    display:flex;
    flex:0 0 auto;
    flex-flow:row wrap;
    align-items:center;
    margin-bottom:0
  }

  .form-inline .form-control{
    display:inline-block;
    width:auto;
    vertical-align:middle
  }

  .form-inline .form-control-plaintext{
    display:inline-block
  }

  .form-inline .input-group,.form-inline .custom-select{
    width:auto
  }

  .form-inline .form-check{
    display:flex;
    align-items:center;
    justify-content:center;
    width:auto;
    padding-left:0
  }

  .form-inline .form-check-input{
    position:relative;
    flex-shrink:0;
    margin-top:0;
    margin-right:.25rem;
    margin-left:0
  }

  .form-inline .custom-control{
    align-items:center;
    justify-content:center
  }

  .form-inline .custom-control-label{
    margin-bottom:0
  }
}

.btn{
  display:inline-block;
  font-weight:400;
  color:#212529;
  text-align:center;
  vertical-align:middle;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  background-color:transparent;
  border:1px solid transparent;
  padding:.375rem .75rem;
  font-size:1rem;
  line-height:1.5;
  border-radius:.25rem;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce){
  .btn{
    -webkit-transition:none;
    transition:none
  }
}

.btn:hover{
  color:#212529;
  text-decoration:none
}

.btn:focus,.btn.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.25)
}

.btn.disabled,.btn:disabled{
  opacity:.65
}

a.btn.disabled,fieldset:disabled a.btn{
  pointer-events:none
}

.btn-primary{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-primary:hover{
  color:#fff;
  background-color:#008a95;
  border-color:#007e88
}

.btn-primary:focus,.btn-primary.focus{
  box-shadow:0 0 0 .2rem rgba(38,185,197,.5)
}

.btn-primary.disabled,.btn-primary:disabled{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-primary:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active,.show>.btn-primary.dropdown-toggle{
  color:#fff;
  background-color:#007e88;
  border-color:#00727b
}

.btn-primary:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(38,185,197,.5)
}

.btn-secondary{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-secondary:hover{
  color:#fff;
  background-color:#5a6268;
  border-color:#545b62
}

.btn-secondary:focus,.btn-secondary.focus{
  box-shadow:0 0 0 .2rem rgba(130,138,145,.5)
}

.btn-secondary.disabled,.btn-secondary:disabled{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-secondary:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active,.show>.btn-secondary.dropdown-toggle{
  color:#fff;
  background-color:#545b62;
  border-color:#4e555b
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(130,138,145,.5)
}

.btn-success{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-success:hover{
  color:#fff;
  background-color:#218838;
  border-color:#1e7e34
}

.btn-success:focus,.btn-success.focus{
  box-shadow:0 0 0 .2rem rgba(72,180,97,.5)
}

.btn-success.disabled,.btn-success:disabled{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-success:not(:disabled):not(.disabled):active,.btn-success:not(:disabled):not(.disabled).active,.show>.btn-success.dropdown-toggle{
  color:#fff;
  background-color:#1e7e34;
  border-color:#1c7430
}

.btn-success:not(:disabled):not(.disabled):active:focus,.btn-success:not(:disabled):not(.disabled).active:focus,.show>.btn-success.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(72,180,97,.5)
}

.btn-info{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-info:hover{
  color:#fff;
  background-color:#138496;
  border-color:#117a8b
}

.btn-info:focus,.btn-info.focus{
  box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
}

.btn-info.disabled,.btn-info:disabled{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-info:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active,.show>.btn-info.dropdown-toggle{
  color:#fff;
  background-color:#117a8b;
  border-color:#10707f
}

.btn-info:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active:focus,.show>.btn-info.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
}

.btn-warning{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-warning:hover{
  color:#212529;
  background-color:#e0a800;
  border-color:#d39e00
}

.btn-warning:focus,.btn-warning.focus{
  box-shadow:0 0 0 .2rem rgba(222,170,12,.5)
}

.btn-warning.disabled,.btn-warning:disabled{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-warning:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active,.show>.btn-warning.dropdown-toggle{
  color:#212529;
  background-color:#d39e00;
  border-color:#c69500
}

.btn-warning:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-warning.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(222,170,12,.5)
}

.btn-danger{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-danger:hover{
  color:#fff;
  background-color:#c82333;
  border-color:#bd2130
}

.btn-danger:focus,.btn-danger.focus{
  box-shadow:0 0 0 .2rem rgba(225,83,97,.5)
}

.btn-danger.disabled,.btn-danger:disabled{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-danger:not(:disabled):not(.disabled):active,.btn-danger:not(:disabled):not(.disabled).active,.show>.btn-danger.dropdown-toggle{
  color:#fff;
  background-color:#bd2130;
  border-color:#b21f2d
}

.btn-danger:not(:disabled):not(.disabled):active:focus,.btn-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-danger.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(225,83,97,.5)
}

.btn-light{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-light:hover{
  color:#212529;
  background-color:#e2e6ea;
  border-color:#dae0e5
}

.btn-light:focus,.btn-light.focus{
  box-shadow:0 0 0 .2rem rgba(216,217,219,.5)
}

.btn-light.disabled,.btn-light:disabled{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-light:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active,.show>.btn-light.dropdown-toggle{
  color:#212529;
  background-color:#dae0e5;
  border-color:#d3d9df
}

.btn-light:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active:focus,.show>.btn-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(216,217,219,.5)
}

.btn-dark{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-dark:hover{
  color:#fff;
  background-color:#23272b;
  border-color:#1d2124
}

.btn-dark:focus,.btn-dark.focus{
  box-shadow:0 0 0 .2rem rgba(82,88,93,.5)
}

.btn-dark.disabled,.btn-dark:disabled{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-dark:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active,.show>.btn-dark.dropdown-toggle{
  color:#fff;
  background-color:#1d2124;
  border-color:#171a1d
}

.btn-dark:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(82,88,93,.5)
}

.btn-tlg-green{
  color:#212529;
  background-color:#a4ca61;
  border-color:#a4ca61
}

.btn-tlg-green:hover{
  color:#212529;
  background-color:#94c044;
  border-color:#8dba3e
}

.btn-tlg-green:focus,.btn-tlg-green.focus{
  box-shadow:0 0 0 .2rem rgba(144,177,89,.5)
}

.btn-tlg-green.disabled,.btn-tlg-green:disabled{
  color:#212529;
  background-color:#a4ca61;
  border-color:#a4ca61
}

.btn-tlg-green:not(:disabled):not(.disabled):active,.btn-tlg-green:not(:disabled):not(.disabled).active,.show>.btn-tlg-green.dropdown-toggle{
  color:#212529;
  background-color:#8dba3e;
  border-color:#86b03b
}

.btn-tlg-green:not(:disabled):not(.disabled):active:focus,.btn-tlg-green:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-green.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(144,177,89,.5)
}

.btn-tlg-teal{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-tlg-teal:hover{
  color:#fff;
  background-color:#008a95;
  border-color:#007e88
}

.btn-tlg-teal:focus,.btn-tlg-teal.focus{
  box-shadow:0 0 0 .2rem rgba(38,185,197,.5)
}

.btn-tlg-teal.disabled,.btn-tlg-teal:disabled{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-tlg-teal:not(:disabled):not(.disabled):active,.btn-tlg-teal:not(:disabled):not(.disabled).active,.show>.btn-tlg-teal.dropdown-toggle{
  color:#fff;
  background-color:#007e88;
  border-color:#00727b
}

.btn-tlg-teal:not(:disabled):not(.disabled):active:focus,.btn-tlg-teal:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-teal.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(38,185,197,.5)
}

.btn-tlg-purple{
  color:#fff;
  background-color:#685bc7;
  border-color:#685bc7
}

.btn-tlg-purple:hover{
  color:#fff;
  background-color:#4f40bc;
  border-color:#4b3db2
}

.btn-tlg-purple:focus,.btn-tlg-purple.focus{
  box-shadow:0 0 0 .2rem rgba(127,116,207,.5)
}

.btn-tlg-purple.disabled,.btn-tlg-purple:disabled{
  color:#fff;
  background-color:#685bc7;
  border-color:#685bc7
}

.btn-tlg-purple:not(:disabled):not(.disabled):active,.btn-tlg-purple:not(:disabled):not(.disabled).active,.show>.btn-tlg-purple.dropdown-toggle{
  color:#fff;
  background-color:#4b3db2;
  border-color:#473aa9
}

.btn-tlg-purple:not(:disabled):not(.disabled):active:focus,.btn-tlg-purple:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-purple.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(127,116,207,.5)
}

.btn-tlg-purple-light{
  color:#fff;
  background-color:#af7cb6;
  border-color:#af7cb6
}

.btn-tlg-purple-light:hover{
  color:#fff;
  background-color:#a063a8;
  border-color:#9b5ba4
}

.btn-tlg-purple-light:focus,.btn-tlg-purple-light.focus{
  box-shadow:0 0 0 .2rem rgba(187,144,193,.5)
}

.btn-tlg-purple-light.disabled,.btn-tlg-purple-light:disabled{
  color:#fff;
  background-color:#af7cb6;
  border-color:#af7cb6
}

.btn-tlg-purple-light:not(:disabled):not(.disabled):active,.btn-tlg-purple-light:not(:disabled):not(.disabled).active,.show>.btn-tlg-purple-light.dropdown-toggle{
  color:#fff;
  background-color:#9b5ba4;
  border-color:#93579c
}

.btn-tlg-purple-light:not(:disabled):not(.disabled):active:focus,.btn-tlg-purple-light:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-purple-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(187,144,193,.5)
}

.btn-tlg-orange{
  color:#fff;
  background-color:#e87722;
  border-color:#e87722
}

.btn-tlg-orange:hover{
  color:#fff;
  background-color:#ce6515;
  border-color:#c35f14
}

.btn-tlg-orange:focus,.btn-tlg-orange.focus{
  box-shadow:0 0 0 .2rem rgba(235,139,67,.5)
}

.btn-tlg-orange.disabled,.btn-tlg-orange:disabled{
  color:#fff;
  background-color:#e87722;
  border-color:#e87722
}

.btn-tlg-orange:not(:disabled):not(.disabled):active,.btn-tlg-orange:not(:disabled):not(.disabled).active,.show>.btn-tlg-orange.dropdown-toggle{
  color:#fff;
  background-color:#c35f14;
  border-color:#b75a13
}

.btn-tlg-orange:not(:disabled):not(.disabled):active:focus,.btn-tlg-orange:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-orange.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(235,139,67,.5)
}

.btn-tlg-orange-light{
  color:#212529;
  background-color:#f6c7a4;
  border-color:#f6c7a4
}

.btn-tlg-orange-light:hover{
  color:#212529;
  background-color:#f3b281;
  border-color:#f1aa76
}

.btn-tlg-orange-light:focus,.btn-tlg-orange-light.focus{
  box-shadow:0 0 0 .2rem rgba(214,175,146,.5)
}

.btn-tlg-orange-light.disabled,.btn-tlg-orange-light:disabled{
  color:#212529;
  background-color:#f6c7a4;
  border-color:#f6c7a4
}

.btn-tlg-orange-light:not(:disabled):not(.disabled):active,.btn-tlg-orange-light:not(:disabled):not(.disabled).active,.show>.btn-tlg-orange-light.dropdown-toggle{
  color:#212529;
  background-color:#f1aa76;
  border-color:#f0a36a
}

.btn-tlg-orange-light:not(:disabled):not(.disabled):active:focus,.btn-tlg-orange-light:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-orange-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(214,175,146,.5)
}

.btn-tlg-orange-500{
  color:#212529;
  background-color:#ff7f41;
  border-color:#ff7f41
}

.btn-tlg-orange-500:hover{
  color:#fff;
  background-color:#ff651b;
  border-color:#ff5d0e
}

.btn-tlg-orange-500:focus,.btn-tlg-orange-500.focus{
  box-shadow:0 0 0 .2rem rgba(222,114,61,.5)
}

.btn-tlg-orange-500.disabled,.btn-tlg-orange-500:disabled{
  color:#212529;
  background-color:#ff7f41;
  border-color:#ff7f41
}

.btn-tlg-orange-500:not(:disabled):not(.disabled):active,.btn-tlg-orange-500:not(:disabled):not(.disabled).active,.show>.btn-tlg-orange-500.dropdown-toggle{
  color:#fff;
  background-color:#ff5d0e;
  border-color:#ff5401
}

.btn-tlg-orange-500:not(:disabled):not(.disabled):active:focus,.btn-tlg-orange-500:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-orange-500.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(222,114,61,.5)
}

.btn-tlg-green-dark{
  color:#212529;
  background-color:#76b84e;
  border-color:#76b84e
}

.btn-tlg-green-dark:hover{
  color:#fff;
  background-color:#64a040;
  border-color:#5e973c
}

.btn-tlg-green-dark:focus,.btn-tlg-green-dark.focus{
  box-shadow:0 0 0 .2rem rgba(105,162,72,.5)
}

.btn-tlg-green-dark.disabled,.btn-tlg-green-dark:disabled{
  color:#212529;
  background-color:#76b84e;
  border-color:#76b84e
}

.btn-tlg-green-dark:not(:disabled):not(.disabled):active,.btn-tlg-green-dark:not(:disabled):not(.disabled).active,.show>.btn-tlg-green-dark.dropdown-toggle{
  color:#fff;
  background-color:#5e973c;
  border-color:#598d39
}

.btn-tlg-green-dark:not(:disabled):not(.disabled):active:focus,.btn-tlg-green-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-tlg-green-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(105,162,72,.5)
}

.btn-outline-primary{
  color:#00adbb;
  border-color:#00adbb
}

.btn-outline-primary:hover{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-outline-primary:focus,.btn-outline-primary.focus{
  box-shadow:0 0 0 .2rem rgba(0,173,187,.5)
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color:#00adbb;
  background-color:transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(0,173,187,.5)
}

.btn-outline-secondary{
  color:#6c757d;
  border-color:#6c757d
}

.btn-outline-secondary:hover{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-outline-secondary:focus,.btn-outline-secondary.focus{
  box-shadow:0 0 0 .2rem rgba(108,117,125,.5)
}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled{
  color:#6c757d;
  background-color:transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active,.show>.btn-outline-secondary.dropdown-toggle{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(108,117,125,.5)
}

.btn-outline-success{
  color:#28a745;
  border-color:#28a745
}

.btn-outline-success:hover{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-outline-success:focus,.btn-outline-success.focus{
  box-shadow:0 0 0 .2rem rgba(40,167,69,.5)
}

.btn-outline-success.disabled,.btn-outline-success:disabled{
  color:#28a745;
  background-color:transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,.btn-outline-success:not(:disabled):not(.disabled).active,.show>.btn-outline-success.dropdown-toggle{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,.btn-outline-success:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-success.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(40,167,69,.5)
}

.btn-outline-info{
  color:#17a2b8;
  border-color:#17a2b8
}

.btn-outline-info:hover{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-outline-info:focus,.btn-outline-info.focus{
  box-shadow:0 0 0 .2rem rgba(23,162,184,.5)
}

.btn-outline-info.disabled,.btn-outline-info:disabled{
  color:#17a2b8;
  background-color:transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active,.show>.btn-outline-info.dropdown-toggle{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-info.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(23,162,184,.5)
}

.btn-outline-warning{
  color:#ffc107;
  border-color:#ffc107
}

.btn-outline-warning:hover{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-outline-warning:focus,.btn-outline-warning.focus{
  box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-outline-warning.disabled,.btn-outline-warning:disabled{
  color:#ffc107;
  background-color:transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active,.show>.btn-outline-warning.dropdown-toggle{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-warning.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-outline-danger{
  color:#dc3545;
  border-color:#dc3545
}

.btn-outline-danger:hover{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-outline-danger:focus,.btn-outline-danger.focus{
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-outline-danger.disabled,.btn-outline-danger:disabled{
  color:#dc3545;
  background-color:transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active,.btn-outline-danger:not(:disabled):not(.disabled).active,.show>.btn-outline-danger.dropdown-toggle{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,.btn-outline-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-danger.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-outline-light{
  color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-outline-light:hover{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-outline-light:focus,.btn-outline-light.focus{
  box-shadow:0 0 0 .2rem rgba(248,249,250,.5)
}

.btn-outline-light.disabled,.btn-outline-light:disabled{
  color:#f8f9fa;
  background-color:transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active,.show>.btn-outline-light.dropdown-toggle{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(248,249,250,.5)
}

.btn-outline-dark{
  color:#343a40;
  border-color:#343a40
}

.btn-outline-dark:hover{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-outline-dark:focus,.btn-outline-dark.focus{
  box-shadow:0 0 0 .2rem rgba(52,58,64,.5)
}

.btn-outline-dark.disabled,.btn-outline-dark:disabled{
  color:#343a40;
  background-color:transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active,.show>.btn-outline-dark.dropdown-toggle{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(52,58,64,.5)
}

.btn-outline-tlg-green{
  color:#a4ca61;
  border-color:#a4ca61
}

.btn-outline-tlg-green:hover{
  color:#212529;
  background-color:#a4ca61;
  border-color:#a4ca61
}

.btn-outline-tlg-green:focus,.btn-outline-tlg-green.focus{
  box-shadow:0 0 0 .2rem rgba(164,202,97,.5)
}

.btn-outline-tlg-green.disabled,.btn-outline-tlg-green:disabled{
  color:#a4ca61;
  background-color:transparent
}

.btn-outline-tlg-green:not(:disabled):not(.disabled):active,.btn-outline-tlg-green:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-green.dropdown-toggle{
  color:#212529;
  background-color:#a4ca61;
  border-color:#a4ca61
}

.btn-outline-tlg-green:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-green:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-green.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(164,202,97,.5)
}

.btn-outline-tlg-teal{
  color:#00adbb;
  border-color:#00adbb
}

.btn-outline-tlg-teal:hover{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-outline-tlg-teal:focus,.btn-outline-tlg-teal.focus{
  box-shadow:0 0 0 .2rem rgba(0,173,187,.5)
}

.btn-outline-tlg-teal.disabled,.btn-outline-tlg-teal:disabled{
  color:#00adbb;
  background-color:transparent
}

.btn-outline-tlg-teal:not(:disabled):not(.disabled):active,.btn-outline-tlg-teal:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-teal.dropdown-toggle{
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.btn-outline-tlg-teal:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-teal:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-teal.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(0,173,187,.5)
}

.btn-outline-tlg-purple{
  color:#685bc7;
  border-color:#685bc7
}

.btn-outline-tlg-purple:hover{
  color:#fff;
  background-color:#685bc7;
  border-color:#685bc7
}

.btn-outline-tlg-purple:focus,.btn-outline-tlg-purple.focus{
  box-shadow:0 0 0 .2rem rgba(104,91,199,.5)
}

.btn-outline-tlg-purple.disabled,.btn-outline-tlg-purple:disabled{
  color:#685bc7;
  background-color:transparent
}

.btn-outline-tlg-purple:not(:disabled):not(.disabled):active,.btn-outline-tlg-purple:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-purple.dropdown-toggle{
  color:#fff;
  background-color:#685bc7;
  border-color:#685bc7
}

.btn-outline-tlg-purple:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-purple:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-purple.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(104,91,199,.5)
}

.btn-outline-tlg-purple-light{
  color:#af7cb6;
  border-color:#af7cb6
}

.btn-outline-tlg-purple-light:hover{
  color:#fff;
  background-color:#af7cb6;
  border-color:#af7cb6
}

.btn-outline-tlg-purple-light:focus,.btn-outline-tlg-purple-light.focus{
  box-shadow:0 0 0 .2rem rgba(175,124,182,.5)
}

.btn-outline-tlg-purple-light.disabled,.btn-outline-tlg-purple-light:disabled{
  color:#af7cb6;
  background-color:transparent
}

.btn-outline-tlg-purple-light:not(:disabled):not(.disabled):active,.btn-outline-tlg-purple-light:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-purple-light.dropdown-toggle{
  color:#fff;
  background-color:#af7cb6;
  border-color:#af7cb6
}

.btn-outline-tlg-purple-light:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-purple-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-purple-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(175,124,182,.5)
}

.btn-outline-tlg-orange{
  color:#e87722;
  border-color:#e87722
}

.btn-outline-tlg-orange:hover{
  color:#fff;
  background-color:#e87722;
  border-color:#e87722
}

.btn-outline-tlg-orange:focus,.btn-outline-tlg-orange.focus{
  box-shadow:0 0 0 .2rem rgba(232,119,34,.5)
}

.btn-outline-tlg-orange.disabled,.btn-outline-tlg-orange:disabled{
  color:#e87722;
  background-color:transparent
}

.btn-outline-tlg-orange:not(:disabled):not(.disabled):active,.btn-outline-tlg-orange:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-orange.dropdown-toggle{
  color:#fff;
  background-color:#e87722;
  border-color:#e87722
}

.btn-outline-tlg-orange:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-orange:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-orange.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(232,119,34,.5)
}

.btn-outline-tlg-orange-light{
  color:#f6c7a4;
  border-color:#f6c7a4
}

.btn-outline-tlg-orange-light:hover{
  color:#212529;
  background-color:#f6c7a4;
  border-color:#f6c7a4
}

.btn-outline-tlg-orange-light:focus,.btn-outline-tlg-orange-light.focus{
  box-shadow:0 0 0 .2rem rgba(246,199,164,.5)
}

.btn-outline-tlg-orange-light.disabled,.btn-outline-tlg-orange-light:disabled{
  color:#f6c7a4;
  background-color:transparent
}

.btn-outline-tlg-orange-light:not(:disabled):not(.disabled):active,.btn-outline-tlg-orange-light:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-orange-light.dropdown-toggle{
  color:#212529;
  background-color:#f6c7a4;
  border-color:#f6c7a4
}

.btn-outline-tlg-orange-light:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-orange-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-orange-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(246,199,164,.5)
}

.btn-outline-tlg-orange-500{
  color:#ff7f41;
  border-color:#ff7f41
}

.btn-outline-tlg-orange-500:hover{
  color:#212529;
  background-color:#ff7f41;
  border-color:#ff7f41
}

.btn-outline-tlg-orange-500:focus,.btn-outline-tlg-orange-500.focus{
  box-shadow:0 0 0 .2rem rgba(255,127,65,.5)
}

.btn-outline-tlg-orange-500.disabled,.btn-outline-tlg-orange-500:disabled{
  color:#ff7f41;
  background-color:transparent
}

.btn-outline-tlg-orange-500:not(:disabled):not(.disabled):active,.btn-outline-tlg-orange-500:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-orange-500.dropdown-toggle{
  color:#212529;
  background-color:#ff7f41;
  border-color:#ff7f41
}

.btn-outline-tlg-orange-500:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-orange-500:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-orange-500.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(255,127,65,.5)
}

.btn-outline-tlg-green-dark{
  color:#76b84e;
  border-color:#76b84e
}

.btn-outline-tlg-green-dark:hover{
  color:#212529;
  background-color:#76b84e;
  border-color:#76b84e
}

.btn-outline-tlg-green-dark:focus,.btn-outline-tlg-green-dark.focus{
  box-shadow:0 0 0 .2rem rgba(118,184,78,.5)
}

.btn-outline-tlg-green-dark.disabled,.btn-outline-tlg-green-dark:disabled{
  color:#76b84e;
  background-color:transparent
}

.btn-outline-tlg-green-dark:not(:disabled):not(.disabled):active,.btn-outline-tlg-green-dark:not(:disabled):not(.disabled).active,.show>.btn-outline-tlg-green-dark.dropdown-toggle{
  color:#212529;
  background-color:#76b84e;
  border-color:#76b84e
}

.btn-outline-tlg-green-dark:not(:disabled):not(.disabled):active:focus,.btn-outline-tlg-green-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-tlg-green-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(118,184,78,.5)
}

.btn-link{
  font-weight:400;
  color:#00adbb;
  text-decoration:none
}

.btn-link:hover{
  color:#00666f;
  text-decoration:underline
}

.btn-link:focus,.btn-link.focus{
  text-decoration:underline;
  box-shadow:none
}

.btn-link:disabled,.btn-link.disabled{
  color:#6c757d;
  pointer-events:none
}

.btn-lg,.btn-group-lg>.btn{
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}

.btn-sm,.btn-group-sm>.btn{
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5;
  border-radius:.2rem
}

.btn-block{
  display:block;
  width:100%
}

.btn-block+.btn-block{
  margin-top:.5rem
}

input[type=submit].btn-block,input[type=reset].btn-block,input[type=button].btn-block{
  width:100%
}

.fade{
  -webkit-transition:opacity .15s linear;
  transition:opacity .15s linear
}

@media(prefers-reduced-motion: reduce){
  .fade{
    -webkit-transition:none;
    transition:none
  }
}

.fade:not(.show){
  opacity:0
}

.collapse:not(.show){
  display:none
}

.collapsing{
  position:relative;
  height:0;
  overflow:hidden;
  -webkit-transition:height .35s ease;
  transition:height .35s ease
}

@media(prefers-reduced-motion: reduce){
  .collapsing{
    -webkit-transition:none;
    transition:none
  }
}

.dropup,.dropright,.dropdown,.dropleft{
  position:relative
}

.dropdown-toggle{
  white-space:nowrap
}

.dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid;
  border-right:.3em solid transparent;
  border-bottom:0;
  border-left:.3em solid transparent
}

.dropdown-toggle:empty::after{
  margin-left:0
}

.dropdown-menu{
  position:absolute;
  top:100%;
  left:0;
  z-index:1000;
  display:none;
  float:left;
  min-width:10rem;
  padding:.5rem 0;
  margin:.125rem 0 0;
  font-size:1rem;
  color:#212529;
  text-align:left;
  list-style:none;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.15);
  border-radius:.25rem
}

.dropdown-menu-left{
  right:auto;
  left:0
}

.dropdown-menu-right{
  right:0;
  left:auto
}

@media(min-width: 640px){
  .dropdown-menu-sm-left{
    right:auto;
    left:0
  }

  .dropdown-menu-sm-right{
    right:0;
    left:auto
  }
}

@media(min-width: 768px){
  .dropdown-menu-md-left{
    right:auto;
    left:0
  }

  .dropdown-menu-md-right{
    right:0;
    left:auto
  }
}

@media(min-width: 1024px){
  .dropdown-menu-lg-left{
    right:auto;
    left:0
  }

  .dropdown-menu-lg-right{
    right:0;
    left:auto
  }
}

@media(min-width: 1280px){
  .dropdown-menu-xl-left{
    right:auto;
    left:0
  }

  .dropdown-menu-xl-right{
    right:0;
    left:auto
  }
}

.dropup .dropdown-menu{
  top:auto;
  bottom:100%;
  margin-top:0;
  margin-bottom:.125rem
}

.dropup .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:0;
  border-right:.3em solid transparent;
  border-bottom:.3em solid;
  border-left:.3em solid transparent
}

.dropup .dropdown-toggle:empty::after{
  margin-left:0
}

.dropright .dropdown-menu{
  top:0;
  right:auto;
  left:100%;
  margin-top:0;
  margin-left:.125rem
}

.dropright .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid transparent;
  border-right:0;
  border-bottom:.3em solid transparent;
  border-left:.3em solid
}

.dropright .dropdown-toggle:empty::after{
  margin-left:0
}

.dropright .dropdown-toggle::after{
  vertical-align:0
}

.dropleft .dropdown-menu{
  top:0;
  right:100%;
  left:auto;
  margin-top:0;
  margin-right:.125rem
}

.dropleft .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:""
}

.dropleft .dropdown-toggle::after{
  display:none
}

.dropleft .dropdown-toggle::before{
  display:inline-block;
  margin-right:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid transparent;
  border-right:.3em solid;
  border-bottom:.3em solid transparent
}

.dropleft .dropdown-toggle:empty::after{
  margin-left:0
}

.dropleft .dropdown-toggle::before{
  vertical-align:0
}

.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=bottom],.dropdown-menu[x-placement^=left]{
  right:auto;
  bottom:auto
}

.dropdown-divider{
  height:0;
  margin:.5rem 0;
  overflow:hidden;
  border-top:1px solid #e9ecef
}

.dropdown-item{
  display:block;
  width:100%;
  padding:.25rem 1.5rem;
  clear:both;
  font-weight:400;
  color:#212529;
  text-align:inherit;
  white-space:nowrap;
  background-color:transparent;
  border:0
}

.dropdown-item:hover,.dropdown-item:focus{
  color:#16181b;
  text-decoration:none;
  background-color:#f8f9fa
}

.dropdown-item.active,.dropdown-item:active{
  color:#fff;
  text-decoration:none;
  background-color:#00adbb
}

.dropdown-item.disabled,.dropdown-item:disabled{
  color:#6c757d;
  pointer-events:none;
  background-color:transparent
}

.dropdown-menu.show{
  display:block
}

.dropdown-header{
  display:block;
  padding:.5rem 1.5rem;
  margin-bottom:0;
  font-size:0.875rem;
  color:#6c757d;
  white-space:nowrap
}

.dropdown-item-text{
  display:block;
  padding:.25rem 1.5rem;
  color:#212529
}

.btn-group,.btn-group-vertical{
  position:relative;
  display:inline-flex;
  vertical-align:middle
}

.btn-group>.btn,.btn-group-vertical>.btn{
  position:relative;
  flex:1 1 auto
}

.btn-group>.btn:hover,.btn-group-vertical>.btn:hover{
  z-index:1
}

.btn-group>.btn:focus,.btn-group>.btn:active,.btn-group>.btn.active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn.active{
  z-index:1
}

.btn-toolbar{
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-start
}

.btn-toolbar .input-group{
  width:auto
}

.btn-group>.btn:not(:first-child),.btn-group>.btn-group:not(:first-child){
  margin-left:-1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),.btn-group>.btn-group:not(:last-child)>.btn{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.btn-group>.btn:not(:first-child),.btn-group>.btn-group:not(:first-child)>.btn{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.dropdown-toggle-split{
  padding-right:.5625rem;
  padding-left:.5625rem
}

.dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after,.dropright .dropdown-toggle-split::after{
  margin-left:0
}

.dropleft .dropdown-toggle-split::before{
  margin-right:0
}

.btn-sm+.dropdown-toggle-split,.btn-group-sm>.btn+.dropdown-toggle-split{
  padding-right:.375rem;
  padding-left:.375rem
}

.btn-lg+.dropdown-toggle-split,.btn-group-lg>.btn+.dropdown-toggle-split{
  padding-right:.75rem;
  padding-left:.75rem
}

.btn-group-vertical{
  flex-direction:column;
  align-items:flex-start;
  justify-content:center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group{
  width:100%
}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child){
  margin-top:-1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),.btn-group-vertical>.btn-group:not(:last-child)>.btn{
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child)>.btn{
  border-top-left-radius:0;
  border-top-right-radius:0
}

.btn-group-toggle>.btn,.btn-group-toggle>.btn-group>.btn{
  margin-bottom:0
}

.btn-group-toggle>.btn input[type=radio],.btn-group-toggle>.btn input[type=checkbox],.btn-group-toggle>.btn-group>.btn input[type=radio],.btn-group-toggle>.btn-group>.btn input[type=checkbox]{
  position:absolute;
  clip:rect(0, 0, 0, 0);
  pointer-events:none
}

.input-group{
  position:relative;
  display:flex;
  flex-wrap:wrap;
  align-items:stretch;
  width:100%
}

.input-group>.form-control,.input-group>.form-control-plaintext,.input-group>.custom-select,.input-group>.custom-file{
  position:relative;
  flex:1 1 auto;
  width:1%;
  margin-bottom:0
}

.input-group>.form-control+.form-control,.input-group>.form-control+.custom-select,.input-group>.form-control+.custom-file,.input-group>.form-control-plaintext+.form-control,.input-group>.form-control-plaintext+.custom-select,.input-group>.form-control-plaintext+.custom-file,.input-group>.custom-select+.form-control,.input-group>.custom-select+.custom-select,.input-group>.custom-select+.custom-file,.input-group>.custom-file+.form-control,.input-group>.custom-file+.custom-select,.input-group>.custom-file+.custom-file{
  margin-left:-1px
}

.input-group>.form-control:focus,.input-group>.custom-select:focus,.input-group>.custom-file .custom-file-input:focus~.custom-file-label{
  z-index:3
}

.input-group>.custom-file .custom-file-input:focus{
  z-index:4
}

.input-group>.form-control:not(:last-child),.input-group>.custom-select:not(:last-child){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.input-group>.form-control:not(:first-child),.input-group>.custom-select:not(:first-child){
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.input-group>.custom-file{
  display:flex;
  align-items:center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,.input-group>.custom-file:not(:last-child) .custom-file-label::after{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.input-group>.custom-file:not(:first-child) .custom-file-label{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.input-group-prepend,.input-group-append{
  display:flex
}

.input-group-prepend .btn,.input-group-append .btn{
  position:relative;
  z-index:2
}

.input-group-prepend .btn:focus,.input-group-append .btn:focus{
  z-index:3
}

.input-group-prepend .btn+.btn,.input-group-prepend .btn+.input-group-text,.input-group-prepend .input-group-text+.input-group-text,.input-group-prepend .input-group-text+.btn,.input-group-append .btn+.btn,.input-group-append .btn+.input-group-text,.input-group-append .input-group-text+.input-group-text,.input-group-append .input-group-text+.btn{
  margin-left:-1px
}

.input-group-prepend{
  margin-right:-1px
}

.input-group-append{
  margin-left:-1px
}

.input-group-text{
  display:flex;
  align-items:center;
  padding:.375rem .75rem;
  margin-bottom:0;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  text-align:center;
  white-space:nowrap;
  background-color:#e9ecef;
  border:1px solid #ced4da;
  border-radius:.25rem
}

.input-group-text input[type=radio],.input-group-text input[type=checkbox]{
  margin-top:0
}

.input-group-lg>.form-control:not(textarea),.input-group-lg>.custom-select{
  height:calc(1.5em + 1rem + 2px)
}

.input-group-lg>.form-control,.input-group-lg>.custom-select,.input-group-lg>.input-group-prepend>.input-group-text,.input-group-lg>.input-group-append>.input-group-text,.input-group-lg>.input-group-prepend>.btn,.input-group-lg>.input-group-append>.btn{
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}

.input-group-sm>.form-control:not(textarea),.input-group-sm>.custom-select{
  height:calc(1.5em + 0.5rem + 2px)
}

.input-group-sm>.form-control,.input-group-sm>.custom-select,.input-group-sm>.input-group-prepend>.input-group-text,.input-group-sm>.input-group-append>.input-group-text,.input-group-sm>.input-group-prepend>.btn,.input-group-sm>.input-group-append>.btn{
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5;
  border-radius:.2rem
}

.input-group-lg>.custom-select,.input-group-sm>.custom-select{
  padding-right:1.75rem
}

.input-group>.input-group-prepend>.btn,.input-group>.input-group-prepend>.input-group-text,.input-group>.input-group-append:not(:last-child)>.btn,.input-group>.input-group-append:not(:last-child)>.input-group-text,.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),.input-group>.input-group-append:last-child>.input-group-text:not(:last-child){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.input-group>.input-group-append>.btn,.input-group>.input-group-append>.input-group-text,.input-group>.input-group-prepend:not(:first-child)>.btn,.input-group>.input-group-prepend:not(:first-child)>.input-group-text,.input-group>.input-group-prepend:first-child>.btn:not(:first-child),.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child){
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.custom-control{
  position:relative;
  display:block;
  min-height:1.5rem;
  padding-left:1.5rem
}

.custom-control-inline{
  display:inline-flex;
  margin-right:1rem
}

.custom-control-input{
  position:absolute;
  z-index:-1;
  opacity:0
}

.custom-control-input:checked~.custom-control-label::before{
  color:#fff;
  border-color:#00adbb;
  background-color:#00adbb
}

.custom-control-input:focus~.custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(0,173,187,.25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before{
  border-color:#3cf0ff
}

.custom-control-input:not(:disabled):active~.custom-control-label::before{
  color:#fff;
  background-color:#6ff4ff;
  border-color:#6ff4ff
}

.custom-control-input:disabled~.custom-control-label{
  color:#6c757d
}

.custom-control-input:disabled~.custom-control-label::before{
  background-color:#e9ecef
}

.custom-control-label{
  position:relative;
  margin-bottom:0;
  vertical-align:top
}

.custom-control-label::before{
  position:absolute;
  top:.25rem;
  left:-1.5rem;
  display:block;
  width:1rem;
  height:1rem;
  pointer-events:none;
  content:"";
  background-color:#fff;
  border:#adb5bd solid 1px
}

.custom-control-label::after{
  position:absolute;
  top:.25rem;
  left:-1.5rem;
  display:block;
  width:1rem;
  height:1rem;
  content:"";
  background:no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before{
  border-radius:.25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before{
  border-color:#00adbb;
  background-color:#00adbb
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color:rgba(0,173,187,.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before{
  background-color:rgba(0,173,187,.5)
}

.custom-radio .custom-control-label::before{
  border-radius:50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color:rgba(0,173,187,.5)
}

.custom-switch{
  padding-left:2.25rem
}

.custom-switch .custom-control-label::before{
  left:-2.25rem;
  width:1.75rem;
  pointer-events:all;
  border-radius:.5rem
}

.custom-switch .custom-control-label::after{
  top:calc(0.25rem + 2px);
  left:calc(-2.25rem + 2px);
  width:calc(1rem - 4px);
  height:calc(1rem - 4px);
  background-color:#adb5bd;
  border-radius:.5rem;
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition:transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out
}

@media(prefers-reduced-motion: reduce){
  .custom-switch .custom-control-label::after{
    -webkit-transition:none;
    transition:none
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after{
  background-color:#fff;
  -webkit-transform:translateX(0.75rem);
          transform:translateX(0.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before{
  background-color:rgba(0,173,187,.5)
}

.custom-select{
  display:inline-block;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  padding:.375rem 1.75rem .375rem .75rem;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  vertical-align:middle;
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  background-color:#fff;
  border:1px solid #ced4da;
  border-radius:.25rem;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}

.custom-select:focus{
  border-color:#3cf0ff;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.25)
}

.custom-select:focus::-ms-value{
  color:#495057;
  background-color:#fff
}

.custom-select[multiple],.custom-select[size]:not([size="1"]){
  height:auto;
  padding-right:.75rem;
  background-image:none
}

.custom-select:disabled{
  color:#6c757d;
  background-color:#e9ecef
}

.custom-select::-ms-expand{
  display:none
}

.custom-select-sm{
  height:calc(1.5em + 0.5rem + 2px);
  padding-top:.25rem;
  padding-bottom:.25rem;
  padding-left:.5rem;
  font-size:0.875rem
}

.custom-select-lg{
  height:calc(1.5em + 1rem + 2px);
  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-left:1rem;
  font-size:1.25rem
}

.custom-file{
  position:relative;
  display:inline-block;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  margin-bottom:0
}

.custom-file-input{
  position:relative;
  z-index:2;
  width:100%;
  height:calc(1.5em + 0.75rem + 2px);
  margin:0;
  opacity:0
}

.custom-file-input:focus~.custom-file-label{
  border-color:#3cf0ff;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.25)
}

.custom-file-input:disabled~.custom-file-label{
  background-color:#e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after{
  content:"Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after{
  content:attr(data-browse)
}

.custom-file-label{
  position:absolute;
  top:0;
  right:0;
  left:0;
  z-index:1;
  height:calc(1.5em + 0.75rem + 2px);
  padding:.375rem .75rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  background-color:#fff;
  border:1px solid #ced4da;
  border-radius:.25rem
}

.custom-file-label::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  z-index:3;
  display:block;
  height:calc(1.5em + 0.75rem);
  padding:.375rem .75rem;
  line-height:1.5;
  color:#495057;
  content:"Browse";
  background-color:#e9ecef;
  border-left:inherit;
  border-radius:0 .25rem .25rem 0
}

.custom-range{
  width:100%;
  height:calc(1rem + 0.4rem);
  padding:0;
  background-color:transparent;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}

.custom-range:focus{
  outline:none
}

.custom-range:focus::-webkit-slider-thumb{
  box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(0,173,187,.25)
}

.custom-range:focus::-moz-range-thumb{
  box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(0,173,187,.25)
}

.custom-range:focus::-ms-thumb{
  box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(0,173,187,.25)
}

.custom-range::-moz-focus-outer{
  border:0
}

.custom-range::-webkit-slider-thumb{
  width:1rem;
  height:1rem;
  margin-top:-0.25rem;
  background-color:#00adbb;
  border:0;
  border-radius:1rem;
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance:none;
          appearance:none
}

@media(prefers-reduced-motion: reduce){
  .custom-range::-webkit-slider-thumb{
    -webkit-transition:none;
    transition:none
  }
}

.custom-range::-webkit-slider-thumb:active{
  background-color:#6ff4ff
}

.custom-range::-webkit-slider-runnable-track{
  width:100%;
  height:.5rem;
  color:transparent;
  cursor:pointer;
  background-color:#dee2e6;
  border-color:transparent;
  border-radius:1rem
}

.custom-range::-moz-range-thumb{
  width:1rem;
  height:1rem;
  background-color:#00adbb;
  border:0;
  border-radius:1rem;
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-appearance:none;
       appearance:none
}

@media(prefers-reduced-motion: reduce){
  .custom-range::-moz-range-thumb{
    -webkit-transition:none;
    transition:none
  }
}

.custom-range::-moz-range-thumb:active{
  background-color:#6ff4ff
}

.custom-range::-moz-range-track{
  width:100%;
  height:.5rem;
  color:transparent;
  cursor:pointer;
  background-color:#dee2e6;
  border-color:transparent;
  border-radius:1rem
}

.custom-range::-ms-thumb{
  width:1rem;
  height:1rem;
  margin-top:0;
  margin-right:.2rem;
  margin-left:.2rem;
  background-color:#00adbb;
  border:0;
  border-radius:1rem;
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance:none
}

@media(prefers-reduced-motion: reduce){
  .custom-range::-ms-thumb{
    -webkit-transition:none;
    transition:none
  }
}

.custom-range::-ms-thumb:active{
  background-color:#6ff4ff
}

.custom-range::-ms-track{
  width:100%;
  height:.5rem;
  color:transparent;
  cursor:pointer;
  background-color:transparent;
  border-color:transparent;
  border-width:.5rem
}

.custom-range::-ms-fill-lower{
  background-color:#dee2e6;
  border-radius:1rem
}

.custom-range::-ms-fill-upper{
  margin-right:15px;
  background-color:#dee2e6;
  border-radius:1rem
}

.custom-range:disabled::-webkit-slider-thumb{
  background-color:#adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track{
  cursor:default
}

.custom-range:disabled::-moz-range-thumb{
  background-color:#adb5bd
}

.custom-range:disabled::-moz-range-track{
  cursor:default
}

.custom-range:disabled::-ms-thumb{
  background-color:#adb5bd
}

.custom-control-label::before,.custom-file-label,.custom-select{
  -webkit-transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce){
  .custom-control-label::before,.custom-file-label,.custom-select{
    -webkit-transition:none;
    transition:none
  }
}

.nav{
  display:flex;
  flex-wrap:wrap;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}

.nav-link{
  display:block;
  padding:.5rem 1rem
}

.nav-link:hover,.nav-link:focus{
  text-decoration:none
}

.nav-link.disabled{
  color:#6c757d;
  pointer-events:none;
  cursor:default
}

.nav-tabs{
  border-bottom:1px solid #dee2e6
}

.nav-tabs .nav-item{
  margin-bottom:-1px
}

.nav-tabs .nav-link{
  border:1px solid transparent;
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}

.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus{
  border-color:#e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled{
  color:#6c757d;
  background-color:transparent;
  border-color:transparent
}

.nav-tabs .nav-link.active,.nav-tabs .nav-item.show .nav-link{
  color:#495057;
  background-color:#fff;
  border-color:#dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu{
  margin-top:-1px;
  border-top-left-radius:0;
  border-top-right-radius:0
}

.nav-pills .nav-link{
  border-radius:.25rem
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link{
  color:#fff;
  background-color:#00adbb
}

.nav-fill .nav-item{
  flex:1 1 auto;
  text-align:center
}

.nav-justified .nav-item{
  flex-basis:0;
  flex-grow:1;
  text-align:center
}

.tab-content>.tab-pane{
  display:none
}

.tab-content>.active{
  display:block
}

.navbar{
  position:relative;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between;
  padding:.5rem 1rem
}

.navbar>.container,.navbar>.container-fluid{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between
}

.navbar-brand{
  display:inline-block;
  padding-top:.3125rem;
  padding-bottom:.3125rem;
  margin-right:1rem;
  font-size:1.25rem;
  line-height:inherit;
  white-space:nowrap
}

.navbar-brand:hover,.navbar-brand:focus{
  text-decoration:none
}

.navbar-nav{
  display:flex;
  flex-direction:column;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}

.navbar-nav .nav-link{
  padding-right:0;
  padding-left:0
}

.navbar-nav .dropdown-menu{
  position:static;
  float:none
}

.navbar-text{
  display:inline-block;
  padding-top:.5rem;
  padding-bottom:.5rem
}

.navbar-collapse{
  flex-basis:100%;
  flex-grow:1;
  align-items:center
}

.navbar-toggler{
  padding:.25rem .75rem;
  font-size:1.25rem;
  line-height:1;
  background-color:transparent;
  border:1px solid transparent;
  border-radius:.25rem
}

.navbar-toggler:hover,.navbar-toggler:focus{
  text-decoration:none
}

.navbar-toggler-icon{
  display:inline-block;
  width:1.5em;
  height:1.5em;
  vertical-align:middle;
  content:"";
  background:no-repeat center center;
  background-size:100% 100%
}

@media(max-width: 639.98px){
  .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid{
    padding-right:0;
    padding-left:0
  }
}

@media(min-width: 640px){
  .navbar-expand-sm{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-sm .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid{
    flex-wrap:nowrap
  }

  .navbar-expand-sm .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-sm .navbar-toggler{
    display:none
  }
}

@media(max-width: 767.98px){
  .navbar-expand-md>.container,.navbar-expand-md>.container-fluid{
    padding-right:0;
    padding-left:0
  }
}

@media(min-width: 768px){
  .navbar-expand-md{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-md .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-md .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-md>.container,.navbar-expand-md>.container-fluid{
    flex-wrap:nowrap
  }

  .navbar-expand-md .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-md .navbar-toggler{
    display:none
  }
}

@media(max-width: 1023.98px){
  .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid{
    padding-right:0;
    padding-left:0
  }
}

@media(min-width: 1024px){
  .navbar-expand-lg{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-lg .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid{
    flex-wrap:nowrap
  }

  .navbar-expand-lg .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-lg .navbar-toggler{
    display:none
  }
}

@media(max-width: 1279.98px){
  .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid{
    padding-right:0;
    padding-left:0
  }
}

@media(min-width: 1280px){
  .navbar-expand-xl{
    flex-flow:row nowrap;
    justify-content:flex-start
  }

  .navbar-expand-xl .navbar-nav{
    flex-direction:row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu{
    position:absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link{
    padding-right:.5rem;
    padding-left:.5rem
  }

  .navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid{
    flex-wrap:nowrap
  }

  .navbar-expand-xl .navbar-collapse{
    display:flex !important;
    flex-basis:auto
  }

  .navbar-expand-xl .navbar-toggler{
    display:none
  }
}

.navbar-expand{
  flex-flow:row nowrap;
  justify-content:flex-start
}

.navbar-expand>.container,.navbar-expand>.container-fluid{
  padding-right:0;
  padding-left:0
}

.navbar-expand .navbar-nav{
  flex-direction:row
}

.navbar-expand .navbar-nav .dropdown-menu{
  position:absolute
}

.navbar-expand .navbar-nav .nav-link{
  padding-right:.5rem;
  padding-left:.5rem
}

.navbar-expand>.container,.navbar-expand>.container-fluid{
  flex-wrap:nowrap
}

.navbar-expand .navbar-collapse{
  display:flex !important;
  flex-basis:auto
}

.navbar-expand .navbar-toggler{
  display:none
}

.navbar-light .navbar-brand{
  color:rgba(0,0,0,.9)
}

.navbar-light .navbar-brand:hover,.navbar-light .navbar-brand:focus{
  color:rgba(0,0,0,.9)
}

.navbar-light .navbar-nav .nav-link{
  color:rgba(0,0,0,.5)
}

.navbar-light .navbar-nav .nav-link:hover,.navbar-light .navbar-nav .nav-link:focus{
  color:rgba(0,0,0,.7)
}

.navbar-light .navbar-nav .nav-link.disabled{
  color:rgba(0,0,0,.3)
}

.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .nav-link.active{
  color:rgba(0,0,0,.9)
}

.navbar-light .navbar-toggler{
  color:rgba(0,0,0,.5);
  border-color:rgba(0,0,0,.1)
}

.navbar-light .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text{
  color:rgba(0,0,0,.5)
}

.navbar-light .navbar-text a{
  color:rgba(0,0,0,.9)
}

.navbar-light .navbar-text a:hover,.navbar-light .navbar-text a:focus{
  color:rgba(0,0,0,.9)
}

.navbar-dark .navbar-brand{
  color:#fff
}

.navbar-dark .navbar-brand:hover,.navbar-dark .navbar-brand:focus{
  color:#fff
}

.navbar-dark .navbar-nav .nav-link{
  color:rgba(255,255,255,.5)
}

.navbar-dark .navbar-nav .nav-link:hover,.navbar-dark .navbar-nav .nav-link:focus{
  color:rgba(255,255,255,.75)
}

.navbar-dark .navbar-nav .nav-link.disabled{
  color:rgba(255,255,255,.25)
}

.navbar-dark .navbar-nav .show>.nav-link,.navbar-dark .navbar-nav .active>.nav-link,.navbar-dark .navbar-nav .nav-link.show,.navbar-dark .navbar-nav .nav-link.active{
  color:#fff
}

.navbar-dark .navbar-toggler{
  color:rgba(255,255,255,.5);
  border-color:rgba(255,255,255,.1)
}

.navbar-dark .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text{
  color:rgba(255,255,255,.5)
}

.navbar-dark .navbar-text a{
  color:#fff
}

.navbar-dark .navbar-text a:hover,.navbar-dark .navbar-text a:focus{
  color:#fff
}

.card{
  position:relative;
  display:flex;
  flex-direction:column;
  min-width:0;
  word-wrap:break-word;
  background-color:#fff;
  background-clip:border-box;
  border:1px solid rgba(0,0,0,.125);
  border-radius:.25rem
}

.card>hr{
  margin-right:0;
  margin-left:0
}

.card>.list-group:first-child .list-group-item:first-child{
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}

.card>.list-group:last-child .list-group-item:last-child{
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:.25rem
}

.card-body{
  flex:1 1 auto;
  padding:1.25rem
}

.card-title{
  margin-bottom:.75rem
}

.card-subtitle{
  margin-top:-0.375rem;
  margin-bottom:0
}

.card-text:last-child{
  margin-bottom:0
}

.card-link:hover{
  text-decoration:none
}

.card-link+.card-link{
  margin-left:1.25rem
}

.card-header{
  padding:.75rem 1.25rem;
  margin-bottom:0;
  background-color:rgba(0,0,0,.03);
  border-bottom:1px solid rgba(0,0,0,.125)
}

.card-header:first-child{
  border-radius:calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child{
  border-top:0
}

.card-footer{
  padding:.75rem 1.25rem;
  background-color:rgba(0,0,0,.03);
  border-top:1px solid rgba(0,0,0,.125)
}

.card-footer:last-child{
  border-radius:0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}

.card-header-tabs{
  margin-right:-0.625rem;
  margin-bottom:-0.75rem;
  margin-left:-0.625rem;
  border-bottom:0
}

.card-header-pills{
  margin-right:-0.625rem;
  margin-left:-0.625rem
}

.card-img-overlay{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  padding:1.25rem
}

.card-img{
  width:100%;
  border-radius:calc(0.25rem - 1px)
}

.card-img-top{
  width:100%;
  border-top-left-radius:calc(0.25rem - 1px);
  border-top-right-radius:calc(0.25rem - 1px)
}

.card-img-bottom{
  width:100%;
  border-bottom-right-radius:calc(0.25rem - 1px);
  border-bottom-left-radius:calc(0.25rem - 1px)
}

.card-deck{
  display:flex;
  flex-direction:column
}

.card-deck .card{
  margin-bottom:15px
}

@media(min-width: 640px){
  .card-deck{
    flex-flow:row wrap;
    margin-right:-15px;
    margin-left:-15px
  }

  .card-deck .card{
    display:flex;
    flex:1 0;
    flex-direction:column;
    margin-right:15px;
    margin-bottom:0;
    margin-left:15px
  }
}

.card-group{
  display:flex;
  flex-direction:column
}

.card-group>.card{
  margin-bottom:15px
}

@media(min-width: 640px){
  .card-group{
    flex-flow:row wrap
  }

  .card-group>.card{
    flex:1 0;
    margin-bottom:0
  }

  .card-group>.card+.card{
    margin-left:0;
    border-left:0
  }

  .card-group>.card:not(:last-child){
    border-top-right-radius:0;
    border-bottom-right-radius:0
  }

  .card-group>.card:not(:last-child) .card-img-top,.card-group>.card:not(:last-child) .card-header{
    border-top-right-radius:0
  }

  .card-group>.card:not(:last-child) .card-img-bottom,.card-group>.card:not(:last-child) .card-footer{
    border-bottom-right-radius:0
  }

  .card-group>.card:not(:first-child){
    border-top-left-radius:0;
    border-bottom-left-radius:0
  }

  .card-group>.card:not(:first-child) .card-img-top,.card-group>.card:not(:first-child) .card-header{
    border-top-left-radius:0
  }

  .card-group>.card:not(:first-child) .card-img-bottom,.card-group>.card:not(:first-child) .card-footer{
    border-bottom-left-radius:0
  }
}

.card-columns .card{
  margin-bottom:.75rem
}

@media(min-width: 640px){
  .card-columns{
    -webkit-column-count:3;
       -moz-column-count:3;
            column-count:3;
    grid-column-gap:1.25rem;
    -webkit-column-gap:1.25rem;
       -moz-column-gap:1.25rem;
            column-gap:1.25rem;
    orphans:1;
    widows:1
  }

  .card-columns .card{
    display:inline-block;
    width:100%
  }
}

.accordion>.card{
  overflow:hidden
}

.accordion>.card:not(:first-of-type) .card-header:first-child{
  border-radius:0
}

.accordion>.card:not(:first-of-type):not(:last-of-type){
  border-bottom:0;
  border-radius:0
}

.accordion>.card:first-of-type{
  border-bottom:0;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}

.accordion>.card:last-of-type{
  border-top-left-radius:0;
  border-top-right-radius:0
}

.accordion>.card .card-header{
  margin-bottom:-1px
}

.breadcrumb{
  display:flex;
  flex-wrap:wrap;
  padding:.75rem 1rem;
  margin-bottom:1rem;
  list-style:none;
  background-color:#e9ecef;
  border-radius:.25rem
}

.breadcrumb-item+.breadcrumb-item{
  padding-left:.5rem
}

.breadcrumb-item+.breadcrumb-item::before{
  display:inline-block;
  padding-right:.5rem;
  color:#6c757d;
  content:"/"
}

.breadcrumb-item+.breadcrumb-item:hover::before{
  text-decoration:underline
}

.breadcrumb-item+.breadcrumb-item:hover::before{
  text-decoration:none
}

.breadcrumb-item.active{
  color:#6c757d
}

.pagination{
  display:flex;
  padding-left:0;
  list-style:none;
  border-radius:.25rem
}

.page-link{
  position:relative;
  display:block;
  padding:.5rem .75rem;
  margin-left:-1px;
  line-height:1.25;
  color:#00adbb;
  background-color:#fff;
  border:1px solid #dee2e6
}

.page-link:hover{
  z-index:2;
  color:#00666f;
  text-decoration:none;
  background-color:#e9ecef;
  border-color:#dee2e6
}

.page-link:focus{
  z-index:2;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.25)
}

.page-item:first-child .page-link{
  margin-left:0;
  border-top-left-radius:.25rem;
  border-bottom-left-radius:.25rem
}

.page-item:last-child .page-link{
  border-top-right-radius:.25rem;
  border-bottom-right-radius:.25rem
}

.page-item.active .page-link{
  z-index:1;
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.page-item.disabled .page-link{
  color:#6c757d;
  pointer-events:none;
  cursor:auto;
  background-color:#fff;
  border-color:#dee2e6
}

.pagination-lg .page-link{
  padding:.75rem 1.5rem;
  font-size:1.25rem;
  line-height:1.5
}

.pagination-lg .page-item:first-child .page-link{
  border-top-left-radius:.3rem;
  border-bottom-left-radius:.3rem
}

.pagination-lg .page-item:last-child .page-link{
  border-top-right-radius:.3rem;
  border-bottom-right-radius:.3rem
}

.pagination-sm .page-link{
  padding:.25rem .5rem;
  font-size:0.875rem;
  line-height:1.5
}

.pagination-sm .page-item:first-child .page-link{
  border-top-left-radius:.2rem;
  border-bottom-left-radius:.2rem
}

.pagination-sm .page-item:last-child .page-link{
  border-top-right-radius:.2rem;
  border-bottom-right-radius:.2rem
}

.badge{
  display:inline-block;
  padding:.25em .4em;
  font-size:75%;
  font-weight:700;
  line-height:1;
  text-align:center;
  white-space:nowrap;
  vertical-align:baseline;
  border-radius:.25rem;
  -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce){
  .badge{
    -webkit-transition:none;
    transition:none
  }
}

a.badge:hover,a.badge:focus{
  text-decoration:none
}

.badge:empty{
  display:none
}

.btn .badge{
  position:relative;
  top:-1px
}

.badge-pill{
  padding-right:.6em;
  padding-left:.6em;
  border-radius:10rem
}

.badge-primary{
  color:#fff;
  background-color:#00adbb
}

a.badge-primary:hover,a.badge-primary:focus{
  color:#fff;
  background-color:#007e88
}

a.badge-primary:focus,a.badge-primary.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.5)
}

.badge-secondary{
  color:#fff;
  background-color:#6c757d
}

a.badge-secondary:hover,a.badge-secondary:focus{
  color:#fff;
  background-color:#545b62
}

a.badge-secondary:focus,a.badge-secondary.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(108,117,125,.5)
}

.badge-success{
  color:#fff;
  background-color:#28a745
}

a.badge-success:hover,a.badge-success:focus{
  color:#fff;
  background-color:#1e7e34
}

a.badge-success:focus,a.badge-success.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(40,167,69,.5)
}

.badge-info{
  color:#fff;
  background-color:#17a2b8
}

a.badge-info:hover,a.badge-info:focus{
  color:#fff;
  background-color:#117a8b
}

a.badge-info:focus,a.badge-info.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(23,162,184,.5)
}

.badge-warning{
  color:#212529;
  background-color:#ffc107
}

a.badge-warning:hover,a.badge-warning:focus{
  color:#212529;
  background-color:#d39e00
}

a.badge-warning:focus,a.badge-warning.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}

.badge-danger{
  color:#fff;
  background-color:#dc3545
}

a.badge-danger:hover,a.badge-danger:focus{
  color:#fff;
  background-color:#bd2130
}

a.badge-danger:focus,a.badge-danger.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5)
}

.badge-light{
  color:#212529;
  background-color:#f8f9fa
}

a.badge-light:hover,a.badge-light:focus{
  color:#212529;
  background-color:#dae0e5
}

a.badge-light:focus,a.badge-light.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(248,249,250,.5)
}

.badge-dark{
  color:#fff;
  background-color:#343a40
}

a.badge-dark:hover,a.badge-dark:focus{
  color:#fff;
  background-color:#1d2124
}

a.badge-dark:focus,a.badge-dark.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(52,58,64,.5)
}

.badge-tlg-green{
  color:#212529;
  background-color:#a4ca61
}

a.badge-tlg-green:hover,a.badge-tlg-green:focus{
  color:#212529;
  background-color:#8dba3e
}

a.badge-tlg-green:focus,a.badge-tlg-green.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(164,202,97,.5)
}

.badge-tlg-teal{
  color:#fff;
  background-color:#00adbb
}

a.badge-tlg-teal:hover,a.badge-tlg-teal:focus{
  color:#fff;
  background-color:#007e88
}

a.badge-tlg-teal:focus,a.badge-tlg-teal.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(0,173,187,.5)
}

.badge-tlg-purple{
  color:#fff;
  background-color:#685bc7
}

a.badge-tlg-purple:hover,a.badge-tlg-purple:focus{
  color:#fff;
  background-color:#4b3db2
}

a.badge-tlg-purple:focus,a.badge-tlg-purple.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(104,91,199,.5)
}

.badge-tlg-purple-light{
  color:#fff;
  background-color:#af7cb6
}

a.badge-tlg-purple-light:hover,a.badge-tlg-purple-light:focus{
  color:#fff;
  background-color:#9b5ba4
}

a.badge-tlg-purple-light:focus,a.badge-tlg-purple-light.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(175,124,182,.5)
}

.badge-tlg-orange{
  color:#fff;
  background-color:#e87722
}

a.badge-tlg-orange:hover,a.badge-tlg-orange:focus{
  color:#fff;
  background-color:#c35f14
}

a.badge-tlg-orange:focus,a.badge-tlg-orange.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(232,119,34,.5)
}

.badge-tlg-orange-light{
  color:#212529;
  background-color:#f6c7a4
}

a.badge-tlg-orange-light:hover,a.badge-tlg-orange-light:focus{
  color:#212529;
  background-color:#f1aa76
}

a.badge-tlg-orange-light:focus,a.badge-tlg-orange-light.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(246,199,164,.5)
}

.badge-tlg-orange-500{
  color:#212529;
  background-color:#ff7f41
}

a.badge-tlg-orange-500:hover,a.badge-tlg-orange-500:focus{
  color:#212529;
  background-color:#ff5d0e
}

a.badge-tlg-orange-500:focus,a.badge-tlg-orange-500.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(255,127,65,.5)
}

.badge-tlg-green-dark{
  color:#212529;
  background-color:#76b84e
}

a.badge-tlg-green-dark:hover,a.badge-tlg-green-dark:focus{
  color:#212529;
  background-color:#5e973c
}

a.badge-tlg-green-dark:focus,a.badge-tlg-green-dark.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(118,184,78,.5)
}

.jumbotron{
  padding:2rem 1rem;
  margin-bottom:2rem;
  background-color:#e9ecef;
  border-radius:.3rem
}

@media(min-width: 640px){
  .jumbotron{
    padding:4rem 2rem
  }
}

.jumbotron-fluid{
  padding-right:0;
  padding-left:0;
  border-radius:0
}

.alert{
  position:relative;
  padding:.75rem 1.25rem;
  margin-bottom:1rem;
  border:1px solid transparent;
  border-radius:.25rem
}

.alert-heading{
  color:inherit
}

.alert-link{
  font-weight:700
}

.alert-dismissible{
  padding-right:4rem
}

.alert-dismissible .close{
  position:absolute;
  top:0;
  right:0;
  padding:.75rem 1.25rem;
  color:inherit
}

.alert-primary{
  color:#005a61;
  background-color:#cceff1;
  border-color:#b8e8ec
}

.alert-primary hr{
  border-top-color:#a4e1e7
}

.alert-primary .alert-link{
  color:#002b2e
}

.alert-secondary{
  color:#383d41;
  background-color:#e2e3e5;
  border-color:#d6d8db
}

.alert-secondary hr{
  border-top-color:#c8cbcf
}

.alert-secondary .alert-link{
  color:#202326
}

.alert-success{
  color:#155724;
  background-color:#d4edda;
  border-color:#c3e6cb
}

.alert-success hr{
  border-top-color:#b1dfbb
}

.alert-success .alert-link{
  color:#0b2e13
}

.alert-info{
  color:#0c5460;
  background-color:#d1ecf1;
  border-color:#bee5eb
}

.alert-info hr{
  border-top-color:#abdde5
}

.alert-info .alert-link{
  color:#062c33
}

.alert-warning{
  color:#856404;
  background-color:#fff3cd;
  border-color:#ffeeba
}

.alert-warning hr{
  border-top-color:#ffe8a1
}

.alert-warning .alert-link{
  color:#533f03
}

.alert-danger{
  color:#721c24;
  background-color:#f8d7da;
  border-color:#f5c6cb
}

.alert-danger hr{
  border-top-color:#f1b0b7
}

.alert-danger .alert-link{
  color:#491217
}

.alert-light{
  color:#818182;
  background-color:#fefefe;
  border-color:#fdfdfe
}

.alert-light hr{
  border-top-color:#ececf6
}

.alert-light .alert-link{
  color:#686868
}

.alert-dark{
  color:#1b1e21;
  background-color:#d6d8d9;
  border-color:#c6c8ca
}

.alert-dark hr{
  border-top-color:#b9bbbe
}

.alert-dark .alert-link{
  color:#040505
}

.alert-tlg-green{
  color:#556932;
  background-color:#edf4df;
  border-color:#e6f0d3
}

.alert-tlg-green hr{
  border-top-color:#dbeac0
}

.alert-tlg-green .alert-link{
  color:#394622
}

.alert-tlg-teal{
  color:#005a61;
  background-color:#cceff1;
  border-color:#b8e8ec
}

.alert-tlg-teal hr{
  border-top-color:#a4e1e7
}

.alert-tlg-teal .alert-link{
  color:#002b2e
}

.alert-tlg-purple{
  color:#362f67;
  background-color:#e1def4;
  border-color:#d5d1ef
}

.alert-tlg-purple hr{
  border-top-color:#c4bee8
}

.alert-tlg-purple .alert-link{
  color:#241f44
}

.alert-tlg-purple-light{
  color:#5b405f;
  background-color:#efe5f0;
  border-color:#e9daeb
}

.alert-tlg-purple-light hr{
  border-top-color:#dfc9e2
}

.alert-tlg-purple-light .alert-link{
  color:#3e2b41
}

.alert-tlg-orange{
  color:#793e12;
  background-color:#fae4d3;
  border-color:#f9d9c1
}

.alert-tlg-orange hr{
  border-top-color:#f7cbaa
}

.alert-tlg-orange .alert-link{
  color:#4d270b
}

.alert-tlg-orange-light{
  color:#806755;
  background-color:#fdf4ed;
  border-color:#fcefe6
}

.alert-tlg-orange-light hr{
  border-top-color:#f9e0cf
}

.alert-tlg-orange-light .alert-link{
  color:#614e41
}

.alert-tlg-orange-500{
  color:#854222;
  background-color:#ffe5d9;
  border-color:#ffdbca
}

.alert-tlg-orange-500 hr{
  border-top-color:#ffcab1
}

.alert-tlg-orange-500 .alert-link{
  color:#5c2e18
}

.alert-tlg-green-dark{
  color:#3d6029;
  background-color:#e4f1dc;
  border-color:#d9ebcd
}

.alert-tlg-green-dark hr{
  border-top-color:#cbe4bb
}

.alert-tlg-green-dark .alert-link{
  color:#263c1a
}

@-webkit-keyframes progress-bar-stripes{
  from{
    background-position:1rem 0
  }

  to{
    background-position:0 0
  }
}

@keyframes progress-bar-stripes{
  from{
    background-position:1rem 0
  }

  to{
    background-position:0 0
  }
}

.progress{
  display:flex;
  height:1rem;
  overflow:hidden;
  font-size:0.75rem;
  background-color:#e9ecef;
  border-radius:.25rem
}

.progress-bar{
  display:flex;
  flex-direction:column;
  justify-content:center;
  color:#fff;
  text-align:center;
  white-space:nowrap;
  background-color:#00adbb;
  -webkit-transition:width .6s ease;
  transition:width .6s ease
}

@media(prefers-reduced-motion: reduce){
  .progress-bar{
    -webkit-transition:none;
    transition:none
  }
}

.progress-bar-striped{
  background-image:-webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image:linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size:1rem 1rem
}

.progress-bar-animated{
  -webkit-animation:progress-bar-stripes 1s linear infinite;
          animation:progress-bar-stripes 1s linear infinite
}

@media(prefers-reduced-motion: reduce){
  .progress-bar-animated{
    -webkit-animation:none;
            animation:none
  }
}

.media{
  display:flex;
  align-items:flex-start
}

.media-body{
  flex:1 1
}

.list-group{
  display:flex;
  flex-direction:column;
  padding-left:0;
  margin-bottom:0
}

.list-group-item-action{
  width:100%;
  color:#495057;
  text-align:inherit
}

.list-group-item-action:hover,.list-group-item-action:focus{
  z-index:1;
  color:#495057;
  text-decoration:none;
  background-color:#f8f9fa
}

.list-group-item-action:active{
  color:#212529;
  background-color:#e9ecef
}

.list-group-item{
  position:relative;
  display:block;
  padding:.75rem 1.25rem;
  margin-bottom:-1px;
  background-color:#fff;
  border:1px solid rgba(0,0,0,.125)
}

.list-group-item:first-child{
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem
}

.list-group-item:last-child{
  margin-bottom:0;
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:.25rem
}

.list-group-item.disabled,.list-group-item:disabled{
  color:#6c757d;
  pointer-events:none;
  background-color:#fff
}

.list-group-item.active{
  z-index:2;
  color:#fff;
  background-color:#00adbb;
  border-color:#00adbb
}

.list-group-horizontal{
  flex-direction:row
}

.list-group-horizontal .list-group-item{
  margin-right:-1px;
  margin-bottom:0
}

.list-group-horizontal .list-group-item:first-child{
  border-top-left-radius:.25rem;
  border-bottom-left-radius:.25rem;
  border-top-right-radius:0
}

.list-group-horizontal .list-group-item:last-child{
  margin-right:0;
  border-top-right-radius:.25rem;
  border-bottom-right-radius:.25rem;
  border-bottom-left-radius:0
}

@media(min-width: 640px){
  .list-group-horizontal-sm{
    flex-direction:row
  }

  .list-group-horizontal-sm .list-group-item{
    margin-right:-1px;
    margin-bottom:0
  }

  .list-group-horizontal-sm .list-group-item:first-child{
    border-top-left-radius:.25rem;
    border-bottom-left-radius:.25rem;
    border-top-right-radius:0
  }

  .list-group-horizontal-sm .list-group-item:last-child{
    margin-right:0;
    border-top-right-radius:.25rem;
    border-bottom-right-radius:.25rem;
    border-bottom-left-radius:0
  }
}

@media(min-width: 768px){
  .list-group-horizontal-md{
    flex-direction:row
  }

  .list-group-horizontal-md .list-group-item{
    margin-right:-1px;
    margin-bottom:0
  }

  .list-group-horizontal-md .list-group-item:first-child{
    border-top-left-radius:.25rem;
    border-bottom-left-radius:.25rem;
    border-top-right-radius:0
  }

  .list-group-horizontal-md .list-group-item:last-child{
    margin-right:0;
    border-top-right-radius:.25rem;
    border-bottom-right-radius:.25rem;
    border-bottom-left-radius:0
  }
}

@media(min-width: 1024px){
  .list-group-horizontal-lg{
    flex-direction:row
  }

  .list-group-horizontal-lg .list-group-item{
    margin-right:-1px;
    margin-bottom:0
  }

  .list-group-horizontal-lg .list-group-item:first-child{
    border-top-left-radius:.25rem;
    border-bottom-left-radius:.25rem;
    border-top-right-radius:0
  }

  .list-group-horizontal-lg .list-group-item:last-child{
    margin-right:0;
    border-top-right-radius:.25rem;
    border-bottom-right-radius:.25rem;
    border-bottom-left-radius:0
  }
}

@media(min-width: 1280px){
  .list-group-horizontal-xl{
    flex-direction:row
  }

  .list-group-horizontal-xl .list-group-item{
    margin-right:-1px;
    margin-bottom:0
  }

  .list-group-horizontal-xl .list-group-item:first-child{
    border-top-left-radius:.25rem;
    border-bottom-left-radius:.25rem;
    border-top-right-radius:0
  }

  .list-group-horizontal-xl .list-group-item:last-child{
    margin-right:0;
    border-top-right-radius:.25rem;
    border-bottom-right-radius:.25rem;
    border-bottom-left-radius:0
  }
}

.list-group-flush .list-group-item{
  border-right:0;
  border-left:0;
  border-radius:0
}

.list-group-flush .list-group-item:last-child{
  margin-bottom:-1px
}

.list-group-flush:first-child .list-group-item:first-child{
  border-top:0
}

.list-group-flush:last-child .list-group-item:last-child{
  margin-bottom:0;
  border-bottom:0
}

.list-group-item-primary{
  color:#005a61;
  background-color:#b8e8ec
}

.list-group-item-primary.list-group-item-action:hover,.list-group-item-primary.list-group-item-action:focus{
  color:#005a61;
  background-color:#a4e1e7
}

.list-group-item-primary.list-group-item-action.active{
  color:#fff;
  background-color:#005a61;
  border-color:#005a61
}

.list-group-item-secondary{
  color:#383d41;
  background-color:#d6d8db
}

.list-group-item-secondary.list-group-item-action:hover,.list-group-item-secondary.list-group-item-action:focus{
  color:#383d41;
  background-color:#c8cbcf
}

.list-group-item-secondary.list-group-item-action.active{
  color:#fff;
  background-color:#383d41;
  border-color:#383d41
}

.list-group-item-success{
  color:#155724;
  background-color:#c3e6cb
}

.list-group-item-success.list-group-item-action:hover,.list-group-item-success.list-group-item-action:focus{
  color:#155724;
  background-color:#b1dfbb
}

.list-group-item-success.list-group-item-action.active{
  color:#fff;
  background-color:#155724;
  border-color:#155724
}

.list-group-item-info{
  color:#0c5460;
  background-color:#bee5eb
}

.list-group-item-info.list-group-item-action:hover,.list-group-item-info.list-group-item-action:focus{
  color:#0c5460;
  background-color:#abdde5
}

.list-group-item-info.list-group-item-action.active{
  color:#fff;
  background-color:#0c5460;
  border-color:#0c5460
}

.list-group-item-warning{
  color:#856404;
  background-color:#ffeeba
}

.list-group-item-warning.list-group-item-action:hover,.list-group-item-warning.list-group-item-action:focus{
  color:#856404;
  background-color:#ffe8a1
}

.list-group-item-warning.list-group-item-action.active{
  color:#fff;
  background-color:#856404;
  border-color:#856404
}

.list-group-item-danger{
  color:#721c24;
  background-color:#f5c6cb
}

.list-group-item-danger.list-group-item-action:hover,.list-group-item-danger.list-group-item-action:focus{
  color:#721c24;
  background-color:#f1b0b7
}

.list-group-item-danger.list-group-item-action.active{
  color:#fff;
  background-color:#721c24;
  border-color:#721c24
}

.list-group-item-light{
  color:#818182;
  background-color:#fdfdfe
}

.list-group-item-light.list-group-item-action:hover,.list-group-item-light.list-group-item-action:focus{
  color:#818182;
  background-color:#ececf6
}

.list-group-item-light.list-group-item-action.active{
  color:#fff;
  background-color:#818182;
  border-color:#818182
}

.list-group-item-dark{
  color:#1b1e21;
  background-color:#c6c8ca
}

.list-group-item-dark.list-group-item-action:hover,.list-group-item-dark.list-group-item-action:focus{
  color:#1b1e21;
  background-color:#b9bbbe
}

.list-group-item-dark.list-group-item-action.active{
  color:#fff;
  background-color:#1b1e21;
  border-color:#1b1e21
}

.list-group-item-tlg-green{
  color:#556932;
  background-color:#e6f0d3
}

.list-group-item-tlg-green.list-group-item-action:hover,.list-group-item-tlg-green.list-group-item-action:focus{
  color:#556932;
  background-color:#dbeac0
}

.list-group-item-tlg-green.list-group-item-action.active{
  color:#fff;
  background-color:#556932;
  border-color:#556932
}

.list-group-item-tlg-teal{
  color:#005a61;
  background-color:#b8e8ec
}

.list-group-item-tlg-teal.list-group-item-action:hover,.list-group-item-tlg-teal.list-group-item-action:focus{
  color:#005a61;
  background-color:#a4e1e7
}

.list-group-item-tlg-teal.list-group-item-action.active{
  color:#fff;
  background-color:#005a61;
  border-color:#005a61
}

.list-group-item-tlg-purple{
  color:#362f67;
  background-color:#d5d1ef
}

.list-group-item-tlg-purple.list-group-item-action:hover,.list-group-item-tlg-purple.list-group-item-action:focus{
  color:#362f67;
  background-color:#c4bee8
}

.list-group-item-tlg-purple.list-group-item-action.active{
  color:#fff;
  background-color:#362f67;
  border-color:#362f67
}

.list-group-item-tlg-purple-light{
  color:#5b405f;
  background-color:#e9daeb
}

.list-group-item-tlg-purple-light.list-group-item-action:hover,.list-group-item-tlg-purple-light.list-group-item-action:focus{
  color:#5b405f;
  background-color:#dfc9e2
}

.list-group-item-tlg-purple-light.list-group-item-action.active{
  color:#fff;
  background-color:#5b405f;
  border-color:#5b405f
}

.list-group-item-tlg-orange{
  color:#793e12;
  background-color:#f9d9c1
}

.list-group-item-tlg-orange.list-group-item-action:hover,.list-group-item-tlg-orange.list-group-item-action:focus{
  color:#793e12;
  background-color:#f7cbaa
}

.list-group-item-tlg-orange.list-group-item-action.active{
  color:#fff;
  background-color:#793e12;
  border-color:#793e12
}

.list-group-item-tlg-orange-light{
  color:#806755;
  background-color:#fcefe6
}

.list-group-item-tlg-orange-light.list-group-item-action:hover,.list-group-item-tlg-orange-light.list-group-item-action:focus{
  color:#806755;
  background-color:#f9e0cf
}

.list-group-item-tlg-orange-light.list-group-item-action.active{
  color:#fff;
  background-color:#806755;
  border-color:#806755
}

.list-group-item-tlg-orange-500{
  color:#854222;
  background-color:#ffdbca
}

.list-group-item-tlg-orange-500.list-group-item-action:hover,.list-group-item-tlg-orange-500.list-group-item-action:focus{
  color:#854222;
  background-color:#ffcab1
}

.list-group-item-tlg-orange-500.list-group-item-action.active{
  color:#fff;
  background-color:#854222;
  border-color:#854222
}

.list-group-item-tlg-green-dark{
  color:#3d6029;
  background-color:#d9ebcd
}

.list-group-item-tlg-green-dark.list-group-item-action:hover,.list-group-item-tlg-green-dark.list-group-item-action:focus{
  color:#3d6029;
  background-color:#cbe4bb
}

.list-group-item-tlg-green-dark.list-group-item-action.active{
  color:#fff;
  background-color:#3d6029;
  border-color:#3d6029
}

.close{
  float:right;
  font-size:1.5rem;
  font-weight:700;
  line-height:1;
  color:#000;
  text-shadow:0 1px 0 #fff;
  opacity:.5
}

.close:hover{
  color:#000;
  text-decoration:none
}

.close:not(:disabled):not(.disabled):hover,.close:not(:disabled):not(.disabled):focus{
  opacity:.75
}

button.close{
  padding:0;
  background-color:transparent;
  border:0;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}

a.close.disabled{
  pointer-events:none
}

.toast{
  max-width:350px;
  overflow:hidden;
  font-size:0.875rem;
  background-color:rgba(255,255,255,.85);
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.1);
  box-shadow:0 .25rem .75rem rgba(0,0,0,.1);
  -webkit-backdrop-filter:blur(10px);
          backdrop-filter:blur(10px);
  opacity:0;
  border-radius:.25rem
}

.toast:not(:last-child){
  margin-bottom:.75rem
}

.toast.showing{
  opacity:1
}

.toast.show{
  display:block;
  opacity:1
}

.toast.hide{
  display:none
}

.toast-header{
  display:flex;
  align-items:center;
  padding:.25rem .75rem;
  color:#6c757d;
  background-color:rgba(255,255,255,.85);
  background-clip:padding-box;
  border-bottom:1px solid rgba(0,0,0,.05)
}

.toast-body{
  padding:.75rem
}

.modal-open{
  overflow:hidden
}

.modal-open .modal{
  overflow-x:hidden;
  overflow-y:auto
}

.modal{
  position:fixed;
  top:0;
  left:0;
  z-index:1050;
  display:none;
  width:100%;
  height:100%;
  overflow:hidden;
  outline:0
}

.modal-dialog{
  position:relative;
  width:auto;
  margin:.5rem;
  pointer-events:none
}

.modal.fade .modal-dialog{
  -webkit-transition:-webkit-transform .3s ease-out;
  transition:-webkit-transform .3s ease-out;
  transition:transform .3s ease-out;
  transition:transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform:translate(0, -50px);
          transform:translate(0, -50px)
}

@media(prefers-reduced-motion: reduce){
  .modal.fade .modal-dialog{
    -webkit-transition:none;
    transition:none
  }
}

.modal.show .modal-dialog{
  -webkit-transform:none;
          transform:none
}

.modal-dialog-scrollable{
  display:flex;
  max-height:calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content{
  max-height:calc(100vh - 1rem);
  overflow:hidden
}

.modal-dialog-scrollable .modal-header,.modal-dialog-scrollable .modal-footer{
  flex-shrink:0
}

.modal-dialog-scrollable .modal-body{
  overflow-y:auto
}

.modal-dialog-centered{
  display:flex;
  align-items:center;
  min-height:calc(100% - 1rem)
}

.modal-dialog-centered::before{
  display:block;
  height:calc(100vh - 1rem);
  content:""
}

.modal-dialog-centered.modal-dialog-scrollable{
  flex-direction:column;
  justify-content:center;
  height:100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content{
  max-height:none
}

.modal-dialog-centered.modal-dialog-scrollable::before{
  content:none
}

.modal-content{
  position:relative;
  display:flex;
  flex-direction:column;
  width:100%;
  pointer-events:auto;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.2);
  border-radius:.3rem;
  outline:0
}

.modal-backdrop{
  position:fixed;
  top:0;
  left:0;
  z-index:1040;
  width:100vw;
  height:100vh;
  background-color:#000
}

.modal-backdrop.fade{
  opacity:0
}

.modal-backdrop.show{
  opacity:.5
}

.modal-header{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  padding:1rem 1rem;
  border-bottom:1px solid #dee2e6;
  border-top-left-radius:.3rem;
  border-top-right-radius:.3rem
}

.modal-header .close{
  padding:1rem 1rem;
  margin:-1rem -1rem -1rem auto
}

.modal-title{
  margin-bottom:0;
  line-height:1.5
}

.modal-body{
  position:relative;
  flex:1 1 auto;
  padding:1rem
}

.modal-footer{
  display:flex;
  align-items:center;
  justify-content:flex-end;
  padding:1rem;
  border-top:1px solid #dee2e6;
  border-bottom-right-radius:.3rem;
  border-bottom-left-radius:.3rem
}

.modal-footer>:not(:first-child){
  margin-left:.25rem
}

.modal-footer>:not(:last-child){
  margin-right:.25rem
}

.modal-scrollbar-measure{
  position:absolute;
  top:-9999px;
  width:50px;
  height:50px;
  overflow:scroll
}

@media(min-width: 640px){
  .modal-dialog{
    max-width:500px;
    margin:1.75rem auto
  }

  .modal-dialog-scrollable{
    max-height:calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content{
    max-height:calc(100vh - 3.5rem)
  }

  .modal-dialog-centered{
    min-height:calc(100% - 3.5rem)
  }

  .modal-dialog-centered::before{
    height:calc(100vh - 3.5rem)
  }

  .modal-sm{
    max-width:300px
  }
}

@media(min-width: 1024px){
  .modal-lg,.modal-xl{
    max-width:800px
  }
}

@media(min-width: 1280px){
  .modal-xl{
    max-width:1140px
  }
}

.tooltip{
  position:absolute;
  z-index:1070;
  display:block;
  margin:0;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:0.875rem;
  word-wrap:break-word;
  opacity:0
}

.tooltip.show{
  opacity:.9
}

.tooltip .arrow{
  position:absolute;
  display:block;
  width:.8rem;
  height:.4rem
}

.tooltip .arrow::before{
  position:absolute;
  content:"";
  border-color:transparent;
  border-style:solid
}

.bs-tooltip-top,.bs-tooltip-auto[x-placement^=top]{
  padding:.4rem 0
}

.bs-tooltip-top .arrow,.bs-tooltip-auto[x-placement^=top] .arrow{
  bottom:0
}

.bs-tooltip-top .arrow::before,.bs-tooltip-auto[x-placement^=top] .arrow::before{
  top:0;
  border-width:.4rem .4rem 0;
  border-top-color:#000
}

.bs-tooltip-right,.bs-tooltip-auto[x-placement^=right]{
  padding:0 .4rem
}

.bs-tooltip-right .arrow,.bs-tooltip-auto[x-placement^=right] .arrow{
  left:0;
  width:.4rem;
  height:.8rem
}

.bs-tooltip-right .arrow::before,.bs-tooltip-auto[x-placement^=right] .arrow::before{
  right:0;
  border-width:.4rem .4rem .4rem 0;
  border-right-color:#000
}

.bs-tooltip-bottom,.bs-tooltip-auto[x-placement^=bottom]{
  padding:.4rem 0
}

.bs-tooltip-bottom .arrow,.bs-tooltip-auto[x-placement^=bottom] .arrow{
  top:0
}

.bs-tooltip-bottom .arrow::before,.bs-tooltip-auto[x-placement^=bottom] .arrow::before{
  bottom:0;
  border-width:0 .4rem .4rem;
  border-bottom-color:#000
}

.bs-tooltip-left,.bs-tooltip-auto[x-placement^=left]{
  padding:0 .4rem
}

.bs-tooltip-left .arrow,.bs-tooltip-auto[x-placement^=left] .arrow{
  right:0;
  width:.4rem;
  height:.8rem
}

.bs-tooltip-left .arrow::before,.bs-tooltip-auto[x-placement^=left] .arrow::before{
  left:0;
  border-width:.4rem 0 .4rem .4rem;
  border-left-color:#000
}

.tooltip-inner{
  max-width:200px;
  padding:.25rem .5rem;
  color:#fff;
  text-align:center;
  background-color:#000;
  border-radius:.25rem
}

.popover{
  position:absolute;
  top:0;
  left:0;
  z-index:1060;
  display:block;
  max-width:276px;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style:normal;
  font-weight:400;
  line-height:1.5;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  white-space:normal;
  line-break:auto;
  font-size:0.875rem;
  word-wrap:break-word;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.2);
  border-radius:.3rem
}

.popover .arrow{
  position:absolute;
  display:block;
  width:1rem;
  height:.5rem;
  margin:0 .3rem
}

.popover .arrow::before,.popover .arrow::after{
  position:absolute;
  display:block;
  content:"";
  border-color:transparent;
  border-style:solid
}

.bs-popover-top,.bs-popover-auto[x-placement^=top]{
  margin-bottom:.5rem
}

.bs-popover-top>.arrow,.bs-popover-auto[x-placement^=top]>.arrow{
  bottom:calc((0.5rem + 1px) * -1)
}

.bs-popover-top>.arrow::before,.bs-popover-auto[x-placement^=top]>.arrow::before{
  bottom:0;
  border-width:.5rem .5rem 0;
  border-top-color:rgba(0,0,0,.25)
}

.bs-popover-top>.arrow::after,.bs-popover-auto[x-placement^=top]>.arrow::after{
  bottom:1px;
  border-width:.5rem .5rem 0;
  border-top-color:#fff
}

.bs-popover-right,.bs-popover-auto[x-placement^=right]{
  margin-left:.5rem
}

.bs-popover-right>.arrow,.bs-popover-auto[x-placement^=right]>.arrow{
  left:calc((0.5rem + 1px) * -1);
  width:.5rem;
  height:1rem;
  margin:.3rem 0
}

.bs-popover-right>.arrow::before,.bs-popover-auto[x-placement^=right]>.arrow::before{
  left:0;
  border-width:.5rem .5rem .5rem 0;
  border-right-color:rgba(0,0,0,.25)
}

.bs-popover-right>.arrow::after,.bs-popover-auto[x-placement^=right]>.arrow::after{
  left:1px;
  border-width:.5rem .5rem .5rem 0;
  border-right-color:#fff
}

.bs-popover-bottom,.bs-popover-auto[x-placement^=bottom]{
  margin-top:.5rem
}

.bs-popover-bottom>.arrow,.bs-popover-auto[x-placement^=bottom]>.arrow{
  top:calc((0.5rem + 1px) * -1)
}

.bs-popover-bottom>.arrow::before,.bs-popover-auto[x-placement^=bottom]>.arrow::before{
  top:0;
  border-width:0 .5rem .5rem .5rem;
  border-bottom-color:rgba(0,0,0,.25)
}

.bs-popover-bottom>.arrow::after,.bs-popover-auto[x-placement^=bottom]>.arrow::after{
  top:1px;
  border-width:0 .5rem .5rem .5rem;
  border-bottom-color:#fff
}

.bs-popover-bottom .popover-header::before,.bs-popover-auto[x-placement^=bottom] .popover-header::before{
  position:absolute;
  top:0;
  left:50%;
  display:block;
  width:1rem;
  margin-left:-0.5rem;
  content:"";
  border-bottom:1px solid #f7f7f7
}

.bs-popover-left,.bs-popover-auto[x-placement^=left]{
  margin-right:.5rem
}

.bs-popover-left>.arrow,.bs-popover-auto[x-placement^=left]>.arrow{
  right:calc((0.5rem + 1px) * -1);
  width:.5rem;
  height:1rem;
  margin:.3rem 0
}

.bs-popover-left>.arrow::before,.bs-popover-auto[x-placement^=left]>.arrow::before{
  right:0;
  border-width:.5rem 0 .5rem .5rem;
  border-left-color:rgba(0,0,0,.25)
}

.bs-popover-left>.arrow::after,.bs-popover-auto[x-placement^=left]>.arrow::after{
  right:1px;
  border-width:.5rem 0 .5rem .5rem;
  border-left-color:#fff
}

.popover-header{
  padding:.5rem .75rem;
  margin-bottom:0;
  font-size:1rem;
  background-color:#f7f7f7;
  border-bottom:1px solid #ebebeb;
  border-top-left-radius:calc(0.3rem - 1px);
  border-top-right-radius:calc(0.3rem - 1px)
}

.popover-header:empty{
  display:none
}

.popover-body{
  padding:.5rem .75rem;
  color:#212529
}

.carousel{
  position:relative
}

.carousel.pointer-event{
  touch-action:pan-y
}

.carousel-inner{
  position:relative;
  width:100%;
  overflow:hidden
}

.carousel-inner::after{
  display:block;
  clear:both;
  content:""
}

.carousel-item{
  position:relative;
  display:none;
  float:left;
  width:100%;
  margin-right:-100%;
  -webkit-backface-visibility:hidden;
          backface-visibility:hidden;
  -webkit-transition:-webkit-transform .6s ease-in-out;
  transition:-webkit-transform .6s ease-in-out;
  transition:transform .6s ease-in-out;
  transition:transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media(prefers-reduced-motion: reduce){
  .carousel-item{
    -webkit-transition:none;
    transition:none
  }
}

.carousel-item.active,.carousel-item-next,.carousel-item-prev{
  display:block
}

.carousel-item-next:not(.carousel-item-left),.active.carousel-item-right{
  -webkit-transform:translateX(100%);
          transform:translateX(100%)
}

.carousel-item-prev:not(.carousel-item-right),.active.carousel-item-left{
  -webkit-transform:translateX(-100%);
          transform:translateX(-100%)
}

.carousel-fade .carousel-item{
  opacity:0;
  -webkit-transition-property:opacity;
  transition-property:opacity;
  -webkit-transform:none;
          transform:none
}

.carousel-fade .carousel-item.active,.carousel-fade .carousel-item-next.carousel-item-left,.carousel-fade .carousel-item-prev.carousel-item-right{
  z-index:1;
  opacity:1
}

.carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right{
  z-index:0;
  opacity:0;
  -webkit-transition:0s .6s opacity;
  transition:0s .6s opacity
}

@media(prefers-reduced-motion: reduce){
  .carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right{
    -webkit-transition:none;
    transition:none
  }
}

.carousel-control-prev,.carousel-control-next{
  position:absolute;
  top:0;
  bottom:0;
  z-index:1;
  display:flex;
  align-items:center;
  justify-content:center;
  width:15%;
  color:#fff;
  text-align:center;
  opacity:.5;
  -webkit-transition:opacity .15s ease;
  transition:opacity .15s ease
}

@media(prefers-reduced-motion: reduce){
  .carousel-control-prev,.carousel-control-next{
    -webkit-transition:none;
    transition:none
  }
}

.carousel-control-prev:hover,.carousel-control-prev:focus,.carousel-control-next:hover,.carousel-control-next:focus{
  color:#fff;
  text-decoration:none;
  outline:0;
  opacity:.9
}

.carousel-control-prev{
  left:0
}

.carousel-control-next{
  right:0
}

.carousel-control-prev-icon,.carousel-control-next-icon{
  display:inline-block;
  width:20px;
  height:20px;
  background:no-repeat 50%/100% 100%
}

.carousel-control-prev-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators{
  position:absolute;
  right:0;
  bottom:0;
  left:0;
  z-index:15;
  display:flex;
  justify-content:center;
  padding-left:0;
  margin-right:15%;
  margin-left:15%;
  list-style:none
}

.carousel-indicators li{
  box-sizing:content-box;
  flex:0 1 auto;
  width:30px;
  height:3px;
  margin-right:3px;
  margin-left:3px;
  text-indent:-999px;
  cursor:pointer;
  background-color:#fff;
  background-clip:padding-box;
  border-top:10px solid transparent;
  border-bottom:10px solid transparent;
  opacity:.5;
  -webkit-transition:opacity .6s ease;
  transition:opacity .6s ease
}

@media(prefers-reduced-motion: reduce){
  .carousel-indicators li{
    -webkit-transition:none;
    transition:none
  }
}

.carousel-indicators .active{
  opacity:1
}

.carousel-caption{
  position:absolute;
  right:15%;
  bottom:20px;
  left:15%;
  z-index:10;
  padding-top:20px;
  padding-bottom:20px;
  color:#fff;
  text-align:center
}

@-webkit-keyframes spinner-border{
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

@keyframes spinner-border{
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

.spinner-border{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:text-bottom;
  border:.25em solid currentColor;
  border-right-color:transparent;
  border-radius:50%;
  -webkit-animation:spinner-border .75s linear infinite;
          animation:spinner-border .75s linear infinite
}

.spinner-border-sm{
  width:1rem;
  height:1rem;
  border-width:.2em
}

@-webkit-keyframes spinner-grow{
  0%{
    -webkit-transform:scale(0);
            transform:scale(0)
  }

  50%{
    opacity:1
  }
}

@keyframes spinner-grow{
  0%{
    -webkit-transform:scale(0);
            transform:scale(0)
  }

  50%{
    opacity:1
  }
}

.spinner-grow{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:text-bottom;
  background-color:currentColor;
  border-radius:50%;
  opacity:0;
  -webkit-animation:spinner-grow .75s linear infinite;
          animation:spinner-grow .75s linear infinite
}

.spinner-grow-sm{
  width:1rem;
  height:1rem
}

.align-baseline{
  vertical-align:baseline !important
}

.align-top{
  vertical-align:top !important
}

.align-middle{
  vertical-align:middle !important
}

.align-bottom{
  vertical-align:bottom !important
}

.align-text-bottom{
  vertical-align:text-bottom !important
}

.align-text-top{
  vertical-align:text-top !important
}

.bg-primary{
  background-color:#00adbb !important
}

a.bg-primary:hover,a.bg-primary:focus,button.bg-primary:hover,button.bg-primary:focus{
  background-color:#007e88 !important
}

.bg-secondary{
  background-color:#6c757d !important
}

a.bg-secondary:hover,a.bg-secondary:focus,button.bg-secondary:hover,button.bg-secondary:focus{
  background-color:#545b62 !important
}

.bg-success{
  background-color:#28a745 !important
}

a.bg-success:hover,a.bg-success:focus,button.bg-success:hover,button.bg-success:focus{
  background-color:#1e7e34 !important
}

.bg-info{
  background-color:#17a2b8 !important
}

a.bg-info:hover,a.bg-info:focus,button.bg-info:hover,button.bg-info:focus{
  background-color:#117a8b !important
}

.bg-warning{
  background-color:#ffc107 !important
}

a.bg-warning:hover,a.bg-warning:focus,button.bg-warning:hover,button.bg-warning:focus{
  background-color:#d39e00 !important
}

.bg-danger{
  background-color:#dc3545 !important
}

a.bg-danger:hover,a.bg-danger:focus,button.bg-danger:hover,button.bg-danger:focus{
  background-color:#bd2130 !important
}

.bg-light{
  background-color:#f8f9fa !important
}

a.bg-light:hover,a.bg-light:focus,button.bg-light:hover,button.bg-light:focus{
  background-color:#dae0e5 !important
}

.bg-dark{
  background-color:#343a40 !important
}

a.bg-dark:hover,a.bg-dark:focus,button.bg-dark:hover,button.bg-dark:focus{
  background-color:#1d2124 !important
}

.bg-tlg-green{
  background-color:#a4ca61 !important
}

a.bg-tlg-green:hover,a.bg-tlg-green:focus,button.bg-tlg-green:hover,button.bg-tlg-green:focus{
  background-color:#8dba3e !important
}

.bg-tlg-teal{
  background-color:#00adbb !important
}

a.bg-tlg-teal:hover,a.bg-tlg-teal:focus,button.bg-tlg-teal:hover,button.bg-tlg-teal:focus{
  background-color:#007e88 !important
}

.bg-tlg-purple{
  background-color:#685bc7 !important
}

a.bg-tlg-purple:hover,a.bg-tlg-purple:focus,button.bg-tlg-purple:hover,button.bg-tlg-purple:focus{
  background-color:#4b3db2 !important
}

.bg-tlg-purple-light{
  background-color:#af7cb6 !important
}

a.bg-tlg-purple-light:hover,a.bg-tlg-purple-light:focus,button.bg-tlg-purple-light:hover,button.bg-tlg-purple-light:focus{
  background-color:#9b5ba4 !important
}

.bg-tlg-orange{
  background-color:#e87722 !important
}

a.bg-tlg-orange:hover,a.bg-tlg-orange:focus,button.bg-tlg-orange:hover,button.bg-tlg-orange:focus{
  background-color:#c35f14 !important
}

.bg-tlg-orange-light{
  background-color:#f6c7a4 !important
}

a.bg-tlg-orange-light:hover,a.bg-tlg-orange-light:focus,button.bg-tlg-orange-light:hover,button.bg-tlg-orange-light:focus{
  background-color:#f1aa76 !important
}

.bg-tlg-orange-500{
  background-color:#ff7f41 !important
}

a.bg-tlg-orange-500:hover,a.bg-tlg-orange-500:focus,button.bg-tlg-orange-500:hover,button.bg-tlg-orange-500:focus{
  background-color:#ff5d0e !important
}

.bg-tlg-green-dark{
  background-color:#76b84e !important
}

a.bg-tlg-green-dark:hover,a.bg-tlg-green-dark:focus,button.bg-tlg-green-dark:hover,button.bg-tlg-green-dark:focus{
  background-color:#5e973c !important
}

.bg-white{
  background-color:#fff !important
}

.bg-transparent{
  background-color:transparent !important
}

.border{
  border:1px solid #dee2e6 !important
}

.border-top{
  border-top:1px solid #dee2e6 !important
}

.border-right{
  border-right:1px solid #dee2e6 !important
}

.border-bottom{
  border-bottom:1px solid #dee2e6 !important
}

.border-left{
  border-left:1px solid #dee2e6 !important
}

.border-0{
  border:0 !important
}

.border-top-0{
  border-top:0 !important
}

.border-right-0{
  border-right:0 !important
}

.border-bottom-0{
  border-bottom:0 !important
}

.border-left-0{
  border-left:0 !important
}

.border-primary{
  border-color:#00adbb !important
}

.border-secondary{
  border-color:#6c757d !important
}

.border-success{
  border-color:#28a745 !important
}

.border-info{
  border-color:#17a2b8 !important
}

.border-warning{
  border-color:#ffc107 !important
}

.border-danger{
  border-color:#dc3545 !important
}

.border-light{
  border-color:#f8f9fa !important
}

.border-dark{
  border-color:#343a40 !important
}

.border-tlg-green{
  border-color:#a4ca61 !important
}

.border-tlg-teal{
  border-color:#00adbb !important
}

.border-tlg-purple{
  border-color:#685bc7 !important
}

.border-tlg-purple-light{
  border-color:#af7cb6 !important
}

.border-tlg-orange{
  border-color:#e87722 !important
}

.border-tlg-orange-light{
  border-color:#f6c7a4 !important
}

.border-tlg-orange-500{
  border-color:#ff7f41 !important
}

.border-tlg-green-dark{
  border-color:#76b84e !important
}

.border-white{
  border-color:#fff !important
}

.rounded-sm{
  border-radius:.2rem !important
}

.rounded{
  border-radius:.25rem !important
}

.rounded-top{
  border-top-left-radius:.25rem !important;
  border-top-right-radius:.25rem !important
}

.rounded-right{
  border-top-right-radius:.25rem !important;
  border-bottom-right-radius:.25rem !important
}

.rounded-bottom{
  border-bottom-right-radius:.25rem !important;
  border-bottom-left-radius:.25rem !important
}

.rounded-left{
  border-top-left-radius:.25rem !important;
  border-bottom-left-radius:.25rem !important
}

.rounded-lg{
  border-radius:.3rem !important
}

.rounded-circle{
  border-radius:50% !important
}

.rounded-pill{
  border-radius:50rem !important
}

.rounded-0{
  border-radius:0 !important
}

.clearfix::after{
  display:block;
  clear:both;
  content:""
}

.d-none{
  display:none !important
}

.d-inline{
  display:inline !important
}

.d-inline-block{
  display:inline-block !important
}

.d-block{
  display:block !important
}

.d-table{
  display:table !important
}

.d-table-row{
  display:table-row !important
}

.d-table-cell{
  display:table-cell !important
}

.d-flex{
  display:flex !important
}

.d-inline-flex{
  display:inline-flex !important
}

@media(min-width: 640px){
  .d-sm-none{
    display:none !important
  }

  .d-sm-inline{
    display:inline !important
  }

  .d-sm-inline-block{
    display:inline-block !important
  }

  .d-sm-block{
    display:block !important
  }

  .d-sm-table{
    display:table !important
  }

  .d-sm-table-row{
    display:table-row !important
  }

  .d-sm-table-cell{
    display:table-cell !important
  }

  .d-sm-flex{
    display:flex !important
  }

  .d-sm-inline-flex{
    display:inline-flex !important
  }
}

@media(min-width: 768px){
  .d-md-none{
    display:none !important
  }

  .d-md-inline{
    display:inline !important
  }

  .d-md-inline-block{
    display:inline-block !important
  }

  .d-md-block{
    display:block !important
  }

  .d-md-table{
    display:table !important
  }

  .d-md-table-row{
    display:table-row !important
  }

  .d-md-table-cell{
    display:table-cell !important
  }

  .d-md-flex{
    display:flex !important
  }

  .d-md-inline-flex{
    display:inline-flex !important
  }
}

@media(min-width: 1024px){
  .d-lg-none{
    display:none !important
  }

  .d-lg-inline{
    display:inline !important
  }

  .d-lg-inline-block{
    display:inline-block !important
  }

  .d-lg-block{
    display:block !important
  }

  .d-lg-table{
    display:table !important
  }

  .d-lg-table-row{
    display:table-row !important
  }

  .d-lg-table-cell{
    display:table-cell !important
  }

  .d-lg-flex{
    display:flex !important
  }

  .d-lg-inline-flex{
    display:inline-flex !important
  }
}

@media(min-width: 1280px){
  .d-xl-none{
    display:none !important
  }

  .d-xl-inline{
    display:inline !important
  }

  .d-xl-inline-block{
    display:inline-block !important
  }

  .d-xl-block{
    display:block !important
  }

  .d-xl-table{
    display:table !important
  }

  .d-xl-table-row{
    display:table-row !important
  }

  .d-xl-table-cell{
    display:table-cell !important
  }

  .d-xl-flex{
    display:flex !important
  }

  .d-xl-inline-flex{
    display:inline-flex !important
  }
}

@media print{
  .d-print-none{
    display:none !important
  }

  .d-print-inline{
    display:inline !important
  }

  .d-print-inline-block{
    display:inline-block !important
  }

  .d-print-block{
    display:block !important
  }

  .d-print-table{
    display:table !important
  }

  .d-print-table-row{
    display:table-row !important
  }

  .d-print-table-cell{
    display:table-cell !important
  }

  .d-print-flex{
    display:flex !important
  }

  .d-print-inline-flex{
    display:inline-flex !important
  }
}

.embed-responsive{
  position:relative;
  display:block;
  width:100%;
  padding:0;
  overflow:hidden
}

.embed-responsive::before{
  display:block;
  content:""
}

.embed-responsive .embed-responsive-item,.embed-responsive iframe,.embed-responsive embed,.embed-responsive object,.embed-responsive video{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  border:0
}

.embed-responsive-21by9::before{
  padding-top:42.8571428571%
}

.embed-responsive-16by9::before{
  padding-top:56.25%
}

.embed-responsive-4by3::before{
  padding-top:75%
}

.embed-responsive-1by1::before{
  padding-top:100%
}

.flex-row{
  flex-direction:row !important
}

.flex-column{
  flex-direction:column !important
}

.flex-row-reverse{
  flex-direction:row-reverse !important
}

.flex-column-reverse{
  flex-direction:column-reverse !important
}

.flex-wrap{
  flex-wrap:wrap !important
}

.flex-nowrap{
  flex-wrap:nowrap !important
}

.flex-wrap-reverse{
  flex-wrap:wrap-reverse !important
}

.flex-fill{
  flex:1 1 auto !important
}

.flex-grow-0{
  flex-grow:0 !important
}

.flex-grow-1{
  flex-grow:1 !important
}

.flex-shrink-0{
  flex-shrink:0 !important
}

.flex-shrink-1{
  flex-shrink:1 !important
}

.justify-content-start{
  justify-content:flex-start !important
}

.justify-content-end{
  justify-content:flex-end !important
}

.justify-content-center{
  justify-content:center !important
}

.justify-content-between{
  justify-content:space-between !important
}

.justify-content-around{
  justify-content:space-around !important
}

.align-items-start{
  align-items:flex-start !important
}

.align-items-end{
  align-items:flex-end !important
}

.align-items-center{
  align-items:center !important
}

.align-items-baseline{
  align-items:baseline !important
}

.align-items-stretch{
  align-items:stretch !important
}

.align-content-start{
  align-content:flex-start !important
}

.align-content-end{
  align-content:flex-end !important
}

.align-content-center{
  align-content:center !important
}

.align-content-between{
  align-content:space-between !important
}

.align-content-around{
  align-content:space-around !important
}

.align-content-stretch{
  align-content:stretch !important
}

.align-self-auto{
  align-self:auto !important
}

.align-self-start{
  align-self:flex-start !important
}

.align-self-end{
  align-self:flex-end !important
}

.align-self-center{
  align-self:center !important
}

.align-self-baseline{
  align-self:baseline !important
}

.align-self-stretch{
  align-self:stretch !important
}

@media(min-width: 640px){
  .flex-sm-row{
    flex-direction:row !important
  }

  .flex-sm-column{
    flex-direction:column !important
  }

  .flex-sm-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-sm-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-sm-wrap{
    flex-wrap:wrap !important
  }

  .flex-sm-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-sm-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-sm-fill{
    flex:1 1 auto !important
  }

  .flex-sm-grow-0{
    flex-grow:0 !important
  }

  .flex-sm-grow-1{
    flex-grow:1 !important
  }

  .flex-sm-shrink-0{
    flex-shrink:0 !important
  }

  .flex-sm-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-sm-start{
    justify-content:flex-start !important
  }

  .justify-content-sm-end{
    justify-content:flex-end !important
  }

  .justify-content-sm-center{
    justify-content:center !important
  }

  .justify-content-sm-between{
    justify-content:space-between !important
  }

  .justify-content-sm-around{
    justify-content:space-around !important
  }

  .align-items-sm-start{
    align-items:flex-start !important
  }

  .align-items-sm-end{
    align-items:flex-end !important
  }

  .align-items-sm-center{
    align-items:center !important
  }

  .align-items-sm-baseline{
    align-items:baseline !important
  }

  .align-items-sm-stretch{
    align-items:stretch !important
  }

  .align-content-sm-start{
    align-content:flex-start !important
  }

  .align-content-sm-end{
    align-content:flex-end !important
  }

  .align-content-sm-center{
    align-content:center !important
  }

  .align-content-sm-between{
    align-content:space-between !important
  }

  .align-content-sm-around{
    align-content:space-around !important
  }

  .align-content-sm-stretch{
    align-content:stretch !important
  }

  .align-self-sm-auto{
    align-self:auto !important
  }

  .align-self-sm-start{
    align-self:flex-start !important
  }

  .align-self-sm-end{
    align-self:flex-end !important
  }

  .align-self-sm-center{
    align-self:center !important
  }

  .align-self-sm-baseline{
    align-self:baseline !important
  }

  .align-self-sm-stretch{
    align-self:stretch !important
  }
}

@media(min-width: 768px){
  .flex-md-row{
    flex-direction:row !important
  }

  .flex-md-column{
    flex-direction:column !important
  }

  .flex-md-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-md-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-md-wrap{
    flex-wrap:wrap !important
  }

  .flex-md-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-md-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-md-fill{
    flex:1 1 auto !important
  }

  .flex-md-grow-0{
    flex-grow:0 !important
  }

  .flex-md-grow-1{
    flex-grow:1 !important
  }

  .flex-md-shrink-0{
    flex-shrink:0 !important
  }

  .flex-md-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-md-start{
    justify-content:flex-start !important
  }

  .justify-content-md-end{
    justify-content:flex-end !important
  }

  .justify-content-md-center{
    justify-content:center !important
  }

  .justify-content-md-between{
    justify-content:space-between !important
  }

  .justify-content-md-around{
    justify-content:space-around !important
  }

  .align-items-md-start{
    align-items:flex-start !important
  }

  .align-items-md-end{
    align-items:flex-end !important
  }

  .align-items-md-center{
    align-items:center !important
  }

  .align-items-md-baseline{
    align-items:baseline !important
  }

  .align-items-md-stretch{
    align-items:stretch !important
  }

  .align-content-md-start{
    align-content:flex-start !important
  }

  .align-content-md-end{
    align-content:flex-end !important
  }

  .align-content-md-center{
    align-content:center !important
  }

  .align-content-md-between{
    align-content:space-between !important
  }

  .align-content-md-around{
    align-content:space-around !important
  }

  .align-content-md-stretch{
    align-content:stretch !important
  }

  .align-self-md-auto{
    align-self:auto !important
  }

  .align-self-md-start{
    align-self:flex-start !important
  }

  .align-self-md-end{
    align-self:flex-end !important
  }

  .align-self-md-center{
    align-self:center !important
  }

  .align-self-md-baseline{
    align-self:baseline !important
  }

  .align-self-md-stretch{
    align-self:stretch !important
  }
}

@media(min-width: 1024px){
  .flex-lg-row{
    flex-direction:row !important
  }

  .flex-lg-column{
    flex-direction:column !important
  }

  .flex-lg-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-lg-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-lg-wrap{
    flex-wrap:wrap !important
  }

  .flex-lg-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-lg-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-lg-fill{
    flex:1 1 auto !important
  }

  .flex-lg-grow-0{
    flex-grow:0 !important
  }

  .flex-lg-grow-1{
    flex-grow:1 !important
  }

  .flex-lg-shrink-0{
    flex-shrink:0 !important
  }

  .flex-lg-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-lg-start{
    justify-content:flex-start !important
  }

  .justify-content-lg-end{
    justify-content:flex-end !important
  }

  .justify-content-lg-center{
    justify-content:center !important
  }

  .justify-content-lg-between{
    justify-content:space-between !important
  }

  .justify-content-lg-around{
    justify-content:space-around !important
  }

  .align-items-lg-start{
    align-items:flex-start !important
  }

  .align-items-lg-end{
    align-items:flex-end !important
  }

  .align-items-lg-center{
    align-items:center !important
  }

  .align-items-lg-baseline{
    align-items:baseline !important
  }

  .align-items-lg-stretch{
    align-items:stretch !important
  }

  .align-content-lg-start{
    align-content:flex-start !important
  }

  .align-content-lg-end{
    align-content:flex-end !important
  }

  .align-content-lg-center{
    align-content:center !important
  }

  .align-content-lg-between{
    align-content:space-between !important
  }

  .align-content-lg-around{
    align-content:space-around !important
  }

  .align-content-lg-stretch{
    align-content:stretch !important
  }

  .align-self-lg-auto{
    align-self:auto !important
  }

  .align-self-lg-start{
    align-self:flex-start !important
  }

  .align-self-lg-end{
    align-self:flex-end !important
  }

  .align-self-lg-center{
    align-self:center !important
  }

  .align-self-lg-baseline{
    align-self:baseline !important
  }

  .align-self-lg-stretch{
    align-self:stretch !important
  }
}

@media(min-width: 1280px){
  .flex-xl-row{
    flex-direction:row !important
  }

  .flex-xl-column{
    flex-direction:column !important
  }

  .flex-xl-row-reverse{
    flex-direction:row-reverse !important
  }

  .flex-xl-column-reverse{
    flex-direction:column-reverse !important
  }

  .flex-xl-wrap{
    flex-wrap:wrap !important
  }

  .flex-xl-nowrap{
    flex-wrap:nowrap !important
  }

  .flex-xl-wrap-reverse{
    flex-wrap:wrap-reverse !important
  }

  .flex-xl-fill{
    flex:1 1 auto !important
  }

  .flex-xl-grow-0{
    flex-grow:0 !important
  }

  .flex-xl-grow-1{
    flex-grow:1 !important
  }

  .flex-xl-shrink-0{
    flex-shrink:0 !important
  }

  .flex-xl-shrink-1{
    flex-shrink:1 !important
  }

  .justify-content-xl-start{
    justify-content:flex-start !important
  }

  .justify-content-xl-end{
    justify-content:flex-end !important
  }

  .justify-content-xl-center{
    justify-content:center !important
  }

  .justify-content-xl-between{
    justify-content:space-between !important
  }

  .justify-content-xl-around{
    justify-content:space-around !important
  }

  .align-items-xl-start{
    align-items:flex-start !important
  }

  .align-items-xl-end{
    align-items:flex-end !important
  }

  .align-items-xl-center{
    align-items:center !important
  }

  .align-items-xl-baseline{
    align-items:baseline !important
  }

  .align-items-xl-stretch{
    align-items:stretch !important
  }

  .align-content-xl-start{
    align-content:flex-start !important
  }

  .align-content-xl-end{
    align-content:flex-end !important
  }

  .align-content-xl-center{
    align-content:center !important
  }

  .align-content-xl-between{
    align-content:space-between !important
  }

  .align-content-xl-around{
    align-content:space-around !important
  }

  .align-content-xl-stretch{
    align-content:stretch !important
  }

  .align-self-xl-auto{
    align-self:auto !important
  }

  .align-self-xl-start{
    align-self:flex-start !important
  }

  .align-self-xl-end{
    align-self:flex-end !important
  }

  .align-self-xl-center{
    align-self:center !important
  }

  .align-self-xl-baseline{
    align-self:baseline !important
  }

  .align-self-xl-stretch{
    align-self:stretch !important
  }
}

.float-left{
  float:left !important
}

.float-right{
  float:right !important
}

.float-none{
  float:none !important
}

@media(min-width: 640px){
  .float-sm-left{
    float:left !important
  }

  .float-sm-right{
    float:right !important
  }

  .float-sm-none{
    float:none !important
  }
}

@media(min-width: 768px){
  .float-md-left{
    float:left !important
  }

  .float-md-right{
    float:right !important
  }

  .float-md-none{
    float:none !important
  }
}

@media(min-width: 1024px){
  .float-lg-left{
    float:left !important
  }

  .float-lg-right{
    float:right !important
  }

  .float-lg-none{
    float:none !important
  }
}

@media(min-width: 1280px){
  .float-xl-left{
    float:left !important
  }

  .float-xl-right{
    float:right !important
  }

  .float-xl-none{
    float:none !important
  }
}

.overflow-auto{
  overflow:auto !important
}

.overflow-hidden{
  overflow:hidden !important
}

.position-static{
  position:static !important
}

.position-relative{
  position:relative !important
}

.position-absolute{
  position:absolute !important
}

.position-fixed{
  position:fixed !important
}

.position-sticky{
  position:-webkit-sticky !important;
  position:sticky !important
}

.fixed-top{
  position:fixed;
  top:0;
  right:0;
  left:0;
  z-index:1030
}

.fixed-bottom{
  position:fixed;
  right:0;
  bottom:0;
  left:0;
  z-index:1030
}

@supports((position: -webkit-sticky) or (position: sticky)){
  .sticky-top{
    position:-webkit-sticky;
    position:sticky;
    top:0;
    z-index:1020
  }
}

.sr-only{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  overflow:hidden;
  clip:rect(0, 0, 0, 0);
  white-space:nowrap;
  border:0
}

.sr-only-focusable:active,.sr-only-focusable:focus{
  position:static;
  width:auto;
  height:auto;
  overflow:visible;
  clip:auto;
  white-space:normal
}

.shadow-sm{
  box-shadow:0 .125rem .25rem rgba(0,0,0,.075) !important
}

.shadow{
  box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important
}

.shadow-lg{
  box-shadow:0 1rem 3rem rgba(0,0,0,.175) !important
}

.shadow-none{
  box-shadow:none !important
}

.w-25{
  width:25% !important
}

.w-50{
  width:50% !important
}

.w-75{
  width:75% !important
}

.w-100{
  width:100% !important
}

.w-auto{
  width:auto !important
}

.h-25{
  height:25% !important
}

.h-50{
  height:50% !important
}

.h-75{
  height:75% !important
}

.h-100{
  height:100% !important
}

.h-auto{
  height:auto !important
}

.mw-100{
  max-width:100% !important
}

.mh-100{
  max-height:100% !important
}

.min-vw-100{
  min-width:100vw !important
}

.min-vh-100{
  min-height:100vh !important
}

.vw-100{
  width:100vw !important
}

.vh-100{
  height:100vh !important
}

.stretched-link::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:1;
  pointer-events:auto;
  content:"";
  background-color:rgba(0,0,0,0)
}

.m-0{
  margin:0 !important
}

.mt-0,.my-0{
  margin-top:0 !important
}

.mr-0,.mx-0{
  margin-right:0 !important
}

.mb-0,.my-0{
  margin-bottom:0 !important
}

.ml-0,.mx-0{
  margin-left:0 !important
}

.m-1{
  margin:.25rem !important
}

.mt-1,.my-1{
  margin-top:.25rem !important
}

.mr-1,.mx-1{
  margin-right:.25rem !important
}

.mb-1,.my-1{
  margin-bottom:.25rem !important
}

.ml-1,.mx-1{
  margin-left:.25rem !important
}

.m-2{
  margin:.5rem !important
}

.mt-2,.my-2{
  margin-top:.5rem !important
}

.mr-2,.mx-2{
  margin-right:.5rem !important
}

.mb-2,.my-2{
  margin-bottom:.5rem !important
}

.ml-2,.mx-2{
  margin-left:.5rem !important
}

.m-3{
  margin:1rem !important
}

.mt-3,.my-3{
  margin-top:1rem !important
}

.mr-3,.mx-3{
  margin-right:1rem !important
}

.mb-3,.my-3{
  margin-bottom:1rem !important
}

.ml-3,.mx-3{
  margin-left:1rem !important
}

.m-4{
  margin:1.5rem !important
}

.mt-4,.my-4{
  margin-top:1.5rem !important
}

.mr-4,.mx-4{
  margin-right:1.5rem !important
}

.mb-4,.my-4{
  margin-bottom:1.5rem !important
}

.ml-4,.mx-4{
  margin-left:1.5rem !important
}

.m-5{
  margin:3rem !important
}

.mt-5,.my-5{
  margin-top:3rem !important
}

.mr-5,.mx-5{
  margin-right:3rem !important
}

.mb-5,.my-5{
  margin-bottom:3rem !important
}

.ml-5,.mx-5{
  margin-left:3rem !important
}

.p-0{
  padding:0 !important
}

.pt-0,.py-0{
  padding-top:0 !important
}

.pr-0,.px-0{
  padding-right:0 !important
}

.pb-0,.py-0{
  padding-bottom:0 !important
}

.pl-0,.px-0{
  padding-left:0 !important
}

.p-1{
  padding:.25rem !important
}

.pt-1,.py-1{
  padding-top:.25rem !important
}

.pr-1,.px-1{
  padding-right:.25rem !important
}

.pb-1,.py-1{
  padding-bottom:.25rem !important
}

.pl-1,.px-1{
  padding-left:.25rem !important
}

.p-2{
  padding:.5rem !important
}

.pt-2,.py-2{
  padding-top:.5rem !important
}

.pr-2,.px-2{
  padding-right:.5rem !important
}

.pb-2,.py-2{
  padding-bottom:.5rem !important
}

.pl-2,.px-2{
  padding-left:.5rem !important
}

.p-3{
  padding:1rem !important
}

.pt-3,.py-3{
  padding-top:1rem !important
}

.pr-3,.px-3{
  padding-right:1rem !important
}

.pb-3,.py-3{
  padding-bottom:1rem !important
}

.pl-3,.px-3{
  padding-left:1rem !important
}

.p-4{
  padding:1.5rem !important
}

.pt-4,.py-4{
  padding-top:1.5rem !important
}

.pr-4,.px-4{
  padding-right:1.5rem !important
}

.pb-4,.py-4{
  padding-bottom:1.5rem !important
}

.pl-4,.px-4{
  padding-left:1.5rem !important
}

.p-5{
  padding:3rem !important
}

.pt-5,.py-5{
  padding-top:3rem !important
}

.pr-5,.px-5{
  padding-right:3rem !important
}

.pb-5,.py-5{
  padding-bottom:3rem !important
}

.pl-5,.px-5{
  padding-left:3rem !important
}

.m-n1{
  margin:-0.25rem !important
}

.mt-n1,.my-n1{
  margin-top:-0.25rem !important
}

.mr-n1,.mx-n1{
  margin-right:-0.25rem !important
}

.mb-n1,.my-n1{
  margin-bottom:-0.25rem !important
}

.ml-n1,.mx-n1{
  margin-left:-0.25rem !important
}

.m-n2{
  margin:-0.5rem !important
}

.mt-n2,.my-n2{
  margin-top:-0.5rem !important
}

.mr-n2,.mx-n2{
  margin-right:-0.5rem !important
}

.mb-n2,.my-n2{
  margin-bottom:-0.5rem !important
}

.ml-n2,.mx-n2{
  margin-left:-0.5rem !important
}

.m-n3{
  margin:-1rem !important
}

.mt-n3,.my-n3{
  margin-top:-1rem !important
}

.mr-n3,.mx-n3{
  margin-right:-1rem !important
}

.mb-n3,.my-n3{
  margin-bottom:-1rem !important
}

.ml-n3,.mx-n3{
  margin-left:-1rem !important
}

.m-n4{
  margin:-1.5rem !important
}

.mt-n4,.my-n4{
  margin-top:-1.5rem !important
}

.mr-n4,.mx-n4{
  margin-right:-1.5rem !important
}

.mb-n4,.my-n4{
  margin-bottom:-1.5rem !important
}

.ml-n4,.mx-n4{
  margin-left:-1.5rem !important
}

.m-n5{
  margin:-3rem !important
}

.mt-n5,.my-n5{
  margin-top:-3rem !important
}

.mr-n5,.mx-n5{
  margin-right:-3rem !important
}

.mb-n5,.my-n5{
  margin-bottom:-3rem !important
}

.ml-n5,.mx-n5{
  margin-left:-3rem !important
}

.m-auto{
  margin:auto !important
}

.mt-auto,.my-auto{
  margin-top:auto !important
}

.mr-auto,.mx-auto{
  margin-right:auto !important
}

.mb-auto,.my-auto{
  margin-bottom:auto !important
}

.ml-auto,.mx-auto{
  margin-left:auto !important
}

@media(min-width: 640px){
  .m-sm-0{
    margin:0 !important
  }

  .mt-sm-0,.my-sm-0{
    margin-top:0 !important
  }

  .mr-sm-0,.mx-sm-0{
    margin-right:0 !important
  }

  .mb-sm-0,.my-sm-0{
    margin-bottom:0 !important
  }

  .ml-sm-0,.mx-sm-0{
    margin-left:0 !important
  }

  .m-sm-1{
    margin:.25rem !important
  }

  .mt-sm-1,.my-sm-1{
    margin-top:.25rem !important
  }

  .mr-sm-1,.mx-sm-1{
    margin-right:.25rem !important
  }

  .mb-sm-1,.my-sm-1{
    margin-bottom:.25rem !important
  }

  .ml-sm-1,.mx-sm-1{
    margin-left:.25rem !important
  }

  .m-sm-2{
    margin:.5rem !important
  }

  .mt-sm-2,.my-sm-2{
    margin-top:.5rem !important
  }

  .mr-sm-2,.mx-sm-2{
    margin-right:.5rem !important
  }

  .mb-sm-2,.my-sm-2{
    margin-bottom:.5rem !important
  }

  .ml-sm-2,.mx-sm-2{
    margin-left:.5rem !important
  }

  .m-sm-3{
    margin:1rem !important
  }

  .mt-sm-3,.my-sm-3{
    margin-top:1rem !important
  }

  .mr-sm-3,.mx-sm-3{
    margin-right:1rem !important
  }

  .mb-sm-3,.my-sm-3{
    margin-bottom:1rem !important
  }

  .ml-sm-3,.mx-sm-3{
    margin-left:1rem !important
  }

  .m-sm-4{
    margin:1.5rem !important
  }

  .mt-sm-4,.my-sm-4{
    margin-top:1.5rem !important
  }

  .mr-sm-4,.mx-sm-4{
    margin-right:1.5rem !important
  }

  .mb-sm-4,.my-sm-4{
    margin-bottom:1.5rem !important
  }

  .ml-sm-4,.mx-sm-4{
    margin-left:1.5rem !important
  }

  .m-sm-5{
    margin:3rem !important
  }

  .mt-sm-5,.my-sm-5{
    margin-top:3rem !important
  }

  .mr-sm-5,.mx-sm-5{
    margin-right:3rem !important
  }

  .mb-sm-5,.my-sm-5{
    margin-bottom:3rem !important
  }

  .ml-sm-5,.mx-sm-5{
    margin-left:3rem !important
  }

  .p-sm-0{
    padding:0 !important
  }

  .pt-sm-0,.py-sm-0{
    padding-top:0 !important
  }

  .pr-sm-0,.px-sm-0{
    padding-right:0 !important
  }

  .pb-sm-0,.py-sm-0{
    padding-bottom:0 !important
  }

  .pl-sm-0,.px-sm-0{
    padding-left:0 !important
  }

  .p-sm-1{
    padding:.25rem !important
  }

  .pt-sm-1,.py-sm-1{
    padding-top:.25rem !important
  }

  .pr-sm-1,.px-sm-1{
    padding-right:.25rem !important
  }

  .pb-sm-1,.py-sm-1{
    padding-bottom:.25rem !important
  }

  .pl-sm-1,.px-sm-1{
    padding-left:.25rem !important
  }

  .p-sm-2{
    padding:.5rem !important
  }

  .pt-sm-2,.py-sm-2{
    padding-top:.5rem !important
  }

  .pr-sm-2,.px-sm-2{
    padding-right:.5rem !important
  }

  .pb-sm-2,.py-sm-2{
    padding-bottom:.5rem !important
  }

  .pl-sm-2,.px-sm-2{
    padding-left:.5rem !important
  }

  .p-sm-3{
    padding:1rem !important
  }

  .pt-sm-3,.py-sm-3{
    padding-top:1rem !important
  }

  .pr-sm-3,.px-sm-3{
    padding-right:1rem !important
  }

  .pb-sm-3,.py-sm-3{
    padding-bottom:1rem !important
  }

  .pl-sm-3,.px-sm-3{
    padding-left:1rem !important
  }

  .p-sm-4{
    padding:1.5rem !important
  }

  .pt-sm-4,.py-sm-4{
    padding-top:1.5rem !important
  }

  .pr-sm-4,.px-sm-4{
    padding-right:1.5rem !important
  }

  .pb-sm-4,.py-sm-4{
    padding-bottom:1.5rem !important
  }

  .pl-sm-4,.px-sm-4{
    padding-left:1.5rem !important
  }

  .p-sm-5{
    padding:3rem !important
  }

  .pt-sm-5,.py-sm-5{
    padding-top:3rem !important
  }

  .pr-sm-5,.px-sm-5{
    padding-right:3rem !important
  }

  .pb-sm-5,.py-sm-5{
    padding-bottom:3rem !important
  }

  .pl-sm-5,.px-sm-5{
    padding-left:3rem !important
  }

  .m-sm-n1{
    margin:-0.25rem !important
  }

  .mt-sm-n1,.my-sm-n1{
    margin-top:-0.25rem !important
  }

  .mr-sm-n1,.mx-sm-n1{
    margin-right:-0.25rem !important
  }

  .mb-sm-n1,.my-sm-n1{
    margin-bottom:-0.25rem !important
  }

  .ml-sm-n1,.mx-sm-n1{
    margin-left:-0.25rem !important
  }

  .m-sm-n2{
    margin:-0.5rem !important
  }

  .mt-sm-n2,.my-sm-n2{
    margin-top:-0.5rem !important
  }

  .mr-sm-n2,.mx-sm-n2{
    margin-right:-0.5rem !important
  }

  .mb-sm-n2,.my-sm-n2{
    margin-bottom:-0.5rem !important
  }

  .ml-sm-n2,.mx-sm-n2{
    margin-left:-0.5rem !important
  }

  .m-sm-n3{
    margin:-1rem !important
  }

  .mt-sm-n3,.my-sm-n3{
    margin-top:-1rem !important
  }

  .mr-sm-n3,.mx-sm-n3{
    margin-right:-1rem !important
  }

  .mb-sm-n3,.my-sm-n3{
    margin-bottom:-1rem !important
  }

  .ml-sm-n3,.mx-sm-n3{
    margin-left:-1rem !important
  }

  .m-sm-n4{
    margin:-1.5rem !important
  }

  .mt-sm-n4,.my-sm-n4{
    margin-top:-1.5rem !important
  }

  .mr-sm-n4,.mx-sm-n4{
    margin-right:-1.5rem !important
  }

  .mb-sm-n4,.my-sm-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-sm-n4,.mx-sm-n4{
    margin-left:-1.5rem !important
  }

  .m-sm-n5{
    margin:-3rem !important
  }

  .mt-sm-n5,.my-sm-n5{
    margin-top:-3rem !important
  }

  .mr-sm-n5,.mx-sm-n5{
    margin-right:-3rem !important
  }

  .mb-sm-n5,.my-sm-n5{
    margin-bottom:-3rem !important
  }

  .ml-sm-n5,.mx-sm-n5{
    margin-left:-3rem !important
  }

  .m-sm-auto{
    margin:auto !important
  }

  .mt-sm-auto,.my-sm-auto{
    margin-top:auto !important
  }

  .mr-sm-auto,.mx-sm-auto{
    margin-right:auto !important
  }

  .mb-sm-auto,.my-sm-auto{
    margin-bottom:auto !important
  }

  .ml-sm-auto,.mx-sm-auto{
    margin-left:auto !important
  }
}

@media(min-width: 768px){
  .m-md-0{
    margin:0 !important
  }

  .mt-md-0,.my-md-0{
    margin-top:0 !important
  }

  .mr-md-0,.mx-md-0{
    margin-right:0 !important
  }

  .mb-md-0,.my-md-0{
    margin-bottom:0 !important
  }

  .ml-md-0,.mx-md-0{
    margin-left:0 !important
  }

  .m-md-1{
    margin:.25rem !important
  }

  .mt-md-1,.my-md-1{
    margin-top:.25rem !important
  }

  .mr-md-1,.mx-md-1{
    margin-right:.25rem !important
  }

  .mb-md-1,.my-md-1{
    margin-bottom:.25rem !important
  }

  .ml-md-1,.mx-md-1{
    margin-left:.25rem !important
  }

  .m-md-2{
    margin:.5rem !important
  }

  .mt-md-2,.my-md-2{
    margin-top:.5rem !important
  }

  .mr-md-2,.mx-md-2{
    margin-right:.5rem !important
  }

  .mb-md-2,.my-md-2{
    margin-bottom:.5rem !important
  }

  .ml-md-2,.mx-md-2{
    margin-left:.5rem !important
  }

  .m-md-3{
    margin:1rem !important
  }

  .mt-md-3,.my-md-3{
    margin-top:1rem !important
  }

  .mr-md-3,.mx-md-3{
    margin-right:1rem !important
  }

  .mb-md-3,.my-md-3{
    margin-bottom:1rem !important
  }

  .ml-md-3,.mx-md-3{
    margin-left:1rem !important
  }

  .m-md-4{
    margin:1.5rem !important
  }

  .mt-md-4,.my-md-4{
    margin-top:1.5rem !important
  }

  .mr-md-4,.mx-md-4{
    margin-right:1.5rem !important
  }

  .mb-md-4,.my-md-4{
    margin-bottom:1.5rem !important
  }

  .ml-md-4,.mx-md-4{
    margin-left:1.5rem !important
  }

  .m-md-5{
    margin:3rem !important
  }

  .mt-md-5,.my-md-5{
    margin-top:3rem !important
  }

  .mr-md-5,.mx-md-5{
    margin-right:3rem !important
  }

  .mb-md-5,.my-md-5{
    margin-bottom:3rem !important
  }

  .ml-md-5,.mx-md-5{
    margin-left:3rem !important
  }

  .p-md-0{
    padding:0 !important
  }

  .pt-md-0,.py-md-0{
    padding-top:0 !important
  }

  .pr-md-0,.px-md-0{
    padding-right:0 !important
  }

  .pb-md-0,.py-md-0{
    padding-bottom:0 !important
  }

  .pl-md-0,.px-md-0{
    padding-left:0 !important
  }

  .p-md-1{
    padding:.25rem !important
  }

  .pt-md-1,.py-md-1{
    padding-top:.25rem !important
  }

  .pr-md-1,.px-md-1{
    padding-right:.25rem !important
  }

  .pb-md-1,.py-md-1{
    padding-bottom:.25rem !important
  }

  .pl-md-1,.px-md-1{
    padding-left:.25rem !important
  }

  .p-md-2{
    padding:.5rem !important
  }

  .pt-md-2,.py-md-2{
    padding-top:.5rem !important
  }

  .pr-md-2,.px-md-2{
    padding-right:.5rem !important
  }

  .pb-md-2,.py-md-2{
    padding-bottom:.5rem !important
  }

  .pl-md-2,.px-md-2{
    padding-left:.5rem !important
  }

  .p-md-3{
    padding:1rem !important
  }

  .pt-md-3,.py-md-3{
    padding-top:1rem !important
  }

  .pr-md-3,.px-md-3{
    padding-right:1rem !important
  }

  .pb-md-3,.py-md-3{
    padding-bottom:1rem !important
  }

  .pl-md-3,.px-md-3{
    padding-left:1rem !important
  }

  .p-md-4{
    padding:1.5rem !important
  }

  .pt-md-4,.py-md-4{
    padding-top:1.5rem !important
  }

  .pr-md-4,.px-md-4{
    padding-right:1.5rem !important
  }

  .pb-md-4,.py-md-4{
    padding-bottom:1.5rem !important
  }

  .pl-md-4,.px-md-4{
    padding-left:1.5rem !important
  }

  .p-md-5{
    padding:3rem !important
  }

  .pt-md-5,.py-md-5{
    padding-top:3rem !important
  }

  .pr-md-5,.px-md-5{
    padding-right:3rem !important
  }

  .pb-md-5,.py-md-5{
    padding-bottom:3rem !important
  }

  .pl-md-5,.px-md-5{
    padding-left:3rem !important
  }

  .m-md-n1{
    margin:-0.25rem !important
  }

  .mt-md-n1,.my-md-n1{
    margin-top:-0.25rem !important
  }

  .mr-md-n1,.mx-md-n1{
    margin-right:-0.25rem !important
  }

  .mb-md-n1,.my-md-n1{
    margin-bottom:-0.25rem !important
  }

  .ml-md-n1,.mx-md-n1{
    margin-left:-0.25rem !important
  }

  .m-md-n2{
    margin:-0.5rem !important
  }

  .mt-md-n2,.my-md-n2{
    margin-top:-0.5rem !important
  }

  .mr-md-n2,.mx-md-n2{
    margin-right:-0.5rem !important
  }

  .mb-md-n2,.my-md-n2{
    margin-bottom:-0.5rem !important
  }

  .ml-md-n2,.mx-md-n2{
    margin-left:-0.5rem !important
  }

  .m-md-n3{
    margin:-1rem !important
  }

  .mt-md-n3,.my-md-n3{
    margin-top:-1rem !important
  }

  .mr-md-n3,.mx-md-n3{
    margin-right:-1rem !important
  }

  .mb-md-n3,.my-md-n3{
    margin-bottom:-1rem !important
  }

  .ml-md-n3,.mx-md-n3{
    margin-left:-1rem !important
  }

  .m-md-n4{
    margin:-1.5rem !important
  }

  .mt-md-n4,.my-md-n4{
    margin-top:-1.5rem !important
  }

  .mr-md-n4,.mx-md-n4{
    margin-right:-1.5rem !important
  }

  .mb-md-n4,.my-md-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-md-n4,.mx-md-n4{
    margin-left:-1.5rem !important
  }

  .m-md-n5{
    margin:-3rem !important
  }

  .mt-md-n5,.my-md-n5{
    margin-top:-3rem !important
  }

  .mr-md-n5,.mx-md-n5{
    margin-right:-3rem !important
  }

  .mb-md-n5,.my-md-n5{
    margin-bottom:-3rem !important
  }

  .ml-md-n5,.mx-md-n5{
    margin-left:-3rem !important
  }

  .m-md-auto{
    margin:auto !important
  }

  .mt-md-auto,.my-md-auto{
    margin-top:auto !important
  }

  .mr-md-auto,.mx-md-auto{
    margin-right:auto !important
  }

  .mb-md-auto,.my-md-auto{
    margin-bottom:auto !important
  }

  .ml-md-auto,.mx-md-auto{
    margin-left:auto !important
  }
}

@media(min-width: 1024px){
  .m-lg-0{
    margin:0 !important
  }

  .mt-lg-0,.my-lg-0{
    margin-top:0 !important
  }

  .mr-lg-0,.mx-lg-0{
    margin-right:0 !important
  }

  .mb-lg-0,.my-lg-0{
    margin-bottom:0 !important
  }

  .ml-lg-0,.mx-lg-0{
    margin-left:0 !important
  }

  .m-lg-1{
    margin:.25rem !important
  }

  .mt-lg-1,.my-lg-1{
    margin-top:.25rem !important
  }

  .mr-lg-1,.mx-lg-1{
    margin-right:.25rem !important
  }

  .mb-lg-1,.my-lg-1{
    margin-bottom:.25rem !important
  }

  .ml-lg-1,.mx-lg-1{
    margin-left:.25rem !important
  }

  .m-lg-2{
    margin:.5rem !important
  }

  .mt-lg-2,.my-lg-2{
    margin-top:.5rem !important
  }

  .mr-lg-2,.mx-lg-2{
    margin-right:.5rem !important
  }

  .mb-lg-2,.my-lg-2{
    margin-bottom:.5rem !important
  }

  .ml-lg-2,.mx-lg-2{
    margin-left:.5rem !important
  }

  .m-lg-3{
    margin:1rem !important
  }

  .mt-lg-3,.my-lg-3{
    margin-top:1rem !important
  }

  .mr-lg-3,.mx-lg-3{
    margin-right:1rem !important
  }

  .mb-lg-3,.my-lg-3{
    margin-bottom:1rem !important
  }

  .ml-lg-3,.mx-lg-3{
    margin-left:1rem !important
  }

  .m-lg-4{
    margin:1.5rem !important
  }

  .mt-lg-4,.my-lg-4{
    margin-top:1.5rem !important
  }

  .mr-lg-4,.mx-lg-4{
    margin-right:1.5rem !important
  }

  .mb-lg-4,.my-lg-4{
    margin-bottom:1.5rem !important
  }

  .ml-lg-4,.mx-lg-4{
    margin-left:1.5rem !important
  }

  .m-lg-5{
    margin:3rem !important
  }

  .mt-lg-5,.my-lg-5{
    margin-top:3rem !important
  }

  .mr-lg-5,.mx-lg-5{
    margin-right:3rem !important
  }

  .mb-lg-5,.my-lg-5{
    margin-bottom:3rem !important
  }

  .ml-lg-5,.mx-lg-5{
    margin-left:3rem !important
  }

  .p-lg-0{
    padding:0 !important
  }

  .pt-lg-0,.py-lg-0{
    padding-top:0 !important
  }

  .pr-lg-0,.px-lg-0{
    padding-right:0 !important
  }

  .pb-lg-0,.py-lg-0{
    padding-bottom:0 !important
  }

  .pl-lg-0,.px-lg-0{
    padding-left:0 !important
  }

  .p-lg-1{
    padding:.25rem !important
  }

  .pt-lg-1,.py-lg-1{
    padding-top:.25rem !important
  }

  .pr-lg-1,.px-lg-1{
    padding-right:.25rem !important
  }

  .pb-lg-1,.py-lg-1{
    padding-bottom:.25rem !important
  }

  .pl-lg-1,.px-lg-1{
    padding-left:.25rem !important
  }

  .p-lg-2{
    padding:.5rem !important
  }

  .pt-lg-2,.py-lg-2{
    padding-top:.5rem !important
  }

  .pr-lg-2,.px-lg-2{
    padding-right:.5rem !important
  }

  .pb-lg-2,.py-lg-2{
    padding-bottom:.5rem !important
  }

  .pl-lg-2,.px-lg-2{
    padding-left:.5rem !important
  }

  .p-lg-3{
    padding:1rem !important
  }

  .pt-lg-3,.py-lg-3{
    padding-top:1rem !important
  }

  .pr-lg-3,.px-lg-3{
    padding-right:1rem !important
  }

  .pb-lg-3,.py-lg-3{
    padding-bottom:1rem !important
  }

  .pl-lg-3,.px-lg-3{
    padding-left:1rem !important
  }

  .p-lg-4{
    padding:1.5rem !important
  }

  .pt-lg-4,.py-lg-4{
    padding-top:1.5rem !important
  }

  .pr-lg-4,.px-lg-4{
    padding-right:1.5rem !important
  }

  .pb-lg-4,.py-lg-4{
    padding-bottom:1.5rem !important
  }

  .pl-lg-4,.px-lg-4{
    padding-left:1.5rem !important
  }

  .p-lg-5{
    padding:3rem !important
  }

  .pt-lg-5,.py-lg-5{
    padding-top:3rem !important
  }

  .pr-lg-5,.px-lg-5{
    padding-right:3rem !important
  }

  .pb-lg-5,.py-lg-5{
    padding-bottom:3rem !important
  }

  .pl-lg-5,.px-lg-5{
    padding-left:3rem !important
  }

  .m-lg-n1{
    margin:-0.25rem !important
  }

  .mt-lg-n1,.my-lg-n1{
    margin-top:-0.25rem !important
  }

  .mr-lg-n1,.mx-lg-n1{
    margin-right:-0.25rem !important
  }

  .mb-lg-n1,.my-lg-n1{
    margin-bottom:-0.25rem !important
  }

  .ml-lg-n1,.mx-lg-n1{
    margin-left:-0.25rem !important
  }

  .m-lg-n2{
    margin:-0.5rem !important
  }

  .mt-lg-n2,.my-lg-n2{
    margin-top:-0.5rem !important
  }

  .mr-lg-n2,.mx-lg-n2{
    margin-right:-0.5rem !important
  }

  .mb-lg-n2,.my-lg-n2{
    margin-bottom:-0.5rem !important
  }

  .ml-lg-n2,.mx-lg-n2{
    margin-left:-0.5rem !important
  }

  .m-lg-n3{
    margin:-1rem !important
  }

  .mt-lg-n3,.my-lg-n3{
    margin-top:-1rem !important
  }

  .mr-lg-n3,.mx-lg-n3{
    margin-right:-1rem !important
  }

  .mb-lg-n3,.my-lg-n3{
    margin-bottom:-1rem !important
  }

  .ml-lg-n3,.mx-lg-n3{
    margin-left:-1rem !important
  }

  .m-lg-n4{
    margin:-1.5rem !important
  }

  .mt-lg-n4,.my-lg-n4{
    margin-top:-1.5rem !important
  }

  .mr-lg-n4,.mx-lg-n4{
    margin-right:-1.5rem !important
  }

  .mb-lg-n4,.my-lg-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-lg-n4,.mx-lg-n4{
    margin-left:-1.5rem !important
  }

  .m-lg-n5{
    margin:-3rem !important
  }

  .mt-lg-n5,.my-lg-n5{
    margin-top:-3rem !important
  }

  .mr-lg-n5,.mx-lg-n5{
    margin-right:-3rem !important
  }

  .mb-lg-n5,.my-lg-n5{
    margin-bottom:-3rem !important
  }

  .ml-lg-n5,.mx-lg-n5{
    margin-left:-3rem !important
  }

  .m-lg-auto{
    margin:auto !important
  }

  .mt-lg-auto,.my-lg-auto{
    margin-top:auto !important
  }

  .mr-lg-auto,.mx-lg-auto{
    margin-right:auto !important
  }

  .mb-lg-auto,.my-lg-auto{
    margin-bottom:auto !important
  }

  .ml-lg-auto,.mx-lg-auto{
    margin-left:auto !important
  }
}

@media(min-width: 1280px){
  .m-xl-0{
    margin:0 !important
  }

  .mt-xl-0,.my-xl-0{
    margin-top:0 !important
  }

  .mr-xl-0,.mx-xl-0{
    margin-right:0 !important
  }

  .mb-xl-0,.my-xl-0{
    margin-bottom:0 !important
  }

  .ml-xl-0,.mx-xl-0{
    margin-left:0 !important
  }

  .m-xl-1{
    margin:.25rem !important
  }

  .mt-xl-1,.my-xl-1{
    margin-top:.25rem !important
  }

  .mr-xl-1,.mx-xl-1{
    margin-right:.25rem !important
  }

  .mb-xl-1,.my-xl-1{
    margin-bottom:.25rem !important
  }

  .ml-xl-1,.mx-xl-1{
    margin-left:.25rem !important
  }

  .m-xl-2{
    margin:.5rem !important
  }

  .mt-xl-2,.my-xl-2{
    margin-top:.5rem !important
  }

  .mr-xl-2,.mx-xl-2{
    margin-right:.5rem !important
  }

  .mb-xl-2,.my-xl-2{
    margin-bottom:.5rem !important
  }

  .ml-xl-2,.mx-xl-2{
    margin-left:.5rem !important
  }

  .m-xl-3{
    margin:1rem !important
  }

  .mt-xl-3,.my-xl-3{
    margin-top:1rem !important
  }

  .mr-xl-3,.mx-xl-3{
    margin-right:1rem !important
  }

  .mb-xl-3,.my-xl-3{
    margin-bottom:1rem !important
  }

  .ml-xl-3,.mx-xl-3{
    margin-left:1rem !important
  }

  .m-xl-4{
    margin:1.5rem !important
  }

  .mt-xl-4,.my-xl-4{
    margin-top:1.5rem !important
  }

  .mr-xl-4,.mx-xl-4{
    margin-right:1.5rem !important
  }

  .mb-xl-4,.my-xl-4{
    margin-bottom:1.5rem !important
  }

  .ml-xl-4,.mx-xl-4{
    margin-left:1.5rem !important
  }

  .m-xl-5{
    margin:3rem !important
  }

  .mt-xl-5,.my-xl-5{
    margin-top:3rem !important
  }

  .mr-xl-5,.mx-xl-5{
    margin-right:3rem !important
  }

  .mb-xl-5,.my-xl-5{
    margin-bottom:3rem !important
  }

  .ml-xl-5,.mx-xl-5{
    margin-left:3rem !important
  }

  .p-xl-0{
    padding:0 !important
  }

  .pt-xl-0,.py-xl-0{
    padding-top:0 !important
  }

  .pr-xl-0,.px-xl-0{
    padding-right:0 !important
  }

  .pb-xl-0,.py-xl-0{
    padding-bottom:0 !important
  }

  .pl-xl-0,.px-xl-0{
    padding-left:0 !important
  }

  .p-xl-1{
    padding:.25rem !important
  }

  .pt-xl-1,.py-xl-1{
    padding-top:.25rem !important
  }

  .pr-xl-1,.px-xl-1{
    padding-right:.25rem !important
  }

  .pb-xl-1,.py-xl-1{
    padding-bottom:.25rem !important
  }

  .pl-xl-1,.px-xl-1{
    padding-left:.25rem !important
  }

  .p-xl-2{
    padding:.5rem !important
  }

  .pt-xl-2,.py-xl-2{
    padding-top:.5rem !important
  }

  .pr-xl-2,.px-xl-2{
    padding-right:.5rem !important
  }

  .pb-xl-2,.py-xl-2{
    padding-bottom:.5rem !important
  }

  .pl-xl-2,.px-xl-2{
    padding-left:.5rem !important
  }

  .p-xl-3{
    padding:1rem !important
  }

  .pt-xl-3,.py-xl-3{
    padding-top:1rem !important
  }

  .pr-xl-3,.px-xl-3{
    padding-right:1rem !important
  }

  .pb-xl-3,.py-xl-3{
    padding-bottom:1rem !important
  }

  .pl-xl-3,.px-xl-3{
    padding-left:1rem !important
  }

  .p-xl-4{
    padding:1.5rem !important
  }

  .pt-xl-4,.py-xl-4{
    padding-top:1.5rem !important
  }

  .pr-xl-4,.px-xl-4{
    padding-right:1.5rem !important
  }

  .pb-xl-4,.py-xl-4{
    padding-bottom:1.5rem !important
  }

  .pl-xl-4,.px-xl-4{
    padding-left:1.5rem !important
  }

  .p-xl-5{
    padding:3rem !important
  }

  .pt-xl-5,.py-xl-5{
    padding-top:3rem !important
  }

  .pr-xl-5,.px-xl-5{
    padding-right:3rem !important
  }

  .pb-xl-5,.py-xl-5{
    padding-bottom:3rem !important
  }

  .pl-xl-5,.px-xl-5{
    padding-left:3rem !important
  }

  .m-xl-n1{
    margin:-0.25rem !important
  }

  .mt-xl-n1,.my-xl-n1{
    margin-top:-0.25rem !important
  }

  .mr-xl-n1,.mx-xl-n1{
    margin-right:-0.25rem !important
  }

  .mb-xl-n1,.my-xl-n1{
    margin-bottom:-0.25rem !important
  }

  .ml-xl-n1,.mx-xl-n1{
    margin-left:-0.25rem !important
  }

  .m-xl-n2{
    margin:-0.5rem !important
  }

  .mt-xl-n2,.my-xl-n2{
    margin-top:-0.5rem !important
  }

  .mr-xl-n2,.mx-xl-n2{
    margin-right:-0.5rem !important
  }

  .mb-xl-n2,.my-xl-n2{
    margin-bottom:-0.5rem !important
  }

  .ml-xl-n2,.mx-xl-n2{
    margin-left:-0.5rem !important
  }

  .m-xl-n3{
    margin:-1rem !important
  }

  .mt-xl-n3,.my-xl-n3{
    margin-top:-1rem !important
  }

  .mr-xl-n3,.mx-xl-n3{
    margin-right:-1rem !important
  }

  .mb-xl-n3,.my-xl-n3{
    margin-bottom:-1rem !important
  }

  .ml-xl-n3,.mx-xl-n3{
    margin-left:-1rem !important
  }

  .m-xl-n4{
    margin:-1.5rem !important
  }

  .mt-xl-n4,.my-xl-n4{
    margin-top:-1.5rem !important
  }

  .mr-xl-n4,.mx-xl-n4{
    margin-right:-1.5rem !important
  }

  .mb-xl-n4,.my-xl-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-xl-n4,.mx-xl-n4{
    margin-left:-1.5rem !important
  }

  .m-xl-n5{
    margin:-3rem !important
  }

  .mt-xl-n5,.my-xl-n5{
    margin-top:-3rem !important
  }

  .mr-xl-n5,.mx-xl-n5{
    margin-right:-3rem !important
  }

  .mb-xl-n5,.my-xl-n5{
    margin-bottom:-3rem !important
  }

  .ml-xl-n5,.mx-xl-n5{
    margin-left:-3rem !important
  }

  .m-xl-auto{
    margin:auto !important
  }

  .mt-xl-auto,.my-xl-auto{
    margin-top:auto !important
  }

  .mr-xl-auto,.mx-xl-auto{
    margin-right:auto !important
  }

  .mb-xl-auto,.my-xl-auto{
    margin-bottom:auto !important
  }

  .ml-xl-auto,.mx-xl-auto{
    margin-left:auto !important
  }
}

.text-monospace{
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important
}

.text-justify{
  text-align:justify !important
}

.text-wrap{
  white-space:normal !important
}

.text-nowrap{
  white-space:nowrap !important
}

.text-truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

.text-left{
  text-align:left !important
}

.text-right{
  text-align:right !important
}

.text-center{
  text-align:center !important
}

@media(min-width: 640px){
  .text-sm-left{
    text-align:left !important
  }

  .text-sm-right{
    text-align:right !important
  }

  .text-sm-center{
    text-align:center !important
  }
}

@media(min-width: 768px){
  .text-md-left{
    text-align:left !important
  }

  .text-md-right{
    text-align:right !important
  }

  .text-md-center{
    text-align:center !important
  }
}

@media(min-width: 1024px){
  .text-lg-left{
    text-align:left !important
  }

  .text-lg-right{
    text-align:right !important
  }

  .text-lg-center{
    text-align:center !important
  }
}

@media(min-width: 1280px){
  .text-xl-left{
    text-align:left !important
  }

  .text-xl-right{
    text-align:right !important
  }

  .text-xl-center{
    text-align:center !important
  }
}

.text-lowercase{
  text-transform:lowercase !important
}

.text-uppercase{
  text-transform:uppercase !important
}

.text-capitalize{
  text-transform:capitalize !important
}

.font-weight-light{
  font-weight:300 !important
}

.font-weight-lighter{
  font-weight:lighter !important
}

.font-weight-normal{
  font-weight:400 !important
}

.font-weight-bold{
  font-weight:700 !important
}

.font-weight-bolder{
  font-weight:bolder !important
}

.font-italic{
  font-style:italic !important
}

.text-white{
  color:#fff !important
}

.text-primary{
  color:#00adbb !important
}

a.text-primary:hover,a.text-primary:focus{
  color:#00666f !important
}

.text-secondary{
  color:#6c757d !important
}

a.text-secondary:hover,a.text-secondary:focus{
  color:#494f54 !important
}

.text-success{
  color:#28a745 !important
}

a.text-success:hover,a.text-success:focus{
  color:#19692c !important
}

.text-info{
  color:#17a2b8 !important
}

a.text-info:hover,a.text-info:focus{
  color:#0f6674 !important
}

.text-warning{
  color:#ffc107 !important
}

a.text-warning:hover,a.text-warning:focus{
  color:#ba8b00 !important
}

.text-danger{
  color:#dc3545 !important
}

a.text-danger:hover,a.text-danger:focus{
  color:#a71d2a !important
}

.text-light{
  color:#f8f9fa !important
}

a.text-light:hover,a.text-light:focus{
  color:#cbd3da !important
}

.text-dark{
  color:#343a40 !important
}

a.text-dark:hover,a.text-dark:focus{
  color:#121416 !important
}

.text-tlg-green{
  color:#a4ca61 !important
}

a.text-tlg-green:hover,a.text-tlg-green:focus{
  color:#7fa738 !important
}

.text-tlg-teal{
  color:#00adbb !important
}

a.text-tlg-teal:hover,a.text-tlg-teal:focus{
  color:#00666f !important
}

.text-tlg-purple{
  color:#685bc7 !important
}

a.text-tlg-purple:hover,a.text-tlg-purple:focus{
  color:#43369f !important
}

.text-tlg-purple-light{
  color:#af7cb6 !important
}

a.text-tlg-purple-light:hover,a.text-tlg-purple-light:focus{
  color:#8c5293 !important
}

.text-tlg-orange{
  color:#e87722 !important
}

a.text-tlg-orange:hover,a.text-tlg-orange:focus{
  color:#ac5412 !important
}

.text-tlg-orange-light{
  color:#f6c7a4 !important
}

a.text-tlg-orange-light:hover,a.text-tlg-orange-light:focus{
  color:#ef9c5e !important
}

.text-tlg-orange-500{
  color:#ff7f41 !important
}

a.text-tlg-orange-500:hover,a.text-tlg-orange-500:focus{
  color:#f44f00 !important
}

.text-tlg-green-dark{
  color:#76b84e !important
}

a.text-tlg-green-dark:hover,a.text-tlg-green-dark:focus{
  color:#538435 !important
}

.text-body{
  color:#212529 !important
}

.text-muted{
  color:#6c757d !important
}

.text-black-50{
  color:rgba(0,0,0,.5) !important
}

.text-white-50{
  color:rgba(255,255,255,.5) !important
}

.text-hide{
  font:0/0 a;
  color:transparent;
  text-shadow:none;
  background-color:transparent;
  border:0
}

.text-decoration-none{
  text-decoration:none !important
}

.text-break{
  word-break:break-word !important;
  overflow-wrap:break-word !important
}

.text-reset{
  color:inherit !important
}

.visible{
  visibility:visible !important
}

.invisible{
  visibility:hidden !important
}

@media print{
  *,*::before,*::after{
    text-shadow:none !important;
    box-shadow:none !important
  }

  a:not(.btn){
    text-decoration:underline
  }

  abbr[title]::after{
    content:" (" attr(title) ")"
  }

  pre{
    white-space:pre-wrap !important
  }

  pre,blockquote{
    border:1px solid #adb5bd;
    page-break-inside:avoid
  }

  thead{
    display:table-header-group
  }

  tr,img{
    page-break-inside:avoid
  }

  p,h2,h3{
    orphans:3;
    widows:3
  }

  h2,h3{
    page-break-after:avoid
  }

  @page{
    size:a3
  }

  body{
    min-width:1024px !important
  }

  .container{
    min-width:1024px !important
  }

  .navbar{
    display:none
  }

  .badge{
    border:1px solid #000
  }

  .table{
    border-collapse:collapse !important
  }

  .table td,.table th{
    background-color:#fff !important
  }

  .table-bordered th,.table-bordered td{
    border:1px solid #dee2e6 !important
  }

  .table-dark{
    color:inherit
  }

  .table-dark th,.table-dark td,.table-dark thead th,.table-dark tbody+tbody{
    border-color:#dee2e6
  }

  .table .thead-dark th{
    color:inherit;
    border-color:#dee2e6
  }
}

.tw-container{
  width: 100%
}

@media (min-width: 640px){
  .tw-container{
    max-width: 640px
  }
}

@media (min-width: 768px){
  .tw-container{
    max-width: 768px
  }
}

@media (min-width: 1024px){
  .tw-container{
    max-width: 1024px
  }
}

@media (min-width: 1280px){
  .tw-container{
    max-width: 1280px
  }
}

.tw-space-y-0 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0px * var(--space-y-reverse))
}

.tw-space-x-0 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-1 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse))
}

.tw-space-x-1 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-2 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--space-y-reverse))
}

.tw-space-x-2 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-3 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--space-y-reverse))
}

.tw-space-x-3 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-4 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse))
}

.tw-space-x-4 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-5 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--space-y-reverse))
}

.tw-space-x-5 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-6 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--space-y-reverse))
}

.tw-space-x-6 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-8 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2rem * var(--space-y-reverse))
}

.tw-space-x-8 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(2rem * var(--space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-10 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--space-y-reverse))
}

.tw-space-x-10 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-12 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(3rem * var(--space-y-reverse))
}

.tw-space-x-12 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(3rem * var(--space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-16 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(4rem * var(--space-y-reverse))
}

.tw-space-x-16 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(4rem * var(--space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-20 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(5rem * var(--space-y-reverse))
}

.tw-space-x-20 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(5rem * var(--space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-24 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(6rem * var(--space-y-reverse))
}

.tw-space-x-24 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(6rem * var(--space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-32 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(8rem * var(--space-y-reverse))
}

.tw-space-x-32 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(8rem * var(--space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-40 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(10rem * var(--space-y-reverse))
}

.tw-space-x-40 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(10rem * var(--space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-48 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(12rem * var(--space-y-reverse))
}

.tw-space-x-48 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(12rem * var(--space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-56 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(14rem * var(--space-y-reverse))
}

.tw-space-x-56 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(14rem * var(--space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-64 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(16rem * var(--space-y-reverse))
}

.tw-space-x-64 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(16rem * var(--space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-px > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1px * var(--space-y-reverse))
}

.tw-space-x-px > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(1px * var(--space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-1 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--space-y-reverse))
}

.tw--space-x-1 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-2 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--space-y-reverse))
}

.tw--space-x-2 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-3 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--space-y-reverse))
}

.tw--space-x-3 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-4 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1rem * var(--space-y-reverse))
}

.tw--space-x-4 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-1rem * var(--space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-5 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--space-y-reverse))
}

.tw--space-x-5 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-6 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--space-y-reverse))
}

.tw--space-x-6 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-8 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2rem * var(--space-y-reverse))
}

.tw--space-x-8 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-2rem * var(--space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-10 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--space-y-reverse))
}

.tw--space-x-10 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-12 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-3rem * var(--space-y-reverse))
}

.tw--space-x-12 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-3rem * var(--space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-16 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-4rem * var(--space-y-reverse))
}

.tw--space-x-16 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-4rem * var(--space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-20 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-5rem * var(--space-y-reverse))
}

.tw--space-x-20 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-5rem * var(--space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-24 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-6rem * var(--space-y-reverse))
}

.tw--space-x-24 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-6rem * var(--space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-32 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-8rem * var(--space-y-reverse))
}

.tw--space-x-32 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-8rem * var(--space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-40 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-10rem * var(--space-y-reverse))
}

.tw--space-x-40 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-10rem * var(--space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-48 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-12rem * var(--space-y-reverse))
}

.tw--space-x-48 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-12rem * var(--space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-56 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-14rem * var(--space-y-reverse))
}

.tw--space-x-56 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-14rem * var(--space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-64 > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-16rem * var(--space-y-reverse))
}

.tw--space-x-64 > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-16rem * var(--space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
}

.tw--space-y-px > :not(template) ~ :not(template){
  --space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1px * var(--space-y-reverse))
}

.tw--space-x-px > :not(template) ~ :not(template){
  --space-x-reverse: 0;
  margin-right: calc(-1px * var(--space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
}

.tw-space-y-reverse > :not(template) ~ :not(template){
  --space-y-reverse: 1
}

.tw-space-x-reverse > :not(template) ~ :not(template){
  --space-x-reverse: 1
}

.tw-divide-y-0 > :not(template) ~ :not(template){
  --divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(0px * var(--divide-y-reverse))
}

.tw-divide-x-0 > :not(template) ~ :not(template){
  --divide-x-reverse: 0;
  border-right-width: calc(0px * var(--divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
}

.tw-divide-y-2 > :not(template) ~ :not(template){
  --divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(2px * var(--divide-y-reverse))
}

.tw-divide-x-2 > :not(template) ~ :not(template){
  --divide-x-reverse: 0;
  border-right-width: calc(2px * var(--divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
}

.tw-divide-y-4 > :not(template) ~ :not(template){
  --divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(4px * var(--divide-y-reverse))
}

.tw-divide-x-4 > :not(template) ~ :not(template){
  --divide-x-reverse: 0;
  border-right-width: calc(4px * var(--divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
}

.tw-divide-y-8 > :not(template) ~ :not(template){
  --divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(8px * var(--divide-y-reverse))
}

.tw-divide-x-8 > :not(template) ~ :not(template){
  --divide-x-reverse: 0;
  border-right-width: calc(8px * var(--divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
}

.tw-divide-y > :not(template) ~ :not(template){
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse))
}

.tw-divide-x > :not(template) ~ :not(template){
  --divide-x-reverse: 0;
  border-right-width: calc(1px * var(--divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
}

.tw-divide-y-reverse > :not(template) ~ :not(template){
  --divide-y-reverse: 1
}

.tw-divide-x-reverse > :not(template) ~ :not(template){
  --divide-x-reverse: 1
}

.tw-divide-transparent > :not(template) ~ :not(template){
  border-color: transparent
}

.tw-divide-tlg-green-dark > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #76b84e;
  border-color: rgba(118, 184, 78, var(--divide-opacity))
}

.tw-divide-tlg-green > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #a4ca61;
  border-color: rgba(164, 202, 97, var(--divide-opacity))
}

.tw-divide-tlg-orange-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #ff7f41;
  border-color: rgba(255, 127, 65, var(--divide-opacity))
}

.tw-divide-black > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--divide-opacity))
}

.tw-divide-white > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--divide-opacity))
}

.tw-divide-gray-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--divide-opacity))
}

.tw-divide-gray-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--divide-opacity))
}

.tw-divide-gray-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--divide-opacity))
}

.tw-divide-gray-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--divide-opacity))
}

.tw-divide-gray-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--divide-opacity))
}

.tw-divide-gray-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--divide-opacity))
}

.tw-divide-gray-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--divide-opacity))
}

.tw-divide-gray-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--divide-opacity))
}

.tw-divide-gray-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--divide-opacity))
}

.tw-divide-red-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--divide-opacity))
}

.tw-divide-red-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--divide-opacity))
}

.tw-divide-red-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--divide-opacity))
}

.tw-divide-red-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--divide-opacity))
}

.tw-divide-red-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--divide-opacity))
}

.tw-divide-red-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--divide-opacity))
}

.tw-divide-red-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--divide-opacity))
}

.tw-divide-red-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--divide-opacity))
}

.tw-divide-red-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--divide-opacity))
}

.tw-divide-orange-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--divide-opacity))
}

.tw-divide-orange-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--divide-opacity))
}

.tw-divide-orange-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--divide-opacity))
}

.tw-divide-orange-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--divide-opacity))
}

.tw-divide-orange-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--divide-opacity))
}

.tw-divide-orange-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--divide-opacity))
}

.tw-divide-orange-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--divide-opacity))
}

.tw-divide-orange-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--divide-opacity))
}

.tw-divide-orange-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--divide-opacity))
}

.tw-divide-yellow-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--divide-opacity))
}

.tw-divide-yellow-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--divide-opacity))
}

.tw-divide-yellow-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--divide-opacity))
}

.tw-divide-yellow-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--divide-opacity))
}

.tw-divide-yellow-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--divide-opacity))
}

.tw-divide-yellow-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--divide-opacity))
}

.tw-divide-yellow-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--divide-opacity))
}

.tw-divide-yellow-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--divide-opacity))
}

.tw-divide-yellow-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--divide-opacity))
}

.tw-divide-green-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--divide-opacity))
}

.tw-divide-green-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--divide-opacity))
}

.tw-divide-green-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--divide-opacity))
}

.tw-divide-green-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--divide-opacity))
}

.tw-divide-green-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--divide-opacity))
}

.tw-divide-green-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--divide-opacity))
}

.tw-divide-green-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--divide-opacity))
}

.tw-divide-green-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--divide-opacity))
}

.tw-divide-green-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--divide-opacity))
}

.tw-divide-teal-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--divide-opacity))
}

.tw-divide-teal-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--divide-opacity))
}

.tw-divide-teal-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--divide-opacity))
}

.tw-divide-teal-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--divide-opacity))
}

.tw-divide-teal-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--divide-opacity))
}

.tw-divide-teal-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--divide-opacity))
}

.tw-divide-teal-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--divide-opacity))
}

.tw-divide-teal-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--divide-opacity))
}

.tw-divide-teal-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--divide-opacity))
}

.tw-divide-blue-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--divide-opacity))
}

.tw-divide-blue-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--divide-opacity))
}

.tw-divide-blue-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--divide-opacity))
}

.tw-divide-blue-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--divide-opacity))
}

.tw-divide-blue-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--divide-opacity))
}

.tw-divide-blue-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--divide-opacity))
}

.tw-divide-blue-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--divide-opacity))
}

.tw-divide-blue-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--divide-opacity))
}

.tw-divide-blue-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--divide-opacity))
}

.tw-divide-indigo-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--divide-opacity))
}

.tw-divide-indigo-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--divide-opacity))
}

.tw-divide-indigo-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--divide-opacity))
}

.tw-divide-indigo-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--divide-opacity))
}

.tw-divide-indigo-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--divide-opacity))
}

.tw-divide-indigo-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--divide-opacity))
}

.tw-divide-indigo-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--divide-opacity))
}

.tw-divide-indigo-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--divide-opacity))
}

.tw-divide-indigo-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--divide-opacity))
}

.tw-divide-purple-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--divide-opacity))
}

.tw-divide-purple-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--divide-opacity))
}

.tw-divide-purple-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--divide-opacity))
}

.tw-divide-purple-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--divide-opacity))
}

.tw-divide-purple-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--divide-opacity))
}

.tw-divide-purple-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--divide-opacity))
}

.tw-divide-purple-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--divide-opacity))
}

.tw-divide-purple-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--divide-opacity))
}

.tw-divide-purple-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--divide-opacity))
}

.tw-divide-pink-100 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--divide-opacity))
}

.tw-divide-pink-200 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--divide-opacity))
}

.tw-divide-pink-300 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--divide-opacity))
}

.tw-divide-pink-400 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--divide-opacity))
}

.tw-divide-pink-500 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--divide-opacity))
}

.tw-divide-pink-600 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--divide-opacity))
}

.tw-divide-pink-700 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--divide-opacity))
}

.tw-divide-pink-800 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--divide-opacity))
}

.tw-divide-pink-900 > :not(template) ~ :not(template){
  --divide-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--divide-opacity))
}

.tw-divide-solid > :not(template) ~ :not(template){
  border-style: solid
}

.tw-divide-dashed > :not(template) ~ :not(template){
  border-style: dashed
}

.tw-divide-dotted > :not(template) ~ :not(template){
  border-style: dotted
}

.tw-divide-double > :not(template) ~ :not(template){
  border-style: double
}

.tw-divide-none > :not(template) ~ :not(template){
  border-style: none
}

.tw-divide-opacity-0 > :not(template) ~ :not(template){
  --divide-opacity: 0
}

.tw-divide-opacity-25 > :not(template) ~ :not(template){
  --divide-opacity: 0.25
}

.tw-divide-opacity-50 > :not(template) ~ :not(template){
  --divide-opacity: 0.5
}

.tw-divide-opacity-75 > :not(template) ~ :not(template){
  --divide-opacity: 0.75
}

.tw-divide-opacity-100 > :not(template) ~ :not(template){
  --divide-opacity: 1
}

.tw-sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.tw-not-sr-only{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.focus\:tw-sr-only:focus{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.focus\:tw-not-sr-only:focus{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.tw-appearance-none{
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.tw-bg-fixed{
  background-attachment: fixed
}

.tw-bg-local{
  background-attachment: local
}

.tw-bg-scroll{
  background-attachment: scroll
}

.tw-bg-clip-border{
  background-clip: border-box
}

.tw-bg-clip-padding{
  background-clip: padding-box
}

.tw-bg-clip-content{
  background-clip: content-box
}

.tw-bg-clip-text{
  -webkit-background-clip: text;
          background-clip: text
}

.tw-bg-transparent{
  background-color: transparent
}

.tw-bg-tlg-green-dark{
  --bg-opacity: 1;
  background-color: #76b84e;
  background-color: rgba(118, 184, 78, var(--bg-opacity))
}

.tw-bg-tlg-green{
  --bg-opacity: 1;
  background-color: #a4ca61;
  background-color: rgba(164, 202, 97, var(--bg-opacity))
}

.tw-bg-tlg-orange-500{
  --bg-opacity: 1;
  background-color: #ff7f41;
  background-color: rgba(255, 127, 65, var(--bg-opacity))
}

.tw-bg-black{
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.tw-bg-white{
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.tw-bg-gray-100{
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.tw-bg-gray-200{
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.tw-bg-gray-300{
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.tw-bg-gray-400{
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.tw-bg-gray-500{
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity))
}

.tw-bg-gray-600{
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity))
}

.tw-bg-gray-700{
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity))
}

.tw-bg-gray-800{
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity))
}

.tw-bg-gray-900{
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity))
}

.tw-bg-red-100{
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity))
}

.tw-bg-red-200{
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.tw-bg-red-300{
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.tw-bg-red-400{
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity))
}

.tw-bg-red-500{
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity))
}

.tw-bg-red-600{
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity))
}

.tw-bg-red-700{
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity))
}

.tw-bg-red-800{
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity))
}

.tw-bg-red-900{
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity))
}

.tw-bg-orange-100{
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity))
}

.tw-bg-orange-200{
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity))
}

.tw-bg-orange-300{
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.tw-bg-orange-400{
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity))
}

.tw-bg-orange-500{
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity))
}

.tw-bg-orange-600{
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity))
}

.tw-bg-orange-700{
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity))
}

.tw-bg-orange-800{
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity))
}

.tw-bg-orange-900{
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity))
}

.tw-bg-yellow-100{
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity))
}

.tw-bg-yellow-200{
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.tw-bg-yellow-300{
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.tw-bg-yellow-400{
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity))
}

.tw-bg-yellow-500{
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity))
}

.tw-bg-yellow-600{
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity))
}

.tw-bg-yellow-700{
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity))
}

.tw-bg-yellow-800{
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity))
}

.tw-bg-yellow-900{
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity))
}

.tw-bg-green-100{
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity))
}

.tw-bg-green-200{
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity))
}

.tw-bg-green-300{
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity))
}

.tw-bg-green-400{
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity))
}

.tw-bg-green-500{
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity))
}

.tw-bg-green-600{
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity))
}

.tw-bg-green-700{
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity))
}

.tw-bg-green-800{
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity))
}

.tw-bg-green-900{
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity))
}

.tw-bg-teal-100{
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity))
}

.tw-bg-teal-200{
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity))
}

.tw-bg-teal-300{
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity))
}

.tw-bg-teal-400{
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity))
}

.tw-bg-teal-500{
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity))
}

.tw-bg-teal-600{
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity))
}

.tw-bg-teal-700{
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity))
}

.tw-bg-teal-800{
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity))
}

.tw-bg-teal-900{
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity))
}

.tw-bg-blue-100{
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.tw-bg-blue-200{
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.tw-bg-blue-300{
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.tw-bg-blue-400{
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity))
}

.tw-bg-blue-500{
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity))
}

.tw-bg-blue-600{
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.tw-bg-blue-700{
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.tw-bg-blue-800{
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity))
}

.tw-bg-blue-900{
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity))
}

.tw-bg-indigo-100{
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity))
}

.tw-bg-indigo-200{
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity))
}

.tw-bg-indigo-300{
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity))
}

.tw-bg-indigo-400{
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity))
}

.tw-bg-indigo-500{
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity))
}

.tw-bg-indigo-600{
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity))
}

.tw-bg-indigo-700{
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity))
}

.tw-bg-indigo-800{
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity))
}

.tw-bg-indigo-900{
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity))
}

.tw-bg-purple-100{
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity))
}

.tw-bg-purple-200{
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity))
}

.tw-bg-purple-300{
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity))
}

.tw-bg-purple-400{
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity))
}

.tw-bg-purple-500{
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity))
}

.tw-bg-purple-600{
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity))
}

.tw-bg-purple-700{
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity))
}

.tw-bg-purple-800{
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity))
}

.tw-bg-purple-900{
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity))
}

.tw-bg-pink-100{
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity))
}

.tw-bg-pink-200{
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity))
}

.tw-bg-pink-300{
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity))
}

.tw-bg-pink-400{
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity))
}

.tw-bg-pink-500{
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity))
}

.tw-bg-pink-600{
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity))
}

.tw-bg-pink-700{
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity))
}

.tw-bg-pink-800{
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity))
}

.tw-bg-pink-900{
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity))
}

.hover\:tw-bg-transparent:hover{
  background-color: transparent
}

.hover\:tw-bg-tlg-green-dark:hover{
  --bg-opacity: 1;
  background-color: #76b84e;
  background-color: rgba(118, 184, 78, var(--bg-opacity))
}

.hover\:tw-bg-tlg-green:hover{
  --bg-opacity: 1;
  background-color: #a4ca61;
  background-color: rgba(164, 202, 97, var(--bg-opacity))
}

.hover\:tw-bg-tlg-orange-500:hover{
  --bg-opacity: 1;
  background-color: #ff7f41;
  background-color: rgba(255, 127, 65, var(--bg-opacity))
}

.hover\:tw-bg-black:hover{
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.hover\:tw-bg-white:hover{
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.hover\:tw-bg-gray-100:hover{
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.hover\:tw-bg-gray-200:hover{
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.hover\:tw-bg-gray-300:hover{
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.hover\:tw-bg-gray-400:hover{
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.hover\:tw-bg-gray-500:hover{
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity))
}

.hover\:tw-bg-gray-600:hover{
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity))
}

.hover\:tw-bg-gray-700:hover{
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity))
}

.hover\:tw-bg-gray-800:hover{
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity))
}

.hover\:tw-bg-gray-900:hover{
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity))
}

.hover\:tw-bg-red-100:hover{
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity))
}

.hover\:tw-bg-red-200:hover{
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.hover\:tw-bg-red-300:hover{
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.hover\:tw-bg-red-400:hover{
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity))
}

.hover\:tw-bg-red-500:hover{
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity))
}

.hover\:tw-bg-red-600:hover{
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity))
}

.hover\:tw-bg-red-700:hover{
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity))
}

.hover\:tw-bg-red-800:hover{
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity))
}

.hover\:tw-bg-red-900:hover{
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity))
}

.hover\:tw-bg-orange-100:hover{
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity))
}

.hover\:tw-bg-orange-200:hover{
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity))
}

.hover\:tw-bg-orange-300:hover{
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.hover\:tw-bg-orange-400:hover{
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity))
}

.hover\:tw-bg-orange-500:hover{
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity))
}

.hover\:tw-bg-orange-600:hover{
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity))
}

.hover\:tw-bg-orange-700:hover{
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity))
}

.hover\:tw-bg-orange-800:hover{
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity))
}

.hover\:tw-bg-orange-900:hover{
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity))
}

.hover\:tw-bg-yellow-100:hover{
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity))
}

.hover\:tw-bg-yellow-200:hover{
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.hover\:tw-bg-yellow-300:hover{
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.hover\:tw-bg-yellow-400:hover{
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity))
}

.hover\:tw-bg-yellow-500:hover{
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity))
}

.hover\:tw-bg-yellow-600:hover{
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity))
}

.hover\:tw-bg-yellow-700:hover{
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity))
}

.hover\:tw-bg-yellow-800:hover{
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity))
}

.hover\:tw-bg-yellow-900:hover{
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity))
}

.hover\:tw-bg-green-100:hover{
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity))
}

.hover\:tw-bg-green-200:hover{
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity))
}

.hover\:tw-bg-green-300:hover{
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity))
}

.hover\:tw-bg-green-400:hover{
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity))
}

.hover\:tw-bg-green-500:hover{
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity))
}

.hover\:tw-bg-green-600:hover{
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity))
}

.hover\:tw-bg-green-700:hover{
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity))
}

.hover\:tw-bg-green-800:hover{
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity))
}

.hover\:tw-bg-green-900:hover{
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity))
}

.hover\:tw-bg-teal-100:hover{
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity))
}

.hover\:tw-bg-teal-200:hover{
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity))
}

.hover\:tw-bg-teal-300:hover{
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity))
}

.hover\:tw-bg-teal-400:hover{
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity))
}

.hover\:tw-bg-teal-500:hover{
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity))
}

.hover\:tw-bg-teal-600:hover{
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity))
}

.hover\:tw-bg-teal-700:hover{
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity))
}

.hover\:tw-bg-teal-800:hover{
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity))
}

.hover\:tw-bg-teal-900:hover{
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity))
}

.hover\:tw-bg-blue-100:hover{
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.hover\:tw-bg-blue-200:hover{
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.hover\:tw-bg-blue-300:hover{
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.hover\:tw-bg-blue-400:hover{
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity))
}

.hover\:tw-bg-blue-500:hover{
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity))
}

.hover\:tw-bg-blue-600:hover{
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.hover\:tw-bg-blue-700:hover{
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.hover\:tw-bg-blue-800:hover{
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity))
}

.hover\:tw-bg-blue-900:hover{
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity))
}

.hover\:tw-bg-indigo-100:hover{
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity))
}

.hover\:tw-bg-indigo-200:hover{
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity))
}

.hover\:tw-bg-indigo-300:hover{
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity))
}

.hover\:tw-bg-indigo-400:hover{
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity))
}

.hover\:tw-bg-indigo-500:hover{
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity))
}

.hover\:tw-bg-indigo-600:hover{
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity))
}

.hover\:tw-bg-indigo-700:hover{
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity))
}

.hover\:tw-bg-indigo-800:hover{
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity))
}

.hover\:tw-bg-indigo-900:hover{
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity))
}

.hover\:tw-bg-purple-100:hover{
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity))
}

.hover\:tw-bg-purple-200:hover{
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity))
}

.hover\:tw-bg-purple-300:hover{
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity))
}

.hover\:tw-bg-purple-400:hover{
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity))
}

.hover\:tw-bg-purple-500:hover{
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity))
}

.hover\:tw-bg-purple-600:hover{
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity))
}

.hover\:tw-bg-purple-700:hover{
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity))
}

.hover\:tw-bg-purple-800:hover{
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity))
}

.hover\:tw-bg-purple-900:hover{
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity))
}

.hover\:tw-bg-pink-100:hover{
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity))
}

.hover\:tw-bg-pink-200:hover{
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity))
}

.hover\:tw-bg-pink-300:hover{
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity))
}

.hover\:tw-bg-pink-400:hover{
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity))
}

.hover\:tw-bg-pink-500:hover{
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity))
}

.hover\:tw-bg-pink-600:hover{
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity))
}

.hover\:tw-bg-pink-700:hover{
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity))
}

.hover\:tw-bg-pink-800:hover{
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity))
}

.hover\:tw-bg-pink-900:hover{
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity))
}

.focus\:tw-bg-transparent:focus{
  background-color: transparent
}

.focus\:tw-bg-tlg-green-dark:focus{
  --bg-opacity: 1;
  background-color: #76b84e;
  background-color: rgba(118, 184, 78, var(--bg-opacity))
}

.focus\:tw-bg-tlg-green:focus{
  --bg-opacity: 1;
  background-color: #a4ca61;
  background-color: rgba(164, 202, 97, var(--bg-opacity))
}

.focus\:tw-bg-tlg-orange-500:focus{
  --bg-opacity: 1;
  background-color: #ff7f41;
  background-color: rgba(255, 127, 65, var(--bg-opacity))
}

.focus\:tw-bg-black:focus{
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.focus\:tw-bg-white:focus{
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.focus\:tw-bg-gray-100:focus{
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity))
}

.focus\:tw-bg-gray-200:focus{
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity))
}

.focus\:tw-bg-gray-300:focus{
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.focus\:tw-bg-gray-400:focus{
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity))
}

.focus\:tw-bg-gray-500:focus{
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity))
}

.focus\:tw-bg-gray-600:focus{
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity))
}

.focus\:tw-bg-gray-700:focus{
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity))
}

.focus\:tw-bg-gray-800:focus{
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity))
}

.focus\:tw-bg-gray-900:focus{
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity))
}

.focus\:tw-bg-red-100:focus{
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity))
}

.focus\:tw-bg-red-200:focus{
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity))
}

.focus\:tw-bg-red-300:focus{
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity))
}

.focus\:tw-bg-red-400:focus{
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity))
}

.focus\:tw-bg-red-500:focus{
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity))
}

.focus\:tw-bg-red-600:focus{
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity))
}

.focus\:tw-bg-red-700:focus{
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity))
}

.focus\:tw-bg-red-800:focus{
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity))
}

.focus\:tw-bg-red-900:focus{
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity))
}

.focus\:tw-bg-orange-100:focus{
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity))
}

.focus\:tw-bg-orange-200:focus{
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity))
}

.focus\:tw-bg-orange-300:focus{
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity))
}

.focus\:tw-bg-orange-400:focus{
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity))
}

.focus\:tw-bg-orange-500:focus{
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity))
}

.focus\:tw-bg-orange-600:focus{
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity))
}

.focus\:tw-bg-orange-700:focus{
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity))
}

.focus\:tw-bg-orange-800:focus{
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity))
}

.focus\:tw-bg-orange-900:focus{
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity))
}

.focus\:tw-bg-yellow-100:focus{
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity))
}

.focus\:tw-bg-yellow-200:focus{
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity))
}

.focus\:tw-bg-yellow-300:focus{
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity))
}

.focus\:tw-bg-yellow-400:focus{
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity))
}

.focus\:tw-bg-yellow-500:focus{
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity))
}

.focus\:tw-bg-yellow-600:focus{
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity))
}

.focus\:tw-bg-yellow-700:focus{
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity))
}

.focus\:tw-bg-yellow-800:focus{
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity))
}

.focus\:tw-bg-yellow-900:focus{
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity))
}

.focus\:tw-bg-green-100:focus{
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity))
}

.focus\:tw-bg-green-200:focus{
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity))
}

.focus\:tw-bg-green-300:focus{
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity))
}

.focus\:tw-bg-green-400:focus{
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity))
}

.focus\:tw-bg-green-500:focus{
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity))
}

.focus\:tw-bg-green-600:focus{
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity))
}

.focus\:tw-bg-green-700:focus{
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity))
}

.focus\:tw-bg-green-800:focus{
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity))
}

.focus\:tw-bg-green-900:focus{
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity))
}

.focus\:tw-bg-teal-100:focus{
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity))
}

.focus\:tw-bg-teal-200:focus{
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity))
}

.focus\:tw-bg-teal-300:focus{
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity))
}

.focus\:tw-bg-teal-400:focus{
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity))
}

.focus\:tw-bg-teal-500:focus{
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity))
}

.focus\:tw-bg-teal-600:focus{
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity))
}

.focus\:tw-bg-teal-700:focus{
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity))
}

.focus\:tw-bg-teal-800:focus{
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity))
}

.focus\:tw-bg-teal-900:focus{
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity))
}

.focus\:tw-bg-blue-100:focus{
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity))
}

.focus\:tw-bg-blue-200:focus{
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity))
}

.focus\:tw-bg-blue-300:focus{
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity))
}

.focus\:tw-bg-blue-400:focus{
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity))
}

.focus\:tw-bg-blue-500:focus{
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity))
}

.focus\:tw-bg-blue-600:focus{
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity))
}

.focus\:tw-bg-blue-700:focus{
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity))
}

.focus\:tw-bg-blue-800:focus{
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity))
}

.focus\:tw-bg-blue-900:focus{
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity))
}

.focus\:tw-bg-indigo-100:focus{
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity))
}

.focus\:tw-bg-indigo-200:focus{
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity))
}

.focus\:tw-bg-indigo-300:focus{
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity))
}

.focus\:tw-bg-indigo-400:focus{
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity))
}

.focus\:tw-bg-indigo-500:focus{
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity))
}

.focus\:tw-bg-indigo-600:focus{
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity))
}

.focus\:tw-bg-indigo-700:focus{
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity))
}

.focus\:tw-bg-indigo-800:focus{
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity))
}

.focus\:tw-bg-indigo-900:focus{
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity))
}

.focus\:tw-bg-purple-100:focus{
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity))
}

.focus\:tw-bg-purple-200:focus{
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity))
}

.focus\:tw-bg-purple-300:focus{
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity))
}

.focus\:tw-bg-purple-400:focus{
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity))
}

.focus\:tw-bg-purple-500:focus{
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity))
}

.focus\:tw-bg-purple-600:focus{
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity))
}

.focus\:tw-bg-purple-700:focus{
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity))
}

.focus\:tw-bg-purple-800:focus{
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity))
}

.focus\:tw-bg-purple-900:focus{
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity))
}

.focus\:tw-bg-pink-100:focus{
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity))
}

.focus\:tw-bg-pink-200:focus{
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity))
}

.focus\:tw-bg-pink-300:focus{
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity))
}

.focus\:tw-bg-pink-400:focus{
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity))
}

.focus\:tw-bg-pink-500:focus{
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity))
}

.focus\:tw-bg-pink-600:focus{
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity))
}

.focus\:tw-bg-pink-700:focus{
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity))
}

.focus\:tw-bg-pink-800:focus{
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity))
}

.focus\:tw-bg-pink-900:focus{
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity))
}

.tw-bg-none{
  background-image: none
}

.tw-bg-gradient-to-t{
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(bottom, var(--gradient-color-stops));
  background-image: linear-gradient(to top, var(--gradient-color-stops))
}

.tw-bg-gradient-to-tr{
  background-image: -webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(bottom left, var(--gradient-color-stops));
  background-image: linear-gradient(to top right, var(--gradient-color-stops))
}

.tw-bg-gradient-to-r{
  background-image: -webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(left, var(--gradient-color-stops));
  background-image: linear-gradient(to right, var(--gradient-color-stops))
}

.tw-bg-gradient-to-br{
  background-image: -webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(top left, var(--gradient-color-stops));
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
}

.tw-bg-gradient-to-b{
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(top, var(--gradient-color-stops));
  background-image: linear-gradient(to bottom, var(--gradient-color-stops))
}

.tw-bg-gradient-to-bl{
  background-image: -webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(top right, var(--gradient-color-stops));
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
}

.tw-bg-gradient-to-l{
  background-image: -webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(right, var(--gradient-color-stops));
  background-image: linear-gradient(to left, var(--gradient-color-stops))
}

.tw-bg-gradient-to-tl{
  background-image: -webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
  background-image: -webkit-linear-gradient(bottom right, var(--gradient-color-stops));
  background-image: linear-gradient(to top left, var(--gradient-color-stops))
}

.tw-from-transparent{
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.tw-from-tlg-green-dark{
  --gradient-from-color: #76b84e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
}

.tw-from-tlg-green{
  --gradient-from-color: #a4ca61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
}

.tw-from-tlg-orange-500{
  --gradient-from-color: #ff7f41;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
}

.tw-from-black{
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.tw-from-white{
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.tw-from-gray-100{
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.tw-from-gray-200{
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.tw-from-gray-300{
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.tw-from-gray-400{
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.tw-from-gray-500{
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.tw-from-gray-600{
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.tw-from-gray-700{
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.tw-from-gray-800{
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.tw-from-gray-900{
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.tw-from-red-100{
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.tw-from-red-200{
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.tw-from-red-300{
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.tw-from-red-400{
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.tw-from-red-500{
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.tw-from-red-600{
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.tw-from-red-700{
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.tw-from-red-800{
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.tw-from-red-900{
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.tw-from-orange-100{
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.tw-from-orange-200{
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.tw-from-orange-300{
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.tw-from-orange-400{
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.tw-from-orange-500{
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.tw-from-orange-600{
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.tw-from-orange-700{
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.tw-from-orange-800{
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.tw-from-orange-900{
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.tw-from-yellow-100{
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.tw-from-yellow-200{
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.tw-from-yellow-300{
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.tw-from-yellow-400{
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.tw-from-yellow-500{
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.tw-from-yellow-600{
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.tw-from-yellow-700{
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.tw-from-yellow-800{
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.tw-from-yellow-900{
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.tw-from-green-100{
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.tw-from-green-200{
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.tw-from-green-300{
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.tw-from-green-400{
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.tw-from-green-500{
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.tw-from-green-600{
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.tw-from-green-700{
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.tw-from-green-800{
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.tw-from-green-900{
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.tw-from-teal-100{
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.tw-from-teal-200{
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.tw-from-teal-300{
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.tw-from-teal-400{
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.tw-from-teal-500{
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.tw-from-teal-600{
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.tw-from-teal-700{
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.tw-from-teal-800{
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.tw-from-teal-900{
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.tw-from-blue-100{
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.tw-from-blue-200{
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.tw-from-blue-300{
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.tw-from-blue-400{
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.tw-from-blue-500{
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.tw-from-blue-600{
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.tw-from-blue-700{
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.tw-from-blue-800{
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.tw-from-blue-900{
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.tw-from-indigo-100{
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.tw-from-indigo-200{
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.tw-from-indigo-300{
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.tw-from-indigo-400{
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.tw-from-indigo-500{
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.tw-from-indigo-600{
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.tw-from-indigo-700{
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.tw-from-indigo-800{
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.tw-from-indigo-900{
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.tw-from-purple-100{
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.tw-from-purple-200{
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.tw-from-purple-300{
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.tw-from-purple-400{
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.tw-from-purple-500{
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.tw-from-purple-600{
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.tw-from-purple-700{
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.tw-from-purple-800{
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.tw-from-purple-900{
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.tw-from-pink-100{
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.tw-from-pink-200{
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.tw-from-pink-300{
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.tw-from-pink-400{
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.tw-from-pink-500{
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.tw-from-pink-600{
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.tw-from-pink-700{
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.tw-from-pink-800{
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.tw-from-pink-900{
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.tw-via-transparent{
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.tw-via-tlg-green-dark{
  --gradient-via-color: #76b84e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
}

.tw-via-tlg-green{
  --gradient-via-color: #a4ca61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
}

.tw-via-tlg-orange-500{
  --gradient-via-color: #ff7f41;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
}

.tw-via-black{
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.tw-via-white{
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.tw-via-gray-100{
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.tw-via-gray-200{
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.tw-via-gray-300{
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.tw-via-gray-400{
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.tw-via-gray-500{
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.tw-via-gray-600{
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.tw-via-gray-700{
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.tw-via-gray-800{
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.tw-via-gray-900{
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.tw-via-red-100{
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.tw-via-red-200{
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.tw-via-red-300{
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.tw-via-red-400{
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.tw-via-red-500{
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.tw-via-red-600{
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.tw-via-red-700{
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.tw-via-red-800{
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.tw-via-red-900{
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.tw-via-orange-100{
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.tw-via-orange-200{
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.tw-via-orange-300{
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.tw-via-orange-400{
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.tw-via-orange-500{
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.tw-via-orange-600{
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.tw-via-orange-700{
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.tw-via-orange-800{
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.tw-via-orange-900{
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.tw-via-yellow-100{
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.tw-via-yellow-200{
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.tw-via-yellow-300{
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.tw-via-yellow-400{
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.tw-via-yellow-500{
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.tw-via-yellow-600{
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.tw-via-yellow-700{
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.tw-via-yellow-800{
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.tw-via-yellow-900{
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.tw-via-green-100{
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.tw-via-green-200{
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.tw-via-green-300{
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.tw-via-green-400{
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.tw-via-green-500{
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.tw-via-green-600{
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.tw-via-green-700{
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.tw-via-green-800{
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.tw-via-green-900{
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.tw-via-teal-100{
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.tw-via-teal-200{
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.tw-via-teal-300{
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.tw-via-teal-400{
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.tw-via-teal-500{
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.tw-via-teal-600{
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.tw-via-teal-700{
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.tw-via-teal-800{
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.tw-via-teal-900{
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.tw-via-blue-100{
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.tw-via-blue-200{
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.tw-via-blue-300{
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.tw-via-blue-400{
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.tw-via-blue-500{
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.tw-via-blue-600{
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.tw-via-blue-700{
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.tw-via-blue-800{
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.tw-via-blue-900{
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.tw-via-indigo-100{
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.tw-via-indigo-200{
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.tw-via-indigo-300{
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.tw-via-indigo-400{
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.tw-via-indigo-500{
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.tw-via-indigo-600{
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.tw-via-indigo-700{
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.tw-via-indigo-800{
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.tw-via-indigo-900{
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.tw-via-purple-100{
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.tw-via-purple-200{
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.tw-via-purple-300{
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.tw-via-purple-400{
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.tw-via-purple-500{
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.tw-via-purple-600{
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.tw-via-purple-700{
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.tw-via-purple-800{
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.tw-via-purple-900{
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.tw-via-pink-100{
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.tw-via-pink-200{
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.tw-via-pink-300{
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.tw-via-pink-400{
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.tw-via-pink-500{
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.tw-via-pink-600{
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.tw-via-pink-700{
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.tw-via-pink-800{
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.tw-via-pink-900{
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.tw-to-transparent{
  --gradient-to-color: transparent
}

.tw-to-tlg-green-dark{
  --gradient-to-color: #76b84e
}

.tw-to-tlg-green{
  --gradient-to-color: #a4ca61
}

.tw-to-tlg-orange-500{
  --gradient-to-color: #ff7f41
}

.tw-to-black{
  --gradient-to-color: #000
}

.tw-to-white{
  --gradient-to-color: #fff
}

.tw-to-gray-100{
  --gradient-to-color: #f7fafc
}

.tw-to-gray-200{
  --gradient-to-color: #edf2f7
}

.tw-to-gray-300{
  --gradient-to-color: #e2e8f0
}

.tw-to-gray-400{
  --gradient-to-color: #cbd5e0
}

.tw-to-gray-500{
  --gradient-to-color: #a0aec0
}

.tw-to-gray-600{
  --gradient-to-color: #718096
}

.tw-to-gray-700{
  --gradient-to-color: #4a5568
}

.tw-to-gray-800{
  --gradient-to-color: #2d3748
}

.tw-to-gray-900{
  --gradient-to-color: #1a202c
}

.tw-to-red-100{
  --gradient-to-color: #fff5f5
}

.tw-to-red-200{
  --gradient-to-color: #fed7d7
}

.tw-to-red-300{
  --gradient-to-color: #feb2b2
}

.tw-to-red-400{
  --gradient-to-color: #fc8181
}

.tw-to-red-500{
  --gradient-to-color: #f56565
}

.tw-to-red-600{
  --gradient-to-color: #e53e3e
}

.tw-to-red-700{
  --gradient-to-color: #c53030
}

.tw-to-red-800{
  --gradient-to-color: #9b2c2c
}

.tw-to-red-900{
  --gradient-to-color: #742a2a
}

.tw-to-orange-100{
  --gradient-to-color: #fffaf0
}

.tw-to-orange-200{
  --gradient-to-color: #feebc8
}

.tw-to-orange-300{
  --gradient-to-color: #fbd38d
}

.tw-to-orange-400{
  --gradient-to-color: #f6ad55
}

.tw-to-orange-500{
  --gradient-to-color: #ed8936
}

.tw-to-orange-600{
  --gradient-to-color: #dd6b20
}

.tw-to-orange-700{
  --gradient-to-color: #c05621
}

.tw-to-orange-800{
  --gradient-to-color: #9c4221
}

.tw-to-orange-900{
  --gradient-to-color: #7b341e
}

.tw-to-yellow-100{
  --gradient-to-color: #fffff0
}

.tw-to-yellow-200{
  --gradient-to-color: #fefcbf
}

.tw-to-yellow-300{
  --gradient-to-color: #faf089
}

.tw-to-yellow-400{
  --gradient-to-color: #f6e05e
}

.tw-to-yellow-500{
  --gradient-to-color: #ecc94b
}

.tw-to-yellow-600{
  --gradient-to-color: #d69e2e
}

.tw-to-yellow-700{
  --gradient-to-color: #b7791f
}

.tw-to-yellow-800{
  --gradient-to-color: #975a16
}

.tw-to-yellow-900{
  --gradient-to-color: #744210
}

.tw-to-green-100{
  --gradient-to-color: #f0fff4
}

.tw-to-green-200{
  --gradient-to-color: #c6f6d5
}

.tw-to-green-300{
  --gradient-to-color: #9ae6b4
}

.tw-to-green-400{
  --gradient-to-color: #68d391
}

.tw-to-green-500{
  --gradient-to-color: #48bb78
}

.tw-to-green-600{
  --gradient-to-color: #38a169
}

.tw-to-green-700{
  --gradient-to-color: #2f855a
}

.tw-to-green-800{
  --gradient-to-color: #276749
}

.tw-to-green-900{
  --gradient-to-color: #22543d
}

.tw-to-teal-100{
  --gradient-to-color: #e6fffa
}

.tw-to-teal-200{
  --gradient-to-color: #b2f5ea
}

.tw-to-teal-300{
  --gradient-to-color: #81e6d9
}

.tw-to-teal-400{
  --gradient-to-color: #4fd1c5
}

.tw-to-teal-500{
  --gradient-to-color: #38b2ac
}

.tw-to-teal-600{
  --gradient-to-color: #319795
}

.tw-to-teal-700{
  --gradient-to-color: #2c7a7b
}

.tw-to-teal-800{
  --gradient-to-color: #285e61
}

.tw-to-teal-900{
  --gradient-to-color: #234e52
}

.tw-to-blue-100{
  --gradient-to-color: #ebf8ff
}

.tw-to-blue-200{
  --gradient-to-color: #bee3f8
}

.tw-to-blue-300{
  --gradient-to-color: #90cdf4
}

.tw-to-blue-400{
  --gradient-to-color: #63b3ed
}

.tw-to-blue-500{
  --gradient-to-color: #4299e1
}

.tw-to-blue-600{
  --gradient-to-color: #3182ce
}

.tw-to-blue-700{
  --gradient-to-color: #2b6cb0
}

.tw-to-blue-800{
  --gradient-to-color: #2c5282
}

.tw-to-blue-900{
  --gradient-to-color: #2a4365
}

.tw-to-indigo-100{
  --gradient-to-color: #ebf4ff
}

.tw-to-indigo-200{
  --gradient-to-color: #c3dafe
}

.tw-to-indigo-300{
  --gradient-to-color: #a3bffa
}

.tw-to-indigo-400{
  --gradient-to-color: #7f9cf5
}

.tw-to-indigo-500{
  --gradient-to-color: #667eea
}

.tw-to-indigo-600{
  --gradient-to-color: #5a67d8
}

.tw-to-indigo-700{
  --gradient-to-color: #4c51bf
}

.tw-to-indigo-800{
  --gradient-to-color: #434190
}

.tw-to-indigo-900{
  --gradient-to-color: #3c366b
}

.tw-to-purple-100{
  --gradient-to-color: #faf5ff
}

.tw-to-purple-200{
  --gradient-to-color: #e9d8fd
}

.tw-to-purple-300{
  --gradient-to-color: #d6bcfa
}

.tw-to-purple-400{
  --gradient-to-color: #b794f4
}

.tw-to-purple-500{
  --gradient-to-color: #9f7aea
}

.tw-to-purple-600{
  --gradient-to-color: #805ad5
}

.tw-to-purple-700{
  --gradient-to-color: #6b46c1
}

.tw-to-purple-800{
  --gradient-to-color: #553c9a
}

.tw-to-purple-900{
  --gradient-to-color: #44337a
}

.tw-to-pink-100{
  --gradient-to-color: #fff5f7
}

.tw-to-pink-200{
  --gradient-to-color: #fed7e2
}

.tw-to-pink-300{
  --gradient-to-color: #fbb6ce
}

.tw-to-pink-400{
  --gradient-to-color: #f687b3
}

.tw-to-pink-500{
  --gradient-to-color: #ed64a6
}

.tw-to-pink-600{
  --gradient-to-color: #d53f8c
}

.tw-to-pink-700{
  --gradient-to-color: #b83280
}

.tw-to-pink-800{
  --gradient-to-color: #97266d
}

.tw-to-pink-900{
  --gradient-to-color: #702459
}

.hover\:tw-from-transparent:hover{
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:tw-from-tlg-green-dark:hover{
  --gradient-from-color: #76b84e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
}

.hover\:tw-from-tlg-green:hover{
  --gradient-from-color: #a4ca61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
}

.hover\:tw-from-tlg-orange-500:hover{
  --gradient-from-color: #ff7f41;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
}

.hover\:tw-from-black:hover{
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:tw-from-white:hover{
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:tw-from-gray-100:hover{
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.hover\:tw-from-gray-200:hover{
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.hover\:tw-from-gray-300:hover{
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.hover\:tw-from-gray-400:hover{
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.hover\:tw-from-gray-500:hover{
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.hover\:tw-from-gray-600:hover{
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.hover\:tw-from-gray-700:hover{
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.hover\:tw-from-gray-800:hover{
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.hover\:tw-from-gray-900:hover{
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.hover\:tw-from-red-100:hover{
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.hover\:tw-from-red-200:hover{
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.hover\:tw-from-red-300:hover{
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.hover\:tw-from-red-400:hover{
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.hover\:tw-from-red-500:hover{
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.hover\:tw-from-red-600:hover{
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.hover\:tw-from-red-700:hover{
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.hover\:tw-from-red-800:hover{
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.hover\:tw-from-red-900:hover{
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.hover\:tw-from-orange-100:hover{
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.hover\:tw-from-orange-200:hover{
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.hover\:tw-from-orange-300:hover{
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.hover\:tw-from-orange-400:hover{
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.hover\:tw-from-orange-500:hover{
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.hover\:tw-from-orange-600:hover{
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.hover\:tw-from-orange-700:hover{
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.hover\:tw-from-orange-800:hover{
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.hover\:tw-from-orange-900:hover{
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.hover\:tw-from-yellow-100:hover{
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.hover\:tw-from-yellow-200:hover{
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.hover\:tw-from-yellow-300:hover{
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.hover\:tw-from-yellow-400:hover{
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.hover\:tw-from-yellow-500:hover{
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.hover\:tw-from-yellow-600:hover{
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.hover\:tw-from-yellow-700:hover{
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.hover\:tw-from-yellow-800:hover{
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.hover\:tw-from-yellow-900:hover{
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.hover\:tw-from-green-100:hover{
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.hover\:tw-from-green-200:hover{
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.hover\:tw-from-green-300:hover{
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.hover\:tw-from-green-400:hover{
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.hover\:tw-from-green-500:hover{
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.hover\:tw-from-green-600:hover{
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.hover\:tw-from-green-700:hover{
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.hover\:tw-from-green-800:hover{
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.hover\:tw-from-green-900:hover{
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.hover\:tw-from-teal-100:hover{
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.hover\:tw-from-teal-200:hover{
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.hover\:tw-from-teal-300:hover{
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.hover\:tw-from-teal-400:hover{
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.hover\:tw-from-teal-500:hover{
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.hover\:tw-from-teal-600:hover{
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.hover\:tw-from-teal-700:hover{
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.hover\:tw-from-teal-800:hover{
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.hover\:tw-from-teal-900:hover{
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.hover\:tw-from-blue-100:hover{
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.hover\:tw-from-blue-200:hover{
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.hover\:tw-from-blue-300:hover{
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.hover\:tw-from-blue-400:hover{
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.hover\:tw-from-blue-500:hover{
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.hover\:tw-from-blue-600:hover{
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.hover\:tw-from-blue-700:hover{
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.hover\:tw-from-blue-800:hover{
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.hover\:tw-from-blue-900:hover{
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.hover\:tw-from-indigo-100:hover{
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.hover\:tw-from-indigo-200:hover{
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.hover\:tw-from-indigo-300:hover{
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.hover\:tw-from-indigo-400:hover{
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.hover\:tw-from-indigo-500:hover{
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.hover\:tw-from-indigo-600:hover{
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.hover\:tw-from-indigo-700:hover{
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.hover\:tw-from-indigo-800:hover{
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.hover\:tw-from-indigo-900:hover{
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.hover\:tw-from-purple-100:hover{
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.hover\:tw-from-purple-200:hover{
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.hover\:tw-from-purple-300:hover{
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.hover\:tw-from-purple-400:hover{
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.hover\:tw-from-purple-500:hover{
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.hover\:tw-from-purple-600:hover{
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.hover\:tw-from-purple-700:hover{
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.hover\:tw-from-purple-800:hover{
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.hover\:tw-from-purple-900:hover{
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.hover\:tw-from-pink-100:hover{
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.hover\:tw-from-pink-200:hover{
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.hover\:tw-from-pink-300:hover{
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.hover\:tw-from-pink-400:hover{
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.hover\:tw-from-pink-500:hover{
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.hover\:tw-from-pink-600:hover{
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.hover\:tw-from-pink-700:hover{
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.hover\:tw-from-pink-800:hover{
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.hover\:tw-from-pink-900:hover{
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.hover\:tw-via-transparent:hover{
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:tw-via-tlg-green-dark:hover{
  --gradient-via-color: #76b84e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
}

.hover\:tw-via-tlg-green:hover{
  --gradient-via-color: #a4ca61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
}

.hover\:tw-via-tlg-orange-500:hover{
  --gradient-via-color: #ff7f41;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
}

.hover\:tw-via-black:hover{
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.hover\:tw-via-white:hover{
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:tw-via-gray-100:hover{
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.hover\:tw-via-gray-200:hover{
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.hover\:tw-via-gray-300:hover{
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.hover\:tw-via-gray-400:hover{
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.hover\:tw-via-gray-500:hover{
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.hover\:tw-via-gray-600:hover{
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.hover\:tw-via-gray-700:hover{
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.hover\:tw-via-gray-800:hover{
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.hover\:tw-via-gray-900:hover{
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.hover\:tw-via-red-100:hover{
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.hover\:tw-via-red-200:hover{
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.hover\:tw-via-red-300:hover{
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.hover\:tw-via-red-400:hover{
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.hover\:tw-via-red-500:hover{
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.hover\:tw-via-red-600:hover{
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.hover\:tw-via-red-700:hover{
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.hover\:tw-via-red-800:hover{
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.hover\:tw-via-red-900:hover{
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.hover\:tw-via-orange-100:hover{
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.hover\:tw-via-orange-200:hover{
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.hover\:tw-via-orange-300:hover{
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.hover\:tw-via-orange-400:hover{
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.hover\:tw-via-orange-500:hover{
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.hover\:tw-via-orange-600:hover{
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.hover\:tw-via-orange-700:hover{
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.hover\:tw-via-orange-800:hover{
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.hover\:tw-via-orange-900:hover{
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.hover\:tw-via-yellow-100:hover{
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.hover\:tw-via-yellow-200:hover{
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.hover\:tw-via-yellow-300:hover{
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.hover\:tw-via-yellow-400:hover{
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.hover\:tw-via-yellow-500:hover{
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.hover\:tw-via-yellow-600:hover{
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.hover\:tw-via-yellow-700:hover{
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.hover\:tw-via-yellow-800:hover{
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.hover\:tw-via-yellow-900:hover{
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.hover\:tw-via-green-100:hover{
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.hover\:tw-via-green-200:hover{
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.hover\:tw-via-green-300:hover{
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.hover\:tw-via-green-400:hover{
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.hover\:tw-via-green-500:hover{
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.hover\:tw-via-green-600:hover{
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.hover\:tw-via-green-700:hover{
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.hover\:tw-via-green-800:hover{
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.hover\:tw-via-green-900:hover{
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.hover\:tw-via-teal-100:hover{
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.hover\:tw-via-teal-200:hover{
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.hover\:tw-via-teal-300:hover{
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.hover\:tw-via-teal-400:hover{
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.hover\:tw-via-teal-500:hover{
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.hover\:tw-via-teal-600:hover{
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.hover\:tw-via-teal-700:hover{
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.hover\:tw-via-teal-800:hover{
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.hover\:tw-via-teal-900:hover{
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.hover\:tw-via-blue-100:hover{
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.hover\:tw-via-blue-200:hover{
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.hover\:tw-via-blue-300:hover{
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.hover\:tw-via-blue-400:hover{
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.hover\:tw-via-blue-500:hover{
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.hover\:tw-via-blue-600:hover{
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.hover\:tw-via-blue-700:hover{
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.hover\:tw-via-blue-800:hover{
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.hover\:tw-via-blue-900:hover{
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.hover\:tw-via-indigo-100:hover{
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.hover\:tw-via-indigo-200:hover{
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.hover\:tw-via-indigo-300:hover{
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.hover\:tw-via-indigo-400:hover{
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.hover\:tw-via-indigo-500:hover{
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.hover\:tw-via-indigo-600:hover{
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.hover\:tw-via-indigo-700:hover{
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.hover\:tw-via-indigo-800:hover{
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.hover\:tw-via-indigo-900:hover{
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.hover\:tw-via-purple-100:hover{
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.hover\:tw-via-purple-200:hover{
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.hover\:tw-via-purple-300:hover{
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.hover\:tw-via-purple-400:hover{
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.hover\:tw-via-purple-500:hover{
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.hover\:tw-via-purple-600:hover{
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.hover\:tw-via-purple-700:hover{
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.hover\:tw-via-purple-800:hover{
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.hover\:tw-via-purple-900:hover{
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.hover\:tw-via-pink-100:hover{
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.hover\:tw-via-pink-200:hover{
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.hover\:tw-via-pink-300:hover{
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.hover\:tw-via-pink-400:hover{
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.hover\:tw-via-pink-500:hover{
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.hover\:tw-via-pink-600:hover{
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.hover\:tw-via-pink-700:hover{
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.hover\:tw-via-pink-800:hover{
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.hover\:tw-via-pink-900:hover{
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.hover\:tw-to-transparent:hover{
  --gradient-to-color: transparent
}

.hover\:tw-to-tlg-green-dark:hover{
  --gradient-to-color: #76b84e
}

.hover\:tw-to-tlg-green:hover{
  --gradient-to-color: #a4ca61
}

.hover\:tw-to-tlg-orange-500:hover{
  --gradient-to-color: #ff7f41
}

.hover\:tw-to-black:hover{
  --gradient-to-color: #000
}

.hover\:tw-to-white:hover{
  --gradient-to-color: #fff
}

.hover\:tw-to-gray-100:hover{
  --gradient-to-color: #f7fafc
}

.hover\:tw-to-gray-200:hover{
  --gradient-to-color: #edf2f7
}

.hover\:tw-to-gray-300:hover{
  --gradient-to-color: #e2e8f0
}

.hover\:tw-to-gray-400:hover{
  --gradient-to-color: #cbd5e0
}

.hover\:tw-to-gray-500:hover{
  --gradient-to-color: #a0aec0
}

.hover\:tw-to-gray-600:hover{
  --gradient-to-color: #718096
}

.hover\:tw-to-gray-700:hover{
  --gradient-to-color: #4a5568
}

.hover\:tw-to-gray-800:hover{
  --gradient-to-color: #2d3748
}

.hover\:tw-to-gray-900:hover{
  --gradient-to-color: #1a202c
}

.hover\:tw-to-red-100:hover{
  --gradient-to-color: #fff5f5
}

.hover\:tw-to-red-200:hover{
  --gradient-to-color: #fed7d7
}

.hover\:tw-to-red-300:hover{
  --gradient-to-color: #feb2b2
}

.hover\:tw-to-red-400:hover{
  --gradient-to-color: #fc8181
}

.hover\:tw-to-red-500:hover{
  --gradient-to-color: #f56565
}

.hover\:tw-to-red-600:hover{
  --gradient-to-color: #e53e3e
}

.hover\:tw-to-red-700:hover{
  --gradient-to-color: #c53030
}

.hover\:tw-to-red-800:hover{
  --gradient-to-color: #9b2c2c
}

.hover\:tw-to-red-900:hover{
  --gradient-to-color: #742a2a
}

.hover\:tw-to-orange-100:hover{
  --gradient-to-color: #fffaf0
}

.hover\:tw-to-orange-200:hover{
  --gradient-to-color: #feebc8
}

.hover\:tw-to-orange-300:hover{
  --gradient-to-color: #fbd38d
}

.hover\:tw-to-orange-400:hover{
  --gradient-to-color: #f6ad55
}

.hover\:tw-to-orange-500:hover{
  --gradient-to-color: #ed8936
}

.hover\:tw-to-orange-600:hover{
  --gradient-to-color: #dd6b20
}

.hover\:tw-to-orange-700:hover{
  --gradient-to-color: #c05621
}

.hover\:tw-to-orange-800:hover{
  --gradient-to-color: #9c4221
}

.hover\:tw-to-orange-900:hover{
  --gradient-to-color: #7b341e
}

.hover\:tw-to-yellow-100:hover{
  --gradient-to-color: #fffff0
}

.hover\:tw-to-yellow-200:hover{
  --gradient-to-color: #fefcbf
}

.hover\:tw-to-yellow-300:hover{
  --gradient-to-color: #faf089
}

.hover\:tw-to-yellow-400:hover{
  --gradient-to-color: #f6e05e
}

.hover\:tw-to-yellow-500:hover{
  --gradient-to-color: #ecc94b
}

.hover\:tw-to-yellow-600:hover{
  --gradient-to-color: #d69e2e
}

.hover\:tw-to-yellow-700:hover{
  --gradient-to-color: #b7791f
}

.hover\:tw-to-yellow-800:hover{
  --gradient-to-color: #975a16
}

.hover\:tw-to-yellow-900:hover{
  --gradient-to-color: #744210
}

.hover\:tw-to-green-100:hover{
  --gradient-to-color: #f0fff4
}

.hover\:tw-to-green-200:hover{
  --gradient-to-color: #c6f6d5
}

.hover\:tw-to-green-300:hover{
  --gradient-to-color: #9ae6b4
}

.hover\:tw-to-green-400:hover{
  --gradient-to-color: #68d391
}

.hover\:tw-to-green-500:hover{
  --gradient-to-color: #48bb78
}

.hover\:tw-to-green-600:hover{
  --gradient-to-color: #38a169
}

.hover\:tw-to-green-700:hover{
  --gradient-to-color: #2f855a
}

.hover\:tw-to-green-800:hover{
  --gradient-to-color: #276749
}

.hover\:tw-to-green-900:hover{
  --gradient-to-color: #22543d
}

.hover\:tw-to-teal-100:hover{
  --gradient-to-color: #e6fffa
}

.hover\:tw-to-teal-200:hover{
  --gradient-to-color: #b2f5ea
}

.hover\:tw-to-teal-300:hover{
  --gradient-to-color: #81e6d9
}

.hover\:tw-to-teal-400:hover{
  --gradient-to-color: #4fd1c5
}

.hover\:tw-to-teal-500:hover{
  --gradient-to-color: #38b2ac
}

.hover\:tw-to-teal-600:hover{
  --gradient-to-color: #319795
}

.hover\:tw-to-teal-700:hover{
  --gradient-to-color: #2c7a7b
}

.hover\:tw-to-teal-800:hover{
  --gradient-to-color: #285e61
}

.hover\:tw-to-teal-900:hover{
  --gradient-to-color: #234e52
}

.hover\:tw-to-blue-100:hover{
  --gradient-to-color: #ebf8ff
}

.hover\:tw-to-blue-200:hover{
  --gradient-to-color: #bee3f8
}

.hover\:tw-to-blue-300:hover{
  --gradient-to-color: #90cdf4
}

.hover\:tw-to-blue-400:hover{
  --gradient-to-color: #63b3ed
}

.hover\:tw-to-blue-500:hover{
  --gradient-to-color: #4299e1
}

.hover\:tw-to-blue-600:hover{
  --gradient-to-color: #3182ce
}

.hover\:tw-to-blue-700:hover{
  --gradient-to-color: #2b6cb0
}

.hover\:tw-to-blue-800:hover{
  --gradient-to-color: #2c5282
}

.hover\:tw-to-blue-900:hover{
  --gradient-to-color: #2a4365
}

.hover\:tw-to-indigo-100:hover{
  --gradient-to-color: #ebf4ff
}

.hover\:tw-to-indigo-200:hover{
  --gradient-to-color: #c3dafe
}

.hover\:tw-to-indigo-300:hover{
  --gradient-to-color: #a3bffa
}

.hover\:tw-to-indigo-400:hover{
  --gradient-to-color: #7f9cf5
}

.hover\:tw-to-indigo-500:hover{
  --gradient-to-color: #667eea
}

.hover\:tw-to-indigo-600:hover{
  --gradient-to-color: #5a67d8
}

.hover\:tw-to-indigo-700:hover{
  --gradient-to-color: #4c51bf
}

.hover\:tw-to-indigo-800:hover{
  --gradient-to-color: #434190
}

.hover\:tw-to-indigo-900:hover{
  --gradient-to-color: #3c366b
}

.hover\:tw-to-purple-100:hover{
  --gradient-to-color: #faf5ff
}

.hover\:tw-to-purple-200:hover{
  --gradient-to-color: #e9d8fd
}

.hover\:tw-to-purple-300:hover{
  --gradient-to-color: #d6bcfa
}

.hover\:tw-to-purple-400:hover{
  --gradient-to-color: #b794f4
}

.hover\:tw-to-purple-500:hover{
  --gradient-to-color: #9f7aea
}

.hover\:tw-to-purple-600:hover{
  --gradient-to-color: #805ad5
}

.hover\:tw-to-purple-700:hover{
  --gradient-to-color: #6b46c1
}

.hover\:tw-to-purple-800:hover{
  --gradient-to-color: #553c9a
}

.hover\:tw-to-purple-900:hover{
  --gradient-to-color: #44337a
}

.hover\:tw-to-pink-100:hover{
  --gradient-to-color: #fff5f7
}

.hover\:tw-to-pink-200:hover{
  --gradient-to-color: #fed7e2
}

.hover\:tw-to-pink-300:hover{
  --gradient-to-color: #fbb6ce
}

.hover\:tw-to-pink-400:hover{
  --gradient-to-color: #f687b3
}

.hover\:tw-to-pink-500:hover{
  --gradient-to-color: #ed64a6
}

.hover\:tw-to-pink-600:hover{
  --gradient-to-color: #d53f8c
}

.hover\:tw-to-pink-700:hover{
  --gradient-to-color: #b83280
}

.hover\:tw-to-pink-800:hover{
  --gradient-to-color: #97266d
}

.hover\:tw-to-pink-900:hover{
  --gradient-to-color: #702459
}

.focus\:tw-from-transparent:focus{
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:tw-from-tlg-green-dark:focus{
  --gradient-from-color: #76b84e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
}

.focus\:tw-from-tlg-green:focus{
  --gradient-from-color: #a4ca61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
}

.focus\:tw-from-tlg-orange-500:focus{
  --gradient-from-color: #ff7f41;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
}

.focus\:tw-from-black:focus{
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:tw-from-white:focus{
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:tw-from-gray-100:focus{
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.focus\:tw-from-gray-200:focus{
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.focus\:tw-from-gray-300:focus{
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.focus\:tw-from-gray-400:focus{
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.focus\:tw-from-gray-500:focus{
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.focus\:tw-from-gray-600:focus{
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.focus\:tw-from-gray-700:focus{
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.focus\:tw-from-gray-800:focus{
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.focus\:tw-from-gray-900:focus{
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.focus\:tw-from-red-100:focus{
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.focus\:tw-from-red-200:focus{
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.focus\:tw-from-red-300:focus{
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.focus\:tw-from-red-400:focus{
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.focus\:tw-from-red-500:focus{
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.focus\:tw-from-red-600:focus{
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.focus\:tw-from-red-700:focus{
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.focus\:tw-from-red-800:focus{
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.focus\:tw-from-red-900:focus{
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.focus\:tw-from-orange-100:focus{
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.focus\:tw-from-orange-200:focus{
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.focus\:tw-from-orange-300:focus{
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.focus\:tw-from-orange-400:focus{
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.focus\:tw-from-orange-500:focus{
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.focus\:tw-from-orange-600:focus{
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.focus\:tw-from-orange-700:focus{
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.focus\:tw-from-orange-800:focus{
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.focus\:tw-from-orange-900:focus{
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.focus\:tw-from-yellow-100:focus{
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.focus\:tw-from-yellow-200:focus{
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.focus\:tw-from-yellow-300:focus{
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.focus\:tw-from-yellow-400:focus{
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.focus\:tw-from-yellow-500:focus{
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.focus\:tw-from-yellow-600:focus{
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.focus\:tw-from-yellow-700:focus{
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.focus\:tw-from-yellow-800:focus{
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.focus\:tw-from-yellow-900:focus{
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.focus\:tw-from-green-100:focus{
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.focus\:tw-from-green-200:focus{
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.focus\:tw-from-green-300:focus{
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.focus\:tw-from-green-400:focus{
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.focus\:tw-from-green-500:focus{
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.focus\:tw-from-green-600:focus{
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.focus\:tw-from-green-700:focus{
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.focus\:tw-from-green-800:focus{
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.focus\:tw-from-green-900:focus{
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.focus\:tw-from-teal-100:focus{
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.focus\:tw-from-teal-200:focus{
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.focus\:tw-from-teal-300:focus{
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.focus\:tw-from-teal-400:focus{
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.focus\:tw-from-teal-500:focus{
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.focus\:tw-from-teal-600:focus{
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.focus\:tw-from-teal-700:focus{
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.focus\:tw-from-teal-800:focus{
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.focus\:tw-from-teal-900:focus{
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.focus\:tw-from-blue-100:focus{
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.focus\:tw-from-blue-200:focus{
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.focus\:tw-from-blue-300:focus{
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.focus\:tw-from-blue-400:focus{
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.focus\:tw-from-blue-500:focus{
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.focus\:tw-from-blue-600:focus{
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.focus\:tw-from-blue-700:focus{
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.focus\:tw-from-blue-800:focus{
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.focus\:tw-from-blue-900:focus{
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.focus\:tw-from-indigo-100:focus{
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.focus\:tw-from-indigo-200:focus{
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.focus\:tw-from-indigo-300:focus{
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.focus\:tw-from-indigo-400:focus{
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.focus\:tw-from-indigo-500:focus{
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.focus\:tw-from-indigo-600:focus{
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.focus\:tw-from-indigo-700:focus{
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.focus\:tw-from-indigo-800:focus{
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.focus\:tw-from-indigo-900:focus{
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.focus\:tw-from-purple-100:focus{
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.focus\:tw-from-purple-200:focus{
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.focus\:tw-from-purple-300:focus{
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.focus\:tw-from-purple-400:focus{
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.focus\:tw-from-purple-500:focus{
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.focus\:tw-from-purple-600:focus{
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.focus\:tw-from-purple-700:focus{
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.focus\:tw-from-purple-800:focus{
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.focus\:tw-from-purple-900:focus{
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.focus\:tw-from-pink-100:focus{
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.focus\:tw-from-pink-200:focus{
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.focus\:tw-from-pink-300:focus{
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.focus\:tw-from-pink-400:focus{
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.focus\:tw-from-pink-500:focus{
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.focus\:tw-from-pink-600:focus{
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.focus\:tw-from-pink-700:focus{
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.focus\:tw-from-pink-800:focus{
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.focus\:tw-from-pink-900:focus{
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.focus\:tw-via-transparent:focus{
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:tw-via-tlg-green-dark:focus{
  --gradient-via-color: #76b84e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
}

.focus\:tw-via-tlg-green:focus{
  --gradient-via-color: #a4ca61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
}

.focus\:tw-via-tlg-orange-500:focus{
  --gradient-via-color: #ff7f41;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
}

.focus\:tw-via-black:focus{
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
}

.focus\:tw-via-white:focus{
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:tw-via-gray-100:focus{
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
}

.focus\:tw-via-gray-200:focus{
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
}

.focus\:tw-via-gray-300:focus{
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
}

.focus\:tw-via-gray-400:focus{
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
}

.focus\:tw-via-gray-500:focus{
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
}

.focus\:tw-via-gray-600:focus{
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
}

.focus\:tw-via-gray-700:focus{
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
}

.focus\:tw-via-gray-800:focus{
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
}

.focus\:tw-via-gray-900:focus{
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
}

.focus\:tw-via-red-100:focus{
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
}

.focus\:tw-via-red-200:focus{
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
}

.focus\:tw-via-red-300:focus{
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
}

.focus\:tw-via-red-400:focus{
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
}

.focus\:tw-via-red-500:focus{
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
}

.focus\:tw-via-red-600:focus{
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
}

.focus\:tw-via-red-700:focus{
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
}

.focus\:tw-via-red-800:focus{
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
}

.focus\:tw-via-red-900:focus{
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
}

.focus\:tw-via-orange-100:focus{
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
}

.focus\:tw-via-orange-200:focus{
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
}

.focus\:tw-via-orange-300:focus{
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
}

.focus\:tw-via-orange-400:focus{
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
}

.focus\:tw-via-orange-500:focus{
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
}

.focus\:tw-via-orange-600:focus{
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
}

.focus\:tw-via-orange-700:focus{
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
}

.focus\:tw-via-orange-800:focus{
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
}

.focus\:tw-via-orange-900:focus{
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
}

.focus\:tw-via-yellow-100:focus{
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
}

.focus\:tw-via-yellow-200:focus{
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
}

.focus\:tw-via-yellow-300:focus{
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
}

.focus\:tw-via-yellow-400:focus{
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
}

.focus\:tw-via-yellow-500:focus{
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
}

.focus\:tw-via-yellow-600:focus{
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
}

.focus\:tw-via-yellow-700:focus{
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
}

.focus\:tw-via-yellow-800:focus{
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
}

.focus\:tw-via-yellow-900:focus{
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
}

.focus\:tw-via-green-100:focus{
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
}

.focus\:tw-via-green-200:focus{
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
}

.focus\:tw-via-green-300:focus{
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
}

.focus\:tw-via-green-400:focus{
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
}

.focus\:tw-via-green-500:focus{
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
}

.focus\:tw-via-green-600:focus{
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
}

.focus\:tw-via-green-700:focus{
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
}

.focus\:tw-via-green-800:focus{
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
}

.focus\:tw-via-green-900:focus{
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
}

.focus\:tw-via-teal-100:focus{
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
}

.focus\:tw-via-teal-200:focus{
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
}

.focus\:tw-via-teal-300:focus{
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
}

.focus\:tw-via-teal-400:focus{
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
}

.focus\:tw-via-teal-500:focus{
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
}

.focus\:tw-via-teal-600:focus{
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
}

.focus\:tw-via-teal-700:focus{
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
}

.focus\:tw-via-teal-800:focus{
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
}

.focus\:tw-via-teal-900:focus{
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
}

.focus\:tw-via-blue-100:focus{
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
}

.focus\:tw-via-blue-200:focus{
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
}

.focus\:tw-via-blue-300:focus{
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
}

.focus\:tw-via-blue-400:focus{
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
}

.focus\:tw-via-blue-500:focus{
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
}

.focus\:tw-via-blue-600:focus{
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
}

.focus\:tw-via-blue-700:focus{
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
}

.focus\:tw-via-blue-800:focus{
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
}

.focus\:tw-via-blue-900:focus{
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
}

.focus\:tw-via-indigo-100:focus{
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
}

.focus\:tw-via-indigo-200:focus{
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
}

.focus\:tw-via-indigo-300:focus{
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
}

.focus\:tw-via-indigo-400:focus{
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
}

.focus\:tw-via-indigo-500:focus{
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
}

.focus\:tw-via-indigo-600:focus{
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
}

.focus\:tw-via-indigo-700:focus{
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
}

.focus\:tw-via-indigo-800:focus{
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
}

.focus\:tw-via-indigo-900:focus{
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
}

.focus\:tw-via-purple-100:focus{
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
}

.focus\:tw-via-purple-200:focus{
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
}

.focus\:tw-via-purple-300:focus{
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
}

.focus\:tw-via-purple-400:focus{
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
}

.focus\:tw-via-purple-500:focus{
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
}

.focus\:tw-via-purple-600:focus{
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
}

.focus\:tw-via-purple-700:focus{
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
}

.focus\:tw-via-purple-800:focus{
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
}

.focus\:tw-via-purple-900:focus{
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
}

.focus\:tw-via-pink-100:focus{
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
}

.focus\:tw-via-pink-200:focus{
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
}

.focus\:tw-via-pink-300:focus{
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
}

.focus\:tw-via-pink-400:focus{
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
}

.focus\:tw-via-pink-500:focus{
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
}

.focus\:tw-via-pink-600:focus{
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
}

.focus\:tw-via-pink-700:focus{
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
}

.focus\:tw-via-pink-800:focus{
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
}

.focus\:tw-via-pink-900:focus{
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
}

.focus\:tw-to-transparent:focus{
  --gradient-to-color: transparent
}

.focus\:tw-to-tlg-green-dark:focus{
  --gradient-to-color: #76b84e
}

.focus\:tw-to-tlg-green:focus{
  --gradient-to-color: #a4ca61
}

.focus\:tw-to-tlg-orange-500:focus{
  --gradient-to-color: #ff7f41
}

.focus\:tw-to-black:focus{
  --gradient-to-color: #000
}

.focus\:tw-to-white:focus{
  --gradient-to-color: #fff
}

.focus\:tw-to-gray-100:focus{
  --gradient-to-color: #f7fafc
}

.focus\:tw-to-gray-200:focus{
  --gradient-to-color: #edf2f7
}

.focus\:tw-to-gray-300:focus{
  --gradient-to-color: #e2e8f0
}

.focus\:tw-to-gray-400:focus{
  --gradient-to-color: #cbd5e0
}

.focus\:tw-to-gray-500:focus{
  --gradient-to-color: #a0aec0
}

.focus\:tw-to-gray-600:focus{
  --gradient-to-color: #718096
}

.focus\:tw-to-gray-700:focus{
  --gradient-to-color: #4a5568
}

.focus\:tw-to-gray-800:focus{
  --gradient-to-color: #2d3748
}

.focus\:tw-to-gray-900:focus{
  --gradient-to-color: #1a202c
}

.focus\:tw-to-red-100:focus{
  --gradient-to-color: #fff5f5
}

.focus\:tw-to-red-200:focus{
  --gradient-to-color: #fed7d7
}

.focus\:tw-to-red-300:focus{
  --gradient-to-color: #feb2b2
}

.focus\:tw-to-red-400:focus{
  --gradient-to-color: #fc8181
}

.focus\:tw-to-red-500:focus{
  --gradient-to-color: #f56565
}

.focus\:tw-to-red-600:focus{
  --gradient-to-color: #e53e3e
}

.focus\:tw-to-red-700:focus{
  --gradient-to-color: #c53030
}

.focus\:tw-to-red-800:focus{
  --gradient-to-color: #9b2c2c
}

.focus\:tw-to-red-900:focus{
  --gradient-to-color: #742a2a
}

.focus\:tw-to-orange-100:focus{
  --gradient-to-color: #fffaf0
}

.focus\:tw-to-orange-200:focus{
  --gradient-to-color: #feebc8
}

.focus\:tw-to-orange-300:focus{
  --gradient-to-color: #fbd38d
}

.focus\:tw-to-orange-400:focus{
  --gradient-to-color: #f6ad55
}

.focus\:tw-to-orange-500:focus{
  --gradient-to-color: #ed8936
}

.focus\:tw-to-orange-600:focus{
  --gradient-to-color: #dd6b20
}

.focus\:tw-to-orange-700:focus{
  --gradient-to-color: #c05621
}

.focus\:tw-to-orange-800:focus{
  --gradient-to-color: #9c4221
}

.focus\:tw-to-orange-900:focus{
  --gradient-to-color: #7b341e
}

.focus\:tw-to-yellow-100:focus{
  --gradient-to-color: #fffff0
}

.focus\:tw-to-yellow-200:focus{
  --gradient-to-color: #fefcbf
}

.focus\:tw-to-yellow-300:focus{
  --gradient-to-color: #faf089
}

.focus\:tw-to-yellow-400:focus{
  --gradient-to-color: #f6e05e
}

.focus\:tw-to-yellow-500:focus{
  --gradient-to-color: #ecc94b
}

.focus\:tw-to-yellow-600:focus{
  --gradient-to-color: #d69e2e
}

.focus\:tw-to-yellow-700:focus{
  --gradient-to-color: #b7791f
}

.focus\:tw-to-yellow-800:focus{
  --gradient-to-color: #975a16
}

.focus\:tw-to-yellow-900:focus{
  --gradient-to-color: #744210
}

.focus\:tw-to-green-100:focus{
  --gradient-to-color: #f0fff4
}

.focus\:tw-to-green-200:focus{
  --gradient-to-color: #c6f6d5
}

.focus\:tw-to-green-300:focus{
  --gradient-to-color: #9ae6b4
}

.focus\:tw-to-green-400:focus{
  --gradient-to-color: #68d391
}

.focus\:tw-to-green-500:focus{
  --gradient-to-color: #48bb78
}

.focus\:tw-to-green-600:focus{
  --gradient-to-color: #38a169
}

.focus\:tw-to-green-700:focus{
  --gradient-to-color: #2f855a
}

.focus\:tw-to-green-800:focus{
  --gradient-to-color: #276749
}

.focus\:tw-to-green-900:focus{
  --gradient-to-color: #22543d
}

.focus\:tw-to-teal-100:focus{
  --gradient-to-color: #e6fffa
}

.focus\:tw-to-teal-200:focus{
  --gradient-to-color: #b2f5ea
}

.focus\:tw-to-teal-300:focus{
  --gradient-to-color: #81e6d9
}

.focus\:tw-to-teal-400:focus{
  --gradient-to-color: #4fd1c5
}

.focus\:tw-to-teal-500:focus{
  --gradient-to-color: #38b2ac
}

.focus\:tw-to-teal-600:focus{
  --gradient-to-color: #319795
}

.focus\:tw-to-teal-700:focus{
  --gradient-to-color: #2c7a7b
}

.focus\:tw-to-teal-800:focus{
  --gradient-to-color: #285e61
}

.focus\:tw-to-teal-900:focus{
  --gradient-to-color: #234e52
}

.focus\:tw-to-blue-100:focus{
  --gradient-to-color: #ebf8ff
}

.focus\:tw-to-blue-200:focus{
  --gradient-to-color: #bee3f8
}

.focus\:tw-to-blue-300:focus{
  --gradient-to-color: #90cdf4
}

.focus\:tw-to-blue-400:focus{
  --gradient-to-color: #63b3ed
}

.focus\:tw-to-blue-500:focus{
  --gradient-to-color: #4299e1
}

.focus\:tw-to-blue-600:focus{
  --gradient-to-color: #3182ce
}

.focus\:tw-to-blue-700:focus{
  --gradient-to-color: #2b6cb0
}

.focus\:tw-to-blue-800:focus{
  --gradient-to-color: #2c5282
}

.focus\:tw-to-blue-900:focus{
  --gradient-to-color: #2a4365
}

.focus\:tw-to-indigo-100:focus{
  --gradient-to-color: #ebf4ff
}

.focus\:tw-to-indigo-200:focus{
  --gradient-to-color: #c3dafe
}

.focus\:tw-to-indigo-300:focus{
  --gradient-to-color: #a3bffa
}

.focus\:tw-to-indigo-400:focus{
  --gradient-to-color: #7f9cf5
}

.focus\:tw-to-indigo-500:focus{
  --gradient-to-color: #667eea
}

.focus\:tw-to-indigo-600:focus{
  --gradient-to-color: #5a67d8
}

.focus\:tw-to-indigo-700:focus{
  --gradient-to-color: #4c51bf
}

.focus\:tw-to-indigo-800:focus{
  --gradient-to-color: #434190
}

.focus\:tw-to-indigo-900:focus{
  --gradient-to-color: #3c366b
}

.focus\:tw-to-purple-100:focus{
  --gradient-to-color: #faf5ff
}

.focus\:tw-to-purple-200:focus{
  --gradient-to-color: #e9d8fd
}

.focus\:tw-to-purple-300:focus{
  --gradient-to-color: #d6bcfa
}

.focus\:tw-to-purple-400:focus{
  --gradient-to-color: #b794f4
}

.focus\:tw-to-purple-500:focus{
  --gradient-to-color: #9f7aea
}

.focus\:tw-to-purple-600:focus{
  --gradient-to-color: #805ad5
}

.focus\:tw-to-purple-700:focus{
  --gradient-to-color: #6b46c1
}

.focus\:tw-to-purple-800:focus{
  --gradient-to-color: #553c9a
}

.focus\:tw-to-purple-900:focus{
  --gradient-to-color: #44337a
}

.focus\:tw-to-pink-100:focus{
  --gradient-to-color: #fff5f7
}

.focus\:tw-to-pink-200:focus{
  --gradient-to-color: #fed7e2
}

.focus\:tw-to-pink-300:focus{
  --gradient-to-color: #fbb6ce
}

.focus\:tw-to-pink-400:focus{
  --gradient-to-color: #f687b3
}

.focus\:tw-to-pink-500:focus{
  --gradient-to-color: #ed64a6
}

.focus\:tw-to-pink-600:focus{
  --gradient-to-color: #d53f8c
}

.focus\:tw-to-pink-700:focus{
  --gradient-to-color: #b83280
}

.focus\:tw-to-pink-800:focus{
  --gradient-to-color: #97266d
}

.focus\:tw-to-pink-900:focus{
  --gradient-to-color: #702459
}

.tw-bg-opacity-0{
  --bg-opacity: 0
}

.tw-bg-opacity-25{
  --bg-opacity: 0.25
}

.tw-bg-opacity-50{
  --bg-opacity: 0.5
}

.tw-bg-opacity-75{
  --bg-opacity: 0.75
}

.tw-bg-opacity-100{
  --bg-opacity: 1
}

.hover\:tw-bg-opacity-0:hover{
  --bg-opacity: 0
}

.hover\:tw-bg-opacity-25:hover{
  --bg-opacity: 0.25
}

.hover\:tw-bg-opacity-50:hover{
  --bg-opacity: 0.5
}

.hover\:tw-bg-opacity-75:hover{
  --bg-opacity: 0.75
}

.hover\:tw-bg-opacity-100:hover{
  --bg-opacity: 1
}

.focus\:tw-bg-opacity-0:focus{
  --bg-opacity: 0
}

.focus\:tw-bg-opacity-25:focus{
  --bg-opacity: 0.25
}

.focus\:tw-bg-opacity-50:focus{
  --bg-opacity: 0.5
}

.focus\:tw-bg-opacity-75:focus{
  --bg-opacity: 0.75
}

.focus\:tw-bg-opacity-100:focus{
  --bg-opacity: 1
}

.tw-bg-bottom{
  background-position: bottom
}

.tw-bg-center{
  background-position: center
}

.tw-bg-left{
  background-position: left
}

.tw-bg-left-bottom{
  background-position: left bottom
}

.tw-bg-left-top{
  background-position: left top
}

.tw-bg-right{
  background-position: right
}

.tw-bg-right-bottom{
  background-position: right bottom
}

.tw-bg-right-top{
  background-position: right top
}

.tw-bg-top{
  background-position: top
}

.tw-bg-repeat{
  background-repeat: repeat
}

.tw-bg-no-repeat{
  background-repeat: no-repeat
}

.tw-bg-repeat-x{
  background-repeat: repeat-x
}

.tw-bg-repeat-y{
  background-repeat: repeat-y
}

.tw-bg-repeat-round{
  background-repeat: round
}

.tw-bg-repeat-space{
  background-repeat: space
}

.tw-bg-auto{
  background-size: auto
}

.tw-bg-cover{
  background-size: cover
}

.tw-bg-contain{
  background-size: contain
}

.tw-border-collapse{
  border-collapse: collapse
}

.tw-border-separate{
  border-collapse: separate
}

.tw-border-transparent{
  border-color: transparent
}

.tw-border-tlg-green-dark{
  --border-opacity: 1;
  border-color: #76b84e;
  border-color: rgba(118, 184, 78, var(--border-opacity))
}

.tw-border-tlg-green{
  --border-opacity: 1;
  border-color: #a4ca61;
  border-color: rgba(164, 202, 97, var(--border-opacity))
}

.tw-border-tlg-orange-500{
  --border-opacity: 1;
  border-color: #ff7f41;
  border-color: rgba(255, 127, 65, var(--border-opacity))
}

.tw-border-black{
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity))
}

.tw-border-white{
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.tw-border-gray-100{
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity))
}

.tw-border-gray-200{
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity))
}

.tw-border-gray-300{
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity))
}

.tw-border-gray-400{
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.tw-border-gray-500{
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity))
}

.tw-border-gray-600{
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity))
}

.tw-border-gray-700{
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity))
}

.tw-border-gray-800{
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity))
}

.tw-border-gray-900{
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity))
}

.tw-border-red-100{
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity))
}

.tw-border-red-200{
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity))
}

.tw-border-red-300{
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity))
}

.tw-border-red-400{
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity))
}

.tw-border-red-500{
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity))
}

.tw-border-red-600{
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity))
}

.tw-border-red-700{
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity))
}

.tw-border-red-800{
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.tw-border-red-900{
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity))
}

.tw-border-orange-100{
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity))
}

.tw-border-orange-200{
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity))
}

.tw-border-orange-300{
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity))
}

.tw-border-orange-400{
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity))
}

.tw-border-orange-500{
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity))
}

.tw-border-orange-600{
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity))
}

.tw-border-orange-700{
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity))
}

.tw-border-orange-800{
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity))
}

.tw-border-orange-900{
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity))
}

.tw-border-yellow-100{
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity))
}

.tw-border-yellow-200{
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity))
}

.tw-border-yellow-300{
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity))
}

.tw-border-yellow-400{
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity))
}

.tw-border-yellow-500{
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity))
}

.tw-border-yellow-600{
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity))
}

.tw-border-yellow-700{
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity))
}

.tw-border-yellow-800{
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity))
}

.tw-border-yellow-900{
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity))
}

.tw-border-green-100{
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity))
}

.tw-border-green-200{
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity))
}

.tw-border-green-300{
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity))
}

.tw-border-green-400{
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity))
}

.tw-border-green-500{
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity))
}

.tw-border-green-600{
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity))
}

.tw-border-green-700{
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity))
}

.tw-border-green-800{
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity))
}

.tw-border-green-900{
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity))
}

.tw-border-teal-100{
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity))
}

.tw-border-teal-200{
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity))
}

.tw-border-teal-300{
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity))
}

.tw-border-teal-400{
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity))
}

.tw-border-teal-500{
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity))
}

.tw-border-teal-600{
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity))
}

.tw-border-teal-700{
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity))
}

.tw-border-teal-800{
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity))
}

.tw-border-teal-900{
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity))
}

.tw-border-blue-100{
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity))
}

.tw-border-blue-200{
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity))
}

.tw-border-blue-300{
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity))
}

.tw-border-blue-400{
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity))
}

.tw-border-blue-500{
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.tw-border-blue-600{
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.tw-border-blue-700{
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity))
}

.tw-border-blue-800{
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity))
}

.tw-border-blue-900{
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity))
}

.tw-border-indigo-100{
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity))
}

.tw-border-indigo-200{
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity))
}

.tw-border-indigo-300{
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity))
}

.tw-border-indigo-400{
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity))
}

.tw-border-indigo-500{
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity))
}

.tw-border-indigo-600{
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity))
}

.tw-border-indigo-700{
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity))
}

.tw-border-indigo-800{
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity))
}

.tw-border-indigo-900{
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity))
}

.tw-border-purple-100{
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity))
}

.tw-border-purple-200{
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity))
}

.tw-border-purple-300{
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity))
}

.tw-border-purple-400{
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity))
}

.tw-border-purple-500{
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity))
}

.tw-border-purple-600{
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity))
}

.tw-border-purple-700{
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity))
}

.tw-border-purple-800{
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity))
}

.tw-border-purple-900{
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity))
}

.tw-border-pink-100{
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity))
}

.tw-border-pink-200{
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity))
}

.tw-border-pink-300{
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity))
}

.tw-border-pink-400{
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity))
}

.tw-border-pink-500{
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity))
}

.tw-border-pink-600{
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity))
}

.tw-border-pink-700{
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity))
}

.tw-border-pink-800{
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity))
}

.tw-border-pink-900{
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity))
}

.hover\:tw-border-transparent:hover{
  border-color: transparent
}

.hover\:tw-border-tlg-green-dark:hover{
  --border-opacity: 1;
  border-color: #76b84e;
  border-color: rgba(118, 184, 78, var(--border-opacity))
}

.hover\:tw-border-tlg-green:hover{
  --border-opacity: 1;
  border-color: #a4ca61;
  border-color: rgba(164, 202, 97, var(--border-opacity))
}

.hover\:tw-border-tlg-orange-500:hover{
  --border-opacity: 1;
  border-color: #ff7f41;
  border-color: rgba(255, 127, 65, var(--border-opacity))
}

.hover\:tw-border-black:hover{
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity))
}

.hover\:tw-border-white:hover{
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.hover\:tw-border-gray-100:hover{
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity))
}

.hover\:tw-border-gray-200:hover{
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity))
}

.hover\:tw-border-gray-300:hover{
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity))
}

.hover\:tw-border-gray-400:hover{
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.hover\:tw-border-gray-500:hover{
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity))
}

.hover\:tw-border-gray-600:hover{
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity))
}

.hover\:tw-border-gray-700:hover{
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity))
}

.hover\:tw-border-gray-800:hover{
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity))
}

.hover\:tw-border-gray-900:hover{
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity))
}

.hover\:tw-border-red-100:hover{
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity))
}

.hover\:tw-border-red-200:hover{
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity))
}

.hover\:tw-border-red-300:hover{
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity))
}

.hover\:tw-border-red-400:hover{
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity))
}

.hover\:tw-border-red-500:hover{
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity))
}

.hover\:tw-border-red-600:hover{
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity))
}

.hover\:tw-border-red-700:hover{
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity))
}

.hover\:tw-border-red-800:hover{
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.hover\:tw-border-red-900:hover{
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity))
}

.hover\:tw-border-orange-100:hover{
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity))
}

.hover\:tw-border-orange-200:hover{
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity))
}

.hover\:tw-border-orange-300:hover{
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity))
}

.hover\:tw-border-orange-400:hover{
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity))
}

.hover\:tw-border-orange-500:hover{
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity))
}

.hover\:tw-border-orange-600:hover{
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity))
}

.hover\:tw-border-orange-700:hover{
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity))
}

.hover\:tw-border-orange-800:hover{
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity))
}

.hover\:tw-border-orange-900:hover{
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity))
}

.hover\:tw-border-yellow-100:hover{
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity))
}

.hover\:tw-border-yellow-200:hover{
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity))
}

.hover\:tw-border-yellow-300:hover{
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity))
}

.hover\:tw-border-yellow-400:hover{
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity))
}

.hover\:tw-border-yellow-500:hover{
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity))
}

.hover\:tw-border-yellow-600:hover{
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity))
}

.hover\:tw-border-yellow-700:hover{
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity))
}

.hover\:tw-border-yellow-800:hover{
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity))
}

.hover\:tw-border-yellow-900:hover{
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity))
}

.hover\:tw-border-green-100:hover{
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity))
}

.hover\:tw-border-green-200:hover{
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity))
}

.hover\:tw-border-green-300:hover{
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity))
}

.hover\:tw-border-green-400:hover{
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity))
}

.hover\:tw-border-green-500:hover{
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity))
}

.hover\:tw-border-green-600:hover{
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity))
}

.hover\:tw-border-green-700:hover{
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity))
}

.hover\:tw-border-green-800:hover{
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity))
}

.hover\:tw-border-green-900:hover{
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity))
}

.hover\:tw-border-teal-100:hover{
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity))
}

.hover\:tw-border-teal-200:hover{
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity))
}

.hover\:tw-border-teal-300:hover{
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity))
}

.hover\:tw-border-teal-400:hover{
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity))
}

.hover\:tw-border-teal-500:hover{
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity))
}

.hover\:tw-border-teal-600:hover{
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity))
}

.hover\:tw-border-teal-700:hover{
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity))
}

.hover\:tw-border-teal-800:hover{
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity))
}

.hover\:tw-border-teal-900:hover{
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity))
}

.hover\:tw-border-blue-100:hover{
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity))
}

.hover\:tw-border-blue-200:hover{
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity))
}

.hover\:tw-border-blue-300:hover{
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity))
}

.hover\:tw-border-blue-400:hover{
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity))
}

.hover\:tw-border-blue-500:hover{
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.hover\:tw-border-blue-600:hover{
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.hover\:tw-border-blue-700:hover{
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity))
}

.hover\:tw-border-blue-800:hover{
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity))
}

.hover\:tw-border-blue-900:hover{
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity))
}

.hover\:tw-border-indigo-100:hover{
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity))
}

.hover\:tw-border-indigo-200:hover{
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity))
}

.hover\:tw-border-indigo-300:hover{
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity))
}

.hover\:tw-border-indigo-400:hover{
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity))
}

.hover\:tw-border-indigo-500:hover{
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity))
}

.hover\:tw-border-indigo-600:hover{
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity))
}

.hover\:tw-border-indigo-700:hover{
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity))
}

.hover\:tw-border-indigo-800:hover{
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity))
}

.hover\:tw-border-indigo-900:hover{
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity))
}

.hover\:tw-border-purple-100:hover{
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity))
}

.hover\:tw-border-purple-200:hover{
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity))
}

.hover\:tw-border-purple-300:hover{
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity))
}

.hover\:tw-border-purple-400:hover{
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity))
}

.hover\:tw-border-purple-500:hover{
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity))
}

.hover\:tw-border-purple-600:hover{
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity))
}

.hover\:tw-border-purple-700:hover{
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity))
}

.hover\:tw-border-purple-800:hover{
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity))
}

.hover\:tw-border-purple-900:hover{
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity))
}

.hover\:tw-border-pink-100:hover{
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity))
}

.hover\:tw-border-pink-200:hover{
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity))
}

.hover\:tw-border-pink-300:hover{
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity))
}

.hover\:tw-border-pink-400:hover{
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity))
}

.hover\:tw-border-pink-500:hover{
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity))
}

.hover\:tw-border-pink-600:hover{
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity))
}

.hover\:tw-border-pink-700:hover{
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity))
}

.hover\:tw-border-pink-800:hover{
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity))
}

.hover\:tw-border-pink-900:hover{
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity))
}

.focus\:tw-border-transparent:focus{
  border-color: transparent
}

.focus\:tw-border-tlg-green-dark:focus{
  --border-opacity: 1;
  border-color: #76b84e;
  border-color: rgba(118, 184, 78, var(--border-opacity))
}

.focus\:tw-border-tlg-green:focus{
  --border-opacity: 1;
  border-color: #a4ca61;
  border-color: rgba(164, 202, 97, var(--border-opacity))
}

.focus\:tw-border-tlg-orange-500:focus{
  --border-opacity: 1;
  border-color: #ff7f41;
  border-color: rgba(255, 127, 65, var(--border-opacity))
}

.focus\:tw-border-black:focus{
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity))
}

.focus\:tw-border-white:focus{
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.focus\:tw-border-gray-100:focus{
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity))
}

.focus\:tw-border-gray-200:focus{
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity))
}

.focus\:tw-border-gray-300:focus{
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity))
}

.focus\:tw-border-gray-400:focus{
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity))
}

.focus\:tw-border-gray-500:focus{
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity))
}

.focus\:tw-border-gray-600:focus{
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity))
}

.focus\:tw-border-gray-700:focus{
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity))
}

.focus\:tw-border-gray-800:focus{
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity))
}

.focus\:tw-border-gray-900:focus{
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity))
}

.focus\:tw-border-red-100:focus{
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity))
}

.focus\:tw-border-red-200:focus{
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity))
}

.focus\:tw-border-red-300:focus{
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity))
}

.focus\:tw-border-red-400:focus{
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity))
}

.focus\:tw-border-red-500:focus{
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity))
}

.focus\:tw-border-red-600:focus{
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity))
}

.focus\:tw-border-red-700:focus{
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity))
}

.focus\:tw-border-red-800:focus{
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity))
}

.focus\:tw-border-red-900:focus{
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity))
}

.focus\:tw-border-orange-100:focus{
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity))
}

.focus\:tw-border-orange-200:focus{
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity))
}

.focus\:tw-border-orange-300:focus{
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity))
}

.focus\:tw-border-orange-400:focus{
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity))
}

.focus\:tw-border-orange-500:focus{
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity))
}

.focus\:tw-border-orange-600:focus{
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity))
}

.focus\:tw-border-orange-700:focus{
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity))
}

.focus\:tw-border-orange-800:focus{
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity))
}

.focus\:tw-border-orange-900:focus{
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity))
}

.focus\:tw-border-yellow-100:focus{
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity))
}

.focus\:tw-border-yellow-200:focus{
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity))
}

.focus\:tw-border-yellow-300:focus{
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity))
}

.focus\:tw-border-yellow-400:focus{
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity))
}

.focus\:tw-border-yellow-500:focus{
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity))
}

.focus\:tw-border-yellow-600:focus{
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity))
}

.focus\:tw-border-yellow-700:focus{
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity))
}

.focus\:tw-border-yellow-800:focus{
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity))
}

.focus\:tw-border-yellow-900:focus{
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity))
}

.focus\:tw-border-green-100:focus{
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity))
}

.focus\:tw-border-green-200:focus{
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity))
}

.focus\:tw-border-green-300:focus{
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity))
}

.focus\:tw-border-green-400:focus{
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity))
}

.focus\:tw-border-green-500:focus{
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity))
}

.focus\:tw-border-green-600:focus{
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity))
}

.focus\:tw-border-green-700:focus{
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity))
}

.focus\:tw-border-green-800:focus{
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity))
}

.focus\:tw-border-green-900:focus{
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity))
}

.focus\:tw-border-teal-100:focus{
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity))
}

.focus\:tw-border-teal-200:focus{
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity))
}

.focus\:tw-border-teal-300:focus{
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity))
}

.focus\:tw-border-teal-400:focus{
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity))
}

.focus\:tw-border-teal-500:focus{
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity))
}

.focus\:tw-border-teal-600:focus{
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity))
}

.focus\:tw-border-teal-700:focus{
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity))
}

.focus\:tw-border-teal-800:focus{
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity))
}

.focus\:tw-border-teal-900:focus{
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity))
}

.focus\:tw-border-blue-100:focus{
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity))
}

.focus\:tw-border-blue-200:focus{
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity))
}

.focus\:tw-border-blue-300:focus{
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity))
}

.focus\:tw-border-blue-400:focus{
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity))
}

.focus\:tw-border-blue-500:focus{
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity))
}

.focus\:tw-border-blue-600:focus{
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity))
}

.focus\:tw-border-blue-700:focus{
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity))
}

.focus\:tw-border-blue-800:focus{
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity))
}

.focus\:tw-border-blue-900:focus{
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity))
}

.focus\:tw-border-indigo-100:focus{
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity))
}

.focus\:tw-border-indigo-200:focus{
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity))
}

.focus\:tw-border-indigo-300:focus{
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity))
}

.focus\:tw-border-indigo-400:focus{
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity))
}

.focus\:tw-border-indigo-500:focus{
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity))
}

.focus\:tw-border-indigo-600:focus{
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity))
}

.focus\:tw-border-indigo-700:focus{
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity))
}

.focus\:tw-border-indigo-800:focus{
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity))
}

.focus\:tw-border-indigo-900:focus{
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity))
}

.focus\:tw-border-purple-100:focus{
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity))
}

.focus\:tw-border-purple-200:focus{
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity))
}

.focus\:tw-border-purple-300:focus{
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity))
}

.focus\:tw-border-purple-400:focus{
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity))
}

.focus\:tw-border-purple-500:focus{
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity))
}

.focus\:tw-border-purple-600:focus{
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity))
}

.focus\:tw-border-purple-700:focus{
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity))
}

.focus\:tw-border-purple-800:focus{
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity))
}

.focus\:tw-border-purple-900:focus{
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity))
}

.focus\:tw-border-pink-100:focus{
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity))
}

.focus\:tw-border-pink-200:focus{
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity))
}

.focus\:tw-border-pink-300:focus{
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity))
}

.focus\:tw-border-pink-400:focus{
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity))
}

.focus\:tw-border-pink-500:focus{
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity))
}

.focus\:tw-border-pink-600:focus{
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity))
}

.focus\:tw-border-pink-700:focus{
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity))
}

.focus\:tw-border-pink-800:focus{
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity))
}

.focus\:tw-border-pink-900:focus{
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity))
}

.tw-border-opacity-0{
  --border-opacity: 0
}

.tw-border-opacity-25{
  --border-opacity: 0.25
}

.tw-border-opacity-50{
  --border-opacity: 0.5
}

.tw-border-opacity-75{
  --border-opacity: 0.75
}

.tw-border-opacity-100{
  --border-opacity: 1
}

.hover\:tw-border-opacity-0:hover{
  --border-opacity: 0
}

.hover\:tw-border-opacity-25:hover{
  --border-opacity: 0.25
}

.hover\:tw-border-opacity-50:hover{
  --border-opacity: 0.5
}

.hover\:tw-border-opacity-75:hover{
  --border-opacity: 0.75
}

.hover\:tw-border-opacity-100:hover{
  --border-opacity: 1
}

.focus\:tw-border-opacity-0:focus{
  --border-opacity: 0
}

.focus\:tw-border-opacity-25:focus{
  --border-opacity: 0.25
}

.focus\:tw-border-opacity-50:focus{
  --border-opacity: 0.5
}

.focus\:tw-border-opacity-75:focus{
  --border-opacity: 0.75
}

.focus\:tw-border-opacity-100:focus{
  --border-opacity: 1
}

.tw-rounded-none{
  border-radius: 0
}

.tw-rounded-sm{
  border-radius: 0.125rem
}

.tw-rounded{
  border-radius: 0.25rem
}

.tw-rounded-lg{
  border-radius: 0.5rem
}

.tw-rounded-full{
  border-radius: 9999px
}

.tw-rounded-t-none{
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.tw-rounded-r-none{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.tw-rounded-b-none{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.tw-rounded-l-none{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.tw-rounded-t-sm{
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem
}

.tw-rounded-r-sm{
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem
}

.tw-rounded-b-sm{
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

.tw-rounded-l-sm{
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

.tw-rounded-t{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.tw-rounded-r{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

.tw-rounded-b{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.tw-rounded-l{
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.tw-rounded-t-lg{
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.tw-rounded-r-lg{
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem
}

.tw-rounded-b-lg{
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.tw-rounded-l-lg{
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.tw-rounded-t-full{
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px
}

.tw-rounded-r-full{
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px
}

.tw-rounded-b-full{
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.tw-rounded-l-full{
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.tw-rounded-tl-none{
  border-top-left-radius: 0
}

.tw-rounded-tr-none{
  border-top-right-radius: 0
}

.tw-rounded-br-none{
  border-bottom-right-radius: 0
}

.tw-rounded-bl-none{
  border-bottom-left-radius: 0
}

.tw-rounded-tl-sm{
  border-top-left-radius: 0.125rem
}

.tw-rounded-tr-sm{
  border-top-right-radius: 0.125rem
}

.tw-rounded-br-sm{
  border-bottom-right-radius: 0.125rem
}

.tw-rounded-bl-sm{
  border-bottom-left-radius: 0.125rem
}

.tw-rounded-tl{
  border-top-left-radius: 0.25rem
}

.tw-rounded-tr{
  border-top-right-radius: 0.25rem
}

.tw-rounded-br{
  border-bottom-right-radius: 0.25rem
}

.tw-rounded-bl{
  border-bottom-left-radius: 0.25rem
}

.tw-rounded-tl-lg{
  border-top-left-radius: 0.5rem
}

.tw-rounded-tr-lg{
  border-top-right-radius: 0.5rem
}

.tw-rounded-br-lg{
  border-bottom-right-radius: 0.5rem
}

.tw-rounded-bl-lg{
  border-bottom-left-radius: 0.5rem
}

.tw-rounded-tl-full{
  border-top-left-radius: 9999px
}

.tw-rounded-tr-full{
  border-top-right-radius: 9999px
}

.tw-rounded-br-full{
  border-bottom-right-radius: 9999px
}

.tw-rounded-bl-full{
  border-bottom-left-radius: 9999px
}

.tw-border-solid{
  border-style: solid
}

.tw-border-dashed{
  border-style: dashed
}

.tw-border-dotted{
  border-style: dotted
}

.tw-border-double{
  border-style: double
}

.tw-border-none{
  border-style: none
}

.tw-border-0{
  border-width: 0
}

.tw-border-2{
  border-width: 2px
}

.tw-border-4{
  border-width: 4px
}

.tw-border-8{
  border-width: 8px
}

.tw-border{
  border-width: 1px
}

.tw-border-t-0{
  border-top-width: 0
}

.tw-border-r-0{
  border-right-width: 0
}

.tw-border-b-0{
  border-bottom-width: 0
}

.tw-border-l-0{
  border-left-width: 0
}

.tw-border-t-2{
  border-top-width: 2px
}

.tw-border-r-2{
  border-right-width: 2px
}

.tw-border-b-2{
  border-bottom-width: 2px
}

.tw-border-l-2{
  border-left-width: 2px
}

.tw-border-t-4{
  border-top-width: 4px
}

.tw-border-r-4{
  border-right-width: 4px
}

.tw-border-b-4{
  border-bottom-width: 4px
}

.tw-border-l-4{
  border-left-width: 4px
}

.tw-border-t-8{
  border-top-width: 8px
}

.tw-border-r-8{
  border-right-width: 8px
}

.tw-border-b-8{
  border-bottom-width: 8px
}

.tw-border-l-8{
  border-left-width: 8px
}

.tw-border-t{
  border-top-width: 1px
}

.tw-border-r{
  border-right-width: 1px
}

.tw-border-b{
  border-bottom-width: 1px
}

.tw-border-l{
  border-left-width: 1px
}

.tw-box-border{
  box-sizing: border-box
}

.tw-box-content{
  box-sizing: content-box
}

.tw-cursor-auto{
  cursor: auto
}

.tw-cursor-default{
  cursor: default
}

.tw-cursor-pointer{
  cursor: pointer
}

.tw-cursor-wait{
  cursor: wait
}

.tw-cursor-text{
  cursor: text
}

.tw-cursor-move{
  cursor: move
}

.tw-cursor-not-allowed{
  cursor: not-allowed
}

.tw-block{
  display: block
}

.tw-inline-block{
  display: inline-block
}

.tw-inline{
  display: inline
}

.tw-flex{
  display: flex
}

.tw-inline-flex{
  display: inline-flex
}

.tw-table{
  display: table
}

.tw-table-caption{
  display: table-caption
}

.tw-table-cell{
  display: table-cell
}

.tw-table-column{
  display: table-column
}

.tw-table-column-group{
  display: table-column-group
}

.tw-table-footer-group{
  display: table-footer-group
}

.tw-table-header-group{
  display: table-header-group
}

.tw-table-row-group{
  display: table-row-group
}

.tw-table-row{
  display: table-row
}

.tw-flow-root{
  display: flow-root
}

.tw-grid{
  display: grid
}

.tw-inline-grid{
  display: inline-grid
}

.tw-contents{
  display: contents
}

.tw-hidden{
  display: none
}

.tw-flex-row{
  flex-direction: row
}

.tw-flex-row-reverse{
  flex-direction: row-reverse
}

.tw-flex-col{
  flex-direction: column
}

.tw-flex-col-reverse{
  flex-direction: column-reverse
}

.tw-flex-wrap{
  flex-wrap: wrap
}

.tw-flex-wrap-reverse{
  flex-wrap: wrap-reverse
}

.tw-flex-no-wrap{
  flex-wrap: nowrap
}

.tw-place-items-auto{
  place-items: auto
}

.tw-place-items-start{
  place-items: start
}

.tw-place-items-end{
  place-items: end
}

.tw-place-items-center{
  place-items: center
}

.tw-place-items-stretch{
  place-items: stretch
}

.tw-place-content-center{
  place-content: center
}

.tw-place-content-start{
  place-content: start
}

.tw-place-content-end{
  place-content: end
}

.tw-place-content-between{
  place-content: space-between
}

.tw-place-content-around{
  place-content: space-around
}

.tw-place-content-evenly{
  place-content: space-evenly
}

.tw-place-content-stretch{
  place-content: stretch
}

.tw-place-self-auto{
  place-self: auto
}

.tw-place-self-start{
  place-self: start
}

.tw-place-self-end{
  place-self: end
}

.tw-place-self-center{
  place-self: center
}

.tw-place-self-stretch{
  place-self: stretch
}

.tw-items-start{
  align-items: flex-start
}

.tw-items-end{
  align-items: flex-end
}

.tw-items-center{
  align-items: center
}

.tw-items-baseline{
  align-items: baseline
}

.tw-items-stretch{
  align-items: stretch
}

.tw-content-center{
  align-content: center
}

.tw-content-start{
  align-content: flex-start
}

.tw-content-end{
  align-content: flex-end
}

.tw-content-between{
  align-content: space-between
}

.tw-content-around{
  align-content: space-around
}

.tw-content-evenly{
  align-content: space-evenly
}

.tw-self-auto{
  align-self: auto
}

.tw-self-start{
  align-self: flex-start
}

.tw-self-end{
  align-self: flex-end
}

.tw-self-center{
  align-self: center
}

.tw-self-stretch{
  align-self: stretch
}

.tw-justify-items-auto{
  justify-items: auto
}

.tw-justify-items-start{
  justify-items: start
}

.tw-justify-items-end{
  justify-items: end
}

.tw-justify-items-center{
  justify-items: center
}

.tw-justify-items-stretch{
  justify-items: stretch
}

.tw-justify-start{
  justify-content: flex-start
}

.tw-justify-end{
  justify-content: flex-end
}

.tw-justify-center{
  justify-content: center
}

.tw-justify-between{
  justify-content: space-between
}

.tw-justify-around{
  justify-content: space-around
}

.tw-justify-evenly{
  justify-content: space-evenly
}

.tw-justify-self-auto{
  justify-self: auto
}

.tw-justify-self-start{
  justify-self: start
}

.tw-justify-self-end{
  justify-self: end
}

.tw-justify-self-center{
  justify-self: center
}

.tw-justify-self-stretch{
  justify-self: stretch
}

.tw-flex-1{
  flex: 1 1
}

.tw-flex-auto{
  flex: 1 1 auto
}

.tw-flex-initial{
  flex: 0 1 auto
}

.tw-flex-none{
  flex: none
}

.tw-flex-grow-0{
  flex-grow: 0
}

.tw-flex-grow{
  flex-grow: 1
}

.tw-flex-shrink-0{
  flex-shrink: 0
}

.tw-flex-shrink{
  flex-shrink: 1
}

.tw-order-1{
  order: 1
}

.tw-order-2{
  order: 2
}

.tw-order-3{
  order: 3
}

.tw-order-4{
  order: 4
}

.tw-order-5{
  order: 5
}

.tw-order-6{
  order: 6
}

.tw-order-7{
  order: 7
}

.tw-order-8{
  order: 8
}

.tw-order-9{
  order: 9
}

.tw-order-10{
  order: 10
}

.tw-order-11{
  order: 11
}

.tw-order-12{
  order: 12
}

.tw-order-first{
  order: -9999
}

.tw-order-last{
  order: 9999
}

.tw-order-none{
  order: 0
}

.tw-float-right{
  float: right
}

.tw-float-left{
  float: left
}

.tw-float-none{
  float: none
}

.tw-clearfix:after{
  content: "";
  display: table;
  clear: both
}

.tw-clear-left{
  clear: left
}

.tw-clear-right{
  clear: right
}

.tw-clear-both{
  clear: both
}

.tw-clear-none{
  clear: none
}

.tw-font-sans{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.tw-font-serif{
  font-family: Georgia, Cambria, "Times New Roman", Times, serif
}

.tw-font-mono{
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.tw-font-hairline{
  font-weight: 100
}

.tw-font-thin{
  font-weight: 200
}

.tw-font-light{
  font-weight: 300
}

.tw-font-normal{
  font-weight: 400
}

.tw-font-medium{
  font-weight: 500
}

.tw-font-semibold{
  font-weight: 600
}

.tw-font-bold{
  font-weight: 700
}

.tw-font-extrabold{
  font-weight: 800
}

.tw-font-black{
  font-weight: 900
}

.hover\:tw-font-hairline:hover{
  font-weight: 100
}

.hover\:tw-font-thin:hover{
  font-weight: 200
}

.hover\:tw-font-light:hover{
  font-weight: 300
}

.hover\:tw-font-normal:hover{
  font-weight: 400
}

.hover\:tw-font-medium:hover{
  font-weight: 500
}

.hover\:tw-font-semibold:hover{
  font-weight: 600
}

.hover\:tw-font-bold:hover{
  font-weight: 700
}

.hover\:tw-font-extrabold:hover{
  font-weight: 800
}

.hover\:tw-font-black:hover{
  font-weight: 900
}

.focus\:tw-font-hairline:focus{
  font-weight: 100
}

.focus\:tw-font-thin:focus{
  font-weight: 200
}

.focus\:tw-font-light:focus{
  font-weight: 300
}

.focus\:tw-font-normal:focus{
  font-weight: 400
}

.focus\:tw-font-medium:focus{
  font-weight: 500
}

.focus\:tw-font-semibold:focus{
  font-weight: 600
}

.focus\:tw-font-bold:focus{
  font-weight: 700
}

.focus\:tw-font-extrabold:focus{
  font-weight: 800
}

.focus\:tw-font-black:focus{
  font-weight: 900
}

.tw-h-0{
  height: 0
}

.tw-h-1{
  height: 0.25rem
}

.tw-h-2{
  height: 0.5rem
}

.tw-h-3{
  height: 0.75rem
}

.tw-h-4{
  height: 1rem
}

.tw-h-5{
  height: 1.25rem
}

.tw-h-6{
  height: 1.5rem
}

.tw-h-8{
  height: 2rem
}

.tw-h-10{
  height: 2.5rem
}

.tw-h-12{
  height: 3rem
}

.tw-h-16{
  height: 4rem
}

.tw-h-20{
  height: 5rem
}

.tw-h-24{
  height: 6rem
}

.tw-h-32{
  height: 8rem
}

.tw-h-40{
  height: 10rem
}

.tw-h-48{
  height: 12rem
}

.tw-h-56{
  height: 14rem
}

.tw-h-64{
  height: 16rem
}

.tw-h-auto{
  height: auto
}

.tw-h-px{
  height: 1px
}

.tw-h-full{
  height: 100%
}

.tw-h-screen{
  height: 100vh
}

.tw-text-xs{
  font-size: 0.75rem
}

.tw-text-sm{
  font-size: 0.875rem
}

.tw-text-base{
  font-size: 1rem
}

.tw-text-lg{
  font-size: 1.125rem
}

.tw-text-xl{
  font-size: 1.25rem
}

.tw-text-2xl{
  font-size: 1.5rem
}

.tw-text-3xl{
  font-size: 1.875rem
}

.tw-text-4xl{
  font-size: 2.25rem
}

.tw-text-5xl{
  font-size: 3rem
}

.tw-text-6xl{
  font-size: 4rem
}

.tw-leading-none{
  line-height: 1
}

.tw-leading-tight{
  line-height: 1.25
}

.tw-leading-snug{
  line-height: 1.375
}

.tw-leading-normal{
  line-height: 1.5
}

.tw-leading-relaxed{
  line-height: 1.625
}

.tw-leading-loose{
  line-height: 2
}

.tw-list-inside{
  list-style-position: inside
}

.tw-list-outside{
  list-style-position: outside
}

.tw-list-none{
  list-style-type: none
}

.tw-list-disc{
  list-style-type: disc
}

.tw-list-decimal{
  list-style-type: decimal
}

.tw-m-0{
  margin: 0
}

.tw-m-1{
  margin: 0.25rem
}

.tw-m-2{
  margin: 0.5rem
}

.tw-m-3{
  margin: 0.75rem
}

.tw-m-4{
  margin: 1rem
}

.tw-m-5{
  margin: 1.25rem
}

.tw-m-6{
  margin: 1.5rem
}

.tw-m-8{
  margin: 2rem
}

.tw-m-10{
  margin: 2.5rem
}

.tw-m-12{
  margin: 3rem
}

.tw-m-16{
  margin: 4rem
}

.tw-m-20{
  margin: 5rem
}

.tw-m-24{
  margin: 6rem
}

.tw-m-32{
  margin: 8rem
}

.tw-m-40{
  margin: 10rem
}

.tw-m-48{
  margin: 12rem
}

.tw-m-56{
  margin: 14rem
}

.tw-m-64{
  margin: 16rem
}

.tw-m-auto{
  margin: auto
}

.tw-m-px{
  margin: 1px
}

.tw--m-1{
  margin: -0.25rem
}

.tw--m-2{
  margin: -0.5rem
}

.tw--m-3{
  margin: -0.75rem
}

.tw--m-4{
  margin: -1rem
}

.tw--m-5{
  margin: -1.25rem
}

.tw--m-6{
  margin: -1.5rem
}

.tw--m-8{
  margin: -2rem
}

.tw--m-10{
  margin: -2.5rem
}

.tw--m-12{
  margin: -3rem
}

.tw--m-16{
  margin: -4rem
}

.tw--m-20{
  margin: -5rem
}

.tw--m-24{
  margin: -6rem
}

.tw--m-32{
  margin: -8rem
}

.tw--m-40{
  margin: -10rem
}

.tw--m-48{
  margin: -12rem
}

.tw--m-56{
  margin: -14rem
}

.tw--m-64{
  margin: -16rem
}

.tw--m-px{
  margin: -1px
}

.tw-my-0{
  margin-top: 0;
  margin-bottom: 0
}

.tw-mx-0{
  margin-left: 0;
  margin-right: 0
}

.tw-my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.tw-mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.tw-my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.tw-mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.tw-my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.tw-mx-3{
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

.tw-my-4{
  margin-top: 1rem;
  margin-bottom: 1rem
}

.tw-mx-4{
  margin-left: 1rem;
  margin-right: 1rem
}

.tw-my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.tw-mx-5{
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.tw-my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.tw-mx-6{
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.tw-my-8{
  margin-top: 2rem;
  margin-bottom: 2rem
}

.tw-mx-8{
  margin-left: 2rem;
  margin-right: 2rem
}

.tw-my-10{
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.tw-mx-10{
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.tw-my-12{
  margin-top: 3rem;
  margin-bottom: 3rem
}

.tw-mx-12{
  margin-left: 3rem;
  margin-right: 3rem
}

.tw-my-16{
  margin-top: 4rem;
  margin-bottom: 4rem
}

.tw-mx-16{
  margin-left: 4rem;
  margin-right: 4rem
}

.tw-my-20{
  margin-top: 5rem;
  margin-bottom: 5rem
}

.tw-mx-20{
  margin-left: 5rem;
  margin-right: 5rem
}

.tw-my-24{
  margin-top: 6rem;
  margin-bottom: 6rem
}

.tw-mx-24{
  margin-left: 6rem;
  margin-right: 6rem
}

.tw-my-32{
  margin-top: 8rem;
  margin-bottom: 8rem
}

.tw-mx-32{
  margin-left: 8rem;
  margin-right: 8rem
}

.tw-my-40{
  margin-top: 10rem;
  margin-bottom: 10rem
}

.tw-mx-40{
  margin-left: 10rem;
  margin-right: 10rem
}

.tw-my-48{
  margin-top: 12rem;
  margin-bottom: 12rem
}

.tw-mx-48{
  margin-left: 12rem;
  margin-right: 12rem
}

.tw-my-56{
  margin-top: 14rem;
  margin-bottom: 14rem
}

.tw-mx-56{
  margin-left: 14rem;
  margin-right: 14rem
}

.tw-my-64{
  margin-top: 16rem;
  margin-bottom: 16rem
}

.tw-mx-64{
  margin-left: 16rem;
  margin-right: 16rem
}

.tw-my-auto{
  margin-top: auto;
  margin-bottom: auto
}

.tw-mx-auto{
  margin-left: auto;
  margin-right: auto
}

.tw-my-px{
  margin-top: 1px;
  margin-bottom: 1px
}

.tw-mx-px{
  margin-left: 1px;
  margin-right: 1px
}

.tw--my-1{
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.tw--mx-1{
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

.tw--my-2{
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

.tw--mx-2{
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.tw--my-3{
  margin-top: -0.75rem;
  margin-bottom: -0.75rem
}

.tw--mx-3{
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

.tw--my-4{
  margin-top: -1rem;
  margin-bottom: -1rem
}

.tw--mx-4{
  margin-left: -1rem;
  margin-right: -1rem
}

.tw--my-5{
  margin-top: -1.25rem;
  margin-bottom: -1.25rem
}

.tw--mx-5{
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

.tw--my-6{
  margin-top: -1.5rem;
  margin-bottom: -1.5rem
}

.tw--mx-6{
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.tw--my-8{
  margin-top: -2rem;
  margin-bottom: -2rem
}

.tw--mx-8{
  margin-left: -2rem;
  margin-right: -2rem
}

.tw--my-10{
  margin-top: -2.5rem;
  margin-bottom: -2.5rem
}

.tw--mx-10{
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

.tw--my-12{
  margin-top: -3rem;
  margin-bottom: -3rem
}

.tw--mx-12{
  margin-left: -3rem;
  margin-right: -3rem
}

.tw--my-16{
  margin-top: -4rem;
  margin-bottom: -4rem
}

.tw--mx-16{
  margin-left: -4rem;
  margin-right: -4rem
}

.tw--my-20{
  margin-top: -5rem;
  margin-bottom: -5rem
}

.tw--mx-20{
  margin-left: -5rem;
  margin-right: -5rem
}

.tw--my-24{
  margin-top: -6rem;
  margin-bottom: -6rem
}

.tw--mx-24{
  margin-left: -6rem;
  margin-right: -6rem
}

.tw--my-32{
  margin-top: -8rem;
  margin-bottom: -8rem
}

.tw--mx-32{
  margin-left: -8rem;
  margin-right: -8rem
}

.tw--my-40{
  margin-top: -10rem;
  margin-bottom: -10rem
}

.tw--mx-40{
  margin-left: -10rem;
  margin-right: -10rem
}

.tw--my-48{
  margin-top: -12rem;
  margin-bottom: -12rem
}

.tw--mx-48{
  margin-left: -12rem;
  margin-right: -12rem
}

.tw--my-56{
  margin-top: -14rem;
  margin-bottom: -14rem
}

.tw--mx-56{
  margin-left: -14rem;
  margin-right: -14rem
}

.tw--my-64{
  margin-top: -16rem;
  margin-bottom: -16rem
}

.tw--mx-64{
  margin-left: -16rem;
  margin-right: -16rem
}

.tw--my-px{
  margin-top: -1px;
  margin-bottom: -1px
}

.tw--mx-px{
  margin-left: -1px;
  margin-right: -1px
}

.tw-mt-0{
  margin-top: 0
}

.tw-mr-0{
  margin-right: 0
}

.tw-mb-0{
  margin-bottom: 0
}

.tw-ml-0{
  margin-left: 0
}

.tw-mt-1{
  margin-top: 0.25rem
}

.tw-mr-1{
  margin-right: 0.25rem
}

.tw-mb-1{
  margin-bottom: 0.25rem
}

.tw-ml-1{
  margin-left: 0.25rem
}

.tw-mt-2{
  margin-top: 0.5rem
}

.tw-mr-2{
  margin-right: 0.5rem
}

.tw-mb-2{
  margin-bottom: 0.5rem
}

.tw-ml-2{
  margin-left: 0.5rem
}

.tw-mt-3{
  margin-top: 0.75rem
}

.tw-mr-3{
  margin-right: 0.75rem
}

.tw-mb-3{
  margin-bottom: 0.75rem
}

.tw-ml-3{
  margin-left: 0.75rem
}

.tw-mt-4{
  margin-top: 1rem
}

.tw-mr-4{
  margin-right: 1rem
}

.tw-mb-4{
  margin-bottom: 1rem
}

.tw-ml-4{
  margin-left: 1rem
}

.tw-mt-5{
  margin-top: 1.25rem
}

.tw-mr-5{
  margin-right: 1.25rem
}

.tw-mb-5{
  margin-bottom: 1.25rem
}

.tw-ml-5{
  margin-left: 1.25rem
}

.tw-mt-6{
  margin-top: 1.5rem
}

.tw-mr-6{
  margin-right: 1.5rem
}

.tw-mb-6{
  margin-bottom: 1.5rem
}

.tw-ml-6{
  margin-left: 1.5rem
}

.tw-mt-8{
  margin-top: 2rem
}

.tw-mr-8{
  margin-right: 2rem
}

.tw-mb-8{
  margin-bottom: 2rem
}

.tw-ml-8{
  margin-left: 2rem
}

.tw-mt-10{
  margin-top: 2.5rem
}

.tw-mr-10{
  margin-right: 2.5rem
}

.tw-mb-10{
  margin-bottom: 2.5rem
}

.tw-ml-10{
  margin-left: 2.5rem
}

.tw-mt-12{
  margin-top: 3rem
}

.tw-mr-12{
  margin-right: 3rem
}

.tw-mb-12{
  margin-bottom: 3rem
}

.tw-ml-12{
  margin-left: 3rem
}

.tw-mt-16{
  margin-top: 4rem
}

.tw-mr-16{
  margin-right: 4rem
}

.tw-mb-16{
  margin-bottom: 4rem
}

.tw-ml-16{
  margin-left: 4rem
}

.tw-mt-20{
  margin-top: 5rem
}

.tw-mr-20{
  margin-right: 5rem
}

.tw-mb-20{
  margin-bottom: 5rem
}

.tw-ml-20{
  margin-left: 5rem
}

.tw-mt-24{
  margin-top: 6rem
}

.tw-mr-24{
  margin-right: 6rem
}

.tw-mb-24{
  margin-bottom: 6rem
}

.tw-ml-24{
  margin-left: 6rem
}

.tw-mt-32{
  margin-top: 8rem
}

.tw-mr-32{
  margin-right: 8rem
}

.tw-mb-32{
  margin-bottom: 8rem
}

.tw-ml-32{
  margin-left: 8rem
}

.tw-mt-40{
  margin-top: 10rem
}

.tw-mr-40{
  margin-right: 10rem
}

.tw-mb-40{
  margin-bottom: 10rem
}

.tw-ml-40{
  margin-left: 10rem
}

.tw-mt-48{
  margin-top: 12rem
}

.tw-mr-48{
  margin-right: 12rem
}

.tw-mb-48{
  margin-bottom: 12rem
}

.tw-ml-48{
  margin-left: 12rem
}

.tw-mt-56{
  margin-top: 14rem
}

.tw-mr-56{
  margin-right: 14rem
}

.tw-mb-56{
  margin-bottom: 14rem
}

.tw-ml-56{
  margin-left: 14rem
}

.tw-mt-64{
  margin-top: 16rem
}

.tw-mr-64{
  margin-right: 16rem
}

.tw-mb-64{
  margin-bottom: 16rem
}

.tw-ml-64{
  margin-left: 16rem
}

.tw-mt-auto{
  margin-top: auto
}

.tw-mr-auto{
  margin-right: auto
}

.tw-mb-auto{
  margin-bottom: auto
}

.tw-ml-auto{
  margin-left: auto
}

.tw-mt-px{
  margin-top: 1px
}

.tw-mr-px{
  margin-right: 1px
}

.tw-mb-px{
  margin-bottom: 1px
}

.tw-ml-px{
  margin-left: 1px
}

.tw--mt-1{
  margin-top: -0.25rem
}

.tw--mr-1{
  margin-right: -0.25rem
}

.tw--mb-1{
  margin-bottom: -0.25rem
}

.tw--ml-1{
  margin-left: -0.25rem
}

.tw--mt-2{
  margin-top: -0.5rem
}

.tw--mr-2{
  margin-right: -0.5rem
}

.tw--mb-2{
  margin-bottom: -0.5rem
}

.tw--ml-2{
  margin-left: -0.5rem
}

.tw--mt-3{
  margin-top: -0.75rem
}

.tw--mr-3{
  margin-right: -0.75rem
}

.tw--mb-3{
  margin-bottom: -0.75rem
}

.tw--ml-3{
  margin-left: -0.75rem
}

.tw--mt-4{
  margin-top: -1rem
}

.tw--mr-4{
  margin-right: -1rem
}

.tw--mb-4{
  margin-bottom: -1rem
}

.tw--ml-4{
  margin-left: -1rem
}

.tw--mt-5{
  margin-top: -1.25rem
}

.tw--mr-5{
  margin-right: -1.25rem
}

.tw--mb-5{
  margin-bottom: -1.25rem
}

.tw--ml-5{
  margin-left: -1.25rem
}

.tw--mt-6{
  margin-top: -1.5rem
}

.tw--mr-6{
  margin-right: -1.5rem
}

.tw--mb-6{
  margin-bottom: -1.5rem
}

.tw--ml-6{
  margin-left: -1.5rem
}

.tw--mt-8{
  margin-top: -2rem
}

.tw--mr-8{
  margin-right: -2rem
}

.tw--mb-8{
  margin-bottom: -2rem
}

.tw--ml-8{
  margin-left: -2rem
}

.tw--mt-10{
  margin-top: -2.5rem
}

.tw--mr-10{
  margin-right: -2.5rem
}

.tw--mb-10{
  margin-bottom: -2.5rem
}

.tw--ml-10{
  margin-left: -2.5rem
}

.tw--mt-12{
  margin-top: -3rem
}

.tw--mr-12{
  margin-right: -3rem
}

.tw--mb-12{
  margin-bottom: -3rem
}

.tw--ml-12{
  margin-left: -3rem
}

.tw--mt-16{
  margin-top: -4rem
}

.tw--mr-16{
  margin-right: -4rem
}

.tw--mb-16{
  margin-bottom: -4rem
}

.tw--ml-16{
  margin-left: -4rem
}

.tw--mt-20{
  margin-top: -5rem
}

.tw--mr-20{
  margin-right: -5rem
}

.tw--mb-20{
  margin-bottom: -5rem
}

.tw--ml-20{
  margin-left: -5rem
}

.tw--mt-24{
  margin-top: -6rem
}

.tw--mr-24{
  margin-right: -6rem
}

.tw--mb-24{
  margin-bottom: -6rem
}

.tw--ml-24{
  margin-left: -6rem
}

.tw--mt-32{
  margin-top: -8rem
}

.tw--mr-32{
  margin-right: -8rem
}

.tw--mb-32{
  margin-bottom: -8rem
}

.tw--ml-32{
  margin-left: -8rem
}

.tw--mt-40{
  margin-top: -10rem
}

.tw--mr-40{
  margin-right: -10rem
}

.tw--mb-40{
  margin-bottom: -10rem
}

.tw--ml-40{
  margin-left: -10rem
}

.tw--mt-48{
  margin-top: -12rem
}

.tw--mr-48{
  margin-right: -12rem
}

.tw--mb-48{
  margin-bottom: -12rem
}

.tw--ml-48{
  margin-left: -12rem
}

.tw--mt-56{
  margin-top: -14rem
}

.tw--mr-56{
  margin-right: -14rem
}

.tw--mb-56{
  margin-bottom: -14rem
}

.tw--ml-56{
  margin-left: -14rem
}

.tw--mt-64{
  margin-top: -16rem
}

.tw--mr-64{
  margin-right: -16rem
}

.tw--mb-64{
  margin-bottom: -16rem
}

.tw--ml-64{
  margin-left: -16rem
}

.tw--mt-px{
  margin-top: -1px
}

.tw--mr-px{
  margin-right: -1px
}

.tw--mb-px{
  margin-bottom: -1px
}

.tw--ml-px{
  margin-left: -1px
}

.tw-max-h-full{
  max-height: 100%
}

.tw-max-h-screen{
  max-height: 100vh
}

.tw-max-w-xs{
  max-width: 20rem
}

.tw-max-w-sm{
  max-width: 24rem
}

.tw-max-w-md{
  max-width: 28rem
}

.tw-max-w-lg{
  max-width: 32rem
}

.tw-max-w-xl{
  max-width: 36rem
}

.tw-max-w-2xl{
  max-width: 42rem
}

.tw-max-w-3xl{
  max-width: 48rem
}

.tw-max-w-4xl{
  max-width: 56rem
}

.tw-max-w-5xl{
  max-width: 64rem
}

.tw-max-w-6xl{
  max-width: 72rem
}

.tw-max-w-full{
  max-width: 100%
}

.tw-min-h-0{
  min-height: 0
}

.tw-min-h-full{
  min-height: 100%
}

.tw-min-h-screen{
  min-height: 100vh
}

.tw-min-w-0{
  min-width: 0
}

.tw-min-w-full{
  min-width: 100%
}

.tw-object-contain{
  object-fit: contain
}

.tw-object-cover{
  object-fit: cover
}

.tw-object-fill{
  object-fit: fill
}

.tw-object-none{
  object-fit: none
}

.tw-object-scale-down{
  object-fit: scale-down
}

.tw-object-bottom{
  object-position: bottom
}

.tw-object-center{
  object-position: center
}

.tw-object-left{
  object-position: left
}

.tw-object-left-bottom{
  object-position: left bottom
}

.tw-object-left-top{
  object-position: left top
}

.tw-object-right{
  object-position: right
}

.tw-object-right-bottom{
  object-position: right bottom
}

.tw-object-right-top{
  object-position: right top
}

.tw-object-top{
  object-position: top
}

.tw-opacity-0{
  opacity: 0
}

.tw-opacity-25{
  opacity: 0.25
}

.tw-opacity-50{
  opacity: 0.5
}

.tw-opacity-75{
  opacity: 0.75
}

.tw-opacity-100{
  opacity: 1
}

.tw-outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px
}

.tw-outline-white{
  outline: 2px dotted white;
  outline-offset: 2px
}

.tw-outline-black{
  outline: 2px dotted black;
  outline-offset: 2px
}

.focus\:tw-outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:tw-outline-white:focus{
  outline: 2px dotted white;
  outline-offset: 2px
}

.focus\:tw-outline-black:focus{
  outline: 2px dotted black;
  outline-offset: 2px
}

.tw-overflow-auto{
  overflow: auto
}

.tw-overflow-hidden{
  overflow: hidden
}

.tw-overflow-visible{
  overflow: visible
}

.tw-overflow-scroll{
  overflow: scroll
}

.tw-overflow-x-auto{
  overflow-x: auto
}

.tw-overflow-y-auto{
  overflow-y: auto
}

.tw-overflow-x-hidden{
  overflow-x: hidden
}

.tw-overflow-y-hidden{
  overflow-y: hidden
}

.tw-overflow-x-visible{
  overflow-x: visible
}

.tw-overflow-y-visible{
  overflow-y: visible
}

.tw-overflow-x-scroll{
  overflow-x: scroll
}

.tw-overflow-y-scroll{
  overflow-y: scroll
}

.tw-scrolling-touch{
  -webkit-overflow-scrolling: touch
}

.tw-scrolling-auto{
  -webkit-overflow-scrolling: auto
}

.tw-overscroll-auto{
  -ms-scroll-chaining: chained;
      overscroll-behavior: auto
}

.tw-overscroll-contain{
  -ms-scroll-chaining: none;
      overscroll-behavior: contain
}

.tw-overscroll-none{
  -ms-scroll-chaining: none;
      overscroll-behavior: none
}

.tw-overscroll-y-auto{
  overscroll-behavior-y: auto
}

.tw-overscroll-y-contain{
  overscroll-behavior-y: contain
}

.tw-overscroll-y-none{
  overscroll-behavior-y: none
}

.tw-overscroll-x-auto{
  overscroll-behavior-x: auto
}

.tw-overscroll-x-contain{
  overscroll-behavior-x: contain
}

.tw-overscroll-x-none{
  overscroll-behavior-x: none
}

.tw-p-0{
  padding: 0
}

.tw-p-1{
  padding: 0.25rem
}

.tw-p-2{
  padding: 0.5rem
}

.tw-p-3{
  padding: 0.75rem
}

.tw-p-4{
  padding: 1rem
}

.tw-p-5{
  padding: 1.25rem
}

.tw-p-6{
  padding: 1.5rem
}

.tw-p-8{
  padding: 2rem
}

.tw-p-10{
  padding: 2.5rem
}

.tw-p-12{
  padding: 3rem
}

.tw-p-16{
  padding: 4rem
}

.tw-p-20{
  padding: 5rem
}

.tw-p-24{
  padding: 6rem
}

.tw-p-32{
  padding: 8rem
}

.tw-p-40{
  padding: 10rem
}

.tw-p-48{
  padding: 12rem
}

.tw-p-56{
  padding: 14rem
}

.tw-p-64{
  padding: 16rem
}

.tw-p-px{
  padding: 1px
}

.tw-py-0{
  padding-top: 0;
  padding-bottom: 0
}

.tw-px-0{
  padding-left: 0;
  padding-right: 0
}

.tw-py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.tw-px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.tw-py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.tw-px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.tw-py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.tw-px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.tw-py-4{
  padding-top: 1rem;
  padding-bottom: 1rem
}

.tw-px-4{
  padding-left: 1rem;
  padding-right: 1rem
}

.tw-py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.tw-px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.tw-py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.tw-px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.tw-py-8{
  padding-top: 2rem;
  padding-bottom: 2rem
}

.tw-px-8{
  padding-left: 2rem;
  padding-right: 2rem
}

.tw-py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.tw-px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.tw-py-12{
  padding-top: 3rem;
  padding-bottom: 3rem
}

.tw-px-12{
  padding-left: 3rem;
  padding-right: 3rem
}

.tw-py-16{
  padding-top: 4rem;
  padding-bottom: 4rem
}

.tw-px-16{
  padding-left: 4rem;
  padding-right: 4rem
}

.tw-py-20{
  padding-top: 5rem;
  padding-bottom: 5rem
}

.tw-px-20{
  padding-left: 5rem;
  padding-right: 5rem
}

.tw-py-24{
  padding-top: 6rem;
  padding-bottom: 6rem
}

.tw-px-24{
  padding-left: 6rem;
  padding-right: 6rem
}

.tw-py-32{
  padding-top: 8rem;
  padding-bottom: 8rem
}

.tw-px-32{
  padding-left: 8rem;
  padding-right: 8rem
}

.tw-py-40{
  padding-top: 10rem;
  padding-bottom: 10rem
}

.tw-px-40{
  padding-left: 10rem;
  padding-right: 10rem
}

.tw-py-48{
  padding-top: 12rem;
  padding-bottom: 12rem
}

.tw-px-48{
  padding-left: 12rem;
  padding-right: 12rem
}

.tw-py-56{
  padding-top: 14rem;
  padding-bottom: 14rem
}

.tw-px-56{
  padding-left: 14rem;
  padding-right: 14rem
}

.tw-py-64{
  padding-top: 16rem;
  padding-bottom: 16rem
}

.tw-px-64{
  padding-left: 16rem;
  padding-right: 16rem
}

.tw-py-px{
  padding-top: 1px;
  padding-bottom: 1px
}

.tw-px-px{
  padding-left: 1px;
  padding-right: 1px
}

.tw-pt-0{
  padding-top: 0
}

.tw-pr-0{
  padding-right: 0
}

.tw-pb-0{
  padding-bottom: 0
}

.tw-pl-0{
  padding-left: 0
}

.tw-pt-1{
  padding-top: 0.25rem
}

.tw-pr-1{
  padding-right: 0.25rem
}

.tw-pb-1{
  padding-bottom: 0.25rem
}

.tw-pl-1{
  padding-left: 0.25rem
}

.tw-pt-2{
  padding-top: 0.5rem
}

.tw-pr-2{
  padding-right: 0.5rem
}

.tw-pb-2{
  padding-bottom: 0.5rem
}

.tw-pl-2{
  padding-left: 0.5rem
}

.tw-pt-3{
  padding-top: 0.75rem
}

.tw-pr-3{
  padding-right: 0.75rem
}

.tw-pb-3{
  padding-bottom: 0.75rem
}

.tw-pl-3{
  padding-left: 0.75rem
}

.tw-pt-4{
  padding-top: 1rem
}

.tw-pr-4{
  padding-right: 1rem
}

.tw-pb-4{
  padding-bottom: 1rem
}

.tw-pl-4{
  padding-left: 1rem
}

.tw-pt-5{
  padding-top: 1.25rem
}

.tw-pr-5{
  padding-right: 1.25rem
}

.tw-pb-5{
  padding-bottom: 1.25rem
}

.tw-pl-5{
  padding-left: 1.25rem
}

.tw-pt-6{
  padding-top: 1.5rem
}

.tw-pr-6{
  padding-right: 1.5rem
}

.tw-pb-6{
  padding-bottom: 1.5rem
}

.tw-pl-6{
  padding-left: 1.5rem
}

.tw-pt-8{
  padding-top: 2rem
}

.tw-pr-8{
  padding-right: 2rem
}

.tw-pb-8{
  padding-bottom: 2rem
}

.tw-pl-8{
  padding-left: 2rem
}

.tw-pt-10{
  padding-top: 2.5rem
}

.tw-pr-10{
  padding-right: 2.5rem
}

.tw-pb-10{
  padding-bottom: 2.5rem
}

.tw-pl-10{
  padding-left: 2.5rem
}

.tw-pt-12{
  padding-top: 3rem
}

.tw-pr-12{
  padding-right: 3rem
}

.tw-pb-12{
  padding-bottom: 3rem
}

.tw-pl-12{
  padding-left: 3rem
}

.tw-pt-16{
  padding-top: 4rem
}

.tw-pr-16{
  padding-right: 4rem
}

.tw-pb-16{
  padding-bottom: 4rem
}

.tw-pl-16{
  padding-left: 4rem
}

.tw-pt-20{
  padding-top: 5rem
}

.tw-pr-20{
  padding-right: 5rem
}

.tw-pb-20{
  padding-bottom: 5rem
}

.tw-pl-20{
  padding-left: 5rem
}

.tw-pt-24{
  padding-top: 6rem
}

.tw-pr-24{
  padding-right: 6rem
}

.tw-pb-24{
  padding-bottom: 6rem
}

.tw-pl-24{
  padding-left: 6rem
}

.tw-pt-32{
  padding-top: 8rem
}

.tw-pr-32{
  padding-right: 8rem
}

.tw-pb-32{
  padding-bottom: 8rem
}

.tw-pl-32{
  padding-left: 8rem
}

.tw-pt-40{
  padding-top: 10rem
}

.tw-pr-40{
  padding-right: 10rem
}

.tw-pb-40{
  padding-bottom: 10rem
}

.tw-pl-40{
  padding-left: 10rem
}

.tw-pt-48{
  padding-top: 12rem
}

.tw-pr-48{
  padding-right: 12rem
}

.tw-pb-48{
  padding-bottom: 12rem
}

.tw-pl-48{
  padding-left: 12rem
}

.tw-pt-56{
  padding-top: 14rem
}

.tw-pr-56{
  padding-right: 14rem
}

.tw-pb-56{
  padding-bottom: 14rem
}

.tw-pl-56{
  padding-left: 14rem
}

.tw-pt-64{
  padding-top: 16rem
}

.tw-pr-64{
  padding-right: 16rem
}

.tw-pb-64{
  padding-bottom: 16rem
}

.tw-pl-64{
  padding-left: 16rem
}

.tw-pt-px{
  padding-top: 1px
}

.tw-pr-px{
  padding-right: 1px
}

.tw-pb-px{
  padding-bottom: 1px
}

.tw-pl-px{
  padding-left: 1px
}

.tw-placeholder-transparent::-webkit-input-placeholder{
  color: transparent
}

.tw-placeholder-transparent::-moz-placeholder{
  color: transparent
}

.tw-placeholder-transparent:-ms-input-placeholder{
  color: transparent
}

.tw-placeholder-transparent::-ms-input-placeholder{
  color: transparent
}

.tw-placeholder-transparent::placeholder{
  color: transparent
}

.tw-placeholder-tlg-green-dark::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green-dark::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green-dark:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green-dark::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green-dark::placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.tw-placeholder-tlg-green::placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.tw-placeholder-tlg-orange-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.tw-placeholder-tlg-orange-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.tw-placeholder-tlg-orange-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.tw-placeholder-tlg-orange-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.tw-placeholder-tlg-orange-500::placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.tw-placeholder-black::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.tw-placeholder-black::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.tw-placeholder-black:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.tw-placeholder-black::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.tw-placeholder-black::placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.tw-placeholder-white::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.tw-placeholder-white::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.tw-placeholder-white:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.tw-placeholder-white::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.tw-placeholder-white::placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.tw-placeholder-gray-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.tw-placeholder-gray-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.tw-placeholder-gray-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.tw-placeholder-gray-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.tw-placeholder-gray-100::placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.tw-placeholder-gray-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.tw-placeholder-gray-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.tw-placeholder-gray-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.tw-placeholder-gray-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.tw-placeholder-gray-200::placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.tw-placeholder-gray-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.tw-placeholder-gray-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.tw-placeholder-gray-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.tw-placeholder-gray-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.tw-placeholder-gray-300::placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.tw-placeholder-gray-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.tw-placeholder-gray-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.tw-placeholder-gray-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.tw-placeholder-gray-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.tw-placeholder-gray-400::placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.tw-placeholder-gray-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.tw-placeholder-gray-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.tw-placeholder-gray-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.tw-placeholder-gray-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.tw-placeholder-gray-500::placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.tw-placeholder-gray-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.tw-placeholder-gray-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.tw-placeholder-gray-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.tw-placeholder-gray-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.tw-placeholder-gray-600::placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.tw-placeholder-gray-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.tw-placeholder-gray-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.tw-placeholder-gray-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.tw-placeholder-gray-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.tw-placeholder-gray-700::placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.tw-placeholder-gray-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.tw-placeholder-gray-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.tw-placeholder-gray-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.tw-placeholder-gray-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.tw-placeholder-gray-800::placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.tw-placeholder-gray-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.tw-placeholder-gray-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.tw-placeholder-gray-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.tw-placeholder-gray-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.tw-placeholder-gray-900::placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.tw-placeholder-red-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.tw-placeholder-red-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.tw-placeholder-red-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.tw-placeholder-red-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.tw-placeholder-red-100::placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.tw-placeholder-red-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.tw-placeholder-red-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.tw-placeholder-red-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.tw-placeholder-red-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.tw-placeholder-red-200::placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.tw-placeholder-red-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.tw-placeholder-red-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.tw-placeholder-red-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.tw-placeholder-red-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.tw-placeholder-red-300::placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.tw-placeholder-red-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.tw-placeholder-red-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.tw-placeholder-red-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.tw-placeholder-red-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.tw-placeholder-red-400::placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.tw-placeholder-red-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.tw-placeholder-red-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.tw-placeholder-red-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.tw-placeholder-red-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.tw-placeholder-red-500::placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.tw-placeholder-red-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.tw-placeholder-red-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.tw-placeholder-red-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.tw-placeholder-red-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.tw-placeholder-red-600::placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.tw-placeholder-red-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.tw-placeholder-red-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.tw-placeholder-red-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.tw-placeholder-red-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.tw-placeholder-red-700::placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.tw-placeholder-red-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.tw-placeholder-red-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.tw-placeholder-red-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.tw-placeholder-red-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.tw-placeholder-red-800::placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.tw-placeholder-red-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.tw-placeholder-red-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.tw-placeholder-red-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.tw-placeholder-red-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.tw-placeholder-red-900::placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.tw-placeholder-orange-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.tw-placeholder-orange-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.tw-placeholder-orange-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.tw-placeholder-orange-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.tw-placeholder-orange-100::placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.tw-placeholder-orange-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.tw-placeholder-orange-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.tw-placeholder-orange-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.tw-placeholder-orange-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.tw-placeholder-orange-200::placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.tw-placeholder-orange-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.tw-placeholder-orange-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.tw-placeholder-orange-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.tw-placeholder-orange-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.tw-placeholder-orange-300::placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.tw-placeholder-orange-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.tw-placeholder-orange-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.tw-placeholder-orange-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.tw-placeholder-orange-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.tw-placeholder-orange-400::placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.tw-placeholder-orange-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.tw-placeholder-orange-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.tw-placeholder-orange-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.tw-placeholder-orange-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.tw-placeholder-orange-500::placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.tw-placeholder-orange-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.tw-placeholder-orange-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.tw-placeholder-orange-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.tw-placeholder-orange-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.tw-placeholder-orange-600::placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.tw-placeholder-orange-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-700::placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-800::placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.tw-placeholder-orange-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.tw-placeholder-orange-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.tw-placeholder-orange-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.tw-placeholder-orange-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.tw-placeholder-orange-900::placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.tw-placeholder-yellow-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.tw-placeholder-yellow-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.tw-placeholder-yellow-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.tw-placeholder-yellow-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.tw-placeholder-yellow-100::placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.tw-placeholder-yellow-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.tw-placeholder-yellow-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.tw-placeholder-yellow-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.tw-placeholder-yellow-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.tw-placeholder-yellow-200::placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.tw-placeholder-yellow-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.tw-placeholder-yellow-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.tw-placeholder-yellow-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.tw-placeholder-yellow-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.tw-placeholder-yellow-300::placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.tw-placeholder-yellow-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.tw-placeholder-yellow-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.tw-placeholder-yellow-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.tw-placeholder-yellow-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.tw-placeholder-yellow-400::placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.tw-placeholder-yellow-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.tw-placeholder-yellow-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.tw-placeholder-yellow-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.tw-placeholder-yellow-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.tw-placeholder-yellow-500::placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.tw-placeholder-yellow-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.tw-placeholder-yellow-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.tw-placeholder-yellow-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.tw-placeholder-yellow-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.tw-placeholder-yellow-600::placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.tw-placeholder-yellow-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.tw-placeholder-yellow-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.tw-placeholder-yellow-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.tw-placeholder-yellow-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.tw-placeholder-yellow-700::placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.tw-placeholder-yellow-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.tw-placeholder-yellow-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.tw-placeholder-yellow-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.tw-placeholder-yellow-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.tw-placeholder-yellow-800::placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.tw-placeholder-yellow-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.tw-placeholder-yellow-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.tw-placeholder-yellow-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.tw-placeholder-yellow-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.tw-placeholder-yellow-900::placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.tw-placeholder-green-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.tw-placeholder-green-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.tw-placeholder-green-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.tw-placeholder-green-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.tw-placeholder-green-100::placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.tw-placeholder-green-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.tw-placeholder-green-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.tw-placeholder-green-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.tw-placeholder-green-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.tw-placeholder-green-200::placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.tw-placeholder-green-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.tw-placeholder-green-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.tw-placeholder-green-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.tw-placeholder-green-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.tw-placeholder-green-300::placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.tw-placeholder-green-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.tw-placeholder-green-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.tw-placeholder-green-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.tw-placeholder-green-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.tw-placeholder-green-400::placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.tw-placeholder-green-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.tw-placeholder-green-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.tw-placeholder-green-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.tw-placeholder-green-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.tw-placeholder-green-500::placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.tw-placeholder-green-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.tw-placeholder-green-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.tw-placeholder-green-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.tw-placeholder-green-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.tw-placeholder-green-600::placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.tw-placeholder-green-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.tw-placeholder-green-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.tw-placeholder-green-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.tw-placeholder-green-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.tw-placeholder-green-700::placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.tw-placeholder-green-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.tw-placeholder-green-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.tw-placeholder-green-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.tw-placeholder-green-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.tw-placeholder-green-800::placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.tw-placeholder-green-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.tw-placeholder-green-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.tw-placeholder-green-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.tw-placeholder-green-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.tw-placeholder-green-900::placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.tw-placeholder-teal-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.tw-placeholder-teal-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.tw-placeholder-teal-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.tw-placeholder-teal-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.tw-placeholder-teal-100::placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.tw-placeholder-teal-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.tw-placeholder-teal-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.tw-placeholder-teal-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.tw-placeholder-teal-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.tw-placeholder-teal-200::placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.tw-placeholder-teal-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.tw-placeholder-teal-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.tw-placeholder-teal-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.tw-placeholder-teal-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.tw-placeholder-teal-300::placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.tw-placeholder-teal-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.tw-placeholder-teal-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.tw-placeholder-teal-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.tw-placeholder-teal-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.tw-placeholder-teal-400::placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.tw-placeholder-teal-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.tw-placeholder-teal-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.tw-placeholder-teal-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.tw-placeholder-teal-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.tw-placeholder-teal-500::placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.tw-placeholder-teal-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.tw-placeholder-teal-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.tw-placeholder-teal-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.tw-placeholder-teal-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.tw-placeholder-teal-600::placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.tw-placeholder-teal-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.tw-placeholder-teal-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.tw-placeholder-teal-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.tw-placeholder-teal-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.tw-placeholder-teal-700::placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.tw-placeholder-teal-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.tw-placeholder-teal-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.tw-placeholder-teal-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.tw-placeholder-teal-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.tw-placeholder-teal-800::placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.tw-placeholder-teal-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.tw-placeholder-teal-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.tw-placeholder-teal-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.tw-placeholder-teal-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.tw-placeholder-teal-900::placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.tw-placeholder-blue-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.tw-placeholder-blue-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.tw-placeholder-blue-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.tw-placeholder-blue-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.tw-placeholder-blue-100::placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.tw-placeholder-blue-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.tw-placeholder-blue-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.tw-placeholder-blue-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.tw-placeholder-blue-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.tw-placeholder-blue-200::placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.tw-placeholder-blue-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.tw-placeholder-blue-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.tw-placeholder-blue-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.tw-placeholder-blue-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.tw-placeholder-blue-300::placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.tw-placeholder-blue-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.tw-placeholder-blue-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.tw-placeholder-blue-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.tw-placeholder-blue-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.tw-placeholder-blue-400::placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.tw-placeholder-blue-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.tw-placeholder-blue-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.tw-placeholder-blue-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.tw-placeholder-blue-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.tw-placeholder-blue-500::placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.tw-placeholder-blue-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.tw-placeholder-blue-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.tw-placeholder-blue-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.tw-placeholder-blue-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.tw-placeholder-blue-600::placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.tw-placeholder-blue-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.tw-placeholder-blue-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.tw-placeholder-blue-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.tw-placeholder-blue-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.tw-placeholder-blue-700::placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.tw-placeholder-blue-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.tw-placeholder-blue-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.tw-placeholder-blue-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.tw-placeholder-blue-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.tw-placeholder-blue-800::placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.tw-placeholder-blue-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.tw-placeholder-blue-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.tw-placeholder-blue-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.tw-placeholder-blue-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.tw-placeholder-blue-900::placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.tw-placeholder-indigo-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.tw-placeholder-indigo-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.tw-placeholder-indigo-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.tw-placeholder-indigo-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.tw-placeholder-indigo-100::placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.tw-placeholder-indigo-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.tw-placeholder-indigo-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.tw-placeholder-indigo-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.tw-placeholder-indigo-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.tw-placeholder-indigo-200::placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.tw-placeholder-indigo-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.tw-placeholder-indigo-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.tw-placeholder-indigo-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.tw-placeholder-indigo-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.tw-placeholder-indigo-300::placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.tw-placeholder-indigo-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.tw-placeholder-indigo-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.tw-placeholder-indigo-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.tw-placeholder-indigo-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.tw-placeholder-indigo-400::placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.tw-placeholder-indigo-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.tw-placeholder-indigo-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.tw-placeholder-indigo-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.tw-placeholder-indigo-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.tw-placeholder-indigo-500::placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.tw-placeholder-indigo-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.tw-placeholder-indigo-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.tw-placeholder-indigo-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.tw-placeholder-indigo-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.tw-placeholder-indigo-600::placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.tw-placeholder-indigo-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.tw-placeholder-indigo-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.tw-placeholder-indigo-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.tw-placeholder-indigo-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.tw-placeholder-indigo-700::placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.tw-placeholder-indigo-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.tw-placeholder-indigo-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.tw-placeholder-indigo-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.tw-placeholder-indigo-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.tw-placeholder-indigo-800::placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.tw-placeholder-indigo-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.tw-placeholder-indigo-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.tw-placeholder-indigo-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.tw-placeholder-indigo-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.tw-placeholder-indigo-900::placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.tw-placeholder-purple-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.tw-placeholder-purple-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.tw-placeholder-purple-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.tw-placeholder-purple-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.tw-placeholder-purple-100::placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.tw-placeholder-purple-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.tw-placeholder-purple-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.tw-placeholder-purple-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.tw-placeholder-purple-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.tw-placeholder-purple-200::placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.tw-placeholder-purple-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.tw-placeholder-purple-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.tw-placeholder-purple-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.tw-placeholder-purple-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.tw-placeholder-purple-300::placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.tw-placeholder-purple-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.tw-placeholder-purple-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.tw-placeholder-purple-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.tw-placeholder-purple-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.tw-placeholder-purple-400::placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.tw-placeholder-purple-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.tw-placeholder-purple-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.tw-placeholder-purple-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.tw-placeholder-purple-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.tw-placeholder-purple-500::placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.tw-placeholder-purple-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.tw-placeholder-purple-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.tw-placeholder-purple-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.tw-placeholder-purple-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.tw-placeholder-purple-600::placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.tw-placeholder-purple-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.tw-placeholder-purple-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.tw-placeholder-purple-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.tw-placeholder-purple-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.tw-placeholder-purple-700::placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.tw-placeholder-purple-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.tw-placeholder-purple-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.tw-placeholder-purple-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.tw-placeholder-purple-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.tw-placeholder-purple-800::placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.tw-placeholder-purple-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.tw-placeholder-purple-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.tw-placeholder-purple-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.tw-placeholder-purple-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.tw-placeholder-purple-900::placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.tw-placeholder-pink-100::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.tw-placeholder-pink-100::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.tw-placeholder-pink-100:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.tw-placeholder-pink-100::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.tw-placeholder-pink-100::placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.tw-placeholder-pink-200::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.tw-placeholder-pink-200::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.tw-placeholder-pink-200:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.tw-placeholder-pink-200::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.tw-placeholder-pink-200::placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.tw-placeholder-pink-300::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.tw-placeholder-pink-300::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.tw-placeholder-pink-300:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.tw-placeholder-pink-300::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.tw-placeholder-pink-300::placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.tw-placeholder-pink-400::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.tw-placeholder-pink-400::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.tw-placeholder-pink-400:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.tw-placeholder-pink-400::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.tw-placeholder-pink-400::placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.tw-placeholder-pink-500::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.tw-placeholder-pink-500::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.tw-placeholder-pink-500:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.tw-placeholder-pink-500::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.tw-placeholder-pink-500::placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.tw-placeholder-pink-600::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.tw-placeholder-pink-600::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.tw-placeholder-pink-600:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.tw-placeholder-pink-600::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.tw-placeholder-pink-600::placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.tw-placeholder-pink-700::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.tw-placeholder-pink-700::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.tw-placeholder-pink-700:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.tw-placeholder-pink-700::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.tw-placeholder-pink-700::placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.tw-placeholder-pink-800::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.tw-placeholder-pink-800::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.tw-placeholder-pink-800:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.tw-placeholder-pink-800::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.tw-placeholder-pink-800::placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.tw-placeholder-pink-900::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.tw-placeholder-pink-900::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.tw-placeholder-pink-900:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.tw-placeholder-pink-900::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.tw-placeholder-pink-900::placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
  color: transparent
}

.focus\:tw-placeholder-transparent:focus::-moz-placeholder{
  color: transparent
}

.focus\:tw-placeholder-transparent:focus:-ms-input-placeholder{
  color: transparent
}

.focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
  color: transparent
}

.focus\:tw-placeholder-transparent:focus::placeholder{
  color: transparent
}

.focus\:tw-placeholder-tlg-green-dark:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green-dark:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green-dark:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green-dark:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green-dark:focus::placeholder{
  --placeholder-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-green:focus::placeholder{
  --placeholder-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-orange-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-orange-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-orange-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-orange-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.focus\:tw-placeholder-tlg-orange-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--placeholder-opacity))
}

.focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:tw-placeholder-black:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:tw-placeholder-black:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:tw-placeholder-black:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:tw-placeholder-black:focus::placeholder{
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity))
}

.focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-white:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-white:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-white:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-white:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-gray-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:tw-placeholder-red-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:tw-placeholder-orange-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:tw-placeholder-yellow-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:tw-placeholder-green-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:tw-placeholder-teal-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-blue-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:tw-placeholder-indigo-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:tw-placeholder-purple-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-100:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-100:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-200:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-200:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-200:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-200:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-200:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-300:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-300:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-300:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-300:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-300:focus::placeholder{
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-400:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-400:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-400:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-400:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-400:focus::placeholder{
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-500:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-500:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-500:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-500:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-500:focus::placeholder{
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-600:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-600:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-600:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-600:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-600:focus::placeholder{
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-700:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-700:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-700:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-700:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-700:focus::placeholder{
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-800:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-800:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-800:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-800:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-800:focus::placeholder{
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-900:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-900:focus::-moz-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-900:focus:-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-900:focus::-ms-input-placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.focus\:tw-placeholder-pink-900:focus::placeholder{
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity))
}

.tw-placeholder-opacity-0::-webkit-input-placeholder{
  --placeholder-opacity: 0
}

.tw-placeholder-opacity-0::-moz-placeholder{
  --placeholder-opacity: 0
}

.tw-placeholder-opacity-0:-ms-input-placeholder{
  --placeholder-opacity: 0
}

.tw-placeholder-opacity-0::-ms-input-placeholder{
  --placeholder-opacity: 0
}

.tw-placeholder-opacity-0::placeholder{
  --placeholder-opacity: 0
}

.tw-placeholder-opacity-25::-webkit-input-placeholder{
  --placeholder-opacity: 0.25
}

.tw-placeholder-opacity-25::-moz-placeholder{
  --placeholder-opacity: 0.25
}

.tw-placeholder-opacity-25:-ms-input-placeholder{
  --placeholder-opacity: 0.25
}

.tw-placeholder-opacity-25::-ms-input-placeholder{
  --placeholder-opacity: 0.25
}

.tw-placeholder-opacity-25::placeholder{
  --placeholder-opacity: 0.25
}

.tw-placeholder-opacity-50::-webkit-input-placeholder{
  --placeholder-opacity: 0.5
}

.tw-placeholder-opacity-50::-moz-placeholder{
  --placeholder-opacity: 0.5
}

.tw-placeholder-opacity-50:-ms-input-placeholder{
  --placeholder-opacity: 0.5
}

.tw-placeholder-opacity-50::-ms-input-placeholder{
  --placeholder-opacity: 0.5
}

.tw-placeholder-opacity-50::placeholder{
  --placeholder-opacity: 0.5
}

.tw-placeholder-opacity-75::-webkit-input-placeholder{
  --placeholder-opacity: 0.75
}

.tw-placeholder-opacity-75::-moz-placeholder{
  --placeholder-opacity: 0.75
}

.tw-placeholder-opacity-75:-ms-input-placeholder{
  --placeholder-opacity: 0.75
}

.tw-placeholder-opacity-75::-ms-input-placeholder{
  --placeholder-opacity: 0.75
}

.tw-placeholder-opacity-75::placeholder{
  --placeholder-opacity: 0.75
}

.tw-placeholder-opacity-100::-webkit-input-placeholder{
  --placeholder-opacity: 1
}

.tw-placeholder-opacity-100::-moz-placeholder{
  --placeholder-opacity: 1
}

.tw-placeholder-opacity-100:-ms-input-placeholder{
  --placeholder-opacity: 1
}

.tw-placeholder-opacity-100::-ms-input-placeholder{
  --placeholder-opacity: 1
}

.tw-placeholder-opacity-100::placeholder{
  --placeholder-opacity: 1
}

.focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
  --placeholder-opacity: 0
}

.focus\:tw-placeholder-opacity-0:focus::-moz-placeholder{
  --placeholder-opacity: 0
}

.focus\:tw-placeholder-opacity-0:focus:-ms-input-placeholder{
  --placeholder-opacity: 0
}

.focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
  --placeholder-opacity: 0
}

.focus\:tw-placeholder-opacity-0:focus::placeholder{
  --placeholder-opacity: 0
}

.focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
  --placeholder-opacity: 0.25
}

.focus\:tw-placeholder-opacity-25:focus::-moz-placeholder{
  --placeholder-opacity: 0.25
}

.focus\:tw-placeholder-opacity-25:focus:-ms-input-placeholder{
  --placeholder-opacity: 0.25
}

.focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
  --placeholder-opacity: 0.25
}

.focus\:tw-placeholder-opacity-25:focus::placeholder{
  --placeholder-opacity: 0.25
}

.focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
  --placeholder-opacity: 0.5
}

.focus\:tw-placeholder-opacity-50:focus::-moz-placeholder{
  --placeholder-opacity: 0.5
}

.focus\:tw-placeholder-opacity-50:focus:-ms-input-placeholder{
  --placeholder-opacity: 0.5
}

.focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
  --placeholder-opacity: 0.5
}

.focus\:tw-placeholder-opacity-50:focus::placeholder{
  --placeholder-opacity: 0.5
}

.focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
  --placeholder-opacity: 0.75
}

.focus\:tw-placeholder-opacity-75:focus::-moz-placeholder{
  --placeholder-opacity: 0.75
}

.focus\:tw-placeholder-opacity-75:focus:-ms-input-placeholder{
  --placeholder-opacity: 0.75
}

.focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
  --placeholder-opacity: 0.75
}

.focus\:tw-placeholder-opacity-75:focus::placeholder{
  --placeholder-opacity: 0.75
}

.focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
  --placeholder-opacity: 1
}

.focus\:tw-placeholder-opacity-100:focus::-moz-placeholder{
  --placeholder-opacity: 1
}

.focus\:tw-placeholder-opacity-100:focus:-ms-input-placeholder{
  --placeholder-opacity: 1
}

.focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
  --placeholder-opacity: 1
}

.focus\:tw-placeholder-opacity-100:focus::placeholder{
  --placeholder-opacity: 1
}

.tw-pointer-events-none{
  pointer-events: none
}

.tw-pointer-events-auto{
  pointer-events: auto
}

.tw-static{
  position: static
}

.tw-fixed{
  position: fixed
}

.tw-absolute{
  position: absolute
}

.tw-relative{
  position: relative
}

.tw-sticky{
  position: -webkit-sticky;
  position: sticky
}

.tw-inset-0{
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.tw-inset-auto{
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

.tw-inset-y-0{
  top: 0;
  bottom: 0
}

.tw-inset-x-0{
  right: 0;
  left: 0
}

.tw-inset-y-auto{
  top: auto;
  bottom: auto
}

.tw-inset-x-auto{
  right: auto;
  left: auto
}

.tw-top-0{
  top: 0
}

.tw-right-0{
  right: 0
}

.tw-bottom-0{
  bottom: 0
}

.tw-left-0{
  left: 0
}

.tw-top-auto{
  top: auto
}

.tw-right-auto{
  right: auto
}

.tw-bottom-auto{
  bottom: auto
}

.tw-left-auto{
  left: auto
}

.tw-resize-none{
  resize: none
}

.tw-resize-y{
  resize: vertical
}

.tw-resize-x{
  resize: horizontal
}

.tw-resize{
  resize: both
}

.tw-shadow{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.tw-shadow-md{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.tw-shadow-lg{
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.tw-shadow-xl{
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.tw-shadow-2xl{
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.tw-shadow-inner{
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.tw-shadow-outline{
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.tw-shadow-none{
  box-shadow: none
}

.hover\:tw-shadow:hover{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.hover\:tw-shadow-md:hover{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.hover\:tw-shadow-lg:hover{
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.hover\:tw-shadow-xl:hover{
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.hover\:tw-shadow-2xl:hover{
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.hover\:tw-shadow-inner:hover{
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.hover\:tw-shadow-outline:hover{
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.hover\:tw-shadow-none:hover{
  box-shadow: none
}

.focus\:tw-shadow:focus{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.focus\:tw-shadow-md:focus{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.focus\:tw-shadow-lg:focus{
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.focus\:tw-shadow-xl:focus{
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.focus\:tw-shadow-2xl:focus{
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.focus\:tw-shadow-inner:focus{
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.focus\:tw-shadow-outline:focus{
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.focus\:tw-shadow-none:focus{
  box-shadow: none
}

.tw-fill-current{
  fill: currentColor
}

.tw-stroke-current{
  stroke: currentColor
}

.tw-stroke-0{
  stroke-width: 0
}

.tw-stroke-1{
  stroke-width: 1
}

.tw-stroke-2{
  stroke-width: 2
}

.tw-table-auto{
  table-layout: auto
}

.tw-table-fixed{
  table-layout: fixed
}

.tw-text-left{
  text-align: left
}

.tw-text-center{
  text-align: center
}

.tw-text-right{
  text-align: right
}

.tw-text-justify{
  text-align: justify
}

.tw-text-transparent{
  color: transparent
}

.tw-text-tlg-green-dark{
  --text-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--text-opacity))
}

.tw-text-tlg-green{
  --text-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--text-opacity))
}

.tw-text-tlg-orange-500{
  --text-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--text-opacity))
}

.tw-text-black{
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.tw-text-white{
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.tw-text-gray-100{
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity))
}

.tw-text-gray-200{
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity))
}

.tw-text-gray-300{
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity))
}

.tw-text-gray-400{
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity))
}

.tw-text-gray-500{
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.tw-text-gray-600{
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.tw-text-gray-700{
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.tw-text-gray-800{
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.tw-text-gray-900{
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity))
}

.tw-text-red-100{
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity))
}

.tw-text-red-200{
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity))
}

.tw-text-red-300{
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity))
}

.tw-text-red-400{
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity))
}

.tw-text-red-500{
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.tw-text-red-600{
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.tw-text-red-700{
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.tw-text-red-800{
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity))
}

.tw-text-red-900{
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity))
}

.tw-text-orange-100{
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity))
}

.tw-text-orange-200{
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity))
}

.tw-text-orange-300{
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity))
}

.tw-text-orange-400{
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity))
}

.tw-text-orange-500{
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.tw-text-orange-600{
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity))
}

.tw-text-orange-700{
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.tw-text-orange-800{
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity))
}

.tw-text-orange-900{
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity))
}

.tw-text-yellow-100{
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity))
}

.tw-text-yellow-200{
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity))
}

.tw-text-yellow-300{
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity))
}

.tw-text-yellow-400{
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity))
}

.tw-text-yellow-500{
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity))
}

.tw-text-yellow-600{
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity))
}

.tw-text-yellow-700{
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity))
}

.tw-text-yellow-800{
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity))
}

.tw-text-yellow-900{
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity))
}

.tw-text-green-100{
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity))
}

.tw-text-green-200{
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity))
}

.tw-text-green-300{
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity))
}

.tw-text-green-400{
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity))
}

.tw-text-green-500{
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.tw-text-green-600{
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.tw-text-green-700{
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.tw-text-green-800{
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity))
}

.tw-text-green-900{
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity))
}

.tw-text-teal-100{
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity))
}

.tw-text-teal-200{
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity))
}

.tw-text-teal-300{
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity))
}

.tw-text-teal-400{
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity))
}

.tw-text-teal-500{
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity))
}

.tw-text-teal-600{
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity))
}

.tw-text-teal-700{
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity))
}

.tw-text-teal-800{
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity))
}

.tw-text-teal-900{
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity))
}

.tw-text-blue-100{
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity))
}

.tw-text-blue-200{
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity))
}

.tw-text-blue-300{
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity))
}

.tw-text-blue-400{
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity))
}

.tw-text-blue-500{
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.tw-text-blue-600{
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.tw-text-blue-700{
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.tw-text-blue-800{
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity))
}

.tw-text-blue-900{
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity))
}

.tw-text-indigo-100{
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity))
}

.tw-text-indigo-200{
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity))
}

.tw-text-indigo-300{
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity))
}

.tw-text-indigo-400{
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity))
}

.tw-text-indigo-500{
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity))
}

.tw-text-indigo-600{
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity))
}

.tw-text-indigo-700{
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity))
}

.tw-text-indigo-800{
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity))
}

.tw-text-indigo-900{
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity))
}

.tw-text-purple-100{
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity))
}

.tw-text-purple-200{
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity))
}

.tw-text-purple-300{
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity))
}

.tw-text-purple-400{
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity))
}

.tw-text-purple-500{
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity))
}

.tw-text-purple-600{
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity))
}

.tw-text-purple-700{
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity))
}

.tw-text-purple-800{
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity))
}

.tw-text-purple-900{
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity))
}

.tw-text-pink-100{
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity))
}

.tw-text-pink-200{
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity))
}

.tw-text-pink-300{
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity))
}

.tw-text-pink-400{
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity))
}

.tw-text-pink-500{
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity))
}

.tw-text-pink-600{
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity))
}

.tw-text-pink-700{
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity))
}

.tw-text-pink-800{
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity))
}

.tw-text-pink-900{
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity))
}

.hover\:tw-text-transparent:hover{
  color: transparent
}

.hover\:tw-text-tlg-green-dark:hover{
  --text-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--text-opacity))
}

.hover\:tw-text-tlg-green:hover{
  --text-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--text-opacity))
}

.hover\:tw-text-tlg-orange-500:hover{
  --text-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--text-opacity))
}

.hover\:tw-text-black:hover{
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.hover\:tw-text-white:hover{
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.hover\:tw-text-gray-100:hover{
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity))
}

.hover\:tw-text-gray-200:hover{
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity))
}

.hover\:tw-text-gray-300:hover{
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity))
}

.hover\:tw-text-gray-400:hover{
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity))
}

.hover\:tw-text-gray-500:hover{
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.hover\:tw-text-gray-600:hover{
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.hover\:tw-text-gray-700:hover{
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.hover\:tw-text-gray-800:hover{
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.hover\:tw-text-gray-900:hover{
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity))
}

.hover\:tw-text-red-100:hover{
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity))
}

.hover\:tw-text-red-200:hover{
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity))
}

.hover\:tw-text-red-300:hover{
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity))
}

.hover\:tw-text-red-400:hover{
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity))
}

.hover\:tw-text-red-500:hover{
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.hover\:tw-text-red-600:hover{
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.hover\:tw-text-red-700:hover{
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.hover\:tw-text-red-800:hover{
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity))
}

.hover\:tw-text-red-900:hover{
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity))
}

.hover\:tw-text-orange-100:hover{
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity))
}

.hover\:tw-text-orange-200:hover{
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity))
}

.hover\:tw-text-orange-300:hover{
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity))
}

.hover\:tw-text-orange-400:hover{
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity))
}

.hover\:tw-text-orange-500:hover{
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.hover\:tw-text-orange-600:hover{
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity))
}

.hover\:tw-text-orange-700:hover{
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.hover\:tw-text-orange-800:hover{
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity))
}

.hover\:tw-text-orange-900:hover{
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity))
}

.hover\:tw-text-yellow-100:hover{
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity))
}

.hover\:tw-text-yellow-200:hover{
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity))
}

.hover\:tw-text-yellow-300:hover{
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity))
}

.hover\:tw-text-yellow-400:hover{
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity))
}

.hover\:tw-text-yellow-500:hover{
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity))
}

.hover\:tw-text-yellow-600:hover{
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity))
}

.hover\:tw-text-yellow-700:hover{
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity))
}

.hover\:tw-text-yellow-800:hover{
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity))
}

.hover\:tw-text-yellow-900:hover{
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity))
}

.hover\:tw-text-green-100:hover{
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity))
}

.hover\:tw-text-green-200:hover{
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity))
}

.hover\:tw-text-green-300:hover{
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity))
}

.hover\:tw-text-green-400:hover{
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity))
}

.hover\:tw-text-green-500:hover{
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.hover\:tw-text-green-600:hover{
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.hover\:tw-text-green-700:hover{
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.hover\:tw-text-green-800:hover{
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity))
}

.hover\:tw-text-green-900:hover{
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity))
}

.hover\:tw-text-teal-100:hover{
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity))
}

.hover\:tw-text-teal-200:hover{
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity))
}

.hover\:tw-text-teal-300:hover{
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity))
}

.hover\:tw-text-teal-400:hover{
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity))
}

.hover\:tw-text-teal-500:hover{
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity))
}

.hover\:tw-text-teal-600:hover{
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity))
}

.hover\:tw-text-teal-700:hover{
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity))
}

.hover\:tw-text-teal-800:hover{
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity))
}

.hover\:tw-text-teal-900:hover{
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity))
}

.hover\:tw-text-blue-100:hover{
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity))
}

.hover\:tw-text-blue-200:hover{
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity))
}

.hover\:tw-text-blue-300:hover{
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity))
}

.hover\:tw-text-blue-400:hover{
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity))
}

.hover\:tw-text-blue-500:hover{
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.hover\:tw-text-blue-600:hover{
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.hover\:tw-text-blue-700:hover{
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.hover\:tw-text-blue-800:hover{
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity))
}

.hover\:tw-text-blue-900:hover{
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity))
}

.hover\:tw-text-indigo-100:hover{
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity))
}

.hover\:tw-text-indigo-200:hover{
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity))
}

.hover\:tw-text-indigo-300:hover{
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity))
}

.hover\:tw-text-indigo-400:hover{
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity))
}

.hover\:tw-text-indigo-500:hover{
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity))
}

.hover\:tw-text-indigo-600:hover{
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity))
}

.hover\:tw-text-indigo-700:hover{
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity))
}

.hover\:tw-text-indigo-800:hover{
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity))
}

.hover\:tw-text-indigo-900:hover{
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity))
}

.hover\:tw-text-purple-100:hover{
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity))
}

.hover\:tw-text-purple-200:hover{
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity))
}

.hover\:tw-text-purple-300:hover{
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity))
}

.hover\:tw-text-purple-400:hover{
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity))
}

.hover\:tw-text-purple-500:hover{
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity))
}

.hover\:tw-text-purple-600:hover{
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity))
}

.hover\:tw-text-purple-700:hover{
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity))
}

.hover\:tw-text-purple-800:hover{
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity))
}

.hover\:tw-text-purple-900:hover{
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity))
}

.hover\:tw-text-pink-100:hover{
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity))
}

.hover\:tw-text-pink-200:hover{
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity))
}

.hover\:tw-text-pink-300:hover{
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity))
}

.hover\:tw-text-pink-400:hover{
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity))
}

.hover\:tw-text-pink-500:hover{
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity))
}

.hover\:tw-text-pink-600:hover{
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity))
}

.hover\:tw-text-pink-700:hover{
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity))
}

.hover\:tw-text-pink-800:hover{
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity))
}

.hover\:tw-text-pink-900:hover{
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity))
}

.focus\:tw-text-transparent:focus{
  color: transparent
}

.focus\:tw-text-tlg-green-dark:focus{
  --text-opacity: 1;
  color: #76b84e;
  color: rgba(118, 184, 78, var(--text-opacity))
}

.focus\:tw-text-tlg-green:focus{
  --text-opacity: 1;
  color: #a4ca61;
  color: rgba(164, 202, 97, var(--text-opacity))
}

.focus\:tw-text-tlg-orange-500:focus{
  --text-opacity: 1;
  color: #ff7f41;
  color: rgba(255, 127, 65, var(--text-opacity))
}

.focus\:tw-text-black:focus{
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.focus\:tw-text-white:focus{
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.focus\:tw-text-gray-100:focus{
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity))
}

.focus\:tw-text-gray-200:focus{
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity))
}

.focus\:tw-text-gray-300:focus{
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity))
}

.focus\:tw-text-gray-400:focus{
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity))
}

.focus\:tw-text-gray-500:focus{
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity))
}

.focus\:tw-text-gray-600:focus{
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.focus\:tw-text-gray-700:focus{
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity))
}

.focus\:tw-text-gray-800:focus{
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity))
}

.focus\:tw-text-gray-900:focus{
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity))
}

.focus\:tw-text-red-100:focus{
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity))
}

.focus\:tw-text-red-200:focus{
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity))
}

.focus\:tw-text-red-300:focus{
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity))
}

.focus\:tw-text-red-400:focus{
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity))
}

.focus\:tw-text-red-500:focus{
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity))
}

.focus\:tw-text-red-600:focus{
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity))
}

.focus\:tw-text-red-700:focus{
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity))
}

.focus\:tw-text-red-800:focus{
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity))
}

.focus\:tw-text-red-900:focus{
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity))
}

.focus\:tw-text-orange-100:focus{
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity))
}

.focus\:tw-text-orange-200:focus{
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity))
}

.focus\:tw-text-orange-300:focus{
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity))
}

.focus\:tw-text-orange-400:focus{
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity))
}

.focus\:tw-text-orange-500:focus{
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity))
}

.focus\:tw-text-orange-600:focus{
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity))
}

.focus\:tw-text-orange-700:focus{
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity))
}

.focus\:tw-text-orange-800:focus{
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity))
}

.focus\:tw-text-orange-900:focus{
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity))
}

.focus\:tw-text-yellow-100:focus{
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity))
}

.focus\:tw-text-yellow-200:focus{
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity))
}

.focus\:tw-text-yellow-300:focus{
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity))
}

.focus\:tw-text-yellow-400:focus{
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity))
}

.focus\:tw-text-yellow-500:focus{
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity))
}

.focus\:tw-text-yellow-600:focus{
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity))
}

.focus\:tw-text-yellow-700:focus{
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity))
}

.focus\:tw-text-yellow-800:focus{
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity))
}

.focus\:tw-text-yellow-900:focus{
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity))
}

.focus\:tw-text-green-100:focus{
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity))
}

.focus\:tw-text-green-200:focus{
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity))
}

.focus\:tw-text-green-300:focus{
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity))
}

.focus\:tw-text-green-400:focus{
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity))
}

.focus\:tw-text-green-500:focus{
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity))
}

.focus\:tw-text-green-600:focus{
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity))
}

.focus\:tw-text-green-700:focus{
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity))
}

.focus\:tw-text-green-800:focus{
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity))
}

.focus\:tw-text-green-900:focus{
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity))
}

.focus\:tw-text-teal-100:focus{
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity))
}

.focus\:tw-text-teal-200:focus{
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity))
}

.focus\:tw-text-teal-300:focus{
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity))
}

.focus\:tw-text-teal-400:focus{
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity))
}

.focus\:tw-text-teal-500:focus{
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity))
}

.focus\:tw-text-teal-600:focus{
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity))
}

.focus\:tw-text-teal-700:focus{
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity))
}

.focus\:tw-text-teal-800:focus{
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity))
}

.focus\:tw-text-teal-900:focus{
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity))
}

.focus\:tw-text-blue-100:focus{
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity))
}

.focus\:tw-text-blue-200:focus{
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity))
}

.focus\:tw-text-blue-300:focus{
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity))
}

.focus\:tw-text-blue-400:focus{
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity))
}

.focus\:tw-text-blue-500:focus{
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity))
}

.focus\:tw-text-blue-600:focus{
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity))
}

.focus\:tw-text-blue-700:focus{
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity))
}

.focus\:tw-text-blue-800:focus{
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity))
}

.focus\:tw-text-blue-900:focus{
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity))
}

.focus\:tw-text-indigo-100:focus{
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity))
}

.focus\:tw-text-indigo-200:focus{
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity))
}

.focus\:tw-text-indigo-300:focus{
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity))
}

.focus\:tw-text-indigo-400:focus{
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity))
}

.focus\:tw-text-indigo-500:focus{
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity))
}

.focus\:tw-text-indigo-600:focus{
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity))
}

.focus\:tw-text-indigo-700:focus{
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity))
}

.focus\:tw-text-indigo-800:focus{
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity))
}

.focus\:tw-text-indigo-900:focus{
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity))
}

.focus\:tw-text-purple-100:focus{
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity))
}

.focus\:tw-text-purple-200:focus{
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity))
}

.focus\:tw-text-purple-300:focus{
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity))
}

.focus\:tw-text-purple-400:focus{
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity))
}

.focus\:tw-text-purple-500:focus{
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity))
}

.focus\:tw-text-purple-600:focus{
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity))
}

.focus\:tw-text-purple-700:focus{
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity))
}

.focus\:tw-text-purple-800:focus{
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity))
}

.focus\:tw-text-purple-900:focus{
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity))
}

.focus\:tw-text-pink-100:focus{
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity))
}

.focus\:tw-text-pink-200:focus{
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity))
}

.focus\:tw-text-pink-300:focus{
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity))
}

.focus\:tw-text-pink-400:focus{
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity))
}

.focus\:tw-text-pink-500:focus{
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity))
}

.focus\:tw-text-pink-600:focus{
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity))
}

.focus\:tw-text-pink-700:focus{
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity))
}

.focus\:tw-text-pink-800:focus{
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity))
}

.focus\:tw-text-pink-900:focus{
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity))
}

.tw-text-opacity-0{
  --text-opacity: 0
}

.tw-text-opacity-25{
  --text-opacity: 0.25
}

.tw-text-opacity-50{
  --text-opacity: 0.5
}

.tw-text-opacity-75{
  --text-opacity: 0.75
}

.tw-text-opacity-100{
  --text-opacity: 1
}

.hover\:tw-text-opacity-0:hover{
  --text-opacity: 0
}

.hover\:tw-text-opacity-25:hover{
  --text-opacity: 0.25
}

.hover\:tw-text-opacity-50:hover{
  --text-opacity: 0.5
}

.hover\:tw-text-opacity-75:hover{
  --text-opacity: 0.75
}

.hover\:tw-text-opacity-100:hover{
  --text-opacity: 1
}

.focus\:tw-text-opacity-0:focus{
  --text-opacity: 0
}

.focus\:tw-text-opacity-25:focus{
  --text-opacity: 0.25
}

.focus\:tw-text-opacity-50:focus{
  --text-opacity: 0.5
}

.focus\:tw-text-opacity-75:focus{
  --text-opacity: 0.75
}

.focus\:tw-text-opacity-100:focus{
  --text-opacity: 1
}

.tw-italic{
  font-style: italic
}

.tw-not-italic{
  font-style: normal
}

.tw-uppercase{
  text-transform: uppercase
}

.tw-lowercase{
  text-transform: lowercase
}

.tw-capitalize{
  text-transform: capitalize
}

.tw-normal-case{
  text-transform: none
}

.tw-underline{
  text-decoration: underline
}

.tw-line-through{
  text-decoration: line-through
}

.tw-no-underline{
  text-decoration: none
}

.hover\:tw-underline:hover{
  text-decoration: underline
}

.hover\:tw-line-through:hover{
  text-decoration: line-through
}

.hover\:tw-no-underline:hover{
  text-decoration: none
}

.focus\:tw-underline:focus{
  text-decoration: underline
}

.focus\:tw-line-through:focus{
  text-decoration: line-through
}

.focus\:tw-no-underline:focus{
  text-decoration: none
}

.tw-antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.tw-subpixel-antialiased{
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

.tw-ordinal, .tw-slashed-zero, .tw-lining-nums, .tw-oldstyle-nums, .tw-proportional-nums, .tw-tabular-nums, .tw-diagonal-fractions, .tw-stacked-fractions{
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  -webkit-font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
          font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
}

.tw-normal-nums{
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant-numeric: normal
}

.tw-ordinal{
  --font-variant-numeric-ordinal: ordinal
}

.tw-slashed-zero{
  --font-variant-numeric-slashed-zero: slashed-zero
}

.tw-lining-nums{
  --font-variant-numeric-figure: lining-nums
}

.tw-oldstyle-nums{
  --font-variant-numeric-figure: oldstyle-nums
}

.tw-proportional-nums{
  --font-variant-numeric-spacing: proportional-nums
}

.tw-tabular-nums{
  --font-variant-numeric-spacing: tabular-nums
}

.tw-diagonal-fractions{
  --font-variant-numeric-fraction: diagonal-fractions
}

.tw-stacked-fractions{
  --font-variant-numeric-fraction: stacked-fractions
}

.tw-tracking-tighter{
  letter-spacing: -0.05em
}

.tw-tracking-tight{
  letter-spacing: -0.025em
}

.tw-tracking-normal{
  letter-spacing: 0
}

.tw-tracking-wide{
  letter-spacing: 0.025em
}

.tw-tracking-wider{
  letter-spacing: 0.05em
}

.tw-tracking-widest{
  letter-spacing: 0.1em
}

.tw-select-none{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.tw-select-text{
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text
}

.tw-select-all{
  -webkit-user-select: all;
     -moz-user-select: all;
      -ms-user-select: all;
          user-select: all
}

.tw-select-auto{
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto
}

.tw-align-baseline{
  vertical-align: baseline
}

.tw-align-top{
  vertical-align: top
}

.tw-align-middle{
  vertical-align: middle
}

.tw-align-bottom{
  vertical-align: bottom
}

.tw-align-text-top{
  vertical-align: text-top
}

.tw-align-text-bottom{
  vertical-align: text-bottom
}

.tw-visible{
  visibility: visible
}

.tw-invisible{
  visibility: hidden
}

.tw-whitespace-normal{
  white-space: normal
}

.tw-whitespace-no-wrap{
  white-space: nowrap
}

.tw-whitespace-pre{
  white-space: pre
}

.tw-whitespace-pre-line{
  white-space: pre-line
}

.tw-whitespace-pre-wrap{
  white-space: pre-wrap
}

.tw-break-normal{
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal
}

.tw-break-words{
  word-wrap: break-word;
  overflow-wrap: break-word
}

.tw-break-all{
  word-break: break-all
}

.tw-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tw-w-0{
  width: 0
}

.tw-w-1{
  width: 0.25rem
}

.tw-w-2{
  width: 0.5rem
}

.tw-w-3{
  width: 0.75rem
}

.tw-w-4{
  width: 1rem
}

.tw-w-5{
  width: 1.25rem
}

.tw-w-6{
  width: 1.5rem
}

.tw-w-8{
  width: 2rem
}

.tw-w-10{
  width: 2.5rem
}

.tw-w-12{
  width: 3rem
}

.tw-w-16{
  width: 4rem
}

.tw-w-20{
  width: 5rem
}

.tw-w-24{
  width: 6rem
}

.tw-w-32{
  width: 8rem
}

.tw-w-40{
  width: 10rem
}

.tw-w-48{
  width: 12rem
}

.tw-w-56{
  width: 14rem
}

.tw-w-64{
  width: 16rem
}

.tw-w-auto{
  width: auto
}

.tw-w-px{
  width: 1px
}

.tw-w-1\/2{
  width: 50%
}

.tw-w-1\/3{
  width: 33.33333%
}

.tw-w-2\/3{
  width: 66.66667%
}

.tw-w-1\/4{
  width: 25%
}

.tw-w-2\/4{
  width: 50%
}

.tw-w-3\/4{
  width: 75%
}

.tw-w-1\/5{
  width: 20%
}

.tw-w-2\/5{
  width: 40%
}

.tw-w-3\/5{
  width: 60%
}

.tw-w-4\/5{
  width: 80%
}

.tw-w-1\/6{
  width: 16.66667%
}

.tw-w-2\/6{
  width: 33.33333%
}

.tw-w-3\/6{
  width: 50%
}

.tw-w-4\/6{
  width: 66.66667%
}

.tw-w-5\/6{
  width: 83.33333%
}

.tw-w-1\/12{
  width: 8.33333%
}

.tw-w-2\/12{
  width: 16.66667%
}

.tw-w-3\/12{
  width: 25%
}

.tw-w-4\/12{
  width: 33.33333%
}

.tw-w-5\/12{
  width: 41.66667%
}

.tw-w-6\/12{
  width: 50%
}

.tw-w-7\/12{
  width: 58.33333%
}

.tw-w-8\/12{
  width: 66.66667%
}

.tw-w-9\/12{
  width: 75%
}

.tw-w-10\/12{
  width: 83.33333%
}

.tw-w-11\/12{
  width: 91.66667%
}

.tw-w-full{
  width: 100%
}

.tw-w-screen{
  width: 100vw
}

.tw-z-0{
  z-index: 0
}

.tw-z-10{
  z-index: 10
}

.tw-z-20{
  z-index: 20
}

.tw-z-30{
  z-index: 30
}

.tw-z-40{
  z-index: 40
}

.tw-z-50{
  z-index: 50
}

.tw-z-auto{
  z-index: auto
}

.tw-gap-0{
  grid-gap: 0;
  grid-gap: 0;
  gap: 0
}

.tw-gap-1{
  grid-gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem
}

.tw-gap-2{
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.tw-gap-3{
  grid-gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem
}

.tw-gap-4{
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem
}

.tw-gap-5{
  grid-gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem
}

.tw-gap-6{
  grid-gap: 1.5rem;
  grid-gap: 1.5rem;
  gap: 1.5rem
}

.tw-gap-8{
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem
}

.tw-gap-10{
  grid-gap: 2.5rem;
  grid-gap: 2.5rem;
  gap: 2.5rem
}

.tw-gap-12{
  grid-gap: 3rem;
  grid-gap: 3rem;
  gap: 3rem
}

.tw-gap-16{
  grid-gap: 4rem;
  grid-gap: 4rem;
  gap: 4rem
}

.tw-gap-20{
  grid-gap: 5rem;
  grid-gap: 5rem;
  gap: 5rem
}

.tw-gap-24{
  grid-gap: 6rem;
  grid-gap: 6rem;
  gap: 6rem
}

.tw-gap-32{
  grid-gap: 8rem;
  grid-gap: 8rem;
  gap: 8rem
}

.tw-gap-40{
  grid-gap: 10rem;
  grid-gap: 10rem;
  gap: 10rem
}

.tw-gap-48{
  grid-gap: 12rem;
  grid-gap: 12rem;
  gap: 12rem
}

.tw-gap-56{
  grid-gap: 14rem;
  grid-gap: 14rem;
  gap: 14rem
}

.tw-gap-64{
  grid-gap: 16rem;
  grid-gap: 16rem;
  gap: 16rem
}

.tw-gap-px{
  grid-gap: 1px;
  grid-gap: 1px;
  gap: 1px
}

.tw-col-gap-0{
  grid-column-gap: 0;
  grid-column-gap: 0;
  -webkit-column-gap: 0;
     -moz-column-gap: 0;
          column-gap: 0
}

.tw-col-gap-1{
  grid-column-gap: 0.25rem;
  grid-column-gap: 0.25rem;
  -webkit-column-gap: 0.25rem;
     -moz-column-gap: 0.25rem;
          column-gap: 0.25rem
}

.tw-col-gap-2{
  grid-column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem
}

.tw-col-gap-3{
  grid-column-gap: 0.75rem;
  grid-column-gap: 0.75rem;
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem
}

.tw-col-gap-4{
  grid-column-gap: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem
}

.tw-col-gap-5{
  grid-column-gap: 1.25rem;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
     -moz-column-gap: 1.25rem;
          column-gap: 1.25rem
}

.tw-col-gap-6{
  grid-column-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
     -moz-column-gap: 1.5rem;
          column-gap: 1.5rem
}

.tw-col-gap-8{
  grid-column-gap: 2rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem
}

.tw-col-gap-10{
  grid-column-gap: 2.5rem;
  grid-column-gap: 2.5rem;
  -webkit-column-gap: 2.5rem;
     -moz-column-gap: 2.5rem;
          column-gap: 2.5rem
}

.tw-col-gap-12{
  grid-column-gap: 3rem;
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
     -moz-column-gap: 3rem;
          column-gap: 3rem
}

.tw-col-gap-16{
  grid-column-gap: 4rem;
  grid-column-gap: 4rem;
  -webkit-column-gap: 4rem;
     -moz-column-gap: 4rem;
          column-gap: 4rem
}

.tw-col-gap-20{
  grid-column-gap: 5rem;
  grid-column-gap: 5rem;
  -webkit-column-gap: 5rem;
     -moz-column-gap: 5rem;
          column-gap: 5rem
}

.tw-col-gap-24{
  grid-column-gap: 6rem;
  grid-column-gap: 6rem;
  -webkit-column-gap: 6rem;
     -moz-column-gap: 6rem;
          column-gap: 6rem
}

.tw-col-gap-32{
  grid-column-gap: 8rem;
  grid-column-gap: 8rem;
  -webkit-column-gap: 8rem;
     -moz-column-gap: 8rem;
          column-gap: 8rem
}

.tw-col-gap-40{
  grid-column-gap: 10rem;
  grid-column-gap: 10rem;
  -webkit-column-gap: 10rem;
     -moz-column-gap: 10rem;
          column-gap: 10rem
}

.tw-col-gap-48{
  grid-column-gap: 12rem;
  grid-column-gap: 12rem;
  -webkit-column-gap: 12rem;
     -moz-column-gap: 12rem;
          column-gap: 12rem
}

.tw-col-gap-56{
  grid-column-gap: 14rem;
  grid-column-gap: 14rem;
  -webkit-column-gap: 14rem;
     -moz-column-gap: 14rem;
          column-gap: 14rem
}

.tw-col-gap-64{
  grid-column-gap: 16rem;
  grid-column-gap: 16rem;
  -webkit-column-gap: 16rem;
     -moz-column-gap: 16rem;
          column-gap: 16rem
}

.tw-col-gap-px{
  grid-column-gap: 1px;
  grid-column-gap: 1px;
  -webkit-column-gap: 1px;
     -moz-column-gap: 1px;
          column-gap: 1px
}

.tw-gap-x-0{
  grid-column-gap: 0;
  grid-column-gap: 0;
  -webkit-column-gap: 0;
     -moz-column-gap: 0;
          column-gap: 0
}

.tw-gap-x-1{
  grid-column-gap: 0.25rem;
  grid-column-gap: 0.25rem;
  -webkit-column-gap: 0.25rem;
     -moz-column-gap: 0.25rem;
          column-gap: 0.25rem
}

.tw-gap-x-2{
  grid-column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem
}

.tw-gap-x-3{
  grid-column-gap: 0.75rem;
  grid-column-gap: 0.75rem;
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem
}

.tw-gap-x-4{
  grid-column-gap: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem
}

.tw-gap-x-5{
  grid-column-gap: 1.25rem;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
     -moz-column-gap: 1.25rem;
          column-gap: 1.25rem
}

.tw-gap-x-6{
  grid-column-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
     -moz-column-gap: 1.5rem;
          column-gap: 1.5rem
}

.tw-gap-x-8{
  grid-column-gap: 2rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem
}

.tw-gap-x-10{
  grid-column-gap: 2.5rem;
  grid-column-gap: 2.5rem;
  -webkit-column-gap: 2.5rem;
     -moz-column-gap: 2.5rem;
          column-gap: 2.5rem
}

.tw-gap-x-12{
  grid-column-gap: 3rem;
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
     -moz-column-gap: 3rem;
          column-gap: 3rem
}

.tw-gap-x-16{
  grid-column-gap: 4rem;
  grid-column-gap: 4rem;
  -webkit-column-gap: 4rem;
     -moz-column-gap: 4rem;
          column-gap: 4rem
}

.tw-gap-x-20{
  grid-column-gap: 5rem;
  grid-column-gap: 5rem;
  -webkit-column-gap: 5rem;
     -moz-column-gap: 5rem;
          column-gap: 5rem
}

.tw-gap-x-24{
  grid-column-gap: 6rem;
  grid-column-gap: 6rem;
  -webkit-column-gap: 6rem;
     -moz-column-gap: 6rem;
          column-gap: 6rem
}

.tw-gap-x-32{
  grid-column-gap: 8rem;
  grid-column-gap: 8rem;
  -webkit-column-gap: 8rem;
     -moz-column-gap: 8rem;
          column-gap: 8rem
}

.tw-gap-x-40{
  grid-column-gap: 10rem;
  grid-column-gap: 10rem;
  -webkit-column-gap: 10rem;
     -moz-column-gap: 10rem;
          column-gap: 10rem
}

.tw-gap-x-48{
  grid-column-gap: 12rem;
  grid-column-gap: 12rem;
  -webkit-column-gap: 12rem;
     -moz-column-gap: 12rem;
          column-gap: 12rem
}

.tw-gap-x-56{
  grid-column-gap: 14rem;
  grid-column-gap: 14rem;
  -webkit-column-gap: 14rem;
     -moz-column-gap: 14rem;
          column-gap: 14rem
}

.tw-gap-x-64{
  grid-column-gap: 16rem;
  grid-column-gap: 16rem;
  -webkit-column-gap: 16rem;
     -moz-column-gap: 16rem;
          column-gap: 16rem
}

.tw-gap-x-px{
  grid-column-gap: 1px;
  grid-column-gap: 1px;
  -webkit-column-gap: 1px;
     -moz-column-gap: 1px;
          column-gap: 1px
}

.tw-row-gap-0{
  grid-row-gap: 0;
  grid-row-gap: 0;
  row-gap: 0
}

.tw-row-gap-1{
  grid-row-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem
}

.tw-row-gap-2{
  grid-row-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.tw-row-gap-3{
  grid-row-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem
}

.tw-row-gap-4{
  grid-row-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.tw-row-gap-5{
  grid-row-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.tw-row-gap-6{
  grid-row-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem
}

.tw-row-gap-8{
  grid-row-gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.tw-row-gap-10{
  grid-row-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem
}

.tw-row-gap-12{
  grid-row-gap: 3rem;
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.tw-row-gap-16{
  grid-row-gap: 4rem;
  grid-row-gap: 4rem;
  row-gap: 4rem
}

.tw-row-gap-20{
  grid-row-gap: 5rem;
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.tw-row-gap-24{
  grid-row-gap: 6rem;
  grid-row-gap: 6rem;
  row-gap: 6rem
}

.tw-row-gap-32{
  grid-row-gap: 8rem;
  grid-row-gap: 8rem;
  row-gap: 8rem
}

.tw-row-gap-40{
  grid-row-gap: 10rem;
  grid-row-gap: 10rem;
  row-gap: 10rem
}

.tw-row-gap-48{
  grid-row-gap: 12rem;
  grid-row-gap: 12rem;
  row-gap: 12rem
}

.tw-row-gap-56{
  grid-row-gap: 14rem;
  grid-row-gap: 14rem;
  row-gap: 14rem
}

.tw-row-gap-64{
  grid-row-gap: 16rem;
  grid-row-gap: 16rem;
  row-gap: 16rem
}

.tw-row-gap-px{
  grid-row-gap: 1px;
  grid-row-gap: 1px;
  row-gap: 1px
}

.tw-gap-y-0{
  grid-row-gap: 0;
  grid-row-gap: 0;
  row-gap: 0
}

.tw-gap-y-1{
  grid-row-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem
}

.tw-gap-y-2{
  grid-row-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.tw-gap-y-3{
  grid-row-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem
}

.tw-gap-y-4{
  grid-row-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.tw-gap-y-5{
  grid-row-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.tw-gap-y-6{
  grid-row-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem
}

.tw-gap-y-8{
  grid-row-gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.tw-gap-y-10{
  grid-row-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem
}

.tw-gap-y-12{
  grid-row-gap: 3rem;
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.tw-gap-y-16{
  grid-row-gap: 4rem;
  grid-row-gap: 4rem;
  row-gap: 4rem
}

.tw-gap-y-20{
  grid-row-gap: 5rem;
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.tw-gap-y-24{
  grid-row-gap: 6rem;
  grid-row-gap: 6rem;
  row-gap: 6rem
}

.tw-gap-y-32{
  grid-row-gap: 8rem;
  grid-row-gap: 8rem;
  row-gap: 8rem
}

.tw-gap-y-40{
  grid-row-gap: 10rem;
  grid-row-gap: 10rem;
  row-gap: 10rem
}

.tw-gap-y-48{
  grid-row-gap: 12rem;
  grid-row-gap: 12rem;
  row-gap: 12rem
}

.tw-gap-y-56{
  grid-row-gap: 14rem;
  grid-row-gap: 14rem;
  row-gap: 14rem
}

.tw-gap-y-64{
  grid-row-gap: 16rem;
  grid-row-gap: 16rem;
  row-gap: 16rem
}

.tw-gap-y-px{
  grid-row-gap: 1px;
  grid-row-gap: 1px;
  row-gap: 1px
}

.tw-grid-flow-row{
  grid-auto-flow: row
}

.tw-grid-flow-col{
  grid-auto-flow: column
}

.tw-grid-flow-row-dense{
  grid-auto-flow: row dense
}

.tw-grid-flow-col-dense{
  grid-auto-flow: column dense
}

.tw-grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.tw-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.tw-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.tw-grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.tw-grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.tw-grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

.tw-grid-cols-7{
  grid-template-columns: repeat(7, minmax(0, 1fr))
}

.tw-grid-cols-8{
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.tw-grid-cols-9{
  grid-template-columns: repeat(9, minmax(0, 1fr))
}

.tw-grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

.tw-grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr))
}

.tw-grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.tw-grid-cols-none{
  grid-template-columns: none
}

.tw-auto-cols-auto{
  grid-auto-columns: auto
}

.tw-auto-cols-min{
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content
}

.tw-auto-cols-max{
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content
}

.tw-auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr)
}

.tw-col-auto{
  grid-column: auto
}

.tw-col-span-1{
  grid-column: span 1 / span 1
}

.tw-col-span-2{
  grid-column: span 2 / span 2
}

.tw-col-span-3{
  grid-column: span 3 / span 3
}

.tw-col-span-4{
  grid-column: span 4 / span 4
}

.tw-col-span-5{
  grid-column: span 5 / span 5
}

.tw-col-span-6{
  grid-column: span 6 / span 6
}

.tw-col-span-7{
  grid-column: span 7 / span 7
}

.tw-col-span-8{
  grid-column: span 8 / span 8
}

.tw-col-span-9{
  grid-column: span 9 / span 9
}

.tw-col-span-10{
  grid-column: span 10 / span 10
}

.tw-col-span-11{
  grid-column: span 11 / span 11
}

.tw-col-span-12{
  grid-column: span 12 / span 12
}

.tw-col-span-full{
  grid-column: 1 / -1
}

.tw-col-start-1{
  grid-column-start: 1
}

.tw-col-start-2{
  grid-column-start: 2
}

.tw-col-start-3{
  grid-column-start: 3
}

.tw-col-start-4{
  grid-column-start: 4
}

.tw-col-start-5{
  grid-column-start: 5
}

.tw-col-start-6{
  grid-column-start: 6
}

.tw-col-start-7{
  grid-column-start: 7
}

.tw-col-start-8{
  grid-column-start: 8
}

.tw-col-start-9{
  grid-column-start: 9
}

.tw-col-start-10{
  grid-column-start: 10
}

.tw-col-start-11{
  grid-column-start: 11
}

.tw-col-start-12{
  grid-column-start: 12
}

.tw-col-start-13{
  grid-column-start: 13
}

.tw-col-start-auto{
  grid-column-start: auto
}

.tw-col-end-1{
  grid-column-end: 1
}

.tw-col-end-2{
  grid-column-end: 2
}

.tw-col-end-3{
  grid-column-end: 3
}

.tw-col-end-4{
  grid-column-end: 4
}

.tw-col-end-5{
  grid-column-end: 5
}

.tw-col-end-6{
  grid-column-end: 6
}

.tw-col-end-7{
  grid-column-end: 7
}

.tw-col-end-8{
  grid-column-end: 8
}

.tw-col-end-9{
  grid-column-end: 9
}

.tw-col-end-10{
  grid-column-end: 10
}

.tw-col-end-11{
  grid-column-end: 11
}

.tw-col-end-12{
  grid-column-end: 12
}

.tw-col-end-13{
  grid-column-end: 13
}

.tw-col-end-auto{
  grid-column-end: auto
}

.tw-grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr))
}

.tw-grid-rows-2{
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.tw-grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr))
}

.tw-grid-rows-4{
  grid-template-rows: repeat(4, minmax(0, 1fr))
}

.tw-grid-rows-5{
  grid-template-rows: repeat(5, minmax(0, 1fr))
}

.tw-grid-rows-6{
  grid-template-rows: repeat(6, minmax(0, 1fr))
}

.tw-grid-rows-none{
  grid-template-rows: none
}

.tw-auto-rows-auto{
  grid-auto-rows: auto
}

.tw-auto-rows-min{
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content
}

.tw-auto-rows-max{
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content
}

.tw-auto-rows-fr{
  grid-auto-rows: minmax(0, 1fr)
}

.tw-row-auto{
  grid-row: auto
}

.tw-row-span-1{
  grid-row: span 1 / span 1
}

.tw-row-span-2{
  grid-row: span 2 / span 2
}

.tw-row-span-3{
  grid-row: span 3 / span 3
}

.tw-row-span-4{
  grid-row: span 4 / span 4
}

.tw-row-span-5{
  grid-row: span 5 / span 5
}

.tw-row-span-6{
  grid-row: span 6 / span 6
}

.tw-row-span-full{
  grid-row: 1 / -1
}

.tw-row-start-1{
  grid-row-start: 1
}

.tw-row-start-2{
  grid-row-start: 2
}

.tw-row-start-3{
  grid-row-start: 3
}

.tw-row-start-4{
  grid-row-start: 4
}

.tw-row-start-5{
  grid-row-start: 5
}

.tw-row-start-6{
  grid-row-start: 6
}

.tw-row-start-7{
  grid-row-start: 7
}

.tw-row-start-auto{
  grid-row-start: auto
}

.tw-row-end-1{
  grid-row-end: 1
}

.tw-row-end-2{
  grid-row-end: 2
}

.tw-row-end-3{
  grid-row-end: 3
}

.tw-row-end-4{
  grid-row-end: 4
}

.tw-row-end-5{
  grid-row-end: 5
}

.tw-row-end-6{
  grid-row-end: 6
}

.tw-row-end-7{
  grid-row-end: 7
}

.tw-row-end-auto{
  grid-row-end: auto
}

.tw-transform{
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.tw-transform-none{
  -webkit-transform: none;
          transform: none
}

.tw-origin-center{
  -webkit-transform-origin: center;
          transform-origin: center
}

.tw-origin-top{
  -webkit-transform-origin: top;
          transform-origin: top
}

.tw-origin-top-right{
  -webkit-transform-origin: top right;
          transform-origin: top right
}

.tw-origin-right{
  -webkit-transform-origin: right;
          transform-origin: right
}

.tw-origin-bottom-right{
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right
}

.tw-origin-bottom{
  -webkit-transform-origin: bottom;
          transform-origin: bottom
}

.tw-origin-bottom-left{
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left
}

.tw-origin-left{
  -webkit-transform-origin: left;
          transform-origin: left
}

.tw-origin-top-left{
  -webkit-transform-origin: top left;
          transform-origin: top left
}

.tw-scale-0{
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.tw-scale-50{
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.tw-scale-75{
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.tw-scale-90{
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.tw-scale-95{
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.tw-scale-100{
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.tw-scale-105{
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.tw-scale-110{
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.tw-scale-125{
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.tw-scale-150{
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.tw-scale-x-0{
  --transform-scale-x: 0
}

.tw-scale-x-50{
  --transform-scale-x: .5
}

.tw-scale-x-75{
  --transform-scale-x: .75
}

.tw-scale-x-90{
  --transform-scale-x: .9
}

.tw-scale-x-95{
  --transform-scale-x: .95
}

.tw-scale-x-100{
  --transform-scale-x: 1
}

.tw-scale-x-105{
  --transform-scale-x: 1.05
}

.tw-scale-x-110{
  --transform-scale-x: 1.1
}

.tw-scale-x-125{
  --transform-scale-x: 1.25
}

.tw-scale-x-150{
  --transform-scale-x: 1.5
}

.tw-scale-y-0{
  --transform-scale-y: 0
}

.tw-scale-y-50{
  --transform-scale-y: .5
}

.tw-scale-y-75{
  --transform-scale-y: .75
}

.tw-scale-y-90{
  --transform-scale-y: .9
}

.tw-scale-y-95{
  --transform-scale-y: .95
}

.tw-scale-y-100{
  --transform-scale-y: 1
}

.tw-scale-y-105{
  --transform-scale-y: 1.05
}

.tw-scale-y-110{
  --transform-scale-y: 1.1
}

.tw-scale-y-125{
  --transform-scale-y: 1.25
}

.tw-scale-y-150{
  --transform-scale-y: 1.5
}

.hover\:tw-scale-0:hover{
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.hover\:tw-scale-50:hover{
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.hover\:tw-scale-75:hover{
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.hover\:tw-scale-90:hover{
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.hover\:tw-scale-95:hover{
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.hover\:tw-scale-100:hover{
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.hover\:tw-scale-105:hover{
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.hover\:tw-scale-110:hover{
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.hover\:tw-scale-125:hover{
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.hover\:tw-scale-150:hover{
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.hover\:tw-scale-x-0:hover{
  --transform-scale-x: 0
}

.hover\:tw-scale-x-50:hover{
  --transform-scale-x: .5
}

.hover\:tw-scale-x-75:hover{
  --transform-scale-x: .75
}

.hover\:tw-scale-x-90:hover{
  --transform-scale-x: .9
}

.hover\:tw-scale-x-95:hover{
  --transform-scale-x: .95
}

.hover\:tw-scale-x-100:hover{
  --transform-scale-x: 1
}

.hover\:tw-scale-x-105:hover{
  --transform-scale-x: 1.05
}

.hover\:tw-scale-x-110:hover{
  --transform-scale-x: 1.1
}

.hover\:tw-scale-x-125:hover{
  --transform-scale-x: 1.25
}

.hover\:tw-scale-x-150:hover{
  --transform-scale-x: 1.5
}

.hover\:tw-scale-y-0:hover{
  --transform-scale-y: 0
}

.hover\:tw-scale-y-50:hover{
  --transform-scale-y: .5
}

.hover\:tw-scale-y-75:hover{
  --transform-scale-y: .75
}

.hover\:tw-scale-y-90:hover{
  --transform-scale-y: .9
}

.hover\:tw-scale-y-95:hover{
  --transform-scale-y: .95
}

.hover\:tw-scale-y-100:hover{
  --transform-scale-y: 1
}

.hover\:tw-scale-y-105:hover{
  --transform-scale-y: 1.05
}

.hover\:tw-scale-y-110:hover{
  --transform-scale-y: 1.1
}

.hover\:tw-scale-y-125:hover{
  --transform-scale-y: 1.25
}

.hover\:tw-scale-y-150:hover{
  --transform-scale-y: 1.5
}

.focus\:tw-scale-0:focus{
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.focus\:tw-scale-50:focus{
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.focus\:tw-scale-75:focus{
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.focus\:tw-scale-90:focus{
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.focus\:tw-scale-95:focus{
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.focus\:tw-scale-100:focus{
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.focus\:tw-scale-105:focus{
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.focus\:tw-scale-110:focus{
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.focus\:tw-scale-125:focus{
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.focus\:tw-scale-150:focus{
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.focus\:tw-scale-x-0:focus{
  --transform-scale-x: 0
}

.focus\:tw-scale-x-50:focus{
  --transform-scale-x: .5
}

.focus\:tw-scale-x-75:focus{
  --transform-scale-x: .75
}

.focus\:tw-scale-x-90:focus{
  --transform-scale-x: .9
}

.focus\:tw-scale-x-95:focus{
  --transform-scale-x: .95
}

.focus\:tw-scale-x-100:focus{
  --transform-scale-x: 1
}

.focus\:tw-scale-x-105:focus{
  --transform-scale-x: 1.05
}

.focus\:tw-scale-x-110:focus{
  --transform-scale-x: 1.1
}

.focus\:tw-scale-x-125:focus{
  --transform-scale-x: 1.25
}

.focus\:tw-scale-x-150:focus{
  --transform-scale-x: 1.5
}

.focus\:tw-scale-y-0:focus{
  --transform-scale-y: 0
}

.focus\:tw-scale-y-50:focus{
  --transform-scale-y: .5
}

.focus\:tw-scale-y-75:focus{
  --transform-scale-y: .75
}

.focus\:tw-scale-y-90:focus{
  --transform-scale-y: .9
}

.focus\:tw-scale-y-95:focus{
  --transform-scale-y: .95
}

.focus\:tw-scale-y-100:focus{
  --transform-scale-y: 1
}

.focus\:tw-scale-y-105:focus{
  --transform-scale-y: 1.05
}

.focus\:tw-scale-y-110:focus{
  --transform-scale-y: 1.1
}

.focus\:tw-scale-y-125:focus{
  --transform-scale-y: 1.25
}

.focus\:tw-scale-y-150:focus{
  --transform-scale-y: 1.5
}

.tw-rotate-0{
  --transform-rotate: 0
}

.tw-rotate-1{
  --transform-rotate: 1deg
}

.tw-rotate-2{
  --transform-rotate: 2deg
}

.tw-rotate-3{
  --transform-rotate: 3deg
}

.tw-rotate-6{
  --transform-rotate: 6deg
}

.tw-rotate-12{
  --transform-rotate: 12deg
}

.tw-rotate-45{
  --transform-rotate: 45deg
}

.tw-rotate-90{
  --transform-rotate: 90deg
}

.tw-rotate-180{
  --transform-rotate: 180deg
}

.tw--rotate-180{
  --transform-rotate: -180deg
}

.tw--rotate-90{
  --transform-rotate: -90deg
}

.tw--rotate-45{
  --transform-rotate: -45deg
}

.tw--rotate-12{
  --transform-rotate: -12deg
}

.tw--rotate-6{
  --transform-rotate: -6deg
}

.tw--rotate-3{
  --transform-rotate: -3deg
}

.tw--rotate-2{
  --transform-rotate: -2deg
}

.tw--rotate-1{
  --transform-rotate: -1deg
}

.hover\:tw-rotate-0:hover{
  --transform-rotate: 0
}

.hover\:tw-rotate-1:hover{
  --transform-rotate: 1deg
}

.hover\:tw-rotate-2:hover{
  --transform-rotate: 2deg
}

.hover\:tw-rotate-3:hover{
  --transform-rotate: 3deg
}

.hover\:tw-rotate-6:hover{
  --transform-rotate: 6deg
}

.hover\:tw-rotate-12:hover{
  --transform-rotate: 12deg
}

.hover\:tw-rotate-45:hover{
  --transform-rotate: 45deg
}

.hover\:tw-rotate-90:hover{
  --transform-rotate: 90deg
}

.hover\:tw-rotate-180:hover{
  --transform-rotate: 180deg
}

.hover\:tw--rotate-180:hover{
  --transform-rotate: -180deg
}

.hover\:tw--rotate-90:hover{
  --transform-rotate: -90deg
}

.hover\:tw--rotate-45:hover{
  --transform-rotate: -45deg
}

.hover\:tw--rotate-12:hover{
  --transform-rotate: -12deg
}

.hover\:tw--rotate-6:hover{
  --transform-rotate: -6deg
}

.hover\:tw--rotate-3:hover{
  --transform-rotate: -3deg
}

.hover\:tw--rotate-2:hover{
  --transform-rotate: -2deg
}

.hover\:tw--rotate-1:hover{
  --transform-rotate: -1deg
}

.focus\:tw-rotate-0:focus{
  --transform-rotate: 0
}

.focus\:tw-rotate-1:focus{
  --transform-rotate: 1deg
}

.focus\:tw-rotate-2:focus{
  --transform-rotate: 2deg
}

.focus\:tw-rotate-3:focus{
  --transform-rotate: 3deg
}

.focus\:tw-rotate-6:focus{
  --transform-rotate: 6deg
}

.focus\:tw-rotate-12:focus{
  --transform-rotate: 12deg
}

.focus\:tw-rotate-45:focus{
  --transform-rotate: 45deg
}

.focus\:tw-rotate-90:focus{
  --transform-rotate: 90deg
}

.focus\:tw-rotate-180:focus{
  --transform-rotate: 180deg
}

.focus\:tw--rotate-180:focus{
  --transform-rotate: -180deg
}

.focus\:tw--rotate-90:focus{
  --transform-rotate: -90deg
}

.focus\:tw--rotate-45:focus{
  --transform-rotate: -45deg
}

.focus\:tw--rotate-12:focus{
  --transform-rotate: -12deg
}

.focus\:tw--rotate-6:focus{
  --transform-rotate: -6deg
}

.focus\:tw--rotate-3:focus{
  --transform-rotate: -3deg
}

.focus\:tw--rotate-2:focus{
  --transform-rotate: -2deg
}

.focus\:tw--rotate-1:focus{
  --transform-rotate: -1deg
}

.tw-translate-x-0{
  --transform-translate-x: 0
}

.tw-translate-x-1{
  --transform-translate-x: 0.25rem
}

.tw-translate-x-2{
  --transform-translate-x: 0.5rem
}

.tw-translate-x-3{
  --transform-translate-x: 0.75rem
}

.tw-translate-x-4{
  --transform-translate-x: 1rem
}

.tw-translate-x-5{
  --transform-translate-x: 1.25rem
}

.tw-translate-x-6{
  --transform-translate-x: 1.5rem
}

.tw-translate-x-8{
  --transform-translate-x: 2rem
}

.tw-translate-x-10{
  --transform-translate-x: 2.5rem
}

.tw-translate-x-12{
  --transform-translate-x: 3rem
}

.tw-translate-x-16{
  --transform-translate-x: 4rem
}

.tw-translate-x-20{
  --transform-translate-x: 5rem
}

.tw-translate-x-24{
  --transform-translate-x: 6rem
}

.tw-translate-x-32{
  --transform-translate-x: 8rem
}

.tw-translate-x-40{
  --transform-translate-x: 10rem
}

.tw-translate-x-48{
  --transform-translate-x: 12rem
}

.tw-translate-x-56{
  --transform-translate-x: 14rem
}

.tw-translate-x-64{
  --transform-translate-x: 16rem
}

.tw-translate-x-px{
  --transform-translate-x: 1px
}

.tw--translate-x-1{
  --transform-translate-x: -0.25rem
}

.tw--translate-x-2{
  --transform-translate-x: -0.5rem
}

.tw--translate-x-3{
  --transform-translate-x: -0.75rem
}

.tw--translate-x-4{
  --transform-translate-x: -1rem
}

.tw--translate-x-5{
  --transform-translate-x: -1.25rem
}

.tw--translate-x-6{
  --transform-translate-x: -1.5rem
}

.tw--translate-x-8{
  --transform-translate-x: -2rem
}

.tw--translate-x-10{
  --transform-translate-x: -2.5rem
}

.tw--translate-x-12{
  --transform-translate-x: -3rem
}

.tw--translate-x-16{
  --transform-translate-x: -4rem
}

.tw--translate-x-20{
  --transform-translate-x: -5rem
}

.tw--translate-x-24{
  --transform-translate-x: -6rem
}

.tw--translate-x-32{
  --transform-translate-x: -8rem
}

.tw--translate-x-40{
  --transform-translate-x: -10rem
}

.tw--translate-x-48{
  --transform-translate-x: -12rem
}

.tw--translate-x-56{
  --transform-translate-x: -14rem
}

.tw--translate-x-64{
  --transform-translate-x: -16rem
}

.tw--translate-x-px{
  --transform-translate-x: -1px
}

.tw--translate-x-full{
  --transform-translate-x: -100%
}

.tw--translate-x-1\/2{
  --transform-translate-x: -50%
}

.tw-translate-x-1\/2{
  --transform-translate-x: 50%
}

.tw-translate-x-full{
  --transform-translate-x: 100%
}

.tw-translate-y-0{
  --transform-translate-y: 0
}

.tw-translate-y-1{
  --transform-translate-y: 0.25rem
}

.tw-translate-y-2{
  --transform-translate-y: 0.5rem
}

.tw-translate-y-3{
  --transform-translate-y: 0.75rem
}

.tw-translate-y-4{
  --transform-translate-y: 1rem
}

.tw-translate-y-5{
  --transform-translate-y: 1.25rem
}

.tw-translate-y-6{
  --transform-translate-y: 1.5rem
}

.tw-translate-y-8{
  --transform-translate-y: 2rem
}

.tw-translate-y-10{
  --transform-translate-y: 2.5rem
}

.tw-translate-y-12{
  --transform-translate-y: 3rem
}

.tw-translate-y-16{
  --transform-translate-y: 4rem
}

.tw-translate-y-20{
  --transform-translate-y: 5rem
}

.tw-translate-y-24{
  --transform-translate-y: 6rem
}

.tw-translate-y-32{
  --transform-translate-y: 8rem
}

.tw-translate-y-40{
  --transform-translate-y: 10rem
}

.tw-translate-y-48{
  --transform-translate-y: 12rem
}

.tw-translate-y-56{
  --transform-translate-y: 14rem
}

.tw-translate-y-64{
  --transform-translate-y: 16rem
}

.tw-translate-y-px{
  --transform-translate-y: 1px
}

.tw--translate-y-1{
  --transform-translate-y: -0.25rem
}

.tw--translate-y-2{
  --transform-translate-y: -0.5rem
}

.tw--translate-y-3{
  --transform-translate-y: -0.75rem
}

.tw--translate-y-4{
  --transform-translate-y: -1rem
}

.tw--translate-y-5{
  --transform-translate-y: -1.25rem
}

.tw--translate-y-6{
  --transform-translate-y: -1.5rem
}

.tw--translate-y-8{
  --transform-translate-y: -2rem
}

.tw--translate-y-10{
  --transform-translate-y: -2.5rem
}

.tw--translate-y-12{
  --transform-translate-y: -3rem
}

.tw--translate-y-16{
  --transform-translate-y: -4rem
}

.tw--translate-y-20{
  --transform-translate-y: -5rem
}

.tw--translate-y-24{
  --transform-translate-y: -6rem
}

.tw--translate-y-32{
  --transform-translate-y: -8rem
}

.tw--translate-y-40{
  --transform-translate-y: -10rem
}

.tw--translate-y-48{
  --transform-translate-y: -12rem
}

.tw--translate-y-56{
  --transform-translate-y: -14rem
}

.tw--translate-y-64{
  --transform-translate-y: -16rem
}

.tw--translate-y-px{
  --transform-translate-y: -1px
}

.tw--translate-y-full{
  --transform-translate-y: -100%
}

.tw--translate-y-1\/2{
  --transform-translate-y: -50%
}

.tw-translate-y-1\/2{
  --transform-translate-y: 50%
}

.tw-translate-y-full{
  --transform-translate-y: 100%
}

.hover\:tw-translate-x-0:hover{
  --transform-translate-x: 0
}

.hover\:tw-translate-x-1:hover{
  --transform-translate-x: 0.25rem
}

.hover\:tw-translate-x-2:hover{
  --transform-translate-x: 0.5rem
}

.hover\:tw-translate-x-3:hover{
  --transform-translate-x: 0.75rem
}

.hover\:tw-translate-x-4:hover{
  --transform-translate-x: 1rem
}

.hover\:tw-translate-x-5:hover{
  --transform-translate-x: 1.25rem
}

.hover\:tw-translate-x-6:hover{
  --transform-translate-x: 1.5rem
}

.hover\:tw-translate-x-8:hover{
  --transform-translate-x: 2rem
}

.hover\:tw-translate-x-10:hover{
  --transform-translate-x: 2.5rem
}

.hover\:tw-translate-x-12:hover{
  --transform-translate-x: 3rem
}

.hover\:tw-translate-x-16:hover{
  --transform-translate-x: 4rem
}

.hover\:tw-translate-x-20:hover{
  --transform-translate-x: 5rem
}

.hover\:tw-translate-x-24:hover{
  --transform-translate-x: 6rem
}

.hover\:tw-translate-x-32:hover{
  --transform-translate-x: 8rem
}

.hover\:tw-translate-x-40:hover{
  --transform-translate-x: 10rem
}

.hover\:tw-translate-x-48:hover{
  --transform-translate-x: 12rem
}

.hover\:tw-translate-x-56:hover{
  --transform-translate-x: 14rem
}

.hover\:tw-translate-x-64:hover{
  --transform-translate-x: 16rem
}

.hover\:tw-translate-x-px:hover{
  --transform-translate-x: 1px
}

.hover\:tw--translate-x-1:hover{
  --transform-translate-x: -0.25rem
}

.hover\:tw--translate-x-2:hover{
  --transform-translate-x: -0.5rem
}

.hover\:tw--translate-x-3:hover{
  --transform-translate-x: -0.75rem
}

.hover\:tw--translate-x-4:hover{
  --transform-translate-x: -1rem
}

.hover\:tw--translate-x-5:hover{
  --transform-translate-x: -1.25rem
}

.hover\:tw--translate-x-6:hover{
  --transform-translate-x: -1.5rem
}

.hover\:tw--translate-x-8:hover{
  --transform-translate-x: -2rem
}

.hover\:tw--translate-x-10:hover{
  --transform-translate-x: -2.5rem
}

.hover\:tw--translate-x-12:hover{
  --transform-translate-x: -3rem
}

.hover\:tw--translate-x-16:hover{
  --transform-translate-x: -4rem
}

.hover\:tw--translate-x-20:hover{
  --transform-translate-x: -5rem
}

.hover\:tw--translate-x-24:hover{
  --transform-translate-x: -6rem
}

.hover\:tw--translate-x-32:hover{
  --transform-translate-x: -8rem
}

.hover\:tw--translate-x-40:hover{
  --transform-translate-x: -10rem
}

.hover\:tw--translate-x-48:hover{
  --transform-translate-x: -12rem
}

.hover\:tw--translate-x-56:hover{
  --transform-translate-x: -14rem
}

.hover\:tw--translate-x-64:hover{
  --transform-translate-x: -16rem
}

.hover\:tw--translate-x-px:hover{
  --transform-translate-x: -1px
}

.hover\:tw--translate-x-full:hover{
  --transform-translate-x: -100%
}

.hover\:tw--translate-x-1\/2:hover{
  --transform-translate-x: -50%
}

.hover\:tw-translate-x-1\/2:hover{
  --transform-translate-x: 50%
}

.hover\:tw-translate-x-full:hover{
  --transform-translate-x: 100%
}

.hover\:tw-translate-y-0:hover{
  --transform-translate-y: 0
}

.hover\:tw-translate-y-1:hover{
  --transform-translate-y: 0.25rem
}

.hover\:tw-translate-y-2:hover{
  --transform-translate-y: 0.5rem
}

.hover\:tw-translate-y-3:hover{
  --transform-translate-y: 0.75rem
}

.hover\:tw-translate-y-4:hover{
  --transform-translate-y: 1rem
}

.hover\:tw-translate-y-5:hover{
  --transform-translate-y: 1.25rem
}

.hover\:tw-translate-y-6:hover{
  --transform-translate-y: 1.5rem
}

.hover\:tw-translate-y-8:hover{
  --transform-translate-y: 2rem
}

.hover\:tw-translate-y-10:hover{
  --transform-translate-y: 2.5rem
}

.hover\:tw-translate-y-12:hover{
  --transform-translate-y: 3rem
}

.hover\:tw-translate-y-16:hover{
  --transform-translate-y: 4rem
}

.hover\:tw-translate-y-20:hover{
  --transform-translate-y: 5rem
}

.hover\:tw-translate-y-24:hover{
  --transform-translate-y: 6rem
}

.hover\:tw-translate-y-32:hover{
  --transform-translate-y: 8rem
}

.hover\:tw-translate-y-40:hover{
  --transform-translate-y: 10rem
}

.hover\:tw-translate-y-48:hover{
  --transform-translate-y: 12rem
}

.hover\:tw-translate-y-56:hover{
  --transform-translate-y: 14rem
}

.hover\:tw-translate-y-64:hover{
  --transform-translate-y: 16rem
}

.hover\:tw-translate-y-px:hover{
  --transform-translate-y: 1px
}

.hover\:tw--translate-y-1:hover{
  --transform-translate-y: -0.25rem
}

.hover\:tw--translate-y-2:hover{
  --transform-translate-y: -0.5rem
}

.hover\:tw--translate-y-3:hover{
  --transform-translate-y: -0.75rem
}

.hover\:tw--translate-y-4:hover{
  --transform-translate-y: -1rem
}

.hover\:tw--translate-y-5:hover{
  --transform-translate-y: -1.25rem
}

.hover\:tw--translate-y-6:hover{
  --transform-translate-y: -1.5rem
}

.hover\:tw--translate-y-8:hover{
  --transform-translate-y: -2rem
}

.hover\:tw--translate-y-10:hover{
  --transform-translate-y: -2.5rem
}

.hover\:tw--translate-y-12:hover{
  --transform-translate-y: -3rem
}

.hover\:tw--translate-y-16:hover{
  --transform-translate-y: -4rem
}

.hover\:tw--translate-y-20:hover{
  --transform-translate-y: -5rem
}

.hover\:tw--translate-y-24:hover{
  --transform-translate-y: -6rem
}

.hover\:tw--translate-y-32:hover{
  --transform-translate-y: -8rem
}

.hover\:tw--translate-y-40:hover{
  --transform-translate-y: -10rem
}

.hover\:tw--translate-y-48:hover{
  --transform-translate-y: -12rem
}

.hover\:tw--translate-y-56:hover{
  --transform-translate-y: -14rem
}

.hover\:tw--translate-y-64:hover{
  --transform-translate-y: -16rem
}

.hover\:tw--translate-y-px:hover{
  --transform-translate-y: -1px
}

.hover\:tw--translate-y-full:hover{
  --transform-translate-y: -100%
}

.hover\:tw--translate-y-1\/2:hover{
  --transform-translate-y: -50%
}

.hover\:tw-translate-y-1\/2:hover{
  --transform-translate-y: 50%
}

.hover\:tw-translate-y-full:hover{
  --transform-translate-y: 100%
}

.focus\:tw-translate-x-0:focus{
  --transform-translate-x: 0
}

.focus\:tw-translate-x-1:focus{
  --transform-translate-x: 0.25rem
}

.focus\:tw-translate-x-2:focus{
  --transform-translate-x: 0.5rem
}

.focus\:tw-translate-x-3:focus{
  --transform-translate-x: 0.75rem
}

.focus\:tw-translate-x-4:focus{
  --transform-translate-x: 1rem
}

.focus\:tw-translate-x-5:focus{
  --transform-translate-x: 1.25rem
}

.focus\:tw-translate-x-6:focus{
  --transform-translate-x: 1.5rem
}

.focus\:tw-translate-x-8:focus{
  --transform-translate-x: 2rem
}

.focus\:tw-translate-x-10:focus{
  --transform-translate-x: 2.5rem
}

.focus\:tw-translate-x-12:focus{
  --transform-translate-x: 3rem
}

.focus\:tw-translate-x-16:focus{
  --transform-translate-x: 4rem
}

.focus\:tw-translate-x-20:focus{
  --transform-translate-x: 5rem
}

.focus\:tw-translate-x-24:focus{
  --transform-translate-x: 6rem
}

.focus\:tw-translate-x-32:focus{
  --transform-translate-x: 8rem
}

.focus\:tw-translate-x-40:focus{
  --transform-translate-x: 10rem
}

.focus\:tw-translate-x-48:focus{
  --transform-translate-x: 12rem
}

.focus\:tw-translate-x-56:focus{
  --transform-translate-x: 14rem
}

.focus\:tw-translate-x-64:focus{
  --transform-translate-x: 16rem
}

.focus\:tw-translate-x-px:focus{
  --transform-translate-x: 1px
}

.focus\:tw--translate-x-1:focus{
  --transform-translate-x: -0.25rem
}

.focus\:tw--translate-x-2:focus{
  --transform-translate-x: -0.5rem
}

.focus\:tw--translate-x-3:focus{
  --transform-translate-x: -0.75rem
}

.focus\:tw--translate-x-4:focus{
  --transform-translate-x: -1rem
}

.focus\:tw--translate-x-5:focus{
  --transform-translate-x: -1.25rem
}

.focus\:tw--translate-x-6:focus{
  --transform-translate-x: -1.5rem
}

.focus\:tw--translate-x-8:focus{
  --transform-translate-x: -2rem
}

.focus\:tw--translate-x-10:focus{
  --transform-translate-x: -2.5rem
}

.focus\:tw--translate-x-12:focus{
  --transform-translate-x: -3rem
}

.focus\:tw--translate-x-16:focus{
  --transform-translate-x: -4rem
}

.focus\:tw--translate-x-20:focus{
  --transform-translate-x: -5rem
}

.focus\:tw--translate-x-24:focus{
  --transform-translate-x: -6rem
}

.focus\:tw--translate-x-32:focus{
  --transform-translate-x: -8rem
}

.focus\:tw--translate-x-40:focus{
  --transform-translate-x: -10rem
}

.focus\:tw--translate-x-48:focus{
  --transform-translate-x: -12rem
}

.focus\:tw--translate-x-56:focus{
  --transform-translate-x: -14rem
}

.focus\:tw--translate-x-64:focus{
  --transform-translate-x: -16rem
}

.focus\:tw--translate-x-px:focus{
  --transform-translate-x: -1px
}

.focus\:tw--translate-x-full:focus{
  --transform-translate-x: -100%
}

.focus\:tw--translate-x-1\/2:focus{
  --transform-translate-x: -50%
}

.focus\:tw-translate-x-1\/2:focus{
  --transform-translate-x: 50%
}

.focus\:tw-translate-x-full:focus{
  --transform-translate-x: 100%
}

.focus\:tw-translate-y-0:focus{
  --transform-translate-y: 0
}

.focus\:tw-translate-y-1:focus{
  --transform-translate-y: 0.25rem
}

.focus\:tw-translate-y-2:focus{
  --transform-translate-y: 0.5rem
}

.focus\:tw-translate-y-3:focus{
  --transform-translate-y: 0.75rem
}

.focus\:tw-translate-y-4:focus{
  --transform-translate-y: 1rem
}

.focus\:tw-translate-y-5:focus{
  --transform-translate-y: 1.25rem
}

.focus\:tw-translate-y-6:focus{
  --transform-translate-y: 1.5rem
}

.focus\:tw-translate-y-8:focus{
  --transform-translate-y: 2rem
}

.focus\:tw-translate-y-10:focus{
  --transform-translate-y: 2.5rem
}

.focus\:tw-translate-y-12:focus{
  --transform-translate-y: 3rem
}

.focus\:tw-translate-y-16:focus{
  --transform-translate-y: 4rem
}

.focus\:tw-translate-y-20:focus{
  --transform-translate-y: 5rem
}

.focus\:tw-translate-y-24:focus{
  --transform-translate-y: 6rem
}

.focus\:tw-translate-y-32:focus{
  --transform-translate-y: 8rem
}

.focus\:tw-translate-y-40:focus{
  --transform-translate-y: 10rem
}

.focus\:tw-translate-y-48:focus{
  --transform-translate-y: 12rem
}

.focus\:tw-translate-y-56:focus{
  --transform-translate-y: 14rem
}

.focus\:tw-translate-y-64:focus{
  --transform-translate-y: 16rem
}

.focus\:tw-translate-y-px:focus{
  --transform-translate-y: 1px
}

.focus\:tw--translate-y-1:focus{
  --transform-translate-y: -0.25rem
}

.focus\:tw--translate-y-2:focus{
  --transform-translate-y: -0.5rem
}

.focus\:tw--translate-y-3:focus{
  --transform-translate-y: -0.75rem
}

.focus\:tw--translate-y-4:focus{
  --transform-translate-y: -1rem
}

.focus\:tw--translate-y-5:focus{
  --transform-translate-y: -1.25rem
}

.focus\:tw--translate-y-6:focus{
  --transform-translate-y: -1.5rem
}

.focus\:tw--translate-y-8:focus{
  --transform-translate-y: -2rem
}

.focus\:tw--translate-y-10:focus{
  --transform-translate-y: -2.5rem
}

.focus\:tw--translate-y-12:focus{
  --transform-translate-y: -3rem
}

.focus\:tw--translate-y-16:focus{
  --transform-translate-y: -4rem
}

.focus\:tw--translate-y-20:focus{
  --transform-translate-y: -5rem
}

.focus\:tw--translate-y-24:focus{
  --transform-translate-y: -6rem
}

.focus\:tw--translate-y-32:focus{
  --transform-translate-y: -8rem
}

.focus\:tw--translate-y-40:focus{
  --transform-translate-y: -10rem
}

.focus\:tw--translate-y-48:focus{
  --transform-translate-y: -12rem
}

.focus\:tw--translate-y-56:focus{
  --transform-translate-y: -14rem
}

.focus\:tw--translate-y-64:focus{
  --transform-translate-y: -16rem
}

.focus\:tw--translate-y-px:focus{
  --transform-translate-y: -1px
}

.focus\:tw--translate-y-full:focus{
  --transform-translate-y: -100%
}

.focus\:tw--translate-y-1\/2:focus{
  --transform-translate-y: -50%
}

.focus\:tw-translate-y-1\/2:focus{
  --transform-translate-y: 50%
}

.focus\:tw-translate-y-full:focus{
  --transform-translate-y: 100%
}

.tw-skew-x-0{
  --transform-skew-x: 0
}

.tw-skew-x-1{
  --transform-skew-x: 1deg
}

.tw-skew-x-2{
  --transform-skew-x: 2deg
}

.tw-skew-x-3{
  --transform-skew-x: 3deg
}

.tw-skew-x-6{
  --transform-skew-x: 6deg
}

.tw-skew-x-12{
  --transform-skew-x: 12deg
}

.tw--skew-x-12{
  --transform-skew-x: -12deg
}

.tw--skew-x-6{
  --transform-skew-x: -6deg
}

.tw--skew-x-3{
  --transform-skew-x: -3deg
}

.tw--skew-x-2{
  --transform-skew-x: -2deg
}

.tw--skew-x-1{
  --transform-skew-x: -1deg
}

.tw-skew-y-0{
  --transform-skew-y: 0
}

.tw-skew-y-1{
  --transform-skew-y: 1deg
}

.tw-skew-y-2{
  --transform-skew-y: 2deg
}

.tw-skew-y-3{
  --transform-skew-y: 3deg
}

.tw-skew-y-6{
  --transform-skew-y: 6deg
}

.tw-skew-y-12{
  --transform-skew-y: 12deg
}

.tw--skew-y-12{
  --transform-skew-y: -12deg
}

.tw--skew-y-6{
  --transform-skew-y: -6deg
}

.tw--skew-y-3{
  --transform-skew-y: -3deg
}

.tw--skew-y-2{
  --transform-skew-y: -2deg
}

.tw--skew-y-1{
  --transform-skew-y: -1deg
}

.hover\:tw-skew-x-0:hover{
  --transform-skew-x: 0
}

.hover\:tw-skew-x-1:hover{
  --transform-skew-x: 1deg
}

.hover\:tw-skew-x-2:hover{
  --transform-skew-x: 2deg
}

.hover\:tw-skew-x-3:hover{
  --transform-skew-x: 3deg
}

.hover\:tw-skew-x-6:hover{
  --transform-skew-x: 6deg
}

.hover\:tw-skew-x-12:hover{
  --transform-skew-x: 12deg
}

.hover\:tw--skew-x-12:hover{
  --transform-skew-x: -12deg
}

.hover\:tw--skew-x-6:hover{
  --transform-skew-x: -6deg
}

.hover\:tw--skew-x-3:hover{
  --transform-skew-x: -3deg
}

.hover\:tw--skew-x-2:hover{
  --transform-skew-x: -2deg
}

.hover\:tw--skew-x-1:hover{
  --transform-skew-x: -1deg
}

.hover\:tw-skew-y-0:hover{
  --transform-skew-y: 0
}

.hover\:tw-skew-y-1:hover{
  --transform-skew-y: 1deg
}

.hover\:tw-skew-y-2:hover{
  --transform-skew-y: 2deg
}

.hover\:tw-skew-y-3:hover{
  --transform-skew-y: 3deg
}

.hover\:tw-skew-y-6:hover{
  --transform-skew-y: 6deg
}

.hover\:tw-skew-y-12:hover{
  --transform-skew-y: 12deg
}

.hover\:tw--skew-y-12:hover{
  --transform-skew-y: -12deg
}

.hover\:tw--skew-y-6:hover{
  --transform-skew-y: -6deg
}

.hover\:tw--skew-y-3:hover{
  --transform-skew-y: -3deg
}

.hover\:tw--skew-y-2:hover{
  --transform-skew-y: -2deg
}

.hover\:tw--skew-y-1:hover{
  --transform-skew-y: -1deg
}

.focus\:tw-skew-x-0:focus{
  --transform-skew-x: 0
}

.focus\:tw-skew-x-1:focus{
  --transform-skew-x: 1deg
}

.focus\:tw-skew-x-2:focus{
  --transform-skew-x: 2deg
}

.focus\:tw-skew-x-3:focus{
  --transform-skew-x: 3deg
}

.focus\:tw-skew-x-6:focus{
  --transform-skew-x: 6deg
}

.focus\:tw-skew-x-12:focus{
  --transform-skew-x: 12deg
}

.focus\:tw--skew-x-12:focus{
  --transform-skew-x: -12deg
}

.focus\:tw--skew-x-6:focus{
  --transform-skew-x: -6deg
}

.focus\:tw--skew-x-3:focus{
  --transform-skew-x: -3deg
}

.focus\:tw--skew-x-2:focus{
  --transform-skew-x: -2deg
}

.focus\:tw--skew-x-1:focus{
  --transform-skew-x: -1deg
}

.focus\:tw-skew-y-0:focus{
  --transform-skew-y: 0
}

.focus\:tw-skew-y-1:focus{
  --transform-skew-y: 1deg
}

.focus\:tw-skew-y-2:focus{
  --transform-skew-y: 2deg
}

.focus\:tw-skew-y-3:focus{
  --transform-skew-y: 3deg
}

.focus\:tw-skew-y-6:focus{
  --transform-skew-y: 6deg
}

.focus\:tw-skew-y-12:focus{
  --transform-skew-y: 12deg
}

.focus\:tw--skew-y-12:focus{
  --transform-skew-y: -12deg
}

.focus\:tw--skew-y-6:focus{
  --transform-skew-y: -6deg
}

.focus\:tw--skew-y-3:focus{
  --transform-skew-y: -3deg
}

.focus\:tw--skew-y-2:focus{
  --transform-skew-y: -2deg
}

.focus\:tw--skew-y-1:focus{
  --transform-skew-y: -1deg
}

.tw-transition-none{
  -webkit-transition-property: none;
  transition-property: none
}

.tw-transition-all{
  -webkit-transition-property: all;
  transition-property: all
}

.tw-transition{
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

.tw-transition-colors{
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke
}

.tw-transition-opacity{
  -webkit-transition-property: opacity;
  transition-property: opacity
}

.tw-transition-shadow{
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow
}

.tw-transition-transform{
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform
}

.tw-ease-linear{
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear
}

.tw-ease-in{
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.tw-ease-out{
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.tw-ease-in-out{
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.tw-duration-75{
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms
}

.tw-duration-100{
  -webkit-transition-duration: 100ms;
          transition-duration: 100ms
}

.tw-duration-150{
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms
}

.tw-duration-200{
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms
}

.tw-duration-300{
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms
}

.tw-duration-500{
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms
}

.tw-duration-700{
  -webkit-transition-duration: 700ms;
          transition-duration: 700ms
}

.tw-duration-1000{
  -webkit-transition-duration: 1000ms;
          transition-duration: 1000ms
}

.tw-delay-75{
  -webkit-transition-delay: 75ms;
          transition-delay: 75ms
}

.tw-delay-100{
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms
}

.tw-delay-150{
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms
}

.tw-delay-200{
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms
}

.tw-delay-300{
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms
}

.tw-delay-500{
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms
}

.tw-delay-700{
  -webkit-transition-delay: 700ms;
          transition-delay: 700ms
}

.tw-delay-1000{
  -webkit-transition-delay: 1000ms;
          transition-delay: 1000ms
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5
  }
}

@keyframes pulse{
  50%{
    opacity: .5
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.tw-animate-none{
  -webkit-animation: none;
          animation: none
}

.tw-animate-spin{
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite
}

.tw-animate-ping{
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

.tw-animate-pulse{
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.tw-animate-bounce{
  -webkit-animation: bounce 1s infinite;
          animation: bounce 1s infinite
}

.has-error .form-control,.has-error .react-select__control{
  border-color:#dc3545
}

.has-error .control-label,.has-error .form-check-label,.has-error .form-text{
  color:#dc3545 !important
}

.react-datepicker-wrapper{
  display:block
}

.react-datepicker__input-container{
  display:block;
  position:relative
}

html,body{
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity))
}

.popover--lg .popover{
  max-width:552px
}

.react-datepicker-popper{
  z-index:100 !important
}

.rc-tree li .rc-tree-node-content-wrapper{
  height:25px
}

.plain-ul{
  padding-left: 0;
  list-style-type: none
}

@media (min-width: 640px){
  .sm\:tw-container{
    width: 100%
  }

  @media (min-width: 640px){
    .sm\:tw-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .sm\:tw-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .sm\:tw-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .sm\:tw-container{
      max-width: 1280px
    }
  }

  .sm\:tw-space-y-0 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .sm\:tw-space-x-0 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .sm\:tw-space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .sm\:tw-space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .sm\:tw--space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw--space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .sm\:tw--space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:tw-space-y-reverse > :not(template) ~ :not(template){
    --space-y-reverse: 1
  }

  .sm\:tw-space-x-reverse > :not(template) ~ :not(template){
    --space-x-reverse: 1
  }

  .sm\:tw-divide-y-0 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .sm\:tw-divide-x-0 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:tw-divide-y-2 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .sm\:tw-divide-x-2 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:tw-divide-y-4 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .sm\:tw-divide-x-4 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:tw-divide-y-8 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .sm\:tw-divide-x-8 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:tw-divide-y > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .sm\:tw-divide-x > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:tw-divide-y-reverse > :not(template) ~ :not(template){
    --divide-y-reverse: 1
  }

  .sm\:tw-divide-x-reverse > :not(template) ~ :not(template){
    --divide-x-reverse: 1
  }

  .sm\:tw-divide-transparent > :not(template) ~ :not(template){
    border-color: transparent
  }

  .sm\:tw-divide-tlg-green-dark > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--divide-opacity))
  }

  .sm\:tw-divide-tlg-green > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--divide-opacity))
  }

  .sm\:tw-divide-tlg-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--divide-opacity))
  }

  .sm\:tw-divide-black > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .sm\:tw-divide-white > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .sm\:tw-divide-gray-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .sm\:tw-divide-red-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .sm\:tw-divide-red-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .sm\:tw-divide-red-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .sm\:tw-divide-red-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .sm\:tw-divide-red-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .sm\:tw-divide-red-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .sm\:tw-divide-red-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .sm\:tw-divide-red-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .sm\:tw-divide-red-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .sm\:tw-divide-orange-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .sm\:tw-divide-yellow-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .sm\:tw-divide-green-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .sm\:tw-divide-green-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .sm\:tw-divide-green-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .sm\:tw-divide-green-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .sm\:tw-divide-green-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .sm\:tw-divide-green-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .sm\:tw-divide-green-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .sm\:tw-divide-green-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .sm\:tw-divide-green-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .sm\:tw-divide-teal-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .sm\:tw-divide-blue-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .sm\:tw-divide-indigo-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .sm\:tw-divide-purple-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .sm\:tw-divide-pink-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .sm\:tw-divide-solid > :not(template) ~ :not(template){
    border-style: solid
  }

  .sm\:tw-divide-dashed > :not(template) ~ :not(template){
    border-style: dashed
  }

  .sm\:tw-divide-dotted > :not(template) ~ :not(template){
    border-style: dotted
  }

  .sm\:tw-divide-double > :not(template) ~ :not(template){
    border-style: double
  }

  .sm\:tw-divide-none > :not(template) ~ :not(template){
    border-style: none
  }

  .sm\:tw-divide-opacity-0 > :not(template) ~ :not(template){
    --divide-opacity: 0
  }

  .sm\:tw-divide-opacity-25 > :not(template) ~ :not(template){
    --divide-opacity: 0.25
  }

  .sm\:tw-divide-opacity-50 > :not(template) ~ :not(template){
    --divide-opacity: 0.5
  }

  .sm\:tw-divide-opacity-75 > :not(template) ~ :not(template){
    --divide-opacity: 0.75
  }

  .sm\:tw-divide-opacity-100 > :not(template) ~ :not(template){
    --divide-opacity: 1
  }

  .sm\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:tw-appearance-none{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .sm\:tw-bg-fixed{
    background-attachment: fixed
  }

  .sm\:tw-bg-local{
    background-attachment: local
  }

  .sm\:tw-bg-scroll{
    background-attachment: scroll
  }

  .sm\:tw-bg-clip-border{
    background-clip: border-box
  }

  .sm\:tw-bg-clip-padding{
    background-clip: padding-box
  }

  .sm\:tw-bg-clip-content{
    background-clip: content-box
  }

  .sm\:tw-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  .sm\:tw-bg-transparent{
    background-color: transparent
  }

  .sm\:tw-bg-tlg-green-dark{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .sm\:tw-bg-tlg-green{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .sm\:tw-bg-tlg-orange-500{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .sm\:tw-bg-black{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:tw-bg-white{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-100{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-200{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-300{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-400{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-500{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-600{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-700{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-800{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .sm\:tw-bg-gray-900{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .sm\:tw-bg-red-100{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .sm\:tw-bg-red-200{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .sm\:tw-bg-red-300{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .sm\:tw-bg-red-400{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .sm\:tw-bg-red-500{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .sm\:tw-bg-red-600{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .sm\:tw-bg-red-700{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .sm\:tw-bg-red-800{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .sm\:tw-bg-red-900{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-100{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-200{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-300{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-400{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-500{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-600{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-700{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-800{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .sm\:tw-bg-orange-900{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-100{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-200{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-300{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-400{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-500{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-600{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-700{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-800{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .sm\:tw-bg-yellow-900{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .sm\:tw-bg-green-100{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .sm\:tw-bg-green-200{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .sm\:tw-bg-green-300{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .sm\:tw-bg-green-400{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .sm\:tw-bg-green-500{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .sm\:tw-bg-green-600{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .sm\:tw-bg-green-700{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .sm\:tw-bg-green-800{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .sm\:tw-bg-green-900{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-100{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-200{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-300{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-400{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-500{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-600{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-700{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-800{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .sm\:tw-bg-teal-900{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-100{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-200{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-300{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-400{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-500{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-600{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-700{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-800{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .sm\:tw-bg-blue-900{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-100{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-200{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-300{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-400{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-500{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-600{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-700{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-800{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .sm\:tw-bg-indigo-900{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-100{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-200{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-300{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-400{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-500{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-600{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-700{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-800{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .sm\:tw-bg-purple-900{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-100{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-200{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-300{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-400{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-500{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-600{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-700{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-800{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .sm\:tw-bg-pink-900{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-transparent:hover{
    background-color: transparent
  }

  .sm\:hover\:tw-bg-tlg-green-dark:hover{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-tlg-green:hover{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-tlg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-black:hover{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-white:hover{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-100:hover{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-200:hover{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-300:hover{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-400:hover{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-500:hover{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-600:hover{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-700:hover{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-800:hover{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-gray-900:hover{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-200:hover{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-300:hover{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-400:hover{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-500:hover{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-600:hover{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-700:hover{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-800:hover{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-red-900:hover{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-100:hover{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-200:hover{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-300:hover{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-400:hover{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-600:hover{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-700:hover{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-800:hover{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-orange-900:hover{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-100:hover{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-200:hover{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-300:hover{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-400:hover{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-500:hover{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-600:hover{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-700:hover{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-800:hover{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-yellow-900:hover{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-100:hover{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-200:hover{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-300:hover{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-400:hover{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-500:hover{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-600:hover{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-700:hover{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-800:hover{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-green-900:hover{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-100:hover{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-200:hover{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-300:hover{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-400:hover{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-500:hover{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-600:hover{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-700:hover{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-800:hover{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-teal-900:hover{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-100:hover{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-200:hover{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-300:hover{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-400:hover{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-500:hover{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-600:hover{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-700:hover{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-800:hover{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-blue-900:hover{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-100:hover{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-200:hover{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-300:hover{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-400:hover{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-500:hover{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-600:hover{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-700:hover{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-800:hover{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-indigo-900:hover{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-100:hover{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-200:hover{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-300:hover{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-400:hover{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-500:hover{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-600:hover{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-700:hover{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-800:hover{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-purple-900:hover{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-200:hover{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-300:hover{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-400:hover{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-500:hover{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-600:hover{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-700:hover{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-800:hover{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .sm\:hover\:tw-bg-pink-900:hover{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-transparent:focus{
    background-color: transparent
  }

  .sm\:focus\:tw-bg-tlg-green-dark:focus{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-tlg-green:focus{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-tlg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-black:focus{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-white:focus{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-100:focus{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-200:focus{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-300:focus{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-400:focus{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-500:focus{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-600:focus{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-700:focus{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-800:focus{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-gray-900:focus{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-200:focus{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-300:focus{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-400:focus{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-500:focus{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-600:focus{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-700:focus{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-800:focus{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-red-900:focus{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-100:focus{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-200:focus{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-300:focus{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-400:focus{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-600:focus{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-700:focus{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-800:focus{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-orange-900:focus{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-100:focus{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-200:focus{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-300:focus{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-400:focus{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-500:focus{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-600:focus{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-700:focus{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-800:focus{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-yellow-900:focus{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-100:focus{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-200:focus{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-300:focus{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-400:focus{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-500:focus{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-600:focus{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-700:focus{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-800:focus{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-green-900:focus{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-100:focus{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-200:focus{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-300:focus{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-400:focus{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-500:focus{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-600:focus{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-700:focus{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-800:focus{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-teal-900:focus{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-100:focus{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-200:focus{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-300:focus{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-400:focus{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-500:focus{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-600:focus{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-700:focus{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-800:focus{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-blue-900:focus{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-100:focus{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-200:focus{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-300:focus{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-400:focus{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-500:focus{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-600:focus{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-700:focus{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-800:focus{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-indigo-900:focus{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-100:focus{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-200:focus{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-300:focus{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-400:focus{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-500:focus{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-600:focus{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-700:focus{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-800:focus{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-purple-900:focus{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-200:focus{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-300:focus{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-400:focus{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-500:focus{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-600:focus{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-700:focus{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-800:focus{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .sm\:focus\:tw-bg-pink-900:focus{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .sm\:tw-bg-none{
    background-image: none
  }

  .sm\:tw-bg-gradient-to-t{
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom, var(--gradient-color-stops));
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-tr{
    background-image: -webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom left, var(--gradient-color-stops));
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-r{
    background-image: -webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(left, var(--gradient-color-stops));
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-br{
    background-image: -webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top left, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-b{
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-bl{
    background-image: -webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top right, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-l{
    background-image: -webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(right, var(--gradient-color-stops));
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .sm\:tw-bg-gradient-to-tl{
    background-image: -webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom right, var(--gradient-color-stops));
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .sm\:tw-from-transparent{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:tw-from-tlg-green-dark{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .sm\:tw-from-tlg-green{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .sm\:tw-from-tlg-orange-500{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .sm\:tw-from-black{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:tw-from-white{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:tw-from-gray-100{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:tw-from-gray-200{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:tw-from-gray-300{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:tw-from-gray-400{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:tw-from-gray-500{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:tw-from-gray-600{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:tw-from-gray-700{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:tw-from-gray-800{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:tw-from-gray-900{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:tw-from-red-100{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:tw-from-red-200{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:tw-from-red-300{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:tw-from-red-400{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:tw-from-red-500{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:tw-from-red-600{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:tw-from-red-700{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:tw-from-red-800{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:tw-from-red-900{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:tw-from-orange-100{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:tw-from-orange-200{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:tw-from-orange-300{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:tw-from-orange-400{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:tw-from-orange-500{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:tw-from-orange-600{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:tw-from-orange-700{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:tw-from-orange-800{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:tw-from-orange-900{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:tw-from-yellow-100{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:tw-from-yellow-200{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:tw-from-yellow-300{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:tw-from-yellow-400{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:tw-from-yellow-500{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:tw-from-yellow-600{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:tw-from-yellow-700{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:tw-from-yellow-800{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:tw-from-yellow-900{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:tw-from-green-100{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:tw-from-green-200{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:tw-from-green-300{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:tw-from-green-400{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:tw-from-green-500{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:tw-from-green-600{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:tw-from-green-700{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:tw-from-green-800{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:tw-from-green-900{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:tw-from-teal-100{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:tw-from-teal-200{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:tw-from-teal-300{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:tw-from-teal-400{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:tw-from-teal-500{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:tw-from-teal-600{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:tw-from-teal-700{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:tw-from-teal-800{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:tw-from-teal-900{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:tw-from-blue-100{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:tw-from-blue-200{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:tw-from-blue-300{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:tw-from-blue-400{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:tw-from-blue-500{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:tw-from-blue-600{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:tw-from-blue-700{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:tw-from-blue-800{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:tw-from-blue-900{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:tw-from-indigo-100{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:tw-from-indigo-200{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:tw-from-indigo-300{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:tw-from-indigo-400{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:tw-from-indigo-500{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:tw-from-indigo-600{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:tw-from-indigo-700{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:tw-from-indigo-800{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:tw-from-indigo-900{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:tw-from-purple-100{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:tw-from-purple-200{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:tw-from-purple-300{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:tw-from-purple-400{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:tw-from-purple-500{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:tw-from-purple-600{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:tw-from-purple-700{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:tw-from-purple-800{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:tw-from-purple-900{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:tw-from-pink-100{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:tw-from-pink-200{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:tw-from-pink-300{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:tw-from-pink-400{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:tw-from-pink-500{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:tw-from-pink-600{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:tw-from-pink-700{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:tw-from-pink-800{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:tw-from-pink-900{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:tw-via-transparent{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:tw-via-tlg-green-dark{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .sm\:tw-via-tlg-green{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .sm\:tw-via-tlg-orange-500{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .sm\:tw-via-black{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:tw-via-white{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:tw-via-gray-100{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:tw-via-gray-200{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:tw-via-gray-300{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:tw-via-gray-400{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:tw-via-gray-500{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:tw-via-gray-600{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:tw-via-gray-700{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:tw-via-gray-800{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:tw-via-gray-900{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:tw-via-red-100{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:tw-via-red-200{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:tw-via-red-300{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:tw-via-red-400{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:tw-via-red-500{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:tw-via-red-600{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:tw-via-red-700{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:tw-via-red-800{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:tw-via-red-900{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:tw-via-orange-100{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:tw-via-orange-200{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:tw-via-orange-300{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:tw-via-orange-400{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:tw-via-orange-500{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:tw-via-orange-600{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:tw-via-orange-700{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:tw-via-orange-800{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:tw-via-orange-900{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:tw-via-yellow-100{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:tw-via-yellow-200{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:tw-via-yellow-300{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:tw-via-yellow-400{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:tw-via-yellow-500{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:tw-via-yellow-600{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:tw-via-yellow-700{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:tw-via-yellow-800{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:tw-via-yellow-900{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:tw-via-green-100{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:tw-via-green-200{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:tw-via-green-300{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:tw-via-green-400{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:tw-via-green-500{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:tw-via-green-600{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:tw-via-green-700{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:tw-via-green-800{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:tw-via-green-900{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:tw-via-teal-100{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:tw-via-teal-200{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:tw-via-teal-300{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:tw-via-teal-400{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:tw-via-teal-500{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:tw-via-teal-600{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:tw-via-teal-700{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:tw-via-teal-800{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:tw-via-teal-900{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:tw-via-blue-100{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:tw-via-blue-200{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:tw-via-blue-300{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:tw-via-blue-400{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:tw-via-blue-500{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:tw-via-blue-600{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:tw-via-blue-700{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:tw-via-blue-800{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:tw-via-blue-900{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:tw-via-indigo-100{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:tw-via-indigo-200{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:tw-via-indigo-300{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:tw-via-indigo-400{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:tw-via-indigo-500{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:tw-via-indigo-600{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:tw-via-indigo-700{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:tw-via-indigo-800{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:tw-via-indigo-900{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:tw-via-purple-100{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:tw-via-purple-200{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:tw-via-purple-300{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:tw-via-purple-400{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:tw-via-purple-500{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:tw-via-purple-600{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:tw-via-purple-700{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:tw-via-purple-800{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:tw-via-purple-900{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:tw-via-pink-100{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:tw-via-pink-200{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:tw-via-pink-300{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:tw-via-pink-400{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:tw-via-pink-500{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:tw-via-pink-600{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:tw-via-pink-700{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:tw-via-pink-800{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:tw-via-pink-900{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:tw-to-transparent{
    --gradient-to-color: transparent
  }

  .sm\:tw-to-tlg-green-dark{
    --gradient-to-color: #76b84e
  }

  .sm\:tw-to-tlg-green{
    --gradient-to-color: #a4ca61
  }

  .sm\:tw-to-tlg-orange-500{
    --gradient-to-color: #ff7f41
  }

  .sm\:tw-to-black{
    --gradient-to-color: #000
  }

  .sm\:tw-to-white{
    --gradient-to-color: #fff
  }

  .sm\:tw-to-gray-100{
    --gradient-to-color: #f7fafc
  }

  .sm\:tw-to-gray-200{
    --gradient-to-color: #edf2f7
  }

  .sm\:tw-to-gray-300{
    --gradient-to-color: #e2e8f0
  }

  .sm\:tw-to-gray-400{
    --gradient-to-color: #cbd5e0
  }

  .sm\:tw-to-gray-500{
    --gradient-to-color: #a0aec0
  }

  .sm\:tw-to-gray-600{
    --gradient-to-color: #718096
  }

  .sm\:tw-to-gray-700{
    --gradient-to-color: #4a5568
  }

  .sm\:tw-to-gray-800{
    --gradient-to-color: #2d3748
  }

  .sm\:tw-to-gray-900{
    --gradient-to-color: #1a202c
  }

  .sm\:tw-to-red-100{
    --gradient-to-color: #fff5f5
  }

  .sm\:tw-to-red-200{
    --gradient-to-color: #fed7d7
  }

  .sm\:tw-to-red-300{
    --gradient-to-color: #feb2b2
  }

  .sm\:tw-to-red-400{
    --gradient-to-color: #fc8181
  }

  .sm\:tw-to-red-500{
    --gradient-to-color: #f56565
  }

  .sm\:tw-to-red-600{
    --gradient-to-color: #e53e3e
  }

  .sm\:tw-to-red-700{
    --gradient-to-color: #c53030
  }

  .sm\:tw-to-red-800{
    --gradient-to-color: #9b2c2c
  }

  .sm\:tw-to-red-900{
    --gradient-to-color: #742a2a
  }

  .sm\:tw-to-orange-100{
    --gradient-to-color: #fffaf0
  }

  .sm\:tw-to-orange-200{
    --gradient-to-color: #feebc8
  }

  .sm\:tw-to-orange-300{
    --gradient-to-color: #fbd38d
  }

  .sm\:tw-to-orange-400{
    --gradient-to-color: #f6ad55
  }

  .sm\:tw-to-orange-500{
    --gradient-to-color: #ed8936
  }

  .sm\:tw-to-orange-600{
    --gradient-to-color: #dd6b20
  }

  .sm\:tw-to-orange-700{
    --gradient-to-color: #c05621
  }

  .sm\:tw-to-orange-800{
    --gradient-to-color: #9c4221
  }

  .sm\:tw-to-orange-900{
    --gradient-to-color: #7b341e
  }

  .sm\:tw-to-yellow-100{
    --gradient-to-color: #fffff0
  }

  .sm\:tw-to-yellow-200{
    --gradient-to-color: #fefcbf
  }

  .sm\:tw-to-yellow-300{
    --gradient-to-color: #faf089
  }

  .sm\:tw-to-yellow-400{
    --gradient-to-color: #f6e05e
  }

  .sm\:tw-to-yellow-500{
    --gradient-to-color: #ecc94b
  }

  .sm\:tw-to-yellow-600{
    --gradient-to-color: #d69e2e
  }

  .sm\:tw-to-yellow-700{
    --gradient-to-color: #b7791f
  }

  .sm\:tw-to-yellow-800{
    --gradient-to-color: #975a16
  }

  .sm\:tw-to-yellow-900{
    --gradient-to-color: #744210
  }

  .sm\:tw-to-green-100{
    --gradient-to-color: #f0fff4
  }

  .sm\:tw-to-green-200{
    --gradient-to-color: #c6f6d5
  }

  .sm\:tw-to-green-300{
    --gradient-to-color: #9ae6b4
  }

  .sm\:tw-to-green-400{
    --gradient-to-color: #68d391
  }

  .sm\:tw-to-green-500{
    --gradient-to-color: #48bb78
  }

  .sm\:tw-to-green-600{
    --gradient-to-color: #38a169
  }

  .sm\:tw-to-green-700{
    --gradient-to-color: #2f855a
  }

  .sm\:tw-to-green-800{
    --gradient-to-color: #276749
  }

  .sm\:tw-to-green-900{
    --gradient-to-color: #22543d
  }

  .sm\:tw-to-teal-100{
    --gradient-to-color: #e6fffa
  }

  .sm\:tw-to-teal-200{
    --gradient-to-color: #b2f5ea
  }

  .sm\:tw-to-teal-300{
    --gradient-to-color: #81e6d9
  }

  .sm\:tw-to-teal-400{
    --gradient-to-color: #4fd1c5
  }

  .sm\:tw-to-teal-500{
    --gradient-to-color: #38b2ac
  }

  .sm\:tw-to-teal-600{
    --gradient-to-color: #319795
  }

  .sm\:tw-to-teal-700{
    --gradient-to-color: #2c7a7b
  }

  .sm\:tw-to-teal-800{
    --gradient-to-color: #285e61
  }

  .sm\:tw-to-teal-900{
    --gradient-to-color: #234e52
  }

  .sm\:tw-to-blue-100{
    --gradient-to-color: #ebf8ff
  }

  .sm\:tw-to-blue-200{
    --gradient-to-color: #bee3f8
  }

  .sm\:tw-to-blue-300{
    --gradient-to-color: #90cdf4
  }

  .sm\:tw-to-blue-400{
    --gradient-to-color: #63b3ed
  }

  .sm\:tw-to-blue-500{
    --gradient-to-color: #4299e1
  }

  .sm\:tw-to-blue-600{
    --gradient-to-color: #3182ce
  }

  .sm\:tw-to-blue-700{
    --gradient-to-color: #2b6cb0
  }

  .sm\:tw-to-blue-800{
    --gradient-to-color: #2c5282
  }

  .sm\:tw-to-blue-900{
    --gradient-to-color: #2a4365
  }

  .sm\:tw-to-indigo-100{
    --gradient-to-color: #ebf4ff
  }

  .sm\:tw-to-indigo-200{
    --gradient-to-color: #c3dafe
  }

  .sm\:tw-to-indigo-300{
    --gradient-to-color: #a3bffa
  }

  .sm\:tw-to-indigo-400{
    --gradient-to-color: #7f9cf5
  }

  .sm\:tw-to-indigo-500{
    --gradient-to-color: #667eea
  }

  .sm\:tw-to-indigo-600{
    --gradient-to-color: #5a67d8
  }

  .sm\:tw-to-indigo-700{
    --gradient-to-color: #4c51bf
  }

  .sm\:tw-to-indigo-800{
    --gradient-to-color: #434190
  }

  .sm\:tw-to-indigo-900{
    --gradient-to-color: #3c366b
  }

  .sm\:tw-to-purple-100{
    --gradient-to-color: #faf5ff
  }

  .sm\:tw-to-purple-200{
    --gradient-to-color: #e9d8fd
  }

  .sm\:tw-to-purple-300{
    --gradient-to-color: #d6bcfa
  }

  .sm\:tw-to-purple-400{
    --gradient-to-color: #b794f4
  }

  .sm\:tw-to-purple-500{
    --gradient-to-color: #9f7aea
  }

  .sm\:tw-to-purple-600{
    --gradient-to-color: #805ad5
  }

  .sm\:tw-to-purple-700{
    --gradient-to-color: #6b46c1
  }

  .sm\:tw-to-purple-800{
    --gradient-to-color: #553c9a
  }

  .sm\:tw-to-purple-900{
    --gradient-to-color: #44337a
  }

  .sm\:tw-to-pink-100{
    --gradient-to-color: #fff5f7
  }

  .sm\:tw-to-pink-200{
    --gradient-to-color: #fed7e2
  }

  .sm\:tw-to-pink-300{
    --gradient-to-color: #fbb6ce
  }

  .sm\:tw-to-pink-400{
    --gradient-to-color: #f687b3
  }

  .sm\:tw-to-pink-500{
    --gradient-to-color: #ed64a6
  }

  .sm\:tw-to-pink-600{
    --gradient-to-color: #d53f8c
  }

  .sm\:tw-to-pink-700{
    --gradient-to-color: #b83280
  }

  .sm\:tw-to-pink-800{
    --gradient-to-color: #97266d
  }

  .sm\:tw-to-pink-900{
    --gradient-to-color: #702459
  }

  .sm\:hover\:tw-from-transparent:hover{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:tw-from-tlg-green-dark:hover{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .sm\:hover\:tw-from-tlg-green:hover{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .sm\:hover\:tw-from-tlg-orange-500:hover{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .sm\:hover\:tw-from-black:hover{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:tw-from-white:hover{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:tw-from-gray-100:hover{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:hover\:tw-from-gray-200:hover{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:hover\:tw-from-gray-300:hover{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:hover\:tw-from-gray-400:hover{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:hover\:tw-from-gray-500:hover{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:hover\:tw-from-gray-600:hover{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:hover\:tw-from-gray-700:hover{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:hover\:tw-from-gray-800:hover{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:hover\:tw-from-gray-900:hover{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:hover\:tw-from-red-100:hover{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:hover\:tw-from-red-200:hover{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:hover\:tw-from-red-300:hover{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:hover\:tw-from-red-400:hover{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:hover\:tw-from-red-500:hover{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:hover\:tw-from-red-600:hover{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:hover\:tw-from-red-700:hover{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:hover\:tw-from-red-800:hover{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:hover\:tw-from-red-900:hover{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:hover\:tw-from-orange-100:hover{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:hover\:tw-from-orange-200:hover{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:hover\:tw-from-orange-300:hover{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:hover\:tw-from-orange-400:hover{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:hover\:tw-from-orange-500:hover{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:hover\:tw-from-orange-600:hover{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:hover\:tw-from-orange-700:hover{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:hover\:tw-from-orange-800:hover{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:hover\:tw-from-orange-900:hover{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:hover\:tw-from-yellow-100:hover{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:hover\:tw-from-yellow-200:hover{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:hover\:tw-from-yellow-300:hover{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:hover\:tw-from-yellow-400:hover{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:hover\:tw-from-yellow-500:hover{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:hover\:tw-from-yellow-600:hover{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:hover\:tw-from-yellow-700:hover{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:hover\:tw-from-yellow-800:hover{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:hover\:tw-from-yellow-900:hover{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:hover\:tw-from-green-100:hover{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:hover\:tw-from-green-200:hover{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:hover\:tw-from-green-300:hover{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:hover\:tw-from-green-400:hover{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:hover\:tw-from-green-500:hover{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:hover\:tw-from-green-600:hover{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:hover\:tw-from-green-700:hover{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:hover\:tw-from-green-800:hover{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:hover\:tw-from-green-900:hover{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:hover\:tw-from-teal-100:hover{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:hover\:tw-from-teal-200:hover{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:hover\:tw-from-teal-300:hover{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:hover\:tw-from-teal-400:hover{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:hover\:tw-from-teal-500:hover{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:hover\:tw-from-teal-600:hover{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:hover\:tw-from-teal-700:hover{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:hover\:tw-from-teal-800:hover{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:hover\:tw-from-teal-900:hover{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:hover\:tw-from-blue-100:hover{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:hover\:tw-from-blue-200:hover{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:hover\:tw-from-blue-300:hover{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:hover\:tw-from-blue-400:hover{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:hover\:tw-from-blue-500:hover{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:hover\:tw-from-blue-600:hover{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:hover\:tw-from-blue-700:hover{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:hover\:tw-from-blue-800:hover{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:hover\:tw-from-blue-900:hover{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:hover\:tw-from-indigo-100:hover{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:hover\:tw-from-indigo-200:hover{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:hover\:tw-from-indigo-300:hover{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:hover\:tw-from-indigo-400:hover{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:hover\:tw-from-indigo-500:hover{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:hover\:tw-from-indigo-600:hover{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:hover\:tw-from-indigo-700:hover{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:hover\:tw-from-indigo-800:hover{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:hover\:tw-from-indigo-900:hover{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:hover\:tw-from-purple-100:hover{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:hover\:tw-from-purple-200:hover{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:hover\:tw-from-purple-300:hover{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:hover\:tw-from-purple-400:hover{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:hover\:tw-from-purple-500:hover{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:hover\:tw-from-purple-600:hover{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:hover\:tw-from-purple-700:hover{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:hover\:tw-from-purple-800:hover{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:hover\:tw-from-purple-900:hover{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:hover\:tw-from-pink-100:hover{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:hover\:tw-from-pink-200:hover{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:hover\:tw-from-pink-300:hover{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:hover\:tw-from-pink-400:hover{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:hover\:tw-from-pink-500:hover{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:hover\:tw-from-pink-600:hover{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:hover\:tw-from-pink-700:hover{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:hover\:tw-from-pink-800:hover{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:hover\:tw-from-pink-900:hover{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:hover\:tw-via-transparent:hover{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:tw-via-tlg-green-dark:hover{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .sm\:hover\:tw-via-tlg-green:hover{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .sm\:hover\:tw-via-tlg-orange-500:hover{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .sm\:hover\:tw-via-black:hover{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:tw-via-white:hover{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:tw-via-gray-100:hover{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:hover\:tw-via-gray-200:hover{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:hover\:tw-via-gray-300:hover{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:hover\:tw-via-gray-400:hover{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:hover\:tw-via-gray-500:hover{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:hover\:tw-via-gray-600:hover{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:hover\:tw-via-gray-700:hover{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:hover\:tw-via-gray-800:hover{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:hover\:tw-via-gray-900:hover{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:hover\:tw-via-red-100:hover{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:hover\:tw-via-red-200:hover{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:hover\:tw-via-red-300:hover{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:hover\:tw-via-red-400:hover{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:hover\:tw-via-red-500:hover{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:hover\:tw-via-red-600:hover{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:hover\:tw-via-red-700:hover{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:hover\:tw-via-red-800:hover{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:hover\:tw-via-red-900:hover{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:hover\:tw-via-orange-100:hover{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:hover\:tw-via-orange-200:hover{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:hover\:tw-via-orange-300:hover{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:hover\:tw-via-orange-400:hover{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:hover\:tw-via-orange-500:hover{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:hover\:tw-via-orange-600:hover{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:hover\:tw-via-orange-700:hover{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:hover\:tw-via-orange-800:hover{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:hover\:tw-via-orange-900:hover{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:hover\:tw-via-yellow-100:hover{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:hover\:tw-via-yellow-200:hover{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:hover\:tw-via-yellow-300:hover{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:hover\:tw-via-yellow-400:hover{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:hover\:tw-via-yellow-500:hover{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:hover\:tw-via-yellow-600:hover{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:hover\:tw-via-yellow-700:hover{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:hover\:tw-via-yellow-800:hover{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:hover\:tw-via-yellow-900:hover{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:hover\:tw-via-green-100:hover{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:hover\:tw-via-green-200:hover{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:hover\:tw-via-green-300:hover{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:hover\:tw-via-green-400:hover{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:hover\:tw-via-green-500:hover{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:hover\:tw-via-green-600:hover{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:hover\:tw-via-green-700:hover{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:hover\:tw-via-green-800:hover{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:hover\:tw-via-green-900:hover{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:hover\:tw-via-teal-100:hover{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:hover\:tw-via-teal-200:hover{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:hover\:tw-via-teal-300:hover{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:hover\:tw-via-teal-400:hover{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:hover\:tw-via-teal-500:hover{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:hover\:tw-via-teal-600:hover{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:hover\:tw-via-teal-700:hover{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:hover\:tw-via-teal-800:hover{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:hover\:tw-via-teal-900:hover{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:hover\:tw-via-blue-100:hover{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:hover\:tw-via-blue-200:hover{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:hover\:tw-via-blue-300:hover{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:hover\:tw-via-blue-400:hover{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:hover\:tw-via-blue-500:hover{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:hover\:tw-via-blue-600:hover{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:hover\:tw-via-blue-700:hover{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:hover\:tw-via-blue-800:hover{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:hover\:tw-via-blue-900:hover{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:hover\:tw-via-indigo-100:hover{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:hover\:tw-via-indigo-200:hover{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:hover\:tw-via-indigo-300:hover{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:hover\:tw-via-indigo-400:hover{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:hover\:tw-via-indigo-500:hover{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:hover\:tw-via-indigo-600:hover{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:hover\:tw-via-indigo-700:hover{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:hover\:tw-via-indigo-800:hover{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:hover\:tw-via-indigo-900:hover{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:hover\:tw-via-purple-100:hover{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:hover\:tw-via-purple-200:hover{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:hover\:tw-via-purple-300:hover{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:hover\:tw-via-purple-400:hover{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:hover\:tw-via-purple-500:hover{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:hover\:tw-via-purple-600:hover{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:hover\:tw-via-purple-700:hover{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:hover\:tw-via-purple-800:hover{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:hover\:tw-via-purple-900:hover{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:hover\:tw-via-pink-100:hover{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:hover\:tw-via-pink-200:hover{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:hover\:tw-via-pink-300:hover{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:hover\:tw-via-pink-400:hover{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:hover\:tw-via-pink-500:hover{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:hover\:tw-via-pink-600:hover{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:hover\:tw-via-pink-700:hover{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:hover\:tw-via-pink-800:hover{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:hover\:tw-via-pink-900:hover{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:hover\:tw-to-transparent:hover{
    --gradient-to-color: transparent
  }

  .sm\:hover\:tw-to-tlg-green-dark:hover{
    --gradient-to-color: #76b84e
  }

  .sm\:hover\:tw-to-tlg-green:hover{
    --gradient-to-color: #a4ca61
  }

  .sm\:hover\:tw-to-tlg-orange-500:hover{
    --gradient-to-color: #ff7f41
  }

  .sm\:hover\:tw-to-black:hover{
    --gradient-to-color: #000
  }

  .sm\:hover\:tw-to-white:hover{
    --gradient-to-color: #fff
  }

  .sm\:hover\:tw-to-gray-100:hover{
    --gradient-to-color: #f7fafc
  }

  .sm\:hover\:tw-to-gray-200:hover{
    --gradient-to-color: #edf2f7
  }

  .sm\:hover\:tw-to-gray-300:hover{
    --gradient-to-color: #e2e8f0
  }

  .sm\:hover\:tw-to-gray-400:hover{
    --gradient-to-color: #cbd5e0
  }

  .sm\:hover\:tw-to-gray-500:hover{
    --gradient-to-color: #a0aec0
  }

  .sm\:hover\:tw-to-gray-600:hover{
    --gradient-to-color: #718096
  }

  .sm\:hover\:tw-to-gray-700:hover{
    --gradient-to-color: #4a5568
  }

  .sm\:hover\:tw-to-gray-800:hover{
    --gradient-to-color: #2d3748
  }

  .sm\:hover\:tw-to-gray-900:hover{
    --gradient-to-color: #1a202c
  }

  .sm\:hover\:tw-to-red-100:hover{
    --gradient-to-color: #fff5f5
  }

  .sm\:hover\:tw-to-red-200:hover{
    --gradient-to-color: #fed7d7
  }

  .sm\:hover\:tw-to-red-300:hover{
    --gradient-to-color: #feb2b2
  }

  .sm\:hover\:tw-to-red-400:hover{
    --gradient-to-color: #fc8181
  }

  .sm\:hover\:tw-to-red-500:hover{
    --gradient-to-color: #f56565
  }

  .sm\:hover\:tw-to-red-600:hover{
    --gradient-to-color: #e53e3e
  }

  .sm\:hover\:tw-to-red-700:hover{
    --gradient-to-color: #c53030
  }

  .sm\:hover\:tw-to-red-800:hover{
    --gradient-to-color: #9b2c2c
  }

  .sm\:hover\:tw-to-red-900:hover{
    --gradient-to-color: #742a2a
  }

  .sm\:hover\:tw-to-orange-100:hover{
    --gradient-to-color: #fffaf0
  }

  .sm\:hover\:tw-to-orange-200:hover{
    --gradient-to-color: #feebc8
  }

  .sm\:hover\:tw-to-orange-300:hover{
    --gradient-to-color: #fbd38d
  }

  .sm\:hover\:tw-to-orange-400:hover{
    --gradient-to-color: #f6ad55
  }

  .sm\:hover\:tw-to-orange-500:hover{
    --gradient-to-color: #ed8936
  }

  .sm\:hover\:tw-to-orange-600:hover{
    --gradient-to-color: #dd6b20
  }

  .sm\:hover\:tw-to-orange-700:hover{
    --gradient-to-color: #c05621
  }

  .sm\:hover\:tw-to-orange-800:hover{
    --gradient-to-color: #9c4221
  }

  .sm\:hover\:tw-to-orange-900:hover{
    --gradient-to-color: #7b341e
  }

  .sm\:hover\:tw-to-yellow-100:hover{
    --gradient-to-color: #fffff0
  }

  .sm\:hover\:tw-to-yellow-200:hover{
    --gradient-to-color: #fefcbf
  }

  .sm\:hover\:tw-to-yellow-300:hover{
    --gradient-to-color: #faf089
  }

  .sm\:hover\:tw-to-yellow-400:hover{
    --gradient-to-color: #f6e05e
  }

  .sm\:hover\:tw-to-yellow-500:hover{
    --gradient-to-color: #ecc94b
  }

  .sm\:hover\:tw-to-yellow-600:hover{
    --gradient-to-color: #d69e2e
  }

  .sm\:hover\:tw-to-yellow-700:hover{
    --gradient-to-color: #b7791f
  }

  .sm\:hover\:tw-to-yellow-800:hover{
    --gradient-to-color: #975a16
  }

  .sm\:hover\:tw-to-yellow-900:hover{
    --gradient-to-color: #744210
  }

  .sm\:hover\:tw-to-green-100:hover{
    --gradient-to-color: #f0fff4
  }

  .sm\:hover\:tw-to-green-200:hover{
    --gradient-to-color: #c6f6d5
  }

  .sm\:hover\:tw-to-green-300:hover{
    --gradient-to-color: #9ae6b4
  }

  .sm\:hover\:tw-to-green-400:hover{
    --gradient-to-color: #68d391
  }

  .sm\:hover\:tw-to-green-500:hover{
    --gradient-to-color: #48bb78
  }

  .sm\:hover\:tw-to-green-600:hover{
    --gradient-to-color: #38a169
  }

  .sm\:hover\:tw-to-green-700:hover{
    --gradient-to-color: #2f855a
  }

  .sm\:hover\:tw-to-green-800:hover{
    --gradient-to-color: #276749
  }

  .sm\:hover\:tw-to-green-900:hover{
    --gradient-to-color: #22543d
  }

  .sm\:hover\:tw-to-teal-100:hover{
    --gradient-to-color: #e6fffa
  }

  .sm\:hover\:tw-to-teal-200:hover{
    --gradient-to-color: #b2f5ea
  }

  .sm\:hover\:tw-to-teal-300:hover{
    --gradient-to-color: #81e6d9
  }

  .sm\:hover\:tw-to-teal-400:hover{
    --gradient-to-color: #4fd1c5
  }

  .sm\:hover\:tw-to-teal-500:hover{
    --gradient-to-color: #38b2ac
  }

  .sm\:hover\:tw-to-teal-600:hover{
    --gradient-to-color: #319795
  }

  .sm\:hover\:tw-to-teal-700:hover{
    --gradient-to-color: #2c7a7b
  }

  .sm\:hover\:tw-to-teal-800:hover{
    --gradient-to-color: #285e61
  }

  .sm\:hover\:tw-to-teal-900:hover{
    --gradient-to-color: #234e52
  }

  .sm\:hover\:tw-to-blue-100:hover{
    --gradient-to-color: #ebf8ff
  }

  .sm\:hover\:tw-to-blue-200:hover{
    --gradient-to-color: #bee3f8
  }

  .sm\:hover\:tw-to-blue-300:hover{
    --gradient-to-color: #90cdf4
  }

  .sm\:hover\:tw-to-blue-400:hover{
    --gradient-to-color: #63b3ed
  }

  .sm\:hover\:tw-to-blue-500:hover{
    --gradient-to-color: #4299e1
  }

  .sm\:hover\:tw-to-blue-600:hover{
    --gradient-to-color: #3182ce
  }

  .sm\:hover\:tw-to-blue-700:hover{
    --gradient-to-color: #2b6cb0
  }

  .sm\:hover\:tw-to-blue-800:hover{
    --gradient-to-color: #2c5282
  }

  .sm\:hover\:tw-to-blue-900:hover{
    --gradient-to-color: #2a4365
  }

  .sm\:hover\:tw-to-indigo-100:hover{
    --gradient-to-color: #ebf4ff
  }

  .sm\:hover\:tw-to-indigo-200:hover{
    --gradient-to-color: #c3dafe
  }

  .sm\:hover\:tw-to-indigo-300:hover{
    --gradient-to-color: #a3bffa
  }

  .sm\:hover\:tw-to-indigo-400:hover{
    --gradient-to-color: #7f9cf5
  }

  .sm\:hover\:tw-to-indigo-500:hover{
    --gradient-to-color: #667eea
  }

  .sm\:hover\:tw-to-indigo-600:hover{
    --gradient-to-color: #5a67d8
  }

  .sm\:hover\:tw-to-indigo-700:hover{
    --gradient-to-color: #4c51bf
  }

  .sm\:hover\:tw-to-indigo-800:hover{
    --gradient-to-color: #434190
  }

  .sm\:hover\:tw-to-indigo-900:hover{
    --gradient-to-color: #3c366b
  }

  .sm\:hover\:tw-to-purple-100:hover{
    --gradient-to-color: #faf5ff
  }

  .sm\:hover\:tw-to-purple-200:hover{
    --gradient-to-color: #e9d8fd
  }

  .sm\:hover\:tw-to-purple-300:hover{
    --gradient-to-color: #d6bcfa
  }

  .sm\:hover\:tw-to-purple-400:hover{
    --gradient-to-color: #b794f4
  }

  .sm\:hover\:tw-to-purple-500:hover{
    --gradient-to-color: #9f7aea
  }

  .sm\:hover\:tw-to-purple-600:hover{
    --gradient-to-color: #805ad5
  }

  .sm\:hover\:tw-to-purple-700:hover{
    --gradient-to-color: #6b46c1
  }

  .sm\:hover\:tw-to-purple-800:hover{
    --gradient-to-color: #553c9a
  }

  .sm\:hover\:tw-to-purple-900:hover{
    --gradient-to-color: #44337a
  }

  .sm\:hover\:tw-to-pink-100:hover{
    --gradient-to-color: #fff5f7
  }

  .sm\:hover\:tw-to-pink-200:hover{
    --gradient-to-color: #fed7e2
  }

  .sm\:hover\:tw-to-pink-300:hover{
    --gradient-to-color: #fbb6ce
  }

  .sm\:hover\:tw-to-pink-400:hover{
    --gradient-to-color: #f687b3
  }

  .sm\:hover\:tw-to-pink-500:hover{
    --gradient-to-color: #ed64a6
  }

  .sm\:hover\:tw-to-pink-600:hover{
    --gradient-to-color: #d53f8c
  }

  .sm\:hover\:tw-to-pink-700:hover{
    --gradient-to-color: #b83280
  }

  .sm\:hover\:tw-to-pink-800:hover{
    --gradient-to-color: #97266d
  }

  .sm\:hover\:tw-to-pink-900:hover{
    --gradient-to-color: #702459
  }

  .sm\:focus\:tw-from-transparent:focus{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:tw-from-tlg-green-dark:focus{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .sm\:focus\:tw-from-tlg-green:focus{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .sm\:focus\:tw-from-tlg-orange-500:focus{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .sm\:focus\:tw-from-black:focus{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:tw-from-white:focus{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:tw-from-gray-100:focus{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:focus\:tw-from-gray-200:focus{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:focus\:tw-from-gray-300:focus{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:focus\:tw-from-gray-400:focus{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:focus\:tw-from-gray-500:focus{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:focus\:tw-from-gray-600:focus{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:focus\:tw-from-gray-700:focus{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:focus\:tw-from-gray-800:focus{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:focus\:tw-from-gray-900:focus{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:focus\:tw-from-red-100:focus{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:focus\:tw-from-red-200:focus{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:focus\:tw-from-red-300:focus{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:focus\:tw-from-red-400:focus{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:focus\:tw-from-red-500:focus{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:focus\:tw-from-red-600:focus{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:focus\:tw-from-red-700:focus{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:focus\:tw-from-red-800:focus{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:focus\:tw-from-red-900:focus{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:focus\:tw-from-orange-100:focus{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:focus\:tw-from-orange-200:focus{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:focus\:tw-from-orange-300:focus{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:focus\:tw-from-orange-400:focus{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:focus\:tw-from-orange-500:focus{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:focus\:tw-from-orange-600:focus{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:focus\:tw-from-orange-700:focus{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:focus\:tw-from-orange-800:focus{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:focus\:tw-from-orange-900:focus{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:focus\:tw-from-yellow-100:focus{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:focus\:tw-from-yellow-200:focus{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:focus\:tw-from-yellow-300:focus{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:focus\:tw-from-yellow-400:focus{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:focus\:tw-from-yellow-500:focus{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:focus\:tw-from-yellow-600:focus{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:focus\:tw-from-yellow-700:focus{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:focus\:tw-from-yellow-800:focus{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:focus\:tw-from-yellow-900:focus{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:focus\:tw-from-green-100:focus{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:focus\:tw-from-green-200:focus{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:focus\:tw-from-green-300:focus{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:focus\:tw-from-green-400:focus{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:focus\:tw-from-green-500:focus{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:focus\:tw-from-green-600:focus{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:focus\:tw-from-green-700:focus{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:focus\:tw-from-green-800:focus{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:focus\:tw-from-green-900:focus{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:focus\:tw-from-teal-100:focus{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:focus\:tw-from-teal-200:focus{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:focus\:tw-from-teal-300:focus{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:focus\:tw-from-teal-400:focus{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:focus\:tw-from-teal-500:focus{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:focus\:tw-from-teal-600:focus{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:focus\:tw-from-teal-700:focus{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:focus\:tw-from-teal-800:focus{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:focus\:tw-from-teal-900:focus{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:focus\:tw-from-blue-100:focus{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:focus\:tw-from-blue-200:focus{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:focus\:tw-from-blue-300:focus{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:focus\:tw-from-blue-400:focus{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:focus\:tw-from-blue-500:focus{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:focus\:tw-from-blue-600:focus{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:focus\:tw-from-blue-700:focus{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:focus\:tw-from-blue-800:focus{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:focus\:tw-from-blue-900:focus{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:focus\:tw-from-indigo-100:focus{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:focus\:tw-from-indigo-200:focus{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:focus\:tw-from-indigo-300:focus{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:focus\:tw-from-indigo-400:focus{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:focus\:tw-from-indigo-500:focus{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:focus\:tw-from-indigo-600:focus{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:focus\:tw-from-indigo-700:focus{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:focus\:tw-from-indigo-800:focus{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:focus\:tw-from-indigo-900:focus{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:focus\:tw-from-purple-100:focus{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:focus\:tw-from-purple-200:focus{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:focus\:tw-from-purple-300:focus{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:focus\:tw-from-purple-400:focus{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:focus\:tw-from-purple-500:focus{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:focus\:tw-from-purple-600:focus{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:focus\:tw-from-purple-700:focus{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:focus\:tw-from-purple-800:focus{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:focus\:tw-from-purple-900:focus{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:focus\:tw-from-pink-100:focus{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:focus\:tw-from-pink-200:focus{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:focus\:tw-from-pink-300:focus{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:focus\:tw-from-pink-400:focus{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:focus\:tw-from-pink-500:focus{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:focus\:tw-from-pink-600:focus{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:focus\:tw-from-pink-700:focus{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:focus\:tw-from-pink-800:focus{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:focus\:tw-from-pink-900:focus{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:focus\:tw-via-transparent:focus{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:tw-via-tlg-green-dark:focus{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .sm\:focus\:tw-via-tlg-green:focus{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .sm\:focus\:tw-via-tlg-orange-500:focus{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .sm\:focus\:tw-via-black:focus{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:tw-via-white:focus{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:tw-via-gray-100:focus{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .sm\:focus\:tw-via-gray-200:focus{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .sm\:focus\:tw-via-gray-300:focus{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .sm\:focus\:tw-via-gray-400:focus{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .sm\:focus\:tw-via-gray-500:focus{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .sm\:focus\:tw-via-gray-600:focus{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .sm\:focus\:tw-via-gray-700:focus{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .sm\:focus\:tw-via-gray-800:focus{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .sm\:focus\:tw-via-gray-900:focus{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .sm\:focus\:tw-via-red-100:focus{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .sm\:focus\:tw-via-red-200:focus{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .sm\:focus\:tw-via-red-300:focus{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .sm\:focus\:tw-via-red-400:focus{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .sm\:focus\:tw-via-red-500:focus{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .sm\:focus\:tw-via-red-600:focus{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .sm\:focus\:tw-via-red-700:focus{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .sm\:focus\:tw-via-red-800:focus{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .sm\:focus\:tw-via-red-900:focus{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .sm\:focus\:tw-via-orange-100:focus{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .sm\:focus\:tw-via-orange-200:focus{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .sm\:focus\:tw-via-orange-300:focus{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .sm\:focus\:tw-via-orange-400:focus{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .sm\:focus\:tw-via-orange-500:focus{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .sm\:focus\:tw-via-orange-600:focus{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .sm\:focus\:tw-via-orange-700:focus{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .sm\:focus\:tw-via-orange-800:focus{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .sm\:focus\:tw-via-orange-900:focus{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .sm\:focus\:tw-via-yellow-100:focus{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .sm\:focus\:tw-via-yellow-200:focus{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .sm\:focus\:tw-via-yellow-300:focus{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .sm\:focus\:tw-via-yellow-400:focus{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .sm\:focus\:tw-via-yellow-500:focus{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .sm\:focus\:tw-via-yellow-600:focus{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .sm\:focus\:tw-via-yellow-700:focus{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .sm\:focus\:tw-via-yellow-800:focus{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .sm\:focus\:tw-via-yellow-900:focus{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .sm\:focus\:tw-via-green-100:focus{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .sm\:focus\:tw-via-green-200:focus{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .sm\:focus\:tw-via-green-300:focus{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .sm\:focus\:tw-via-green-400:focus{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .sm\:focus\:tw-via-green-500:focus{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .sm\:focus\:tw-via-green-600:focus{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .sm\:focus\:tw-via-green-700:focus{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .sm\:focus\:tw-via-green-800:focus{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .sm\:focus\:tw-via-green-900:focus{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .sm\:focus\:tw-via-teal-100:focus{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .sm\:focus\:tw-via-teal-200:focus{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .sm\:focus\:tw-via-teal-300:focus{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .sm\:focus\:tw-via-teal-400:focus{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .sm\:focus\:tw-via-teal-500:focus{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .sm\:focus\:tw-via-teal-600:focus{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .sm\:focus\:tw-via-teal-700:focus{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .sm\:focus\:tw-via-teal-800:focus{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .sm\:focus\:tw-via-teal-900:focus{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .sm\:focus\:tw-via-blue-100:focus{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .sm\:focus\:tw-via-blue-200:focus{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .sm\:focus\:tw-via-blue-300:focus{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .sm\:focus\:tw-via-blue-400:focus{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .sm\:focus\:tw-via-blue-500:focus{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .sm\:focus\:tw-via-blue-600:focus{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .sm\:focus\:tw-via-blue-700:focus{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .sm\:focus\:tw-via-blue-800:focus{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .sm\:focus\:tw-via-blue-900:focus{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .sm\:focus\:tw-via-indigo-100:focus{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .sm\:focus\:tw-via-indigo-200:focus{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .sm\:focus\:tw-via-indigo-300:focus{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .sm\:focus\:tw-via-indigo-400:focus{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .sm\:focus\:tw-via-indigo-500:focus{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .sm\:focus\:tw-via-indigo-600:focus{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .sm\:focus\:tw-via-indigo-700:focus{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .sm\:focus\:tw-via-indigo-800:focus{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .sm\:focus\:tw-via-indigo-900:focus{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .sm\:focus\:tw-via-purple-100:focus{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .sm\:focus\:tw-via-purple-200:focus{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .sm\:focus\:tw-via-purple-300:focus{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .sm\:focus\:tw-via-purple-400:focus{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .sm\:focus\:tw-via-purple-500:focus{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .sm\:focus\:tw-via-purple-600:focus{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .sm\:focus\:tw-via-purple-700:focus{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .sm\:focus\:tw-via-purple-800:focus{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .sm\:focus\:tw-via-purple-900:focus{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .sm\:focus\:tw-via-pink-100:focus{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .sm\:focus\:tw-via-pink-200:focus{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .sm\:focus\:tw-via-pink-300:focus{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .sm\:focus\:tw-via-pink-400:focus{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .sm\:focus\:tw-via-pink-500:focus{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .sm\:focus\:tw-via-pink-600:focus{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .sm\:focus\:tw-via-pink-700:focus{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .sm\:focus\:tw-via-pink-800:focus{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .sm\:focus\:tw-via-pink-900:focus{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .sm\:focus\:tw-to-transparent:focus{
    --gradient-to-color: transparent
  }

  .sm\:focus\:tw-to-tlg-green-dark:focus{
    --gradient-to-color: #76b84e
  }

  .sm\:focus\:tw-to-tlg-green:focus{
    --gradient-to-color: #a4ca61
  }

  .sm\:focus\:tw-to-tlg-orange-500:focus{
    --gradient-to-color: #ff7f41
  }

  .sm\:focus\:tw-to-black:focus{
    --gradient-to-color: #000
  }

  .sm\:focus\:tw-to-white:focus{
    --gradient-to-color: #fff
  }

  .sm\:focus\:tw-to-gray-100:focus{
    --gradient-to-color: #f7fafc
  }

  .sm\:focus\:tw-to-gray-200:focus{
    --gradient-to-color: #edf2f7
  }

  .sm\:focus\:tw-to-gray-300:focus{
    --gradient-to-color: #e2e8f0
  }

  .sm\:focus\:tw-to-gray-400:focus{
    --gradient-to-color: #cbd5e0
  }

  .sm\:focus\:tw-to-gray-500:focus{
    --gradient-to-color: #a0aec0
  }

  .sm\:focus\:tw-to-gray-600:focus{
    --gradient-to-color: #718096
  }

  .sm\:focus\:tw-to-gray-700:focus{
    --gradient-to-color: #4a5568
  }

  .sm\:focus\:tw-to-gray-800:focus{
    --gradient-to-color: #2d3748
  }

  .sm\:focus\:tw-to-gray-900:focus{
    --gradient-to-color: #1a202c
  }

  .sm\:focus\:tw-to-red-100:focus{
    --gradient-to-color: #fff5f5
  }

  .sm\:focus\:tw-to-red-200:focus{
    --gradient-to-color: #fed7d7
  }

  .sm\:focus\:tw-to-red-300:focus{
    --gradient-to-color: #feb2b2
  }

  .sm\:focus\:tw-to-red-400:focus{
    --gradient-to-color: #fc8181
  }

  .sm\:focus\:tw-to-red-500:focus{
    --gradient-to-color: #f56565
  }

  .sm\:focus\:tw-to-red-600:focus{
    --gradient-to-color: #e53e3e
  }

  .sm\:focus\:tw-to-red-700:focus{
    --gradient-to-color: #c53030
  }

  .sm\:focus\:tw-to-red-800:focus{
    --gradient-to-color: #9b2c2c
  }

  .sm\:focus\:tw-to-red-900:focus{
    --gradient-to-color: #742a2a
  }

  .sm\:focus\:tw-to-orange-100:focus{
    --gradient-to-color: #fffaf0
  }

  .sm\:focus\:tw-to-orange-200:focus{
    --gradient-to-color: #feebc8
  }

  .sm\:focus\:tw-to-orange-300:focus{
    --gradient-to-color: #fbd38d
  }

  .sm\:focus\:tw-to-orange-400:focus{
    --gradient-to-color: #f6ad55
  }

  .sm\:focus\:tw-to-orange-500:focus{
    --gradient-to-color: #ed8936
  }

  .sm\:focus\:tw-to-orange-600:focus{
    --gradient-to-color: #dd6b20
  }

  .sm\:focus\:tw-to-orange-700:focus{
    --gradient-to-color: #c05621
  }

  .sm\:focus\:tw-to-orange-800:focus{
    --gradient-to-color: #9c4221
  }

  .sm\:focus\:tw-to-orange-900:focus{
    --gradient-to-color: #7b341e
  }

  .sm\:focus\:tw-to-yellow-100:focus{
    --gradient-to-color: #fffff0
  }

  .sm\:focus\:tw-to-yellow-200:focus{
    --gradient-to-color: #fefcbf
  }

  .sm\:focus\:tw-to-yellow-300:focus{
    --gradient-to-color: #faf089
  }

  .sm\:focus\:tw-to-yellow-400:focus{
    --gradient-to-color: #f6e05e
  }

  .sm\:focus\:tw-to-yellow-500:focus{
    --gradient-to-color: #ecc94b
  }

  .sm\:focus\:tw-to-yellow-600:focus{
    --gradient-to-color: #d69e2e
  }

  .sm\:focus\:tw-to-yellow-700:focus{
    --gradient-to-color: #b7791f
  }

  .sm\:focus\:tw-to-yellow-800:focus{
    --gradient-to-color: #975a16
  }

  .sm\:focus\:tw-to-yellow-900:focus{
    --gradient-to-color: #744210
  }

  .sm\:focus\:tw-to-green-100:focus{
    --gradient-to-color: #f0fff4
  }

  .sm\:focus\:tw-to-green-200:focus{
    --gradient-to-color: #c6f6d5
  }

  .sm\:focus\:tw-to-green-300:focus{
    --gradient-to-color: #9ae6b4
  }

  .sm\:focus\:tw-to-green-400:focus{
    --gradient-to-color: #68d391
  }

  .sm\:focus\:tw-to-green-500:focus{
    --gradient-to-color: #48bb78
  }

  .sm\:focus\:tw-to-green-600:focus{
    --gradient-to-color: #38a169
  }

  .sm\:focus\:tw-to-green-700:focus{
    --gradient-to-color: #2f855a
  }

  .sm\:focus\:tw-to-green-800:focus{
    --gradient-to-color: #276749
  }

  .sm\:focus\:tw-to-green-900:focus{
    --gradient-to-color: #22543d
  }

  .sm\:focus\:tw-to-teal-100:focus{
    --gradient-to-color: #e6fffa
  }

  .sm\:focus\:tw-to-teal-200:focus{
    --gradient-to-color: #b2f5ea
  }

  .sm\:focus\:tw-to-teal-300:focus{
    --gradient-to-color: #81e6d9
  }

  .sm\:focus\:tw-to-teal-400:focus{
    --gradient-to-color: #4fd1c5
  }

  .sm\:focus\:tw-to-teal-500:focus{
    --gradient-to-color: #38b2ac
  }

  .sm\:focus\:tw-to-teal-600:focus{
    --gradient-to-color: #319795
  }

  .sm\:focus\:tw-to-teal-700:focus{
    --gradient-to-color: #2c7a7b
  }

  .sm\:focus\:tw-to-teal-800:focus{
    --gradient-to-color: #285e61
  }

  .sm\:focus\:tw-to-teal-900:focus{
    --gradient-to-color: #234e52
  }

  .sm\:focus\:tw-to-blue-100:focus{
    --gradient-to-color: #ebf8ff
  }

  .sm\:focus\:tw-to-blue-200:focus{
    --gradient-to-color: #bee3f8
  }

  .sm\:focus\:tw-to-blue-300:focus{
    --gradient-to-color: #90cdf4
  }

  .sm\:focus\:tw-to-blue-400:focus{
    --gradient-to-color: #63b3ed
  }

  .sm\:focus\:tw-to-blue-500:focus{
    --gradient-to-color: #4299e1
  }

  .sm\:focus\:tw-to-blue-600:focus{
    --gradient-to-color: #3182ce
  }

  .sm\:focus\:tw-to-blue-700:focus{
    --gradient-to-color: #2b6cb0
  }

  .sm\:focus\:tw-to-blue-800:focus{
    --gradient-to-color: #2c5282
  }

  .sm\:focus\:tw-to-blue-900:focus{
    --gradient-to-color: #2a4365
  }

  .sm\:focus\:tw-to-indigo-100:focus{
    --gradient-to-color: #ebf4ff
  }

  .sm\:focus\:tw-to-indigo-200:focus{
    --gradient-to-color: #c3dafe
  }

  .sm\:focus\:tw-to-indigo-300:focus{
    --gradient-to-color: #a3bffa
  }

  .sm\:focus\:tw-to-indigo-400:focus{
    --gradient-to-color: #7f9cf5
  }

  .sm\:focus\:tw-to-indigo-500:focus{
    --gradient-to-color: #667eea
  }

  .sm\:focus\:tw-to-indigo-600:focus{
    --gradient-to-color: #5a67d8
  }

  .sm\:focus\:tw-to-indigo-700:focus{
    --gradient-to-color: #4c51bf
  }

  .sm\:focus\:tw-to-indigo-800:focus{
    --gradient-to-color: #434190
  }

  .sm\:focus\:tw-to-indigo-900:focus{
    --gradient-to-color: #3c366b
  }

  .sm\:focus\:tw-to-purple-100:focus{
    --gradient-to-color: #faf5ff
  }

  .sm\:focus\:tw-to-purple-200:focus{
    --gradient-to-color: #e9d8fd
  }

  .sm\:focus\:tw-to-purple-300:focus{
    --gradient-to-color: #d6bcfa
  }

  .sm\:focus\:tw-to-purple-400:focus{
    --gradient-to-color: #b794f4
  }

  .sm\:focus\:tw-to-purple-500:focus{
    --gradient-to-color: #9f7aea
  }

  .sm\:focus\:tw-to-purple-600:focus{
    --gradient-to-color: #805ad5
  }

  .sm\:focus\:tw-to-purple-700:focus{
    --gradient-to-color: #6b46c1
  }

  .sm\:focus\:tw-to-purple-800:focus{
    --gradient-to-color: #553c9a
  }

  .sm\:focus\:tw-to-purple-900:focus{
    --gradient-to-color: #44337a
  }

  .sm\:focus\:tw-to-pink-100:focus{
    --gradient-to-color: #fff5f7
  }

  .sm\:focus\:tw-to-pink-200:focus{
    --gradient-to-color: #fed7e2
  }

  .sm\:focus\:tw-to-pink-300:focus{
    --gradient-to-color: #fbb6ce
  }

  .sm\:focus\:tw-to-pink-400:focus{
    --gradient-to-color: #f687b3
  }

  .sm\:focus\:tw-to-pink-500:focus{
    --gradient-to-color: #ed64a6
  }

  .sm\:focus\:tw-to-pink-600:focus{
    --gradient-to-color: #d53f8c
  }

  .sm\:focus\:tw-to-pink-700:focus{
    --gradient-to-color: #b83280
  }

  .sm\:focus\:tw-to-pink-800:focus{
    --gradient-to-color: #97266d
  }

  .sm\:focus\:tw-to-pink-900:focus{
    --gradient-to-color: #702459
  }

  .sm\:tw-bg-opacity-0{
    --bg-opacity: 0
  }

  .sm\:tw-bg-opacity-25{
    --bg-opacity: 0.25
  }

  .sm\:tw-bg-opacity-50{
    --bg-opacity: 0.5
  }

  .sm\:tw-bg-opacity-75{
    --bg-opacity: 0.75
  }

  .sm\:tw-bg-opacity-100{
    --bg-opacity: 1
  }

  .sm\:hover\:tw-bg-opacity-0:hover{
    --bg-opacity: 0
  }

  .sm\:hover\:tw-bg-opacity-25:hover{
    --bg-opacity: 0.25
  }

  .sm\:hover\:tw-bg-opacity-50:hover{
    --bg-opacity: 0.5
  }

  .sm\:hover\:tw-bg-opacity-75:hover{
    --bg-opacity: 0.75
  }

  .sm\:hover\:tw-bg-opacity-100:hover{
    --bg-opacity: 1
  }

  .sm\:focus\:tw-bg-opacity-0:focus{
    --bg-opacity: 0
  }

  .sm\:focus\:tw-bg-opacity-25:focus{
    --bg-opacity: 0.25
  }

  .sm\:focus\:tw-bg-opacity-50:focus{
    --bg-opacity: 0.5
  }

  .sm\:focus\:tw-bg-opacity-75:focus{
    --bg-opacity: 0.75
  }

  .sm\:focus\:tw-bg-opacity-100:focus{
    --bg-opacity: 1
  }

  .sm\:tw-bg-bottom{
    background-position: bottom
  }

  .sm\:tw-bg-center{
    background-position: center
  }

  .sm\:tw-bg-left{
    background-position: left
  }

  .sm\:tw-bg-left-bottom{
    background-position: left bottom
  }

  .sm\:tw-bg-left-top{
    background-position: left top
  }

  .sm\:tw-bg-right{
    background-position: right
  }

  .sm\:tw-bg-right-bottom{
    background-position: right bottom
  }

  .sm\:tw-bg-right-top{
    background-position: right top
  }

  .sm\:tw-bg-top{
    background-position: top
  }

  .sm\:tw-bg-repeat{
    background-repeat: repeat
  }

  .sm\:tw-bg-no-repeat{
    background-repeat: no-repeat
  }

  .sm\:tw-bg-repeat-x{
    background-repeat: repeat-x
  }

  .sm\:tw-bg-repeat-y{
    background-repeat: repeat-y
  }

  .sm\:tw-bg-repeat-round{
    background-repeat: round
  }

  .sm\:tw-bg-repeat-space{
    background-repeat: space
  }

  .sm\:tw-bg-auto{
    background-size: auto
  }

  .sm\:tw-bg-cover{
    background-size: cover
  }

  .sm\:tw-bg-contain{
    background-size: contain
  }

  .sm\:tw-border-collapse{
    border-collapse: collapse
  }

  .sm\:tw-border-separate{
    border-collapse: separate
  }

  .sm\:tw-border-transparent{
    border-color: transparent
  }

  .sm\:tw-border-tlg-green-dark{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .sm\:tw-border-tlg-green{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .sm\:tw-border-tlg-orange-500{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .sm\:tw-border-black{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .sm\:tw-border-white{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .sm\:tw-border-gray-100{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .sm\:tw-border-gray-200{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .sm\:tw-border-gray-300{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .sm\:tw-border-gray-400{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .sm\:tw-border-gray-500{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .sm\:tw-border-gray-600{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .sm\:tw-border-gray-700{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .sm\:tw-border-gray-800{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .sm\:tw-border-gray-900{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .sm\:tw-border-red-100{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .sm\:tw-border-red-200{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .sm\:tw-border-red-300{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .sm\:tw-border-red-400{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .sm\:tw-border-red-500{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .sm\:tw-border-red-600{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .sm\:tw-border-red-700{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .sm\:tw-border-red-800{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .sm\:tw-border-red-900{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .sm\:tw-border-orange-100{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .sm\:tw-border-orange-200{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .sm\:tw-border-orange-300{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .sm\:tw-border-orange-400{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .sm\:tw-border-orange-500{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .sm\:tw-border-orange-600{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .sm\:tw-border-orange-700{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .sm\:tw-border-orange-800{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .sm\:tw-border-orange-900{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .sm\:tw-border-yellow-100{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .sm\:tw-border-yellow-200{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .sm\:tw-border-yellow-300{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .sm\:tw-border-yellow-400{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .sm\:tw-border-yellow-500{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .sm\:tw-border-yellow-600{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .sm\:tw-border-yellow-700{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .sm\:tw-border-yellow-800{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .sm\:tw-border-yellow-900{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .sm\:tw-border-green-100{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .sm\:tw-border-green-200{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .sm\:tw-border-green-300{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .sm\:tw-border-green-400{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .sm\:tw-border-green-500{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .sm\:tw-border-green-600{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .sm\:tw-border-green-700{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .sm\:tw-border-green-800{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .sm\:tw-border-green-900{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .sm\:tw-border-teal-100{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .sm\:tw-border-teal-200{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .sm\:tw-border-teal-300{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .sm\:tw-border-teal-400{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .sm\:tw-border-teal-500{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .sm\:tw-border-teal-600{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .sm\:tw-border-teal-700{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .sm\:tw-border-teal-800{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .sm\:tw-border-teal-900{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .sm\:tw-border-blue-100{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .sm\:tw-border-blue-200{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .sm\:tw-border-blue-300{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .sm\:tw-border-blue-400{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .sm\:tw-border-blue-500{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .sm\:tw-border-blue-600{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .sm\:tw-border-blue-700{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .sm\:tw-border-blue-800{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .sm\:tw-border-blue-900{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .sm\:tw-border-indigo-100{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .sm\:tw-border-indigo-200{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .sm\:tw-border-indigo-300{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .sm\:tw-border-indigo-400{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .sm\:tw-border-indigo-500{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .sm\:tw-border-indigo-600{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .sm\:tw-border-indigo-700{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .sm\:tw-border-indigo-800{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .sm\:tw-border-indigo-900{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .sm\:tw-border-purple-100{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .sm\:tw-border-purple-200{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .sm\:tw-border-purple-300{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .sm\:tw-border-purple-400{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .sm\:tw-border-purple-500{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .sm\:tw-border-purple-600{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .sm\:tw-border-purple-700{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .sm\:tw-border-purple-800{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .sm\:tw-border-purple-900{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .sm\:tw-border-pink-100{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .sm\:tw-border-pink-200{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .sm\:tw-border-pink-300{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .sm\:tw-border-pink-400{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .sm\:tw-border-pink-500{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .sm\:tw-border-pink-600{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .sm\:tw-border-pink-700{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .sm\:tw-border-pink-800{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .sm\:tw-border-pink-900{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .sm\:hover\:tw-border-transparent:hover{
    border-color: transparent
  }

  .sm\:hover\:tw-border-tlg-green-dark:hover{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .sm\:hover\:tw-border-tlg-green:hover{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .sm\:hover\:tw-border-tlg-orange-500:hover{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .sm\:hover\:tw-border-black:hover{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .sm\:hover\:tw-border-white:hover{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-100:hover{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-200:hover{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-300:hover{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-400:hover{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-500:hover{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-600:hover{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-700:hover{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-800:hover{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .sm\:hover\:tw-border-gray-900:hover{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-100:hover{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-200:hover{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-300:hover{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-400:hover{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-500:hover{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-600:hover{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-700:hover{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-800:hover{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .sm\:hover\:tw-border-red-900:hover{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-100:hover{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-200:hover{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-300:hover{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-400:hover{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-500:hover{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-600:hover{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-700:hover{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-800:hover{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .sm\:hover\:tw-border-orange-900:hover{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-100:hover{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-200:hover{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-300:hover{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-400:hover{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-500:hover{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-600:hover{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-700:hover{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-800:hover{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .sm\:hover\:tw-border-yellow-900:hover{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-100:hover{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-200:hover{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-300:hover{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-400:hover{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-500:hover{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-600:hover{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-700:hover{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-800:hover{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .sm\:hover\:tw-border-green-900:hover{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-100:hover{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-200:hover{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-300:hover{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-400:hover{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-500:hover{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-600:hover{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-700:hover{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-800:hover{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .sm\:hover\:tw-border-teal-900:hover{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-100:hover{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-200:hover{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-300:hover{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-400:hover{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-500:hover{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-600:hover{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-700:hover{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-800:hover{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .sm\:hover\:tw-border-blue-900:hover{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-100:hover{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-200:hover{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-300:hover{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-400:hover{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-500:hover{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-600:hover{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-700:hover{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-800:hover{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .sm\:hover\:tw-border-indigo-900:hover{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-100:hover{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-200:hover{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-300:hover{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-400:hover{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-500:hover{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-600:hover{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-700:hover{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-800:hover{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .sm\:hover\:tw-border-purple-900:hover{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-100:hover{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-200:hover{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-300:hover{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-400:hover{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-500:hover{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-600:hover{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-700:hover{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-800:hover{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .sm\:hover\:tw-border-pink-900:hover{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .sm\:focus\:tw-border-transparent:focus{
    border-color: transparent
  }

  .sm\:focus\:tw-border-tlg-green-dark:focus{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .sm\:focus\:tw-border-tlg-green:focus{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .sm\:focus\:tw-border-tlg-orange-500:focus{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .sm\:focus\:tw-border-black:focus{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .sm\:focus\:tw-border-white:focus{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-100:focus{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-200:focus{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-300:focus{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-400:focus{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-500:focus{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-600:focus{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-700:focus{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-800:focus{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .sm\:focus\:tw-border-gray-900:focus{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-100:focus{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-200:focus{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-300:focus{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-400:focus{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-500:focus{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-600:focus{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-700:focus{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-800:focus{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .sm\:focus\:tw-border-red-900:focus{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-100:focus{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-200:focus{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-300:focus{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-400:focus{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-500:focus{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-600:focus{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-700:focus{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-800:focus{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .sm\:focus\:tw-border-orange-900:focus{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-100:focus{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-200:focus{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-300:focus{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-400:focus{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-500:focus{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-600:focus{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-700:focus{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-800:focus{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .sm\:focus\:tw-border-yellow-900:focus{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-100:focus{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-200:focus{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-300:focus{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-400:focus{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-500:focus{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-600:focus{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-700:focus{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-800:focus{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .sm\:focus\:tw-border-green-900:focus{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-100:focus{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-200:focus{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-300:focus{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-400:focus{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-500:focus{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-600:focus{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-700:focus{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-800:focus{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .sm\:focus\:tw-border-teal-900:focus{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-100:focus{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-200:focus{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-300:focus{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-400:focus{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-500:focus{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-600:focus{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-700:focus{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-800:focus{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .sm\:focus\:tw-border-blue-900:focus{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-100:focus{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-200:focus{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-300:focus{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-400:focus{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-500:focus{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-600:focus{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-700:focus{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-800:focus{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .sm\:focus\:tw-border-indigo-900:focus{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-100:focus{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-200:focus{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-300:focus{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-400:focus{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-500:focus{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-600:focus{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-700:focus{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-800:focus{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .sm\:focus\:tw-border-purple-900:focus{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-100:focus{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-200:focus{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-300:focus{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-400:focus{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-500:focus{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-600:focus{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-700:focus{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-800:focus{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .sm\:focus\:tw-border-pink-900:focus{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .sm\:tw-border-opacity-0{
    --border-opacity: 0
  }

  .sm\:tw-border-opacity-25{
    --border-opacity: 0.25
  }

  .sm\:tw-border-opacity-50{
    --border-opacity: 0.5
  }

  .sm\:tw-border-opacity-75{
    --border-opacity: 0.75
  }

  .sm\:tw-border-opacity-100{
    --border-opacity: 1
  }

  .sm\:hover\:tw-border-opacity-0:hover{
    --border-opacity: 0
  }

  .sm\:hover\:tw-border-opacity-25:hover{
    --border-opacity: 0.25
  }

  .sm\:hover\:tw-border-opacity-50:hover{
    --border-opacity: 0.5
  }

  .sm\:hover\:tw-border-opacity-75:hover{
    --border-opacity: 0.75
  }

  .sm\:hover\:tw-border-opacity-100:hover{
    --border-opacity: 1
  }

  .sm\:focus\:tw-border-opacity-0:focus{
    --border-opacity: 0
  }

  .sm\:focus\:tw-border-opacity-25:focus{
    --border-opacity: 0.25
  }

  .sm\:focus\:tw-border-opacity-50:focus{
    --border-opacity: 0.5
  }

  .sm\:focus\:tw-border-opacity-75:focus{
    --border-opacity: 0.75
  }

  .sm\:focus\:tw-border-opacity-100:focus{
    --border-opacity: 1
  }

  .sm\:tw-rounded-none{
    border-radius: 0
  }

  .sm\:tw-rounded-sm{
    border-radius: 0.125rem
  }

  .sm\:tw-rounded{
    border-radius: 0.25rem
  }

  .sm\:tw-rounded-lg{
    border-radius: 0.5rem
  }

  .sm\:tw-rounded-full{
    border-radius: 9999px
  }

  .sm\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .sm\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .sm\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .sm\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .sm\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .sm\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .sm\:tw-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .sm\:tw-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .sm\:tw-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .sm\:tw-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .sm\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .sm\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .sm\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .sm\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .sm\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .sm\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .sm\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:tw-rounded-tl-none{
    border-top-left-radius: 0
  }

  .sm\:tw-rounded-tr-none{
    border-top-right-radius: 0
  }

  .sm\:tw-rounded-br-none{
    border-bottom-right-radius: 0
  }

  .sm\:tw-rounded-bl-none{
    border-bottom-left-radius: 0
  }

  .sm\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  .sm\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  .sm\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  .sm\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  .sm\:tw-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  .sm\:tw-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  .sm\:tw-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  .sm\:tw-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  .sm\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  .sm\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  .sm\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  .sm\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  .sm\:tw-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  .sm\:tw-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  .sm\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  .sm\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  .sm\:tw-border-solid{
    border-style: solid
  }

  .sm\:tw-border-dashed{
    border-style: dashed
  }

  .sm\:tw-border-dotted{
    border-style: dotted
  }

  .sm\:tw-border-double{
    border-style: double
  }

  .sm\:tw-border-none{
    border-style: none
  }

  .sm\:tw-border-0{
    border-width: 0
  }

  .sm\:tw-border-2{
    border-width: 2px
  }

  .sm\:tw-border-4{
    border-width: 4px
  }

  .sm\:tw-border-8{
    border-width: 8px
  }

  .sm\:tw-border{
    border-width: 1px
  }

  .sm\:tw-border-t-0{
    border-top-width: 0
  }

  .sm\:tw-border-r-0{
    border-right-width: 0
  }

  .sm\:tw-border-b-0{
    border-bottom-width: 0
  }

  .sm\:tw-border-l-0{
    border-left-width: 0
  }

  .sm\:tw-border-t-2{
    border-top-width: 2px
  }

  .sm\:tw-border-r-2{
    border-right-width: 2px
  }

  .sm\:tw-border-b-2{
    border-bottom-width: 2px
  }

  .sm\:tw-border-l-2{
    border-left-width: 2px
  }

  .sm\:tw-border-t-4{
    border-top-width: 4px
  }

  .sm\:tw-border-r-4{
    border-right-width: 4px
  }

  .sm\:tw-border-b-4{
    border-bottom-width: 4px
  }

  .sm\:tw-border-l-4{
    border-left-width: 4px
  }

  .sm\:tw-border-t-8{
    border-top-width: 8px
  }

  .sm\:tw-border-r-8{
    border-right-width: 8px
  }

  .sm\:tw-border-b-8{
    border-bottom-width: 8px
  }

  .sm\:tw-border-l-8{
    border-left-width: 8px
  }

  .sm\:tw-border-t{
    border-top-width: 1px
  }

  .sm\:tw-border-r{
    border-right-width: 1px
  }

  .sm\:tw-border-b{
    border-bottom-width: 1px
  }

  .sm\:tw-border-l{
    border-left-width: 1px
  }

  .sm\:tw-box-border{
    box-sizing: border-box
  }

  .sm\:tw-box-content{
    box-sizing: content-box
  }

  .sm\:tw-cursor-auto{
    cursor: auto
  }

  .sm\:tw-cursor-default{
    cursor: default
  }

  .sm\:tw-cursor-pointer{
    cursor: pointer
  }

  .sm\:tw-cursor-wait{
    cursor: wait
  }

  .sm\:tw-cursor-text{
    cursor: text
  }

  .sm\:tw-cursor-move{
    cursor: move
  }

  .sm\:tw-cursor-not-allowed{
    cursor: not-allowed
  }

  .sm\:tw-block{
    display: block
  }

  .sm\:tw-inline-block{
    display: inline-block
  }

  .sm\:tw-inline{
    display: inline
  }

  .sm\:tw-flex{
    display: flex
  }

  .sm\:tw-inline-flex{
    display: inline-flex
  }

  .sm\:tw-table{
    display: table
  }

  .sm\:tw-table-caption{
    display: table-caption
  }

  .sm\:tw-table-cell{
    display: table-cell
  }

  .sm\:tw-table-column{
    display: table-column
  }

  .sm\:tw-table-column-group{
    display: table-column-group
  }

  .sm\:tw-table-footer-group{
    display: table-footer-group
  }

  .sm\:tw-table-header-group{
    display: table-header-group
  }

  .sm\:tw-table-row-group{
    display: table-row-group
  }

  .sm\:tw-table-row{
    display: table-row
  }

  .sm\:tw-flow-root{
    display: flow-root
  }

  .sm\:tw-grid{
    display: grid
  }

  .sm\:tw-inline-grid{
    display: inline-grid
  }

  .sm\:tw-contents{
    display: contents
  }

  .sm\:tw-hidden{
    display: none
  }

  .sm\:tw-flex-row{
    flex-direction: row
  }

  .sm\:tw-flex-row-reverse{
    flex-direction: row-reverse
  }

  .sm\:tw-flex-col{
    flex-direction: column
  }

  .sm\:tw-flex-col-reverse{
    flex-direction: column-reverse
  }

  .sm\:tw-flex-wrap{
    flex-wrap: wrap
  }

  .sm\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  .sm\:tw-flex-no-wrap{
    flex-wrap: nowrap
  }

  .sm\:tw-place-items-auto{
    place-items: auto
  }

  .sm\:tw-place-items-start{
    place-items: start
  }

  .sm\:tw-place-items-end{
    place-items: end
  }

  .sm\:tw-place-items-center{
    place-items: center
  }

  .sm\:tw-place-items-stretch{
    place-items: stretch
  }

  .sm\:tw-place-content-center{
    place-content: center
  }

  .sm\:tw-place-content-start{
    place-content: start
  }

  .sm\:tw-place-content-end{
    place-content: end
  }

  .sm\:tw-place-content-between{
    place-content: space-between
  }

  .sm\:tw-place-content-around{
    place-content: space-around
  }

  .sm\:tw-place-content-evenly{
    place-content: space-evenly
  }

  .sm\:tw-place-content-stretch{
    place-content: stretch
  }

  .sm\:tw-place-self-auto{
    place-self: auto
  }

  .sm\:tw-place-self-start{
    place-self: start
  }

  .sm\:tw-place-self-end{
    place-self: end
  }

  .sm\:tw-place-self-center{
    place-self: center
  }

  .sm\:tw-place-self-stretch{
    place-self: stretch
  }

  .sm\:tw-items-start{
    align-items: flex-start
  }

  .sm\:tw-items-end{
    align-items: flex-end
  }

  .sm\:tw-items-center{
    align-items: center
  }

  .sm\:tw-items-baseline{
    align-items: baseline
  }

  .sm\:tw-items-stretch{
    align-items: stretch
  }

  .sm\:tw-content-center{
    align-content: center
  }

  .sm\:tw-content-start{
    align-content: flex-start
  }

  .sm\:tw-content-end{
    align-content: flex-end
  }

  .sm\:tw-content-between{
    align-content: space-between
  }

  .sm\:tw-content-around{
    align-content: space-around
  }

  .sm\:tw-content-evenly{
    align-content: space-evenly
  }

  .sm\:tw-self-auto{
    align-self: auto
  }

  .sm\:tw-self-start{
    align-self: flex-start
  }

  .sm\:tw-self-end{
    align-self: flex-end
  }

  .sm\:tw-self-center{
    align-self: center
  }

  .sm\:tw-self-stretch{
    align-self: stretch
  }

  .sm\:tw-justify-items-auto{
    justify-items: auto
  }

  .sm\:tw-justify-items-start{
    justify-items: start
  }

  .sm\:tw-justify-items-end{
    justify-items: end
  }

  .sm\:tw-justify-items-center{
    justify-items: center
  }

  .sm\:tw-justify-items-stretch{
    justify-items: stretch
  }

  .sm\:tw-justify-start{
    justify-content: flex-start
  }

  .sm\:tw-justify-end{
    justify-content: flex-end
  }

  .sm\:tw-justify-center{
    justify-content: center
  }

  .sm\:tw-justify-between{
    justify-content: space-between
  }

  .sm\:tw-justify-around{
    justify-content: space-around
  }

  .sm\:tw-justify-evenly{
    justify-content: space-evenly
  }

  .sm\:tw-justify-self-auto{
    justify-self: auto
  }

  .sm\:tw-justify-self-start{
    justify-self: start
  }

  .sm\:tw-justify-self-end{
    justify-self: end
  }

  .sm\:tw-justify-self-center{
    justify-self: center
  }

  .sm\:tw-justify-self-stretch{
    justify-self: stretch
  }

  .sm\:tw-flex-1{
    flex: 1 1
  }

  .sm\:tw-flex-auto{
    flex: 1 1 auto
  }

  .sm\:tw-flex-initial{
    flex: 0 1 auto
  }

  .sm\:tw-flex-none{
    flex: none
  }

  .sm\:tw-flex-grow-0{
    flex-grow: 0
  }

  .sm\:tw-flex-grow{
    flex-grow: 1
  }

  .sm\:tw-flex-shrink-0{
    flex-shrink: 0
  }

  .sm\:tw-flex-shrink{
    flex-shrink: 1
  }

  .sm\:tw-order-1{
    order: 1
  }

  .sm\:tw-order-2{
    order: 2
  }

  .sm\:tw-order-3{
    order: 3
  }

  .sm\:tw-order-4{
    order: 4
  }

  .sm\:tw-order-5{
    order: 5
  }

  .sm\:tw-order-6{
    order: 6
  }

  .sm\:tw-order-7{
    order: 7
  }

  .sm\:tw-order-8{
    order: 8
  }

  .sm\:tw-order-9{
    order: 9
  }

  .sm\:tw-order-10{
    order: 10
  }

  .sm\:tw-order-11{
    order: 11
  }

  .sm\:tw-order-12{
    order: 12
  }

  .sm\:tw-order-first{
    order: -9999
  }

  .sm\:tw-order-last{
    order: 9999
  }

  .sm\:tw-order-none{
    order: 0
  }

  .sm\:tw-float-right{
    float: right
  }

  .sm\:tw-float-left{
    float: left
  }

  .sm\:tw-float-none{
    float: none
  }

  .sm\:tw-clearfix:after{
    content: "";
    display: table;
    clear: both
  }

  .sm\:tw-clear-left{
    clear: left
  }

  .sm\:tw-clear-right{
    clear: right
  }

  .sm\:tw-clear-both{
    clear: both
  }

  .sm\:tw-clear-none{
    clear: none
  }

  .sm\:tw-font-sans{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .sm\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .sm\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .sm\:tw-font-hairline{
    font-weight: 100
  }

  .sm\:tw-font-thin{
    font-weight: 200
  }

  .sm\:tw-font-light{
    font-weight: 300
  }

  .sm\:tw-font-normal{
    font-weight: 400
  }

  .sm\:tw-font-medium{
    font-weight: 500
  }

  .sm\:tw-font-semibold{
    font-weight: 600
  }

  .sm\:tw-font-bold{
    font-weight: 700
  }

  .sm\:tw-font-extrabold{
    font-weight: 800
  }

  .sm\:tw-font-black{
    font-weight: 900
  }

  .sm\:hover\:tw-font-hairline:hover{
    font-weight: 100
  }

  .sm\:hover\:tw-font-thin:hover{
    font-weight: 200
  }

  .sm\:hover\:tw-font-light:hover{
    font-weight: 300
  }

  .sm\:hover\:tw-font-normal:hover{
    font-weight: 400
  }

  .sm\:hover\:tw-font-medium:hover{
    font-weight: 500
  }

  .sm\:hover\:tw-font-semibold:hover{
    font-weight: 600
  }

  .sm\:hover\:tw-font-bold:hover{
    font-weight: 700
  }

  .sm\:hover\:tw-font-extrabold:hover{
    font-weight: 800
  }

  .sm\:hover\:tw-font-black:hover{
    font-weight: 900
  }

  .sm\:focus\:tw-font-hairline:focus{
    font-weight: 100
  }

  .sm\:focus\:tw-font-thin:focus{
    font-weight: 200
  }

  .sm\:focus\:tw-font-light:focus{
    font-weight: 300
  }

  .sm\:focus\:tw-font-normal:focus{
    font-weight: 400
  }

  .sm\:focus\:tw-font-medium:focus{
    font-weight: 500
  }

  .sm\:focus\:tw-font-semibold:focus{
    font-weight: 600
  }

  .sm\:focus\:tw-font-bold:focus{
    font-weight: 700
  }

  .sm\:focus\:tw-font-extrabold:focus{
    font-weight: 800
  }

  .sm\:focus\:tw-font-black:focus{
    font-weight: 900
  }

  .sm\:tw-h-0{
    height: 0
  }

  .sm\:tw-h-1{
    height: 0.25rem
  }

  .sm\:tw-h-2{
    height: 0.5rem
  }

  .sm\:tw-h-3{
    height: 0.75rem
  }

  .sm\:tw-h-4{
    height: 1rem
  }

  .sm\:tw-h-5{
    height: 1.25rem
  }

  .sm\:tw-h-6{
    height: 1.5rem
  }

  .sm\:tw-h-8{
    height: 2rem
  }

  .sm\:tw-h-10{
    height: 2.5rem
  }

  .sm\:tw-h-12{
    height: 3rem
  }

  .sm\:tw-h-16{
    height: 4rem
  }

  .sm\:tw-h-20{
    height: 5rem
  }

  .sm\:tw-h-24{
    height: 6rem
  }

  .sm\:tw-h-32{
    height: 8rem
  }

  .sm\:tw-h-40{
    height: 10rem
  }

  .sm\:tw-h-48{
    height: 12rem
  }

  .sm\:tw-h-56{
    height: 14rem
  }

  .sm\:tw-h-64{
    height: 16rem
  }

  .sm\:tw-h-auto{
    height: auto
  }

  .sm\:tw-h-px{
    height: 1px
  }

  .sm\:tw-h-full{
    height: 100%
  }

  .sm\:tw-h-screen{
    height: 100vh
  }

  .sm\:tw-text-xs{
    font-size: 0.75rem
  }

  .sm\:tw-text-sm{
    font-size: 0.875rem
  }

  .sm\:tw-text-base{
    font-size: 1rem
  }

  .sm\:tw-text-lg{
    font-size: 1.125rem
  }

  .sm\:tw-text-xl{
    font-size: 1.25rem
  }

  .sm\:tw-text-2xl{
    font-size: 1.5rem
  }

  .sm\:tw-text-3xl{
    font-size: 1.875rem
  }

  .sm\:tw-text-4xl{
    font-size: 2.25rem
  }

  .sm\:tw-text-5xl{
    font-size: 3rem
  }

  .sm\:tw-text-6xl{
    font-size: 4rem
  }

  .sm\:tw-leading-none{
    line-height: 1
  }

  .sm\:tw-leading-tight{
    line-height: 1.25
  }

  .sm\:tw-leading-snug{
    line-height: 1.375
  }

  .sm\:tw-leading-normal{
    line-height: 1.5
  }

  .sm\:tw-leading-relaxed{
    line-height: 1.625
  }

  .sm\:tw-leading-loose{
    line-height: 2
  }

  .sm\:tw-list-inside{
    list-style-position: inside
  }

  .sm\:tw-list-outside{
    list-style-position: outside
  }

  .sm\:tw-list-none{
    list-style-type: none
  }

  .sm\:tw-list-disc{
    list-style-type: disc
  }

  .sm\:tw-list-decimal{
    list-style-type: decimal
  }

  .sm\:tw-m-0{
    margin: 0
  }

  .sm\:tw-m-1{
    margin: 0.25rem
  }

  .sm\:tw-m-2{
    margin: 0.5rem
  }

  .sm\:tw-m-3{
    margin: 0.75rem
  }

  .sm\:tw-m-4{
    margin: 1rem
  }

  .sm\:tw-m-5{
    margin: 1.25rem
  }

  .sm\:tw-m-6{
    margin: 1.5rem
  }

  .sm\:tw-m-8{
    margin: 2rem
  }

  .sm\:tw-m-10{
    margin: 2.5rem
  }

  .sm\:tw-m-12{
    margin: 3rem
  }

  .sm\:tw-m-16{
    margin: 4rem
  }

  .sm\:tw-m-20{
    margin: 5rem
  }

  .sm\:tw-m-24{
    margin: 6rem
  }

  .sm\:tw-m-32{
    margin: 8rem
  }

  .sm\:tw-m-40{
    margin: 10rem
  }

  .sm\:tw-m-48{
    margin: 12rem
  }

  .sm\:tw-m-56{
    margin: 14rem
  }

  .sm\:tw-m-64{
    margin: 16rem
  }

  .sm\:tw-m-auto{
    margin: auto
  }

  .sm\:tw-m-px{
    margin: 1px
  }

  .sm\:tw--m-1{
    margin: -0.25rem
  }

  .sm\:tw--m-2{
    margin: -0.5rem
  }

  .sm\:tw--m-3{
    margin: -0.75rem
  }

  .sm\:tw--m-4{
    margin: -1rem
  }

  .sm\:tw--m-5{
    margin: -1.25rem
  }

  .sm\:tw--m-6{
    margin: -1.5rem
  }

  .sm\:tw--m-8{
    margin: -2rem
  }

  .sm\:tw--m-10{
    margin: -2.5rem
  }

  .sm\:tw--m-12{
    margin: -3rem
  }

  .sm\:tw--m-16{
    margin: -4rem
  }

  .sm\:tw--m-20{
    margin: -5rem
  }

  .sm\:tw--m-24{
    margin: -6rem
  }

  .sm\:tw--m-32{
    margin: -8rem
  }

  .sm\:tw--m-40{
    margin: -10rem
  }

  .sm\:tw--m-48{
    margin: -12rem
  }

  .sm\:tw--m-56{
    margin: -14rem
  }

  .sm\:tw--m-64{
    margin: -16rem
  }

  .sm\:tw--m-px{
    margin: -1px
  }

  .sm\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0
  }

  .sm\:tw-mx-0{
    margin-left: 0;
    margin-right: 0
  }

  .sm\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .sm\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .sm\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .sm\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .sm\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .sm\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .sm\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .sm\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  .sm\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .sm\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .sm\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .sm\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .sm\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .sm\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  .sm\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .sm\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .sm\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .sm\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  .sm\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .sm\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  .sm\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .sm\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  .sm\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .sm\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  .sm\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .sm\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  .sm\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .sm\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  .sm\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .sm\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  .sm\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .sm\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  .sm\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .sm\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  .sm\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  .sm\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  .sm\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  .sm\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  .sm\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .sm\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .sm\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .sm\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .sm\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .sm\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .sm\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .sm\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  .sm\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .sm\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .sm\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .sm\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .sm\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .sm\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  .sm\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .sm\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .sm\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .sm\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  .sm\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .sm\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  .sm\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .sm\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  .sm\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .sm\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  .sm\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .sm\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  .sm\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .sm\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  .sm\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .sm\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  .sm\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .sm\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  .sm\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .sm\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  .sm\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  .sm\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  .sm\:tw-mt-0{
    margin-top: 0
  }

  .sm\:tw-mr-0{
    margin-right: 0
  }

  .sm\:tw-mb-0{
    margin-bottom: 0
  }

  .sm\:tw-ml-0{
    margin-left: 0
  }

  .sm\:tw-mt-1{
    margin-top: 0.25rem
  }

  .sm\:tw-mr-1{
    margin-right: 0.25rem
  }

  .sm\:tw-mb-1{
    margin-bottom: 0.25rem
  }

  .sm\:tw-ml-1{
    margin-left: 0.25rem
  }

  .sm\:tw-mt-2{
    margin-top: 0.5rem
  }

  .sm\:tw-mr-2{
    margin-right: 0.5rem
  }

  .sm\:tw-mb-2{
    margin-bottom: 0.5rem
  }

  .sm\:tw-ml-2{
    margin-left: 0.5rem
  }

  .sm\:tw-mt-3{
    margin-top: 0.75rem
  }

  .sm\:tw-mr-3{
    margin-right: 0.75rem
  }

  .sm\:tw-mb-3{
    margin-bottom: 0.75rem
  }

  .sm\:tw-ml-3{
    margin-left: 0.75rem
  }

  .sm\:tw-mt-4{
    margin-top: 1rem
  }

  .sm\:tw-mr-4{
    margin-right: 1rem
  }

  .sm\:tw-mb-4{
    margin-bottom: 1rem
  }

  .sm\:tw-ml-4{
    margin-left: 1rem
  }

  .sm\:tw-mt-5{
    margin-top: 1.25rem
  }

  .sm\:tw-mr-5{
    margin-right: 1.25rem
  }

  .sm\:tw-mb-5{
    margin-bottom: 1.25rem
  }

  .sm\:tw-ml-5{
    margin-left: 1.25rem
  }

  .sm\:tw-mt-6{
    margin-top: 1.5rem
  }

  .sm\:tw-mr-6{
    margin-right: 1.5rem
  }

  .sm\:tw-mb-6{
    margin-bottom: 1.5rem
  }

  .sm\:tw-ml-6{
    margin-left: 1.5rem
  }

  .sm\:tw-mt-8{
    margin-top: 2rem
  }

  .sm\:tw-mr-8{
    margin-right: 2rem
  }

  .sm\:tw-mb-8{
    margin-bottom: 2rem
  }

  .sm\:tw-ml-8{
    margin-left: 2rem
  }

  .sm\:tw-mt-10{
    margin-top: 2.5rem
  }

  .sm\:tw-mr-10{
    margin-right: 2.5rem
  }

  .sm\:tw-mb-10{
    margin-bottom: 2.5rem
  }

  .sm\:tw-ml-10{
    margin-left: 2.5rem
  }

  .sm\:tw-mt-12{
    margin-top: 3rem
  }

  .sm\:tw-mr-12{
    margin-right: 3rem
  }

  .sm\:tw-mb-12{
    margin-bottom: 3rem
  }

  .sm\:tw-ml-12{
    margin-left: 3rem
  }

  .sm\:tw-mt-16{
    margin-top: 4rem
  }

  .sm\:tw-mr-16{
    margin-right: 4rem
  }

  .sm\:tw-mb-16{
    margin-bottom: 4rem
  }

  .sm\:tw-ml-16{
    margin-left: 4rem
  }

  .sm\:tw-mt-20{
    margin-top: 5rem
  }

  .sm\:tw-mr-20{
    margin-right: 5rem
  }

  .sm\:tw-mb-20{
    margin-bottom: 5rem
  }

  .sm\:tw-ml-20{
    margin-left: 5rem
  }

  .sm\:tw-mt-24{
    margin-top: 6rem
  }

  .sm\:tw-mr-24{
    margin-right: 6rem
  }

  .sm\:tw-mb-24{
    margin-bottom: 6rem
  }

  .sm\:tw-ml-24{
    margin-left: 6rem
  }

  .sm\:tw-mt-32{
    margin-top: 8rem
  }

  .sm\:tw-mr-32{
    margin-right: 8rem
  }

  .sm\:tw-mb-32{
    margin-bottom: 8rem
  }

  .sm\:tw-ml-32{
    margin-left: 8rem
  }

  .sm\:tw-mt-40{
    margin-top: 10rem
  }

  .sm\:tw-mr-40{
    margin-right: 10rem
  }

  .sm\:tw-mb-40{
    margin-bottom: 10rem
  }

  .sm\:tw-ml-40{
    margin-left: 10rem
  }

  .sm\:tw-mt-48{
    margin-top: 12rem
  }

  .sm\:tw-mr-48{
    margin-right: 12rem
  }

  .sm\:tw-mb-48{
    margin-bottom: 12rem
  }

  .sm\:tw-ml-48{
    margin-left: 12rem
  }

  .sm\:tw-mt-56{
    margin-top: 14rem
  }

  .sm\:tw-mr-56{
    margin-right: 14rem
  }

  .sm\:tw-mb-56{
    margin-bottom: 14rem
  }

  .sm\:tw-ml-56{
    margin-left: 14rem
  }

  .sm\:tw-mt-64{
    margin-top: 16rem
  }

  .sm\:tw-mr-64{
    margin-right: 16rem
  }

  .sm\:tw-mb-64{
    margin-bottom: 16rem
  }

  .sm\:tw-ml-64{
    margin-left: 16rem
  }

  .sm\:tw-mt-auto{
    margin-top: auto
  }

  .sm\:tw-mr-auto{
    margin-right: auto
  }

  .sm\:tw-mb-auto{
    margin-bottom: auto
  }

  .sm\:tw-ml-auto{
    margin-left: auto
  }

  .sm\:tw-mt-px{
    margin-top: 1px
  }

  .sm\:tw-mr-px{
    margin-right: 1px
  }

  .sm\:tw-mb-px{
    margin-bottom: 1px
  }

  .sm\:tw-ml-px{
    margin-left: 1px
  }

  .sm\:tw--mt-1{
    margin-top: -0.25rem
  }

  .sm\:tw--mr-1{
    margin-right: -0.25rem
  }

  .sm\:tw--mb-1{
    margin-bottom: -0.25rem
  }

  .sm\:tw--ml-1{
    margin-left: -0.25rem
  }

  .sm\:tw--mt-2{
    margin-top: -0.5rem
  }

  .sm\:tw--mr-2{
    margin-right: -0.5rem
  }

  .sm\:tw--mb-2{
    margin-bottom: -0.5rem
  }

  .sm\:tw--ml-2{
    margin-left: -0.5rem
  }

  .sm\:tw--mt-3{
    margin-top: -0.75rem
  }

  .sm\:tw--mr-3{
    margin-right: -0.75rem
  }

  .sm\:tw--mb-3{
    margin-bottom: -0.75rem
  }

  .sm\:tw--ml-3{
    margin-left: -0.75rem
  }

  .sm\:tw--mt-4{
    margin-top: -1rem
  }

  .sm\:tw--mr-4{
    margin-right: -1rem
  }

  .sm\:tw--mb-4{
    margin-bottom: -1rem
  }

  .sm\:tw--ml-4{
    margin-left: -1rem
  }

  .sm\:tw--mt-5{
    margin-top: -1.25rem
  }

  .sm\:tw--mr-5{
    margin-right: -1.25rem
  }

  .sm\:tw--mb-5{
    margin-bottom: -1.25rem
  }

  .sm\:tw--ml-5{
    margin-left: -1.25rem
  }

  .sm\:tw--mt-6{
    margin-top: -1.5rem
  }

  .sm\:tw--mr-6{
    margin-right: -1.5rem
  }

  .sm\:tw--mb-6{
    margin-bottom: -1.5rem
  }

  .sm\:tw--ml-6{
    margin-left: -1.5rem
  }

  .sm\:tw--mt-8{
    margin-top: -2rem
  }

  .sm\:tw--mr-8{
    margin-right: -2rem
  }

  .sm\:tw--mb-8{
    margin-bottom: -2rem
  }

  .sm\:tw--ml-8{
    margin-left: -2rem
  }

  .sm\:tw--mt-10{
    margin-top: -2.5rem
  }

  .sm\:tw--mr-10{
    margin-right: -2.5rem
  }

  .sm\:tw--mb-10{
    margin-bottom: -2.5rem
  }

  .sm\:tw--ml-10{
    margin-left: -2.5rem
  }

  .sm\:tw--mt-12{
    margin-top: -3rem
  }

  .sm\:tw--mr-12{
    margin-right: -3rem
  }

  .sm\:tw--mb-12{
    margin-bottom: -3rem
  }

  .sm\:tw--ml-12{
    margin-left: -3rem
  }

  .sm\:tw--mt-16{
    margin-top: -4rem
  }

  .sm\:tw--mr-16{
    margin-right: -4rem
  }

  .sm\:tw--mb-16{
    margin-bottom: -4rem
  }

  .sm\:tw--ml-16{
    margin-left: -4rem
  }

  .sm\:tw--mt-20{
    margin-top: -5rem
  }

  .sm\:tw--mr-20{
    margin-right: -5rem
  }

  .sm\:tw--mb-20{
    margin-bottom: -5rem
  }

  .sm\:tw--ml-20{
    margin-left: -5rem
  }

  .sm\:tw--mt-24{
    margin-top: -6rem
  }

  .sm\:tw--mr-24{
    margin-right: -6rem
  }

  .sm\:tw--mb-24{
    margin-bottom: -6rem
  }

  .sm\:tw--ml-24{
    margin-left: -6rem
  }

  .sm\:tw--mt-32{
    margin-top: -8rem
  }

  .sm\:tw--mr-32{
    margin-right: -8rem
  }

  .sm\:tw--mb-32{
    margin-bottom: -8rem
  }

  .sm\:tw--ml-32{
    margin-left: -8rem
  }

  .sm\:tw--mt-40{
    margin-top: -10rem
  }

  .sm\:tw--mr-40{
    margin-right: -10rem
  }

  .sm\:tw--mb-40{
    margin-bottom: -10rem
  }

  .sm\:tw--ml-40{
    margin-left: -10rem
  }

  .sm\:tw--mt-48{
    margin-top: -12rem
  }

  .sm\:tw--mr-48{
    margin-right: -12rem
  }

  .sm\:tw--mb-48{
    margin-bottom: -12rem
  }

  .sm\:tw--ml-48{
    margin-left: -12rem
  }

  .sm\:tw--mt-56{
    margin-top: -14rem
  }

  .sm\:tw--mr-56{
    margin-right: -14rem
  }

  .sm\:tw--mb-56{
    margin-bottom: -14rem
  }

  .sm\:tw--ml-56{
    margin-left: -14rem
  }

  .sm\:tw--mt-64{
    margin-top: -16rem
  }

  .sm\:tw--mr-64{
    margin-right: -16rem
  }

  .sm\:tw--mb-64{
    margin-bottom: -16rem
  }

  .sm\:tw--ml-64{
    margin-left: -16rem
  }

  .sm\:tw--mt-px{
    margin-top: -1px
  }

  .sm\:tw--mr-px{
    margin-right: -1px
  }

  .sm\:tw--mb-px{
    margin-bottom: -1px
  }

  .sm\:tw--ml-px{
    margin-left: -1px
  }

  .sm\:tw-max-h-full{
    max-height: 100%
  }

  .sm\:tw-max-h-screen{
    max-height: 100vh
  }

  .sm\:tw-max-w-xs{
    max-width: 20rem
  }

  .sm\:tw-max-w-sm{
    max-width: 24rem
  }

  .sm\:tw-max-w-md{
    max-width: 28rem
  }

  .sm\:tw-max-w-lg{
    max-width: 32rem
  }

  .sm\:tw-max-w-xl{
    max-width: 36rem
  }

  .sm\:tw-max-w-2xl{
    max-width: 42rem
  }

  .sm\:tw-max-w-3xl{
    max-width: 48rem
  }

  .sm\:tw-max-w-4xl{
    max-width: 56rem
  }

  .sm\:tw-max-w-5xl{
    max-width: 64rem
  }

  .sm\:tw-max-w-6xl{
    max-width: 72rem
  }

  .sm\:tw-max-w-full{
    max-width: 100%
  }

  .sm\:tw-min-h-0{
    min-height: 0
  }

  .sm\:tw-min-h-full{
    min-height: 100%
  }

  .sm\:tw-min-h-screen{
    min-height: 100vh
  }

  .sm\:tw-min-w-0{
    min-width: 0
  }

  .sm\:tw-min-w-full{
    min-width: 100%
  }

  .sm\:tw-object-contain{
    object-fit: contain
  }

  .sm\:tw-object-cover{
    object-fit: cover
  }

  .sm\:tw-object-fill{
    object-fit: fill
  }

  .sm\:tw-object-none{
    object-fit: none
  }

  .sm\:tw-object-scale-down{
    object-fit: scale-down
  }

  .sm\:tw-object-bottom{
    object-position: bottom
  }

  .sm\:tw-object-center{
    object-position: center
  }

  .sm\:tw-object-left{
    object-position: left
  }

  .sm\:tw-object-left-bottom{
    object-position: left bottom
  }

  .sm\:tw-object-left-top{
    object-position: left top
  }

  .sm\:tw-object-right{
    object-position: right
  }

  .sm\:tw-object-right-bottom{
    object-position: right bottom
  }

  .sm\:tw-object-right-top{
    object-position: right top
  }

  .sm\:tw-object-top{
    object-position: top
  }

  .sm\:tw-opacity-0{
    opacity: 0
  }

  .sm\:tw-opacity-25{
    opacity: 0.25
  }

  .sm\:tw-opacity-50{
    opacity: 0.5
  }

  .sm\:tw-opacity-75{
    opacity: 0.75
  }

  .sm\:tw-opacity-100{
    opacity: 1
  }

  .sm\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:tw-overflow-auto{
    overflow: auto
  }

  .sm\:tw-overflow-hidden{
    overflow: hidden
  }

  .sm\:tw-overflow-visible{
    overflow: visible
  }

  .sm\:tw-overflow-scroll{
    overflow: scroll
  }

  .sm\:tw-overflow-x-auto{
    overflow-x: auto
  }

  .sm\:tw-overflow-y-auto{
    overflow-y: auto
  }

  .sm\:tw-overflow-x-hidden{
    overflow-x: hidden
  }

  .sm\:tw-overflow-y-hidden{
    overflow-y: hidden
  }

  .sm\:tw-overflow-x-visible{
    overflow-x: visible
  }

  .sm\:tw-overflow-y-visible{
    overflow-y: visible
  }

  .sm\:tw-overflow-x-scroll{
    overflow-x: scroll
  }

  .sm\:tw-overflow-y-scroll{
    overflow-y: scroll
  }

  .sm\:tw-scrolling-touch{
    -webkit-overflow-scrolling: touch
  }

  .sm\:tw-scrolling-auto{
    -webkit-overflow-scrolling: auto
  }

  .sm\:tw-overscroll-auto{
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .sm\:tw-overscroll-contain{
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .sm\:tw-overscroll-none{
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .sm\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  .sm\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  .sm\:tw-overscroll-y-none{
    overscroll-behavior-y: none
  }

  .sm\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  .sm\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  .sm\:tw-overscroll-x-none{
    overscroll-behavior-x: none
  }

  .sm\:tw-p-0{
    padding: 0
  }

  .sm\:tw-p-1{
    padding: 0.25rem
  }

  .sm\:tw-p-2{
    padding: 0.5rem
  }

  .sm\:tw-p-3{
    padding: 0.75rem
  }

  .sm\:tw-p-4{
    padding: 1rem
  }

  .sm\:tw-p-5{
    padding: 1.25rem
  }

  .sm\:tw-p-6{
    padding: 1.5rem
  }

  .sm\:tw-p-8{
    padding: 2rem
  }

  .sm\:tw-p-10{
    padding: 2.5rem
  }

  .sm\:tw-p-12{
    padding: 3rem
  }

  .sm\:tw-p-16{
    padding: 4rem
  }

  .sm\:tw-p-20{
    padding: 5rem
  }

  .sm\:tw-p-24{
    padding: 6rem
  }

  .sm\:tw-p-32{
    padding: 8rem
  }

  .sm\:tw-p-40{
    padding: 10rem
  }

  .sm\:tw-p-48{
    padding: 12rem
  }

  .sm\:tw-p-56{
    padding: 14rem
  }

  .sm\:tw-p-64{
    padding: 16rem
  }

  .sm\:tw-p-px{
    padding: 1px
  }

  .sm\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0
  }

  .sm\:tw-px-0{
    padding-left: 0;
    padding-right: 0
  }

  .sm\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .sm\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .sm\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .sm\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .sm\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .sm\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .sm\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .sm\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  .sm\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .sm\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .sm\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .sm\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .sm\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .sm\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .sm\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  .sm\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .sm\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  .sm\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .sm\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  .sm\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  .sm\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  .sm\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .sm\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  .sm\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .sm\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  .sm\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .sm\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  .sm\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .sm\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  .sm\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  .sm\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  .sm\:tw-pt-0{
    padding-top: 0
  }

  .sm\:tw-pr-0{
    padding-right: 0
  }

  .sm\:tw-pb-0{
    padding-bottom: 0
  }

  .sm\:tw-pl-0{
    padding-left: 0
  }

  .sm\:tw-pt-1{
    padding-top: 0.25rem
  }

  .sm\:tw-pr-1{
    padding-right: 0.25rem
  }

  .sm\:tw-pb-1{
    padding-bottom: 0.25rem
  }

  .sm\:tw-pl-1{
    padding-left: 0.25rem
  }

  .sm\:tw-pt-2{
    padding-top: 0.5rem
  }

  .sm\:tw-pr-2{
    padding-right: 0.5rem
  }

  .sm\:tw-pb-2{
    padding-bottom: 0.5rem
  }

  .sm\:tw-pl-2{
    padding-left: 0.5rem
  }

  .sm\:tw-pt-3{
    padding-top: 0.75rem
  }

  .sm\:tw-pr-3{
    padding-right: 0.75rem
  }

  .sm\:tw-pb-3{
    padding-bottom: 0.75rem
  }

  .sm\:tw-pl-3{
    padding-left: 0.75rem
  }

  .sm\:tw-pt-4{
    padding-top: 1rem
  }

  .sm\:tw-pr-4{
    padding-right: 1rem
  }

  .sm\:tw-pb-4{
    padding-bottom: 1rem
  }

  .sm\:tw-pl-4{
    padding-left: 1rem
  }

  .sm\:tw-pt-5{
    padding-top: 1.25rem
  }

  .sm\:tw-pr-5{
    padding-right: 1.25rem
  }

  .sm\:tw-pb-5{
    padding-bottom: 1.25rem
  }

  .sm\:tw-pl-5{
    padding-left: 1.25rem
  }

  .sm\:tw-pt-6{
    padding-top: 1.5rem
  }

  .sm\:tw-pr-6{
    padding-right: 1.5rem
  }

  .sm\:tw-pb-6{
    padding-bottom: 1.5rem
  }

  .sm\:tw-pl-6{
    padding-left: 1.5rem
  }

  .sm\:tw-pt-8{
    padding-top: 2rem
  }

  .sm\:tw-pr-8{
    padding-right: 2rem
  }

  .sm\:tw-pb-8{
    padding-bottom: 2rem
  }

  .sm\:tw-pl-8{
    padding-left: 2rem
  }

  .sm\:tw-pt-10{
    padding-top: 2.5rem
  }

  .sm\:tw-pr-10{
    padding-right: 2.5rem
  }

  .sm\:tw-pb-10{
    padding-bottom: 2.5rem
  }

  .sm\:tw-pl-10{
    padding-left: 2.5rem
  }

  .sm\:tw-pt-12{
    padding-top: 3rem
  }

  .sm\:tw-pr-12{
    padding-right: 3rem
  }

  .sm\:tw-pb-12{
    padding-bottom: 3rem
  }

  .sm\:tw-pl-12{
    padding-left: 3rem
  }

  .sm\:tw-pt-16{
    padding-top: 4rem
  }

  .sm\:tw-pr-16{
    padding-right: 4rem
  }

  .sm\:tw-pb-16{
    padding-bottom: 4rem
  }

  .sm\:tw-pl-16{
    padding-left: 4rem
  }

  .sm\:tw-pt-20{
    padding-top: 5rem
  }

  .sm\:tw-pr-20{
    padding-right: 5rem
  }

  .sm\:tw-pb-20{
    padding-bottom: 5rem
  }

  .sm\:tw-pl-20{
    padding-left: 5rem
  }

  .sm\:tw-pt-24{
    padding-top: 6rem
  }

  .sm\:tw-pr-24{
    padding-right: 6rem
  }

  .sm\:tw-pb-24{
    padding-bottom: 6rem
  }

  .sm\:tw-pl-24{
    padding-left: 6rem
  }

  .sm\:tw-pt-32{
    padding-top: 8rem
  }

  .sm\:tw-pr-32{
    padding-right: 8rem
  }

  .sm\:tw-pb-32{
    padding-bottom: 8rem
  }

  .sm\:tw-pl-32{
    padding-left: 8rem
  }

  .sm\:tw-pt-40{
    padding-top: 10rem
  }

  .sm\:tw-pr-40{
    padding-right: 10rem
  }

  .sm\:tw-pb-40{
    padding-bottom: 10rem
  }

  .sm\:tw-pl-40{
    padding-left: 10rem
  }

  .sm\:tw-pt-48{
    padding-top: 12rem
  }

  .sm\:tw-pr-48{
    padding-right: 12rem
  }

  .sm\:tw-pb-48{
    padding-bottom: 12rem
  }

  .sm\:tw-pl-48{
    padding-left: 12rem
  }

  .sm\:tw-pt-56{
    padding-top: 14rem
  }

  .sm\:tw-pr-56{
    padding-right: 14rem
  }

  .sm\:tw-pb-56{
    padding-bottom: 14rem
  }

  .sm\:tw-pl-56{
    padding-left: 14rem
  }

  .sm\:tw-pt-64{
    padding-top: 16rem
  }

  .sm\:tw-pr-64{
    padding-right: 16rem
  }

  .sm\:tw-pb-64{
    padding-bottom: 16rem
  }

  .sm\:tw-pl-64{
    padding-left: 16rem
  }

  .sm\:tw-pt-px{
    padding-top: 1px
  }

  .sm\:tw-pr-px{
    padding-right: 1px
  }

  .sm\:tw-pb-px{
    padding-bottom: 1px
  }

  .sm\:tw-pl-px{
    padding-left: 1px
  }

  .sm\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent
  }

  .sm\:tw-placeholder-transparent::-moz-placeholder{
    color: transparent
  }

  .sm\:tw-placeholder-transparent:-ms-input-placeholder{
    color: transparent
  }

  .sm\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent
  }

  .sm\:tw-placeholder-transparent::placeholder{
    color: transparent
  }

  .sm\:tw-placeholder-tlg-green-dark::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green-dark::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green-dark:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green-dark::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green-dark::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-green::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-tlg-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-black::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-black::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-black:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-black::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-black::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-white::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-white::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-white:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-white::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-white::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-100::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-200::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-300::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-400::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-500::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-600::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-700::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-800::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-gray-900::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-300::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-400::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-500::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-600::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-700::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-800::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-red-900::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-200::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-600::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-700::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-800::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-orange-900::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-200::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-300::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-500::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-600::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-700::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-800::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-yellow-900::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-100::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-200::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-300::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-400::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-500::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-600::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-700::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-800::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-green-900::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-100::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-200::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-300::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-400::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-500::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-600::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-700::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-800::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-teal-900::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-200::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-300::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-400::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-500::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-600::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-700::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-800::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-blue-900::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-200::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-300::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-400::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-500::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-600::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-700::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-800::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-indigo-900::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-100::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-200::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-300::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-400::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-500::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-600::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-700::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-800::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-purple-900::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-400::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-600::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-700::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-800::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-pink-900::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent
  }

  .sm\:focus\:tw-placeholder-transparent:focus::-moz-placeholder{
    color: transparent
  }

  .sm\:focus\:tw-placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent
  }

  .sm\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent
  }

  .sm\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  .sm\:focus\:tw-placeholder-tlg-green-dark:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green-dark:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green-dark:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green-dark:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green-dark:focus::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-green:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-tlg-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-black:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-black:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-black:focus::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-white:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-white:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-white:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-red-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-orange-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-green-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-teal-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-purple-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:focus\:tw-placeholder-pink-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .sm\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:tw-placeholder-opacity-0::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:tw-placeholder-opacity-0:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:tw-placeholder-opacity-0::placeholder{
    --placeholder-opacity: 0
  }

  .sm\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:tw-placeholder-opacity-25::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:tw-placeholder-opacity-25:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:tw-placeholder-opacity-25::placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:tw-placeholder-opacity-50::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:tw-placeholder-opacity-50:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:tw-placeholder-opacity-50::placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:tw-placeholder-opacity-75::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:tw-placeholder-opacity-75:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:tw-placeholder-opacity-75::placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:tw-placeholder-opacity-100::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:tw-placeholder-opacity-100:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:tw-placeholder-opacity-100::placeholder{
    --placeholder-opacity: 1
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --placeholder-opacity: 0
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --placeholder-opacity: 1
  }

  .sm\:tw-pointer-events-none{
    pointer-events: none
  }

  .sm\:tw-pointer-events-auto{
    pointer-events: auto
  }

  .sm\:tw-static{
    position: static
  }

  .sm\:tw-fixed{
    position: fixed
  }

  .sm\:tw-absolute{
    position: absolute
  }

  .sm\:tw-relative{
    position: relative
  }

  .sm\:tw-sticky{
    position: -webkit-sticky;
    position: sticky
  }

  .sm\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .sm\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .sm\:tw-inset-y-0{
    top: 0;
    bottom: 0
  }

  .sm\:tw-inset-x-0{
    right: 0;
    left: 0
  }

  .sm\:tw-inset-y-auto{
    top: auto;
    bottom: auto
  }

  .sm\:tw-inset-x-auto{
    right: auto;
    left: auto
  }

  .sm\:tw-top-0{
    top: 0
  }

  .sm\:tw-right-0{
    right: 0
  }

  .sm\:tw-bottom-0{
    bottom: 0
  }

  .sm\:tw-left-0{
    left: 0
  }

  .sm\:tw-top-auto{
    top: auto
  }

  .sm\:tw-right-auto{
    right: auto
  }

  .sm\:tw-bottom-auto{
    bottom: auto
  }

  .sm\:tw-left-auto{
    left: auto
  }

  .sm\:tw-resize-none{
    resize: none
  }

  .sm\:tw-resize-y{
    resize: vertical
  }

  .sm\:tw-resize-x{
    resize: horizontal
  }

  .sm\:tw-resize{
    resize: both
  }

  .sm\:tw-shadow{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:tw-shadow-md{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:tw-shadow-lg{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:tw-shadow-xl{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:tw-shadow-2xl{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:tw-shadow-inner{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:tw-shadow-outline{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:tw-shadow-none{
    box-shadow: none
  }

  .sm\:hover\:tw-shadow:hover{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:tw-shadow-md:hover{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:tw-shadow-lg:hover{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:tw-shadow-xl:hover{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:tw-shadow-2xl:hover{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:hover\:tw-shadow-inner:hover{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:tw-shadow-outline:hover{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:hover\:tw-shadow-none:hover{
    box-shadow: none
  }

  .sm\:focus\:tw-shadow:focus{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:tw-shadow-md:focus{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:tw-shadow-lg:focus{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:tw-shadow-xl:focus{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:tw-shadow-2xl:focus{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:focus\:tw-shadow-inner:focus{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:tw-shadow-outline:focus{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:focus\:tw-shadow-none:focus{
    box-shadow: none
  }

  .sm\:tw-fill-current{
    fill: currentColor
  }

  .sm\:tw-stroke-current{
    stroke: currentColor
  }

  .sm\:tw-stroke-0{
    stroke-width: 0
  }

  .sm\:tw-stroke-1{
    stroke-width: 1
  }

  .sm\:tw-stroke-2{
    stroke-width: 2
  }

  .sm\:tw-table-auto{
    table-layout: auto
  }

  .sm\:tw-table-fixed{
    table-layout: fixed
  }

  .sm\:tw-text-left{
    text-align: left
  }

  .sm\:tw-text-center{
    text-align: center
  }

  .sm\:tw-text-right{
    text-align: right
  }

  .sm\:tw-text-justify{
    text-align: justify
  }

  .sm\:tw-text-transparent{
    color: transparent
  }

  .sm\:tw-text-tlg-green-dark{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .sm\:tw-text-tlg-green{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .sm\:tw-text-tlg-orange-500{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .sm\:tw-text-black{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:tw-text-white{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:tw-text-gray-100{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .sm\:tw-text-gray-200{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .sm\:tw-text-gray-300{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .sm\:tw-text-gray-400{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .sm\:tw-text-gray-500{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .sm\:tw-text-gray-600{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .sm\:tw-text-gray-700{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .sm\:tw-text-gray-800{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .sm\:tw-text-gray-900{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .sm\:tw-text-red-100{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .sm\:tw-text-red-200{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .sm\:tw-text-red-300{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .sm\:tw-text-red-400{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .sm\:tw-text-red-500{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .sm\:tw-text-red-600{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .sm\:tw-text-red-700{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .sm\:tw-text-red-800{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .sm\:tw-text-red-900{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .sm\:tw-text-orange-100{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .sm\:tw-text-orange-200{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .sm\:tw-text-orange-300{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .sm\:tw-text-orange-400{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .sm\:tw-text-orange-500{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .sm\:tw-text-orange-600{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .sm\:tw-text-orange-700{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .sm\:tw-text-orange-800{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .sm\:tw-text-orange-900{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .sm\:tw-text-yellow-100{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .sm\:tw-text-yellow-200{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .sm\:tw-text-yellow-300{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .sm\:tw-text-yellow-400{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .sm\:tw-text-yellow-500{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .sm\:tw-text-yellow-600{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .sm\:tw-text-yellow-700{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .sm\:tw-text-yellow-800{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .sm\:tw-text-yellow-900{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .sm\:tw-text-green-100{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .sm\:tw-text-green-200{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .sm\:tw-text-green-300{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .sm\:tw-text-green-400{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .sm\:tw-text-green-500{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .sm\:tw-text-green-600{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .sm\:tw-text-green-700{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .sm\:tw-text-green-800{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .sm\:tw-text-green-900{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .sm\:tw-text-teal-100{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .sm\:tw-text-teal-200{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .sm\:tw-text-teal-300{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .sm\:tw-text-teal-400{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .sm\:tw-text-teal-500{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .sm\:tw-text-teal-600{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .sm\:tw-text-teal-700{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .sm\:tw-text-teal-800{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .sm\:tw-text-teal-900{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .sm\:tw-text-blue-100{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .sm\:tw-text-blue-200{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .sm\:tw-text-blue-300{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .sm\:tw-text-blue-400{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .sm\:tw-text-blue-500{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .sm\:tw-text-blue-600{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .sm\:tw-text-blue-700{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .sm\:tw-text-blue-800{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .sm\:tw-text-blue-900{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .sm\:tw-text-indigo-100{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .sm\:tw-text-indigo-200{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .sm\:tw-text-indigo-300{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .sm\:tw-text-indigo-400{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .sm\:tw-text-indigo-500{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .sm\:tw-text-indigo-600{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .sm\:tw-text-indigo-700{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .sm\:tw-text-indigo-800{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .sm\:tw-text-indigo-900{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .sm\:tw-text-purple-100{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .sm\:tw-text-purple-200{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .sm\:tw-text-purple-300{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .sm\:tw-text-purple-400{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .sm\:tw-text-purple-500{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .sm\:tw-text-purple-600{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .sm\:tw-text-purple-700{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .sm\:tw-text-purple-800{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .sm\:tw-text-purple-900{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .sm\:tw-text-pink-100{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .sm\:tw-text-pink-200{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .sm\:tw-text-pink-300{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .sm\:tw-text-pink-400{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .sm\:tw-text-pink-500{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .sm\:tw-text-pink-600{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .sm\:tw-text-pink-700{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .sm\:tw-text-pink-800{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .sm\:tw-text-pink-900{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .sm\:hover\:tw-text-transparent:hover{
    color: transparent
  }

  .sm\:hover\:tw-text-tlg-green-dark:hover{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .sm\:hover\:tw-text-tlg-green:hover{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .sm\:hover\:tw-text-tlg-orange-500:hover{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .sm\:hover\:tw-text-black:hover{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:hover\:tw-text-white:hover{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-100:hover{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-200:hover{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-300:hover{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-400:hover{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-500:hover{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-600:hover{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-700:hover{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-800:hover{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .sm\:hover\:tw-text-gray-900:hover{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-100:hover{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-200:hover{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-300:hover{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-400:hover{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-500:hover{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-600:hover{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-700:hover{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-800:hover{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .sm\:hover\:tw-text-red-900:hover{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-100:hover{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-200:hover{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-300:hover{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-400:hover{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-500:hover{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-600:hover{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-700:hover{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-800:hover{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .sm\:hover\:tw-text-orange-900:hover{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-100:hover{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-200:hover{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-300:hover{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-400:hover{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-500:hover{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-600:hover{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-700:hover{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-800:hover{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .sm\:hover\:tw-text-yellow-900:hover{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-100:hover{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-200:hover{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-300:hover{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-400:hover{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-500:hover{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-600:hover{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-700:hover{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-800:hover{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .sm\:hover\:tw-text-green-900:hover{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-100:hover{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-200:hover{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-300:hover{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-400:hover{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-500:hover{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-600:hover{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-700:hover{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-800:hover{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .sm\:hover\:tw-text-teal-900:hover{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-100:hover{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-200:hover{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-300:hover{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-400:hover{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-500:hover{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-600:hover{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-700:hover{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-800:hover{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .sm\:hover\:tw-text-blue-900:hover{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-100:hover{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-200:hover{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-300:hover{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-400:hover{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-500:hover{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-600:hover{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-700:hover{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-800:hover{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .sm\:hover\:tw-text-indigo-900:hover{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-100:hover{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-200:hover{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-300:hover{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-400:hover{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-500:hover{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-600:hover{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-700:hover{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-800:hover{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .sm\:hover\:tw-text-purple-900:hover{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-100:hover{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-200:hover{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-300:hover{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-400:hover{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-500:hover{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-600:hover{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-700:hover{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-800:hover{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .sm\:hover\:tw-text-pink-900:hover{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .sm\:focus\:tw-text-transparent:focus{
    color: transparent
  }

  .sm\:focus\:tw-text-tlg-green-dark:focus{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .sm\:focus\:tw-text-tlg-green:focus{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .sm\:focus\:tw-text-tlg-orange-500:focus{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .sm\:focus\:tw-text-black:focus{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:focus\:tw-text-white:focus{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-100:focus{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-200:focus{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-300:focus{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-400:focus{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-500:focus{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-600:focus{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-700:focus{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-800:focus{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .sm\:focus\:tw-text-gray-900:focus{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-100:focus{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-200:focus{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-300:focus{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-400:focus{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-500:focus{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-600:focus{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-700:focus{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-800:focus{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .sm\:focus\:tw-text-red-900:focus{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-100:focus{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-200:focus{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-300:focus{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-400:focus{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-500:focus{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-600:focus{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-700:focus{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-800:focus{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .sm\:focus\:tw-text-orange-900:focus{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-100:focus{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-200:focus{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-300:focus{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-400:focus{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-500:focus{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-600:focus{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-700:focus{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-800:focus{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .sm\:focus\:tw-text-yellow-900:focus{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-100:focus{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-200:focus{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-300:focus{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-400:focus{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-500:focus{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-600:focus{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-700:focus{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-800:focus{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .sm\:focus\:tw-text-green-900:focus{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-100:focus{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-200:focus{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-300:focus{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-400:focus{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-500:focus{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-600:focus{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-700:focus{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-800:focus{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .sm\:focus\:tw-text-teal-900:focus{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-100:focus{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-200:focus{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-300:focus{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-400:focus{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-500:focus{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-600:focus{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-700:focus{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-800:focus{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .sm\:focus\:tw-text-blue-900:focus{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-100:focus{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-200:focus{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-300:focus{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-400:focus{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-500:focus{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-600:focus{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-700:focus{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-800:focus{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .sm\:focus\:tw-text-indigo-900:focus{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-100:focus{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-200:focus{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-300:focus{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-400:focus{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-500:focus{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-600:focus{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-700:focus{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-800:focus{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .sm\:focus\:tw-text-purple-900:focus{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-100:focus{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-200:focus{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-300:focus{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-400:focus{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-500:focus{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-600:focus{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-700:focus{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-800:focus{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .sm\:focus\:tw-text-pink-900:focus{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .sm\:tw-text-opacity-0{
    --text-opacity: 0
  }

  .sm\:tw-text-opacity-25{
    --text-opacity: 0.25
  }

  .sm\:tw-text-opacity-50{
    --text-opacity: 0.5
  }

  .sm\:tw-text-opacity-75{
    --text-opacity: 0.75
  }

  .sm\:tw-text-opacity-100{
    --text-opacity: 1
  }

  .sm\:hover\:tw-text-opacity-0:hover{
    --text-opacity: 0
  }

  .sm\:hover\:tw-text-opacity-25:hover{
    --text-opacity: 0.25
  }

  .sm\:hover\:tw-text-opacity-50:hover{
    --text-opacity: 0.5
  }

  .sm\:hover\:tw-text-opacity-75:hover{
    --text-opacity: 0.75
  }

  .sm\:hover\:tw-text-opacity-100:hover{
    --text-opacity: 1
  }

  .sm\:focus\:tw-text-opacity-0:focus{
    --text-opacity: 0
  }

  .sm\:focus\:tw-text-opacity-25:focus{
    --text-opacity: 0.25
  }

  .sm\:focus\:tw-text-opacity-50:focus{
    --text-opacity: 0.5
  }

  .sm\:focus\:tw-text-opacity-75:focus{
    --text-opacity: 0.75
  }

  .sm\:focus\:tw-text-opacity-100:focus{
    --text-opacity: 1
  }

  .sm\:tw-italic{
    font-style: italic
  }

  .sm\:tw-not-italic{
    font-style: normal
  }

  .sm\:tw-uppercase{
    text-transform: uppercase
  }

  .sm\:tw-lowercase{
    text-transform: lowercase
  }

  .sm\:tw-capitalize{
    text-transform: capitalize
  }

  .sm\:tw-normal-case{
    text-transform: none
  }

  .sm\:tw-underline{
    text-decoration: underline
  }

  .sm\:tw-line-through{
    text-decoration: line-through
  }

  .sm\:tw-no-underline{
    text-decoration: none
  }

  .sm\:hover\:tw-underline:hover{
    text-decoration: underline
  }

  .sm\:hover\:tw-line-through:hover{
    text-decoration: line-through
  }

  .sm\:hover\:tw-no-underline:hover{
    text-decoration: none
  }

  .sm\:focus\:tw-underline:focus{
    text-decoration: underline
  }

  .sm\:focus\:tw-line-through:focus{
    text-decoration: line-through
  }

  .sm\:focus\:tw-no-underline:focus{
    text-decoration: none
  }

  .sm\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .sm\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .sm\:tw-ordinal, .sm\:tw-slashed-zero, .sm\:tw-lining-nums, .sm\:tw-oldstyle-nums, .sm\:tw-proportional-nums, .sm\:tw-tabular-nums, .sm\:tw-diagonal-fractions, .sm\:tw-stacked-fractions{
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    -webkit-font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
            font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .sm\:tw-normal-nums{
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-numeric: normal
  }

  .sm\:tw-ordinal{
    --font-variant-numeric-ordinal: ordinal
  }

  .sm\:tw-slashed-zero{
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .sm\:tw-lining-nums{
    --font-variant-numeric-figure: lining-nums
  }

  .sm\:tw-oldstyle-nums{
    --font-variant-numeric-figure: oldstyle-nums
  }

  .sm\:tw-proportional-nums{
    --font-variant-numeric-spacing: proportional-nums
  }

  .sm\:tw-tabular-nums{
    --font-variant-numeric-spacing: tabular-nums
  }

  .sm\:tw-diagonal-fractions{
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .sm\:tw-stacked-fractions{
    --font-variant-numeric-fraction: stacked-fractions
  }

  .sm\:tw-tracking-tighter{
    letter-spacing: -0.05em
  }

  .sm\:tw-tracking-tight{
    letter-spacing: -0.025em
  }

  .sm\:tw-tracking-normal{
    letter-spacing: 0
  }

  .sm\:tw-tracking-wide{
    letter-spacing: 0.025em
  }

  .sm\:tw-tracking-wider{
    letter-spacing: 0.05em
  }

  .sm\:tw-tracking-widest{
    letter-spacing: 0.1em
  }

  .sm\:tw-select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .sm\:tw-select-text{
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .sm\:tw-select-all{
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .sm\:tw-select-auto{
    -webkit-user-select: auto;
       -moz-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .sm\:tw-align-baseline{
    vertical-align: baseline
  }

  .sm\:tw-align-top{
    vertical-align: top
  }

  .sm\:tw-align-middle{
    vertical-align: middle
  }

  .sm\:tw-align-bottom{
    vertical-align: bottom
  }

  .sm\:tw-align-text-top{
    vertical-align: text-top
  }

  .sm\:tw-align-text-bottom{
    vertical-align: text-bottom
  }

  .sm\:tw-visible{
    visibility: visible
  }

  .sm\:tw-invisible{
    visibility: hidden
  }

  .sm\:tw-whitespace-normal{
    white-space: normal
  }

  .sm\:tw-whitespace-no-wrap{
    white-space: nowrap
  }

  .sm\:tw-whitespace-pre{
    white-space: pre
  }

  .sm\:tw-whitespace-pre-line{
    white-space: pre-line
  }

  .sm\:tw-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  .sm\:tw-break-normal{
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .sm\:tw-break-words{
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .sm\:tw-break-all{
    word-break: break-all
  }

  .sm\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm\:tw-w-0{
    width: 0
  }

  .sm\:tw-w-1{
    width: 0.25rem
  }

  .sm\:tw-w-2{
    width: 0.5rem
  }

  .sm\:tw-w-3{
    width: 0.75rem
  }

  .sm\:tw-w-4{
    width: 1rem
  }

  .sm\:tw-w-5{
    width: 1.25rem
  }

  .sm\:tw-w-6{
    width: 1.5rem
  }

  .sm\:tw-w-8{
    width: 2rem
  }

  .sm\:tw-w-10{
    width: 2.5rem
  }

  .sm\:tw-w-12{
    width: 3rem
  }

  .sm\:tw-w-16{
    width: 4rem
  }

  .sm\:tw-w-20{
    width: 5rem
  }

  .sm\:tw-w-24{
    width: 6rem
  }

  .sm\:tw-w-32{
    width: 8rem
  }

  .sm\:tw-w-40{
    width: 10rem
  }

  .sm\:tw-w-48{
    width: 12rem
  }

  .sm\:tw-w-56{
    width: 14rem
  }

  .sm\:tw-w-64{
    width: 16rem
  }

  .sm\:tw-w-auto{
    width: auto
  }

  .sm\:tw-w-px{
    width: 1px
  }

  .sm\:tw-w-1\/2{
    width: 50%
  }

  .sm\:tw-w-1\/3{
    width: 33.33333%
  }

  .sm\:tw-w-2\/3{
    width: 66.66667%
  }

  .sm\:tw-w-1\/4{
    width: 25%
  }

  .sm\:tw-w-2\/4{
    width: 50%
  }

  .sm\:tw-w-3\/4{
    width: 75%
  }

  .sm\:tw-w-1\/5{
    width: 20%
  }

  .sm\:tw-w-2\/5{
    width: 40%
  }

  .sm\:tw-w-3\/5{
    width: 60%
  }

  .sm\:tw-w-4\/5{
    width: 80%
  }

  .sm\:tw-w-1\/6{
    width: 16.66667%
  }

  .sm\:tw-w-2\/6{
    width: 33.33333%
  }

  .sm\:tw-w-3\/6{
    width: 50%
  }

  .sm\:tw-w-4\/6{
    width: 66.66667%
  }

  .sm\:tw-w-5\/6{
    width: 83.33333%
  }

  .sm\:tw-w-1\/12{
    width: 8.33333%
  }

  .sm\:tw-w-2\/12{
    width: 16.66667%
  }

  .sm\:tw-w-3\/12{
    width: 25%
  }

  .sm\:tw-w-4\/12{
    width: 33.33333%
  }

  .sm\:tw-w-5\/12{
    width: 41.66667%
  }

  .sm\:tw-w-6\/12{
    width: 50%
  }

  .sm\:tw-w-7\/12{
    width: 58.33333%
  }

  .sm\:tw-w-8\/12{
    width: 66.66667%
  }

  .sm\:tw-w-9\/12{
    width: 75%
  }

  .sm\:tw-w-10\/12{
    width: 83.33333%
  }

  .sm\:tw-w-11\/12{
    width: 91.66667%
  }

  .sm\:tw-w-full{
    width: 100%
  }

  .sm\:tw-w-screen{
    width: 100vw
  }

  .sm\:tw-z-0{
    z-index: 0
  }

  .sm\:tw-z-10{
    z-index: 10
  }

  .sm\:tw-z-20{
    z-index: 20
  }

  .sm\:tw-z-30{
    z-index: 30
  }

  .sm\:tw-z-40{
    z-index: 40
  }

  .sm\:tw-z-50{
    z-index: 50
  }

  .sm\:tw-z-auto{
    z-index: auto
  }

  .sm\:tw-gap-0{
    grid-gap: 0;
    grid-gap: 0;
    gap: 0
  }

  .sm\:tw-gap-1{
    grid-gap: 0.25rem;
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .sm\:tw-gap-2{
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .sm\:tw-gap-3{
    grid-gap: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .sm\:tw-gap-4{
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem
  }

  .sm\:tw-gap-5{
    grid-gap: 1.25rem;
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .sm\:tw-gap-6{
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .sm\:tw-gap-8{
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem
  }

  .sm\:tw-gap-10{
    grid-gap: 2.5rem;
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .sm\:tw-gap-12{
    grid-gap: 3rem;
    grid-gap: 3rem;
    gap: 3rem
  }

  .sm\:tw-gap-16{
    grid-gap: 4rem;
    grid-gap: 4rem;
    gap: 4rem
  }

  .sm\:tw-gap-20{
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem
  }

  .sm\:tw-gap-24{
    grid-gap: 6rem;
    grid-gap: 6rem;
    gap: 6rem
  }

  .sm\:tw-gap-32{
    grid-gap: 8rem;
    grid-gap: 8rem;
    gap: 8rem
  }

  .sm\:tw-gap-40{
    grid-gap: 10rem;
    grid-gap: 10rem;
    gap: 10rem
  }

  .sm\:tw-gap-48{
    grid-gap: 12rem;
    grid-gap: 12rem;
    gap: 12rem
  }

  .sm\:tw-gap-56{
    grid-gap: 14rem;
    grid-gap: 14rem;
    gap: 14rem
  }

  .sm\:tw-gap-64{
    grid-gap: 16rem;
    grid-gap: 16rem;
    gap: 16rem
  }

  .sm\:tw-gap-px{
    grid-gap: 1px;
    grid-gap: 1px;
    gap: 1px
  }

  .sm\:tw-col-gap-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .sm\:tw-col-gap-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .sm\:tw-col-gap-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .sm\:tw-col-gap-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .sm\:tw-col-gap-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .sm\:tw-col-gap-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .sm\:tw-col-gap-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .sm\:tw-col-gap-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .sm\:tw-col-gap-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .sm\:tw-col-gap-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .sm\:tw-col-gap-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .sm\:tw-col-gap-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .sm\:tw-col-gap-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .sm\:tw-col-gap-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .sm\:tw-col-gap-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .sm\:tw-col-gap-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .sm\:tw-col-gap-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .sm\:tw-col-gap-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .sm\:tw-col-gap-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .sm\:tw-gap-x-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .sm\:tw-gap-x-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .sm\:tw-gap-x-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .sm\:tw-gap-x-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .sm\:tw-gap-x-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .sm\:tw-gap-x-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .sm\:tw-gap-x-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .sm\:tw-gap-x-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .sm\:tw-gap-x-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .sm\:tw-gap-x-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .sm\:tw-gap-x-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .sm\:tw-gap-x-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .sm\:tw-gap-x-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .sm\:tw-gap-x-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .sm\:tw-gap-x-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .sm\:tw-gap-x-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .sm\:tw-gap-x-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .sm\:tw-gap-x-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .sm\:tw-gap-x-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .sm\:tw-row-gap-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .sm\:tw-row-gap-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .sm\:tw-row-gap-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .sm\:tw-row-gap-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .sm\:tw-row-gap-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .sm\:tw-row-gap-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .sm\:tw-row-gap-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .sm\:tw-row-gap-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .sm\:tw-row-gap-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .sm\:tw-row-gap-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .sm\:tw-row-gap-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .sm\:tw-row-gap-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .sm\:tw-row-gap-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .sm\:tw-row-gap-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .sm\:tw-row-gap-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .sm\:tw-row-gap-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .sm\:tw-row-gap-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .sm\:tw-row-gap-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .sm\:tw-row-gap-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .sm\:tw-gap-y-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .sm\:tw-gap-y-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .sm\:tw-gap-y-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .sm\:tw-gap-y-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .sm\:tw-gap-y-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .sm\:tw-gap-y-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .sm\:tw-gap-y-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .sm\:tw-gap-y-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .sm\:tw-gap-y-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .sm\:tw-gap-y-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .sm\:tw-gap-y-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .sm\:tw-gap-y-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .sm\:tw-gap-y-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .sm\:tw-gap-y-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .sm\:tw-gap-y-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .sm\:tw-gap-y-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .sm\:tw-gap-y-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .sm\:tw-gap-y-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .sm\:tw-gap-y-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .sm\:tw-grid-flow-row{
    grid-auto-flow: row
  }

  .sm\:tw-grid-flow-col{
    grid-auto-flow: column
  }

  .sm\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  .sm\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  .sm\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .sm\:tw-grid-cols-none{
    grid-template-columns: none
  }

  .sm\:tw-auto-cols-auto{
    grid-auto-columns: auto
  }

  .sm\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .sm\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .sm\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  .sm\:tw-col-auto{
    grid-column: auto
  }

  .sm\:tw-col-span-1{
    grid-column: span 1 / span 1
  }

  .sm\:tw-col-span-2{
    grid-column: span 2 / span 2
  }

  .sm\:tw-col-span-3{
    grid-column: span 3 / span 3
  }

  .sm\:tw-col-span-4{
    grid-column: span 4 / span 4
  }

  .sm\:tw-col-span-5{
    grid-column: span 5 / span 5
  }

  .sm\:tw-col-span-6{
    grid-column: span 6 / span 6
  }

  .sm\:tw-col-span-7{
    grid-column: span 7 / span 7
  }

  .sm\:tw-col-span-8{
    grid-column: span 8 / span 8
  }

  .sm\:tw-col-span-9{
    grid-column: span 9 / span 9
  }

  .sm\:tw-col-span-10{
    grid-column: span 10 / span 10
  }

  .sm\:tw-col-span-11{
    grid-column: span 11 / span 11
  }

  .sm\:tw-col-span-12{
    grid-column: span 12 / span 12
  }

  .sm\:tw-col-span-full{
    grid-column: 1 / -1
  }

  .sm\:tw-col-start-1{
    grid-column-start: 1
  }

  .sm\:tw-col-start-2{
    grid-column-start: 2
  }

  .sm\:tw-col-start-3{
    grid-column-start: 3
  }

  .sm\:tw-col-start-4{
    grid-column-start: 4
  }

  .sm\:tw-col-start-5{
    grid-column-start: 5
  }

  .sm\:tw-col-start-6{
    grid-column-start: 6
  }

  .sm\:tw-col-start-7{
    grid-column-start: 7
  }

  .sm\:tw-col-start-8{
    grid-column-start: 8
  }

  .sm\:tw-col-start-9{
    grid-column-start: 9
  }

  .sm\:tw-col-start-10{
    grid-column-start: 10
  }

  .sm\:tw-col-start-11{
    grid-column-start: 11
  }

  .sm\:tw-col-start-12{
    grid-column-start: 12
  }

  .sm\:tw-col-start-13{
    grid-column-start: 13
  }

  .sm\:tw-col-start-auto{
    grid-column-start: auto
  }

  .sm\:tw-col-end-1{
    grid-column-end: 1
  }

  .sm\:tw-col-end-2{
    grid-column-end: 2
  }

  .sm\:tw-col-end-3{
    grid-column-end: 3
  }

  .sm\:tw-col-end-4{
    grid-column-end: 4
  }

  .sm\:tw-col-end-5{
    grid-column-end: 5
  }

  .sm\:tw-col-end-6{
    grid-column-end: 6
  }

  .sm\:tw-col-end-7{
    grid-column-end: 7
  }

  .sm\:tw-col-end-8{
    grid-column-end: 8
  }

  .sm\:tw-col-end-9{
    grid-column-end: 9
  }

  .sm\:tw-col-end-10{
    grid-column-end: 10
  }

  .sm\:tw-col-end-11{
    grid-column-end: 11
  }

  .sm\:tw-col-end-12{
    grid-column-end: 12
  }

  .sm\:tw-col-end-13{
    grid-column-end: 13
  }

  .sm\:tw-col-end-auto{
    grid-column-end: auto
  }

  .sm\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .sm\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .sm\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .sm\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .sm\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .sm\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .sm\:tw-grid-rows-none{
    grid-template-rows: none
  }

  .sm\:tw-auto-rows-auto{
    grid-auto-rows: auto
  }

  .sm\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .sm\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .sm\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  .sm\:tw-row-auto{
    grid-row: auto
  }

  .sm\:tw-row-span-1{
    grid-row: span 1 / span 1
  }

  .sm\:tw-row-span-2{
    grid-row: span 2 / span 2
  }

  .sm\:tw-row-span-3{
    grid-row: span 3 / span 3
  }

  .sm\:tw-row-span-4{
    grid-row: span 4 / span 4
  }

  .sm\:tw-row-span-5{
    grid-row: span 5 / span 5
  }

  .sm\:tw-row-span-6{
    grid-row: span 6 / span 6
  }

  .sm\:tw-row-span-full{
    grid-row: 1 / -1
  }

  .sm\:tw-row-start-1{
    grid-row-start: 1
  }

  .sm\:tw-row-start-2{
    grid-row-start: 2
  }

  .sm\:tw-row-start-3{
    grid-row-start: 3
  }

  .sm\:tw-row-start-4{
    grid-row-start: 4
  }

  .sm\:tw-row-start-5{
    grid-row-start: 5
  }

  .sm\:tw-row-start-6{
    grid-row-start: 6
  }

  .sm\:tw-row-start-7{
    grid-row-start: 7
  }

  .sm\:tw-row-start-auto{
    grid-row-start: auto
  }

  .sm\:tw-row-end-1{
    grid-row-end: 1
  }

  .sm\:tw-row-end-2{
    grid-row-end: 2
  }

  .sm\:tw-row-end-3{
    grid-row-end: 3
  }

  .sm\:tw-row-end-4{
    grid-row-end: 4
  }

  .sm\:tw-row-end-5{
    grid-row-end: 5
  }

  .sm\:tw-row-end-6{
    grid-row-end: 6
  }

  .sm\:tw-row-end-7{
    grid-row-end: 7
  }

  .sm\:tw-row-end-auto{
    grid-row-end: auto
  }

  .sm\:tw-transform{
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .sm\:tw-transform-none{
    -webkit-transform: none;
            transform: none
  }

  .sm\:tw-origin-center{
    -webkit-transform-origin: center;
            transform-origin: center
  }

  .sm\:tw-origin-top{
    -webkit-transform-origin: top;
            transform-origin: top
  }

  .sm\:tw-origin-top-right{
    -webkit-transform-origin: top right;
            transform-origin: top right
  }

  .sm\:tw-origin-right{
    -webkit-transform-origin: right;
            transform-origin: right
  }

  .sm\:tw-origin-bottom-right{
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right
  }

  .sm\:tw-origin-bottom{
    -webkit-transform-origin: bottom;
            transform-origin: bottom
  }

  .sm\:tw-origin-bottom-left{
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left
  }

  .sm\:tw-origin-left{
    -webkit-transform-origin: left;
            transform-origin: left
  }

  .sm\:tw-origin-top-left{
    -webkit-transform-origin: top left;
            transform-origin: top left
  }

  .sm\:tw-scale-0{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:tw-scale-50{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:tw-scale-75{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:tw-scale-90{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:tw-scale-95{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:tw-scale-100{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:tw-scale-105{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:tw-scale-110{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:tw-scale-125{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:tw-scale-150{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:tw-scale-x-0{
    --transform-scale-x: 0
  }

  .sm\:tw-scale-x-50{
    --transform-scale-x: .5
  }

  .sm\:tw-scale-x-75{
    --transform-scale-x: .75
  }

  .sm\:tw-scale-x-90{
    --transform-scale-x: .9
  }

  .sm\:tw-scale-x-95{
    --transform-scale-x: .95
  }

  .sm\:tw-scale-x-100{
    --transform-scale-x: 1
  }

  .sm\:tw-scale-x-105{
    --transform-scale-x: 1.05
  }

  .sm\:tw-scale-x-110{
    --transform-scale-x: 1.1
  }

  .sm\:tw-scale-x-125{
    --transform-scale-x: 1.25
  }

  .sm\:tw-scale-x-150{
    --transform-scale-x: 1.5
  }

  .sm\:tw-scale-y-0{
    --transform-scale-y: 0
  }

  .sm\:tw-scale-y-50{
    --transform-scale-y: .5
  }

  .sm\:tw-scale-y-75{
    --transform-scale-y: .75
  }

  .sm\:tw-scale-y-90{
    --transform-scale-y: .9
  }

  .sm\:tw-scale-y-95{
    --transform-scale-y: .95
  }

  .sm\:tw-scale-y-100{
    --transform-scale-y: 1
  }

  .sm\:tw-scale-y-105{
    --transform-scale-y: 1.05
  }

  .sm\:tw-scale-y-110{
    --transform-scale-y: 1.1
  }

  .sm\:tw-scale-y-125{
    --transform-scale-y: 1.25
  }

  .sm\:tw-scale-y-150{
    --transform-scale-y: 1.5
  }

  .sm\:hover\:tw-scale-0:hover{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:hover\:tw-scale-50:hover{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:hover\:tw-scale-75:hover{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:hover\:tw-scale-90:hover{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:hover\:tw-scale-95:hover{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:hover\:tw-scale-100:hover{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:hover\:tw-scale-105:hover{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:hover\:tw-scale-110:hover{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:hover\:tw-scale-125:hover{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:hover\:tw-scale-150:hover{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:hover\:tw-scale-x-0:hover{
    --transform-scale-x: 0
  }

  .sm\:hover\:tw-scale-x-50:hover{
    --transform-scale-x: .5
  }

  .sm\:hover\:tw-scale-x-75:hover{
    --transform-scale-x: .75
  }

  .sm\:hover\:tw-scale-x-90:hover{
    --transform-scale-x: .9
  }

  .sm\:hover\:tw-scale-x-95:hover{
    --transform-scale-x: .95
  }

  .sm\:hover\:tw-scale-x-100:hover{
    --transform-scale-x: 1
  }

  .sm\:hover\:tw-scale-x-105:hover{
    --transform-scale-x: 1.05
  }

  .sm\:hover\:tw-scale-x-110:hover{
    --transform-scale-x: 1.1
  }

  .sm\:hover\:tw-scale-x-125:hover{
    --transform-scale-x: 1.25
  }

  .sm\:hover\:tw-scale-x-150:hover{
    --transform-scale-x: 1.5
  }

  .sm\:hover\:tw-scale-y-0:hover{
    --transform-scale-y: 0
  }

  .sm\:hover\:tw-scale-y-50:hover{
    --transform-scale-y: .5
  }

  .sm\:hover\:tw-scale-y-75:hover{
    --transform-scale-y: .75
  }

  .sm\:hover\:tw-scale-y-90:hover{
    --transform-scale-y: .9
  }

  .sm\:hover\:tw-scale-y-95:hover{
    --transform-scale-y: .95
  }

  .sm\:hover\:tw-scale-y-100:hover{
    --transform-scale-y: 1
  }

  .sm\:hover\:tw-scale-y-105:hover{
    --transform-scale-y: 1.05
  }

  .sm\:hover\:tw-scale-y-110:hover{
    --transform-scale-y: 1.1
  }

  .sm\:hover\:tw-scale-y-125:hover{
    --transform-scale-y: 1.25
  }

  .sm\:hover\:tw-scale-y-150:hover{
    --transform-scale-y: 1.5
  }

  .sm\:focus\:tw-scale-0:focus{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:focus\:tw-scale-50:focus{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:focus\:tw-scale-75:focus{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:focus\:tw-scale-90:focus{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:focus\:tw-scale-95:focus{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:focus\:tw-scale-100:focus{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:focus\:tw-scale-105:focus{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:focus\:tw-scale-110:focus{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:focus\:tw-scale-125:focus{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:focus\:tw-scale-150:focus{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:focus\:tw-scale-x-0:focus{
    --transform-scale-x: 0
  }

  .sm\:focus\:tw-scale-x-50:focus{
    --transform-scale-x: .5
  }

  .sm\:focus\:tw-scale-x-75:focus{
    --transform-scale-x: .75
  }

  .sm\:focus\:tw-scale-x-90:focus{
    --transform-scale-x: .9
  }

  .sm\:focus\:tw-scale-x-95:focus{
    --transform-scale-x: .95
  }

  .sm\:focus\:tw-scale-x-100:focus{
    --transform-scale-x: 1
  }

  .sm\:focus\:tw-scale-x-105:focus{
    --transform-scale-x: 1.05
  }

  .sm\:focus\:tw-scale-x-110:focus{
    --transform-scale-x: 1.1
  }

  .sm\:focus\:tw-scale-x-125:focus{
    --transform-scale-x: 1.25
  }

  .sm\:focus\:tw-scale-x-150:focus{
    --transform-scale-x: 1.5
  }

  .sm\:focus\:tw-scale-y-0:focus{
    --transform-scale-y: 0
  }

  .sm\:focus\:tw-scale-y-50:focus{
    --transform-scale-y: .5
  }

  .sm\:focus\:tw-scale-y-75:focus{
    --transform-scale-y: .75
  }

  .sm\:focus\:tw-scale-y-90:focus{
    --transform-scale-y: .9
  }

  .sm\:focus\:tw-scale-y-95:focus{
    --transform-scale-y: .95
  }

  .sm\:focus\:tw-scale-y-100:focus{
    --transform-scale-y: 1
  }

  .sm\:focus\:tw-scale-y-105:focus{
    --transform-scale-y: 1.05
  }

  .sm\:focus\:tw-scale-y-110:focus{
    --transform-scale-y: 1.1
  }

  .sm\:focus\:tw-scale-y-125:focus{
    --transform-scale-y: 1.25
  }

  .sm\:focus\:tw-scale-y-150:focus{
    --transform-scale-y: 1.5
  }

  .sm\:tw-rotate-0{
    --transform-rotate: 0
  }

  .sm\:tw-rotate-1{
    --transform-rotate: 1deg
  }

  .sm\:tw-rotate-2{
    --transform-rotate: 2deg
  }

  .sm\:tw-rotate-3{
    --transform-rotate: 3deg
  }

  .sm\:tw-rotate-6{
    --transform-rotate: 6deg
  }

  .sm\:tw-rotate-12{
    --transform-rotate: 12deg
  }

  .sm\:tw-rotate-45{
    --transform-rotate: 45deg
  }

  .sm\:tw-rotate-90{
    --transform-rotate: 90deg
  }

  .sm\:tw-rotate-180{
    --transform-rotate: 180deg
  }

  .sm\:tw--rotate-180{
    --transform-rotate: -180deg
  }

  .sm\:tw--rotate-90{
    --transform-rotate: -90deg
  }

  .sm\:tw--rotate-45{
    --transform-rotate: -45deg
  }

  .sm\:tw--rotate-12{
    --transform-rotate: -12deg
  }

  .sm\:tw--rotate-6{
    --transform-rotate: -6deg
  }

  .sm\:tw--rotate-3{
    --transform-rotate: -3deg
  }

  .sm\:tw--rotate-2{
    --transform-rotate: -2deg
  }

  .sm\:tw--rotate-1{
    --transform-rotate: -1deg
  }

  .sm\:hover\:tw-rotate-0:hover{
    --transform-rotate: 0
  }

  .sm\:hover\:tw-rotate-1:hover{
    --transform-rotate: 1deg
  }

  .sm\:hover\:tw-rotate-2:hover{
    --transform-rotate: 2deg
  }

  .sm\:hover\:tw-rotate-3:hover{
    --transform-rotate: 3deg
  }

  .sm\:hover\:tw-rotate-6:hover{
    --transform-rotate: 6deg
  }

  .sm\:hover\:tw-rotate-12:hover{
    --transform-rotate: 12deg
  }

  .sm\:hover\:tw-rotate-45:hover{
    --transform-rotate: 45deg
  }

  .sm\:hover\:tw-rotate-90:hover{
    --transform-rotate: 90deg
  }

  .sm\:hover\:tw-rotate-180:hover{
    --transform-rotate: 180deg
  }

  .sm\:hover\:tw--rotate-180:hover{
    --transform-rotate: -180deg
  }

  .sm\:hover\:tw--rotate-90:hover{
    --transform-rotate: -90deg
  }

  .sm\:hover\:tw--rotate-45:hover{
    --transform-rotate: -45deg
  }

  .sm\:hover\:tw--rotate-12:hover{
    --transform-rotate: -12deg
  }

  .sm\:hover\:tw--rotate-6:hover{
    --transform-rotate: -6deg
  }

  .sm\:hover\:tw--rotate-3:hover{
    --transform-rotate: -3deg
  }

  .sm\:hover\:tw--rotate-2:hover{
    --transform-rotate: -2deg
  }

  .sm\:hover\:tw--rotate-1:hover{
    --transform-rotate: -1deg
  }

  .sm\:focus\:tw-rotate-0:focus{
    --transform-rotate: 0
  }

  .sm\:focus\:tw-rotate-1:focus{
    --transform-rotate: 1deg
  }

  .sm\:focus\:tw-rotate-2:focus{
    --transform-rotate: 2deg
  }

  .sm\:focus\:tw-rotate-3:focus{
    --transform-rotate: 3deg
  }

  .sm\:focus\:tw-rotate-6:focus{
    --transform-rotate: 6deg
  }

  .sm\:focus\:tw-rotate-12:focus{
    --transform-rotate: 12deg
  }

  .sm\:focus\:tw-rotate-45:focus{
    --transform-rotate: 45deg
  }

  .sm\:focus\:tw-rotate-90:focus{
    --transform-rotate: 90deg
  }

  .sm\:focus\:tw-rotate-180:focus{
    --transform-rotate: 180deg
  }

  .sm\:focus\:tw--rotate-180:focus{
    --transform-rotate: -180deg
  }

  .sm\:focus\:tw--rotate-90:focus{
    --transform-rotate: -90deg
  }

  .sm\:focus\:tw--rotate-45:focus{
    --transform-rotate: -45deg
  }

  .sm\:focus\:tw--rotate-12:focus{
    --transform-rotate: -12deg
  }

  .sm\:focus\:tw--rotate-6:focus{
    --transform-rotate: -6deg
  }

  .sm\:focus\:tw--rotate-3:focus{
    --transform-rotate: -3deg
  }

  .sm\:focus\:tw--rotate-2:focus{
    --transform-rotate: -2deg
  }

  .sm\:focus\:tw--rotate-1:focus{
    --transform-rotate: -1deg
  }

  .sm\:tw-translate-x-0{
    --transform-translate-x: 0
  }

  .sm\:tw-translate-x-1{
    --transform-translate-x: 0.25rem
  }

  .sm\:tw-translate-x-2{
    --transform-translate-x: 0.5rem
  }

  .sm\:tw-translate-x-3{
    --transform-translate-x: 0.75rem
  }

  .sm\:tw-translate-x-4{
    --transform-translate-x: 1rem
  }

  .sm\:tw-translate-x-5{
    --transform-translate-x: 1.25rem
  }

  .sm\:tw-translate-x-6{
    --transform-translate-x: 1.5rem
  }

  .sm\:tw-translate-x-8{
    --transform-translate-x: 2rem
  }

  .sm\:tw-translate-x-10{
    --transform-translate-x: 2.5rem
  }

  .sm\:tw-translate-x-12{
    --transform-translate-x: 3rem
  }

  .sm\:tw-translate-x-16{
    --transform-translate-x: 4rem
  }

  .sm\:tw-translate-x-20{
    --transform-translate-x: 5rem
  }

  .sm\:tw-translate-x-24{
    --transform-translate-x: 6rem
  }

  .sm\:tw-translate-x-32{
    --transform-translate-x: 8rem
  }

  .sm\:tw-translate-x-40{
    --transform-translate-x: 10rem
  }

  .sm\:tw-translate-x-48{
    --transform-translate-x: 12rem
  }

  .sm\:tw-translate-x-56{
    --transform-translate-x: 14rem
  }

  .sm\:tw-translate-x-64{
    --transform-translate-x: 16rem
  }

  .sm\:tw-translate-x-px{
    --transform-translate-x: 1px
  }

  .sm\:tw--translate-x-1{
    --transform-translate-x: -0.25rem
  }

  .sm\:tw--translate-x-2{
    --transform-translate-x: -0.5rem
  }

  .sm\:tw--translate-x-3{
    --transform-translate-x: -0.75rem
  }

  .sm\:tw--translate-x-4{
    --transform-translate-x: -1rem
  }

  .sm\:tw--translate-x-5{
    --transform-translate-x: -1.25rem
  }

  .sm\:tw--translate-x-6{
    --transform-translate-x: -1.5rem
  }

  .sm\:tw--translate-x-8{
    --transform-translate-x: -2rem
  }

  .sm\:tw--translate-x-10{
    --transform-translate-x: -2.5rem
  }

  .sm\:tw--translate-x-12{
    --transform-translate-x: -3rem
  }

  .sm\:tw--translate-x-16{
    --transform-translate-x: -4rem
  }

  .sm\:tw--translate-x-20{
    --transform-translate-x: -5rem
  }

  .sm\:tw--translate-x-24{
    --transform-translate-x: -6rem
  }

  .sm\:tw--translate-x-32{
    --transform-translate-x: -8rem
  }

  .sm\:tw--translate-x-40{
    --transform-translate-x: -10rem
  }

  .sm\:tw--translate-x-48{
    --transform-translate-x: -12rem
  }

  .sm\:tw--translate-x-56{
    --transform-translate-x: -14rem
  }

  .sm\:tw--translate-x-64{
    --transform-translate-x: -16rem
  }

  .sm\:tw--translate-x-px{
    --transform-translate-x: -1px
  }

  .sm\:tw--translate-x-full{
    --transform-translate-x: -100%
  }

  .sm\:tw--translate-x-1\/2{
    --transform-translate-x: -50%
  }

  .sm\:tw-translate-x-1\/2{
    --transform-translate-x: 50%
  }

  .sm\:tw-translate-x-full{
    --transform-translate-x: 100%
  }

  .sm\:tw-translate-y-0{
    --transform-translate-y: 0
  }

  .sm\:tw-translate-y-1{
    --transform-translate-y: 0.25rem
  }

  .sm\:tw-translate-y-2{
    --transform-translate-y: 0.5rem
  }

  .sm\:tw-translate-y-3{
    --transform-translate-y: 0.75rem
  }

  .sm\:tw-translate-y-4{
    --transform-translate-y: 1rem
  }

  .sm\:tw-translate-y-5{
    --transform-translate-y: 1.25rem
  }

  .sm\:tw-translate-y-6{
    --transform-translate-y: 1.5rem
  }

  .sm\:tw-translate-y-8{
    --transform-translate-y: 2rem
  }

  .sm\:tw-translate-y-10{
    --transform-translate-y: 2.5rem
  }

  .sm\:tw-translate-y-12{
    --transform-translate-y: 3rem
  }

  .sm\:tw-translate-y-16{
    --transform-translate-y: 4rem
  }

  .sm\:tw-translate-y-20{
    --transform-translate-y: 5rem
  }

  .sm\:tw-translate-y-24{
    --transform-translate-y: 6rem
  }

  .sm\:tw-translate-y-32{
    --transform-translate-y: 8rem
  }

  .sm\:tw-translate-y-40{
    --transform-translate-y: 10rem
  }

  .sm\:tw-translate-y-48{
    --transform-translate-y: 12rem
  }

  .sm\:tw-translate-y-56{
    --transform-translate-y: 14rem
  }

  .sm\:tw-translate-y-64{
    --transform-translate-y: 16rem
  }

  .sm\:tw-translate-y-px{
    --transform-translate-y: 1px
  }

  .sm\:tw--translate-y-1{
    --transform-translate-y: -0.25rem
  }

  .sm\:tw--translate-y-2{
    --transform-translate-y: -0.5rem
  }

  .sm\:tw--translate-y-3{
    --transform-translate-y: -0.75rem
  }

  .sm\:tw--translate-y-4{
    --transform-translate-y: -1rem
  }

  .sm\:tw--translate-y-5{
    --transform-translate-y: -1.25rem
  }

  .sm\:tw--translate-y-6{
    --transform-translate-y: -1.5rem
  }

  .sm\:tw--translate-y-8{
    --transform-translate-y: -2rem
  }

  .sm\:tw--translate-y-10{
    --transform-translate-y: -2.5rem
  }

  .sm\:tw--translate-y-12{
    --transform-translate-y: -3rem
  }

  .sm\:tw--translate-y-16{
    --transform-translate-y: -4rem
  }

  .sm\:tw--translate-y-20{
    --transform-translate-y: -5rem
  }

  .sm\:tw--translate-y-24{
    --transform-translate-y: -6rem
  }

  .sm\:tw--translate-y-32{
    --transform-translate-y: -8rem
  }

  .sm\:tw--translate-y-40{
    --transform-translate-y: -10rem
  }

  .sm\:tw--translate-y-48{
    --transform-translate-y: -12rem
  }

  .sm\:tw--translate-y-56{
    --transform-translate-y: -14rem
  }

  .sm\:tw--translate-y-64{
    --transform-translate-y: -16rem
  }

  .sm\:tw--translate-y-px{
    --transform-translate-y: -1px
  }

  .sm\:tw--translate-y-full{
    --transform-translate-y: -100%
  }

  .sm\:tw--translate-y-1\/2{
    --transform-translate-y: -50%
  }

  .sm\:tw-translate-y-1\/2{
    --transform-translate-y: 50%
  }

  .sm\:tw-translate-y-full{
    --transform-translate-y: 100%
  }

  .sm\:hover\:tw-translate-x-0:hover{
    --transform-translate-x: 0
  }

  .sm\:hover\:tw-translate-x-1:hover{
    --transform-translate-x: 0.25rem
  }

  .sm\:hover\:tw-translate-x-2:hover{
    --transform-translate-x: 0.5rem
  }

  .sm\:hover\:tw-translate-x-3:hover{
    --transform-translate-x: 0.75rem
  }

  .sm\:hover\:tw-translate-x-4:hover{
    --transform-translate-x: 1rem
  }

  .sm\:hover\:tw-translate-x-5:hover{
    --transform-translate-x: 1.25rem
  }

  .sm\:hover\:tw-translate-x-6:hover{
    --transform-translate-x: 1.5rem
  }

  .sm\:hover\:tw-translate-x-8:hover{
    --transform-translate-x: 2rem
  }

  .sm\:hover\:tw-translate-x-10:hover{
    --transform-translate-x: 2.5rem
  }

  .sm\:hover\:tw-translate-x-12:hover{
    --transform-translate-x: 3rem
  }

  .sm\:hover\:tw-translate-x-16:hover{
    --transform-translate-x: 4rem
  }

  .sm\:hover\:tw-translate-x-20:hover{
    --transform-translate-x: 5rem
  }

  .sm\:hover\:tw-translate-x-24:hover{
    --transform-translate-x: 6rem
  }

  .sm\:hover\:tw-translate-x-32:hover{
    --transform-translate-x: 8rem
  }

  .sm\:hover\:tw-translate-x-40:hover{
    --transform-translate-x: 10rem
  }

  .sm\:hover\:tw-translate-x-48:hover{
    --transform-translate-x: 12rem
  }

  .sm\:hover\:tw-translate-x-56:hover{
    --transform-translate-x: 14rem
  }

  .sm\:hover\:tw-translate-x-64:hover{
    --transform-translate-x: 16rem
  }

  .sm\:hover\:tw-translate-x-px:hover{
    --transform-translate-x: 1px
  }

  .sm\:hover\:tw--translate-x-1:hover{
    --transform-translate-x: -0.25rem
  }

  .sm\:hover\:tw--translate-x-2:hover{
    --transform-translate-x: -0.5rem
  }

  .sm\:hover\:tw--translate-x-3:hover{
    --transform-translate-x: -0.75rem
  }

  .sm\:hover\:tw--translate-x-4:hover{
    --transform-translate-x: -1rem
  }

  .sm\:hover\:tw--translate-x-5:hover{
    --transform-translate-x: -1.25rem
  }

  .sm\:hover\:tw--translate-x-6:hover{
    --transform-translate-x: -1.5rem
  }

  .sm\:hover\:tw--translate-x-8:hover{
    --transform-translate-x: -2rem
  }

  .sm\:hover\:tw--translate-x-10:hover{
    --transform-translate-x: -2.5rem
  }

  .sm\:hover\:tw--translate-x-12:hover{
    --transform-translate-x: -3rem
  }

  .sm\:hover\:tw--translate-x-16:hover{
    --transform-translate-x: -4rem
  }

  .sm\:hover\:tw--translate-x-20:hover{
    --transform-translate-x: -5rem
  }

  .sm\:hover\:tw--translate-x-24:hover{
    --transform-translate-x: -6rem
  }

  .sm\:hover\:tw--translate-x-32:hover{
    --transform-translate-x: -8rem
  }

  .sm\:hover\:tw--translate-x-40:hover{
    --transform-translate-x: -10rem
  }

  .sm\:hover\:tw--translate-x-48:hover{
    --transform-translate-x: -12rem
  }

  .sm\:hover\:tw--translate-x-56:hover{
    --transform-translate-x: -14rem
  }

  .sm\:hover\:tw--translate-x-64:hover{
    --transform-translate-x: -16rem
  }

  .sm\:hover\:tw--translate-x-px:hover{
    --transform-translate-x: -1px
  }

  .sm\:hover\:tw--translate-x-full:hover{
    --transform-translate-x: -100%
  }

  .sm\:hover\:tw--translate-x-1\/2:hover{
    --transform-translate-x: -50%
  }

  .sm\:hover\:tw-translate-x-1\/2:hover{
    --transform-translate-x: 50%
  }

  .sm\:hover\:tw-translate-x-full:hover{
    --transform-translate-x: 100%
  }

  .sm\:hover\:tw-translate-y-0:hover{
    --transform-translate-y: 0
  }

  .sm\:hover\:tw-translate-y-1:hover{
    --transform-translate-y: 0.25rem
  }

  .sm\:hover\:tw-translate-y-2:hover{
    --transform-translate-y: 0.5rem
  }

  .sm\:hover\:tw-translate-y-3:hover{
    --transform-translate-y: 0.75rem
  }

  .sm\:hover\:tw-translate-y-4:hover{
    --transform-translate-y: 1rem
  }

  .sm\:hover\:tw-translate-y-5:hover{
    --transform-translate-y: 1.25rem
  }

  .sm\:hover\:tw-translate-y-6:hover{
    --transform-translate-y: 1.5rem
  }

  .sm\:hover\:tw-translate-y-8:hover{
    --transform-translate-y: 2rem
  }

  .sm\:hover\:tw-translate-y-10:hover{
    --transform-translate-y: 2.5rem
  }

  .sm\:hover\:tw-translate-y-12:hover{
    --transform-translate-y: 3rem
  }

  .sm\:hover\:tw-translate-y-16:hover{
    --transform-translate-y: 4rem
  }

  .sm\:hover\:tw-translate-y-20:hover{
    --transform-translate-y: 5rem
  }

  .sm\:hover\:tw-translate-y-24:hover{
    --transform-translate-y: 6rem
  }

  .sm\:hover\:tw-translate-y-32:hover{
    --transform-translate-y: 8rem
  }

  .sm\:hover\:tw-translate-y-40:hover{
    --transform-translate-y: 10rem
  }

  .sm\:hover\:tw-translate-y-48:hover{
    --transform-translate-y: 12rem
  }

  .sm\:hover\:tw-translate-y-56:hover{
    --transform-translate-y: 14rem
  }

  .sm\:hover\:tw-translate-y-64:hover{
    --transform-translate-y: 16rem
  }

  .sm\:hover\:tw-translate-y-px:hover{
    --transform-translate-y: 1px
  }

  .sm\:hover\:tw--translate-y-1:hover{
    --transform-translate-y: -0.25rem
  }

  .sm\:hover\:tw--translate-y-2:hover{
    --transform-translate-y: -0.5rem
  }

  .sm\:hover\:tw--translate-y-3:hover{
    --transform-translate-y: -0.75rem
  }

  .sm\:hover\:tw--translate-y-4:hover{
    --transform-translate-y: -1rem
  }

  .sm\:hover\:tw--translate-y-5:hover{
    --transform-translate-y: -1.25rem
  }

  .sm\:hover\:tw--translate-y-6:hover{
    --transform-translate-y: -1.5rem
  }

  .sm\:hover\:tw--translate-y-8:hover{
    --transform-translate-y: -2rem
  }

  .sm\:hover\:tw--translate-y-10:hover{
    --transform-translate-y: -2.5rem
  }

  .sm\:hover\:tw--translate-y-12:hover{
    --transform-translate-y: -3rem
  }

  .sm\:hover\:tw--translate-y-16:hover{
    --transform-translate-y: -4rem
  }

  .sm\:hover\:tw--translate-y-20:hover{
    --transform-translate-y: -5rem
  }

  .sm\:hover\:tw--translate-y-24:hover{
    --transform-translate-y: -6rem
  }

  .sm\:hover\:tw--translate-y-32:hover{
    --transform-translate-y: -8rem
  }

  .sm\:hover\:tw--translate-y-40:hover{
    --transform-translate-y: -10rem
  }

  .sm\:hover\:tw--translate-y-48:hover{
    --transform-translate-y: -12rem
  }

  .sm\:hover\:tw--translate-y-56:hover{
    --transform-translate-y: -14rem
  }

  .sm\:hover\:tw--translate-y-64:hover{
    --transform-translate-y: -16rem
  }

  .sm\:hover\:tw--translate-y-px:hover{
    --transform-translate-y: -1px
  }

  .sm\:hover\:tw--translate-y-full:hover{
    --transform-translate-y: -100%
  }

  .sm\:hover\:tw--translate-y-1\/2:hover{
    --transform-translate-y: -50%
  }

  .sm\:hover\:tw-translate-y-1\/2:hover{
    --transform-translate-y: 50%
  }

  .sm\:hover\:tw-translate-y-full:hover{
    --transform-translate-y: 100%
  }

  .sm\:focus\:tw-translate-x-0:focus{
    --transform-translate-x: 0
  }

  .sm\:focus\:tw-translate-x-1:focus{
    --transform-translate-x: 0.25rem
  }

  .sm\:focus\:tw-translate-x-2:focus{
    --transform-translate-x: 0.5rem
  }

  .sm\:focus\:tw-translate-x-3:focus{
    --transform-translate-x: 0.75rem
  }

  .sm\:focus\:tw-translate-x-4:focus{
    --transform-translate-x: 1rem
  }

  .sm\:focus\:tw-translate-x-5:focus{
    --transform-translate-x: 1.25rem
  }

  .sm\:focus\:tw-translate-x-6:focus{
    --transform-translate-x: 1.5rem
  }

  .sm\:focus\:tw-translate-x-8:focus{
    --transform-translate-x: 2rem
  }

  .sm\:focus\:tw-translate-x-10:focus{
    --transform-translate-x: 2.5rem
  }

  .sm\:focus\:tw-translate-x-12:focus{
    --transform-translate-x: 3rem
  }

  .sm\:focus\:tw-translate-x-16:focus{
    --transform-translate-x: 4rem
  }

  .sm\:focus\:tw-translate-x-20:focus{
    --transform-translate-x: 5rem
  }

  .sm\:focus\:tw-translate-x-24:focus{
    --transform-translate-x: 6rem
  }

  .sm\:focus\:tw-translate-x-32:focus{
    --transform-translate-x: 8rem
  }

  .sm\:focus\:tw-translate-x-40:focus{
    --transform-translate-x: 10rem
  }

  .sm\:focus\:tw-translate-x-48:focus{
    --transform-translate-x: 12rem
  }

  .sm\:focus\:tw-translate-x-56:focus{
    --transform-translate-x: 14rem
  }

  .sm\:focus\:tw-translate-x-64:focus{
    --transform-translate-x: 16rem
  }

  .sm\:focus\:tw-translate-x-px:focus{
    --transform-translate-x: 1px
  }

  .sm\:focus\:tw--translate-x-1:focus{
    --transform-translate-x: -0.25rem
  }

  .sm\:focus\:tw--translate-x-2:focus{
    --transform-translate-x: -0.5rem
  }

  .sm\:focus\:tw--translate-x-3:focus{
    --transform-translate-x: -0.75rem
  }

  .sm\:focus\:tw--translate-x-4:focus{
    --transform-translate-x: -1rem
  }

  .sm\:focus\:tw--translate-x-5:focus{
    --transform-translate-x: -1.25rem
  }

  .sm\:focus\:tw--translate-x-6:focus{
    --transform-translate-x: -1.5rem
  }

  .sm\:focus\:tw--translate-x-8:focus{
    --transform-translate-x: -2rem
  }

  .sm\:focus\:tw--translate-x-10:focus{
    --transform-translate-x: -2.5rem
  }

  .sm\:focus\:tw--translate-x-12:focus{
    --transform-translate-x: -3rem
  }

  .sm\:focus\:tw--translate-x-16:focus{
    --transform-translate-x: -4rem
  }

  .sm\:focus\:tw--translate-x-20:focus{
    --transform-translate-x: -5rem
  }

  .sm\:focus\:tw--translate-x-24:focus{
    --transform-translate-x: -6rem
  }

  .sm\:focus\:tw--translate-x-32:focus{
    --transform-translate-x: -8rem
  }

  .sm\:focus\:tw--translate-x-40:focus{
    --transform-translate-x: -10rem
  }

  .sm\:focus\:tw--translate-x-48:focus{
    --transform-translate-x: -12rem
  }

  .sm\:focus\:tw--translate-x-56:focus{
    --transform-translate-x: -14rem
  }

  .sm\:focus\:tw--translate-x-64:focus{
    --transform-translate-x: -16rem
  }

  .sm\:focus\:tw--translate-x-px:focus{
    --transform-translate-x: -1px
  }

  .sm\:focus\:tw--translate-x-full:focus{
    --transform-translate-x: -100%
  }

  .sm\:focus\:tw--translate-x-1\/2:focus{
    --transform-translate-x: -50%
  }

  .sm\:focus\:tw-translate-x-1\/2:focus{
    --transform-translate-x: 50%
  }

  .sm\:focus\:tw-translate-x-full:focus{
    --transform-translate-x: 100%
  }

  .sm\:focus\:tw-translate-y-0:focus{
    --transform-translate-y: 0
  }

  .sm\:focus\:tw-translate-y-1:focus{
    --transform-translate-y: 0.25rem
  }

  .sm\:focus\:tw-translate-y-2:focus{
    --transform-translate-y: 0.5rem
  }

  .sm\:focus\:tw-translate-y-3:focus{
    --transform-translate-y: 0.75rem
  }

  .sm\:focus\:tw-translate-y-4:focus{
    --transform-translate-y: 1rem
  }

  .sm\:focus\:tw-translate-y-5:focus{
    --transform-translate-y: 1.25rem
  }

  .sm\:focus\:tw-translate-y-6:focus{
    --transform-translate-y: 1.5rem
  }

  .sm\:focus\:tw-translate-y-8:focus{
    --transform-translate-y: 2rem
  }

  .sm\:focus\:tw-translate-y-10:focus{
    --transform-translate-y: 2.5rem
  }

  .sm\:focus\:tw-translate-y-12:focus{
    --transform-translate-y: 3rem
  }

  .sm\:focus\:tw-translate-y-16:focus{
    --transform-translate-y: 4rem
  }

  .sm\:focus\:tw-translate-y-20:focus{
    --transform-translate-y: 5rem
  }

  .sm\:focus\:tw-translate-y-24:focus{
    --transform-translate-y: 6rem
  }

  .sm\:focus\:tw-translate-y-32:focus{
    --transform-translate-y: 8rem
  }

  .sm\:focus\:tw-translate-y-40:focus{
    --transform-translate-y: 10rem
  }

  .sm\:focus\:tw-translate-y-48:focus{
    --transform-translate-y: 12rem
  }

  .sm\:focus\:tw-translate-y-56:focus{
    --transform-translate-y: 14rem
  }

  .sm\:focus\:tw-translate-y-64:focus{
    --transform-translate-y: 16rem
  }

  .sm\:focus\:tw-translate-y-px:focus{
    --transform-translate-y: 1px
  }

  .sm\:focus\:tw--translate-y-1:focus{
    --transform-translate-y: -0.25rem
  }

  .sm\:focus\:tw--translate-y-2:focus{
    --transform-translate-y: -0.5rem
  }

  .sm\:focus\:tw--translate-y-3:focus{
    --transform-translate-y: -0.75rem
  }

  .sm\:focus\:tw--translate-y-4:focus{
    --transform-translate-y: -1rem
  }

  .sm\:focus\:tw--translate-y-5:focus{
    --transform-translate-y: -1.25rem
  }

  .sm\:focus\:tw--translate-y-6:focus{
    --transform-translate-y: -1.5rem
  }

  .sm\:focus\:tw--translate-y-8:focus{
    --transform-translate-y: -2rem
  }

  .sm\:focus\:tw--translate-y-10:focus{
    --transform-translate-y: -2.5rem
  }

  .sm\:focus\:tw--translate-y-12:focus{
    --transform-translate-y: -3rem
  }

  .sm\:focus\:tw--translate-y-16:focus{
    --transform-translate-y: -4rem
  }

  .sm\:focus\:tw--translate-y-20:focus{
    --transform-translate-y: -5rem
  }

  .sm\:focus\:tw--translate-y-24:focus{
    --transform-translate-y: -6rem
  }

  .sm\:focus\:tw--translate-y-32:focus{
    --transform-translate-y: -8rem
  }

  .sm\:focus\:tw--translate-y-40:focus{
    --transform-translate-y: -10rem
  }

  .sm\:focus\:tw--translate-y-48:focus{
    --transform-translate-y: -12rem
  }

  .sm\:focus\:tw--translate-y-56:focus{
    --transform-translate-y: -14rem
  }

  .sm\:focus\:tw--translate-y-64:focus{
    --transform-translate-y: -16rem
  }

  .sm\:focus\:tw--translate-y-px:focus{
    --transform-translate-y: -1px
  }

  .sm\:focus\:tw--translate-y-full:focus{
    --transform-translate-y: -100%
  }

  .sm\:focus\:tw--translate-y-1\/2:focus{
    --transform-translate-y: -50%
  }

  .sm\:focus\:tw-translate-y-1\/2:focus{
    --transform-translate-y: 50%
  }

  .sm\:focus\:tw-translate-y-full:focus{
    --transform-translate-y: 100%
  }

  .sm\:tw-skew-x-0{
    --transform-skew-x: 0
  }

  .sm\:tw-skew-x-1{
    --transform-skew-x: 1deg
  }

  .sm\:tw-skew-x-2{
    --transform-skew-x: 2deg
  }

  .sm\:tw-skew-x-3{
    --transform-skew-x: 3deg
  }

  .sm\:tw-skew-x-6{
    --transform-skew-x: 6deg
  }

  .sm\:tw-skew-x-12{
    --transform-skew-x: 12deg
  }

  .sm\:tw--skew-x-12{
    --transform-skew-x: -12deg
  }

  .sm\:tw--skew-x-6{
    --transform-skew-x: -6deg
  }

  .sm\:tw--skew-x-3{
    --transform-skew-x: -3deg
  }

  .sm\:tw--skew-x-2{
    --transform-skew-x: -2deg
  }

  .sm\:tw--skew-x-1{
    --transform-skew-x: -1deg
  }

  .sm\:tw-skew-y-0{
    --transform-skew-y: 0
  }

  .sm\:tw-skew-y-1{
    --transform-skew-y: 1deg
  }

  .sm\:tw-skew-y-2{
    --transform-skew-y: 2deg
  }

  .sm\:tw-skew-y-3{
    --transform-skew-y: 3deg
  }

  .sm\:tw-skew-y-6{
    --transform-skew-y: 6deg
  }

  .sm\:tw-skew-y-12{
    --transform-skew-y: 12deg
  }

  .sm\:tw--skew-y-12{
    --transform-skew-y: -12deg
  }

  .sm\:tw--skew-y-6{
    --transform-skew-y: -6deg
  }

  .sm\:tw--skew-y-3{
    --transform-skew-y: -3deg
  }

  .sm\:tw--skew-y-2{
    --transform-skew-y: -2deg
  }

  .sm\:tw--skew-y-1{
    --transform-skew-y: -1deg
  }

  .sm\:hover\:tw-skew-x-0:hover{
    --transform-skew-x: 0
  }

  .sm\:hover\:tw-skew-x-1:hover{
    --transform-skew-x: 1deg
  }

  .sm\:hover\:tw-skew-x-2:hover{
    --transform-skew-x: 2deg
  }

  .sm\:hover\:tw-skew-x-3:hover{
    --transform-skew-x: 3deg
  }

  .sm\:hover\:tw-skew-x-6:hover{
    --transform-skew-x: 6deg
  }

  .sm\:hover\:tw-skew-x-12:hover{
    --transform-skew-x: 12deg
  }

  .sm\:hover\:tw--skew-x-12:hover{
    --transform-skew-x: -12deg
  }

  .sm\:hover\:tw--skew-x-6:hover{
    --transform-skew-x: -6deg
  }

  .sm\:hover\:tw--skew-x-3:hover{
    --transform-skew-x: -3deg
  }

  .sm\:hover\:tw--skew-x-2:hover{
    --transform-skew-x: -2deg
  }

  .sm\:hover\:tw--skew-x-1:hover{
    --transform-skew-x: -1deg
  }

  .sm\:hover\:tw-skew-y-0:hover{
    --transform-skew-y: 0
  }

  .sm\:hover\:tw-skew-y-1:hover{
    --transform-skew-y: 1deg
  }

  .sm\:hover\:tw-skew-y-2:hover{
    --transform-skew-y: 2deg
  }

  .sm\:hover\:tw-skew-y-3:hover{
    --transform-skew-y: 3deg
  }

  .sm\:hover\:tw-skew-y-6:hover{
    --transform-skew-y: 6deg
  }

  .sm\:hover\:tw-skew-y-12:hover{
    --transform-skew-y: 12deg
  }

  .sm\:hover\:tw--skew-y-12:hover{
    --transform-skew-y: -12deg
  }

  .sm\:hover\:tw--skew-y-6:hover{
    --transform-skew-y: -6deg
  }

  .sm\:hover\:tw--skew-y-3:hover{
    --transform-skew-y: -3deg
  }

  .sm\:hover\:tw--skew-y-2:hover{
    --transform-skew-y: -2deg
  }

  .sm\:hover\:tw--skew-y-1:hover{
    --transform-skew-y: -1deg
  }

  .sm\:focus\:tw-skew-x-0:focus{
    --transform-skew-x: 0
  }

  .sm\:focus\:tw-skew-x-1:focus{
    --transform-skew-x: 1deg
  }

  .sm\:focus\:tw-skew-x-2:focus{
    --transform-skew-x: 2deg
  }

  .sm\:focus\:tw-skew-x-3:focus{
    --transform-skew-x: 3deg
  }

  .sm\:focus\:tw-skew-x-6:focus{
    --transform-skew-x: 6deg
  }

  .sm\:focus\:tw-skew-x-12:focus{
    --transform-skew-x: 12deg
  }

  .sm\:focus\:tw--skew-x-12:focus{
    --transform-skew-x: -12deg
  }

  .sm\:focus\:tw--skew-x-6:focus{
    --transform-skew-x: -6deg
  }

  .sm\:focus\:tw--skew-x-3:focus{
    --transform-skew-x: -3deg
  }

  .sm\:focus\:tw--skew-x-2:focus{
    --transform-skew-x: -2deg
  }

  .sm\:focus\:tw--skew-x-1:focus{
    --transform-skew-x: -1deg
  }

  .sm\:focus\:tw-skew-y-0:focus{
    --transform-skew-y: 0
  }

  .sm\:focus\:tw-skew-y-1:focus{
    --transform-skew-y: 1deg
  }

  .sm\:focus\:tw-skew-y-2:focus{
    --transform-skew-y: 2deg
  }

  .sm\:focus\:tw-skew-y-3:focus{
    --transform-skew-y: 3deg
  }

  .sm\:focus\:tw-skew-y-6:focus{
    --transform-skew-y: 6deg
  }

  .sm\:focus\:tw-skew-y-12:focus{
    --transform-skew-y: 12deg
  }

  .sm\:focus\:tw--skew-y-12:focus{
    --transform-skew-y: -12deg
  }

  .sm\:focus\:tw--skew-y-6:focus{
    --transform-skew-y: -6deg
  }

  .sm\:focus\:tw--skew-y-3:focus{
    --transform-skew-y: -3deg
  }

  .sm\:focus\:tw--skew-y-2:focus{
    --transform-skew-y: -2deg
  }

  .sm\:focus\:tw--skew-y-1:focus{
    --transform-skew-y: -1deg
  }

  .sm\:tw-transition-none{
    -webkit-transition-property: none;
    transition-property: none
  }

  .sm\:tw-transition-all{
    -webkit-transition-property: all;
    transition-property: all
  }

  .sm\:tw-transition{
    -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
  }

  .sm\:tw-transition-colors{
    -webkit-transition-property: background-color, border-color, color, fill, stroke;
    transition-property: background-color, border-color, color, fill, stroke
  }

  .sm\:tw-transition-opacity{
    -webkit-transition-property: opacity;
    transition-property: opacity
  }

  .sm\:tw-transition-shadow{
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow
  }

  .sm\:tw-transition-transform{
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
  }

  .sm\:tw-ease-linear{
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear
  }

  .sm\:tw-ease-in{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .sm\:tw-ease-out{
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .sm\:tw-ease-in-out{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .sm\:tw-duration-75{
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms
  }

  .sm\:tw-duration-100{
    -webkit-transition-duration: 100ms;
            transition-duration: 100ms
  }

  .sm\:tw-duration-150{
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms
  }

  .sm\:tw-duration-200{
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms
  }

  .sm\:tw-duration-300{
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms
  }

  .sm\:tw-duration-500{
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms
  }

  .sm\:tw-duration-700{
    -webkit-transition-duration: 700ms;
            transition-duration: 700ms
  }

  .sm\:tw-duration-1000{
    -webkit-transition-duration: 1000ms;
            transition-duration: 1000ms
  }

  .sm\:tw-delay-75{
    -webkit-transition-delay: 75ms;
            transition-delay: 75ms
  }

  .sm\:tw-delay-100{
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms
  }

  .sm\:tw-delay-150{
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms
  }

  .sm\:tw-delay-200{
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms
  }

  .sm\:tw-delay-300{
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms
  }

  .sm\:tw-delay-500{
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms
  }

  .sm\:tw-delay-700{
    -webkit-transition-delay: 700ms;
            transition-delay: 700ms
  }

  .sm\:tw-delay-1000{
    -webkit-transition-delay: 1000ms;
            transition-delay: 1000ms
  }

  .sm\:tw-animate-none{
    -webkit-animation: none;
            animation: none
  }

  .sm\:tw-animate-spin{
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .sm\:tw-animate-ping{
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .sm\:tw-animate-pulse{
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .sm\:tw-animate-bounce{
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

@media (min-width: 768px){
  .md\:tw-container{
    width: 100%
  }

  @media (min-width: 640px){
    .md\:tw-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .md\:tw-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .md\:tw-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .md\:tw-container{
      max-width: 1280px
    }
  }

  .md\:tw-space-y-0 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .md\:tw-space-x-0 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .md\:tw-space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .md\:tw-space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .md\:tw--space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw--space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .md\:tw--space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .md\:tw-space-y-reverse > :not(template) ~ :not(template){
    --space-y-reverse: 1
  }

  .md\:tw-space-x-reverse > :not(template) ~ :not(template){
    --space-x-reverse: 1
  }

  .md\:tw-divide-y-0 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .md\:tw-divide-x-0 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:tw-divide-y-2 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .md\:tw-divide-x-2 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:tw-divide-y-4 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .md\:tw-divide-x-4 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:tw-divide-y-8 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .md\:tw-divide-x-8 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:tw-divide-y > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .md\:tw-divide-x > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:tw-divide-y-reverse > :not(template) ~ :not(template){
    --divide-y-reverse: 1
  }

  .md\:tw-divide-x-reverse > :not(template) ~ :not(template){
    --divide-x-reverse: 1
  }

  .md\:tw-divide-transparent > :not(template) ~ :not(template){
    border-color: transparent
  }

  .md\:tw-divide-tlg-green-dark > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--divide-opacity))
  }

  .md\:tw-divide-tlg-green > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--divide-opacity))
  }

  .md\:tw-divide-tlg-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--divide-opacity))
  }

  .md\:tw-divide-black > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .md\:tw-divide-white > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .md\:tw-divide-gray-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .md\:tw-divide-gray-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .md\:tw-divide-gray-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .md\:tw-divide-gray-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .md\:tw-divide-gray-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .md\:tw-divide-gray-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .md\:tw-divide-gray-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .md\:tw-divide-gray-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .md\:tw-divide-gray-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .md\:tw-divide-red-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .md\:tw-divide-red-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .md\:tw-divide-red-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .md\:tw-divide-red-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .md\:tw-divide-red-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .md\:tw-divide-red-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .md\:tw-divide-red-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .md\:tw-divide-red-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .md\:tw-divide-red-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .md\:tw-divide-orange-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .md\:tw-divide-orange-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .md\:tw-divide-orange-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .md\:tw-divide-orange-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .md\:tw-divide-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .md\:tw-divide-orange-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .md\:tw-divide-orange-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .md\:tw-divide-orange-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .md\:tw-divide-orange-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .md\:tw-divide-yellow-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .md\:tw-divide-green-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .md\:tw-divide-green-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .md\:tw-divide-green-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .md\:tw-divide-green-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .md\:tw-divide-green-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .md\:tw-divide-green-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .md\:tw-divide-green-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .md\:tw-divide-green-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .md\:tw-divide-green-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .md\:tw-divide-teal-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .md\:tw-divide-teal-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .md\:tw-divide-teal-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .md\:tw-divide-teal-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .md\:tw-divide-teal-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .md\:tw-divide-teal-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .md\:tw-divide-teal-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .md\:tw-divide-teal-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .md\:tw-divide-teal-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .md\:tw-divide-blue-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .md\:tw-divide-blue-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .md\:tw-divide-blue-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .md\:tw-divide-blue-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .md\:tw-divide-blue-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .md\:tw-divide-blue-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .md\:tw-divide-blue-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .md\:tw-divide-blue-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .md\:tw-divide-blue-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .md\:tw-divide-indigo-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .md\:tw-divide-purple-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .md\:tw-divide-purple-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .md\:tw-divide-purple-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .md\:tw-divide-purple-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .md\:tw-divide-purple-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .md\:tw-divide-purple-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .md\:tw-divide-purple-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .md\:tw-divide-purple-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .md\:tw-divide-purple-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .md\:tw-divide-pink-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .md\:tw-divide-pink-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .md\:tw-divide-pink-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .md\:tw-divide-pink-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .md\:tw-divide-pink-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .md\:tw-divide-pink-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .md\:tw-divide-pink-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .md\:tw-divide-pink-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .md\:tw-divide-pink-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .md\:tw-divide-solid > :not(template) ~ :not(template){
    border-style: solid
  }

  .md\:tw-divide-dashed > :not(template) ~ :not(template){
    border-style: dashed
  }

  .md\:tw-divide-dotted > :not(template) ~ :not(template){
    border-style: dotted
  }

  .md\:tw-divide-double > :not(template) ~ :not(template){
    border-style: double
  }

  .md\:tw-divide-none > :not(template) ~ :not(template){
    border-style: none
  }

  .md\:tw-divide-opacity-0 > :not(template) ~ :not(template){
    --divide-opacity: 0
  }

  .md\:tw-divide-opacity-25 > :not(template) ~ :not(template){
    --divide-opacity: 0.25
  }

  .md\:tw-divide-opacity-50 > :not(template) ~ :not(template){
    --divide-opacity: 0.5
  }

  .md\:tw-divide-opacity-75 > :not(template) ~ :not(template){
    --divide-opacity: 0.75
  }

  .md\:tw-divide-opacity-100 > :not(template) ~ :not(template){
    --divide-opacity: 1
  }

  .md\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:tw-appearance-none{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .md\:tw-bg-fixed{
    background-attachment: fixed
  }

  .md\:tw-bg-local{
    background-attachment: local
  }

  .md\:tw-bg-scroll{
    background-attachment: scroll
  }

  .md\:tw-bg-clip-border{
    background-clip: border-box
  }

  .md\:tw-bg-clip-padding{
    background-clip: padding-box
  }

  .md\:tw-bg-clip-content{
    background-clip: content-box
  }

  .md\:tw-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  .md\:tw-bg-transparent{
    background-color: transparent
  }

  .md\:tw-bg-tlg-green-dark{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .md\:tw-bg-tlg-green{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .md\:tw-bg-tlg-orange-500{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .md\:tw-bg-black{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:tw-bg-white{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:tw-bg-gray-100{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .md\:tw-bg-gray-200{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .md\:tw-bg-gray-300{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .md\:tw-bg-gray-400{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .md\:tw-bg-gray-500{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .md\:tw-bg-gray-600{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .md\:tw-bg-gray-700{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .md\:tw-bg-gray-800{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .md\:tw-bg-gray-900{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .md\:tw-bg-red-100{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .md\:tw-bg-red-200{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .md\:tw-bg-red-300{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .md\:tw-bg-red-400{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .md\:tw-bg-red-500{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .md\:tw-bg-red-600{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .md\:tw-bg-red-700{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .md\:tw-bg-red-800{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .md\:tw-bg-red-900{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .md\:tw-bg-orange-100{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .md\:tw-bg-orange-200{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .md\:tw-bg-orange-300{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .md\:tw-bg-orange-400{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .md\:tw-bg-orange-500{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .md\:tw-bg-orange-600{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .md\:tw-bg-orange-700{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .md\:tw-bg-orange-800{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .md\:tw-bg-orange-900{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-100{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-200{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-300{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-400{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-500{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-600{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-700{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-800{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .md\:tw-bg-yellow-900{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .md\:tw-bg-green-100{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .md\:tw-bg-green-200{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .md\:tw-bg-green-300{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .md\:tw-bg-green-400{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .md\:tw-bg-green-500{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .md\:tw-bg-green-600{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .md\:tw-bg-green-700{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .md\:tw-bg-green-800{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .md\:tw-bg-green-900{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .md\:tw-bg-teal-100{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .md\:tw-bg-teal-200{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .md\:tw-bg-teal-300{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .md\:tw-bg-teal-400{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .md\:tw-bg-teal-500{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .md\:tw-bg-teal-600{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .md\:tw-bg-teal-700{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .md\:tw-bg-teal-800{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .md\:tw-bg-teal-900{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .md\:tw-bg-blue-100{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .md\:tw-bg-blue-200{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .md\:tw-bg-blue-300{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .md\:tw-bg-blue-400{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .md\:tw-bg-blue-500{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .md\:tw-bg-blue-600{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .md\:tw-bg-blue-700{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .md\:tw-bg-blue-800{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .md\:tw-bg-blue-900{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-100{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-200{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-300{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-400{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-500{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-600{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-700{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-800{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .md\:tw-bg-indigo-900{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .md\:tw-bg-purple-100{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .md\:tw-bg-purple-200{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .md\:tw-bg-purple-300{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .md\:tw-bg-purple-400{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .md\:tw-bg-purple-500{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .md\:tw-bg-purple-600{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .md\:tw-bg-purple-700{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .md\:tw-bg-purple-800{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .md\:tw-bg-purple-900{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .md\:tw-bg-pink-100{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .md\:tw-bg-pink-200{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .md\:tw-bg-pink-300{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .md\:tw-bg-pink-400{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .md\:tw-bg-pink-500{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .md\:tw-bg-pink-600{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .md\:tw-bg-pink-700{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .md\:tw-bg-pink-800{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .md\:tw-bg-pink-900{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-transparent:hover{
    background-color: transparent
  }

  .md\:hover\:tw-bg-tlg-green-dark:hover{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-tlg-green:hover{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-tlg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-black:hover{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-white:hover{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-100:hover{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-200:hover{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-300:hover{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-400:hover{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-500:hover{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-600:hover{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-700:hover{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-800:hover{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-gray-900:hover{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-200:hover{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-300:hover{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-400:hover{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-500:hover{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-600:hover{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-700:hover{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-800:hover{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-red-900:hover{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-100:hover{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-200:hover{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-300:hover{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-400:hover{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-600:hover{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-700:hover{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-800:hover{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-orange-900:hover{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-100:hover{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-200:hover{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-300:hover{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-400:hover{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-500:hover{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-600:hover{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-700:hover{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-800:hover{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-yellow-900:hover{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-100:hover{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-200:hover{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-300:hover{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-400:hover{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-500:hover{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-600:hover{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-700:hover{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-800:hover{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-green-900:hover{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-100:hover{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-200:hover{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-300:hover{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-400:hover{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-500:hover{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-600:hover{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-700:hover{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-800:hover{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-teal-900:hover{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-100:hover{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-200:hover{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-300:hover{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-400:hover{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-500:hover{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-600:hover{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-700:hover{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-800:hover{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-blue-900:hover{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-100:hover{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-200:hover{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-300:hover{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-400:hover{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-500:hover{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-600:hover{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-700:hover{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-800:hover{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-indigo-900:hover{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-100:hover{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-200:hover{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-300:hover{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-400:hover{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-500:hover{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-600:hover{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-700:hover{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-800:hover{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-purple-900:hover{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-200:hover{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-300:hover{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-400:hover{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-500:hover{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-600:hover{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-700:hover{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-800:hover{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .md\:hover\:tw-bg-pink-900:hover{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-transparent:focus{
    background-color: transparent
  }

  .md\:focus\:tw-bg-tlg-green-dark:focus{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-tlg-green:focus{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-tlg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-black:focus{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-white:focus{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-100:focus{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-200:focus{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-300:focus{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-400:focus{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-500:focus{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-600:focus{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-700:focus{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-800:focus{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-gray-900:focus{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-200:focus{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-300:focus{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-400:focus{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-500:focus{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-600:focus{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-700:focus{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-800:focus{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-red-900:focus{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-100:focus{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-200:focus{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-300:focus{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-400:focus{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-600:focus{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-700:focus{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-800:focus{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-orange-900:focus{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-100:focus{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-200:focus{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-300:focus{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-400:focus{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-500:focus{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-600:focus{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-700:focus{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-800:focus{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-yellow-900:focus{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-100:focus{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-200:focus{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-300:focus{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-400:focus{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-500:focus{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-600:focus{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-700:focus{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-800:focus{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-green-900:focus{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-100:focus{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-200:focus{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-300:focus{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-400:focus{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-500:focus{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-600:focus{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-700:focus{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-800:focus{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-teal-900:focus{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-100:focus{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-200:focus{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-300:focus{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-400:focus{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-500:focus{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-600:focus{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-700:focus{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-800:focus{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-blue-900:focus{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-100:focus{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-200:focus{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-300:focus{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-400:focus{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-500:focus{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-600:focus{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-700:focus{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-800:focus{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-indigo-900:focus{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-100:focus{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-200:focus{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-300:focus{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-400:focus{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-500:focus{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-600:focus{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-700:focus{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-800:focus{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-purple-900:focus{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-200:focus{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-300:focus{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-400:focus{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-500:focus{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-600:focus{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-700:focus{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-800:focus{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .md\:focus\:tw-bg-pink-900:focus{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .md\:tw-bg-none{
    background-image: none
  }

  .md\:tw-bg-gradient-to-t{
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom, var(--gradient-color-stops));
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-tr{
    background-image: -webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom left, var(--gradient-color-stops));
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-r{
    background-image: -webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(left, var(--gradient-color-stops));
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-br{
    background-image: -webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top left, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-b{
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-bl{
    background-image: -webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top right, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-l{
    background-image: -webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(right, var(--gradient-color-stops));
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .md\:tw-bg-gradient-to-tl{
    background-image: -webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom right, var(--gradient-color-stops));
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .md\:tw-from-transparent{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:tw-from-tlg-green-dark{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .md\:tw-from-tlg-green{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .md\:tw-from-tlg-orange-500{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .md\:tw-from-black{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:tw-from-white{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:tw-from-gray-100{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:tw-from-gray-200{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:tw-from-gray-300{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:tw-from-gray-400{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:tw-from-gray-500{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:tw-from-gray-600{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:tw-from-gray-700{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:tw-from-gray-800{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:tw-from-gray-900{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:tw-from-red-100{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:tw-from-red-200{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:tw-from-red-300{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:tw-from-red-400{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:tw-from-red-500{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:tw-from-red-600{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:tw-from-red-700{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:tw-from-red-800{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:tw-from-red-900{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:tw-from-orange-100{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:tw-from-orange-200{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:tw-from-orange-300{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:tw-from-orange-400{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:tw-from-orange-500{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:tw-from-orange-600{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:tw-from-orange-700{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:tw-from-orange-800{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:tw-from-orange-900{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:tw-from-yellow-100{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:tw-from-yellow-200{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:tw-from-yellow-300{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:tw-from-yellow-400{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:tw-from-yellow-500{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:tw-from-yellow-600{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:tw-from-yellow-700{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:tw-from-yellow-800{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:tw-from-yellow-900{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:tw-from-green-100{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:tw-from-green-200{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:tw-from-green-300{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:tw-from-green-400{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:tw-from-green-500{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:tw-from-green-600{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:tw-from-green-700{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:tw-from-green-800{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:tw-from-green-900{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:tw-from-teal-100{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:tw-from-teal-200{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:tw-from-teal-300{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:tw-from-teal-400{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:tw-from-teal-500{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:tw-from-teal-600{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:tw-from-teal-700{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:tw-from-teal-800{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:tw-from-teal-900{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:tw-from-blue-100{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:tw-from-blue-200{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:tw-from-blue-300{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:tw-from-blue-400{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:tw-from-blue-500{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:tw-from-blue-600{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:tw-from-blue-700{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:tw-from-blue-800{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:tw-from-blue-900{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:tw-from-indigo-100{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:tw-from-indigo-200{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:tw-from-indigo-300{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:tw-from-indigo-400{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:tw-from-indigo-500{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:tw-from-indigo-600{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:tw-from-indigo-700{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:tw-from-indigo-800{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:tw-from-indigo-900{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:tw-from-purple-100{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:tw-from-purple-200{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:tw-from-purple-300{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:tw-from-purple-400{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:tw-from-purple-500{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:tw-from-purple-600{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:tw-from-purple-700{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:tw-from-purple-800{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:tw-from-purple-900{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:tw-from-pink-100{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:tw-from-pink-200{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:tw-from-pink-300{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:tw-from-pink-400{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:tw-from-pink-500{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:tw-from-pink-600{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:tw-from-pink-700{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:tw-from-pink-800{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:tw-from-pink-900{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:tw-via-transparent{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:tw-via-tlg-green-dark{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .md\:tw-via-tlg-green{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .md\:tw-via-tlg-orange-500{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .md\:tw-via-black{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:tw-via-white{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:tw-via-gray-100{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:tw-via-gray-200{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:tw-via-gray-300{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:tw-via-gray-400{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:tw-via-gray-500{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:tw-via-gray-600{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:tw-via-gray-700{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:tw-via-gray-800{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:tw-via-gray-900{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:tw-via-red-100{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:tw-via-red-200{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:tw-via-red-300{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:tw-via-red-400{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:tw-via-red-500{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:tw-via-red-600{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:tw-via-red-700{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:tw-via-red-800{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:tw-via-red-900{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:tw-via-orange-100{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:tw-via-orange-200{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:tw-via-orange-300{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:tw-via-orange-400{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:tw-via-orange-500{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:tw-via-orange-600{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:tw-via-orange-700{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:tw-via-orange-800{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:tw-via-orange-900{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:tw-via-yellow-100{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:tw-via-yellow-200{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:tw-via-yellow-300{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:tw-via-yellow-400{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:tw-via-yellow-500{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:tw-via-yellow-600{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:tw-via-yellow-700{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:tw-via-yellow-800{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:tw-via-yellow-900{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:tw-via-green-100{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:tw-via-green-200{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:tw-via-green-300{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:tw-via-green-400{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:tw-via-green-500{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:tw-via-green-600{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:tw-via-green-700{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:tw-via-green-800{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:tw-via-green-900{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:tw-via-teal-100{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:tw-via-teal-200{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:tw-via-teal-300{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:tw-via-teal-400{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:tw-via-teal-500{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:tw-via-teal-600{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:tw-via-teal-700{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:tw-via-teal-800{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:tw-via-teal-900{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:tw-via-blue-100{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:tw-via-blue-200{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:tw-via-blue-300{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:tw-via-blue-400{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:tw-via-blue-500{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:tw-via-blue-600{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:tw-via-blue-700{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:tw-via-blue-800{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:tw-via-blue-900{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:tw-via-indigo-100{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:tw-via-indigo-200{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:tw-via-indigo-300{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:tw-via-indigo-400{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:tw-via-indigo-500{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:tw-via-indigo-600{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:tw-via-indigo-700{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:tw-via-indigo-800{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:tw-via-indigo-900{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:tw-via-purple-100{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:tw-via-purple-200{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:tw-via-purple-300{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:tw-via-purple-400{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:tw-via-purple-500{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:tw-via-purple-600{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:tw-via-purple-700{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:tw-via-purple-800{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:tw-via-purple-900{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:tw-via-pink-100{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:tw-via-pink-200{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:tw-via-pink-300{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:tw-via-pink-400{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:tw-via-pink-500{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:tw-via-pink-600{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:tw-via-pink-700{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:tw-via-pink-800{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:tw-via-pink-900{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:tw-to-transparent{
    --gradient-to-color: transparent
  }

  .md\:tw-to-tlg-green-dark{
    --gradient-to-color: #76b84e
  }

  .md\:tw-to-tlg-green{
    --gradient-to-color: #a4ca61
  }

  .md\:tw-to-tlg-orange-500{
    --gradient-to-color: #ff7f41
  }

  .md\:tw-to-black{
    --gradient-to-color: #000
  }

  .md\:tw-to-white{
    --gradient-to-color: #fff
  }

  .md\:tw-to-gray-100{
    --gradient-to-color: #f7fafc
  }

  .md\:tw-to-gray-200{
    --gradient-to-color: #edf2f7
  }

  .md\:tw-to-gray-300{
    --gradient-to-color: #e2e8f0
  }

  .md\:tw-to-gray-400{
    --gradient-to-color: #cbd5e0
  }

  .md\:tw-to-gray-500{
    --gradient-to-color: #a0aec0
  }

  .md\:tw-to-gray-600{
    --gradient-to-color: #718096
  }

  .md\:tw-to-gray-700{
    --gradient-to-color: #4a5568
  }

  .md\:tw-to-gray-800{
    --gradient-to-color: #2d3748
  }

  .md\:tw-to-gray-900{
    --gradient-to-color: #1a202c
  }

  .md\:tw-to-red-100{
    --gradient-to-color: #fff5f5
  }

  .md\:tw-to-red-200{
    --gradient-to-color: #fed7d7
  }

  .md\:tw-to-red-300{
    --gradient-to-color: #feb2b2
  }

  .md\:tw-to-red-400{
    --gradient-to-color: #fc8181
  }

  .md\:tw-to-red-500{
    --gradient-to-color: #f56565
  }

  .md\:tw-to-red-600{
    --gradient-to-color: #e53e3e
  }

  .md\:tw-to-red-700{
    --gradient-to-color: #c53030
  }

  .md\:tw-to-red-800{
    --gradient-to-color: #9b2c2c
  }

  .md\:tw-to-red-900{
    --gradient-to-color: #742a2a
  }

  .md\:tw-to-orange-100{
    --gradient-to-color: #fffaf0
  }

  .md\:tw-to-orange-200{
    --gradient-to-color: #feebc8
  }

  .md\:tw-to-orange-300{
    --gradient-to-color: #fbd38d
  }

  .md\:tw-to-orange-400{
    --gradient-to-color: #f6ad55
  }

  .md\:tw-to-orange-500{
    --gradient-to-color: #ed8936
  }

  .md\:tw-to-orange-600{
    --gradient-to-color: #dd6b20
  }

  .md\:tw-to-orange-700{
    --gradient-to-color: #c05621
  }

  .md\:tw-to-orange-800{
    --gradient-to-color: #9c4221
  }

  .md\:tw-to-orange-900{
    --gradient-to-color: #7b341e
  }

  .md\:tw-to-yellow-100{
    --gradient-to-color: #fffff0
  }

  .md\:tw-to-yellow-200{
    --gradient-to-color: #fefcbf
  }

  .md\:tw-to-yellow-300{
    --gradient-to-color: #faf089
  }

  .md\:tw-to-yellow-400{
    --gradient-to-color: #f6e05e
  }

  .md\:tw-to-yellow-500{
    --gradient-to-color: #ecc94b
  }

  .md\:tw-to-yellow-600{
    --gradient-to-color: #d69e2e
  }

  .md\:tw-to-yellow-700{
    --gradient-to-color: #b7791f
  }

  .md\:tw-to-yellow-800{
    --gradient-to-color: #975a16
  }

  .md\:tw-to-yellow-900{
    --gradient-to-color: #744210
  }

  .md\:tw-to-green-100{
    --gradient-to-color: #f0fff4
  }

  .md\:tw-to-green-200{
    --gradient-to-color: #c6f6d5
  }

  .md\:tw-to-green-300{
    --gradient-to-color: #9ae6b4
  }

  .md\:tw-to-green-400{
    --gradient-to-color: #68d391
  }

  .md\:tw-to-green-500{
    --gradient-to-color: #48bb78
  }

  .md\:tw-to-green-600{
    --gradient-to-color: #38a169
  }

  .md\:tw-to-green-700{
    --gradient-to-color: #2f855a
  }

  .md\:tw-to-green-800{
    --gradient-to-color: #276749
  }

  .md\:tw-to-green-900{
    --gradient-to-color: #22543d
  }

  .md\:tw-to-teal-100{
    --gradient-to-color: #e6fffa
  }

  .md\:tw-to-teal-200{
    --gradient-to-color: #b2f5ea
  }

  .md\:tw-to-teal-300{
    --gradient-to-color: #81e6d9
  }

  .md\:tw-to-teal-400{
    --gradient-to-color: #4fd1c5
  }

  .md\:tw-to-teal-500{
    --gradient-to-color: #38b2ac
  }

  .md\:tw-to-teal-600{
    --gradient-to-color: #319795
  }

  .md\:tw-to-teal-700{
    --gradient-to-color: #2c7a7b
  }

  .md\:tw-to-teal-800{
    --gradient-to-color: #285e61
  }

  .md\:tw-to-teal-900{
    --gradient-to-color: #234e52
  }

  .md\:tw-to-blue-100{
    --gradient-to-color: #ebf8ff
  }

  .md\:tw-to-blue-200{
    --gradient-to-color: #bee3f8
  }

  .md\:tw-to-blue-300{
    --gradient-to-color: #90cdf4
  }

  .md\:tw-to-blue-400{
    --gradient-to-color: #63b3ed
  }

  .md\:tw-to-blue-500{
    --gradient-to-color: #4299e1
  }

  .md\:tw-to-blue-600{
    --gradient-to-color: #3182ce
  }

  .md\:tw-to-blue-700{
    --gradient-to-color: #2b6cb0
  }

  .md\:tw-to-blue-800{
    --gradient-to-color: #2c5282
  }

  .md\:tw-to-blue-900{
    --gradient-to-color: #2a4365
  }

  .md\:tw-to-indigo-100{
    --gradient-to-color: #ebf4ff
  }

  .md\:tw-to-indigo-200{
    --gradient-to-color: #c3dafe
  }

  .md\:tw-to-indigo-300{
    --gradient-to-color: #a3bffa
  }

  .md\:tw-to-indigo-400{
    --gradient-to-color: #7f9cf5
  }

  .md\:tw-to-indigo-500{
    --gradient-to-color: #667eea
  }

  .md\:tw-to-indigo-600{
    --gradient-to-color: #5a67d8
  }

  .md\:tw-to-indigo-700{
    --gradient-to-color: #4c51bf
  }

  .md\:tw-to-indigo-800{
    --gradient-to-color: #434190
  }

  .md\:tw-to-indigo-900{
    --gradient-to-color: #3c366b
  }

  .md\:tw-to-purple-100{
    --gradient-to-color: #faf5ff
  }

  .md\:tw-to-purple-200{
    --gradient-to-color: #e9d8fd
  }

  .md\:tw-to-purple-300{
    --gradient-to-color: #d6bcfa
  }

  .md\:tw-to-purple-400{
    --gradient-to-color: #b794f4
  }

  .md\:tw-to-purple-500{
    --gradient-to-color: #9f7aea
  }

  .md\:tw-to-purple-600{
    --gradient-to-color: #805ad5
  }

  .md\:tw-to-purple-700{
    --gradient-to-color: #6b46c1
  }

  .md\:tw-to-purple-800{
    --gradient-to-color: #553c9a
  }

  .md\:tw-to-purple-900{
    --gradient-to-color: #44337a
  }

  .md\:tw-to-pink-100{
    --gradient-to-color: #fff5f7
  }

  .md\:tw-to-pink-200{
    --gradient-to-color: #fed7e2
  }

  .md\:tw-to-pink-300{
    --gradient-to-color: #fbb6ce
  }

  .md\:tw-to-pink-400{
    --gradient-to-color: #f687b3
  }

  .md\:tw-to-pink-500{
    --gradient-to-color: #ed64a6
  }

  .md\:tw-to-pink-600{
    --gradient-to-color: #d53f8c
  }

  .md\:tw-to-pink-700{
    --gradient-to-color: #b83280
  }

  .md\:tw-to-pink-800{
    --gradient-to-color: #97266d
  }

  .md\:tw-to-pink-900{
    --gradient-to-color: #702459
  }

  .md\:hover\:tw-from-transparent:hover{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:tw-from-tlg-green-dark:hover{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .md\:hover\:tw-from-tlg-green:hover{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .md\:hover\:tw-from-tlg-orange-500:hover{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .md\:hover\:tw-from-black:hover{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:tw-from-white:hover{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:tw-from-gray-100:hover{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:hover\:tw-from-gray-200:hover{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:hover\:tw-from-gray-300:hover{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:hover\:tw-from-gray-400:hover{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:hover\:tw-from-gray-500:hover{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:hover\:tw-from-gray-600:hover{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:hover\:tw-from-gray-700:hover{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:hover\:tw-from-gray-800:hover{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:hover\:tw-from-gray-900:hover{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:hover\:tw-from-red-100:hover{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:hover\:tw-from-red-200:hover{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:hover\:tw-from-red-300:hover{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:hover\:tw-from-red-400:hover{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:hover\:tw-from-red-500:hover{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:hover\:tw-from-red-600:hover{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:hover\:tw-from-red-700:hover{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:hover\:tw-from-red-800:hover{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:hover\:tw-from-red-900:hover{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:hover\:tw-from-orange-100:hover{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:hover\:tw-from-orange-200:hover{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:hover\:tw-from-orange-300:hover{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:hover\:tw-from-orange-400:hover{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:hover\:tw-from-orange-500:hover{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:hover\:tw-from-orange-600:hover{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:hover\:tw-from-orange-700:hover{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:hover\:tw-from-orange-800:hover{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:hover\:tw-from-orange-900:hover{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:hover\:tw-from-yellow-100:hover{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:hover\:tw-from-yellow-200:hover{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:hover\:tw-from-yellow-300:hover{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:hover\:tw-from-yellow-400:hover{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:hover\:tw-from-yellow-500:hover{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:hover\:tw-from-yellow-600:hover{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:hover\:tw-from-yellow-700:hover{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:hover\:tw-from-yellow-800:hover{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:hover\:tw-from-yellow-900:hover{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:hover\:tw-from-green-100:hover{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:hover\:tw-from-green-200:hover{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:hover\:tw-from-green-300:hover{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:hover\:tw-from-green-400:hover{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:hover\:tw-from-green-500:hover{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:hover\:tw-from-green-600:hover{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:hover\:tw-from-green-700:hover{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:hover\:tw-from-green-800:hover{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:hover\:tw-from-green-900:hover{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:hover\:tw-from-teal-100:hover{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:hover\:tw-from-teal-200:hover{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:hover\:tw-from-teal-300:hover{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:hover\:tw-from-teal-400:hover{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:hover\:tw-from-teal-500:hover{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:hover\:tw-from-teal-600:hover{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:hover\:tw-from-teal-700:hover{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:hover\:tw-from-teal-800:hover{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:hover\:tw-from-teal-900:hover{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:hover\:tw-from-blue-100:hover{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:hover\:tw-from-blue-200:hover{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:hover\:tw-from-blue-300:hover{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:hover\:tw-from-blue-400:hover{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:hover\:tw-from-blue-500:hover{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:hover\:tw-from-blue-600:hover{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:hover\:tw-from-blue-700:hover{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:hover\:tw-from-blue-800:hover{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:hover\:tw-from-blue-900:hover{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:hover\:tw-from-indigo-100:hover{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:hover\:tw-from-indigo-200:hover{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:hover\:tw-from-indigo-300:hover{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:hover\:tw-from-indigo-400:hover{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:hover\:tw-from-indigo-500:hover{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:hover\:tw-from-indigo-600:hover{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:hover\:tw-from-indigo-700:hover{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:hover\:tw-from-indigo-800:hover{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:hover\:tw-from-indigo-900:hover{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:hover\:tw-from-purple-100:hover{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:hover\:tw-from-purple-200:hover{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:hover\:tw-from-purple-300:hover{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:hover\:tw-from-purple-400:hover{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:hover\:tw-from-purple-500:hover{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:hover\:tw-from-purple-600:hover{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:hover\:tw-from-purple-700:hover{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:hover\:tw-from-purple-800:hover{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:hover\:tw-from-purple-900:hover{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:hover\:tw-from-pink-100:hover{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:hover\:tw-from-pink-200:hover{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:hover\:tw-from-pink-300:hover{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:hover\:tw-from-pink-400:hover{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:hover\:tw-from-pink-500:hover{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:hover\:tw-from-pink-600:hover{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:hover\:tw-from-pink-700:hover{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:hover\:tw-from-pink-800:hover{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:hover\:tw-from-pink-900:hover{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:hover\:tw-via-transparent:hover{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:tw-via-tlg-green-dark:hover{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .md\:hover\:tw-via-tlg-green:hover{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .md\:hover\:tw-via-tlg-orange-500:hover{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .md\:hover\:tw-via-black:hover{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:hover\:tw-via-white:hover{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:tw-via-gray-100:hover{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:hover\:tw-via-gray-200:hover{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:hover\:tw-via-gray-300:hover{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:hover\:tw-via-gray-400:hover{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:hover\:tw-via-gray-500:hover{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:hover\:tw-via-gray-600:hover{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:hover\:tw-via-gray-700:hover{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:hover\:tw-via-gray-800:hover{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:hover\:tw-via-gray-900:hover{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:hover\:tw-via-red-100:hover{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:hover\:tw-via-red-200:hover{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:hover\:tw-via-red-300:hover{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:hover\:tw-via-red-400:hover{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:hover\:tw-via-red-500:hover{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:hover\:tw-via-red-600:hover{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:hover\:tw-via-red-700:hover{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:hover\:tw-via-red-800:hover{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:hover\:tw-via-red-900:hover{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:hover\:tw-via-orange-100:hover{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:hover\:tw-via-orange-200:hover{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:hover\:tw-via-orange-300:hover{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:hover\:tw-via-orange-400:hover{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:hover\:tw-via-orange-500:hover{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:hover\:tw-via-orange-600:hover{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:hover\:tw-via-orange-700:hover{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:hover\:tw-via-orange-800:hover{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:hover\:tw-via-orange-900:hover{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:hover\:tw-via-yellow-100:hover{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:hover\:tw-via-yellow-200:hover{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:hover\:tw-via-yellow-300:hover{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:hover\:tw-via-yellow-400:hover{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:hover\:tw-via-yellow-500:hover{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:hover\:tw-via-yellow-600:hover{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:hover\:tw-via-yellow-700:hover{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:hover\:tw-via-yellow-800:hover{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:hover\:tw-via-yellow-900:hover{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:hover\:tw-via-green-100:hover{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:hover\:tw-via-green-200:hover{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:hover\:tw-via-green-300:hover{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:hover\:tw-via-green-400:hover{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:hover\:tw-via-green-500:hover{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:hover\:tw-via-green-600:hover{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:hover\:tw-via-green-700:hover{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:hover\:tw-via-green-800:hover{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:hover\:tw-via-green-900:hover{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:hover\:tw-via-teal-100:hover{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:hover\:tw-via-teal-200:hover{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:hover\:tw-via-teal-300:hover{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:hover\:tw-via-teal-400:hover{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:hover\:tw-via-teal-500:hover{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:hover\:tw-via-teal-600:hover{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:hover\:tw-via-teal-700:hover{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:hover\:tw-via-teal-800:hover{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:hover\:tw-via-teal-900:hover{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:hover\:tw-via-blue-100:hover{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:hover\:tw-via-blue-200:hover{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:hover\:tw-via-blue-300:hover{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:hover\:tw-via-blue-400:hover{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:hover\:tw-via-blue-500:hover{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:hover\:tw-via-blue-600:hover{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:hover\:tw-via-blue-700:hover{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:hover\:tw-via-blue-800:hover{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:hover\:tw-via-blue-900:hover{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:hover\:tw-via-indigo-100:hover{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:hover\:tw-via-indigo-200:hover{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:hover\:tw-via-indigo-300:hover{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:hover\:tw-via-indigo-400:hover{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:hover\:tw-via-indigo-500:hover{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:hover\:tw-via-indigo-600:hover{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:hover\:tw-via-indigo-700:hover{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:hover\:tw-via-indigo-800:hover{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:hover\:tw-via-indigo-900:hover{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:hover\:tw-via-purple-100:hover{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:hover\:tw-via-purple-200:hover{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:hover\:tw-via-purple-300:hover{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:hover\:tw-via-purple-400:hover{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:hover\:tw-via-purple-500:hover{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:hover\:tw-via-purple-600:hover{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:hover\:tw-via-purple-700:hover{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:hover\:tw-via-purple-800:hover{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:hover\:tw-via-purple-900:hover{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:hover\:tw-via-pink-100:hover{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:hover\:tw-via-pink-200:hover{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:hover\:tw-via-pink-300:hover{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:hover\:tw-via-pink-400:hover{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:hover\:tw-via-pink-500:hover{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:hover\:tw-via-pink-600:hover{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:hover\:tw-via-pink-700:hover{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:hover\:tw-via-pink-800:hover{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:hover\:tw-via-pink-900:hover{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:hover\:tw-to-transparent:hover{
    --gradient-to-color: transparent
  }

  .md\:hover\:tw-to-tlg-green-dark:hover{
    --gradient-to-color: #76b84e
  }

  .md\:hover\:tw-to-tlg-green:hover{
    --gradient-to-color: #a4ca61
  }

  .md\:hover\:tw-to-tlg-orange-500:hover{
    --gradient-to-color: #ff7f41
  }

  .md\:hover\:tw-to-black:hover{
    --gradient-to-color: #000
  }

  .md\:hover\:tw-to-white:hover{
    --gradient-to-color: #fff
  }

  .md\:hover\:tw-to-gray-100:hover{
    --gradient-to-color: #f7fafc
  }

  .md\:hover\:tw-to-gray-200:hover{
    --gradient-to-color: #edf2f7
  }

  .md\:hover\:tw-to-gray-300:hover{
    --gradient-to-color: #e2e8f0
  }

  .md\:hover\:tw-to-gray-400:hover{
    --gradient-to-color: #cbd5e0
  }

  .md\:hover\:tw-to-gray-500:hover{
    --gradient-to-color: #a0aec0
  }

  .md\:hover\:tw-to-gray-600:hover{
    --gradient-to-color: #718096
  }

  .md\:hover\:tw-to-gray-700:hover{
    --gradient-to-color: #4a5568
  }

  .md\:hover\:tw-to-gray-800:hover{
    --gradient-to-color: #2d3748
  }

  .md\:hover\:tw-to-gray-900:hover{
    --gradient-to-color: #1a202c
  }

  .md\:hover\:tw-to-red-100:hover{
    --gradient-to-color: #fff5f5
  }

  .md\:hover\:tw-to-red-200:hover{
    --gradient-to-color: #fed7d7
  }

  .md\:hover\:tw-to-red-300:hover{
    --gradient-to-color: #feb2b2
  }

  .md\:hover\:tw-to-red-400:hover{
    --gradient-to-color: #fc8181
  }

  .md\:hover\:tw-to-red-500:hover{
    --gradient-to-color: #f56565
  }

  .md\:hover\:tw-to-red-600:hover{
    --gradient-to-color: #e53e3e
  }

  .md\:hover\:tw-to-red-700:hover{
    --gradient-to-color: #c53030
  }

  .md\:hover\:tw-to-red-800:hover{
    --gradient-to-color: #9b2c2c
  }

  .md\:hover\:tw-to-red-900:hover{
    --gradient-to-color: #742a2a
  }

  .md\:hover\:tw-to-orange-100:hover{
    --gradient-to-color: #fffaf0
  }

  .md\:hover\:tw-to-orange-200:hover{
    --gradient-to-color: #feebc8
  }

  .md\:hover\:tw-to-orange-300:hover{
    --gradient-to-color: #fbd38d
  }

  .md\:hover\:tw-to-orange-400:hover{
    --gradient-to-color: #f6ad55
  }

  .md\:hover\:tw-to-orange-500:hover{
    --gradient-to-color: #ed8936
  }

  .md\:hover\:tw-to-orange-600:hover{
    --gradient-to-color: #dd6b20
  }

  .md\:hover\:tw-to-orange-700:hover{
    --gradient-to-color: #c05621
  }

  .md\:hover\:tw-to-orange-800:hover{
    --gradient-to-color: #9c4221
  }

  .md\:hover\:tw-to-orange-900:hover{
    --gradient-to-color: #7b341e
  }

  .md\:hover\:tw-to-yellow-100:hover{
    --gradient-to-color: #fffff0
  }

  .md\:hover\:tw-to-yellow-200:hover{
    --gradient-to-color: #fefcbf
  }

  .md\:hover\:tw-to-yellow-300:hover{
    --gradient-to-color: #faf089
  }

  .md\:hover\:tw-to-yellow-400:hover{
    --gradient-to-color: #f6e05e
  }

  .md\:hover\:tw-to-yellow-500:hover{
    --gradient-to-color: #ecc94b
  }

  .md\:hover\:tw-to-yellow-600:hover{
    --gradient-to-color: #d69e2e
  }

  .md\:hover\:tw-to-yellow-700:hover{
    --gradient-to-color: #b7791f
  }

  .md\:hover\:tw-to-yellow-800:hover{
    --gradient-to-color: #975a16
  }

  .md\:hover\:tw-to-yellow-900:hover{
    --gradient-to-color: #744210
  }

  .md\:hover\:tw-to-green-100:hover{
    --gradient-to-color: #f0fff4
  }

  .md\:hover\:tw-to-green-200:hover{
    --gradient-to-color: #c6f6d5
  }

  .md\:hover\:tw-to-green-300:hover{
    --gradient-to-color: #9ae6b4
  }

  .md\:hover\:tw-to-green-400:hover{
    --gradient-to-color: #68d391
  }

  .md\:hover\:tw-to-green-500:hover{
    --gradient-to-color: #48bb78
  }

  .md\:hover\:tw-to-green-600:hover{
    --gradient-to-color: #38a169
  }

  .md\:hover\:tw-to-green-700:hover{
    --gradient-to-color: #2f855a
  }

  .md\:hover\:tw-to-green-800:hover{
    --gradient-to-color: #276749
  }

  .md\:hover\:tw-to-green-900:hover{
    --gradient-to-color: #22543d
  }

  .md\:hover\:tw-to-teal-100:hover{
    --gradient-to-color: #e6fffa
  }

  .md\:hover\:tw-to-teal-200:hover{
    --gradient-to-color: #b2f5ea
  }

  .md\:hover\:tw-to-teal-300:hover{
    --gradient-to-color: #81e6d9
  }

  .md\:hover\:tw-to-teal-400:hover{
    --gradient-to-color: #4fd1c5
  }

  .md\:hover\:tw-to-teal-500:hover{
    --gradient-to-color: #38b2ac
  }

  .md\:hover\:tw-to-teal-600:hover{
    --gradient-to-color: #319795
  }

  .md\:hover\:tw-to-teal-700:hover{
    --gradient-to-color: #2c7a7b
  }

  .md\:hover\:tw-to-teal-800:hover{
    --gradient-to-color: #285e61
  }

  .md\:hover\:tw-to-teal-900:hover{
    --gradient-to-color: #234e52
  }

  .md\:hover\:tw-to-blue-100:hover{
    --gradient-to-color: #ebf8ff
  }

  .md\:hover\:tw-to-blue-200:hover{
    --gradient-to-color: #bee3f8
  }

  .md\:hover\:tw-to-blue-300:hover{
    --gradient-to-color: #90cdf4
  }

  .md\:hover\:tw-to-blue-400:hover{
    --gradient-to-color: #63b3ed
  }

  .md\:hover\:tw-to-blue-500:hover{
    --gradient-to-color: #4299e1
  }

  .md\:hover\:tw-to-blue-600:hover{
    --gradient-to-color: #3182ce
  }

  .md\:hover\:tw-to-blue-700:hover{
    --gradient-to-color: #2b6cb0
  }

  .md\:hover\:tw-to-blue-800:hover{
    --gradient-to-color: #2c5282
  }

  .md\:hover\:tw-to-blue-900:hover{
    --gradient-to-color: #2a4365
  }

  .md\:hover\:tw-to-indigo-100:hover{
    --gradient-to-color: #ebf4ff
  }

  .md\:hover\:tw-to-indigo-200:hover{
    --gradient-to-color: #c3dafe
  }

  .md\:hover\:tw-to-indigo-300:hover{
    --gradient-to-color: #a3bffa
  }

  .md\:hover\:tw-to-indigo-400:hover{
    --gradient-to-color: #7f9cf5
  }

  .md\:hover\:tw-to-indigo-500:hover{
    --gradient-to-color: #667eea
  }

  .md\:hover\:tw-to-indigo-600:hover{
    --gradient-to-color: #5a67d8
  }

  .md\:hover\:tw-to-indigo-700:hover{
    --gradient-to-color: #4c51bf
  }

  .md\:hover\:tw-to-indigo-800:hover{
    --gradient-to-color: #434190
  }

  .md\:hover\:tw-to-indigo-900:hover{
    --gradient-to-color: #3c366b
  }

  .md\:hover\:tw-to-purple-100:hover{
    --gradient-to-color: #faf5ff
  }

  .md\:hover\:tw-to-purple-200:hover{
    --gradient-to-color: #e9d8fd
  }

  .md\:hover\:tw-to-purple-300:hover{
    --gradient-to-color: #d6bcfa
  }

  .md\:hover\:tw-to-purple-400:hover{
    --gradient-to-color: #b794f4
  }

  .md\:hover\:tw-to-purple-500:hover{
    --gradient-to-color: #9f7aea
  }

  .md\:hover\:tw-to-purple-600:hover{
    --gradient-to-color: #805ad5
  }

  .md\:hover\:tw-to-purple-700:hover{
    --gradient-to-color: #6b46c1
  }

  .md\:hover\:tw-to-purple-800:hover{
    --gradient-to-color: #553c9a
  }

  .md\:hover\:tw-to-purple-900:hover{
    --gradient-to-color: #44337a
  }

  .md\:hover\:tw-to-pink-100:hover{
    --gradient-to-color: #fff5f7
  }

  .md\:hover\:tw-to-pink-200:hover{
    --gradient-to-color: #fed7e2
  }

  .md\:hover\:tw-to-pink-300:hover{
    --gradient-to-color: #fbb6ce
  }

  .md\:hover\:tw-to-pink-400:hover{
    --gradient-to-color: #f687b3
  }

  .md\:hover\:tw-to-pink-500:hover{
    --gradient-to-color: #ed64a6
  }

  .md\:hover\:tw-to-pink-600:hover{
    --gradient-to-color: #d53f8c
  }

  .md\:hover\:tw-to-pink-700:hover{
    --gradient-to-color: #b83280
  }

  .md\:hover\:tw-to-pink-800:hover{
    --gradient-to-color: #97266d
  }

  .md\:hover\:tw-to-pink-900:hover{
    --gradient-to-color: #702459
  }

  .md\:focus\:tw-from-transparent:focus{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:tw-from-tlg-green-dark:focus{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .md\:focus\:tw-from-tlg-green:focus{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .md\:focus\:tw-from-tlg-orange-500:focus{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .md\:focus\:tw-from-black:focus{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:tw-from-white:focus{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:tw-from-gray-100:focus{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:focus\:tw-from-gray-200:focus{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:focus\:tw-from-gray-300:focus{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:focus\:tw-from-gray-400:focus{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:focus\:tw-from-gray-500:focus{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:focus\:tw-from-gray-600:focus{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:focus\:tw-from-gray-700:focus{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:focus\:tw-from-gray-800:focus{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:focus\:tw-from-gray-900:focus{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:focus\:tw-from-red-100:focus{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:focus\:tw-from-red-200:focus{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:focus\:tw-from-red-300:focus{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:focus\:tw-from-red-400:focus{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:focus\:tw-from-red-500:focus{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:focus\:tw-from-red-600:focus{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:focus\:tw-from-red-700:focus{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:focus\:tw-from-red-800:focus{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:focus\:tw-from-red-900:focus{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:focus\:tw-from-orange-100:focus{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:focus\:tw-from-orange-200:focus{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:focus\:tw-from-orange-300:focus{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:focus\:tw-from-orange-400:focus{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:focus\:tw-from-orange-500:focus{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:focus\:tw-from-orange-600:focus{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:focus\:tw-from-orange-700:focus{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:focus\:tw-from-orange-800:focus{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:focus\:tw-from-orange-900:focus{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:focus\:tw-from-yellow-100:focus{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:focus\:tw-from-yellow-200:focus{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:focus\:tw-from-yellow-300:focus{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:focus\:tw-from-yellow-400:focus{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:focus\:tw-from-yellow-500:focus{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:focus\:tw-from-yellow-600:focus{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:focus\:tw-from-yellow-700:focus{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:focus\:tw-from-yellow-800:focus{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:focus\:tw-from-yellow-900:focus{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:focus\:tw-from-green-100:focus{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:focus\:tw-from-green-200:focus{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:focus\:tw-from-green-300:focus{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:focus\:tw-from-green-400:focus{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:focus\:tw-from-green-500:focus{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:focus\:tw-from-green-600:focus{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:focus\:tw-from-green-700:focus{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:focus\:tw-from-green-800:focus{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:focus\:tw-from-green-900:focus{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:focus\:tw-from-teal-100:focus{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:focus\:tw-from-teal-200:focus{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:focus\:tw-from-teal-300:focus{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:focus\:tw-from-teal-400:focus{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:focus\:tw-from-teal-500:focus{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:focus\:tw-from-teal-600:focus{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:focus\:tw-from-teal-700:focus{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:focus\:tw-from-teal-800:focus{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:focus\:tw-from-teal-900:focus{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:focus\:tw-from-blue-100:focus{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:focus\:tw-from-blue-200:focus{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:focus\:tw-from-blue-300:focus{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:focus\:tw-from-blue-400:focus{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:focus\:tw-from-blue-500:focus{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:focus\:tw-from-blue-600:focus{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:focus\:tw-from-blue-700:focus{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:focus\:tw-from-blue-800:focus{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:focus\:tw-from-blue-900:focus{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:focus\:tw-from-indigo-100:focus{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:focus\:tw-from-indigo-200:focus{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:focus\:tw-from-indigo-300:focus{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:focus\:tw-from-indigo-400:focus{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:focus\:tw-from-indigo-500:focus{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:focus\:tw-from-indigo-600:focus{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:focus\:tw-from-indigo-700:focus{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:focus\:tw-from-indigo-800:focus{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:focus\:tw-from-indigo-900:focus{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:focus\:tw-from-purple-100:focus{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:focus\:tw-from-purple-200:focus{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:focus\:tw-from-purple-300:focus{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:focus\:tw-from-purple-400:focus{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:focus\:tw-from-purple-500:focus{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:focus\:tw-from-purple-600:focus{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:focus\:tw-from-purple-700:focus{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:focus\:tw-from-purple-800:focus{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:focus\:tw-from-purple-900:focus{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:focus\:tw-from-pink-100:focus{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:focus\:tw-from-pink-200:focus{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:focus\:tw-from-pink-300:focus{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:focus\:tw-from-pink-400:focus{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:focus\:tw-from-pink-500:focus{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:focus\:tw-from-pink-600:focus{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:focus\:tw-from-pink-700:focus{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:focus\:tw-from-pink-800:focus{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:focus\:tw-from-pink-900:focus{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:focus\:tw-via-transparent:focus{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:tw-via-tlg-green-dark:focus{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .md\:focus\:tw-via-tlg-green:focus{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .md\:focus\:tw-via-tlg-orange-500:focus{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .md\:focus\:tw-via-black:focus{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .md\:focus\:tw-via-white:focus{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:tw-via-gray-100:focus{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .md\:focus\:tw-via-gray-200:focus{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .md\:focus\:tw-via-gray-300:focus{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .md\:focus\:tw-via-gray-400:focus{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .md\:focus\:tw-via-gray-500:focus{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .md\:focus\:tw-via-gray-600:focus{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .md\:focus\:tw-via-gray-700:focus{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .md\:focus\:tw-via-gray-800:focus{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .md\:focus\:tw-via-gray-900:focus{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .md\:focus\:tw-via-red-100:focus{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .md\:focus\:tw-via-red-200:focus{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .md\:focus\:tw-via-red-300:focus{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .md\:focus\:tw-via-red-400:focus{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .md\:focus\:tw-via-red-500:focus{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .md\:focus\:tw-via-red-600:focus{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .md\:focus\:tw-via-red-700:focus{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .md\:focus\:tw-via-red-800:focus{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .md\:focus\:tw-via-red-900:focus{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .md\:focus\:tw-via-orange-100:focus{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .md\:focus\:tw-via-orange-200:focus{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .md\:focus\:tw-via-orange-300:focus{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .md\:focus\:tw-via-orange-400:focus{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .md\:focus\:tw-via-orange-500:focus{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .md\:focus\:tw-via-orange-600:focus{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .md\:focus\:tw-via-orange-700:focus{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .md\:focus\:tw-via-orange-800:focus{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .md\:focus\:tw-via-orange-900:focus{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .md\:focus\:tw-via-yellow-100:focus{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .md\:focus\:tw-via-yellow-200:focus{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .md\:focus\:tw-via-yellow-300:focus{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .md\:focus\:tw-via-yellow-400:focus{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .md\:focus\:tw-via-yellow-500:focus{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .md\:focus\:tw-via-yellow-600:focus{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .md\:focus\:tw-via-yellow-700:focus{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .md\:focus\:tw-via-yellow-800:focus{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .md\:focus\:tw-via-yellow-900:focus{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .md\:focus\:tw-via-green-100:focus{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .md\:focus\:tw-via-green-200:focus{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .md\:focus\:tw-via-green-300:focus{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .md\:focus\:tw-via-green-400:focus{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .md\:focus\:tw-via-green-500:focus{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .md\:focus\:tw-via-green-600:focus{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .md\:focus\:tw-via-green-700:focus{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .md\:focus\:tw-via-green-800:focus{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .md\:focus\:tw-via-green-900:focus{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .md\:focus\:tw-via-teal-100:focus{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .md\:focus\:tw-via-teal-200:focus{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .md\:focus\:tw-via-teal-300:focus{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .md\:focus\:tw-via-teal-400:focus{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .md\:focus\:tw-via-teal-500:focus{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .md\:focus\:tw-via-teal-600:focus{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .md\:focus\:tw-via-teal-700:focus{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .md\:focus\:tw-via-teal-800:focus{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .md\:focus\:tw-via-teal-900:focus{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .md\:focus\:tw-via-blue-100:focus{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .md\:focus\:tw-via-blue-200:focus{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .md\:focus\:tw-via-blue-300:focus{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .md\:focus\:tw-via-blue-400:focus{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .md\:focus\:tw-via-blue-500:focus{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .md\:focus\:tw-via-blue-600:focus{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .md\:focus\:tw-via-blue-700:focus{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .md\:focus\:tw-via-blue-800:focus{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .md\:focus\:tw-via-blue-900:focus{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .md\:focus\:tw-via-indigo-100:focus{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .md\:focus\:tw-via-indigo-200:focus{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .md\:focus\:tw-via-indigo-300:focus{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .md\:focus\:tw-via-indigo-400:focus{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .md\:focus\:tw-via-indigo-500:focus{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .md\:focus\:tw-via-indigo-600:focus{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .md\:focus\:tw-via-indigo-700:focus{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .md\:focus\:tw-via-indigo-800:focus{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .md\:focus\:tw-via-indigo-900:focus{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .md\:focus\:tw-via-purple-100:focus{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .md\:focus\:tw-via-purple-200:focus{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .md\:focus\:tw-via-purple-300:focus{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .md\:focus\:tw-via-purple-400:focus{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .md\:focus\:tw-via-purple-500:focus{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .md\:focus\:tw-via-purple-600:focus{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .md\:focus\:tw-via-purple-700:focus{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .md\:focus\:tw-via-purple-800:focus{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .md\:focus\:tw-via-purple-900:focus{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .md\:focus\:tw-via-pink-100:focus{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .md\:focus\:tw-via-pink-200:focus{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .md\:focus\:tw-via-pink-300:focus{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .md\:focus\:tw-via-pink-400:focus{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .md\:focus\:tw-via-pink-500:focus{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .md\:focus\:tw-via-pink-600:focus{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .md\:focus\:tw-via-pink-700:focus{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .md\:focus\:tw-via-pink-800:focus{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .md\:focus\:tw-via-pink-900:focus{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .md\:focus\:tw-to-transparent:focus{
    --gradient-to-color: transparent
  }

  .md\:focus\:tw-to-tlg-green-dark:focus{
    --gradient-to-color: #76b84e
  }

  .md\:focus\:tw-to-tlg-green:focus{
    --gradient-to-color: #a4ca61
  }

  .md\:focus\:tw-to-tlg-orange-500:focus{
    --gradient-to-color: #ff7f41
  }

  .md\:focus\:tw-to-black:focus{
    --gradient-to-color: #000
  }

  .md\:focus\:tw-to-white:focus{
    --gradient-to-color: #fff
  }

  .md\:focus\:tw-to-gray-100:focus{
    --gradient-to-color: #f7fafc
  }

  .md\:focus\:tw-to-gray-200:focus{
    --gradient-to-color: #edf2f7
  }

  .md\:focus\:tw-to-gray-300:focus{
    --gradient-to-color: #e2e8f0
  }

  .md\:focus\:tw-to-gray-400:focus{
    --gradient-to-color: #cbd5e0
  }

  .md\:focus\:tw-to-gray-500:focus{
    --gradient-to-color: #a0aec0
  }

  .md\:focus\:tw-to-gray-600:focus{
    --gradient-to-color: #718096
  }

  .md\:focus\:tw-to-gray-700:focus{
    --gradient-to-color: #4a5568
  }

  .md\:focus\:tw-to-gray-800:focus{
    --gradient-to-color: #2d3748
  }

  .md\:focus\:tw-to-gray-900:focus{
    --gradient-to-color: #1a202c
  }

  .md\:focus\:tw-to-red-100:focus{
    --gradient-to-color: #fff5f5
  }

  .md\:focus\:tw-to-red-200:focus{
    --gradient-to-color: #fed7d7
  }

  .md\:focus\:tw-to-red-300:focus{
    --gradient-to-color: #feb2b2
  }

  .md\:focus\:tw-to-red-400:focus{
    --gradient-to-color: #fc8181
  }

  .md\:focus\:tw-to-red-500:focus{
    --gradient-to-color: #f56565
  }

  .md\:focus\:tw-to-red-600:focus{
    --gradient-to-color: #e53e3e
  }

  .md\:focus\:tw-to-red-700:focus{
    --gradient-to-color: #c53030
  }

  .md\:focus\:tw-to-red-800:focus{
    --gradient-to-color: #9b2c2c
  }

  .md\:focus\:tw-to-red-900:focus{
    --gradient-to-color: #742a2a
  }

  .md\:focus\:tw-to-orange-100:focus{
    --gradient-to-color: #fffaf0
  }

  .md\:focus\:tw-to-orange-200:focus{
    --gradient-to-color: #feebc8
  }

  .md\:focus\:tw-to-orange-300:focus{
    --gradient-to-color: #fbd38d
  }

  .md\:focus\:tw-to-orange-400:focus{
    --gradient-to-color: #f6ad55
  }

  .md\:focus\:tw-to-orange-500:focus{
    --gradient-to-color: #ed8936
  }

  .md\:focus\:tw-to-orange-600:focus{
    --gradient-to-color: #dd6b20
  }

  .md\:focus\:tw-to-orange-700:focus{
    --gradient-to-color: #c05621
  }

  .md\:focus\:tw-to-orange-800:focus{
    --gradient-to-color: #9c4221
  }

  .md\:focus\:tw-to-orange-900:focus{
    --gradient-to-color: #7b341e
  }

  .md\:focus\:tw-to-yellow-100:focus{
    --gradient-to-color: #fffff0
  }

  .md\:focus\:tw-to-yellow-200:focus{
    --gradient-to-color: #fefcbf
  }

  .md\:focus\:tw-to-yellow-300:focus{
    --gradient-to-color: #faf089
  }

  .md\:focus\:tw-to-yellow-400:focus{
    --gradient-to-color: #f6e05e
  }

  .md\:focus\:tw-to-yellow-500:focus{
    --gradient-to-color: #ecc94b
  }

  .md\:focus\:tw-to-yellow-600:focus{
    --gradient-to-color: #d69e2e
  }

  .md\:focus\:tw-to-yellow-700:focus{
    --gradient-to-color: #b7791f
  }

  .md\:focus\:tw-to-yellow-800:focus{
    --gradient-to-color: #975a16
  }

  .md\:focus\:tw-to-yellow-900:focus{
    --gradient-to-color: #744210
  }

  .md\:focus\:tw-to-green-100:focus{
    --gradient-to-color: #f0fff4
  }

  .md\:focus\:tw-to-green-200:focus{
    --gradient-to-color: #c6f6d5
  }

  .md\:focus\:tw-to-green-300:focus{
    --gradient-to-color: #9ae6b4
  }

  .md\:focus\:tw-to-green-400:focus{
    --gradient-to-color: #68d391
  }

  .md\:focus\:tw-to-green-500:focus{
    --gradient-to-color: #48bb78
  }

  .md\:focus\:tw-to-green-600:focus{
    --gradient-to-color: #38a169
  }

  .md\:focus\:tw-to-green-700:focus{
    --gradient-to-color: #2f855a
  }

  .md\:focus\:tw-to-green-800:focus{
    --gradient-to-color: #276749
  }

  .md\:focus\:tw-to-green-900:focus{
    --gradient-to-color: #22543d
  }

  .md\:focus\:tw-to-teal-100:focus{
    --gradient-to-color: #e6fffa
  }

  .md\:focus\:tw-to-teal-200:focus{
    --gradient-to-color: #b2f5ea
  }

  .md\:focus\:tw-to-teal-300:focus{
    --gradient-to-color: #81e6d9
  }

  .md\:focus\:tw-to-teal-400:focus{
    --gradient-to-color: #4fd1c5
  }

  .md\:focus\:tw-to-teal-500:focus{
    --gradient-to-color: #38b2ac
  }

  .md\:focus\:tw-to-teal-600:focus{
    --gradient-to-color: #319795
  }

  .md\:focus\:tw-to-teal-700:focus{
    --gradient-to-color: #2c7a7b
  }

  .md\:focus\:tw-to-teal-800:focus{
    --gradient-to-color: #285e61
  }

  .md\:focus\:tw-to-teal-900:focus{
    --gradient-to-color: #234e52
  }

  .md\:focus\:tw-to-blue-100:focus{
    --gradient-to-color: #ebf8ff
  }

  .md\:focus\:tw-to-blue-200:focus{
    --gradient-to-color: #bee3f8
  }

  .md\:focus\:tw-to-blue-300:focus{
    --gradient-to-color: #90cdf4
  }

  .md\:focus\:tw-to-blue-400:focus{
    --gradient-to-color: #63b3ed
  }

  .md\:focus\:tw-to-blue-500:focus{
    --gradient-to-color: #4299e1
  }

  .md\:focus\:tw-to-blue-600:focus{
    --gradient-to-color: #3182ce
  }

  .md\:focus\:tw-to-blue-700:focus{
    --gradient-to-color: #2b6cb0
  }

  .md\:focus\:tw-to-blue-800:focus{
    --gradient-to-color: #2c5282
  }

  .md\:focus\:tw-to-blue-900:focus{
    --gradient-to-color: #2a4365
  }

  .md\:focus\:tw-to-indigo-100:focus{
    --gradient-to-color: #ebf4ff
  }

  .md\:focus\:tw-to-indigo-200:focus{
    --gradient-to-color: #c3dafe
  }

  .md\:focus\:tw-to-indigo-300:focus{
    --gradient-to-color: #a3bffa
  }

  .md\:focus\:tw-to-indigo-400:focus{
    --gradient-to-color: #7f9cf5
  }

  .md\:focus\:tw-to-indigo-500:focus{
    --gradient-to-color: #667eea
  }

  .md\:focus\:tw-to-indigo-600:focus{
    --gradient-to-color: #5a67d8
  }

  .md\:focus\:tw-to-indigo-700:focus{
    --gradient-to-color: #4c51bf
  }

  .md\:focus\:tw-to-indigo-800:focus{
    --gradient-to-color: #434190
  }

  .md\:focus\:tw-to-indigo-900:focus{
    --gradient-to-color: #3c366b
  }

  .md\:focus\:tw-to-purple-100:focus{
    --gradient-to-color: #faf5ff
  }

  .md\:focus\:tw-to-purple-200:focus{
    --gradient-to-color: #e9d8fd
  }

  .md\:focus\:tw-to-purple-300:focus{
    --gradient-to-color: #d6bcfa
  }

  .md\:focus\:tw-to-purple-400:focus{
    --gradient-to-color: #b794f4
  }

  .md\:focus\:tw-to-purple-500:focus{
    --gradient-to-color: #9f7aea
  }

  .md\:focus\:tw-to-purple-600:focus{
    --gradient-to-color: #805ad5
  }

  .md\:focus\:tw-to-purple-700:focus{
    --gradient-to-color: #6b46c1
  }

  .md\:focus\:tw-to-purple-800:focus{
    --gradient-to-color: #553c9a
  }

  .md\:focus\:tw-to-purple-900:focus{
    --gradient-to-color: #44337a
  }

  .md\:focus\:tw-to-pink-100:focus{
    --gradient-to-color: #fff5f7
  }

  .md\:focus\:tw-to-pink-200:focus{
    --gradient-to-color: #fed7e2
  }

  .md\:focus\:tw-to-pink-300:focus{
    --gradient-to-color: #fbb6ce
  }

  .md\:focus\:tw-to-pink-400:focus{
    --gradient-to-color: #f687b3
  }

  .md\:focus\:tw-to-pink-500:focus{
    --gradient-to-color: #ed64a6
  }

  .md\:focus\:tw-to-pink-600:focus{
    --gradient-to-color: #d53f8c
  }

  .md\:focus\:tw-to-pink-700:focus{
    --gradient-to-color: #b83280
  }

  .md\:focus\:tw-to-pink-800:focus{
    --gradient-to-color: #97266d
  }

  .md\:focus\:tw-to-pink-900:focus{
    --gradient-to-color: #702459
  }

  .md\:tw-bg-opacity-0{
    --bg-opacity: 0
  }

  .md\:tw-bg-opacity-25{
    --bg-opacity: 0.25
  }

  .md\:tw-bg-opacity-50{
    --bg-opacity: 0.5
  }

  .md\:tw-bg-opacity-75{
    --bg-opacity: 0.75
  }

  .md\:tw-bg-opacity-100{
    --bg-opacity: 1
  }

  .md\:hover\:tw-bg-opacity-0:hover{
    --bg-opacity: 0
  }

  .md\:hover\:tw-bg-opacity-25:hover{
    --bg-opacity: 0.25
  }

  .md\:hover\:tw-bg-opacity-50:hover{
    --bg-opacity: 0.5
  }

  .md\:hover\:tw-bg-opacity-75:hover{
    --bg-opacity: 0.75
  }

  .md\:hover\:tw-bg-opacity-100:hover{
    --bg-opacity: 1
  }

  .md\:focus\:tw-bg-opacity-0:focus{
    --bg-opacity: 0
  }

  .md\:focus\:tw-bg-opacity-25:focus{
    --bg-opacity: 0.25
  }

  .md\:focus\:tw-bg-opacity-50:focus{
    --bg-opacity: 0.5
  }

  .md\:focus\:tw-bg-opacity-75:focus{
    --bg-opacity: 0.75
  }

  .md\:focus\:tw-bg-opacity-100:focus{
    --bg-opacity: 1
  }

  .md\:tw-bg-bottom{
    background-position: bottom
  }

  .md\:tw-bg-center{
    background-position: center
  }

  .md\:tw-bg-left{
    background-position: left
  }

  .md\:tw-bg-left-bottom{
    background-position: left bottom
  }

  .md\:tw-bg-left-top{
    background-position: left top
  }

  .md\:tw-bg-right{
    background-position: right
  }

  .md\:tw-bg-right-bottom{
    background-position: right bottom
  }

  .md\:tw-bg-right-top{
    background-position: right top
  }

  .md\:tw-bg-top{
    background-position: top
  }

  .md\:tw-bg-repeat{
    background-repeat: repeat
  }

  .md\:tw-bg-no-repeat{
    background-repeat: no-repeat
  }

  .md\:tw-bg-repeat-x{
    background-repeat: repeat-x
  }

  .md\:tw-bg-repeat-y{
    background-repeat: repeat-y
  }

  .md\:tw-bg-repeat-round{
    background-repeat: round
  }

  .md\:tw-bg-repeat-space{
    background-repeat: space
  }

  .md\:tw-bg-auto{
    background-size: auto
  }

  .md\:tw-bg-cover{
    background-size: cover
  }

  .md\:tw-bg-contain{
    background-size: contain
  }

  .md\:tw-border-collapse{
    border-collapse: collapse
  }

  .md\:tw-border-separate{
    border-collapse: separate
  }

  .md\:tw-border-transparent{
    border-color: transparent
  }

  .md\:tw-border-tlg-green-dark{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .md\:tw-border-tlg-green{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .md\:tw-border-tlg-orange-500{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .md\:tw-border-black{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .md\:tw-border-white{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .md\:tw-border-gray-100{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .md\:tw-border-gray-200{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .md\:tw-border-gray-300{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .md\:tw-border-gray-400{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .md\:tw-border-gray-500{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .md\:tw-border-gray-600{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .md\:tw-border-gray-700{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .md\:tw-border-gray-800{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .md\:tw-border-gray-900{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .md\:tw-border-red-100{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .md\:tw-border-red-200{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .md\:tw-border-red-300{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .md\:tw-border-red-400{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .md\:tw-border-red-500{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .md\:tw-border-red-600{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .md\:tw-border-red-700{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .md\:tw-border-red-800{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .md\:tw-border-red-900{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .md\:tw-border-orange-100{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .md\:tw-border-orange-200{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .md\:tw-border-orange-300{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .md\:tw-border-orange-400{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .md\:tw-border-orange-500{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .md\:tw-border-orange-600{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .md\:tw-border-orange-700{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .md\:tw-border-orange-800{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .md\:tw-border-orange-900{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .md\:tw-border-yellow-100{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .md\:tw-border-yellow-200{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .md\:tw-border-yellow-300{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .md\:tw-border-yellow-400{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .md\:tw-border-yellow-500{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .md\:tw-border-yellow-600{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .md\:tw-border-yellow-700{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .md\:tw-border-yellow-800{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .md\:tw-border-yellow-900{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .md\:tw-border-green-100{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .md\:tw-border-green-200{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .md\:tw-border-green-300{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .md\:tw-border-green-400{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .md\:tw-border-green-500{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .md\:tw-border-green-600{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .md\:tw-border-green-700{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .md\:tw-border-green-800{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .md\:tw-border-green-900{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .md\:tw-border-teal-100{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .md\:tw-border-teal-200{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .md\:tw-border-teal-300{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .md\:tw-border-teal-400{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .md\:tw-border-teal-500{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .md\:tw-border-teal-600{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .md\:tw-border-teal-700{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .md\:tw-border-teal-800{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .md\:tw-border-teal-900{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .md\:tw-border-blue-100{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .md\:tw-border-blue-200{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .md\:tw-border-blue-300{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .md\:tw-border-blue-400{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .md\:tw-border-blue-500{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .md\:tw-border-blue-600{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .md\:tw-border-blue-700{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .md\:tw-border-blue-800{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .md\:tw-border-blue-900{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .md\:tw-border-indigo-100{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .md\:tw-border-indigo-200{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .md\:tw-border-indigo-300{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .md\:tw-border-indigo-400{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .md\:tw-border-indigo-500{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .md\:tw-border-indigo-600{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .md\:tw-border-indigo-700{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .md\:tw-border-indigo-800{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .md\:tw-border-indigo-900{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .md\:tw-border-purple-100{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .md\:tw-border-purple-200{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .md\:tw-border-purple-300{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .md\:tw-border-purple-400{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .md\:tw-border-purple-500{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .md\:tw-border-purple-600{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .md\:tw-border-purple-700{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .md\:tw-border-purple-800{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .md\:tw-border-purple-900{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .md\:tw-border-pink-100{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .md\:tw-border-pink-200{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .md\:tw-border-pink-300{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .md\:tw-border-pink-400{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .md\:tw-border-pink-500{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .md\:tw-border-pink-600{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .md\:tw-border-pink-700{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .md\:tw-border-pink-800{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .md\:tw-border-pink-900{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .md\:hover\:tw-border-transparent:hover{
    border-color: transparent
  }

  .md\:hover\:tw-border-tlg-green-dark:hover{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .md\:hover\:tw-border-tlg-green:hover{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .md\:hover\:tw-border-tlg-orange-500:hover{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .md\:hover\:tw-border-black:hover{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .md\:hover\:tw-border-white:hover{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-100:hover{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-200:hover{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-300:hover{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-400:hover{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-500:hover{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-600:hover{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-700:hover{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-800:hover{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .md\:hover\:tw-border-gray-900:hover{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-100:hover{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-200:hover{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-300:hover{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-400:hover{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-500:hover{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-600:hover{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-700:hover{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-800:hover{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .md\:hover\:tw-border-red-900:hover{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-100:hover{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-200:hover{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-300:hover{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-400:hover{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-500:hover{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-600:hover{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-700:hover{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-800:hover{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .md\:hover\:tw-border-orange-900:hover{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-100:hover{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-200:hover{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-300:hover{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-400:hover{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-500:hover{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-600:hover{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-700:hover{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-800:hover{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .md\:hover\:tw-border-yellow-900:hover{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-100:hover{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-200:hover{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-300:hover{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-400:hover{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-500:hover{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-600:hover{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-700:hover{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-800:hover{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .md\:hover\:tw-border-green-900:hover{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-100:hover{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-200:hover{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-300:hover{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-400:hover{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-500:hover{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-600:hover{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-700:hover{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-800:hover{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .md\:hover\:tw-border-teal-900:hover{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-100:hover{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-200:hover{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-300:hover{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-400:hover{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-500:hover{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-600:hover{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-700:hover{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-800:hover{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .md\:hover\:tw-border-blue-900:hover{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-100:hover{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-200:hover{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-300:hover{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-400:hover{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-500:hover{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-600:hover{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-700:hover{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-800:hover{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .md\:hover\:tw-border-indigo-900:hover{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-100:hover{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-200:hover{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-300:hover{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-400:hover{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-500:hover{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-600:hover{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-700:hover{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-800:hover{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .md\:hover\:tw-border-purple-900:hover{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-100:hover{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-200:hover{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-300:hover{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-400:hover{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-500:hover{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-600:hover{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-700:hover{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-800:hover{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .md\:hover\:tw-border-pink-900:hover{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .md\:focus\:tw-border-transparent:focus{
    border-color: transparent
  }

  .md\:focus\:tw-border-tlg-green-dark:focus{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .md\:focus\:tw-border-tlg-green:focus{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .md\:focus\:tw-border-tlg-orange-500:focus{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .md\:focus\:tw-border-black:focus{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .md\:focus\:tw-border-white:focus{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-100:focus{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-200:focus{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-300:focus{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-400:focus{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-500:focus{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-600:focus{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-700:focus{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-800:focus{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .md\:focus\:tw-border-gray-900:focus{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-100:focus{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-200:focus{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-300:focus{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-400:focus{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-500:focus{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-600:focus{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-700:focus{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-800:focus{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .md\:focus\:tw-border-red-900:focus{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-100:focus{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-200:focus{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-300:focus{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-400:focus{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-500:focus{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-600:focus{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-700:focus{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-800:focus{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .md\:focus\:tw-border-orange-900:focus{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-100:focus{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-200:focus{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-300:focus{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-400:focus{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-500:focus{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-600:focus{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-700:focus{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-800:focus{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .md\:focus\:tw-border-yellow-900:focus{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-100:focus{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-200:focus{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-300:focus{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-400:focus{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-500:focus{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-600:focus{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-700:focus{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-800:focus{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .md\:focus\:tw-border-green-900:focus{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-100:focus{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-200:focus{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-300:focus{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-400:focus{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-500:focus{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-600:focus{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-700:focus{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-800:focus{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .md\:focus\:tw-border-teal-900:focus{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-100:focus{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-200:focus{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-300:focus{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-400:focus{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-500:focus{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-600:focus{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-700:focus{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-800:focus{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .md\:focus\:tw-border-blue-900:focus{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-100:focus{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-200:focus{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-300:focus{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-400:focus{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-500:focus{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-600:focus{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-700:focus{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-800:focus{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .md\:focus\:tw-border-indigo-900:focus{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-100:focus{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-200:focus{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-300:focus{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-400:focus{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-500:focus{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-600:focus{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-700:focus{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-800:focus{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .md\:focus\:tw-border-purple-900:focus{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-100:focus{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-200:focus{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-300:focus{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-400:focus{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-500:focus{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-600:focus{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-700:focus{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-800:focus{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .md\:focus\:tw-border-pink-900:focus{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .md\:tw-border-opacity-0{
    --border-opacity: 0
  }

  .md\:tw-border-opacity-25{
    --border-opacity: 0.25
  }

  .md\:tw-border-opacity-50{
    --border-opacity: 0.5
  }

  .md\:tw-border-opacity-75{
    --border-opacity: 0.75
  }

  .md\:tw-border-opacity-100{
    --border-opacity: 1
  }

  .md\:hover\:tw-border-opacity-0:hover{
    --border-opacity: 0
  }

  .md\:hover\:tw-border-opacity-25:hover{
    --border-opacity: 0.25
  }

  .md\:hover\:tw-border-opacity-50:hover{
    --border-opacity: 0.5
  }

  .md\:hover\:tw-border-opacity-75:hover{
    --border-opacity: 0.75
  }

  .md\:hover\:tw-border-opacity-100:hover{
    --border-opacity: 1
  }

  .md\:focus\:tw-border-opacity-0:focus{
    --border-opacity: 0
  }

  .md\:focus\:tw-border-opacity-25:focus{
    --border-opacity: 0.25
  }

  .md\:focus\:tw-border-opacity-50:focus{
    --border-opacity: 0.5
  }

  .md\:focus\:tw-border-opacity-75:focus{
    --border-opacity: 0.75
  }

  .md\:focus\:tw-border-opacity-100:focus{
    --border-opacity: 1
  }

  .md\:tw-rounded-none{
    border-radius: 0
  }

  .md\:tw-rounded-sm{
    border-radius: 0.125rem
  }

  .md\:tw-rounded{
    border-radius: 0.25rem
  }

  .md\:tw-rounded-lg{
    border-radius: 0.5rem
  }

  .md\:tw-rounded-full{
    border-radius: 9999px
  }

  .md\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .md\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .md\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .md\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .md\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .md\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .md\:tw-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .md\:tw-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .md\:tw-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .md\:tw-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .md\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .md\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .md\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .md\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .md\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .md\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .md\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:tw-rounded-tl-none{
    border-top-left-radius: 0
  }

  .md\:tw-rounded-tr-none{
    border-top-right-radius: 0
  }

  .md\:tw-rounded-br-none{
    border-bottom-right-radius: 0
  }

  .md\:tw-rounded-bl-none{
    border-bottom-left-radius: 0
  }

  .md\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  .md\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  .md\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  .md\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  .md\:tw-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  .md\:tw-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  .md\:tw-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  .md\:tw-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  .md\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  .md\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  .md\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  .md\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  .md\:tw-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  .md\:tw-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  .md\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  .md\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  .md\:tw-border-solid{
    border-style: solid
  }

  .md\:tw-border-dashed{
    border-style: dashed
  }

  .md\:tw-border-dotted{
    border-style: dotted
  }

  .md\:tw-border-double{
    border-style: double
  }

  .md\:tw-border-none{
    border-style: none
  }

  .md\:tw-border-0{
    border-width: 0
  }

  .md\:tw-border-2{
    border-width: 2px
  }

  .md\:tw-border-4{
    border-width: 4px
  }

  .md\:tw-border-8{
    border-width: 8px
  }

  .md\:tw-border{
    border-width: 1px
  }

  .md\:tw-border-t-0{
    border-top-width: 0
  }

  .md\:tw-border-r-0{
    border-right-width: 0
  }

  .md\:tw-border-b-0{
    border-bottom-width: 0
  }

  .md\:tw-border-l-0{
    border-left-width: 0
  }

  .md\:tw-border-t-2{
    border-top-width: 2px
  }

  .md\:tw-border-r-2{
    border-right-width: 2px
  }

  .md\:tw-border-b-2{
    border-bottom-width: 2px
  }

  .md\:tw-border-l-2{
    border-left-width: 2px
  }

  .md\:tw-border-t-4{
    border-top-width: 4px
  }

  .md\:tw-border-r-4{
    border-right-width: 4px
  }

  .md\:tw-border-b-4{
    border-bottom-width: 4px
  }

  .md\:tw-border-l-4{
    border-left-width: 4px
  }

  .md\:tw-border-t-8{
    border-top-width: 8px
  }

  .md\:tw-border-r-8{
    border-right-width: 8px
  }

  .md\:tw-border-b-8{
    border-bottom-width: 8px
  }

  .md\:tw-border-l-8{
    border-left-width: 8px
  }

  .md\:tw-border-t{
    border-top-width: 1px
  }

  .md\:tw-border-r{
    border-right-width: 1px
  }

  .md\:tw-border-b{
    border-bottom-width: 1px
  }

  .md\:tw-border-l{
    border-left-width: 1px
  }

  .md\:tw-box-border{
    box-sizing: border-box
  }

  .md\:tw-box-content{
    box-sizing: content-box
  }

  .md\:tw-cursor-auto{
    cursor: auto
  }

  .md\:tw-cursor-default{
    cursor: default
  }

  .md\:tw-cursor-pointer{
    cursor: pointer
  }

  .md\:tw-cursor-wait{
    cursor: wait
  }

  .md\:tw-cursor-text{
    cursor: text
  }

  .md\:tw-cursor-move{
    cursor: move
  }

  .md\:tw-cursor-not-allowed{
    cursor: not-allowed
  }

  .md\:tw-block{
    display: block
  }

  .md\:tw-inline-block{
    display: inline-block
  }

  .md\:tw-inline{
    display: inline
  }

  .md\:tw-flex{
    display: flex
  }

  .md\:tw-inline-flex{
    display: inline-flex
  }

  .md\:tw-table{
    display: table
  }

  .md\:tw-table-caption{
    display: table-caption
  }

  .md\:tw-table-cell{
    display: table-cell
  }

  .md\:tw-table-column{
    display: table-column
  }

  .md\:tw-table-column-group{
    display: table-column-group
  }

  .md\:tw-table-footer-group{
    display: table-footer-group
  }

  .md\:tw-table-header-group{
    display: table-header-group
  }

  .md\:tw-table-row-group{
    display: table-row-group
  }

  .md\:tw-table-row{
    display: table-row
  }

  .md\:tw-flow-root{
    display: flow-root
  }

  .md\:tw-grid{
    display: grid
  }

  .md\:tw-inline-grid{
    display: inline-grid
  }

  .md\:tw-contents{
    display: contents
  }

  .md\:tw-hidden{
    display: none
  }

  .md\:tw-flex-row{
    flex-direction: row
  }

  .md\:tw-flex-row-reverse{
    flex-direction: row-reverse
  }

  .md\:tw-flex-col{
    flex-direction: column
  }

  .md\:tw-flex-col-reverse{
    flex-direction: column-reverse
  }

  .md\:tw-flex-wrap{
    flex-wrap: wrap
  }

  .md\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  .md\:tw-flex-no-wrap{
    flex-wrap: nowrap
  }

  .md\:tw-place-items-auto{
    place-items: auto
  }

  .md\:tw-place-items-start{
    place-items: start
  }

  .md\:tw-place-items-end{
    place-items: end
  }

  .md\:tw-place-items-center{
    place-items: center
  }

  .md\:tw-place-items-stretch{
    place-items: stretch
  }

  .md\:tw-place-content-center{
    place-content: center
  }

  .md\:tw-place-content-start{
    place-content: start
  }

  .md\:tw-place-content-end{
    place-content: end
  }

  .md\:tw-place-content-between{
    place-content: space-between
  }

  .md\:tw-place-content-around{
    place-content: space-around
  }

  .md\:tw-place-content-evenly{
    place-content: space-evenly
  }

  .md\:tw-place-content-stretch{
    place-content: stretch
  }

  .md\:tw-place-self-auto{
    place-self: auto
  }

  .md\:tw-place-self-start{
    place-self: start
  }

  .md\:tw-place-self-end{
    place-self: end
  }

  .md\:tw-place-self-center{
    place-self: center
  }

  .md\:tw-place-self-stretch{
    place-self: stretch
  }

  .md\:tw-items-start{
    align-items: flex-start
  }

  .md\:tw-items-end{
    align-items: flex-end
  }

  .md\:tw-items-center{
    align-items: center
  }

  .md\:tw-items-baseline{
    align-items: baseline
  }

  .md\:tw-items-stretch{
    align-items: stretch
  }

  .md\:tw-content-center{
    align-content: center
  }

  .md\:tw-content-start{
    align-content: flex-start
  }

  .md\:tw-content-end{
    align-content: flex-end
  }

  .md\:tw-content-between{
    align-content: space-between
  }

  .md\:tw-content-around{
    align-content: space-around
  }

  .md\:tw-content-evenly{
    align-content: space-evenly
  }

  .md\:tw-self-auto{
    align-self: auto
  }

  .md\:tw-self-start{
    align-self: flex-start
  }

  .md\:tw-self-end{
    align-self: flex-end
  }

  .md\:tw-self-center{
    align-self: center
  }

  .md\:tw-self-stretch{
    align-self: stretch
  }

  .md\:tw-justify-items-auto{
    justify-items: auto
  }

  .md\:tw-justify-items-start{
    justify-items: start
  }

  .md\:tw-justify-items-end{
    justify-items: end
  }

  .md\:tw-justify-items-center{
    justify-items: center
  }

  .md\:tw-justify-items-stretch{
    justify-items: stretch
  }

  .md\:tw-justify-start{
    justify-content: flex-start
  }

  .md\:tw-justify-end{
    justify-content: flex-end
  }

  .md\:tw-justify-center{
    justify-content: center
  }

  .md\:tw-justify-between{
    justify-content: space-between
  }

  .md\:tw-justify-around{
    justify-content: space-around
  }

  .md\:tw-justify-evenly{
    justify-content: space-evenly
  }

  .md\:tw-justify-self-auto{
    justify-self: auto
  }

  .md\:tw-justify-self-start{
    justify-self: start
  }

  .md\:tw-justify-self-end{
    justify-self: end
  }

  .md\:tw-justify-self-center{
    justify-self: center
  }

  .md\:tw-justify-self-stretch{
    justify-self: stretch
  }

  .md\:tw-flex-1{
    flex: 1 1
  }

  .md\:tw-flex-auto{
    flex: 1 1 auto
  }

  .md\:tw-flex-initial{
    flex: 0 1 auto
  }

  .md\:tw-flex-none{
    flex: none
  }

  .md\:tw-flex-grow-0{
    flex-grow: 0
  }

  .md\:tw-flex-grow{
    flex-grow: 1
  }

  .md\:tw-flex-shrink-0{
    flex-shrink: 0
  }

  .md\:tw-flex-shrink{
    flex-shrink: 1
  }

  .md\:tw-order-1{
    order: 1
  }

  .md\:tw-order-2{
    order: 2
  }

  .md\:tw-order-3{
    order: 3
  }

  .md\:tw-order-4{
    order: 4
  }

  .md\:tw-order-5{
    order: 5
  }

  .md\:tw-order-6{
    order: 6
  }

  .md\:tw-order-7{
    order: 7
  }

  .md\:tw-order-8{
    order: 8
  }

  .md\:tw-order-9{
    order: 9
  }

  .md\:tw-order-10{
    order: 10
  }

  .md\:tw-order-11{
    order: 11
  }

  .md\:tw-order-12{
    order: 12
  }

  .md\:tw-order-first{
    order: -9999
  }

  .md\:tw-order-last{
    order: 9999
  }

  .md\:tw-order-none{
    order: 0
  }

  .md\:tw-float-right{
    float: right
  }

  .md\:tw-float-left{
    float: left
  }

  .md\:tw-float-none{
    float: none
  }

  .md\:tw-clearfix:after{
    content: "";
    display: table;
    clear: both
  }

  .md\:tw-clear-left{
    clear: left
  }

  .md\:tw-clear-right{
    clear: right
  }

  .md\:tw-clear-both{
    clear: both
  }

  .md\:tw-clear-none{
    clear: none
  }

  .md\:tw-font-sans{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .md\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .md\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .md\:tw-font-hairline{
    font-weight: 100
  }

  .md\:tw-font-thin{
    font-weight: 200
  }

  .md\:tw-font-light{
    font-weight: 300
  }

  .md\:tw-font-normal{
    font-weight: 400
  }

  .md\:tw-font-medium{
    font-weight: 500
  }

  .md\:tw-font-semibold{
    font-weight: 600
  }

  .md\:tw-font-bold{
    font-weight: 700
  }

  .md\:tw-font-extrabold{
    font-weight: 800
  }

  .md\:tw-font-black{
    font-weight: 900
  }

  .md\:hover\:tw-font-hairline:hover{
    font-weight: 100
  }

  .md\:hover\:tw-font-thin:hover{
    font-weight: 200
  }

  .md\:hover\:tw-font-light:hover{
    font-weight: 300
  }

  .md\:hover\:tw-font-normal:hover{
    font-weight: 400
  }

  .md\:hover\:tw-font-medium:hover{
    font-weight: 500
  }

  .md\:hover\:tw-font-semibold:hover{
    font-weight: 600
  }

  .md\:hover\:tw-font-bold:hover{
    font-weight: 700
  }

  .md\:hover\:tw-font-extrabold:hover{
    font-weight: 800
  }

  .md\:hover\:tw-font-black:hover{
    font-weight: 900
  }

  .md\:focus\:tw-font-hairline:focus{
    font-weight: 100
  }

  .md\:focus\:tw-font-thin:focus{
    font-weight: 200
  }

  .md\:focus\:tw-font-light:focus{
    font-weight: 300
  }

  .md\:focus\:tw-font-normal:focus{
    font-weight: 400
  }

  .md\:focus\:tw-font-medium:focus{
    font-weight: 500
  }

  .md\:focus\:tw-font-semibold:focus{
    font-weight: 600
  }

  .md\:focus\:tw-font-bold:focus{
    font-weight: 700
  }

  .md\:focus\:tw-font-extrabold:focus{
    font-weight: 800
  }

  .md\:focus\:tw-font-black:focus{
    font-weight: 900
  }

  .md\:tw-h-0{
    height: 0
  }

  .md\:tw-h-1{
    height: 0.25rem
  }

  .md\:tw-h-2{
    height: 0.5rem
  }

  .md\:tw-h-3{
    height: 0.75rem
  }

  .md\:tw-h-4{
    height: 1rem
  }

  .md\:tw-h-5{
    height: 1.25rem
  }

  .md\:tw-h-6{
    height: 1.5rem
  }

  .md\:tw-h-8{
    height: 2rem
  }

  .md\:tw-h-10{
    height: 2.5rem
  }

  .md\:tw-h-12{
    height: 3rem
  }

  .md\:tw-h-16{
    height: 4rem
  }

  .md\:tw-h-20{
    height: 5rem
  }

  .md\:tw-h-24{
    height: 6rem
  }

  .md\:tw-h-32{
    height: 8rem
  }

  .md\:tw-h-40{
    height: 10rem
  }

  .md\:tw-h-48{
    height: 12rem
  }

  .md\:tw-h-56{
    height: 14rem
  }

  .md\:tw-h-64{
    height: 16rem
  }

  .md\:tw-h-auto{
    height: auto
  }

  .md\:tw-h-px{
    height: 1px
  }

  .md\:tw-h-full{
    height: 100%
  }

  .md\:tw-h-screen{
    height: 100vh
  }

  .md\:tw-text-xs{
    font-size: 0.75rem
  }

  .md\:tw-text-sm{
    font-size: 0.875rem
  }

  .md\:tw-text-base{
    font-size: 1rem
  }

  .md\:tw-text-lg{
    font-size: 1.125rem
  }

  .md\:tw-text-xl{
    font-size: 1.25rem
  }

  .md\:tw-text-2xl{
    font-size: 1.5rem
  }

  .md\:tw-text-3xl{
    font-size: 1.875rem
  }

  .md\:tw-text-4xl{
    font-size: 2.25rem
  }

  .md\:tw-text-5xl{
    font-size: 3rem
  }

  .md\:tw-text-6xl{
    font-size: 4rem
  }

  .md\:tw-leading-none{
    line-height: 1
  }

  .md\:tw-leading-tight{
    line-height: 1.25
  }

  .md\:tw-leading-snug{
    line-height: 1.375
  }

  .md\:tw-leading-normal{
    line-height: 1.5
  }

  .md\:tw-leading-relaxed{
    line-height: 1.625
  }

  .md\:tw-leading-loose{
    line-height: 2
  }

  .md\:tw-list-inside{
    list-style-position: inside
  }

  .md\:tw-list-outside{
    list-style-position: outside
  }

  .md\:tw-list-none{
    list-style-type: none
  }

  .md\:tw-list-disc{
    list-style-type: disc
  }

  .md\:tw-list-decimal{
    list-style-type: decimal
  }

  .md\:tw-m-0{
    margin: 0
  }

  .md\:tw-m-1{
    margin: 0.25rem
  }

  .md\:tw-m-2{
    margin: 0.5rem
  }

  .md\:tw-m-3{
    margin: 0.75rem
  }

  .md\:tw-m-4{
    margin: 1rem
  }

  .md\:tw-m-5{
    margin: 1.25rem
  }

  .md\:tw-m-6{
    margin: 1.5rem
  }

  .md\:tw-m-8{
    margin: 2rem
  }

  .md\:tw-m-10{
    margin: 2.5rem
  }

  .md\:tw-m-12{
    margin: 3rem
  }

  .md\:tw-m-16{
    margin: 4rem
  }

  .md\:tw-m-20{
    margin: 5rem
  }

  .md\:tw-m-24{
    margin: 6rem
  }

  .md\:tw-m-32{
    margin: 8rem
  }

  .md\:tw-m-40{
    margin: 10rem
  }

  .md\:tw-m-48{
    margin: 12rem
  }

  .md\:tw-m-56{
    margin: 14rem
  }

  .md\:tw-m-64{
    margin: 16rem
  }

  .md\:tw-m-auto{
    margin: auto
  }

  .md\:tw-m-px{
    margin: 1px
  }

  .md\:tw--m-1{
    margin: -0.25rem
  }

  .md\:tw--m-2{
    margin: -0.5rem
  }

  .md\:tw--m-3{
    margin: -0.75rem
  }

  .md\:tw--m-4{
    margin: -1rem
  }

  .md\:tw--m-5{
    margin: -1.25rem
  }

  .md\:tw--m-6{
    margin: -1.5rem
  }

  .md\:tw--m-8{
    margin: -2rem
  }

  .md\:tw--m-10{
    margin: -2.5rem
  }

  .md\:tw--m-12{
    margin: -3rem
  }

  .md\:tw--m-16{
    margin: -4rem
  }

  .md\:tw--m-20{
    margin: -5rem
  }

  .md\:tw--m-24{
    margin: -6rem
  }

  .md\:tw--m-32{
    margin: -8rem
  }

  .md\:tw--m-40{
    margin: -10rem
  }

  .md\:tw--m-48{
    margin: -12rem
  }

  .md\:tw--m-56{
    margin: -14rem
  }

  .md\:tw--m-64{
    margin: -16rem
  }

  .md\:tw--m-px{
    margin: -1px
  }

  .md\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:tw-mx-0{
    margin-left: 0;
    margin-right: 0
  }

  .md\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .md\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .md\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .md\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .md\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .md\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .md\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .md\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  .md\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .md\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .md\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .md\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .md\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  .md\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .md\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .md\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .md\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  .md\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  .md\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .md\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  .md\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .md\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  .md\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .md\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  .md\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .md\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  .md\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .md\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  .md\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .md\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  .md\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .md\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  .md\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  .md\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  .md\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  .md\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  .md\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .md\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .md\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .md\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .md\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .md\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .md\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .md\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  .md\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .md\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .md\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .md\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .md\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .md\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  .md\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .md\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .md\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .md\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  .md\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .md\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  .md\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .md\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  .md\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .md\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  .md\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .md\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  .md\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .md\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  .md\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .md\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  .md\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .md\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  .md\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .md\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  .md\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  .md\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  .md\:tw-mt-0{
    margin-top: 0
  }

  .md\:tw-mr-0{
    margin-right: 0
  }

  .md\:tw-mb-0{
    margin-bottom: 0
  }

  .md\:tw-ml-0{
    margin-left: 0
  }

  .md\:tw-mt-1{
    margin-top: 0.25rem
  }

  .md\:tw-mr-1{
    margin-right: 0.25rem
  }

  .md\:tw-mb-1{
    margin-bottom: 0.25rem
  }

  .md\:tw-ml-1{
    margin-left: 0.25rem
  }

  .md\:tw-mt-2{
    margin-top: 0.5rem
  }

  .md\:tw-mr-2{
    margin-right: 0.5rem
  }

  .md\:tw-mb-2{
    margin-bottom: 0.5rem
  }

  .md\:tw-ml-2{
    margin-left: 0.5rem
  }

  .md\:tw-mt-3{
    margin-top: 0.75rem
  }

  .md\:tw-mr-3{
    margin-right: 0.75rem
  }

  .md\:tw-mb-3{
    margin-bottom: 0.75rem
  }

  .md\:tw-ml-3{
    margin-left: 0.75rem
  }

  .md\:tw-mt-4{
    margin-top: 1rem
  }

  .md\:tw-mr-4{
    margin-right: 1rem
  }

  .md\:tw-mb-4{
    margin-bottom: 1rem
  }

  .md\:tw-ml-4{
    margin-left: 1rem
  }

  .md\:tw-mt-5{
    margin-top: 1.25rem
  }

  .md\:tw-mr-5{
    margin-right: 1.25rem
  }

  .md\:tw-mb-5{
    margin-bottom: 1.25rem
  }

  .md\:tw-ml-5{
    margin-left: 1.25rem
  }

  .md\:tw-mt-6{
    margin-top: 1.5rem
  }

  .md\:tw-mr-6{
    margin-right: 1.5rem
  }

  .md\:tw-mb-6{
    margin-bottom: 1.5rem
  }

  .md\:tw-ml-6{
    margin-left: 1.5rem
  }

  .md\:tw-mt-8{
    margin-top: 2rem
  }

  .md\:tw-mr-8{
    margin-right: 2rem
  }

  .md\:tw-mb-8{
    margin-bottom: 2rem
  }

  .md\:tw-ml-8{
    margin-left: 2rem
  }

  .md\:tw-mt-10{
    margin-top: 2.5rem
  }

  .md\:tw-mr-10{
    margin-right: 2.5rem
  }

  .md\:tw-mb-10{
    margin-bottom: 2.5rem
  }

  .md\:tw-ml-10{
    margin-left: 2.5rem
  }

  .md\:tw-mt-12{
    margin-top: 3rem
  }

  .md\:tw-mr-12{
    margin-right: 3rem
  }

  .md\:tw-mb-12{
    margin-bottom: 3rem
  }

  .md\:tw-ml-12{
    margin-left: 3rem
  }

  .md\:tw-mt-16{
    margin-top: 4rem
  }

  .md\:tw-mr-16{
    margin-right: 4rem
  }

  .md\:tw-mb-16{
    margin-bottom: 4rem
  }

  .md\:tw-ml-16{
    margin-left: 4rem
  }

  .md\:tw-mt-20{
    margin-top: 5rem
  }

  .md\:tw-mr-20{
    margin-right: 5rem
  }

  .md\:tw-mb-20{
    margin-bottom: 5rem
  }

  .md\:tw-ml-20{
    margin-left: 5rem
  }

  .md\:tw-mt-24{
    margin-top: 6rem
  }

  .md\:tw-mr-24{
    margin-right: 6rem
  }

  .md\:tw-mb-24{
    margin-bottom: 6rem
  }

  .md\:tw-ml-24{
    margin-left: 6rem
  }

  .md\:tw-mt-32{
    margin-top: 8rem
  }

  .md\:tw-mr-32{
    margin-right: 8rem
  }

  .md\:tw-mb-32{
    margin-bottom: 8rem
  }

  .md\:tw-ml-32{
    margin-left: 8rem
  }

  .md\:tw-mt-40{
    margin-top: 10rem
  }

  .md\:tw-mr-40{
    margin-right: 10rem
  }

  .md\:tw-mb-40{
    margin-bottom: 10rem
  }

  .md\:tw-ml-40{
    margin-left: 10rem
  }

  .md\:tw-mt-48{
    margin-top: 12rem
  }

  .md\:tw-mr-48{
    margin-right: 12rem
  }

  .md\:tw-mb-48{
    margin-bottom: 12rem
  }

  .md\:tw-ml-48{
    margin-left: 12rem
  }

  .md\:tw-mt-56{
    margin-top: 14rem
  }

  .md\:tw-mr-56{
    margin-right: 14rem
  }

  .md\:tw-mb-56{
    margin-bottom: 14rem
  }

  .md\:tw-ml-56{
    margin-left: 14rem
  }

  .md\:tw-mt-64{
    margin-top: 16rem
  }

  .md\:tw-mr-64{
    margin-right: 16rem
  }

  .md\:tw-mb-64{
    margin-bottom: 16rem
  }

  .md\:tw-ml-64{
    margin-left: 16rem
  }

  .md\:tw-mt-auto{
    margin-top: auto
  }

  .md\:tw-mr-auto{
    margin-right: auto
  }

  .md\:tw-mb-auto{
    margin-bottom: auto
  }

  .md\:tw-ml-auto{
    margin-left: auto
  }

  .md\:tw-mt-px{
    margin-top: 1px
  }

  .md\:tw-mr-px{
    margin-right: 1px
  }

  .md\:tw-mb-px{
    margin-bottom: 1px
  }

  .md\:tw-ml-px{
    margin-left: 1px
  }

  .md\:tw--mt-1{
    margin-top: -0.25rem
  }

  .md\:tw--mr-1{
    margin-right: -0.25rem
  }

  .md\:tw--mb-1{
    margin-bottom: -0.25rem
  }

  .md\:tw--ml-1{
    margin-left: -0.25rem
  }

  .md\:tw--mt-2{
    margin-top: -0.5rem
  }

  .md\:tw--mr-2{
    margin-right: -0.5rem
  }

  .md\:tw--mb-2{
    margin-bottom: -0.5rem
  }

  .md\:tw--ml-2{
    margin-left: -0.5rem
  }

  .md\:tw--mt-3{
    margin-top: -0.75rem
  }

  .md\:tw--mr-3{
    margin-right: -0.75rem
  }

  .md\:tw--mb-3{
    margin-bottom: -0.75rem
  }

  .md\:tw--ml-3{
    margin-left: -0.75rem
  }

  .md\:tw--mt-4{
    margin-top: -1rem
  }

  .md\:tw--mr-4{
    margin-right: -1rem
  }

  .md\:tw--mb-4{
    margin-bottom: -1rem
  }

  .md\:tw--ml-4{
    margin-left: -1rem
  }

  .md\:tw--mt-5{
    margin-top: -1.25rem
  }

  .md\:tw--mr-5{
    margin-right: -1.25rem
  }

  .md\:tw--mb-5{
    margin-bottom: -1.25rem
  }

  .md\:tw--ml-5{
    margin-left: -1.25rem
  }

  .md\:tw--mt-6{
    margin-top: -1.5rem
  }

  .md\:tw--mr-6{
    margin-right: -1.5rem
  }

  .md\:tw--mb-6{
    margin-bottom: -1.5rem
  }

  .md\:tw--ml-6{
    margin-left: -1.5rem
  }

  .md\:tw--mt-8{
    margin-top: -2rem
  }

  .md\:tw--mr-8{
    margin-right: -2rem
  }

  .md\:tw--mb-8{
    margin-bottom: -2rem
  }

  .md\:tw--ml-8{
    margin-left: -2rem
  }

  .md\:tw--mt-10{
    margin-top: -2.5rem
  }

  .md\:tw--mr-10{
    margin-right: -2.5rem
  }

  .md\:tw--mb-10{
    margin-bottom: -2.5rem
  }

  .md\:tw--ml-10{
    margin-left: -2.5rem
  }

  .md\:tw--mt-12{
    margin-top: -3rem
  }

  .md\:tw--mr-12{
    margin-right: -3rem
  }

  .md\:tw--mb-12{
    margin-bottom: -3rem
  }

  .md\:tw--ml-12{
    margin-left: -3rem
  }

  .md\:tw--mt-16{
    margin-top: -4rem
  }

  .md\:tw--mr-16{
    margin-right: -4rem
  }

  .md\:tw--mb-16{
    margin-bottom: -4rem
  }

  .md\:tw--ml-16{
    margin-left: -4rem
  }

  .md\:tw--mt-20{
    margin-top: -5rem
  }

  .md\:tw--mr-20{
    margin-right: -5rem
  }

  .md\:tw--mb-20{
    margin-bottom: -5rem
  }

  .md\:tw--ml-20{
    margin-left: -5rem
  }

  .md\:tw--mt-24{
    margin-top: -6rem
  }

  .md\:tw--mr-24{
    margin-right: -6rem
  }

  .md\:tw--mb-24{
    margin-bottom: -6rem
  }

  .md\:tw--ml-24{
    margin-left: -6rem
  }

  .md\:tw--mt-32{
    margin-top: -8rem
  }

  .md\:tw--mr-32{
    margin-right: -8rem
  }

  .md\:tw--mb-32{
    margin-bottom: -8rem
  }

  .md\:tw--ml-32{
    margin-left: -8rem
  }

  .md\:tw--mt-40{
    margin-top: -10rem
  }

  .md\:tw--mr-40{
    margin-right: -10rem
  }

  .md\:tw--mb-40{
    margin-bottom: -10rem
  }

  .md\:tw--ml-40{
    margin-left: -10rem
  }

  .md\:tw--mt-48{
    margin-top: -12rem
  }

  .md\:tw--mr-48{
    margin-right: -12rem
  }

  .md\:tw--mb-48{
    margin-bottom: -12rem
  }

  .md\:tw--ml-48{
    margin-left: -12rem
  }

  .md\:tw--mt-56{
    margin-top: -14rem
  }

  .md\:tw--mr-56{
    margin-right: -14rem
  }

  .md\:tw--mb-56{
    margin-bottom: -14rem
  }

  .md\:tw--ml-56{
    margin-left: -14rem
  }

  .md\:tw--mt-64{
    margin-top: -16rem
  }

  .md\:tw--mr-64{
    margin-right: -16rem
  }

  .md\:tw--mb-64{
    margin-bottom: -16rem
  }

  .md\:tw--ml-64{
    margin-left: -16rem
  }

  .md\:tw--mt-px{
    margin-top: -1px
  }

  .md\:tw--mr-px{
    margin-right: -1px
  }

  .md\:tw--mb-px{
    margin-bottom: -1px
  }

  .md\:tw--ml-px{
    margin-left: -1px
  }

  .md\:tw-max-h-full{
    max-height: 100%
  }

  .md\:tw-max-h-screen{
    max-height: 100vh
  }

  .md\:tw-max-w-xs{
    max-width: 20rem
  }

  .md\:tw-max-w-sm{
    max-width: 24rem
  }

  .md\:tw-max-w-md{
    max-width: 28rem
  }

  .md\:tw-max-w-lg{
    max-width: 32rem
  }

  .md\:tw-max-w-xl{
    max-width: 36rem
  }

  .md\:tw-max-w-2xl{
    max-width: 42rem
  }

  .md\:tw-max-w-3xl{
    max-width: 48rem
  }

  .md\:tw-max-w-4xl{
    max-width: 56rem
  }

  .md\:tw-max-w-5xl{
    max-width: 64rem
  }

  .md\:tw-max-w-6xl{
    max-width: 72rem
  }

  .md\:tw-max-w-full{
    max-width: 100%
  }

  .md\:tw-min-h-0{
    min-height: 0
  }

  .md\:tw-min-h-full{
    min-height: 100%
  }

  .md\:tw-min-h-screen{
    min-height: 100vh
  }

  .md\:tw-min-w-0{
    min-width: 0
  }

  .md\:tw-min-w-full{
    min-width: 100%
  }

  .md\:tw-object-contain{
    object-fit: contain
  }

  .md\:tw-object-cover{
    object-fit: cover
  }

  .md\:tw-object-fill{
    object-fit: fill
  }

  .md\:tw-object-none{
    object-fit: none
  }

  .md\:tw-object-scale-down{
    object-fit: scale-down
  }

  .md\:tw-object-bottom{
    object-position: bottom
  }

  .md\:tw-object-center{
    object-position: center
  }

  .md\:tw-object-left{
    object-position: left
  }

  .md\:tw-object-left-bottom{
    object-position: left bottom
  }

  .md\:tw-object-left-top{
    object-position: left top
  }

  .md\:tw-object-right{
    object-position: right
  }

  .md\:tw-object-right-bottom{
    object-position: right bottom
  }

  .md\:tw-object-right-top{
    object-position: right top
  }

  .md\:tw-object-top{
    object-position: top
  }

  .md\:tw-opacity-0{
    opacity: 0
  }

  .md\:tw-opacity-25{
    opacity: 0.25
  }

  .md\:tw-opacity-50{
    opacity: 0.5
  }

  .md\:tw-opacity-75{
    opacity: 0.75
  }

  .md\:tw-opacity-100{
    opacity: 1
  }

  .md\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:tw-overflow-auto{
    overflow: auto
  }

  .md\:tw-overflow-hidden{
    overflow: hidden
  }

  .md\:tw-overflow-visible{
    overflow: visible
  }

  .md\:tw-overflow-scroll{
    overflow: scroll
  }

  .md\:tw-overflow-x-auto{
    overflow-x: auto
  }

  .md\:tw-overflow-y-auto{
    overflow-y: auto
  }

  .md\:tw-overflow-x-hidden{
    overflow-x: hidden
  }

  .md\:tw-overflow-y-hidden{
    overflow-y: hidden
  }

  .md\:tw-overflow-x-visible{
    overflow-x: visible
  }

  .md\:tw-overflow-y-visible{
    overflow-y: visible
  }

  .md\:tw-overflow-x-scroll{
    overflow-x: scroll
  }

  .md\:tw-overflow-y-scroll{
    overflow-y: scroll
  }

  .md\:tw-scrolling-touch{
    -webkit-overflow-scrolling: touch
  }

  .md\:tw-scrolling-auto{
    -webkit-overflow-scrolling: auto
  }

  .md\:tw-overscroll-auto{
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .md\:tw-overscroll-contain{
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .md\:tw-overscroll-none{
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .md\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  .md\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  .md\:tw-overscroll-y-none{
    overscroll-behavior-y: none
  }

  .md\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  .md\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  .md\:tw-overscroll-x-none{
    overscroll-behavior-x: none
  }

  .md\:tw-p-0{
    padding: 0
  }

  .md\:tw-p-1{
    padding: 0.25rem
  }

  .md\:tw-p-2{
    padding: 0.5rem
  }

  .md\:tw-p-3{
    padding: 0.75rem
  }

  .md\:tw-p-4{
    padding: 1rem
  }

  .md\:tw-p-5{
    padding: 1.25rem
  }

  .md\:tw-p-6{
    padding: 1.5rem
  }

  .md\:tw-p-8{
    padding: 2rem
  }

  .md\:tw-p-10{
    padding: 2.5rem
  }

  .md\:tw-p-12{
    padding: 3rem
  }

  .md\:tw-p-16{
    padding: 4rem
  }

  .md\:tw-p-20{
    padding: 5rem
  }

  .md\:tw-p-24{
    padding: 6rem
  }

  .md\:tw-p-32{
    padding: 8rem
  }

  .md\:tw-p-40{
    padding: 10rem
  }

  .md\:tw-p-48{
    padding: 12rem
  }

  .md\:tw-p-56{
    padding: 14rem
  }

  .md\:tw-p-64{
    padding: 16rem
  }

  .md\:tw-p-px{
    padding: 1px
  }

  .md\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0
  }

  .md\:tw-px-0{
    padding-left: 0;
    padding-right: 0
  }

  .md\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .md\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .md\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .md\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .md\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .md\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .md\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .md\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .md\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .md\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .md\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .md\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .md\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .md\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  .md\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .md\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  .md\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  .md\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .md\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  .md\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .md\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  .md\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .md\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  .md\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .md\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  .md\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .md\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  .md\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  .md\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  .md\:tw-pt-0{
    padding-top: 0
  }

  .md\:tw-pr-0{
    padding-right: 0
  }

  .md\:tw-pb-0{
    padding-bottom: 0
  }

  .md\:tw-pl-0{
    padding-left: 0
  }

  .md\:tw-pt-1{
    padding-top: 0.25rem
  }

  .md\:tw-pr-1{
    padding-right: 0.25rem
  }

  .md\:tw-pb-1{
    padding-bottom: 0.25rem
  }

  .md\:tw-pl-1{
    padding-left: 0.25rem
  }

  .md\:tw-pt-2{
    padding-top: 0.5rem
  }

  .md\:tw-pr-2{
    padding-right: 0.5rem
  }

  .md\:tw-pb-2{
    padding-bottom: 0.5rem
  }

  .md\:tw-pl-2{
    padding-left: 0.5rem
  }

  .md\:tw-pt-3{
    padding-top: 0.75rem
  }

  .md\:tw-pr-3{
    padding-right: 0.75rem
  }

  .md\:tw-pb-3{
    padding-bottom: 0.75rem
  }

  .md\:tw-pl-3{
    padding-left: 0.75rem
  }

  .md\:tw-pt-4{
    padding-top: 1rem
  }

  .md\:tw-pr-4{
    padding-right: 1rem
  }

  .md\:tw-pb-4{
    padding-bottom: 1rem
  }

  .md\:tw-pl-4{
    padding-left: 1rem
  }

  .md\:tw-pt-5{
    padding-top: 1.25rem
  }

  .md\:tw-pr-5{
    padding-right: 1.25rem
  }

  .md\:tw-pb-5{
    padding-bottom: 1.25rem
  }

  .md\:tw-pl-5{
    padding-left: 1.25rem
  }

  .md\:tw-pt-6{
    padding-top: 1.5rem
  }

  .md\:tw-pr-6{
    padding-right: 1.5rem
  }

  .md\:tw-pb-6{
    padding-bottom: 1.5rem
  }

  .md\:tw-pl-6{
    padding-left: 1.5rem
  }

  .md\:tw-pt-8{
    padding-top: 2rem
  }

  .md\:tw-pr-8{
    padding-right: 2rem
  }

  .md\:tw-pb-8{
    padding-bottom: 2rem
  }

  .md\:tw-pl-8{
    padding-left: 2rem
  }

  .md\:tw-pt-10{
    padding-top: 2.5rem
  }

  .md\:tw-pr-10{
    padding-right: 2.5rem
  }

  .md\:tw-pb-10{
    padding-bottom: 2.5rem
  }

  .md\:tw-pl-10{
    padding-left: 2.5rem
  }

  .md\:tw-pt-12{
    padding-top: 3rem
  }

  .md\:tw-pr-12{
    padding-right: 3rem
  }

  .md\:tw-pb-12{
    padding-bottom: 3rem
  }

  .md\:tw-pl-12{
    padding-left: 3rem
  }

  .md\:tw-pt-16{
    padding-top: 4rem
  }

  .md\:tw-pr-16{
    padding-right: 4rem
  }

  .md\:tw-pb-16{
    padding-bottom: 4rem
  }

  .md\:tw-pl-16{
    padding-left: 4rem
  }

  .md\:tw-pt-20{
    padding-top: 5rem
  }

  .md\:tw-pr-20{
    padding-right: 5rem
  }

  .md\:tw-pb-20{
    padding-bottom: 5rem
  }

  .md\:tw-pl-20{
    padding-left: 5rem
  }

  .md\:tw-pt-24{
    padding-top: 6rem
  }

  .md\:tw-pr-24{
    padding-right: 6rem
  }

  .md\:tw-pb-24{
    padding-bottom: 6rem
  }

  .md\:tw-pl-24{
    padding-left: 6rem
  }

  .md\:tw-pt-32{
    padding-top: 8rem
  }

  .md\:tw-pr-32{
    padding-right: 8rem
  }

  .md\:tw-pb-32{
    padding-bottom: 8rem
  }

  .md\:tw-pl-32{
    padding-left: 8rem
  }

  .md\:tw-pt-40{
    padding-top: 10rem
  }

  .md\:tw-pr-40{
    padding-right: 10rem
  }

  .md\:tw-pb-40{
    padding-bottom: 10rem
  }

  .md\:tw-pl-40{
    padding-left: 10rem
  }

  .md\:tw-pt-48{
    padding-top: 12rem
  }

  .md\:tw-pr-48{
    padding-right: 12rem
  }

  .md\:tw-pb-48{
    padding-bottom: 12rem
  }

  .md\:tw-pl-48{
    padding-left: 12rem
  }

  .md\:tw-pt-56{
    padding-top: 14rem
  }

  .md\:tw-pr-56{
    padding-right: 14rem
  }

  .md\:tw-pb-56{
    padding-bottom: 14rem
  }

  .md\:tw-pl-56{
    padding-left: 14rem
  }

  .md\:tw-pt-64{
    padding-top: 16rem
  }

  .md\:tw-pr-64{
    padding-right: 16rem
  }

  .md\:tw-pb-64{
    padding-bottom: 16rem
  }

  .md\:tw-pl-64{
    padding-left: 16rem
  }

  .md\:tw-pt-px{
    padding-top: 1px
  }

  .md\:tw-pr-px{
    padding-right: 1px
  }

  .md\:tw-pb-px{
    padding-bottom: 1px
  }

  .md\:tw-pl-px{
    padding-left: 1px
  }

  .md\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent
  }

  .md\:tw-placeholder-transparent::-moz-placeholder{
    color: transparent
  }

  .md\:tw-placeholder-transparent:-ms-input-placeholder{
    color: transparent
  }

  .md\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent
  }

  .md\:tw-placeholder-transparent::placeholder{
    color: transparent
  }

  .md\:tw-placeholder-tlg-green-dark::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green-dark::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green-dark:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green-dark::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green-dark::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-green::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-tlg-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-black::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-black::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-black:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-black::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-black::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-white::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-white::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-white:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-white::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-white::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-100::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-200::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-300::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-400::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-500::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-600::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-700::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-800::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-gray-900::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-300::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-400::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-500::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-600::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-700::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-800::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-red-900::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-200::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-600::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-700::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-800::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-orange-900::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-200::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-300::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-500::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-600::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-700::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-800::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-yellow-900::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-100::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-200::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-300::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-400::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-500::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-600::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-700::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-800::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-green-900::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-100::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-200::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-300::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-400::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-500::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-600::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-700::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-800::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-teal-900::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-200::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-300::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-400::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-500::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-600::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-700::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-800::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-blue-900::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-200::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-300::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-400::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-500::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-600::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-700::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-800::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-indigo-900::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-100::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-200::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-300::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-400::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-500::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-600::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-700::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-800::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-purple-900::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-400::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-600::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-700::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-800::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-pink-900::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent
  }

  .md\:focus\:tw-placeholder-transparent:focus::-moz-placeholder{
    color: transparent
  }

  .md\:focus\:tw-placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent
  }

  .md\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent
  }

  .md\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  .md\:focus\:tw-placeholder-tlg-green-dark:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green-dark:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green-dark:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green-dark:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green-dark:focus::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-green:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-tlg-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-black:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-black:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-black:focus::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-white:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-white:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-white:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-red-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-orange-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-yellow-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-green-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-teal-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-indigo-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-purple-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:focus\:tw-placeholder-pink-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .md\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .md\:tw-placeholder-opacity-0::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .md\:tw-placeholder-opacity-0:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .md\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .md\:tw-placeholder-opacity-0::placeholder{
    --placeholder-opacity: 0
  }

  .md\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:tw-placeholder-opacity-25::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:tw-placeholder-opacity-25:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:tw-placeholder-opacity-25::placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:tw-placeholder-opacity-50::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:tw-placeholder-opacity-50:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:tw-placeholder-opacity-50::placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:tw-placeholder-opacity-75::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:tw-placeholder-opacity-75:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:tw-placeholder-opacity-75::placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .md\:tw-placeholder-opacity-100::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .md\:tw-placeholder-opacity-100:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .md\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .md\:tw-placeholder-opacity-100::placeholder{
    --placeholder-opacity: 1
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .md\:focus\:tw-placeholder-opacity-0:focus:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --placeholder-opacity: 0
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:focus\:tw-placeholder-opacity-25:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --placeholder-opacity: 0.25
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:focus\:tw-placeholder-opacity-50:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --placeholder-opacity: 0.5
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:focus\:tw-placeholder-opacity-75:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --placeholder-opacity: 0.75
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .md\:focus\:tw-placeholder-opacity-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --placeholder-opacity: 1
  }

  .md\:tw-pointer-events-none{
    pointer-events: none
  }

  .md\:tw-pointer-events-auto{
    pointer-events: auto
  }

  .md\:tw-static{
    position: static
  }

  .md\:tw-fixed{
    position: fixed
  }

  .md\:tw-absolute{
    position: absolute
  }

  .md\:tw-relative{
    position: relative
  }

  .md\:tw-sticky{
    position: -webkit-sticky;
    position: sticky
  }

  .md\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .md\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .md\:tw-inset-y-0{
    top: 0;
    bottom: 0
  }

  .md\:tw-inset-x-0{
    right: 0;
    left: 0
  }

  .md\:tw-inset-y-auto{
    top: auto;
    bottom: auto
  }

  .md\:tw-inset-x-auto{
    right: auto;
    left: auto
  }

  .md\:tw-top-0{
    top: 0
  }

  .md\:tw-right-0{
    right: 0
  }

  .md\:tw-bottom-0{
    bottom: 0
  }

  .md\:tw-left-0{
    left: 0
  }

  .md\:tw-top-auto{
    top: auto
  }

  .md\:tw-right-auto{
    right: auto
  }

  .md\:tw-bottom-auto{
    bottom: auto
  }

  .md\:tw-left-auto{
    left: auto
  }

  .md\:tw-resize-none{
    resize: none
  }

  .md\:tw-resize-y{
    resize: vertical
  }

  .md\:tw-resize-x{
    resize: horizontal
  }

  .md\:tw-resize{
    resize: both
  }

  .md\:tw-shadow{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:tw-shadow-md{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:tw-shadow-lg{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:tw-shadow-xl{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:tw-shadow-2xl{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:tw-shadow-inner{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:tw-shadow-outline{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:tw-shadow-none{
    box-shadow: none
  }

  .md\:hover\:tw-shadow:hover{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:tw-shadow-md:hover{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:tw-shadow-lg:hover{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:tw-shadow-xl:hover{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:tw-shadow-2xl:hover{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:hover\:tw-shadow-inner:hover{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:tw-shadow-outline:hover{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:hover\:tw-shadow-none:hover{
    box-shadow: none
  }

  .md\:focus\:tw-shadow:focus{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:tw-shadow-md:focus{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:tw-shadow-lg:focus{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:tw-shadow-xl:focus{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:tw-shadow-2xl:focus{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:focus\:tw-shadow-inner:focus{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:tw-shadow-outline:focus{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:focus\:tw-shadow-none:focus{
    box-shadow: none
  }

  .md\:tw-fill-current{
    fill: currentColor
  }

  .md\:tw-stroke-current{
    stroke: currentColor
  }

  .md\:tw-stroke-0{
    stroke-width: 0
  }

  .md\:tw-stroke-1{
    stroke-width: 1
  }

  .md\:tw-stroke-2{
    stroke-width: 2
  }

  .md\:tw-table-auto{
    table-layout: auto
  }

  .md\:tw-table-fixed{
    table-layout: fixed
  }

  .md\:tw-text-left{
    text-align: left
  }

  .md\:tw-text-center{
    text-align: center
  }

  .md\:tw-text-right{
    text-align: right
  }

  .md\:tw-text-justify{
    text-align: justify
  }

  .md\:tw-text-transparent{
    color: transparent
  }

  .md\:tw-text-tlg-green-dark{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .md\:tw-text-tlg-green{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .md\:tw-text-tlg-orange-500{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .md\:tw-text-black{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:tw-text-white{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:tw-text-gray-100{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .md\:tw-text-gray-200{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .md\:tw-text-gray-300{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .md\:tw-text-gray-400{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .md\:tw-text-gray-500{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .md\:tw-text-gray-600{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .md\:tw-text-gray-700{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .md\:tw-text-gray-800{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .md\:tw-text-gray-900{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .md\:tw-text-red-100{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .md\:tw-text-red-200{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .md\:tw-text-red-300{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .md\:tw-text-red-400{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .md\:tw-text-red-500{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .md\:tw-text-red-600{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .md\:tw-text-red-700{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .md\:tw-text-red-800{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .md\:tw-text-red-900{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .md\:tw-text-orange-100{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .md\:tw-text-orange-200{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .md\:tw-text-orange-300{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .md\:tw-text-orange-400{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .md\:tw-text-orange-500{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .md\:tw-text-orange-600{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .md\:tw-text-orange-700{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .md\:tw-text-orange-800{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .md\:tw-text-orange-900{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .md\:tw-text-yellow-100{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .md\:tw-text-yellow-200{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .md\:tw-text-yellow-300{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .md\:tw-text-yellow-400{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .md\:tw-text-yellow-500{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .md\:tw-text-yellow-600{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .md\:tw-text-yellow-700{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .md\:tw-text-yellow-800{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .md\:tw-text-yellow-900{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .md\:tw-text-green-100{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .md\:tw-text-green-200{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .md\:tw-text-green-300{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .md\:tw-text-green-400{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .md\:tw-text-green-500{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .md\:tw-text-green-600{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .md\:tw-text-green-700{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .md\:tw-text-green-800{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .md\:tw-text-green-900{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .md\:tw-text-teal-100{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .md\:tw-text-teal-200{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .md\:tw-text-teal-300{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .md\:tw-text-teal-400{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .md\:tw-text-teal-500{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .md\:tw-text-teal-600{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .md\:tw-text-teal-700{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .md\:tw-text-teal-800{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .md\:tw-text-teal-900{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .md\:tw-text-blue-100{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .md\:tw-text-blue-200{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .md\:tw-text-blue-300{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .md\:tw-text-blue-400{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .md\:tw-text-blue-500{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .md\:tw-text-blue-600{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .md\:tw-text-blue-700{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .md\:tw-text-blue-800{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .md\:tw-text-blue-900{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .md\:tw-text-indigo-100{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .md\:tw-text-indigo-200{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .md\:tw-text-indigo-300{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .md\:tw-text-indigo-400{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .md\:tw-text-indigo-500{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .md\:tw-text-indigo-600{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .md\:tw-text-indigo-700{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .md\:tw-text-indigo-800{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .md\:tw-text-indigo-900{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .md\:tw-text-purple-100{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .md\:tw-text-purple-200{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .md\:tw-text-purple-300{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .md\:tw-text-purple-400{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .md\:tw-text-purple-500{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .md\:tw-text-purple-600{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .md\:tw-text-purple-700{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .md\:tw-text-purple-800{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .md\:tw-text-purple-900{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .md\:tw-text-pink-100{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .md\:tw-text-pink-200{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .md\:tw-text-pink-300{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .md\:tw-text-pink-400{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .md\:tw-text-pink-500{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .md\:tw-text-pink-600{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .md\:tw-text-pink-700{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .md\:tw-text-pink-800{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .md\:tw-text-pink-900{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .md\:hover\:tw-text-transparent:hover{
    color: transparent
  }

  .md\:hover\:tw-text-tlg-green-dark:hover{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .md\:hover\:tw-text-tlg-green:hover{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .md\:hover\:tw-text-tlg-orange-500:hover{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .md\:hover\:tw-text-black:hover{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:hover\:tw-text-white:hover{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-100:hover{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-200:hover{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-300:hover{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-400:hover{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-500:hover{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-600:hover{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-700:hover{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-800:hover{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .md\:hover\:tw-text-gray-900:hover{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-100:hover{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-200:hover{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-300:hover{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-400:hover{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-500:hover{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-600:hover{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-700:hover{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-800:hover{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .md\:hover\:tw-text-red-900:hover{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-100:hover{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-200:hover{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-300:hover{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-400:hover{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-500:hover{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-600:hover{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-700:hover{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-800:hover{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .md\:hover\:tw-text-orange-900:hover{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-100:hover{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-200:hover{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-300:hover{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-400:hover{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-500:hover{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-600:hover{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-700:hover{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-800:hover{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .md\:hover\:tw-text-yellow-900:hover{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-100:hover{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-200:hover{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-300:hover{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-400:hover{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-500:hover{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-600:hover{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-700:hover{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-800:hover{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .md\:hover\:tw-text-green-900:hover{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-100:hover{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-200:hover{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-300:hover{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-400:hover{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-500:hover{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-600:hover{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-700:hover{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-800:hover{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .md\:hover\:tw-text-teal-900:hover{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-100:hover{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-200:hover{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-300:hover{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-400:hover{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-500:hover{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-600:hover{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-700:hover{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-800:hover{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .md\:hover\:tw-text-blue-900:hover{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-100:hover{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-200:hover{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-300:hover{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-400:hover{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-500:hover{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-600:hover{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-700:hover{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-800:hover{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .md\:hover\:tw-text-indigo-900:hover{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-100:hover{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-200:hover{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-300:hover{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-400:hover{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-500:hover{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-600:hover{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-700:hover{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-800:hover{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .md\:hover\:tw-text-purple-900:hover{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-100:hover{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-200:hover{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-300:hover{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-400:hover{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-500:hover{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-600:hover{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-700:hover{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-800:hover{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .md\:hover\:tw-text-pink-900:hover{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .md\:focus\:tw-text-transparent:focus{
    color: transparent
  }

  .md\:focus\:tw-text-tlg-green-dark:focus{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .md\:focus\:tw-text-tlg-green:focus{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .md\:focus\:tw-text-tlg-orange-500:focus{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .md\:focus\:tw-text-black:focus{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:focus\:tw-text-white:focus{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-100:focus{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-200:focus{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-300:focus{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-400:focus{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-500:focus{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-600:focus{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-700:focus{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-800:focus{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .md\:focus\:tw-text-gray-900:focus{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-100:focus{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-200:focus{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-300:focus{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-400:focus{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-500:focus{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-600:focus{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-700:focus{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-800:focus{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .md\:focus\:tw-text-red-900:focus{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-100:focus{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-200:focus{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-300:focus{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-400:focus{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-500:focus{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-600:focus{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-700:focus{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-800:focus{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .md\:focus\:tw-text-orange-900:focus{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-100:focus{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-200:focus{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-300:focus{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-400:focus{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-500:focus{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-600:focus{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-700:focus{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-800:focus{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .md\:focus\:tw-text-yellow-900:focus{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-100:focus{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-200:focus{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-300:focus{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-400:focus{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-500:focus{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-600:focus{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-700:focus{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-800:focus{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .md\:focus\:tw-text-green-900:focus{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-100:focus{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-200:focus{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-300:focus{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-400:focus{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-500:focus{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-600:focus{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-700:focus{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-800:focus{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .md\:focus\:tw-text-teal-900:focus{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-100:focus{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-200:focus{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-300:focus{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-400:focus{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-500:focus{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-600:focus{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-700:focus{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-800:focus{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .md\:focus\:tw-text-blue-900:focus{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-100:focus{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-200:focus{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-300:focus{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-400:focus{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-500:focus{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-600:focus{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-700:focus{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-800:focus{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .md\:focus\:tw-text-indigo-900:focus{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-100:focus{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-200:focus{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-300:focus{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-400:focus{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-500:focus{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-600:focus{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-700:focus{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-800:focus{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .md\:focus\:tw-text-purple-900:focus{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-100:focus{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-200:focus{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-300:focus{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-400:focus{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-500:focus{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-600:focus{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-700:focus{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-800:focus{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .md\:focus\:tw-text-pink-900:focus{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .md\:tw-text-opacity-0{
    --text-opacity: 0
  }

  .md\:tw-text-opacity-25{
    --text-opacity: 0.25
  }

  .md\:tw-text-opacity-50{
    --text-opacity: 0.5
  }

  .md\:tw-text-opacity-75{
    --text-opacity: 0.75
  }

  .md\:tw-text-opacity-100{
    --text-opacity: 1
  }

  .md\:hover\:tw-text-opacity-0:hover{
    --text-opacity: 0
  }

  .md\:hover\:tw-text-opacity-25:hover{
    --text-opacity: 0.25
  }

  .md\:hover\:tw-text-opacity-50:hover{
    --text-opacity: 0.5
  }

  .md\:hover\:tw-text-opacity-75:hover{
    --text-opacity: 0.75
  }

  .md\:hover\:tw-text-opacity-100:hover{
    --text-opacity: 1
  }

  .md\:focus\:tw-text-opacity-0:focus{
    --text-opacity: 0
  }

  .md\:focus\:tw-text-opacity-25:focus{
    --text-opacity: 0.25
  }

  .md\:focus\:tw-text-opacity-50:focus{
    --text-opacity: 0.5
  }

  .md\:focus\:tw-text-opacity-75:focus{
    --text-opacity: 0.75
  }

  .md\:focus\:tw-text-opacity-100:focus{
    --text-opacity: 1
  }

  .md\:tw-italic{
    font-style: italic
  }

  .md\:tw-not-italic{
    font-style: normal
  }

  .md\:tw-uppercase{
    text-transform: uppercase
  }

  .md\:tw-lowercase{
    text-transform: lowercase
  }

  .md\:tw-capitalize{
    text-transform: capitalize
  }

  .md\:tw-normal-case{
    text-transform: none
  }

  .md\:tw-underline{
    text-decoration: underline
  }

  .md\:tw-line-through{
    text-decoration: line-through
  }

  .md\:tw-no-underline{
    text-decoration: none
  }

  .md\:hover\:tw-underline:hover{
    text-decoration: underline
  }

  .md\:hover\:tw-line-through:hover{
    text-decoration: line-through
  }

  .md\:hover\:tw-no-underline:hover{
    text-decoration: none
  }

  .md\:focus\:tw-underline:focus{
    text-decoration: underline
  }

  .md\:focus\:tw-line-through:focus{
    text-decoration: line-through
  }

  .md\:focus\:tw-no-underline:focus{
    text-decoration: none
  }

  .md\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .md\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .md\:tw-ordinal, .md\:tw-slashed-zero, .md\:tw-lining-nums, .md\:tw-oldstyle-nums, .md\:tw-proportional-nums, .md\:tw-tabular-nums, .md\:tw-diagonal-fractions, .md\:tw-stacked-fractions{
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    -webkit-font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
            font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .md\:tw-normal-nums{
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-numeric: normal
  }

  .md\:tw-ordinal{
    --font-variant-numeric-ordinal: ordinal
  }

  .md\:tw-slashed-zero{
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .md\:tw-lining-nums{
    --font-variant-numeric-figure: lining-nums
  }

  .md\:tw-oldstyle-nums{
    --font-variant-numeric-figure: oldstyle-nums
  }

  .md\:tw-proportional-nums{
    --font-variant-numeric-spacing: proportional-nums
  }

  .md\:tw-tabular-nums{
    --font-variant-numeric-spacing: tabular-nums
  }

  .md\:tw-diagonal-fractions{
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .md\:tw-stacked-fractions{
    --font-variant-numeric-fraction: stacked-fractions
  }

  .md\:tw-tracking-tighter{
    letter-spacing: -0.05em
  }

  .md\:tw-tracking-tight{
    letter-spacing: -0.025em
  }

  .md\:tw-tracking-normal{
    letter-spacing: 0
  }

  .md\:tw-tracking-wide{
    letter-spacing: 0.025em
  }

  .md\:tw-tracking-wider{
    letter-spacing: 0.05em
  }

  .md\:tw-tracking-widest{
    letter-spacing: 0.1em
  }

  .md\:tw-select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .md\:tw-select-text{
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .md\:tw-select-all{
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .md\:tw-select-auto{
    -webkit-user-select: auto;
       -moz-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .md\:tw-align-baseline{
    vertical-align: baseline
  }

  .md\:tw-align-top{
    vertical-align: top
  }

  .md\:tw-align-middle{
    vertical-align: middle
  }

  .md\:tw-align-bottom{
    vertical-align: bottom
  }

  .md\:tw-align-text-top{
    vertical-align: text-top
  }

  .md\:tw-align-text-bottom{
    vertical-align: text-bottom
  }

  .md\:tw-visible{
    visibility: visible
  }

  .md\:tw-invisible{
    visibility: hidden
  }

  .md\:tw-whitespace-normal{
    white-space: normal
  }

  .md\:tw-whitespace-no-wrap{
    white-space: nowrap
  }

  .md\:tw-whitespace-pre{
    white-space: pre
  }

  .md\:tw-whitespace-pre-line{
    white-space: pre-line
  }

  .md\:tw-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  .md\:tw-break-normal{
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .md\:tw-break-words{
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .md\:tw-break-all{
    word-break: break-all
  }

  .md\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .md\:tw-w-0{
    width: 0
  }

  .md\:tw-w-1{
    width: 0.25rem
  }

  .md\:tw-w-2{
    width: 0.5rem
  }

  .md\:tw-w-3{
    width: 0.75rem
  }

  .md\:tw-w-4{
    width: 1rem
  }

  .md\:tw-w-5{
    width: 1.25rem
  }

  .md\:tw-w-6{
    width: 1.5rem
  }

  .md\:tw-w-8{
    width: 2rem
  }

  .md\:tw-w-10{
    width: 2.5rem
  }

  .md\:tw-w-12{
    width: 3rem
  }

  .md\:tw-w-16{
    width: 4rem
  }

  .md\:tw-w-20{
    width: 5rem
  }

  .md\:tw-w-24{
    width: 6rem
  }

  .md\:tw-w-32{
    width: 8rem
  }

  .md\:tw-w-40{
    width: 10rem
  }

  .md\:tw-w-48{
    width: 12rem
  }

  .md\:tw-w-56{
    width: 14rem
  }

  .md\:tw-w-64{
    width: 16rem
  }

  .md\:tw-w-auto{
    width: auto
  }

  .md\:tw-w-px{
    width: 1px
  }

  .md\:tw-w-1\/2{
    width: 50%
  }

  .md\:tw-w-1\/3{
    width: 33.33333%
  }

  .md\:tw-w-2\/3{
    width: 66.66667%
  }

  .md\:tw-w-1\/4{
    width: 25%
  }

  .md\:tw-w-2\/4{
    width: 50%
  }

  .md\:tw-w-3\/4{
    width: 75%
  }

  .md\:tw-w-1\/5{
    width: 20%
  }

  .md\:tw-w-2\/5{
    width: 40%
  }

  .md\:tw-w-3\/5{
    width: 60%
  }

  .md\:tw-w-4\/5{
    width: 80%
  }

  .md\:tw-w-1\/6{
    width: 16.66667%
  }

  .md\:tw-w-2\/6{
    width: 33.33333%
  }

  .md\:tw-w-3\/6{
    width: 50%
  }

  .md\:tw-w-4\/6{
    width: 66.66667%
  }

  .md\:tw-w-5\/6{
    width: 83.33333%
  }

  .md\:tw-w-1\/12{
    width: 8.33333%
  }

  .md\:tw-w-2\/12{
    width: 16.66667%
  }

  .md\:tw-w-3\/12{
    width: 25%
  }

  .md\:tw-w-4\/12{
    width: 33.33333%
  }

  .md\:tw-w-5\/12{
    width: 41.66667%
  }

  .md\:tw-w-6\/12{
    width: 50%
  }

  .md\:tw-w-7\/12{
    width: 58.33333%
  }

  .md\:tw-w-8\/12{
    width: 66.66667%
  }

  .md\:tw-w-9\/12{
    width: 75%
  }

  .md\:tw-w-10\/12{
    width: 83.33333%
  }

  .md\:tw-w-11\/12{
    width: 91.66667%
  }

  .md\:tw-w-full{
    width: 100%
  }

  .md\:tw-w-screen{
    width: 100vw
  }

  .md\:tw-z-0{
    z-index: 0
  }

  .md\:tw-z-10{
    z-index: 10
  }

  .md\:tw-z-20{
    z-index: 20
  }

  .md\:tw-z-30{
    z-index: 30
  }

  .md\:tw-z-40{
    z-index: 40
  }

  .md\:tw-z-50{
    z-index: 50
  }

  .md\:tw-z-auto{
    z-index: auto
  }

  .md\:tw-gap-0{
    grid-gap: 0;
    grid-gap: 0;
    gap: 0
  }

  .md\:tw-gap-1{
    grid-gap: 0.25rem;
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .md\:tw-gap-2{
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .md\:tw-gap-3{
    grid-gap: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .md\:tw-gap-4{
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem
  }

  .md\:tw-gap-5{
    grid-gap: 1.25rem;
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .md\:tw-gap-6{
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .md\:tw-gap-8{
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem
  }

  .md\:tw-gap-10{
    grid-gap: 2.5rem;
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .md\:tw-gap-12{
    grid-gap: 3rem;
    grid-gap: 3rem;
    gap: 3rem
  }

  .md\:tw-gap-16{
    grid-gap: 4rem;
    grid-gap: 4rem;
    gap: 4rem
  }

  .md\:tw-gap-20{
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem
  }

  .md\:tw-gap-24{
    grid-gap: 6rem;
    grid-gap: 6rem;
    gap: 6rem
  }

  .md\:tw-gap-32{
    grid-gap: 8rem;
    grid-gap: 8rem;
    gap: 8rem
  }

  .md\:tw-gap-40{
    grid-gap: 10rem;
    grid-gap: 10rem;
    gap: 10rem
  }

  .md\:tw-gap-48{
    grid-gap: 12rem;
    grid-gap: 12rem;
    gap: 12rem
  }

  .md\:tw-gap-56{
    grid-gap: 14rem;
    grid-gap: 14rem;
    gap: 14rem
  }

  .md\:tw-gap-64{
    grid-gap: 16rem;
    grid-gap: 16rem;
    gap: 16rem
  }

  .md\:tw-gap-px{
    grid-gap: 1px;
    grid-gap: 1px;
    gap: 1px
  }

  .md\:tw-col-gap-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .md\:tw-col-gap-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .md\:tw-col-gap-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .md\:tw-col-gap-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .md\:tw-col-gap-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .md\:tw-col-gap-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .md\:tw-col-gap-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .md\:tw-col-gap-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .md\:tw-col-gap-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .md\:tw-col-gap-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .md\:tw-col-gap-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .md\:tw-col-gap-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .md\:tw-col-gap-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .md\:tw-col-gap-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .md\:tw-col-gap-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .md\:tw-col-gap-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .md\:tw-col-gap-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .md\:tw-col-gap-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .md\:tw-col-gap-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .md\:tw-gap-x-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .md\:tw-gap-x-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .md\:tw-gap-x-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .md\:tw-gap-x-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .md\:tw-gap-x-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .md\:tw-gap-x-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .md\:tw-gap-x-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .md\:tw-gap-x-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .md\:tw-gap-x-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .md\:tw-gap-x-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .md\:tw-gap-x-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .md\:tw-gap-x-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .md\:tw-gap-x-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .md\:tw-gap-x-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .md\:tw-gap-x-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .md\:tw-gap-x-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .md\:tw-gap-x-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .md\:tw-gap-x-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .md\:tw-gap-x-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .md\:tw-row-gap-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .md\:tw-row-gap-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .md\:tw-row-gap-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .md\:tw-row-gap-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .md\:tw-row-gap-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .md\:tw-row-gap-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .md\:tw-row-gap-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .md\:tw-row-gap-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .md\:tw-row-gap-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .md\:tw-row-gap-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .md\:tw-row-gap-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .md\:tw-row-gap-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .md\:tw-row-gap-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .md\:tw-row-gap-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .md\:tw-row-gap-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .md\:tw-row-gap-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .md\:tw-row-gap-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .md\:tw-row-gap-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .md\:tw-row-gap-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .md\:tw-gap-y-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .md\:tw-gap-y-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .md\:tw-gap-y-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .md\:tw-gap-y-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .md\:tw-gap-y-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .md\:tw-gap-y-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .md\:tw-gap-y-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .md\:tw-gap-y-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .md\:tw-gap-y-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .md\:tw-gap-y-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .md\:tw-gap-y-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .md\:tw-gap-y-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .md\:tw-gap-y-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .md\:tw-gap-y-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .md\:tw-gap-y-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .md\:tw-gap-y-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .md\:tw-gap-y-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .md\:tw-gap-y-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .md\:tw-gap-y-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .md\:tw-grid-flow-row{
    grid-auto-flow: row
  }

  .md\:tw-grid-flow-col{
    grid-auto-flow: column
  }

  .md\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  .md\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  .md\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:tw-grid-cols-none{
    grid-template-columns: none
  }

  .md\:tw-auto-cols-auto{
    grid-auto-columns: auto
  }

  .md\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .md\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .md\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  .md\:tw-col-auto{
    grid-column: auto
  }

  .md\:tw-col-span-1{
    grid-column: span 1 / span 1
  }

  .md\:tw-col-span-2{
    grid-column: span 2 / span 2
  }

  .md\:tw-col-span-3{
    grid-column: span 3 / span 3
  }

  .md\:tw-col-span-4{
    grid-column: span 4 / span 4
  }

  .md\:tw-col-span-5{
    grid-column: span 5 / span 5
  }

  .md\:tw-col-span-6{
    grid-column: span 6 / span 6
  }

  .md\:tw-col-span-7{
    grid-column: span 7 / span 7
  }

  .md\:tw-col-span-8{
    grid-column: span 8 / span 8
  }

  .md\:tw-col-span-9{
    grid-column: span 9 / span 9
  }

  .md\:tw-col-span-10{
    grid-column: span 10 / span 10
  }

  .md\:tw-col-span-11{
    grid-column: span 11 / span 11
  }

  .md\:tw-col-span-12{
    grid-column: span 12 / span 12
  }

  .md\:tw-col-span-full{
    grid-column: 1 / -1
  }

  .md\:tw-col-start-1{
    grid-column-start: 1
  }

  .md\:tw-col-start-2{
    grid-column-start: 2
  }

  .md\:tw-col-start-3{
    grid-column-start: 3
  }

  .md\:tw-col-start-4{
    grid-column-start: 4
  }

  .md\:tw-col-start-5{
    grid-column-start: 5
  }

  .md\:tw-col-start-6{
    grid-column-start: 6
  }

  .md\:tw-col-start-7{
    grid-column-start: 7
  }

  .md\:tw-col-start-8{
    grid-column-start: 8
  }

  .md\:tw-col-start-9{
    grid-column-start: 9
  }

  .md\:tw-col-start-10{
    grid-column-start: 10
  }

  .md\:tw-col-start-11{
    grid-column-start: 11
  }

  .md\:tw-col-start-12{
    grid-column-start: 12
  }

  .md\:tw-col-start-13{
    grid-column-start: 13
  }

  .md\:tw-col-start-auto{
    grid-column-start: auto
  }

  .md\:tw-col-end-1{
    grid-column-end: 1
  }

  .md\:tw-col-end-2{
    grid-column-end: 2
  }

  .md\:tw-col-end-3{
    grid-column-end: 3
  }

  .md\:tw-col-end-4{
    grid-column-end: 4
  }

  .md\:tw-col-end-5{
    grid-column-end: 5
  }

  .md\:tw-col-end-6{
    grid-column-end: 6
  }

  .md\:tw-col-end-7{
    grid-column-end: 7
  }

  .md\:tw-col-end-8{
    grid-column-end: 8
  }

  .md\:tw-col-end-9{
    grid-column-end: 9
  }

  .md\:tw-col-end-10{
    grid-column-end: 10
  }

  .md\:tw-col-end-11{
    grid-column-end: 11
  }

  .md\:tw-col-end-12{
    grid-column-end: 12
  }

  .md\:tw-col-end-13{
    grid-column-end: 13
  }

  .md\:tw-col-end-auto{
    grid-column-end: auto
  }

  .md\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-none{
    grid-template-rows: none
  }

  .md\:tw-auto-rows-auto{
    grid-auto-rows: auto
  }

  .md\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .md\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .md\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  .md\:tw-row-auto{
    grid-row: auto
  }

  .md\:tw-row-span-1{
    grid-row: span 1 / span 1
  }

  .md\:tw-row-span-2{
    grid-row: span 2 / span 2
  }

  .md\:tw-row-span-3{
    grid-row: span 3 / span 3
  }

  .md\:tw-row-span-4{
    grid-row: span 4 / span 4
  }

  .md\:tw-row-span-5{
    grid-row: span 5 / span 5
  }

  .md\:tw-row-span-6{
    grid-row: span 6 / span 6
  }

  .md\:tw-row-span-full{
    grid-row: 1 / -1
  }

  .md\:tw-row-start-1{
    grid-row-start: 1
  }

  .md\:tw-row-start-2{
    grid-row-start: 2
  }

  .md\:tw-row-start-3{
    grid-row-start: 3
  }

  .md\:tw-row-start-4{
    grid-row-start: 4
  }

  .md\:tw-row-start-5{
    grid-row-start: 5
  }

  .md\:tw-row-start-6{
    grid-row-start: 6
  }

  .md\:tw-row-start-7{
    grid-row-start: 7
  }

  .md\:tw-row-start-auto{
    grid-row-start: auto
  }

  .md\:tw-row-end-1{
    grid-row-end: 1
  }

  .md\:tw-row-end-2{
    grid-row-end: 2
  }

  .md\:tw-row-end-3{
    grid-row-end: 3
  }

  .md\:tw-row-end-4{
    grid-row-end: 4
  }

  .md\:tw-row-end-5{
    grid-row-end: 5
  }

  .md\:tw-row-end-6{
    grid-row-end: 6
  }

  .md\:tw-row-end-7{
    grid-row-end: 7
  }

  .md\:tw-row-end-auto{
    grid-row-end: auto
  }

  .md\:tw-transform{
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .md\:tw-transform-none{
    -webkit-transform: none;
            transform: none
  }

  .md\:tw-origin-center{
    -webkit-transform-origin: center;
            transform-origin: center
  }

  .md\:tw-origin-top{
    -webkit-transform-origin: top;
            transform-origin: top
  }

  .md\:tw-origin-top-right{
    -webkit-transform-origin: top right;
            transform-origin: top right
  }

  .md\:tw-origin-right{
    -webkit-transform-origin: right;
            transform-origin: right
  }

  .md\:tw-origin-bottom-right{
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right
  }

  .md\:tw-origin-bottom{
    -webkit-transform-origin: bottom;
            transform-origin: bottom
  }

  .md\:tw-origin-bottom-left{
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left
  }

  .md\:tw-origin-left{
    -webkit-transform-origin: left;
            transform-origin: left
  }

  .md\:tw-origin-top-left{
    -webkit-transform-origin: top left;
            transform-origin: top left
  }

  .md\:tw-scale-0{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:tw-scale-50{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:tw-scale-75{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:tw-scale-90{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:tw-scale-95{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:tw-scale-100{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:tw-scale-105{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:tw-scale-110{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:tw-scale-125{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:tw-scale-150{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:tw-scale-x-0{
    --transform-scale-x: 0
  }

  .md\:tw-scale-x-50{
    --transform-scale-x: .5
  }

  .md\:tw-scale-x-75{
    --transform-scale-x: .75
  }

  .md\:tw-scale-x-90{
    --transform-scale-x: .9
  }

  .md\:tw-scale-x-95{
    --transform-scale-x: .95
  }

  .md\:tw-scale-x-100{
    --transform-scale-x: 1
  }

  .md\:tw-scale-x-105{
    --transform-scale-x: 1.05
  }

  .md\:tw-scale-x-110{
    --transform-scale-x: 1.1
  }

  .md\:tw-scale-x-125{
    --transform-scale-x: 1.25
  }

  .md\:tw-scale-x-150{
    --transform-scale-x: 1.5
  }

  .md\:tw-scale-y-0{
    --transform-scale-y: 0
  }

  .md\:tw-scale-y-50{
    --transform-scale-y: .5
  }

  .md\:tw-scale-y-75{
    --transform-scale-y: .75
  }

  .md\:tw-scale-y-90{
    --transform-scale-y: .9
  }

  .md\:tw-scale-y-95{
    --transform-scale-y: .95
  }

  .md\:tw-scale-y-100{
    --transform-scale-y: 1
  }

  .md\:tw-scale-y-105{
    --transform-scale-y: 1.05
  }

  .md\:tw-scale-y-110{
    --transform-scale-y: 1.1
  }

  .md\:tw-scale-y-125{
    --transform-scale-y: 1.25
  }

  .md\:tw-scale-y-150{
    --transform-scale-y: 1.5
  }

  .md\:hover\:tw-scale-0:hover{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:hover\:tw-scale-50:hover{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:hover\:tw-scale-75:hover{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:hover\:tw-scale-90:hover{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:hover\:tw-scale-95:hover{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:hover\:tw-scale-100:hover{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:hover\:tw-scale-105:hover{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:hover\:tw-scale-110:hover{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:hover\:tw-scale-125:hover{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:hover\:tw-scale-150:hover{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:hover\:tw-scale-x-0:hover{
    --transform-scale-x: 0
  }

  .md\:hover\:tw-scale-x-50:hover{
    --transform-scale-x: .5
  }

  .md\:hover\:tw-scale-x-75:hover{
    --transform-scale-x: .75
  }

  .md\:hover\:tw-scale-x-90:hover{
    --transform-scale-x: .9
  }

  .md\:hover\:tw-scale-x-95:hover{
    --transform-scale-x: .95
  }

  .md\:hover\:tw-scale-x-100:hover{
    --transform-scale-x: 1
  }

  .md\:hover\:tw-scale-x-105:hover{
    --transform-scale-x: 1.05
  }

  .md\:hover\:tw-scale-x-110:hover{
    --transform-scale-x: 1.1
  }

  .md\:hover\:tw-scale-x-125:hover{
    --transform-scale-x: 1.25
  }

  .md\:hover\:tw-scale-x-150:hover{
    --transform-scale-x: 1.5
  }

  .md\:hover\:tw-scale-y-0:hover{
    --transform-scale-y: 0
  }

  .md\:hover\:tw-scale-y-50:hover{
    --transform-scale-y: .5
  }

  .md\:hover\:tw-scale-y-75:hover{
    --transform-scale-y: .75
  }

  .md\:hover\:tw-scale-y-90:hover{
    --transform-scale-y: .9
  }

  .md\:hover\:tw-scale-y-95:hover{
    --transform-scale-y: .95
  }

  .md\:hover\:tw-scale-y-100:hover{
    --transform-scale-y: 1
  }

  .md\:hover\:tw-scale-y-105:hover{
    --transform-scale-y: 1.05
  }

  .md\:hover\:tw-scale-y-110:hover{
    --transform-scale-y: 1.1
  }

  .md\:hover\:tw-scale-y-125:hover{
    --transform-scale-y: 1.25
  }

  .md\:hover\:tw-scale-y-150:hover{
    --transform-scale-y: 1.5
  }

  .md\:focus\:tw-scale-0:focus{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:focus\:tw-scale-50:focus{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:focus\:tw-scale-75:focus{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:focus\:tw-scale-90:focus{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:focus\:tw-scale-95:focus{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:focus\:tw-scale-100:focus{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:focus\:tw-scale-105:focus{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:focus\:tw-scale-110:focus{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:focus\:tw-scale-125:focus{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:focus\:tw-scale-150:focus{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:focus\:tw-scale-x-0:focus{
    --transform-scale-x: 0
  }

  .md\:focus\:tw-scale-x-50:focus{
    --transform-scale-x: .5
  }

  .md\:focus\:tw-scale-x-75:focus{
    --transform-scale-x: .75
  }

  .md\:focus\:tw-scale-x-90:focus{
    --transform-scale-x: .9
  }

  .md\:focus\:tw-scale-x-95:focus{
    --transform-scale-x: .95
  }

  .md\:focus\:tw-scale-x-100:focus{
    --transform-scale-x: 1
  }

  .md\:focus\:tw-scale-x-105:focus{
    --transform-scale-x: 1.05
  }

  .md\:focus\:tw-scale-x-110:focus{
    --transform-scale-x: 1.1
  }

  .md\:focus\:tw-scale-x-125:focus{
    --transform-scale-x: 1.25
  }

  .md\:focus\:tw-scale-x-150:focus{
    --transform-scale-x: 1.5
  }

  .md\:focus\:tw-scale-y-0:focus{
    --transform-scale-y: 0
  }

  .md\:focus\:tw-scale-y-50:focus{
    --transform-scale-y: .5
  }

  .md\:focus\:tw-scale-y-75:focus{
    --transform-scale-y: .75
  }

  .md\:focus\:tw-scale-y-90:focus{
    --transform-scale-y: .9
  }

  .md\:focus\:tw-scale-y-95:focus{
    --transform-scale-y: .95
  }

  .md\:focus\:tw-scale-y-100:focus{
    --transform-scale-y: 1
  }

  .md\:focus\:tw-scale-y-105:focus{
    --transform-scale-y: 1.05
  }

  .md\:focus\:tw-scale-y-110:focus{
    --transform-scale-y: 1.1
  }

  .md\:focus\:tw-scale-y-125:focus{
    --transform-scale-y: 1.25
  }

  .md\:focus\:tw-scale-y-150:focus{
    --transform-scale-y: 1.5
  }

  .md\:tw-rotate-0{
    --transform-rotate: 0
  }

  .md\:tw-rotate-1{
    --transform-rotate: 1deg
  }

  .md\:tw-rotate-2{
    --transform-rotate: 2deg
  }

  .md\:tw-rotate-3{
    --transform-rotate: 3deg
  }

  .md\:tw-rotate-6{
    --transform-rotate: 6deg
  }

  .md\:tw-rotate-12{
    --transform-rotate: 12deg
  }

  .md\:tw-rotate-45{
    --transform-rotate: 45deg
  }

  .md\:tw-rotate-90{
    --transform-rotate: 90deg
  }

  .md\:tw-rotate-180{
    --transform-rotate: 180deg
  }

  .md\:tw--rotate-180{
    --transform-rotate: -180deg
  }

  .md\:tw--rotate-90{
    --transform-rotate: -90deg
  }

  .md\:tw--rotate-45{
    --transform-rotate: -45deg
  }

  .md\:tw--rotate-12{
    --transform-rotate: -12deg
  }

  .md\:tw--rotate-6{
    --transform-rotate: -6deg
  }

  .md\:tw--rotate-3{
    --transform-rotate: -3deg
  }

  .md\:tw--rotate-2{
    --transform-rotate: -2deg
  }

  .md\:tw--rotate-1{
    --transform-rotate: -1deg
  }

  .md\:hover\:tw-rotate-0:hover{
    --transform-rotate: 0
  }

  .md\:hover\:tw-rotate-1:hover{
    --transform-rotate: 1deg
  }

  .md\:hover\:tw-rotate-2:hover{
    --transform-rotate: 2deg
  }

  .md\:hover\:tw-rotate-3:hover{
    --transform-rotate: 3deg
  }

  .md\:hover\:tw-rotate-6:hover{
    --transform-rotate: 6deg
  }

  .md\:hover\:tw-rotate-12:hover{
    --transform-rotate: 12deg
  }

  .md\:hover\:tw-rotate-45:hover{
    --transform-rotate: 45deg
  }

  .md\:hover\:tw-rotate-90:hover{
    --transform-rotate: 90deg
  }

  .md\:hover\:tw-rotate-180:hover{
    --transform-rotate: 180deg
  }

  .md\:hover\:tw--rotate-180:hover{
    --transform-rotate: -180deg
  }

  .md\:hover\:tw--rotate-90:hover{
    --transform-rotate: -90deg
  }

  .md\:hover\:tw--rotate-45:hover{
    --transform-rotate: -45deg
  }

  .md\:hover\:tw--rotate-12:hover{
    --transform-rotate: -12deg
  }

  .md\:hover\:tw--rotate-6:hover{
    --transform-rotate: -6deg
  }

  .md\:hover\:tw--rotate-3:hover{
    --transform-rotate: -3deg
  }

  .md\:hover\:tw--rotate-2:hover{
    --transform-rotate: -2deg
  }

  .md\:hover\:tw--rotate-1:hover{
    --transform-rotate: -1deg
  }

  .md\:focus\:tw-rotate-0:focus{
    --transform-rotate: 0
  }

  .md\:focus\:tw-rotate-1:focus{
    --transform-rotate: 1deg
  }

  .md\:focus\:tw-rotate-2:focus{
    --transform-rotate: 2deg
  }

  .md\:focus\:tw-rotate-3:focus{
    --transform-rotate: 3deg
  }

  .md\:focus\:tw-rotate-6:focus{
    --transform-rotate: 6deg
  }

  .md\:focus\:tw-rotate-12:focus{
    --transform-rotate: 12deg
  }

  .md\:focus\:tw-rotate-45:focus{
    --transform-rotate: 45deg
  }

  .md\:focus\:tw-rotate-90:focus{
    --transform-rotate: 90deg
  }

  .md\:focus\:tw-rotate-180:focus{
    --transform-rotate: 180deg
  }

  .md\:focus\:tw--rotate-180:focus{
    --transform-rotate: -180deg
  }

  .md\:focus\:tw--rotate-90:focus{
    --transform-rotate: -90deg
  }

  .md\:focus\:tw--rotate-45:focus{
    --transform-rotate: -45deg
  }

  .md\:focus\:tw--rotate-12:focus{
    --transform-rotate: -12deg
  }

  .md\:focus\:tw--rotate-6:focus{
    --transform-rotate: -6deg
  }

  .md\:focus\:tw--rotate-3:focus{
    --transform-rotate: -3deg
  }

  .md\:focus\:tw--rotate-2:focus{
    --transform-rotate: -2deg
  }

  .md\:focus\:tw--rotate-1:focus{
    --transform-rotate: -1deg
  }

  .md\:tw-translate-x-0{
    --transform-translate-x: 0
  }

  .md\:tw-translate-x-1{
    --transform-translate-x: 0.25rem
  }

  .md\:tw-translate-x-2{
    --transform-translate-x: 0.5rem
  }

  .md\:tw-translate-x-3{
    --transform-translate-x: 0.75rem
  }

  .md\:tw-translate-x-4{
    --transform-translate-x: 1rem
  }

  .md\:tw-translate-x-5{
    --transform-translate-x: 1.25rem
  }

  .md\:tw-translate-x-6{
    --transform-translate-x: 1.5rem
  }

  .md\:tw-translate-x-8{
    --transform-translate-x: 2rem
  }

  .md\:tw-translate-x-10{
    --transform-translate-x: 2.5rem
  }

  .md\:tw-translate-x-12{
    --transform-translate-x: 3rem
  }

  .md\:tw-translate-x-16{
    --transform-translate-x: 4rem
  }

  .md\:tw-translate-x-20{
    --transform-translate-x: 5rem
  }

  .md\:tw-translate-x-24{
    --transform-translate-x: 6rem
  }

  .md\:tw-translate-x-32{
    --transform-translate-x: 8rem
  }

  .md\:tw-translate-x-40{
    --transform-translate-x: 10rem
  }

  .md\:tw-translate-x-48{
    --transform-translate-x: 12rem
  }

  .md\:tw-translate-x-56{
    --transform-translate-x: 14rem
  }

  .md\:tw-translate-x-64{
    --transform-translate-x: 16rem
  }

  .md\:tw-translate-x-px{
    --transform-translate-x: 1px
  }

  .md\:tw--translate-x-1{
    --transform-translate-x: -0.25rem
  }

  .md\:tw--translate-x-2{
    --transform-translate-x: -0.5rem
  }

  .md\:tw--translate-x-3{
    --transform-translate-x: -0.75rem
  }

  .md\:tw--translate-x-4{
    --transform-translate-x: -1rem
  }

  .md\:tw--translate-x-5{
    --transform-translate-x: -1.25rem
  }

  .md\:tw--translate-x-6{
    --transform-translate-x: -1.5rem
  }

  .md\:tw--translate-x-8{
    --transform-translate-x: -2rem
  }

  .md\:tw--translate-x-10{
    --transform-translate-x: -2.5rem
  }

  .md\:tw--translate-x-12{
    --transform-translate-x: -3rem
  }

  .md\:tw--translate-x-16{
    --transform-translate-x: -4rem
  }

  .md\:tw--translate-x-20{
    --transform-translate-x: -5rem
  }

  .md\:tw--translate-x-24{
    --transform-translate-x: -6rem
  }

  .md\:tw--translate-x-32{
    --transform-translate-x: -8rem
  }

  .md\:tw--translate-x-40{
    --transform-translate-x: -10rem
  }

  .md\:tw--translate-x-48{
    --transform-translate-x: -12rem
  }

  .md\:tw--translate-x-56{
    --transform-translate-x: -14rem
  }

  .md\:tw--translate-x-64{
    --transform-translate-x: -16rem
  }

  .md\:tw--translate-x-px{
    --transform-translate-x: -1px
  }

  .md\:tw--translate-x-full{
    --transform-translate-x: -100%
  }

  .md\:tw--translate-x-1\/2{
    --transform-translate-x: -50%
  }

  .md\:tw-translate-x-1\/2{
    --transform-translate-x: 50%
  }

  .md\:tw-translate-x-full{
    --transform-translate-x: 100%
  }

  .md\:tw-translate-y-0{
    --transform-translate-y: 0
  }

  .md\:tw-translate-y-1{
    --transform-translate-y: 0.25rem
  }

  .md\:tw-translate-y-2{
    --transform-translate-y: 0.5rem
  }

  .md\:tw-translate-y-3{
    --transform-translate-y: 0.75rem
  }

  .md\:tw-translate-y-4{
    --transform-translate-y: 1rem
  }

  .md\:tw-translate-y-5{
    --transform-translate-y: 1.25rem
  }

  .md\:tw-translate-y-6{
    --transform-translate-y: 1.5rem
  }

  .md\:tw-translate-y-8{
    --transform-translate-y: 2rem
  }

  .md\:tw-translate-y-10{
    --transform-translate-y: 2.5rem
  }

  .md\:tw-translate-y-12{
    --transform-translate-y: 3rem
  }

  .md\:tw-translate-y-16{
    --transform-translate-y: 4rem
  }

  .md\:tw-translate-y-20{
    --transform-translate-y: 5rem
  }

  .md\:tw-translate-y-24{
    --transform-translate-y: 6rem
  }

  .md\:tw-translate-y-32{
    --transform-translate-y: 8rem
  }

  .md\:tw-translate-y-40{
    --transform-translate-y: 10rem
  }

  .md\:tw-translate-y-48{
    --transform-translate-y: 12rem
  }

  .md\:tw-translate-y-56{
    --transform-translate-y: 14rem
  }

  .md\:tw-translate-y-64{
    --transform-translate-y: 16rem
  }

  .md\:tw-translate-y-px{
    --transform-translate-y: 1px
  }

  .md\:tw--translate-y-1{
    --transform-translate-y: -0.25rem
  }

  .md\:tw--translate-y-2{
    --transform-translate-y: -0.5rem
  }

  .md\:tw--translate-y-3{
    --transform-translate-y: -0.75rem
  }

  .md\:tw--translate-y-4{
    --transform-translate-y: -1rem
  }

  .md\:tw--translate-y-5{
    --transform-translate-y: -1.25rem
  }

  .md\:tw--translate-y-6{
    --transform-translate-y: -1.5rem
  }

  .md\:tw--translate-y-8{
    --transform-translate-y: -2rem
  }

  .md\:tw--translate-y-10{
    --transform-translate-y: -2.5rem
  }

  .md\:tw--translate-y-12{
    --transform-translate-y: -3rem
  }

  .md\:tw--translate-y-16{
    --transform-translate-y: -4rem
  }

  .md\:tw--translate-y-20{
    --transform-translate-y: -5rem
  }

  .md\:tw--translate-y-24{
    --transform-translate-y: -6rem
  }

  .md\:tw--translate-y-32{
    --transform-translate-y: -8rem
  }

  .md\:tw--translate-y-40{
    --transform-translate-y: -10rem
  }

  .md\:tw--translate-y-48{
    --transform-translate-y: -12rem
  }

  .md\:tw--translate-y-56{
    --transform-translate-y: -14rem
  }

  .md\:tw--translate-y-64{
    --transform-translate-y: -16rem
  }

  .md\:tw--translate-y-px{
    --transform-translate-y: -1px
  }

  .md\:tw--translate-y-full{
    --transform-translate-y: -100%
  }

  .md\:tw--translate-y-1\/2{
    --transform-translate-y: -50%
  }

  .md\:tw-translate-y-1\/2{
    --transform-translate-y: 50%
  }

  .md\:tw-translate-y-full{
    --transform-translate-y: 100%
  }

  .md\:hover\:tw-translate-x-0:hover{
    --transform-translate-x: 0
  }

  .md\:hover\:tw-translate-x-1:hover{
    --transform-translate-x: 0.25rem
  }

  .md\:hover\:tw-translate-x-2:hover{
    --transform-translate-x: 0.5rem
  }

  .md\:hover\:tw-translate-x-3:hover{
    --transform-translate-x: 0.75rem
  }

  .md\:hover\:tw-translate-x-4:hover{
    --transform-translate-x: 1rem
  }

  .md\:hover\:tw-translate-x-5:hover{
    --transform-translate-x: 1.25rem
  }

  .md\:hover\:tw-translate-x-6:hover{
    --transform-translate-x: 1.5rem
  }

  .md\:hover\:tw-translate-x-8:hover{
    --transform-translate-x: 2rem
  }

  .md\:hover\:tw-translate-x-10:hover{
    --transform-translate-x: 2.5rem
  }

  .md\:hover\:tw-translate-x-12:hover{
    --transform-translate-x: 3rem
  }

  .md\:hover\:tw-translate-x-16:hover{
    --transform-translate-x: 4rem
  }

  .md\:hover\:tw-translate-x-20:hover{
    --transform-translate-x: 5rem
  }

  .md\:hover\:tw-translate-x-24:hover{
    --transform-translate-x: 6rem
  }

  .md\:hover\:tw-translate-x-32:hover{
    --transform-translate-x: 8rem
  }

  .md\:hover\:tw-translate-x-40:hover{
    --transform-translate-x: 10rem
  }

  .md\:hover\:tw-translate-x-48:hover{
    --transform-translate-x: 12rem
  }

  .md\:hover\:tw-translate-x-56:hover{
    --transform-translate-x: 14rem
  }

  .md\:hover\:tw-translate-x-64:hover{
    --transform-translate-x: 16rem
  }

  .md\:hover\:tw-translate-x-px:hover{
    --transform-translate-x: 1px
  }

  .md\:hover\:tw--translate-x-1:hover{
    --transform-translate-x: -0.25rem
  }

  .md\:hover\:tw--translate-x-2:hover{
    --transform-translate-x: -0.5rem
  }

  .md\:hover\:tw--translate-x-3:hover{
    --transform-translate-x: -0.75rem
  }

  .md\:hover\:tw--translate-x-4:hover{
    --transform-translate-x: -1rem
  }

  .md\:hover\:tw--translate-x-5:hover{
    --transform-translate-x: -1.25rem
  }

  .md\:hover\:tw--translate-x-6:hover{
    --transform-translate-x: -1.5rem
  }

  .md\:hover\:tw--translate-x-8:hover{
    --transform-translate-x: -2rem
  }

  .md\:hover\:tw--translate-x-10:hover{
    --transform-translate-x: -2.5rem
  }

  .md\:hover\:tw--translate-x-12:hover{
    --transform-translate-x: -3rem
  }

  .md\:hover\:tw--translate-x-16:hover{
    --transform-translate-x: -4rem
  }

  .md\:hover\:tw--translate-x-20:hover{
    --transform-translate-x: -5rem
  }

  .md\:hover\:tw--translate-x-24:hover{
    --transform-translate-x: -6rem
  }

  .md\:hover\:tw--translate-x-32:hover{
    --transform-translate-x: -8rem
  }

  .md\:hover\:tw--translate-x-40:hover{
    --transform-translate-x: -10rem
  }

  .md\:hover\:tw--translate-x-48:hover{
    --transform-translate-x: -12rem
  }

  .md\:hover\:tw--translate-x-56:hover{
    --transform-translate-x: -14rem
  }

  .md\:hover\:tw--translate-x-64:hover{
    --transform-translate-x: -16rem
  }

  .md\:hover\:tw--translate-x-px:hover{
    --transform-translate-x: -1px
  }

  .md\:hover\:tw--translate-x-full:hover{
    --transform-translate-x: -100%
  }

  .md\:hover\:tw--translate-x-1\/2:hover{
    --transform-translate-x: -50%
  }

  .md\:hover\:tw-translate-x-1\/2:hover{
    --transform-translate-x: 50%
  }

  .md\:hover\:tw-translate-x-full:hover{
    --transform-translate-x: 100%
  }

  .md\:hover\:tw-translate-y-0:hover{
    --transform-translate-y: 0
  }

  .md\:hover\:tw-translate-y-1:hover{
    --transform-translate-y: 0.25rem
  }

  .md\:hover\:tw-translate-y-2:hover{
    --transform-translate-y: 0.5rem
  }

  .md\:hover\:tw-translate-y-3:hover{
    --transform-translate-y: 0.75rem
  }

  .md\:hover\:tw-translate-y-4:hover{
    --transform-translate-y: 1rem
  }

  .md\:hover\:tw-translate-y-5:hover{
    --transform-translate-y: 1.25rem
  }

  .md\:hover\:tw-translate-y-6:hover{
    --transform-translate-y: 1.5rem
  }

  .md\:hover\:tw-translate-y-8:hover{
    --transform-translate-y: 2rem
  }

  .md\:hover\:tw-translate-y-10:hover{
    --transform-translate-y: 2.5rem
  }

  .md\:hover\:tw-translate-y-12:hover{
    --transform-translate-y: 3rem
  }

  .md\:hover\:tw-translate-y-16:hover{
    --transform-translate-y: 4rem
  }

  .md\:hover\:tw-translate-y-20:hover{
    --transform-translate-y: 5rem
  }

  .md\:hover\:tw-translate-y-24:hover{
    --transform-translate-y: 6rem
  }

  .md\:hover\:tw-translate-y-32:hover{
    --transform-translate-y: 8rem
  }

  .md\:hover\:tw-translate-y-40:hover{
    --transform-translate-y: 10rem
  }

  .md\:hover\:tw-translate-y-48:hover{
    --transform-translate-y: 12rem
  }

  .md\:hover\:tw-translate-y-56:hover{
    --transform-translate-y: 14rem
  }

  .md\:hover\:tw-translate-y-64:hover{
    --transform-translate-y: 16rem
  }

  .md\:hover\:tw-translate-y-px:hover{
    --transform-translate-y: 1px
  }

  .md\:hover\:tw--translate-y-1:hover{
    --transform-translate-y: -0.25rem
  }

  .md\:hover\:tw--translate-y-2:hover{
    --transform-translate-y: -0.5rem
  }

  .md\:hover\:tw--translate-y-3:hover{
    --transform-translate-y: -0.75rem
  }

  .md\:hover\:tw--translate-y-4:hover{
    --transform-translate-y: -1rem
  }

  .md\:hover\:tw--translate-y-5:hover{
    --transform-translate-y: -1.25rem
  }

  .md\:hover\:tw--translate-y-6:hover{
    --transform-translate-y: -1.5rem
  }

  .md\:hover\:tw--translate-y-8:hover{
    --transform-translate-y: -2rem
  }

  .md\:hover\:tw--translate-y-10:hover{
    --transform-translate-y: -2.5rem
  }

  .md\:hover\:tw--translate-y-12:hover{
    --transform-translate-y: -3rem
  }

  .md\:hover\:tw--translate-y-16:hover{
    --transform-translate-y: -4rem
  }

  .md\:hover\:tw--translate-y-20:hover{
    --transform-translate-y: -5rem
  }

  .md\:hover\:tw--translate-y-24:hover{
    --transform-translate-y: -6rem
  }

  .md\:hover\:tw--translate-y-32:hover{
    --transform-translate-y: -8rem
  }

  .md\:hover\:tw--translate-y-40:hover{
    --transform-translate-y: -10rem
  }

  .md\:hover\:tw--translate-y-48:hover{
    --transform-translate-y: -12rem
  }

  .md\:hover\:tw--translate-y-56:hover{
    --transform-translate-y: -14rem
  }

  .md\:hover\:tw--translate-y-64:hover{
    --transform-translate-y: -16rem
  }

  .md\:hover\:tw--translate-y-px:hover{
    --transform-translate-y: -1px
  }

  .md\:hover\:tw--translate-y-full:hover{
    --transform-translate-y: -100%
  }

  .md\:hover\:tw--translate-y-1\/2:hover{
    --transform-translate-y: -50%
  }

  .md\:hover\:tw-translate-y-1\/2:hover{
    --transform-translate-y: 50%
  }

  .md\:hover\:tw-translate-y-full:hover{
    --transform-translate-y: 100%
  }

  .md\:focus\:tw-translate-x-0:focus{
    --transform-translate-x: 0
  }

  .md\:focus\:tw-translate-x-1:focus{
    --transform-translate-x: 0.25rem
  }

  .md\:focus\:tw-translate-x-2:focus{
    --transform-translate-x: 0.5rem
  }

  .md\:focus\:tw-translate-x-3:focus{
    --transform-translate-x: 0.75rem
  }

  .md\:focus\:tw-translate-x-4:focus{
    --transform-translate-x: 1rem
  }

  .md\:focus\:tw-translate-x-5:focus{
    --transform-translate-x: 1.25rem
  }

  .md\:focus\:tw-translate-x-6:focus{
    --transform-translate-x: 1.5rem
  }

  .md\:focus\:tw-translate-x-8:focus{
    --transform-translate-x: 2rem
  }

  .md\:focus\:tw-translate-x-10:focus{
    --transform-translate-x: 2.5rem
  }

  .md\:focus\:tw-translate-x-12:focus{
    --transform-translate-x: 3rem
  }

  .md\:focus\:tw-translate-x-16:focus{
    --transform-translate-x: 4rem
  }

  .md\:focus\:tw-translate-x-20:focus{
    --transform-translate-x: 5rem
  }

  .md\:focus\:tw-translate-x-24:focus{
    --transform-translate-x: 6rem
  }

  .md\:focus\:tw-translate-x-32:focus{
    --transform-translate-x: 8rem
  }

  .md\:focus\:tw-translate-x-40:focus{
    --transform-translate-x: 10rem
  }

  .md\:focus\:tw-translate-x-48:focus{
    --transform-translate-x: 12rem
  }

  .md\:focus\:tw-translate-x-56:focus{
    --transform-translate-x: 14rem
  }

  .md\:focus\:tw-translate-x-64:focus{
    --transform-translate-x: 16rem
  }

  .md\:focus\:tw-translate-x-px:focus{
    --transform-translate-x: 1px
  }

  .md\:focus\:tw--translate-x-1:focus{
    --transform-translate-x: -0.25rem
  }

  .md\:focus\:tw--translate-x-2:focus{
    --transform-translate-x: -0.5rem
  }

  .md\:focus\:tw--translate-x-3:focus{
    --transform-translate-x: -0.75rem
  }

  .md\:focus\:tw--translate-x-4:focus{
    --transform-translate-x: -1rem
  }

  .md\:focus\:tw--translate-x-5:focus{
    --transform-translate-x: -1.25rem
  }

  .md\:focus\:tw--translate-x-6:focus{
    --transform-translate-x: -1.5rem
  }

  .md\:focus\:tw--translate-x-8:focus{
    --transform-translate-x: -2rem
  }

  .md\:focus\:tw--translate-x-10:focus{
    --transform-translate-x: -2.5rem
  }

  .md\:focus\:tw--translate-x-12:focus{
    --transform-translate-x: -3rem
  }

  .md\:focus\:tw--translate-x-16:focus{
    --transform-translate-x: -4rem
  }

  .md\:focus\:tw--translate-x-20:focus{
    --transform-translate-x: -5rem
  }

  .md\:focus\:tw--translate-x-24:focus{
    --transform-translate-x: -6rem
  }

  .md\:focus\:tw--translate-x-32:focus{
    --transform-translate-x: -8rem
  }

  .md\:focus\:tw--translate-x-40:focus{
    --transform-translate-x: -10rem
  }

  .md\:focus\:tw--translate-x-48:focus{
    --transform-translate-x: -12rem
  }

  .md\:focus\:tw--translate-x-56:focus{
    --transform-translate-x: -14rem
  }

  .md\:focus\:tw--translate-x-64:focus{
    --transform-translate-x: -16rem
  }

  .md\:focus\:tw--translate-x-px:focus{
    --transform-translate-x: -1px
  }

  .md\:focus\:tw--translate-x-full:focus{
    --transform-translate-x: -100%
  }

  .md\:focus\:tw--translate-x-1\/2:focus{
    --transform-translate-x: -50%
  }

  .md\:focus\:tw-translate-x-1\/2:focus{
    --transform-translate-x: 50%
  }

  .md\:focus\:tw-translate-x-full:focus{
    --transform-translate-x: 100%
  }

  .md\:focus\:tw-translate-y-0:focus{
    --transform-translate-y: 0
  }

  .md\:focus\:tw-translate-y-1:focus{
    --transform-translate-y: 0.25rem
  }

  .md\:focus\:tw-translate-y-2:focus{
    --transform-translate-y: 0.5rem
  }

  .md\:focus\:tw-translate-y-3:focus{
    --transform-translate-y: 0.75rem
  }

  .md\:focus\:tw-translate-y-4:focus{
    --transform-translate-y: 1rem
  }

  .md\:focus\:tw-translate-y-5:focus{
    --transform-translate-y: 1.25rem
  }

  .md\:focus\:tw-translate-y-6:focus{
    --transform-translate-y: 1.5rem
  }

  .md\:focus\:tw-translate-y-8:focus{
    --transform-translate-y: 2rem
  }

  .md\:focus\:tw-translate-y-10:focus{
    --transform-translate-y: 2.5rem
  }

  .md\:focus\:tw-translate-y-12:focus{
    --transform-translate-y: 3rem
  }

  .md\:focus\:tw-translate-y-16:focus{
    --transform-translate-y: 4rem
  }

  .md\:focus\:tw-translate-y-20:focus{
    --transform-translate-y: 5rem
  }

  .md\:focus\:tw-translate-y-24:focus{
    --transform-translate-y: 6rem
  }

  .md\:focus\:tw-translate-y-32:focus{
    --transform-translate-y: 8rem
  }

  .md\:focus\:tw-translate-y-40:focus{
    --transform-translate-y: 10rem
  }

  .md\:focus\:tw-translate-y-48:focus{
    --transform-translate-y: 12rem
  }

  .md\:focus\:tw-translate-y-56:focus{
    --transform-translate-y: 14rem
  }

  .md\:focus\:tw-translate-y-64:focus{
    --transform-translate-y: 16rem
  }

  .md\:focus\:tw-translate-y-px:focus{
    --transform-translate-y: 1px
  }

  .md\:focus\:tw--translate-y-1:focus{
    --transform-translate-y: -0.25rem
  }

  .md\:focus\:tw--translate-y-2:focus{
    --transform-translate-y: -0.5rem
  }

  .md\:focus\:tw--translate-y-3:focus{
    --transform-translate-y: -0.75rem
  }

  .md\:focus\:tw--translate-y-4:focus{
    --transform-translate-y: -1rem
  }

  .md\:focus\:tw--translate-y-5:focus{
    --transform-translate-y: -1.25rem
  }

  .md\:focus\:tw--translate-y-6:focus{
    --transform-translate-y: -1.5rem
  }

  .md\:focus\:tw--translate-y-8:focus{
    --transform-translate-y: -2rem
  }

  .md\:focus\:tw--translate-y-10:focus{
    --transform-translate-y: -2.5rem
  }

  .md\:focus\:tw--translate-y-12:focus{
    --transform-translate-y: -3rem
  }

  .md\:focus\:tw--translate-y-16:focus{
    --transform-translate-y: -4rem
  }

  .md\:focus\:tw--translate-y-20:focus{
    --transform-translate-y: -5rem
  }

  .md\:focus\:tw--translate-y-24:focus{
    --transform-translate-y: -6rem
  }

  .md\:focus\:tw--translate-y-32:focus{
    --transform-translate-y: -8rem
  }

  .md\:focus\:tw--translate-y-40:focus{
    --transform-translate-y: -10rem
  }

  .md\:focus\:tw--translate-y-48:focus{
    --transform-translate-y: -12rem
  }

  .md\:focus\:tw--translate-y-56:focus{
    --transform-translate-y: -14rem
  }

  .md\:focus\:tw--translate-y-64:focus{
    --transform-translate-y: -16rem
  }

  .md\:focus\:tw--translate-y-px:focus{
    --transform-translate-y: -1px
  }

  .md\:focus\:tw--translate-y-full:focus{
    --transform-translate-y: -100%
  }

  .md\:focus\:tw--translate-y-1\/2:focus{
    --transform-translate-y: -50%
  }

  .md\:focus\:tw-translate-y-1\/2:focus{
    --transform-translate-y: 50%
  }

  .md\:focus\:tw-translate-y-full:focus{
    --transform-translate-y: 100%
  }

  .md\:tw-skew-x-0{
    --transform-skew-x: 0
  }

  .md\:tw-skew-x-1{
    --transform-skew-x: 1deg
  }

  .md\:tw-skew-x-2{
    --transform-skew-x: 2deg
  }

  .md\:tw-skew-x-3{
    --transform-skew-x: 3deg
  }

  .md\:tw-skew-x-6{
    --transform-skew-x: 6deg
  }

  .md\:tw-skew-x-12{
    --transform-skew-x: 12deg
  }

  .md\:tw--skew-x-12{
    --transform-skew-x: -12deg
  }

  .md\:tw--skew-x-6{
    --transform-skew-x: -6deg
  }

  .md\:tw--skew-x-3{
    --transform-skew-x: -3deg
  }

  .md\:tw--skew-x-2{
    --transform-skew-x: -2deg
  }

  .md\:tw--skew-x-1{
    --transform-skew-x: -1deg
  }

  .md\:tw-skew-y-0{
    --transform-skew-y: 0
  }

  .md\:tw-skew-y-1{
    --transform-skew-y: 1deg
  }

  .md\:tw-skew-y-2{
    --transform-skew-y: 2deg
  }

  .md\:tw-skew-y-3{
    --transform-skew-y: 3deg
  }

  .md\:tw-skew-y-6{
    --transform-skew-y: 6deg
  }

  .md\:tw-skew-y-12{
    --transform-skew-y: 12deg
  }

  .md\:tw--skew-y-12{
    --transform-skew-y: -12deg
  }

  .md\:tw--skew-y-6{
    --transform-skew-y: -6deg
  }

  .md\:tw--skew-y-3{
    --transform-skew-y: -3deg
  }

  .md\:tw--skew-y-2{
    --transform-skew-y: -2deg
  }

  .md\:tw--skew-y-1{
    --transform-skew-y: -1deg
  }

  .md\:hover\:tw-skew-x-0:hover{
    --transform-skew-x: 0
  }

  .md\:hover\:tw-skew-x-1:hover{
    --transform-skew-x: 1deg
  }

  .md\:hover\:tw-skew-x-2:hover{
    --transform-skew-x: 2deg
  }

  .md\:hover\:tw-skew-x-3:hover{
    --transform-skew-x: 3deg
  }

  .md\:hover\:tw-skew-x-6:hover{
    --transform-skew-x: 6deg
  }

  .md\:hover\:tw-skew-x-12:hover{
    --transform-skew-x: 12deg
  }

  .md\:hover\:tw--skew-x-12:hover{
    --transform-skew-x: -12deg
  }

  .md\:hover\:tw--skew-x-6:hover{
    --transform-skew-x: -6deg
  }

  .md\:hover\:tw--skew-x-3:hover{
    --transform-skew-x: -3deg
  }

  .md\:hover\:tw--skew-x-2:hover{
    --transform-skew-x: -2deg
  }

  .md\:hover\:tw--skew-x-1:hover{
    --transform-skew-x: -1deg
  }

  .md\:hover\:tw-skew-y-0:hover{
    --transform-skew-y: 0
  }

  .md\:hover\:tw-skew-y-1:hover{
    --transform-skew-y: 1deg
  }

  .md\:hover\:tw-skew-y-2:hover{
    --transform-skew-y: 2deg
  }

  .md\:hover\:tw-skew-y-3:hover{
    --transform-skew-y: 3deg
  }

  .md\:hover\:tw-skew-y-6:hover{
    --transform-skew-y: 6deg
  }

  .md\:hover\:tw-skew-y-12:hover{
    --transform-skew-y: 12deg
  }

  .md\:hover\:tw--skew-y-12:hover{
    --transform-skew-y: -12deg
  }

  .md\:hover\:tw--skew-y-6:hover{
    --transform-skew-y: -6deg
  }

  .md\:hover\:tw--skew-y-3:hover{
    --transform-skew-y: -3deg
  }

  .md\:hover\:tw--skew-y-2:hover{
    --transform-skew-y: -2deg
  }

  .md\:hover\:tw--skew-y-1:hover{
    --transform-skew-y: -1deg
  }

  .md\:focus\:tw-skew-x-0:focus{
    --transform-skew-x: 0
  }

  .md\:focus\:tw-skew-x-1:focus{
    --transform-skew-x: 1deg
  }

  .md\:focus\:tw-skew-x-2:focus{
    --transform-skew-x: 2deg
  }

  .md\:focus\:tw-skew-x-3:focus{
    --transform-skew-x: 3deg
  }

  .md\:focus\:tw-skew-x-6:focus{
    --transform-skew-x: 6deg
  }

  .md\:focus\:tw-skew-x-12:focus{
    --transform-skew-x: 12deg
  }

  .md\:focus\:tw--skew-x-12:focus{
    --transform-skew-x: -12deg
  }

  .md\:focus\:tw--skew-x-6:focus{
    --transform-skew-x: -6deg
  }

  .md\:focus\:tw--skew-x-3:focus{
    --transform-skew-x: -3deg
  }

  .md\:focus\:tw--skew-x-2:focus{
    --transform-skew-x: -2deg
  }

  .md\:focus\:tw--skew-x-1:focus{
    --transform-skew-x: -1deg
  }

  .md\:focus\:tw-skew-y-0:focus{
    --transform-skew-y: 0
  }

  .md\:focus\:tw-skew-y-1:focus{
    --transform-skew-y: 1deg
  }

  .md\:focus\:tw-skew-y-2:focus{
    --transform-skew-y: 2deg
  }

  .md\:focus\:tw-skew-y-3:focus{
    --transform-skew-y: 3deg
  }

  .md\:focus\:tw-skew-y-6:focus{
    --transform-skew-y: 6deg
  }

  .md\:focus\:tw-skew-y-12:focus{
    --transform-skew-y: 12deg
  }

  .md\:focus\:tw--skew-y-12:focus{
    --transform-skew-y: -12deg
  }

  .md\:focus\:tw--skew-y-6:focus{
    --transform-skew-y: -6deg
  }

  .md\:focus\:tw--skew-y-3:focus{
    --transform-skew-y: -3deg
  }

  .md\:focus\:tw--skew-y-2:focus{
    --transform-skew-y: -2deg
  }

  .md\:focus\:tw--skew-y-1:focus{
    --transform-skew-y: -1deg
  }

  .md\:tw-transition-none{
    -webkit-transition-property: none;
    transition-property: none
  }

  .md\:tw-transition-all{
    -webkit-transition-property: all;
    transition-property: all
  }

  .md\:tw-transition{
    -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
  }

  .md\:tw-transition-colors{
    -webkit-transition-property: background-color, border-color, color, fill, stroke;
    transition-property: background-color, border-color, color, fill, stroke
  }

  .md\:tw-transition-opacity{
    -webkit-transition-property: opacity;
    transition-property: opacity
  }

  .md\:tw-transition-shadow{
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow
  }

  .md\:tw-transition-transform{
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
  }

  .md\:tw-ease-linear{
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear
  }

  .md\:tw-ease-in{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .md\:tw-ease-out{
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .md\:tw-ease-in-out{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .md\:tw-duration-75{
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms
  }

  .md\:tw-duration-100{
    -webkit-transition-duration: 100ms;
            transition-duration: 100ms
  }

  .md\:tw-duration-150{
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms
  }

  .md\:tw-duration-200{
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms
  }

  .md\:tw-duration-300{
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms
  }

  .md\:tw-duration-500{
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms
  }

  .md\:tw-duration-700{
    -webkit-transition-duration: 700ms;
            transition-duration: 700ms
  }

  .md\:tw-duration-1000{
    -webkit-transition-duration: 1000ms;
            transition-duration: 1000ms
  }

  .md\:tw-delay-75{
    -webkit-transition-delay: 75ms;
            transition-delay: 75ms
  }

  .md\:tw-delay-100{
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms
  }

  .md\:tw-delay-150{
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms
  }

  .md\:tw-delay-200{
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms
  }

  .md\:tw-delay-300{
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms
  }

  .md\:tw-delay-500{
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms
  }

  .md\:tw-delay-700{
    -webkit-transition-delay: 700ms;
            transition-delay: 700ms
  }

  .md\:tw-delay-1000{
    -webkit-transition-delay: 1000ms;
            transition-delay: 1000ms
  }

  .md\:tw-animate-none{
    -webkit-animation: none;
            animation: none
  }

  .md\:tw-animate-spin{
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .md\:tw-animate-ping{
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .md\:tw-animate-pulse{
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .md\:tw-animate-bounce{
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

@media (min-width: 1024px){
  .lg\:tw-container{
    width: 100%
  }

  @media (min-width: 640px){
    .lg\:tw-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .lg\:tw-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .lg\:tw-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .lg\:tw-container{
      max-width: 1280px
    }
  }

  .lg\:tw-space-y-0 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .lg\:tw-space-x-0 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .lg\:tw-space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .lg\:tw-space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .lg\:tw--space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw--space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .lg\:tw--space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:tw-space-y-reverse > :not(template) ~ :not(template){
    --space-y-reverse: 1
  }

  .lg\:tw-space-x-reverse > :not(template) ~ :not(template){
    --space-x-reverse: 1
  }

  .lg\:tw-divide-y-0 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .lg\:tw-divide-x-0 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:tw-divide-y-2 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .lg\:tw-divide-x-2 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:tw-divide-y-4 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .lg\:tw-divide-x-4 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:tw-divide-y-8 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .lg\:tw-divide-x-8 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:tw-divide-y > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .lg\:tw-divide-x > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:tw-divide-y-reverse > :not(template) ~ :not(template){
    --divide-y-reverse: 1
  }

  .lg\:tw-divide-x-reverse > :not(template) ~ :not(template){
    --divide-x-reverse: 1
  }

  .lg\:tw-divide-transparent > :not(template) ~ :not(template){
    border-color: transparent
  }

  .lg\:tw-divide-tlg-green-dark > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--divide-opacity))
  }

  .lg\:tw-divide-tlg-green > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--divide-opacity))
  }

  .lg\:tw-divide-tlg-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--divide-opacity))
  }

  .lg\:tw-divide-black > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .lg\:tw-divide-white > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .lg\:tw-divide-gray-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .lg\:tw-divide-red-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .lg\:tw-divide-red-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .lg\:tw-divide-red-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .lg\:tw-divide-red-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .lg\:tw-divide-red-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .lg\:tw-divide-red-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .lg\:tw-divide-red-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .lg\:tw-divide-red-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .lg\:tw-divide-red-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .lg\:tw-divide-orange-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .lg\:tw-divide-yellow-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .lg\:tw-divide-green-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .lg\:tw-divide-green-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .lg\:tw-divide-green-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .lg\:tw-divide-green-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .lg\:tw-divide-green-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .lg\:tw-divide-green-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .lg\:tw-divide-green-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .lg\:tw-divide-green-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .lg\:tw-divide-green-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .lg\:tw-divide-teal-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .lg\:tw-divide-blue-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .lg\:tw-divide-indigo-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .lg\:tw-divide-purple-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .lg\:tw-divide-pink-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .lg\:tw-divide-solid > :not(template) ~ :not(template){
    border-style: solid
  }

  .lg\:tw-divide-dashed > :not(template) ~ :not(template){
    border-style: dashed
  }

  .lg\:tw-divide-dotted > :not(template) ~ :not(template){
    border-style: dotted
  }

  .lg\:tw-divide-double > :not(template) ~ :not(template){
    border-style: double
  }

  .lg\:tw-divide-none > :not(template) ~ :not(template){
    border-style: none
  }

  .lg\:tw-divide-opacity-0 > :not(template) ~ :not(template){
    --divide-opacity: 0
  }

  .lg\:tw-divide-opacity-25 > :not(template) ~ :not(template){
    --divide-opacity: 0.25
  }

  .lg\:tw-divide-opacity-50 > :not(template) ~ :not(template){
    --divide-opacity: 0.5
  }

  .lg\:tw-divide-opacity-75 > :not(template) ~ :not(template){
    --divide-opacity: 0.75
  }

  .lg\:tw-divide-opacity-100 > :not(template) ~ :not(template){
    --divide-opacity: 1
  }

  .lg\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:tw-appearance-none{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .lg\:tw-bg-fixed{
    background-attachment: fixed
  }

  .lg\:tw-bg-local{
    background-attachment: local
  }

  .lg\:tw-bg-scroll{
    background-attachment: scroll
  }

  .lg\:tw-bg-clip-border{
    background-clip: border-box
  }

  .lg\:tw-bg-clip-padding{
    background-clip: padding-box
  }

  .lg\:tw-bg-clip-content{
    background-clip: content-box
  }

  .lg\:tw-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  .lg\:tw-bg-transparent{
    background-color: transparent
  }

  .lg\:tw-bg-tlg-green-dark{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .lg\:tw-bg-tlg-green{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .lg\:tw-bg-tlg-orange-500{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .lg\:tw-bg-black{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:tw-bg-white{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-100{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-200{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-300{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-400{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-500{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-600{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-700{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-800{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .lg\:tw-bg-gray-900{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .lg\:tw-bg-red-100{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .lg\:tw-bg-red-200{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .lg\:tw-bg-red-300{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .lg\:tw-bg-red-400{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .lg\:tw-bg-red-500{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .lg\:tw-bg-red-600{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .lg\:tw-bg-red-700{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .lg\:tw-bg-red-800{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .lg\:tw-bg-red-900{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-100{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-200{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-300{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-400{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-500{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-600{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-700{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-800{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .lg\:tw-bg-orange-900{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-100{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-200{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-300{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-400{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-500{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-600{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-700{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-800{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .lg\:tw-bg-yellow-900{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .lg\:tw-bg-green-100{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .lg\:tw-bg-green-200{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .lg\:tw-bg-green-300{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .lg\:tw-bg-green-400{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .lg\:tw-bg-green-500{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .lg\:tw-bg-green-600{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .lg\:tw-bg-green-700{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .lg\:tw-bg-green-800{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .lg\:tw-bg-green-900{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-100{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-200{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-300{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-400{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-500{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-600{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-700{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-800{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .lg\:tw-bg-teal-900{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-100{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-200{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-300{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-400{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-500{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-600{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-700{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-800{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .lg\:tw-bg-blue-900{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-100{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-200{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-300{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-400{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-500{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-600{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-700{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-800{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .lg\:tw-bg-indigo-900{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-100{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-200{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-300{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-400{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-500{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-600{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-700{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-800{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .lg\:tw-bg-purple-900{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-100{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-200{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-300{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-400{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-500{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-600{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-700{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-800{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .lg\:tw-bg-pink-900{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-transparent:hover{
    background-color: transparent
  }

  .lg\:hover\:tw-bg-tlg-green-dark:hover{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-tlg-green:hover{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-tlg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-black:hover{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-white:hover{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-100:hover{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-200:hover{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-300:hover{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-400:hover{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-500:hover{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-600:hover{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-700:hover{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-800:hover{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-gray-900:hover{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-200:hover{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-300:hover{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-400:hover{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-500:hover{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-600:hover{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-700:hover{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-800:hover{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-red-900:hover{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-100:hover{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-200:hover{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-300:hover{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-400:hover{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-600:hover{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-700:hover{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-800:hover{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-orange-900:hover{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-100:hover{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-200:hover{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-300:hover{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-400:hover{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-500:hover{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-600:hover{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-700:hover{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-800:hover{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-yellow-900:hover{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-100:hover{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-200:hover{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-300:hover{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-400:hover{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-500:hover{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-600:hover{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-700:hover{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-800:hover{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-green-900:hover{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-100:hover{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-200:hover{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-300:hover{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-400:hover{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-500:hover{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-600:hover{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-700:hover{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-800:hover{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-teal-900:hover{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-100:hover{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-200:hover{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-300:hover{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-400:hover{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-500:hover{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-600:hover{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-700:hover{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-800:hover{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-blue-900:hover{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-100:hover{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-200:hover{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-300:hover{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-400:hover{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-500:hover{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-600:hover{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-700:hover{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-800:hover{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-indigo-900:hover{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-100:hover{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-200:hover{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-300:hover{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-400:hover{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-500:hover{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-600:hover{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-700:hover{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-800:hover{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-purple-900:hover{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-200:hover{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-300:hover{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-400:hover{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-500:hover{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-600:hover{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-700:hover{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-800:hover{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .lg\:hover\:tw-bg-pink-900:hover{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-transparent:focus{
    background-color: transparent
  }

  .lg\:focus\:tw-bg-tlg-green-dark:focus{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-tlg-green:focus{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-tlg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-black:focus{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-white:focus{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-100:focus{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-200:focus{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-300:focus{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-400:focus{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-500:focus{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-600:focus{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-700:focus{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-800:focus{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-gray-900:focus{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-200:focus{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-300:focus{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-400:focus{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-500:focus{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-600:focus{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-700:focus{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-800:focus{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-red-900:focus{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-100:focus{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-200:focus{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-300:focus{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-400:focus{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-600:focus{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-700:focus{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-800:focus{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-orange-900:focus{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-100:focus{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-200:focus{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-300:focus{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-400:focus{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-500:focus{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-600:focus{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-700:focus{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-800:focus{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-yellow-900:focus{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-100:focus{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-200:focus{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-300:focus{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-400:focus{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-500:focus{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-600:focus{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-700:focus{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-800:focus{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-green-900:focus{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-100:focus{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-200:focus{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-300:focus{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-400:focus{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-500:focus{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-600:focus{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-700:focus{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-800:focus{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-teal-900:focus{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-100:focus{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-200:focus{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-300:focus{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-400:focus{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-500:focus{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-600:focus{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-700:focus{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-800:focus{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-blue-900:focus{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-100:focus{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-200:focus{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-300:focus{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-400:focus{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-500:focus{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-600:focus{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-700:focus{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-800:focus{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-indigo-900:focus{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-100:focus{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-200:focus{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-300:focus{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-400:focus{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-500:focus{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-600:focus{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-700:focus{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-800:focus{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-purple-900:focus{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-200:focus{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-300:focus{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-400:focus{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-500:focus{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-600:focus{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-700:focus{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-800:focus{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .lg\:focus\:tw-bg-pink-900:focus{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .lg\:tw-bg-none{
    background-image: none
  }

  .lg\:tw-bg-gradient-to-t{
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom, var(--gradient-color-stops));
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-tr{
    background-image: -webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom left, var(--gradient-color-stops));
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-r{
    background-image: -webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(left, var(--gradient-color-stops));
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-br{
    background-image: -webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top left, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-b{
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-bl{
    background-image: -webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top right, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-l{
    background-image: -webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(right, var(--gradient-color-stops));
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .lg\:tw-bg-gradient-to-tl{
    background-image: -webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom right, var(--gradient-color-stops));
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .lg\:tw-from-transparent{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:tw-from-tlg-green-dark{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .lg\:tw-from-tlg-green{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .lg\:tw-from-tlg-orange-500{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .lg\:tw-from-black{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:tw-from-white{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:tw-from-gray-100{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:tw-from-gray-200{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:tw-from-gray-300{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:tw-from-gray-400{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:tw-from-gray-500{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:tw-from-gray-600{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:tw-from-gray-700{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:tw-from-gray-800{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:tw-from-gray-900{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:tw-from-red-100{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:tw-from-red-200{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:tw-from-red-300{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:tw-from-red-400{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:tw-from-red-500{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:tw-from-red-600{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:tw-from-red-700{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:tw-from-red-800{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:tw-from-red-900{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:tw-from-orange-100{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:tw-from-orange-200{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:tw-from-orange-300{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:tw-from-orange-400{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:tw-from-orange-500{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:tw-from-orange-600{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:tw-from-orange-700{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:tw-from-orange-800{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:tw-from-orange-900{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:tw-from-yellow-100{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:tw-from-yellow-200{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:tw-from-yellow-300{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:tw-from-yellow-400{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:tw-from-yellow-500{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:tw-from-yellow-600{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:tw-from-yellow-700{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:tw-from-yellow-800{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:tw-from-yellow-900{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:tw-from-green-100{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:tw-from-green-200{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:tw-from-green-300{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:tw-from-green-400{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:tw-from-green-500{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:tw-from-green-600{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:tw-from-green-700{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:tw-from-green-800{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:tw-from-green-900{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:tw-from-teal-100{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:tw-from-teal-200{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:tw-from-teal-300{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:tw-from-teal-400{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:tw-from-teal-500{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:tw-from-teal-600{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:tw-from-teal-700{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:tw-from-teal-800{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:tw-from-teal-900{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:tw-from-blue-100{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:tw-from-blue-200{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:tw-from-blue-300{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:tw-from-blue-400{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:tw-from-blue-500{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:tw-from-blue-600{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:tw-from-blue-700{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:tw-from-blue-800{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:tw-from-blue-900{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:tw-from-indigo-100{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:tw-from-indigo-200{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:tw-from-indigo-300{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:tw-from-indigo-400{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:tw-from-indigo-500{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:tw-from-indigo-600{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:tw-from-indigo-700{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:tw-from-indigo-800{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:tw-from-indigo-900{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:tw-from-purple-100{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:tw-from-purple-200{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:tw-from-purple-300{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:tw-from-purple-400{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:tw-from-purple-500{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:tw-from-purple-600{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:tw-from-purple-700{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:tw-from-purple-800{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:tw-from-purple-900{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:tw-from-pink-100{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:tw-from-pink-200{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:tw-from-pink-300{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:tw-from-pink-400{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:tw-from-pink-500{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:tw-from-pink-600{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:tw-from-pink-700{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:tw-from-pink-800{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:tw-from-pink-900{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:tw-via-transparent{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:tw-via-tlg-green-dark{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .lg\:tw-via-tlg-green{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .lg\:tw-via-tlg-orange-500{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .lg\:tw-via-black{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:tw-via-white{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:tw-via-gray-100{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:tw-via-gray-200{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:tw-via-gray-300{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:tw-via-gray-400{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:tw-via-gray-500{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:tw-via-gray-600{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:tw-via-gray-700{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:tw-via-gray-800{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:tw-via-gray-900{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:tw-via-red-100{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:tw-via-red-200{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:tw-via-red-300{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:tw-via-red-400{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:tw-via-red-500{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:tw-via-red-600{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:tw-via-red-700{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:tw-via-red-800{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:tw-via-red-900{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:tw-via-orange-100{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:tw-via-orange-200{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:tw-via-orange-300{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:tw-via-orange-400{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:tw-via-orange-500{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:tw-via-orange-600{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:tw-via-orange-700{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:tw-via-orange-800{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:tw-via-orange-900{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:tw-via-yellow-100{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:tw-via-yellow-200{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:tw-via-yellow-300{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:tw-via-yellow-400{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:tw-via-yellow-500{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:tw-via-yellow-600{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:tw-via-yellow-700{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:tw-via-yellow-800{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:tw-via-yellow-900{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:tw-via-green-100{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:tw-via-green-200{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:tw-via-green-300{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:tw-via-green-400{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:tw-via-green-500{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:tw-via-green-600{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:tw-via-green-700{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:tw-via-green-800{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:tw-via-green-900{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:tw-via-teal-100{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:tw-via-teal-200{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:tw-via-teal-300{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:tw-via-teal-400{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:tw-via-teal-500{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:tw-via-teal-600{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:tw-via-teal-700{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:tw-via-teal-800{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:tw-via-teal-900{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:tw-via-blue-100{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:tw-via-blue-200{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:tw-via-blue-300{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:tw-via-blue-400{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:tw-via-blue-500{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:tw-via-blue-600{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:tw-via-blue-700{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:tw-via-blue-800{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:tw-via-blue-900{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:tw-via-indigo-100{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:tw-via-indigo-200{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:tw-via-indigo-300{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:tw-via-indigo-400{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:tw-via-indigo-500{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:tw-via-indigo-600{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:tw-via-indigo-700{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:tw-via-indigo-800{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:tw-via-indigo-900{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:tw-via-purple-100{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:tw-via-purple-200{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:tw-via-purple-300{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:tw-via-purple-400{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:tw-via-purple-500{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:tw-via-purple-600{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:tw-via-purple-700{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:tw-via-purple-800{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:tw-via-purple-900{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:tw-via-pink-100{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:tw-via-pink-200{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:tw-via-pink-300{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:tw-via-pink-400{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:tw-via-pink-500{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:tw-via-pink-600{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:tw-via-pink-700{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:tw-via-pink-800{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:tw-via-pink-900{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:tw-to-transparent{
    --gradient-to-color: transparent
  }

  .lg\:tw-to-tlg-green-dark{
    --gradient-to-color: #76b84e
  }

  .lg\:tw-to-tlg-green{
    --gradient-to-color: #a4ca61
  }

  .lg\:tw-to-tlg-orange-500{
    --gradient-to-color: #ff7f41
  }

  .lg\:tw-to-black{
    --gradient-to-color: #000
  }

  .lg\:tw-to-white{
    --gradient-to-color: #fff
  }

  .lg\:tw-to-gray-100{
    --gradient-to-color: #f7fafc
  }

  .lg\:tw-to-gray-200{
    --gradient-to-color: #edf2f7
  }

  .lg\:tw-to-gray-300{
    --gradient-to-color: #e2e8f0
  }

  .lg\:tw-to-gray-400{
    --gradient-to-color: #cbd5e0
  }

  .lg\:tw-to-gray-500{
    --gradient-to-color: #a0aec0
  }

  .lg\:tw-to-gray-600{
    --gradient-to-color: #718096
  }

  .lg\:tw-to-gray-700{
    --gradient-to-color: #4a5568
  }

  .lg\:tw-to-gray-800{
    --gradient-to-color: #2d3748
  }

  .lg\:tw-to-gray-900{
    --gradient-to-color: #1a202c
  }

  .lg\:tw-to-red-100{
    --gradient-to-color: #fff5f5
  }

  .lg\:tw-to-red-200{
    --gradient-to-color: #fed7d7
  }

  .lg\:tw-to-red-300{
    --gradient-to-color: #feb2b2
  }

  .lg\:tw-to-red-400{
    --gradient-to-color: #fc8181
  }

  .lg\:tw-to-red-500{
    --gradient-to-color: #f56565
  }

  .lg\:tw-to-red-600{
    --gradient-to-color: #e53e3e
  }

  .lg\:tw-to-red-700{
    --gradient-to-color: #c53030
  }

  .lg\:tw-to-red-800{
    --gradient-to-color: #9b2c2c
  }

  .lg\:tw-to-red-900{
    --gradient-to-color: #742a2a
  }

  .lg\:tw-to-orange-100{
    --gradient-to-color: #fffaf0
  }

  .lg\:tw-to-orange-200{
    --gradient-to-color: #feebc8
  }

  .lg\:tw-to-orange-300{
    --gradient-to-color: #fbd38d
  }

  .lg\:tw-to-orange-400{
    --gradient-to-color: #f6ad55
  }

  .lg\:tw-to-orange-500{
    --gradient-to-color: #ed8936
  }

  .lg\:tw-to-orange-600{
    --gradient-to-color: #dd6b20
  }

  .lg\:tw-to-orange-700{
    --gradient-to-color: #c05621
  }

  .lg\:tw-to-orange-800{
    --gradient-to-color: #9c4221
  }

  .lg\:tw-to-orange-900{
    --gradient-to-color: #7b341e
  }

  .lg\:tw-to-yellow-100{
    --gradient-to-color: #fffff0
  }

  .lg\:tw-to-yellow-200{
    --gradient-to-color: #fefcbf
  }

  .lg\:tw-to-yellow-300{
    --gradient-to-color: #faf089
  }

  .lg\:tw-to-yellow-400{
    --gradient-to-color: #f6e05e
  }

  .lg\:tw-to-yellow-500{
    --gradient-to-color: #ecc94b
  }

  .lg\:tw-to-yellow-600{
    --gradient-to-color: #d69e2e
  }

  .lg\:tw-to-yellow-700{
    --gradient-to-color: #b7791f
  }

  .lg\:tw-to-yellow-800{
    --gradient-to-color: #975a16
  }

  .lg\:tw-to-yellow-900{
    --gradient-to-color: #744210
  }

  .lg\:tw-to-green-100{
    --gradient-to-color: #f0fff4
  }

  .lg\:tw-to-green-200{
    --gradient-to-color: #c6f6d5
  }

  .lg\:tw-to-green-300{
    --gradient-to-color: #9ae6b4
  }

  .lg\:tw-to-green-400{
    --gradient-to-color: #68d391
  }

  .lg\:tw-to-green-500{
    --gradient-to-color: #48bb78
  }

  .lg\:tw-to-green-600{
    --gradient-to-color: #38a169
  }

  .lg\:tw-to-green-700{
    --gradient-to-color: #2f855a
  }

  .lg\:tw-to-green-800{
    --gradient-to-color: #276749
  }

  .lg\:tw-to-green-900{
    --gradient-to-color: #22543d
  }

  .lg\:tw-to-teal-100{
    --gradient-to-color: #e6fffa
  }

  .lg\:tw-to-teal-200{
    --gradient-to-color: #b2f5ea
  }

  .lg\:tw-to-teal-300{
    --gradient-to-color: #81e6d9
  }

  .lg\:tw-to-teal-400{
    --gradient-to-color: #4fd1c5
  }

  .lg\:tw-to-teal-500{
    --gradient-to-color: #38b2ac
  }

  .lg\:tw-to-teal-600{
    --gradient-to-color: #319795
  }

  .lg\:tw-to-teal-700{
    --gradient-to-color: #2c7a7b
  }

  .lg\:tw-to-teal-800{
    --gradient-to-color: #285e61
  }

  .lg\:tw-to-teal-900{
    --gradient-to-color: #234e52
  }

  .lg\:tw-to-blue-100{
    --gradient-to-color: #ebf8ff
  }

  .lg\:tw-to-blue-200{
    --gradient-to-color: #bee3f8
  }

  .lg\:tw-to-blue-300{
    --gradient-to-color: #90cdf4
  }

  .lg\:tw-to-blue-400{
    --gradient-to-color: #63b3ed
  }

  .lg\:tw-to-blue-500{
    --gradient-to-color: #4299e1
  }

  .lg\:tw-to-blue-600{
    --gradient-to-color: #3182ce
  }

  .lg\:tw-to-blue-700{
    --gradient-to-color: #2b6cb0
  }

  .lg\:tw-to-blue-800{
    --gradient-to-color: #2c5282
  }

  .lg\:tw-to-blue-900{
    --gradient-to-color: #2a4365
  }

  .lg\:tw-to-indigo-100{
    --gradient-to-color: #ebf4ff
  }

  .lg\:tw-to-indigo-200{
    --gradient-to-color: #c3dafe
  }

  .lg\:tw-to-indigo-300{
    --gradient-to-color: #a3bffa
  }

  .lg\:tw-to-indigo-400{
    --gradient-to-color: #7f9cf5
  }

  .lg\:tw-to-indigo-500{
    --gradient-to-color: #667eea
  }

  .lg\:tw-to-indigo-600{
    --gradient-to-color: #5a67d8
  }

  .lg\:tw-to-indigo-700{
    --gradient-to-color: #4c51bf
  }

  .lg\:tw-to-indigo-800{
    --gradient-to-color: #434190
  }

  .lg\:tw-to-indigo-900{
    --gradient-to-color: #3c366b
  }

  .lg\:tw-to-purple-100{
    --gradient-to-color: #faf5ff
  }

  .lg\:tw-to-purple-200{
    --gradient-to-color: #e9d8fd
  }

  .lg\:tw-to-purple-300{
    --gradient-to-color: #d6bcfa
  }

  .lg\:tw-to-purple-400{
    --gradient-to-color: #b794f4
  }

  .lg\:tw-to-purple-500{
    --gradient-to-color: #9f7aea
  }

  .lg\:tw-to-purple-600{
    --gradient-to-color: #805ad5
  }

  .lg\:tw-to-purple-700{
    --gradient-to-color: #6b46c1
  }

  .lg\:tw-to-purple-800{
    --gradient-to-color: #553c9a
  }

  .lg\:tw-to-purple-900{
    --gradient-to-color: #44337a
  }

  .lg\:tw-to-pink-100{
    --gradient-to-color: #fff5f7
  }

  .lg\:tw-to-pink-200{
    --gradient-to-color: #fed7e2
  }

  .lg\:tw-to-pink-300{
    --gradient-to-color: #fbb6ce
  }

  .lg\:tw-to-pink-400{
    --gradient-to-color: #f687b3
  }

  .lg\:tw-to-pink-500{
    --gradient-to-color: #ed64a6
  }

  .lg\:tw-to-pink-600{
    --gradient-to-color: #d53f8c
  }

  .lg\:tw-to-pink-700{
    --gradient-to-color: #b83280
  }

  .lg\:tw-to-pink-800{
    --gradient-to-color: #97266d
  }

  .lg\:tw-to-pink-900{
    --gradient-to-color: #702459
  }

  .lg\:hover\:tw-from-transparent:hover{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:tw-from-tlg-green-dark:hover{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .lg\:hover\:tw-from-tlg-green:hover{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .lg\:hover\:tw-from-tlg-orange-500:hover{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .lg\:hover\:tw-from-black:hover{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:tw-from-white:hover{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:tw-from-gray-100:hover{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:hover\:tw-from-gray-200:hover{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:hover\:tw-from-gray-300:hover{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:hover\:tw-from-gray-400:hover{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:hover\:tw-from-gray-500:hover{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:hover\:tw-from-gray-600:hover{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:hover\:tw-from-gray-700:hover{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:hover\:tw-from-gray-800:hover{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:hover\:tw-from-gray-900:hover{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:hover\:tw-from-red-100:hover{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:hover\:tw-from-red-200:hover{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:hover\:tw-from-red-300:hover{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:hover\:tw-from-red-400:hover{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:hover\:tw-from-red-500:hover{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:hover\:tw-from-red-600:hover{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:hover\:tw-from-red-700:hover{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:hover\:tw-from-red-800:hover{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:hover\:tw-from-red-900:hover{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:hover\:tw-from-orange-100:hover{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:hover\:tw-from-orange-200:hover{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:hover\:tw-from-orange-300:hover{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:hover\:tw-from-orange-400:hover{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:hover\:tw-from-orange-500:hover{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:hover\:tw-from-orange-600:hover{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:hover\:tw-from-orange-700:hover{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:hover\:tw-from-orange-800:hover{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:hover\:tw-from-orange-900:hover{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:hover\:tw-from-yellow-100:hover{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:hover\:tw-from-yellow-200:hover{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:hover\:tw-from-yellow-300:hover{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:hover\:tw-from-yellow-400:hover{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:hover\:tw-from-yellow-500:hover{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:hover\:tw-from-yellow-600:hover{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:hover\:tw-from-yellow-700:hover{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:hover\:tw-from-yellow-800:hover{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:hover\:tw-from-yellow-900:hover{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:hover\:tw-from-green-100:hover{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:hover\:tw-from-green-200:hover{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:hover\:tw-from-green-300:hover{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:hover\:tw-from-green-400:hover{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:hover\:tw-from-green-500:hover{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:hover\:tw-from-green-600:hover{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:hover\:tw-from-green-700:hover{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:hover\:tw-from-green-800:hover{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:hover\:tw-from-green-900:hover{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:hover\:tw-from-teal-100:hover{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:hover\:tw-from-teal-200:hover{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:hover\:tw-from-teal-300:hover{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:hover\:tw-from-teal-400:hover{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:hover\:tw-from-teal-500:hover{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:hover\:tw-from-teal-600:hover{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:hover\:tw-from-teal-700:hover{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:hover\:tw-from-teal-800:hover{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:hover\:tw-from-teal-900:hover{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:hover\:tw-from-blue-100:hover{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:hover\:tw-from-blue-200:hover{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:hover\:tw-from-blue-300:hover{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:hover\:tw-from-blue-400:hover{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:hover\:tw-from-blue-500:hover{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:hover\:tw-from-blue-600:hover{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:hover\:tw-from-blue-700:hover{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:hover\:tw-from-blue-800:hover{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:hover\:tw-from-blue-900:hover{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:hover\:tw-from-indigo-100:hover{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:hover\:tw-from-indigo-200:hover{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:hover\:tw-from-indigo-300:hover{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:hover\:tw-from-indigo-400:hover{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:hover\:tw-from-indigo-500:hover{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:hover\:tw-from-indigo-600:hover{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:hover\:tw-from-indigo-700:hover{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:hover\:tw-from-indigo-800:hover{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:hover\:tw-from-indigo-900:hover{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:hover\:tw-from-purple-100:hover{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:hover\:tw-from-purple-200:hover{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:hover\:tw-from-purple-300:hover{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:hover\:tw-from-purple-400:hover{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:hover\:tw-from-purple-500:hover{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:hover\:tw-from-purple-600:hover{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:hover\:tw-from-purple-700:hover{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:hover\:tw-from-purple-800:hover{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:hover\:tw-from-purple-900:hover{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:hover\:tw-from-pink-100:hover{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:hover\:tw-from-pink-200:hover{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:hover\:tw-from-pink-300:hover{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:hover\:tw-from-pink-400:hover{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:hover\:tw-from-pink-500:hover{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:hover\:tw-from-pink-600:hover{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:hover\:tw-from-pink-700:hover{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:hover\:tw-from-pink-800:hover{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:hover\:tw-from-pink-900:hover{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:hover\:tw-via-transparent:hover{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:tw-via-tlg-green-dark:hover{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .lg\:hover\:tw-via-tlg-green:hover{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .lg\:hover\:tw-via-tlg-orange-500:hover{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .lg\:hover\:tw-via-black:hover{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:tw-via-white:hover{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:tw-via-gray-100:hover{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:hover\:tw-via-gray-200:hover{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:hover\:tw-via-gray-300:hover{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:hover\:tw-via-gray-400:hover{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:hover\:tw-via-gray-500:hover{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:hover\:tw-via-gray-600:hover{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:hover\:tw-via-gray-700:hover{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:hover\:tw-via-gray-800:hover{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:hover\:tw-via-gray-900:hover{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:hover\:tw-via-red-100:hover{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:hover\:tw-via-red-200:hover{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:hover\:tw-via-red-300:hover{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:hover\:tw-via-red-400:hover{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:hover\:tw-via-red-500:hover{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:hover\:tw-via-red-600:hover{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:hover\:tw-via-red-700:hover{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:hover\:tw-via-red-800:hover{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:hover\:tw-via-red-900:hover{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:hover\:tw-via-orange-100:hover{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:hover\:tw-via-orange-200:hover{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:hover\:tw-via-orange-300:hover{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:hover\:tw-via-orange-400:hover{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:hover\:tw-via-orange-500:hover{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:hover\:tw-via-orange-600:hover{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:hover\:tw-via-orange-700:hover{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:hover\:tw-via-orange-800:hover{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:hover\:tw-via-orange-900:hover{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:hover\:tw-via-yellow-100:hover{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:hover\:tw-via-yellow-200:hover{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:hover\:tw-via-yellow-300:hover{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:hover\:tw-via-yellow-400:hover{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:hover\:tw-via-yellow-500:hover{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:hover\:tw-via-yellow-600:hover{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:hover\:tw-via-yellow-700:hover{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:hover\:tw-via-yellow-800:hover{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:hover\:tw-via-yellow-900:hover{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:hover\:tw-via-green-100:hover{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:hover\:tw-via-green-200:hover{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:hover\:tw-via-green-300:hover{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:hover\:tw-via-green-400:hover{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:hover\:tw-via-green-500:hover{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:hover\:tw-via-green-600:hover{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:hover\:tw-via-green-700:hover{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:hover\:tw-via-green-800:hover{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:hover\:tw-via-green-900:hover{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:hover\:tw-via-teal-100:hover{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:hover\:tw-via-teal-200:hover{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:hover\:tw-via-teal-300:hover{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:hover\:tw-via-teal-400:hover{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:hover\:tw-via-teal-500:hover{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:hover\:tw-via-teal-600:hover{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:hover\:tw-via-teal-700:hover{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:hover\:tw-via-teal-800:hover{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:hover\:tw-via-teal-900:hover{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:hover\:tw-via-blue-100:hover{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:hover\:tw-via-blue-200:hover{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:hover\:tw-via-blue-300:hover{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:hover\:tw-via-blue-400:hover{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:hover\:tw-via-blue-500:hover{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:hover\:tw-via-blue-600:hover{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:hover\:tw-via-blue-700:hover{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:hover\:tw-via-blue-800:hover{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:hover\:tw-via-blue-900:hover{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:hover\:tw-via-indigo-100:hover{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:hover\:tw-via-indigo-200:hover{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:hover\:tw-via-indigo-300:hover{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:hover\:tw-via-indigo-400:hover{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:hover\:tw-via-indigo-500:hover{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:hover\:tw-via-indigo-600:hover{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:hover\:tw-via-indigo-700:hover{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:hover\:tw-via-indigo-800:hover{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:hover\:tw-via-indigo-900:hover{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:hover\:tw-via-purple-100:hover{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:hover\:tw-via-purple-200:hover{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:hover\:tw-via-purple-300:hover{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:hover\:tw-via-purple-400:hover{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:hover\:tw-via-purple-500:hover{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:hover\:tw-via-purple-600:hover{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:hover\:tw-via-purple-700:hover{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:hover\:tw-via-purple-800:hover{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:hover\:tw-via-purple-900:hover{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:hover\:tw-via-pink-100:hover{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:hover\:tw-via-pink-200:hover{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:hover\:tw-via-pink-300:hover{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:hover\:tw-via-pink-400:hover{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:hover\:tw-via-pink-500:hover{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:hover\:tw-via-pink-600:hover{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:hover\:tw-via-pink-700:hover{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:hover\:tw-via-pink-800:hover{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:hover\:tw-via-pink-900:hover{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:hover\:tw-to-transparent:hover{
    --gradient-to-color: transparent
  }

  .lg\:hover\:tw-to-tlg-green-dark:hover{
    --gradient-to-color: #76b84e
  }

  .lg\:hover\:tw-to-tlg-green:hover{
    --gradient-to-color: #a4ca61
  }

  .lg\:hover\:tw-to-tlg-orange-500:hover{
    --gradient-to-color: #ff7f41
  }

  .lg\:hover\:tw-to-black:hover{
    --gradient-to-color: #000
  }

  .lg\:hover\:tw-to-white:hover{
    --gradient-to-color: #fff
  }

  .lg\:hover\:tw-to-gray-100:hover{
    --gradient-to-color: #f7fafc
  }

  .lg\:hover\:tw-to-gray-200:hover{
    --gradient-to-color: #edf2f7
  }

  .lg\:hover\:tw-to-gray-300:hover{
    --gradient-to-color: #e2e8f0
  }

  .lg\:hover\:tw-to-gray-400:hover{
    --gradient-to-color: #cbd5e0
  }

  .lg\:hover\:tw-to-gray-500:hover{
    --gradient-to-color: #a0aec0
  }

  .lg\:hover\:tw-to-gray-600:hover{
    --gradient-to-color: #718096
  }

  .lg\:hover\:tw-to-gray-700:hover{
    --gradient-to-color: #4a5568
  }

  .lg\:hover\:tw-to-gray-800:hover{
    --gradient-to-color: #2d3748
  }

  .lg\:hover\:tw-to-gray-900:hover{
    --gradient-to-color: #1a202c
  }

  .lg\:hover\:tw-to-red-100:hover{
    --gradient-to-color: #fff5f5
  }

  .lg\:hover\:tw-to-red-200:hover{
    --gradient-to-color: #fed7d7
  }

  .lg\:hover\:tw-to-red-300:hover{
    --gradient-to-color: #feb2b2
  }

  .lg\:hover\:tw-to-red-400:hover{
    --gradient-to-color: #fc8181
  }

  .lg\:hover\:tw-to-red-500:hover{
    --gradient-to-color: #f56565
  }

  .lg\:hover\:tw-to-red-600:hover{
    --gradient-to-color: #e53e3e
  }

  .lg\:hover\:tw-to-red-700:hover{
    --gradient-to-color: #c53030
  }

  .lg\:hover\:tw-to-red-800:hover{
    --gradient-to-color: #9b2c2c
  }

  .lg\:hover\:tw-to-red-900:hover{
    --gradient-to-color: #742a2a
  }

  .lg\:hover\:tw-to-orange-100:hover{
    --gradient-to-color: #fffaf0
  }

  .lg\:hover\:tw-to-orange-200:hover{
    --gradient-to-color: #feebc8
  }

  .lg\:hover\:tw-to-orange-300:hover{
    --gradient-to-color: #fbd38d
  }

  .lg\:hover\:tw-to-orange-400:hover{
    --gradient-to-color: #f6ad55
  }

  .lg\:hover\:tw-to-orange-500:hover{
    --gradient-to-color: #ed8936
  }

  .lg\:hover\:tw-to-orange-600:hover{
    --gradient-to-color: #dd6b20
  }

  .lg\:hover\:tw-to-orange-700:hover{
    --gradient-to-color: #c05621
  }

  .lg\:hover\:tw-to-orange-800:hover{
    --gradient-to-color: #9c4221
  }

  .lg\:hover\:tw-to-orange-900:hover{
    --gradient-to-color: #7b341e
  }

  .lg\:hover\:tw-to-yellow-100:hover{
    --gradient-to-color: #fffff0
  }

  .lg\:hover\:tw-to-yellow-200:hover{
    --gradient-to-color: #fefcbf
  }

  .lg\:hover\:tw-to-yellow-300:hover{
    --gradient-to-color: #faf089
  }

  .lg\:hover\:tw-to-yellow-400:hover{
    --gradient-to-color: #f6e05e
  }

  .lg\:hover\:tw-to-yellow-500:hover{
    --gradient-to-color: #ecc94b
  }

  .lg\:hover\:tw-to-yellow-600:hover{
    --gradient-to-color: #d69e2e
  }

  .lg\:hover\:tw-to-yellow-700:hover{
    --gradient-to-color: #b7791f
  }

  .lg\:hover\:tw-to-yellow-800:hover{
    --gradient-to-color: #975a16
  }

  .lg\:hover\:tw-to-yellow-900:hover{
    --gradient-to-color: #744210
  }

  .lg\:hover\:tw-to-green-100:hover{
    --gradient-to-color: #f0fff4
  }

  .lg\:hover\:tw-to-green-200:hover{
    --gradient-to-color: #c6f6d5
  }

  .lg\:hover\:tw-to-green-300:hover{
    --gradient-to-color: #9ae6b4
  }

  .lg\:hover\:tw-to-green-400:hover{
    --gradient-to-color: #68d391
  }

  .lg\:hover\:tw-to-green-500:hover{
    --gradient-to-color: #48bb78
  }

  .lg\:hover\:tw-to-green-600:hover{
    --gradient-to-color: #38a169
  }

  .lg\:hover\:tw-to-green-700:hover{
    --gradient-to-color: #2f855a
  }

  .lg\:hover\:tw-to-green-800:hover{
    --gradient-to-color: #276749
  }

  .lg\:hover\:tw-to-green-900:hover{
    --gradient-to-color: #22543d
  }

  .lg\:hover\:tw-to-teal-100:hover{
    --gradient-to-color: #e6fffa
  }

  .lg\:hover\:tw-to-teal-200:hover{
    --gradient-to-color: #b2f5ea
  }

  .lg\:hover\:tw-to-teal-300:hover{
    --gradient-to-color: #81e6d9
  }

  .lg\:hover\:tw-to-teal-400:hover{
    --gradient-to-color: #4fd1c5
  }

  .lg\:hover\:tw-to-teal-500:hover{
    --gradient-to-color: #38b2ac
  }

  .lg\:hover\:tw-to-teal-600:hover{
    --gradient-to-color: #319795
  }

  .lg\:hover\:tw-to-teal-700:hover{
    --gradient-to-color: #2c7a7b
  }

  .lg\:hover\:tw-to-teal-800:hover{
    --gradient-to-color: #285e61
  }

  .lg\:hover\:tw-to-teal-900:hover{
    --gradient-to-color: #234e52
  }

  .lg\:hover\:tw-to-blue-100:hover{
    --gradient-to-color: #ebf8ff
  }

  .lg\:hover\:tw-to-blue-200:hover{
    --gradient-to-color: #bee3f8
  }

  .lg\:hover\:tw-to-blue-300:hover{
    --gradient-to-color: #90cdf4
  }

  .lg\:hover\:tw-to-blue-400:hover{
    --gradient-to-color: #63b3ed
  }

  .lg\:hover\:tw-to-blue-500:hover{
    --gradient-to-color: #4299e1
  }

  .lg\:hover\:tw-to-blue-600:hover{
    --gradient-to-color: #3182ce
  }

  .lg\:hover\:tw-to-blue-700:hover{
    --gradient-to-color: #2b6cb0
  }

  .lg\:hover\:tw-to-blue-800:hover{
    --gradient-to-color: #2c5282
  }

  .lg\:hover\:tw-to-blue-900:hover{
    --gradient-to-color: #2a4365
  }

  .lg\:hover\:tw-to-indigo-100:hover{
    --gradient-to-color: #ebf4ff
  }

  .lg\:hover\:tw-to-indigo-200:hover{
    --gradient-to-color: #c3dafe
  }

  .lg\:hover\:tw-to-indigo-300:hover{
    --gradient-to-color: #a3bffa
  }

  .lg\:hover\:tw-to-indigo-400:hover{
    --gradient-to-color: #7f9cf5
  }

  .lg\:hover\:tw-to-indigo-500:hover{
    --gradient-to-color: #667eea
  }

  .lg\:hover\:tw-to-indigo-600:hover{
    --gradient-to-color: #5a67d8
  }

  .lg\:hover\:tw-to-indigo-700:hover{
    --gradient-to-color: #4c51bf
  }

  .lg\:hover\:tw-to-indigo-800:hover{
    --gradient-to-color: #434190
  }

  .lg\:hover\:tw-to-indigo-900:hover{
    --gradient-to-color: #3c366b
  }

  .lg\:hover\:tw-to-purple-100:hover{
    --gradient-to-color: #faf5ff
  }

  .lg\:hover\:tw-to-purple-200:hover{
    --gradient-to-color: #e9d8fd
  }

  .lg\:hover\:tw-to-purple-300:hover{
    --gradient-to-color: #d6bcfa
  }

  .lg\:hover\:tw-to-purple-400:hover{
    --gradient-to-color: #b794f4
  }

  .lg\:hover\:tw-to-purple-500:hover{
    --gradient-to-color: #9f7aea
  }

  .lg\:hover\:tw-to-purple-600:hover{
    --gradient-to-color: #805ad5
  }

  .lg\:hover\:tw-to-purple-700:hover{
    --gradient-to-color: #6b46c1
  }

  .lg\:hover\:tw-to-purple-800:hover{
    --gradient-to-color: #553c9a
  }

  .lg\:hover\:tw-to-purple-900:hover{
    --gradient-to-color: #44337a
  }

  .lg\:hover\:tw-to-pink-100:hover{
    --gradient-to-color: #fff5f7
  }

  .lg\:hover\:tw-to-pink-200:hover{
    --gradient-to-color: #fed7e2
  }

  .lg\:hover\:tw-to-pink-300:hover{
    --gradient-to-color: #fbb6ce
  }

  .lg\:hover\:tw-to-pink-400:hover{
    --gradient-to-color: #f687b3
  }

  .lg\:hover\:tw-to-pink-500:hover{
    --gradient-to-color: #ed64a6
  }

  .lg\:hover\:tw-to-pink-600:hover{
    --gradient-to-color: #d53f8c
  }

  .lg\:hover\:tw-to-pink-700:hover{
    --gradient-to-color: #b83280
  }

  .lg\:hover\:tw-to-pink-800:hover{
    --gradient-to-color: #97266d
  }

  .lg\:hover\:tw-to-pink-900:hover{
    --gradient-to-color: #702459
  }

  .lg\:focus\:tw-from-transparent:focus{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:tw-from-tlg-green-dark:focus{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .lg\:focus\:tw-from-tlg-green:focus{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .lg\:focus\:tw-from-tlg-orange-500:focus{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .lg\:focus\:tw-from-black:focus{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:tw-from-white:focus{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:tw-from-gray-100:focus{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:focus\:tw-from-gray-200:focus{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:focus\:tw-from-gray-300:focus{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:focus\:tw-from-gray-400:focus{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:focus\:tw-from-gray-500:focus{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:focus\:tw-from-gray-600:focus{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:focus\:tw-from-gray-700:focus{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:focus\:tw-from-gray-800:focus{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:focus\:tw-from-gray-900:focus{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:focus\:tw-from-red-100:focus{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:focus\:tw-from-red-200:focus{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:focus\:tw-from-red-300:focus{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:focus\:tw-from-red-400:focus{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:focus\:tw-from-red-500:focus{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:focus\:tw-from-red-600:focus{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:focus\:tw-from-red-700:focus{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:focus\:tw-from-red-800:focus{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:focus\:tw-from-red-900:focus{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:focus\:tw-from-orange-100:focus{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:focus\:tw-from-orange-200:focus{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:focus\:tw-from-orange-300:focus{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:focus\:tw-from-orange-400:focus{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:focus\:tw-from-orange-500:focus{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:focus\:tw-from-orange-600:focus{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:focus\:tw-from-orange-700:focus{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:focus\:tw-from-orange-800:focus{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:focus\:tw-from-orange-900:focus{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:focus\:tw-from-yellow-100:focus{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:focus\:tw-from-yellow-200:focus{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:focus\:tw-from-yellow-300:focus{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:focus\:tw-from-yellow-400:focus{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:focus\:tw-from-yellow-500:focus{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:focus\:tw-from-yellow-600:focus{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:focus\:tw-from-yellow-700:focus{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:focus\:tw-from-yellow-800:focus{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:focus\:tw-from-yellow-900:focus{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:focus\:tw-from-green-100:focus{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:focus\:tw-from-green-200:focus{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:focus\:tw-from-green-300:focus{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:focus\:tw-from-green-400:focus{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:focus\:tw-from-green-500:focus{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:focus\:tw-from-green-600:focus{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:focus\:tw-from-green-700:focus{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:focus\:tw-from-green-800:focus{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:focus\:tw-from-green-900:focus{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:focus\:tw-from-teal-100:focus{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:focus\:tw-from-teal-200:focus{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:focus\:tw-from-teal-300:focus{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:focus\:tw-from-teal-400:focus{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:focus\:tw-from-teal-500:focus{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:focus\:tw-from-teal-600:focus{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:focus\:tw-from-teal-700:focus{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:focus\:tw-from-teal-800:focus{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:focus\:tw-from-teal-900:focus{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:focus\:tw-from-blue-100:focus{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:focus\:tw-from-blue-200:focus{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:focus\:tw-from-blue-300:focus{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:focus\:tw-from-blue-400:focus{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:focus\:tw-from-blue-500:focus{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:focus\:tw-from-blue-600:focus{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:focus\:tw-from-blue-700:focus{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:focus\:tw-from-blue-800:focus{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:focus\:tw-from-blue-900:focus{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:focus\:tw-from-indigo-100:focus{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:focus\:tw-from-indigo-200:focus{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:focus\:tw-from-indigo-300:focus{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:focus\:tw-from-indigo-400:focus{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:focus\:tw-from-indigo-500:focus{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:focus\:tw-from-indigo-600:focus{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:focus\:tw-from-indigo-700:focus{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:focus\:tw-from-indigo-800:focus{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:focus\:tw-from-indigo-900:focus{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:focus\:tw-from-purple-100:focus{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:focus\:tw-from-purple-200:focus{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:focus\:tw-from-purple-300:focus{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:focus\:tw-from-purple-400:focus{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:focus\:tw-from-purple-500:focus{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:focus\:tw-from-purple-600:focus{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:focus\:tw-from-purple-700:focus{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:focus\:tw-from-purple-800:focus{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:focus\:tw-from-purple-900:focus{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:focus\:tw-from-pink-100:focus{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:focus\:tw-from-pink-200:focus{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:focus\:tw-from-pink-300:focus{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:focus\:tw-from-pink-400:focus{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:focus\:tw-from-pink-500:focus{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:focus\:tw-from-pink-600:focus{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:focus\:tw-from-pink-700:focus{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:focus\:tw-from-pink-800:focus{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:focus\:tw-from-pink-900:focus{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:focus\:tw-via-transparent:focus{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:tw-via-tlg-green-dark:focus{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .lg\:focus\:tw-via-tlg-green:focus{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .lg\:focus\:tw-via-tlg-orange-500:focus{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .lg\:focus\:tw-via-black:focus{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:tw-via-white:focus{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:tw-via-gray-100:focus{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .lg\:focus\:tw-via-gray-200:focus{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .lg\:focus\:tw-via-gray-300:focus{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .lg\:focus\:tw-via-gray-400:focus{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .lg\:focus\:tw-via-gray-500:focus{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .lg\:focus\:tw-via-gray-600:focus{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .lg\:focus\:tw-via-gray-700:focus{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .lg\:focus\:tw-via-gray-800:focus{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .lg\:focus\:tw-via-gray-900:focus{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .lg\:focus\:tw-via-red-100:focus{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .lg\:focus\:tw-via-red-200:focus{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .lg\:focus\:tw-via-red-300:focus{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .lg\:focus\:tw-via-red-400:focus{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .lg\:focus\:tw-via-red-500:focus{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .lg\:focus\:tw-via-red-600:focus{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .lg\:focus\:tw-via-red-700:focus{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .lg\:focus\:tw-via-red-800:focus{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .lg\:focus\:tw-via-red-900:focus{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .lg\:focus\:tw-via-orange-100:focus{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .lg\:focus\:tw-via-orange-200:focus{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .lg\:focus\:tw-via-orange-300:focus{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .lg\:focus\:tw-via-orange-400:focus{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .lg\:focus\:tw-via-orange-500:focus{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .lg\:focus\:tw-via-orange-600:focus{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .lg\:focus\:tw-via-orange-700:focus{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .lg\:focus\:tw-via-orange-800:focus{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .lg\:focus\:tw-via-orange-900:focus{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .lg\:focus\:tw-via-yellow-100:focus{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .lg\:focus\:tw-via-yellow-200:focus{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .lg\:focus\:tw-via-yellow-300:focus{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .lg\:focus\:tw-via-yellow-400:focus{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .lg\:focus\:tw-via-yellow-500:focus{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .lg\:focus\:tw-via-yellow-600:focus{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .lg\:focus\:tw-via-yellow-700:focus{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .lg\:focus\:tw-via-yellow-800:focus{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .lg\:focus\:tw-via-yellow-900:focus{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .lg\:focus\:tw-via-green-100:focus{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .lg\:focus\:tw-via-green-200:focus{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .lg\:focus\:tw-via-green-300:focus{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .lg\:focus\:tw-via-green-400:focus{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .lg\:focus\:tw-via-green-500:focus{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .lg\:focus\:tw-via-green-600:focus{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .lg\:focus\:tw-via-green-700:focus{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .lg\:focus\:tw-via-green-800:focus{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .lg\:focus\:tw-via-green-900:focus{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .lg\:focus\:tw-via-teal-100:focus{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .lg\:focus\:tw-via-teal-200:focus{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .lg\:focus\:tw-via-teal-300:focus{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .lg\:focus\:tw-via-teal-400:focus{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .lg\:focus\:tw-via-teal-500:focus{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .lg\:focus\:tw-via-teal-600:focus{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .lg\:focus\:tw-via-teal-700:focus{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .lg\:focus\:tw-via-teal-800:focus{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .lg\:focus\:tw-via-teal-900:focus{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .lg\:focus\:tw-via-blue-100:focus{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .lg\:focus\:tw-via-blue-200:focus{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .lg\:focus\:tw-via-blue-300:focus{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .lg\:focus\:tw-via-blue-400:focus{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .lg\:focus\:tw-via-blue-500:focus{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .lg\:focus\:tw-via-blue-600:focus{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .lg\:focus\:tw-via-blue-700:focus{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .lg\:focus\:tw-via-blue-800:focus{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .lg\:focus\:tw-via-blue-900:focus{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .lg\:focus\:tw-via-indigo-100:focus{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .lg\:focus\:tw-via-indigo-200:focus{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .lg\:focus\:tw-via-indigo-300:focus{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .lg\:focus\:tw-via-indigo-400:focus{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .lg\:focus\:tw-via-indigo-500:focus{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .lg\:focus\:tw-via-indigo-600:focus{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .lg\:focus\:tw-via-indigo-700:focus{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .lg\:focus\:tw-via-indigo-800:focus{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .lg\:focus\:tw-via-indigo-900:focus{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .lg\:focus\:tw-via-purple-100:focus{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .lg\:focus\:tw-via-purple-200:focus{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .lg\:focus\:tw-via-purple-300:focus{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .lg\:focus\:tw-via-purple-400:focus{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .lg\:focus\:tw-via-purple-500:focus{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .lg\:focus\:tw-via-purple-600:focus{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .lg\:focus\:tw-via-purple-700:focus{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .lg\:focus\:tw-via-purple-800:focus{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .lg\:focus\:tw-via-purple-900:focus{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .lg\:focus\:tw-via-pink-100:focus{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .lg\:focus\:tw-via-pink-200:focus{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .lg\:focus\:tw-via-pink-300:focus{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .lg\:focus\:tw-via-pink-400:focus{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .lg\:focus\:tw-via-pink-500:focus{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .lg\:focus\:tw-via-pink-600:focus{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .lg\:focus\:tw-via-pink-700:focus{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .lg\:focus\:tw-via-pink-800:focus{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .lg\:focus\:tw-via-pink-900:focus{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .lg\:focus\:tw-to-transparent:focus{
    --gradient-to-color: transparent
  }

  .lg\:focus\:tw-to-tlg-green-dark:focus{
    --gradient-to-color: #76b84e
  }

  .lg\:focus\:tw-to-tlg-green:focus{
    --gradient-to-color: #a4ca61
  }

  .lg\:focus\:tw-to-tlg-orange-500:focus{
    --gradient-to-color: #ff7f41
  }

  .lg\:focus\:tw-to-black:focus{
    --gradient-to-color: #000
  }

  .lg\:focus\:tw-to-white:focus{
    --gradient-to-color: #fff
  }

  .lg\:focus\:tw-to-gray-100:focus{
    --gradient-to-color: #f7fafc
  }

  .lg\:focus\:tw-to-gray-200:focus{
    --gradient-to-color: #edf2f7
  }

  .lg\:focus\:tw-to-gray-300:focus{
    --gradient-to-color: #e2e8f0
  }

  .lg\:focus\:tw-to-gray-400:focus{
    --gradient-to-color: #cbd5e0
  }

  .lg\:focus\:tw-to-gray-500:focus{
    --gradient-to-color: #a0aec0
  }

  .lg\:focus\:tw-to-gray-600:focus{
    --gradient-to-color: #718096
  }

  .lg\:focus\:tw-to-gray-700:focus{
    --gradient-to-color: #4a5568
  }

  .lg\:focus\:tw-to-gray-800:focus{
    --gradient-to-color: #2d3748
  }

  .lg\:focus\:tw-to-gray-900:focus{
    --gradient-to-color: #1a202c
  }

  .lg\:focus\:tw-to-red-100:focus{
    --gradient-to-color: #fff5f5
  }

  .lg\:focus\:tw-to-red-200:focus{
    --gradient-to-color: #fed7d7
  }

  .lg\:focus\:tw-to-red-300:focus{
    --gradient-to-color: #feb2b2
  }

  .lg\:focus\:tw-to-red-400:focus{
    --gradient-to-color: #fc8181
  }

  .lg\:focus\:tw-to-red-500:focus{
    --gradient-to-color: #f56565
  }

  .lg\:focus\:tw-to-red-600:focus{
    --gradient-to-color: #e53e3e
  }

  .lg\:focus\:tw-to-red-700:focus{
    --gradient-to-color: #c53030
  }

  .lg\:focus\:tw-to-red-800:focus{
    --gradient-to-color: #9b2c2c
  }

  .lg\:focus\:tw-to-red-900:focus{
    --gradient-to-color: #742a2a
  }

  .lg\:focus\:tw-to-orange-100:focus{
    --gradient-to-color: #fffaf0
  }

  .lg\:focus\:tw-to-orange-200:focus{
    --gradient-to-color: #feebc8
  }

  .lg\:focus\:tw-to-orange-300:focus{
    --gradient-to-color: #fbd38d
  }

  .lg\:focus\:tw-to-orange-400:focus{
    --gradient-to-color: #f6ad55
  }

  .lg\:focus\:tw-to-orange-500:focus{
    --gradient-to-color: #ed8936
  }

  .lg\:focus\:tw-to-orange-600:focus{
    --gradient-to-color: #dd6b20
  }

  .lg\:focus\:tw-to-orange-700:focus{
    --gradient-to-color: #c05621
  }

  .lg\:focus\:tw-to-orange-800:focus{
    --gradient-to-color: #9c4221
  }

  .lg\:focus\:tw-to-orange-900:focus{
    --gradient-to-color: #7b341e
  }

  .lg\:focus\:tw-to-yellow-100:focus{
    --gradient-to-color: #fffff0
  }

  .lg\:focus\:tw-to-yellow-200:focus{
    --gradient-to-color: #fefcbf
  }

  .lg\:focus\:tw-to-yellow-300:focus{
    --gradient-to-color: #faf089
  }

  .lg\:focus\:tw-to-yellow-400:focus{
    --gradient-to-color: #f6e05e
  }

  .lg\:focus\:tw-to-yellow-500:focus{
    --gradient-to-color: #ecc94b
  }

  .lg\:focus\:tw-to-yellow-600:focus{
    --gradient-to-color: #d69e2e
  }

  .lg\:focus\:tw-to-yellow-700:focus{
    --gradient-to-color: #b7791f
  }

  .lg\:focus\:tw-to-yellow-800:focus{
    --gradient-to-color: #975a16
  }

  .lg\:focus\:tw-to-yellow-900:focus{
    --gradient-to-color: #744210
  }

  .lg\:focus\:tw-to-green-100:focus{
    --gradient-to-color: #f0fff4
  }

  .lg\:focus\:tw-to-green-200:focus{
    --gradient-to-color: #c6f6d5
  }

  .lg\:focus\:tw-to-green-300:focus{
    --gradient-to-color: #9ae6b4
  }

  .lg\:focus\:tw-to-green-400:focus{
    --gradient-to-color: #68d391
  }

  .lg\:focus\:tw-to-green-500:focus{
    --gradient-to-color: #48bb78
  }

  .lg\:focus\:tw-to-green-600:focus{
    --gradient-to-color: #38a169
  }

  .lg\:focus\:tw-to-green-700:focus{
    --gradient-to-color: #2f855a
  }

  .lg\:focus\:tw-to-green-800:focus{
    --gradient-to-color: #276749
  }

  .lg\:focus\:tw-to-green-900:focus{
    --gradient-to-color: #22543d
  }

  .lg\:focus\:tw-to-teal-100:focus{
    --gradient-to-color: #e6fffa
  }

  .lg\:focus\:tw-to-teal-200:focus{
    --gradient-to-color: #b2f5ea
  }

  .lg\:focus\:tw-to-teal-300:focus{
    --gradient-to-color: #81e6d9
  }

  .lg\:focus\:tw-to-teal-400:focus{
    --gradient-to-color: #4fd1c5
  }

  .lg\:focus\:tw-to-teal-500:focus{
    --gradient-to-color: #38b2ac
  }

  .lg\:focus\:tw-to-teal-600:focus{
    --gradient-to-color: #319795
  }

  .lg\:focus\:tw-to-teal-700:focus{
    --gradient-to-color: #2c7a7b
  }

  .lg\:focus\:tw-to-teal-800:focus{
    --gradient-to-color: #285e61
  }

  .lg\:focus\:tw-to-teal-900:focus{
    --gradient-to-color: #234e52
  }

  .lg\:focus\:tw-to-blue-100:focus{
    --gradient-to-color: #ebf8ff
  }

  .lg\:focus\:tw-to-blue-200:focus{
    --gradient-to-color: #bee3f8
  }

  .lg\:focus\:tw-to-blue-300:focus{
    --gradient-to-color: #90cdf4
  }

  .lg\:focus\:tw-to-blue-400:focus{
    --gradient-to-color: #63b3ed
  }

  .lg\:focus\:tw-to-blue-500:focus{
    --gradient-to-color: #4299e1
  }

  .lg\:focus\:tw-to-blue-600:focus{
    --gradient-to-color: #3182ce
  }

  .lg\:focus\:tw-to-blue-700:focus{
    --gradient-to-color: #2b6cb0
  }

  .lg\:focus\:tw-to-blue-800:focus{
    --gradient-to-color: #2c5282
  }

  .lg\:focus\:tw-to-blue-900:focus{
    --gradient-to-color: #2a4365
  }

  .lg\:focus\:tw-to-indigo-100:focus{
    --gradient-to-color: #ebf4ff
  }

  .lg\:focus\:tw-to-indigo-200:focus{
    --gradient-to-color: #c3dafe
  }

  .lg\:focus\:tw-to-indigo-300:focus{
    --gradient-to-color: #a3bffa
  }

  .lg\:focus\:tw-to-indigo-400:focus{
    --gradient-to-color: #7f9cf5
  }

  .lg\:focus\:tw-to-indigo-500:focus{
    --gradient-to-color: #667eea
  }

  .lg\:focus\:tw-to-indigo-600:focus{
    --gradient-to-color: #5a67d8
  }

  .lg\:focus\:tw-to-indigo-700:focus{
    --gradient-to-color: #4c51bf
  }

  .lg\:focus\:tw-to-indigo-800:focus{
    --gradient-to-color: #434190
  }

  .lg\:focus\:tw-to-indigo-900:focus{
    --gradient-to-color: #3c366b
  }

  .lg\:focus\:tw-to-purple-100:focus{
    --gradient-to-color: #faf5ff
  }

  .lg\:focus\:tw-to-purple-200:focus{
    --gradient-to-color: #e9d8fd
  }

  .lg\:focus\:tw-to-purple-300:focus{
    --gradient-to-color: #d6bcfa
  }

  .lg\:focus\:tw-to-purple-400:focus{
    --gradient-to-color: #b794f4
  }

  .lg\:focus\:tw-to-purple-500:focus{
    --gradient-to-color: #9f7aea
  }

  .lg\:focus\:tw-to-purple-600:focus{
    --gradient-to-color: #805ad5
  }

  .lg\:focus\:tw-to-purple-700:focus{
    --gradient-to-color: #6b46c1
  }

  .lg\:focus\:tw-to-purple-800:focus{
    --gradient-to-color: #553c9a
  }

  .lg\:focus\:tw-to-purple-900:focus{
    --gradient-to-color: #44337a
  }

  .lg\:focus\:tw-to-pink-100:focus{
    --gradient-to-color: #fff5f7
  }

  .lg\:focus\:tw-to-pink-200:focus{
    --gradient-to-color: #fed7e2
  }

  .lg\:focus\:tw-to-pink-300:focus{
    --gradient-to-color: #fbb6ce
  }

  .lg\:focus\:tw-to-pink-400:focus{
    --gradient-to-color: #f687b3
  }

  .lg\:focus\:tw-to-pink-500:focus{
    --gradient-to-color: #ed64a6
  }

  .lg\:focus\:tw-to-pink-600:focus{
    --gradient-to-color: #d53f8c
  }

  .lg\:focus\:tw-to-pink-700:focus{
    --gradient-to-color: #b83280
  }

  .lg\:focus\:tw-to-pink-800:focus{
    --gradient-to-color: #97266d
  }

  .lg\:focus\:tw-to-pink-900:focus{
    --gradient-to-color: #702459
  }

  .lg\:tw-bg-opacity-0{
    --bg-opacity: 0
  }

  .lg\:tw-bg-opacity-25{
    --bg-opacity: 0.25
  }

  .lg\:tw-bg-opacity-50{
    --bg-opacity: 0.5
  }

  .lg\:tw-bg-opacity-75{
    --bg-opacity: 0.75
  }

  .lg\:tw-bg-opacity-100{
    --bg-opacity: 1
  }

  .lg\:hover\:tw-bg-opacity-0:hover{
    --bg-opacity: 0
  }

  .lg\:hover\:tw-bg-opacity-25:hover{
    --bg-opacity: 0.25
  }

  .lg\:hover\:tw-bg-opacity-50:hover{
    --bg-opacity: 0.5
  }

  .lg\:hover\:tw-bg-opacity-75:hover{
    --bg-opacity: 0.75
  }

  .lg\:hover\:tw-bg-opacity-100:hover{
    --bg-opacity: 1
  }

  .lg\:focus\:tw-bg-opacity-0:focus{
    --bg-opacity: 0
  }

  .lg\:focus\:tw-bg-opacity-25:focus{
    --bg-opacity: 0.25
  }

  .lg\:focus\:tw-bg-opacity-50:focus{
    --bg-opacity: 0.5
  }

  .lg\:focus\:tw-bg-opacity-75:focus{
    --bg-opacity: 0.75
  }

  .lg\:focus\:tw-bg-opacity-100:focus{
    --bg-opacity: 1
  }

  .lg\:tw-bg-bottom{
    background-position: bottom
  }

  .lg\:tw-bg-center{
    background-position: center
  }

  .lg\:tw-bg-left{
    background-position: left
  }

  .lg\:tw-bg-left-bottom{
    background-position: left bottom
  }

  .lg\:tw-bg-left-top{
    background-position: left top
  }

  .lg\:tw-bg-right{
    background-position: right
  }

  .lg\:tw-bg-right-bottom{
    background-position: right bottom
  }

  .lg\:tw-bg-right-top{
    background-position: right top
  }

  .lg\:tw-bg-top{
    background-position: top
  }

  .lg\:tw-bg-repeat{
    background-repeat: repeat
  }

  .lg\:tw-bg-no-repeat{
    background-repeat: no-repeat
  }

  .lg\:tw-bg-repeat-x{
    background-repeat: repeat-x
  }

  .lg\:tw-bg-repeat-y{
    background-repeat: repeat-y
  }

  .lg\:tw-bg-repeat-round{
    background-repeat: round
  }

  .lg\:tw-bg-repeat-space{
    background-repeat: space
  }

  .lg\:tw-bg-auto{
    background-size: auto
  }

  .lg\:tw-bg-cover{
    background-size: cover
  }

  .lg\:tw-bg-contain{
    background-size: contain
  }

  .lg\:tw-border-collapse{
    border-collapse: collapse
  }

  .lg\:tw-border-separate{
    border-collapse: separate
  }

  .lg\:tw-border-transparent{
    border-color: transparent
  }

  .lg\:tw-border-tlg-green-dark{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .lg\:tw-border-tlg-green{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .lg\:tw-border-tlg-orange-500{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .lg\:tw-border-black{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .lg\:tw-border-white{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .lg\:tw-border-gray-100{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .lg\:tw-border-gray-200{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .lg\:tw-border-gray-300{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .lg\:tw-border-gray-400{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .lg\:tw-border-gray-500{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .lg\:tw-border-gray-600{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .lg\:tw-border-gray-700{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .lg\:tw-border-gray-800{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .lg\:tw-border-gray-900{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .lg\:tw-border-red-100{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .lg\:tw-border-red-200{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .lg\:tw-border-red-300{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .lg\:tw-border-red-400{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .lg\:tw-border-red-500{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .lg\:tw-border-red-600{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .lg\:tw-border-red-700{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .lg\:tw-border-red-800{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .lg\:tw-border-red-900{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .lg\:tw-border-orange-100{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .lg\:tw-border-orange-200{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .lg\:tw-border-orange-300{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .lg\:tw-border-orange-400{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .lg\:tw-border-orange-500{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .lg\:tw-border-orange-600{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .lg\:tw-border-orange-700{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .lg\:tw-border-orange-800{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .lg\:tw-border-orange-900{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .lg\:tw-border-yellow-100{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .lg\:tw-border-yellow-200{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .lg\:tw-border-yellow-300{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .lg\:tw-border-yellow-400{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .lg\:tw-border-yellow-500{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .lg\:tw-border-yellow-600{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .lg\:tw-border-yellow-700{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .lg\:tw-border-yellow-800{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .lg\:tw-border-yellow-900{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .lg\:tw-border-green-100{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .lg\:tw-border-green-200{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .lg\:tw-border-green-300{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .lg\:tw-border-green-400{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .lg\:tw-border-green-500{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .lg\:tw-border-green-600{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .lg\:tw-border-green-700{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .lg\:tw-border-green-800{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .lg\:tw-border-green-900{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .lg\:tw-border-teal-100{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .lg\:tw-border-teal-200{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .lg\:tw-border-teal-300{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .lg\:tw-border-teal-400{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .lg\:tw-border-teal-500{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .lg\:tw-border-teal-600{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .lg\:tw-border-teal-700{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .lg\:tw-border-teal-800{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .lg\:tw-border-teal-900{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .lg\:tw-border-blue-100{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .lg\:tw-border-blue-200{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .lg\:tw-border-blue-300{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .lg\:tw-border-blue-400{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .lg\:tw-border-blue-500{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .lg\:tw-border-blue-600{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .lg\:tw-border-blue-700{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .lg\:tw-border-blue-800{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .lg\:tw-border-blue-900{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .lg\:tw-border-indigo-100{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .lg\:tw-border-indigo-200{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .lg\:tw-border-indigo-300{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .lg\:tw-border-indigo-400{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .lg\:tw-border-indigo-500{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .lg\:tw-border-indigo-600{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .lg\:tw-border-indigo-700{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .lg\:tw-border-indigo-800{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .lg\:tw-border-indigo-900{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .lg\:tw-border-purple-100{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .lg\:tw-border-purple-200{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .lg\:tw-border-purple-300{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .lg\:tw-border-purple-400{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .lg\:tw-border-purple-500{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .lg\:tw-border-purple-600{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .lg\:tw-border-purple-700{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .lg\:tw-border-purple-800{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .lg\:tw-border-purple-900{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .lg\:tw-border-pink-100{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .lg\:tw-border-pink-200{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .lg\:tw-border-pink-300{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .lg\:tw-border-pink-400{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .lg\:tw-border-pink-500{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .lg\:tw-border-pink-600{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .lg\:tw-border-pink-700{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .lg\:tw-border-pink-800{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .lg\:tw-border-pink-900{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .lg\:hover\:tw-border-transparent:hover{
    border-color: transparent
  }

  .lg\:hover\:tw-border-tlg-green-dark:hover{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .lg\:hover\:tw-border-tlg-green:hover{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .lg\:hover\:tw-border-tlg-orange-500:hover{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .lg\:hover\:tw-border-black:hover{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .lg\:hover\:tw-border-white:hover{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-100:hover{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-200:hover{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-300:hover{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-400:hover{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-500:hover{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-600:hover{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-700:hover{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-800:hover{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .lg\:hover\:tw-border-gray-900:hover{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-100:hover{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-200:hover{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-300:hover{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-400:hover{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-500:hover{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-600:hover{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-700:hover{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-800:hover{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .lg\:hover\:tw-border-red-900:hover{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-100:hover{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-200:hover{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-300:hover{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-400:hover{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-500:hover{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-600:hover{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-700:hover{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-800:hover{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .lg\:hover\:tw-border-orange-900:hover{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-100:hover{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-200:hover{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-300:hover{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-400:hover{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-500:hover{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-600:hover{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-700:hover{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-800:hover{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .lg\:hover\:tw-border-yellow-900:hover{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-100:hover{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-200:hover{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-300:hover{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-400:hover{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-500:hover{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-600:hover{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-700:hover{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-800:hover{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .lg\:hover\:tw-border-green-900:hover{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-100:hover{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-200:hover{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-300:hover{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-400:hover{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-500:hover{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-600:hover{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-700:hover{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-800:hover{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .lg\:hover\:tw-border-teal-900:hover{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-100:hover{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-200:hover{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-300:hover{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-400:hover{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-500:hover{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-600:hover{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-700:hover{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-800:hover{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .lg\:hover\:tw-border-blue-900:hover{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-100:hover{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-200:hover{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-300:hover{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-400:hover{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-500:hover{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-600:hover{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-700:hover{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-800:hover{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .lg\:hover\:tw-border-indigo-900:hover{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-100:hover{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-200:hover{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-300:hover{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-400:hover{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-500:hover{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-600:hover{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-700:hover{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-800:hover{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .lg\:hover\:tw-border-purple-900:hover{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-100:hover{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-200:hover{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-300:hover{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-400:hover{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-500:hover{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-600:hover{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-700:hover{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-800:hover{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .lg\:hover\:tw-border-pink-900:hover{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .lg\:focus\:tw-border-transparent:focus{
    border-color: transparent
  }

  .lg\:focus\:tw-border-tlg-green-dark:focus{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .lg\:focus\:tw-border-tlg-green:focus{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .lg\:focus\:tw-border-tlg-orange-500:focus{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .lg\:focus\:tw-border-black:focus{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .lg\:focus\:tw-border-white:focus{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-100:focus{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-200:focus{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-300:focus{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-400:focus{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-500:focus{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-600:focus{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-700:focus{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-800:focus{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .lg\:focus\:tw-border-gray-900:focus{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-100:focus{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-200:focus{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-300:focus{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-400:focus{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-500:focus{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-600:focus{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-700:focus{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-800:focus{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .lg\:focus\:tw-border-red-900:focus{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-100:focus{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-200:focus{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-300:focus{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-400:focus{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-500:focus{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-600:focus{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-700:focus{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-800:focus{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .lg\:focus\:tw-border-orange-900:focus{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-100:focus{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-200:focus{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-300:focus{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-400:focus{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-500:focus{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-600:focus{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-700:focus{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-800:focus{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .lg\:focus\:tw-border-yellow-900:focus{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-100:focus{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-200:focus{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-300:focus{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-400:focus{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-500:focus{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-600:focus{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-700:focus{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-800:focus{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .lg\:focus\:tw-border-green-900:focus{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-100:focus{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-200:focus{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-300:focus{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-400:focus{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-500:focus{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-600:focus{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-700:focus{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-800:focus{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .lg\:focus\:tw-border-teal-900:focus{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-100:focus{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-200:focus{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-300:focus{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-400:focus{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-500:focus{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-600:focus{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-700:focus{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-800:focus{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .lg\:focus\:tw-border-blue-900:focus{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-100:focus{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-200:focus{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-300:focus{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-400:focus{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-500:focus{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-600:focus{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-700:focus{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-800:focus{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .lg\:focus\:tw-border-indigo-900:focus{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-100:focus{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-200:focus{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-300:focus{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-400:focus{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-500:focus{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-600:focus{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-700:focus{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-800:focus{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .lg\:focus\:tw-border-purple-900:focus{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-100:focus{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-200:focus{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-300:focus{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-400:focus{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-500:focus{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-600:focus{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-700:focus{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-800:focus{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .lg\:focus\:tw-border-pink-900:focus{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .lg\:tw-border-opacity-0{
    --border-opacity: 0
  }

  .lg\:tw-border-opacity-25{
    --border-opacity: 0.25
  }

  .lg\:tw-border-opacity-50{
    --border-opacity: 0.5
  }

  .lg\:tw-border-opacity-75{
    --border-opacity: 0.75
  }

  .lg\:tw-border-opacity-100{
    --border-opacity: 1
  }

  .lg\:hover\:tw-border-opacity-0:hover{
    --border-opacity: 0
  }

  .lg\:hover\:tw-border-opacity-25:hover{
    --border-opacity: 0.25
  }

  .lg\:hover\:tw-border-opacity-50:hover{
    --border-opacity: 0.5
  }

  .lg\:hover\:tw-border-opacity-75:hover{
    --border-opacity: 0.75
  }

  .lg\:hover\:tw-border-opacity-100:hover{
    --border-opacity: 1
  }

  .lg\:focus\:tw-border-opacity-0:focus{
    --border-opacity: 0
  }

  .lg\:focus\:tw-border-opacity-25:focus{
    --border-opacity: 0.25
  }

  .lg\:focus\:tw-border-opacity-50:focus{
    --border-opacity: 0.5
  }

  .lg\:focus\:tw-border-opacity-75:focus{
    --border-opacity: 0.75
  }

  .lg\:focus\:tw-border-opacity-100:focus{
    --border-opacity: 1
  }

  .lg\:tw-rounded-none{
    border-radius: 0
  }

  .lg\:tw-rounded-sm{
    border-radius: 0.125rem
  }

  .lg\:tw-rounded{
    border-radius: 0.25rem
  }

  .lg\:tw-rounded-lg{
    border-radius: 0.5rem
  }

  .lg\:tw-rounded-full{
    border-radius: 9999px
  }

  .lg\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .lg\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .lg\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .lg\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .lg\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .lg\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .lg\:tw-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .lg\:tw-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .lg\:tw-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .lg\:tw-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .lg\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .lg\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .lg\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .lg\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .lg\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .lg\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .lg\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:tw-rounded-tl-none{
    border-top-left-radius: 0
  }

  .lg\:tw-rounded-tr-none{
    border-top-right-radius: 0
  }

  .lg\:tw-rounded-br-none{
    border-bottom-right-radius: 0
  }

  .lg\:tw-rounded-bl-none{
    border-bottom-left-radius: 0
  }

  .lg\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  .lg\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  .lg\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  .lg\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  .lg\:tw-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  .lg\:tw-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  .lg\:tw-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  .lg\:tw-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  .lg\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  .lg\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  .lg\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  .lg\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  .lg\:tw-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  .lg\:tw-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  .lg\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  .lg\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  .lg\:tw-border-solid{
    border-style: solid
  }

  .lg\:tw-border-dashed{
    border-style: dashed
  }

  .lg\:tw-border-dotted{
    border-style: dotted
  }

  .lg\:tw-border-double{
    border-style: double
  }

  .lg\:tw-border-none{
    border-style: none
  }

  .lg\:tw-border-0{
    border-width: 0
  }

  .lg\:tw-border-2{
    border-width: 2px
  }

  .lg\:tw-border-4{
    border-width: 4px
  }

  .lg\:tw-border-8{
    border-width: 8px
  }

  .lg\:tw-border{
    border-width: 1px
  }

  .lg\:tw-border-t-0{
    border-top-width: 0
  }

  .lg\:tw-border-r-0{
    border-right-width: 0
  }

  .lg\:tw-border-b-0{
    border-bottom-width: 0
  }

  .lg\:tw-border-l-0{
    border-left-width: 0
  }

  .lg\:tw-border-t-2{
    border-top-width: 2px
  }

  .lg\:tw-border-r-2{
    border-right-width: 2px
  }

  .lg\:tw-border-b-2{
    border-bottom-width: 2px
  }

  .lg\:tw-border-l-2{
    border-left-width: 2px
  }

  .lg\:tw-border-t-4{
    border-top-width: 4px
  }

  .lg\:tw-border-r-4{
    border-right-width: 4px
  }

  .lg\:tw-border-b-4{
    border-bottom-width: 4px
  }

  .lg\:tw-border-l-4{
    border-left-width: 4px
  }

  .lg\:tw-border-t-8{
    border-top-width: 8px
  }

  .lg\:tw-border-r-8{
    border-right-width: 8px
  }

  .lg\:tw-border-b-8{
    border-bottom-width: 8px
  }

  .lg\:tw-border-l-8{
    border-left-width: 8px
  }

  .lg\:tw-border-t{
    border-top-width: 1px
  }

  .lg\:tw-border-r{
    border-right-width: 1px
  }

  .lg\:tw-border-b{
    border-bottom-width: 1px
  }

  .lg\:tw-border-l{
    border-left-width: 1px
  }

  .lg\:tw-box-border{
    box-sizing: border-box
  }

  .lg\:tw-box-content{
    box-sizing: content-box
  }

  .lg\:tw-cursor-auto{
    cursor: auto
  }

  .lg\:tw-cursor-default{
    cursor: default
  }

  .lg\:tw-cursor-pointer{
    cursor: pointer
  }

  .lg\:tw-cursor-wait{
    cursor: wait
  }

  .lg\:tw-cursor-text{
    cursor: text
  }

  .lg\:tw-cursor-move{
    cursor: move
  }

  .lg\:tw-cursor-not-allowed{
    cursor: not-allowed
  }

  .lg\:tw-block{
    display: block
  }

  .lg\:tw-inline-block{
    display: inline-block
  }

  .lg\:tw-inline{
    display: inline
  }

  .lg\:tw-flex{
    display: flex
  }

  .lg\:tw-inline-flex{
    display: inline-flex
  }

  .lg\:tw-table{
    display: table
  }

  .lg\:tw-table-caption{
    display: table-caption
  }

  .lg\:tw-table-cell{
    display: table-cell
  }

  .lg\:tw-table-column{
    display: table-column
  }

  .lg\:tw-table-column-group{
    display: table-column-group
  }

  .lg\:tw-table-footer-group{
    display: table-footer-group
  }

  .lg\:tw-table-header-group{
    display: table-header-group
  }

  .lg\:tw-table-row-group{
    display: table-row-group
  }

  .lg\:tw-table-row{
    display: table-row
  }

  .lg\:tw-flow-root{
    display: flow-root
  }

  .lg\:tw-grid{
    display: grid
  }

  .lg\:tw-inline-grid{
    display: inline-grid
  }

  .lg\:tw-contents{
    display: contents
  }

  .lg\:tw-hidden{
    display: none
  }

  .lg\:tw-flex-row{
    flex-direction: row
  }

  .lg\:tw-flex-row-reverse{
    flex-direction: row-reverse
  }

  .lg\:tw-flex-col{
    flex-direction: column
  }

  .lg\:tw-flex-col-reverse{
    flex-direction: column-reverse
  }

  .lg\:tw-flex-wrap{
    flex-wrap: wrap
  }

  .lg\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  .lg\:tw-flex-no-wrap{
    flex-wrap: nowrap
  }

  .lg\:tw-place-items-auto{
    place-items: auto
  }

  .lg\:tw-place-items-start{
    place-items: start
  }

  .lg\:tw-place-items-end{
    place-items: end
  }

  .lg\:tw-place-items-center{
    place-items: center
  }

  .lg\:tw-place-items-stretch{
    place-items: stretch
  }

  .lg\:tw-place-content-center{
    place-content: center
  }

  .lg\:tw-place-content-start{
    place-content: start
  }

  .lg\:tw-place-content-end{
    place-content: end
  }

  .lg\:tw-place-content-between{
    place-content: space-between
  }

  .lg\:tw-place-content-around{
    place-content: space-around
  }

  .lg\:tw-place-content-evenly{
    place-content: space-evenly
  }

  .lg\:tw-place-content-stretch{
    place-content: stretch
  }

  .lg\:tw-place-self-auto{
    place-self: auto
  }

  .lg\:tw-place-self-start{
    place-self: start
  }

  .lg\:tw-place-self-end{
    place-self: end
  }

  .lg\:tw-place-self-center{
    place-self: center
  }

  .lg\:tw-place-self-stretch{
    place-self: stretch
  }

  .lg\:tw-items-start{
    align-items: flex-start
  }

  .lg\:tw-items-end{
    align-items: flex-end
  }

  .lg\:tw-items-center{
    align-items: center
  }

  .lg\:tw-items-baseline{
    align-items: baseline
  }

  .lg\:tw-items-stretch{
    align-items: stretch
  }

  .lg\:tw-content-center{
    align-content: center
  }

  .lg\:tw-content-start{
    align-content: flex-start
  }

  .lg\:tw-content-end{
    align-content: flex-end
  }

  .lg\:tw-content-between{
    align-content: space-between
  }

  .lg\:tw-content-around{
    align-content: space-around
  }

  .lg\:tw-content-evenly{
    align-content: space-evenly
  }

  .lg\:tw-self-auto{
    align-self: auto
  }

  .lg\:tw-self-start{
    align-self: flex-start
  }

  .lg\:tw-self-end{
    align-self: flex-end
  }

  .lg\:tw-self-center{
    align-self: center
  }

  .lg\:tw-self-stretch{
    align-self: stretch
  }

  .lg\:tw-justify-items-auto{
    justify-items: auto
  }

  .lg\:tw-justify-items-start{
    justify-items: start
  }

  .lg\:tw-justify-items-end{
    justify-items: end
  }

  .lg\:tw-justify-items-center{
    justify-items: center
  }

  .lg\:tw-justify-items-stretch{
    justify-items: stretch
  }

  .lg\:tw-justify-start{
    justify-content: flex-start
  }

  .lg\:tw-justify-end{
    justify-content: flex-end
  }

  .lg\:tw-justify-center{
    justify-content: center
  }

  .lg\:tw-justify-between{
    justify-content: space-between
  }

  .lg\:tw-justify-around{
    justify-content: space-around
  }

  .lg\:tw-justify-evenly{
    justify-content: space-evenly
  }

  .lg\:tw-justify-self-auto{
    justify-self: auto
  }

  .lg\:tw-justify-self-start{
    justify-self: start
  }

  .lg\:tw-justify-self-end{
    justify-self: end
  }

  .lg\:tw-justify-self-center{
    justify-self: center
  }

  .lg\:tw-justify-self-stretch{
    justify-self: stretch
  }

  .lg\:tw-flex-1{
    flex: 1 1
  }

  .lg\:tw-flex-auto{
    flex: 1 1 auto
  }

  .lg\:tw-flex-initial{
    flex: 0 1 auto
  }

  .lg\:tw-flex-none{
    flex: none
  }

  .lg\:tw-flex-grow-0{
    flex-grow: 0
  }

  .lg\:tw-flex-grow{
    flex-grow: 1
  }

  .lg\:tw-flex-shrink-0{
    flex-shrink: 0
  }

  .lg\:tw-flex-shrink{
    flex-shrink: 1
  }

  .lg\:tw-order-1{
    order: 1
  }

  .lg\:tw-order-2{
    order: 2
  }

  .lg\:tw-order-3{
    order: 3
  }

  .lg\:tw-order-4{
    order: 4
  }

  .lg\:tw-order-5{
    order: 5
  }

  .lg\:tw-order-6{
    order: 6
  }

  .lg\:tw-order-7{
    order: 7
  }

  .lg\:tw-order-8{
    order: 8
  }

  .lg\:tw-order-9{
    order: 9
  }

  .lg\:tw-order-10{
    order: 10
  }

  .lg\:tw-order-11{
    order: 11
  }

  .lg\:tw-order-12{
    order: 12
  }

  .lg\:tw-order-first{
    order: -9999
  }

  .lg\:tw-order-last{
    order: 9999
  }

  .lg\:tw-order-none{
    order: 0
  }

  .lg\:tw-float-right{
    float: right
  }

  .lg\:tw-float-left{
    float: left
  }

  .lg\:tw-float-none{
    float: none
  }

  .lg\:tw-clearfix:after{
    content: "";
    display: table;
    clear: both
  }

  .lg\:tw-clear-left{
    clear: left
  }

  .lg\:tw-clear-right{
    clear: right
  }

  .lg\:tw-clear-both{
    clear: both
  }

  .lg\:tw-clear-none{
    clear: none
  }

  .lg\:tw-font-sans{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .lg\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .lg\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .lg\:tw-font-hairline{
    font-weight: 100
  }

  .lg\:tw-font-thin{
    font-weight: 200
  }

  .lg\:tw-font-light{
    font-weight: 300
  }

  .lg\:tw-font-normal{
    font-weight: 400
  }

  .lg\:tw-font-medium{
    font-weight: 500
  }

  .lg\:tw-font-semibold{
    font-weight: 600
  }

  .lg\:tw-font-bold{
    font-weight: 700
  }

  .lg\:tw-font-extrabold{
    font-weight: 800
  }

  .lg\:tw-font-black{
    font-weight: 900
  }

  .lg\:hover\:tw-font-hairline:hover{
    font-weight: 100
  }

  .lg\:hover\:tw-font-thin:hover{
    font-weight: 200
  }

  .lg\:hover\:tw-font-light:hover{
    font-weight: 300
  }

  .lg\:hover\:tw-font-normal:hover{
    font-weight: 400
  }

  .lg\:hover\:tw-font-medium:hover{
    font-weight: 500
  }

  .lg\:hover\:tw-font-semibold:hover{
    font-weight: 600
  }

  .lg\:hover\:tw-font-bold:hover{
    font-weight: 700
  }

  .lg\:hover\:tw-font-extrabold:hover{
    font-weight: 800
  }

  .lg\:hover\:tw-font-black:hover{
    font-weight: 900
  }

  .lg\:focus\:tw-font-hairline:focus{
    font-weight: 100
  }

  .lg\:focus\:tw-font-thin:focus{
    font-weight: 200
  }

  .lg\:focus\:tw-font-light:focus{
    font-weight: 300
  }

  .lg\:focus\:tw-font-normal:focus{
    font-weight: 400
  }

  .lg\:focus\:tw-font-medium:focus{
    font-weight: 500
  }

  .lg\:focus\:tw-font-semibold:focus{
    font-weight: 600
  }

  .lg\:focus\:tw-font-bold:focus{
    font-weight: 700
  }

  .lg\:focus\:tw-font-extrabold:focus{
    font-weight: 800
  }

  .lg\:focus\:tw-font-black:focus{
    font-weight: 900
  }

  .lg\:tw-h-0{
    height: 0
  }

  .lg\:tw-h-1{
    height: 0.25rem
  }

  .lg\:tw-h-2{
    height: 0.5rem
  }

  .lg\:tw-h-3{
    height: 0.75rem
  }

  .lg\:tw-h-4{
    height: 1rem
  }

  .lg\:tw-h-5{
    height: 1.25rem
  }

  .lg\:tw-h-6{
    height: 1.5rem
  }

  .lg\:tw-h-8{
    height: 2rem
  }

  .lg\:tw-h-10{
    height: 2.5rem
  }

  .lg\:tw-h-12{
    height: 3rem
  }

  .lg\:tw-h-16{
    height: 4rem
  }

  .lg\:tw-h-20{
    height: 5rem
  }

  .lg\:tw-h-24{
    height: 6rem
  }

  .lg\:tw-h-32{
    height: 8rem
  }

  .lg\:tw-h-40{
    height: 10rem
  }

  .lg\:tw-h-48{
    height: 12rem
  }

  .lg\:tw-h-56{
    height: 14rem
  }

  .lg\:tw-h-64{
    height: 16rem
  }

  .lg\:tw-h-auto{
    height: auto
  }

  .lg\:tw-h-px{
    height: 1px
  }

  .lg\:tw-h-full{
    height: 100%
  }

  .lg\:tw-h-screen{
    height: 100vh
  }

  .lg\:tw-text-xs{
    font-size: 0.75rem
  }

  .lg\:tw-text-sm{
    font-size: 0.875rem
  }

  .lg\:tw-text-base{
    font-size: 1rem
  }

  .lg\:tw-text-lg{
    font-size: 1.125rem
  }

  .lg\:tw-text-xl{
    font-size: 1.25rem
  }

  .lg\:tw-text-2xl{
    font-size: 1.5rem
  }

  .lg\:tw-text-3xl{
    font-size: 1.875rem
  }

  .lg\:tw-text-4xl{
    font-size: 2.25rem
  }

  .lg\:tw-text-5xl{
    font-size: 3rem
  }

  .lg\:tw-text-6xl{
    font-size: 4rem
  }

  .lg\:tw-leading-none{
    line-height: 1
  }

  .lg\:tw-leading-tight{
    line-height: 1.25
  }

  .lg\:tw-leading-snug{
    line-height: 1.375
  }

  .lg\:tw-leading-normal{
    line-height: 1.5
  }

  .lg\:tw-leading-relaxed{
    line-height: 1.625
  }

  .lg\:tw-leading-loose{
    line-height: 2
  }

  .lg\:tw-list-inside{
    list-style-position: inside
  }

  .lg\:tw-list-outside{
    list-style-position: outside
  }

  .lg\:tw-list-none{
    list-style-type: none
  }

  .lg\:tw-list-disc{
    list-style-type: disc
  }

  .lg\:tw-list-decimal{
    list-style-type: decimal
  }

  .lg\:tw-m-0{
    margin: 0
  }

  .lg\:tw-m-1{
    margin: 0.25rem
  }

  .lg\:tw-m-2{
    margin: 0.5rem
  }

  .lg\:tw-m-3{
    margin: 0.75rem
  }

  .lg\:tw-m-4{
    margin: 1rem
  }

  .lg\:tw-m-5{
    margin: 1.25rem
  }

  .lg\:tw-m-6{
    margin: 1.5rem
  }

  .lg\:tw-m-8{
    margin: 2rem
  }

  .lg\:tw-m-10{
    margin: 2.5rem
  }

  .lg\:tw-m-12{
    margin: 3rem
  }

  .lg\:tw-m-16{
    margin: 4rem
  }

  .lg\:tw-m-20{
    margin: 5rem
  }

  .lg\:tw-m-24{
    margin: 6rem
  }

  .lg\:tw-m-32{
    margin: 8rem
  }

  .lg\:tw-m-40{
    margin: 10rem
  }

  .lg\:tw-m-48{
    margin: 12rem
  }

  .lg\:tw-m-56{
    margin: 14rem
  }

  .lg\:tw-m-64{
    margin: 16rem
  }

  .lg\:tw-m-auto{
    margin: auto
  }

  .lg\:tw-m-px{
    margin: 1px
  }

  .lg\:tw--m-1{
    margin: -0.25rem
  }

  .lg\:tw--m-2{
    margin: -0.5rem
  }

  .lg\:tw--m-3{
    margin: -0.75rem
  }

  .lg\:tw--m-4{
    margin: -1rem
  }

  .lg\:tw--m-5{
    margin: -1.25rem
  }

  .lg\:tw--m-6{
    margin: -1.5rem
  }

  .lg\:tw--m-8{
    margin: -2rem
  }

  .lg\:tw--m-10{
    margin: -2.5rem
  }

  .lg\:tw--m-12{
    margin: -3rem
  }

  .lg\:tw--m-16{
    margin: -4rem
  }

  .lg\:tw--m-20{
    margin: -5rem
  }

  .lg\:tw--m-24{
    margin: -6rem
  }

  .lg\:tw--m-32{
    margin: -8rem
  }

  .lg\:tw--m-40{
    margin: -10rem
  }

  .lg\:tw--m-48{
    margin: -12rem
  }

  .lg\:tw--m-56{
    margin: -14rem
  }

  .lg\:tw--m-64{
    margin: -16rem
  }

  .lg\:tw--m-px{
    margin: -1px
  }

  .lg\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0
  }

  .lg\:tw-mx-0{
    margin-left: 0;
    margin-right: 0
  }

  .lg\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .lg\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .lg\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .lg\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .lg\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .lg\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .lg\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .lg\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  .lg\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .lg\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .lg\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .lg\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .lg\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .lg\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  .lg\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .lg\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .lg\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .lg\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  .lg\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .lg\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  .lg\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .lg\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  .lg\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .lg\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  .lg\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .lg\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  .lg\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .lg\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  .lg\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .lg\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  .lg\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .lg\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  .lg\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .lg\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  .lg\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  .lg\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  .lg\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  .lg\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  .lg\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .lg\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .lg\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .lg\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .lg\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .lg\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .lg\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .lg\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  .lg\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .lg\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .lg\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .lg\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .lg\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .lg\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  .lg\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .lg\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .lg\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .lg\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  .lg\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .lg\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  .lg\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .lg\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  .lg\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .lg\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  .lg\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .lg\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  .lg\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .lg\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  .lg\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .lg\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  .lg\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .lg\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  .lg\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .lg\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  .lg\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  .lg\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  .lg\:tw-mt-0{
    margin-top: 0
  }

  .lg\:tw-mr-0{
    margin-right: 0
  }

  .lg\:tw-mb-0{
    margin-bottom: 0
  }

  .lg\:tw-ml-0{
    margin-left: 0
  }

  .lg\:tw-mt-1{
    margin-top: 0.25rem
  }

  .lg\:tw-mr-1{
    margin-right: 0.25rem
  }

  .lg\:tw-mb-1{
    margin-bottom: 0.25rem
  }

  .lg\:tw-ml-1{
    margin-left: 0.25rem
  }

  .lg\:tw-mt-2{
    margin-top: 0.5rem
  }

  .lg\:tw-mr-2{
    margin-right: 0.5rem
  }

  .lg\:tw-mb-2{
    margin-bottom: 0.5rem
  }

  .lg\:tw-ml-2{
    margin-left: 0.5rem
  }

  .lg\:tw-mt-3{
    margin-top: 0.75rem
  }

  .lg\:tw-mr-3{
    margin-right: 0.75rem
  }

  .lg\:tw-mb-3{
    margin-bottom: 0.75rem
  }

  .lg\:tw-ml-3{
    margin-left: 0.75rem
  }

  .lg\:tw-mt-4{
    margin-top: 1rem
  }

  .lg\:tw-mr-4{
    margin-right: 1rem
  }

  .lg\:tw-mb-4{
    margin-bottom: 1rem
  }

  .lg\:tw-ml-4{
    margin-left: 1rem
  }

  .lg\:tw-mt-5{
    margin-top: 1.25rem
  }

  .lg\:tw-mr-5{
    margin-right: 1.25rem
  }

  .lg\:tw-mb-5{
    margin-bottom: 1.25rem
  }

  .lg\:tw-ml-5{
    margin-left: 1.25rem
  }

  .lg\:tw-mt-6{
    margin-top: 1.5rem
  }

  .lg\:tw-mr-6{
    margin-right: 1.5rem
  }

  .lg\:tw-mb-6{
    margin-bottom: 1.5rem
  }

  .lg\:tw-ml-6{
    margin-left: 1.5rem
  }

  .lg\:tw-mt-8{
    margin-top: 2rem
  }

  .lg\:tw-mr-8{
    margin-right: 2rem
  }

  .lg\:tw-mb-8{
    margin-bottom: 2rem
  }

  .lg\:tw-ml-8{
    margin-left: 2rem
  }

  .lg\:tw-mt-10{
    margin-top: 2.5rem
  }

  .lg\:tw-mr-10{
    margin-right: 2.5rem
  }

  .lg\:tw-mb-10{
    margin-bottom: 2.5rem
  }

  .lg\:tw-ml-10{
    margin-left: 2.5rem
  }

  .lg\:tw-mt-12{
    margin-top: 3rem
  }

  .lg\:tw-mr-12{
    margin-right: 3rem
  }

  .lg\:tw-mb-12{
    margin-bottom: 3rem
  }

  .lg\:tw-ml-12{
    margin-left: 3rem
  }

  .lg\:tw-mt-16{
    margin-top: 4rem
  }

  .lg\:tw-mr-16{
    margin-right: 4rem
  }

  .lg\:tw-mb-16{
    margin-bottom: 4rem
  }

  .lg\:tw-ml-16{
    margin-left: 4rem
  }

  .lg\:tw-mt-20{
    margin-top: 5rem
  }

  .lg\:tw-mr-20{
    margin-right: 5rem
  }

  .lg\:tw-mb-20{
    margin-bottom: 5rem
  }

  .lg\:tw-ml-20{
    margin-left: 5rem
  }

  .lg\:tw-mt-24{
    margin-top: 6rem
  }

  .lg\:tw-mr-24{
    margin-right: 6rem
  }

  .lg\:tw-mb-24{
    margin-bottom: 6rem
  }

  .lg\:tw-ml-24{
    margin-left: 6rem
  }

  .lg\:tw-mt-32{
    margin-top: 8rem
  }

  .lg\:tw-mr-32{
    margin-right: 8rem
  }

  .lg\:tw-mb-32{
    margin-bottom: 8rem
  }

  .lg\:tw-ml-32{
    margin-left: 8rem
  }

  .lg\:tw-mt-40{
    margin-top: 10rem
  }

  .lg\:tw-mr-40{
    margin-right: 10rem
  }

  .lg\:tw-mb-40{
    margin-bottom: 10rem
  }

  .lg\:tw-ml-40{
    margin-left: 10rem
  }

  .lg\:tw-mt-48{
    margin-top: 12rem
  }

  .lg\:tw-mr-48{
    margin-right: 12rem
  }

  .lg\:tw-mb-48{
    margin-bottom: 12rem
  }

  .lg\:tw-ml-48{
    margin-left: 12rem
  }

  .lg\:tw-mt-56{
    margin-top: 14rem
  }

  .lg\:tw-mr-56{
    margin-right: 14rem
  }

  .lg\:tw-mb-56{
    margin-bottom: 14rem
  }

  .lg\:tw-ml-56{
    margin-left: 14rem
  }

  .lg\:tw-mt-64{
    margin-top: 16rem
  }

  .lg\:tw-mr-64{
    margin-right: 16rem
  }

  .lg\:tw-mb-64{
    margin-bottom: 16rem
  }

  .lg\:tw-ml-64{
    margin-left: 16rem
  }

  .lg\:tw-mt-auto{
    margin-top: auto
  }

  .lg\:tw-mr-auto{
    margin-right: auto
  }

  .lg\:tw-mb-auto{
    margin-bottom: auto
  }

  .lg\:tw-ml-auto{
    margin-left: auto
  }

  .lg\:tw-mt-px{
    margin-top: 1px
  }

  .lg\:tw-mr-px{
    margin-right: 1px
  }

  .lg\:tw-mb-px{
    margin-bottom: 1px
  }

  .lg\:tw-ml-px{
    margin-left: 1px
  }

  .lg\:tw--mt-1{
    margin-top: -0.25rem
  }

  .lg\:tw--mr-1{
    margin-right: -0.25rem
  }

  .lg\:tw--mb-1{
    margin-bottom: -0.25rem
  }

  .lg\:tw--ml-1{
    margin-left: -0.25rem
  }

  .lg\:tw--mt-2{
    margin-top: -0.5rem
  }

  .lg\:tw--mr-2{
    margin-right: -0.5rem
  }

  .lg\:tw--mb-2{
    margin-bottom: -0.5rem
  }

  .lg\:tw--ml-2{
    margin-left: -0.5rem
  }

  .lg\:tw--mt-3{
    margin-top: -0.75rem
  }

  .lg\:tw--mr-3{
    margin-right: -0.75rem
  }

  .lg\:tw--mb-3{
    margin-bottom: -0.75rem
  }

  .lg\:tw--ml-3{
    margin-left: -0.75rem
  }

  .lg\:tw--mt-4{
    margin-top: -1rem
  }

  .lg\:tw--mr-4{
    margin-right: -1rem
  }

  .lg\:tw--mb-4{
    margin-bottom: -1rem
  }

  .lg\:tw--ml-4{
    margin-left: -1rem
  }

  .lg\:tw--mt-5{
    margin-top: -1.25rem
  }

  .lg\:tw--mr-5{
    margin-right: -1.25rem
  }

  .lg\:tw--mb-5{
    margin-bottom: -1.25rem
  }

  .lg\:tw--ml-5{
    margin-left: -1.25rem
  }

  .lg\:tw--mt-6{
    margin-top: -1.5rem
  }

  .lg\:tw--mr-6{
    margin-right: -1.5rem
  }

  .lg\:tw--mb-6{
    margin-bottom: -1.5rem
  }

  .lg\:tw--ml-6{
    margin-left: -1.5rem
  }

  .lg\:tw--mt-8{
    margin-top: -2rem
  }

  .lg\:tw--mr-8{
    margin-right: -2rem
  }

  .lg\:tw--mb-8{
    margin-bottom: -2rem
  }

  .lg\:tw--ml-8{
    margin-left: -2rem
  }

  .lg\:tw--mt-10{
    margin-top: -2.5rem
  }

  .lg\:tw--mr-10{
    margin-right: -2.5rem
  }

  .lg\:tw--mb-10{
    margin-bottom: -2.5rem
  }

  .lg\:tw--ml-10{
    margin-left: -2.5rem
  }

  .lg\:tw--mt-12{
    margin-top: -3rem
  }

  .lg\:tw--mr-12{
    margin-right: -3rem
  }

  .lg\:tw--mb-12{
    margin-bottom: -3rem
  }

  .lg\:tw--ml-12{
    margin-left: -3rem
  }

  .lg\:tw--mt-16{
    margin-top: -4rem
  }

  .lg\:tw--mr-16{
    margin-right: -4rem
  }

  .lg\:tw--mb-16{
    margin-bottom: -4rem
  }

  .lg\:tw--ml-16{
    margin-left: -4rem
  }

  .lg\:tw--mt-20{
    margin-top: -5rem
  }

  .lg\:tw--mr-20{
    margin-right: -5rem
  }

  .lg\:tw--mb-20{
    margin-bottom: -5rem
  }

  .lg\:tw--ml-20{
    margin-left: -5rem
  }

  .lg\:tw--mt-24{
    margin-top: -6rem
  }

  .lg\:tw--mr-24{
    margin-right: -6rem
  }

  .lg\:tw--mb-24{
    margin-bottom: -6rem
  }

  .lg\:tw--ml-24{
    margin-left: -6rem
  }

  .lg\:tw--mt-32{
    margin-top: -8rem
  }

  .lg\:tw--mr-32{
    margin-right: -8rem
  }

  .lg\:tw--mb-32{
    margin-bottom: -8rem
  }

  .lg\:tw--ml-32{
    margin-left: -8rem
  }

  .lg\:tw--mt-40{
    margin-top: -10rem
  }

  .lg\:tw--mr-40{
    margin-right: -10rem
  }

  .lg\:tw--mb-40{
    margin-bottom: -10rem
  }

  .lg\:tw--ml-40{
    margin-left: -10rem
  }

  .lg\:tw--mt-48{
    margin-top: -12rem
  }

  .lg\:tw--mr-48{
    margin-right: -12rem
  }

  .lg\:tw--mb-48{
    margin-bottom: -12rem
  }

  .lg\:tw--ml-48{
    margin-left: -12rem
  }

  .lg\:tw--mt-56{
    margin-top: -14rem
  }

  .lg\:tw--mr-56{
    margin-right: -14rem
  }

  .lg\:tw--mb-56{
    margin-bottom: -14rem
  }

  .lg\:tw--ml-56{
    margin-left: -14rem
  }

  .lg\:tw--mt-64{
    margin-top: -16rem
  }

  .lg\:tw--mr-64{
    margin-right: -16rem
  }

  .lg\:tw--mb-64{
    margin-bottom: -16rem
  }

  .lg\:tw--ml-64{
    margin-left: -16rem
  }

  .lg\:tw--mt-px{
    margin-top: -1px
  }

  .lg\:tw--mr-px{
    margin-right: -1px
  }

  .lg\:tw--mb-px{
    margin-bottom: -1px
  }

  .lg\:tw--ml-px{
    margin-left: -1px
  }

  .lg\:tw-max-h-full{
    max-height: 100%
  }

  .lg\:tw-max-h-screen{
    max-height: 100vh
  }

  .lg\:tw-max-w-xs{
    max-width: 20rem
  }

  .lg\:tw-max-w-sm{
    max-width: 24rem
  }

  .lg\:tw-max-w-md{
    max-width: 28rem
  }

  .lg\:tw-max-w-lg{
    max-width: 32rem
  }

  .lg\:tw-max-w-xl{
    max-width: 36rem
  }

  .lg\:tw-max-w-2xl{
    max-width: 42rem
  }

  .lg\:tw-max-w-3xl{
    max-width: 48rem
  }

  .lg\:tw-max-w-4xl{
    max-width: 56rem
  }

  .lg\:tw-max-w-5xl{
    max-width: 64rem
  }

  .lg\:tw-max-w-6xl{
    max-width: 72rem
  }

  .lg\:tw-max-w-full{
    max-width: 100%
  }

  .lg\:tw-min-h-0{
    min-height: 0
  }

  .lg\:tw-min-h-full{
    min-height: 100%
  }

  .lg\:tw-min-h-screen{
    min-height: 100vh
  }

  .lg\:tw-min-w-0{
    min-width: 0
  }

  .lg\:tw-min-w-full{
    min-width: 100%
  }

  .lg\:tw-object-contain{
    object-fit: contain
  }

  .lg\:tw-object-cover{
    object-fit: cover
  }

  .lg\:tw-object-fill{
    object-fit: fill
  }

  .lg\:tw-object-none{
    object-fit: none
  }

  .lg\:tw-object-scale-down{
    object-fit: scale-down
  }

  .lg\:tw-object-bottom{
    object-position: bottom
  }

  .lg\:tw-object-center{
    object-position: center
  }

  .lg\:tw-object-left{
    object-position: left
  }

  .lg\:tw-object-left-bottom{
    object-position: left bottom
  }

  .lg\:tw-object-left-top{
    object-position: left top
  }

  .lg\:tw-object-right{
    object-position: right
  }

  .lg\:tw-object-right-bottom{
    object-position: right bottom
  }

  .lg\:tw-object-right-top{
    object-position: right top
  }

  .lg\:tw-object-top{
    object-position: top
  }

  .lg\:tw-opacity-0{
    opacity: 0
  }

  .lg\:tw-opacity-25{
    opacity: 0.25
  }

  .lg\:tw-opacity-50{
    opacity: 0.5
  }

  .lg\:tw-opacity-75{
    opacity: 0.75
  }

  .lg\:tw-opacity-100{
    opacity: 1
  }

  .lg\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:tw-overflow-auto{
    overflow: auto
  }

  .lg\:tw-overflow-hidden{
    overflow: hidden
  }

  .lg\:tw-overflow-visible{
    overflow: visible
  }

  .lg\:tw-overflow-scroll{
    overflow: scroll
  }

  .lg\:tw-overflow-x-auto{
    overflow-x: auto
  }

  .lg\:tw-overflow-y-auto{
    overflow-y: auto
  }

  .lg\:tw-overflow-x-hidden{
    overflow-x: hidden
  }

  .lg\:tw-overflow-y-hidden{
    overflow-y: hidden
  }

  .lg\:tw-overflow-x-visible{
    overflow-x: visible
  }

  .lg\:tw-overflow-y-visible{
    overflow-y: visible
  }

  .lg\:tw-overflow-x-scroll{
    overflow-x: scroll
  }

  .lg\:tw-overflow-y-scroll{
    overflow-y: scroll
  }

  .lg\:tw-scrolling-touch{
    -webkit-overflow-scrolling: touch
  }

  .lg\:tw-scrolling-auto{
    -webkit-overflow-scrolling: auto
  }

  .lg\:tw-overscroll-auto{
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .lg\:tw-overscroll-contain{
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .lg\:tw-overscroll-none{
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .lg\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  .lg\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  .lg\:tw-overscroll-y-none{
    overscroll-behavior-y: none
  }

  .lg\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  .lg\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  .lg\:tw-overscroll-x-none{
    overscroll-behavior-x: none
  }

  .lg\:tw-p-0{
    padding: 0
  }

  .lg\:tw-p-1{
    padding: 0.25rem
  }

  .lg\:tw-p-2{
    padding: 0.5rem
  }

  .lg\:tw-p-3{
    padding: 0.75rem
  }

  .lg\:tw-p-4{
    padding: 1rem
  }

  .lg\:tw-p-5{
    padding: 1.25rem
  }

  .lg\:tw-p-6{
    padding: 1.5rem
  }

  .lg\:tw-p-8{
    padding: 2rem
  }

  .lg\:tw-p-10{
    padding: 2.5rem
  }

  .lg\:tw-p-12{
    padding: 3rem
  }

  .lg\:tw-p-16{
    padding: 4rem
  }

  .lg\:tw-p-20{
    padding: 5rem
  }

  .lg\:tw-p-24{
    padding: 6rem
  }

  .lg\:tw-p-32{
    padding: 8rem
  }

  .lg\:tw-p-40{
    padding: 10rem
  }

  .lg\:tw-p-48{
    padding: 12rem
  }

  .lg\:tw-p-56{
    padding: 14rem
  }

  .lg\:tw-p-64{
    padding: 16rem
  }

  .lg\:tw-p-px{
    padding: 1px
  }

  .lg\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:tw-px-0{
    padding-left: 0;
    padding-right: 0
  }

  .lg\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .lg\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .lg\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .lg\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .lg\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .lg\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .lg\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .lg\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .lg\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .lg\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .lg\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .lg\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .lg\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  .lg\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  .lg\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .lg\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  .lg\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  .lg\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .lg\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  .lg\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .lg\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  .lg\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .lg\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  .lg\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .lg\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  .lg\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  .lg\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  .lg\:tw-pt-0{
    padding-top: 0
  }

  .lg\:tw-pr-0{
    padding-right: 0
  }

  .lg\:tw-pb-0{
    padding-bottom: 0
  }

  .lg\:tw-pl-0{
    padding-left: 0
  }

  .lg\:tw-pt-1{
    padding-top: 0.25rem
  }

  .lg\:tw-pr-1{
    padding-right: 0.25rem
  }

  .lg\:tw-pb-1{
    padding-bottom: 0.25rem
  }

  .lg\:tw-pl-1{
    padding-left: 0.25rem
  }

  .lg\:tw-pt-2{
    padding-top: 0.5rem
  }

  .lg\:tw-pr-2{
    padding-right: 0.5rem
  }

  .lg\:tw-pb-2{
    padding-bottom: 0.5rem
  }

  .lg\:tw-pl-2{
    padding-left: 0.5rem
  }

  .lg\:tw-pt-3{
    padding-top: 0.75rem
  }

  .lg\:tw-pr-3{
    padding-right: 0.75rem
  }

  .lg\:tw-pb-3{
    padding-bottom: 0.75rem
  }

  .lg\:tw-pl-3{
    padding-left: 0.75rem
  }

  .lg\:tw-pt-4{
    padding-top: 1rem
  }

  .lg\:tw-pr-4{
    padding-right: 1rem
  }

  .lg\:tw-pb-4{
    padding-bottom: 1rem
  }

  .lg\:tw-pl-4{
    padding-left: 1rem
  }

  .lg\:tw-pt-5{
    padding-top: 1.25rem
  }

  .lg\:tw-pr-5{
    padding-right: 1.25rem
  }

  .lg\:tw-pb-5{
    padding-bottom: 1.25rem
  }

  .lg\:tw-pl-5{
    padding-left: 1.25rem
  }

  .lg\:tw-pt-6{
    padding-top: 1.5rem
  }

  .lg\:tw-pr-6{
    padding-right: 1.5rem
  }

  .lg\:tw-pb-6{
    padding-bottom: 1.5rem
  }

  .lg\:tw-pl-6{
    padding-left: 1.5rem
  }

  .lg\:tw-pt-8{
    padding-top: 2rem
  }

  .lg\:tw-pr-8{
    padding-right: 2rem
  }

  .lg\:tw-pb-8{
    padding-bottom: 2rem
  }

  .lg\:tw-pl-8{
    padding-left: 2rem
  }

  .lg\:tw-pt-10{
    padding-top: 2.5rem
  }

  .lg\:tw-pr-10{
    padding-right: 2.5rem
  }

  .lg\:tw-pb-10{
    padding-bottom: 2.5rem
  }

  .lg\:tw-pl-10{
    padding-left: 2.5rem
  }

  .lg\:tw-pt-12{
    padding-top: 3rem
  }

  .lg\:tw-pr-12{
    padding-right: 3rem
  }

  .lg\:tw-pb-12{
    padding-bottom: 3rem
  }

  .lg\:tw-pl-12{
    padding-left: 3rem
  }

  .lg\:tw-pt-16{
    padding-top: 4rem
  }

  .lg\:tw-pr-16{
    padding-right: 4rem
  }

  .lg\:tw-pb-16{
    padding-bottom: 4rem
  }

  .lg\:tw-pl-16{
    padding-left: 4rem
  }

  .lg\:tw-pt-20{
    padding-top: 5rem
  }

  .lg\:tw-pr-20{
    padding-right: 5rem
  }

  .lg\:tw-pb-20{
    padding-bottom: 5rem
  }

  .lg\:tw-pl-20{
    padding-left: 5rem
  }

  .lg\:tw-pt-24{
    padding-top: 6rem
  }

  .lg\:tw-pr-24{
    padding-right: 6rem
  }

  .lg\:tw-pb-24{
    padding-bottom: 6rem
  }

  .lg\:tw-pl-24{
    padding-left: 6rem
  }

  .lg\:tw-pt-32{
    padding-top: 8rem
  }

  .lg\:tw-pr-32{
    padding-right: 8rem
  }

  .lg\:tw-pb-32{
    padding-bottom: 8rem
  }

  .lg\:tw-pl-32{
    padding-left: 8rem
  }

  .lg\:tw-pt-40{
    padding-top: 10rem
  }

  .lg\:tw-pr-40{
    padding-right: 10rem
  }

  .lg\:tw-pb-40{
    padding-bottom: 10rem
  }

  .lg\:tw-pl-40{
    padding-left: 10rem
  }

  .lg\:tw-pt-48{
    padding-top: 12rem
  }

  .lg\:tw-pr-48{
    padding-right: 12rem
  }

  .lg\:tw-pb-48{
    padding-bottom: 12rem
  }

  .lg\:tw-pl-48{
    padding-left: 12rem
  }

  .lg\:tw-pt-56{
    padding-top: 14rem
  }

  .lg\:tw-pr-56{
    padding-right: 14rem
  }

  .lg\:tw-pb-56{
    padding-bottom: 14rem
  }

  .lg\:tw-pl-56{
    padding-left: 14rem
  }

  .lg\:tw-pt-64{
    padding-top: 16rem
  }

  .lg\:tw-pr-64{
    padding-right: 16rem
  }

  .lg\:tw-pb-64{
    padding-bottom: 16rem
  }

  .lg\:tw-pl-64{
    padding-left: 16rem
  }

  .lg\:tw-pt-px{
    padding-top: 1px
  }

  .lg\:tw-pr-px{
    padding-right: 1px
  }

  .lg\:tw-pb-px{
    padding-bottom: 1px
  }

  .lg\:tw-pl-px{
    padding-left: 1px
  }

  .lg\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent
  }

  .lg\:tw-placeholder-transparent::-moz-placeholder{
    color: transparent
  }

  .lg\:tw-placeholder-transparent:-ms-input-placeholder{
    color: transparent
  }

  .lg\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent
  }

  .lg\:tw-placeholder-transparent::placeholder{
    color: transparent
  }

  .lg\:tw-placeholder-tlg-green-dark::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green-dark::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green-dark:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green-dark::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green-dark::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-green::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-tlg-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-black::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-black::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-black:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-black::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-black::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-white::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-white::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-white:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-white::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-white::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-100::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-200::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-300::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-400::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-500::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-600::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-700::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-800::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-gray-900::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-300::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-400::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-500::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-600::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-700::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-800::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-red-900::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-200::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-600::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-700::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-800::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-orange-900::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-200::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-300::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-500::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-600::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-700::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-800::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-yellow-900::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-100::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-200::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-300::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-400::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-500::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-600::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-700::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-800::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-green-900::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-100::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-200::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-300::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-400::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-500::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-600::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-700::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-800::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-teal-900::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-200::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-300::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-400::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-500::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-600::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-700::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-800::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-blue-900::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-200::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-300::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-400::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-500::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-600::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-700::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-800::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-indigo-900::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-100::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-200::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-300::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-400::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-500::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-600::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-700::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-800::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-purple-900::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-400::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-600::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-700::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-800::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-pink-900::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent
  }

  .lg\:focus\:tw-placeholder-transparent:focus::-moz-placeholder{
    color: transparent
  }

  .lg\:focus\:tw-placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent
  }

  .lg\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent
  }

  .lg\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  .lg\:focus\:tw-placeholder-tlg-green-dark:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green-dark:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green-dark:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green-dark:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green-dark:focus::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-green:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-tlg-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-black:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-black:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-black:focus::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-white:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-white:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-white:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-red-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-orange-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-green-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-teal-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-purple-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:focus\:tw-placeholder-pink-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .lg\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:tw-placeholder-opacity-0::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:tw-placeholder-opacity-0:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:tw-placeholder-opacity-0::placeholder{
    --placeholder-opacity: 0
  }

  .lg\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:tw-placeholder-opacity-25::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:tw-placeholder-opacity-25:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:tw-placeholder-opacity-25::placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:tw-placeholder-opacity-50::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:tw-placeholder-opacity-50:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:tw-placeholder-opacity-50::placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:tw-placeholder-opacity-75::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:tw-placeholder-opacity-75:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:tw-placeholder-opacity-75::placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:tw-placeholder-opacity-100::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:tw-placeholder-opacity-100:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:tw-placeholder-opacity-100::placeholder{
    --placeholder-opacity: 1
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --placeholder-opacity: 0
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --placeholder-opacity: 1
  }

  .lg\:tw-pointer-events-none{
    pointer-events: none
  }

  .lg\:tw-pointer-events-auto{
    pointer-events: auto
  }

  .lg\:tw-static{
    position: static
  }

  .lg\:tw-fixed{
    position: fixed
  }

  .lg\:tw-absolute{
    position: absolute
  }

  .lg\:tw-relative{
    position: relative
  }

  .lg\:tw-sticky{
    position: -webkit-sticky;
    position: sticky
  }

  .lg\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .lg\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .lg\:tw-inset-y-0{
    top: 0;
    bottom: 0
  }

  .lg\:tw-inset-x-0{
    right: 0;
    left: 0
  }

  .lg\:tw-inset-y-auto{
    top: auto;
    bottom: auto
  }

  .lg\:tw-inset-x-auto{
    right: auto;
    left: auto
  }

  .lg\:tw-top-0{
    top: 0
  }

  .lg\:tw-right-0{
    right: 0
  }

  .lg\:tw-bottom-0{
    bottom: 0
  }

  .lg\:tw-left-0{
    left: 0
  }

  .lg\:tw-top-auto{
    top: auto
  }

  .lg\:tw-right-auto{
    right: auto
  }

  .lg\:tw-bottom-auto{
    bottom: auto
  }

  .lg\:tw-left-auto{
    left: auto
  }

  .lg\:tw-resize-none{
    resize: none
  }

  .lg\:tw-resize-y{
    resize: vertical
  }

  .lg\:tw-resize-x{
    resize: horizontal
  }

  .lg\:tw-resize{
    resize: both
  }

  .lg\:tw-shadow{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:tw-shadow-md{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:tw-shadow-lg{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:tw-shadow-xl{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:tw-shadow-2xl{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:tw-shadow-inner{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:tw-shadow-outline{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:tw-shadow-none{
    box-shadow: none
  }

  .lg\:hover\:tw-shadow:hover{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:tw-shadow-md:hover{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:tw-shadow-lg:hover{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:tw-shadow-xl:hover{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:tw-shadow-2xl:hover{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:hover\:tw-shadow-inner:hover{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:tw-shadow-outline:hover{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:hover\:tw-shadow-none:hover{
    box-shadow: none
  }

  .lg\:focus\:tw-shadow:focus{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:tw-shadow-md:focus{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:tw-shadow-lg:focus{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:tw-shadow-xl:focus{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:tw-shadow-2xl:focus{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:focus\:tw-shadow-inner:focus{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:tw-shadow-outline:focus{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:focus\:tw-shadow-none:focus{
    box-shadow: none
  }

  .lg\:tw-fill-current{
    fill: currentColor
  }

  .lg\:tw-stroke-current{
    stroke: currentColor
  }

  .lg\:tw-stroke-0{
    stroke-width: 0
  }

  .lg\:tw-stroke-1{
    stroke-width: 1
  }

  .lg\:tw-stroke-2{
    stroke-width: 2
  }

  .lg\:tw-table-auto{
    table-layout: auto
  }

  .lg\:tw-table-fixed{
    table-layout: fixed
  }

  .lg\:tw-text-left{
    text-align: left
  }

  .lg\:tw-text-center{
    text-align: center
  }

  .lg\:tw-text-right{
    text-align: right
  }

  .lg\:tw-text-justify{
    text-align: justify
  }

  .lg\:tw-text-transparent{
    color: transparent
  }

  .lg\:tw-text-tlg-green-dark{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .lg\:tw-text-tlg-green{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .lg\:tw-text-tlg-orange-500{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .lg\:tw-text-black{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:tw-text-white{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:tw-text-gray-100{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .lg\:tw-text-gray-200{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .lg\:tw-text-gray-300{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .lg\:tw-text-gray-400{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .lg\:tw-text-gray-500{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .lg\:tw-text-gray-600{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .lg\:tw-text-gray-700{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .lg\:tw-text-gray-800{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .lg\:tw-text-gray-900{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .lg\:tw-text-red-100{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .lg\:tw-text-red-200{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .lg\:tw-text-red-300{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .lg\:tw-text-red-400{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .lg\:tw-text-red-500{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .lg\:tw-text-red-600{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .lg\:tw-text-red-700{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .lg\:tw-text-red-800{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .lg\:tw-text-red-900{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .lg\:tw-text-orange-100{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .lg\:tw-text-orange-200{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .lg\:tw-text-orange-300{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .lg\:tw-text-orange-400{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .lg\:tw-text-orange-500{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .lg\:tw-text-orange-600{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .lg\:tw-text-orange-700{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .lg\:tw-text-orange-800{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .lg\:tw-text-orange-900{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .lg\:tw-text-yellow-100{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .lg\:tw-text-yellow-200{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .lg\:tw-text-yellow-300{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .lg\:tw-text-yellow-400{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .lg\:tw-text-yellow-500{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .lg\:tw-text-yellow-600{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .lg\:tw-text-yellow-700{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .lg\:tw-text-yellow-800{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .lg\:tw-text-yellow-900{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .lg\:tw-text-green-100{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .lg\:tw-text-green-200{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .lg\:tw-text-green-300{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .lg\:tw-text-green-400{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .lg\:tw-text-green-500{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .lg\:tw-text-green-600{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .lg\:tw-text-green-700{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .lg\:tw-text-green-800{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .lg\:tw-text-green-900{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .lg\:tw-text-teal-100{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .lg\:tw-text-teal-200{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .lg\:tw-text-teal-300{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .lg\:tw-text-teal-400{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .lg\:tw-text-teal-500{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .lg\:tw-text-teal-600{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .lg\:tw-text-teal-700{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .lg\:tw-text-teal-800{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .lg\:tw-text-teal-900{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .lg\:tw-text-blue-100{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .lg\:tw-text-blue-200{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .lg\:tw-text-blue-300{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .lg\:tw-text-blue-400{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .lg\:tw-text-blue-500{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .lg\:tw-text-blue-600{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .lg\:tw-text-blue-700{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .lg\:tw-text-blue-800{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .lg\:tw-text-blue-900{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .lg\:tw-text-indigo-100{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .lg\:tw-text-indigo-200{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .lg\:tw-text-indigo-300{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .lg\:tw-text-indigo-400{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .lg\:tw-text-indigo-500{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .lg\:tw-text-indigo-600{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .lg\:tw-text-indigo-700{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .lg\:tw-text-indigo-800{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .lg\:tw-text-indigo-900{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .lg\:tw-text-purple-100{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .lg\:tw-text-purple-200{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .lg\:tw-text-purple-300{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .lg\:tw-text-purple-400{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .lg\:tw-text-purple-500{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .lg\:tw-text-purple-600{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .lg\:tw-text-purple-700{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .lg\:tw-text-purple-800{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .lg\:tw-text-purple-900{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .lg\:tw-text-pink-100{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .lg\:tw-text-pink-200{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .lg\:tw-text-pink-300{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .lg\:tw-text-pink-400{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .lg\:tw-text-pink-500{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .lg\:tw-text-pink-600{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .lg\:tw-text-pink-700{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .lg\:tw-text-pink-800{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .lg\:tw-text-pink-900{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .lg\:hover\:tw-text-transparent:hover{
    color: transparent
  }

  .lg\:hover\:tw-text-tlg-green-dark:hover{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .lg\:hover\:tw-text-tlg-green:hover{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .lg\:hover\:tw-text-tlg-orange-500:hover{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .lg\:hover\:tw-text-black:hover{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:hover\:tw-text-white:hover{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-100:hover{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-200:hover{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-300:hover{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-400:hover{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-500:hover{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-600:hover{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-700:hover{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-800:hover{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .lg\:hover\:tw-text-gray-900:hover{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-100:hover{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-200:hover{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-300:hover{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-400:hover{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-500:hover{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-600:hover{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-700:hover{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-800:hover{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .lg\:hover\:tw-text-red-900:hover{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-100:hover{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-200:hover{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-300:hover{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-400:hover{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-500:hover{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-600:hover{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-700:hover{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-800:hover{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .lg\:hover\:tw-text-orange-900:hover{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-100:hover{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-200:hover{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-300:hover{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-400:hover{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-500:hover{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-600:hover{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-700:hover{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-800:hover{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .lg\:hover\:tw-text-yellow-900:hover{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-100:hover{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-200:hover{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-300:hover{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-400:hover{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-500:hover{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-600:hover{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-700:hover{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-800:hover{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .lg\:hover\:tw-text-green-900:hover{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-100:hover{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-200:hover{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-300:hover{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-400:hover{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-500:hover{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-600:hover{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-700:hover{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-800:hover{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .lg\:hover\:tw-text-teal-900:hover{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-100:hover{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-200:hover{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-300:hover{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-400:hover{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-500:hover{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-600:hover{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-700:hover{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-800:hover{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .lg\:hover\:tw-text-blue-900:hover{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-100:hover{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-200:hover{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-300:hover{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-400:hover{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-500:hover{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-600:hover{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-700:hover{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-800:hover{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .lg\:hover\:tw-text-indigo-900:hover{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-100:hover{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-200:hover{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-300:hover{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-400:hover{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-500:hover{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-600:hover{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-700:hover{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-800:hover{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .lg\:hover\:tw-text-purple-900:hover{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-100:hover{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-200:hover{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-300:hover{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-400:hover{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-500:hover{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-600:hover{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-700:hover{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-800:hover{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .lg\:hover\:tw-text-pink-900:hover{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .lg\:focus\:tw-text-transparent:focus{
    color: transparent
  }

  .lg\:focus\:tw-text-tlg-green-dark:focus{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .lg\:focus\:tw-text-tlg-green:focus{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .lg\:focus\:tw-text-tlg-orange-500:focus{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .lg\:focus\:tw-text-black:focus{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:focus\:tw-text-white:focus{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-100:focus{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-200:focus{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-300:focus{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-400:focus{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-500:focus{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-600:focus{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-700:focus{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-800:focus{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .lg\:focus\:tw-text-gray-900:focus{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-100:focus{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-200:focus{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-300:focus{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-400:focus{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-500:focus{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-600:focus{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-700:focus{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-800:focus{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .lg\:focus\:tw-text-red-900:focus{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-100:focus{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-200:focus{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-300:focus{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-400:focus{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-500:focus{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-600:focus{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-700:focus{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-800:focus{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .lg\:focus\:tw-text-orange-900:focus{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-100:focus{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-200:focus{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-300:focus{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-400:focus{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-500:focus{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-600:focus{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-700:focus{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-800:focus{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .lg\:focus\:tw-text-yellow-900:focus{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-100:focus{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-200:focus{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-300:focus{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-400:focus{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-500:focus{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-600:focus{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-700:focus{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-800:focus{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .lg\:focus\:tw-text-green-900:focus{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-100:focus{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-200:focus{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-300:focus{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-400:focus{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-500:focus{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-600:focus{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-700:focus{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-800:focus{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .lg\:focus\:tw-text-teal-900:focus{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-100:focus{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-200:focus{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-300:focus{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-400:focus{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-500:focus{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-600:focus{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-700:focus{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-800:focus{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .lg\:focus\:tw-text-blue-900:focus{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-100:focus{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-200:focus{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-300:focus{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-400:focus{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-500:focus{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-600:focus{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-700:focus{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-800:focus{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .lg\:focus\:tw-text-indigo-900:focus{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-100:focus{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-200:focus{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-300:focus{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-400:focus{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-500:focus{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-600:focus{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-700:focus{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-800:focus{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .lg\:focus\:tw-text-purple-900:focus{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-100:focus{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-200:focus{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-300:focus{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-400:focus{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-500:focus{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-600:focus{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-700:focus{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-800:focus{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .lg\:focus\:tw-text-pink-900:focus{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .lg\:tw-text-opacity-0{
    --text-opacity: 0
  }

  .lg\:tw-text-opacity-25{
    --text-opacity: 0.25
  }

  .lg\:tw-text-opacity-50{
    --text-opacity: 0.5
  }

  .lg\:tw-text-opacity-75{
    --text-opacity: 0.75
  }

  .lg\:tw-text-opacity-100{
    --text-opacity: 1
  }

  .lg\:hover\:tw-text-opacity-0:hover{
    --text-opacity: 0
  }

  .lg\:hover\:tw-text-opacity-25:hover{
    --text-opacity: 0.25
  }

  .lg\:hover\:tw-text-opacity-50:hover{
    --text-opacity: 0.5
  }

  .lg\:hover\:tw-text-opacity-75:hover{
    --text-opacity: 0.75
  }

  .lg\:hover\:tw-text-opacity-100:hover{
    --text-opacity: 1
  }

  .lg\:focus\:tw-text-opacity-0:focus{
    --text-opacity: 0
  }

  .lg\:focus\:tw-text-opacity-25:focus{
    --text-opacity: 0.25
  }

  .lg\:focus\:tw-text-opacity-50:focus{
    --text-opacity: 0.5
  }

  .lg\:focus\:tw-text-opacity-75:focus{
    --text-opacity: 0.75
  }

  .lg\:focus\:tw-text-opacity-100:focus{
    --text-opacity: 1
  }

  .lg\:tw-italic{
    font-style: italic
  }

  .lg\:tw-not-italic{
    font-style: normal
  }

  .lg\:tw-uppercase{
    text-transform: uppercase
  }

  .lg\:tw-lowercase{
    text-transform: lowercase
  }

  .lg\:tw-capitalize{
    text-transform: capitalize
  }

  .lg\:tw-normal-case{
    text-transform: none
  }

  .lg\:tw-underline{
    text-decoration: underline
  }

  .lg\:tw-line-through{
    text-decoration: line-through
  }

  .lg\:tw-no-underline{
    text-decoration: none
  }

  .lg\:hover\:tw-underline:hover{
    text-decoration: underline
  }

  .lg\:hover\:tw-line-through:hover{
    text-decoration: line-through
  }

  .lg\:hover\:tw-no-underline:hover{
    text-decoration: none
  }

  .lg\:focus\:tw-underline:focus{
    text-decoration: underline
  }

  .lg\:focus\:tw-line-through:focus{
    text-decoration: line-through
  }

  .lg\:focus\:tw-no-underline:focus{
    text-decoration: none
  }

  .lg\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .lg\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .lg\:tw-ordinal, .lg\:tw-slashed-zero, .lg\:tw-lining-nums, .lg\:tw-oldstyle-nums, .lg\:tw-proportional-nums, .lg\:tw-tabular-nums, .lg\:tw-diagonal-fractions, .lg\:tw-stacked-fractions{
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    -webkit-font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
            font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .lg\:tw-normal-nums{
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-numeric: normal
  }

  .lg\:tw-ordinal{
    --font-variant-numeric-ordinal: ordinal
  }

  .lg\:tw-slashed-zero{
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .lg\:tw-lining-nums{
    --font-variant-numeric-figure: lining-nums
  }

  .lg\:tw-oldstyle-nums{
    --font-variant-numeric-figure: oldstyle-nums
  }

  .lg\:tw-proportional-nums{
    --font-variant-numeric-spacing: proportional-nums
  }

  .lg\:tw-tabular-nums{
    --font-variant-numeric-spacing: tabular-nums
  }

  .lg\:tw-diagonal-fractions{
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .lg\:tw-stacked-fractions{
    --font-variant-numeric-fraction: stacked-fractions
  }

  .lg\:tw-tracking-tighter{
    letter-spacing: -0.05em
  }

  .lg\:tw-tracking-tight{
    letter-spacing: -0.025em
  }

  .lg\:tw-tracking-normal{
    letter-spacing: 0
  }

  .lg\:tw-tracking-wide{
    letter-spacing: 0.025em
  }

  .lg\:tw-tracking-wider{
    letter-spacing: 0.05em
  }

  .lg\:tw-tracking-widest{
    letter-spacing: 0.1em
  }

  .lg\:tw-select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .lg\:tw-select-text{
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .lg\:tw-select-all{
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .lg\:tw-select-auto{
    -webkit-user-select: auto;
       -moz-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .lg\:tw-align-baseline{
    vertical-align: baseline
  }

  .lg\:tw-align-top{
    vertical-align: top
  }

  .lg\:tw-align-middle{
    vertical-align: middle
  }

  .lg\:tw-align-bottom{
    vertical-align: bottom
  }

  .lg\:tw-align-text-top{
    vertical-align: text-top
  }

  .lg\:tw-align-text-bottom{
    vertical-align: text-bottom
  }

  .lg\:tw-visible{
    visibility: visible
  }

  .lg\:tw-invisible{
    visibility: hidden
  }

  .lg\:tw-whitespace-normal{
    white-space: normal
  }

  .lg\:tw-whitespace-no-wrap{
    white-space: nowrap
  }

  .lg\:tw-whitespace-pre{
    white-space: pre
  }

  .lg\:tw-whitespace-pre-line{
    white-space: pre-line
  }

  .lg\:tw-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  .lg\:tw-break-normal{
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .lg\:tw-break-words{
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .lg\:tw-break-all{
    word-break: break-all
  }

  .lg\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .lg\:tw-w-0{
    width: 0
  }

  .lg\:tw-w-1{
    width: 0.25rem
  }

  .lg\:tw-w-2{
    width: 0.5rem
  }

  .lg\:tw-w-3{
    width: 0.75rem
  }

  .lg\:tw-w-4{
    width: 1rem
  }

  .lg\:tw-w-5{
    width: 1.25rem
  }

  .lg\:tw-w-6{
    width: 1.5rem
  }

  .lg\:tw-w-8{
    width: 2rem
  }

  .lg\:tw-w-10{
    width: 2.5rem
  }

  .lg\:tw-w-12{
    width: 3rem
  }

  .lg\:tw-w-16{
    width: 4rem
  }

  .lg\:tw-w-20{
    width: 5rem
  }

  .lg\:tw-w-24{
    width: 6rem
  }

  .lg\:tw-w-32{
    width: 8rem
  }

  .lg\:tw-w-40{
    width: 10rem
  }

  .lg\:tw-w-48{
    width: 12rem
  }

  .lg\:tw-w-56{
    width: 14rem
  }

  .lg\:tw-w-64{
    width: 16rem
  }

  .lg\:tw-w-auto{
    width: auto
  }

  .lg\:tw-w-px{
    width: 1px
  }

  .lg\:tw-w-1\/2{
    width: 50%
  }

  .lg\:tw-w-1\/3{
    width: 33.33333%
  }

  .lg\:tw-w-2\/3{
    width: 66.66667%
  }

  .lg\:tw-w-1\/4{
    width: 25%
  }

  .lg\:tw-w-2\/4{
    width: 50%
  }

  .lg\:tw-w-3\/4{
    width: 75%
  }

  .lg\:tw-w-1\/5{
    width: 20%
  }

  .lg\:tw-w-2\/5{
    width: 40%
  }

  .lg\:tw-w-3\/5{
    width: 60%
  }

  .lg\:tw-w-4\/5{
    width: 80%
  }

  .lg\:tw-w-1\/6{
    width: 16.66667%
  }

  .lg\:tw-w-2\/6{
    width: 33.33333%
  }

  .lg\:tw-w-3\/6{
    width: 50%
  }

  .lg\:tw-w-4\/6{
    width: 66.66667%
  }

  .lg\:tw-w-5\/6{
    width: 83.33333%
  }

  .lg\:tw-w-1\/12{
    width: 8.33333%
  }

  .lg\:tw-w-2\/12{
    width: 16.66667%
  }

  .lg\:tw-w-3\/12{
    width: 25%
  }

  .lg\:tw-w-4\/12{
    width: 33.33333%
  }

  .lg\:tw-w-5\/12{
    width: 41.66667%
  }

  .lg\:tw-w-6\/12{
    width: 50%
  }

  .lg\:tw-w-7\/12{
    width: 58.33333%
  }

  .lg\:tw-w-8\/12{
    width: 66.66667%
  }

  .lg\:tw-w-9\/12{
    width: 75%
  }

  .lg\:tw-w-10\/12{
    width: 83.33333%
  }

  .lg\:tw-w-11\/12{
    width: 91.66667%
  }

  .lg\:tw-w-full{
    width: 100%
  }

  .lg\:tw-w-screen{
    width: 100vw
  }

  .lg\:tw-z-0{
    z-index: 0
  }

  .lg\:tw-z-10{
    z-index: 10
  }

  .lg\:tw-z-20{
    z-index: 20
  }

  .lg\:tw-z-30{
    z-index: 30
  }

  .lg\:tw-z-40{
    z-index: 40
  }

  .lg\:tw-z-50{
    z-index: 50
  }

  .lg\:tw-z-auto{
    z-index: auto
  }

  .lg\:tw-gap-0{
    grid-gap: 0;
    grid-gap: 0;
    gap: 0
  }

  .lg\:tw-gap-1{
    grid-gap: 0.25rem;
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .lg\:tw-gap-2{
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .lg\:tw-gap-3{
    grid-gap: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .lg\:tw-gap-4{
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem
  }

  .lg\:tw-gap-5{
    grid-gap: 1.25rem;
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .lg\:tw-gap-6{
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .lg\:tw-gap-8{
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem
  }

  .lg\:tw-gap-10{
    grid-gap: 2.5rem;
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .lg\:tw-gap-12{
    grid-gap: 3rem;
    grid-gap: 3rem;
    gap: 3rem
  }

  .lg\:tw-gap-16{
    grid-gap: 4rem;
    grid-gap: 4rem;
    gap: 4rem
  }

  .lg\:tw-gap-20{
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem
  }

  .lg\:tw-gap-24{
    grid-gap: 6rem;
    grid-gap: 6rem;
    gap: 6rem
  }

  .lg\:tw-gap-32{
    grid-gap: 8rem;
    grid-gap: 8rem;
    gap: 8rem
  }

  .lg\:tw-gap-40{
    grid-gap: 10rem;
    grid-gap: 10rem;
    gap: 10rem
  }

  .lg\:tw-gap-48{
    grid-gap: 12rem;
    grid-gap: 12rem;
    gap: 12rem
  }

  .lg\:tw-gap-56{
    grid-gap: 14rem;
    grid-gap: 14rem;
    gap: 14rem
  }

  .lg\:tw-gap-64{
    grid-gap: 16rem;
    grid-gap: 16rem;
    gap: 16rem
  }

  .lg\:tw-gap-px{
    grid-gap: 1px;
    grid-gap: 1px;
    gap: 1px
  }

  .lg\:tw-col-gap-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .lg\:tw-col-gap-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .lg\:tw-col-gap-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .lg\:tw-col-gap-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .lg\:tw-col-gap-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .lg\:tw-col-gap-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .lg\:tw-col-gap-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .lg\:tw-col-gap-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .lg\:tw-col-gap-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .lg\:tw-col-gap-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .lg\:tw-col-gap-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .lg\:tw-col-gap-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .lg\:tw-col-gap-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .lg\:tw-col-gap-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .lg\:tw-col-gap-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .lg\:tw-col-gap-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .lg\:tw-col-gap-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .lg\:tw-col-gap-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .lg\:tw-col-gap-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .lg\:tw-gap-x-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .lg\:tw-gap-x-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .lg\:tw-gap-x-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .lg\:tw-gap-x-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .lg\:tw-gap-x-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .lg\:tw-gap-x-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .lg\:tw-gap-x-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .lg\:tw-gap-x-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .lg\:tw-gap-x-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .lg\:tw-gap-x-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .lg\:tw-gap-x-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .lg\:tw-gap-x-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .lg\:tw-gap-x-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .lg\:tw-gap-x-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .lg\:tw-gap-x-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .lg\:tw-gap-x-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .lg\:tw-gap-x-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .lg\:tw-gap-x-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .lg\:tw-gap-x-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .lg\:tw-row-gap-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:tw-row-gap-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .lg\:tw-row-gap-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .lg\:tw-row-gap-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .lg\:tw-row-gap-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .lg\:tw-row-gap-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .lg\:tw-row-gap-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:tw-row-gap-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .lg\:tw-row-gap-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .lg\:tw-row-gap-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .lg\:tw-row-gap-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .lg\:tw-row-gap-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .lg\:tw-row-gap-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .lg\:tw-row-gap-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .lg\:tw-row-gap-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .lg\:tw-row-gap-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .lg\:tw-row-gap-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .lg\:tw-row-gap-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .lg\:tw-row-gap-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .lg\:tw-gap-y-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:tw-gap-y-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .lg\:tw-gap-y-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .lg\:tw-gap-y-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .lg\:tw-gap-y-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .lg\:tw-gap-y-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .lg\:tw-gap-y-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:tw-gap-y-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .lg\:tw-gap-y-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .lg\:tw-gap-y-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .lg\:tw-gap-y-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .lg\:tw-gap-y-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .lg\:tw-gap-y-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .lg\:tw-gap-y-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .lg\:tw-gap-y-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .lg\:tw-gap-y-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .lg\:tw-gap-y-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .lg\:tw-gap-y-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .lg\:tw-gap-y-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .lg\:tw-grid-flow-row{
    grid-auto-flow: row
  }

  .lg\:tw-grid-flow-col{
    grid-auto-flow: column
  }

  .lg\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  .lg\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  .lg\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .lg\:tw-grid-cols-none{
    grid-template-columns: none
  }

  .lg\:tw-auto-cols-auto{
    grid-auto-columns: auto
  }

  .lg\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .lg\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .lg\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  .lg\:tw-col-auto{
    grid-column: auto
  }

  .lg\:tw-col-span-1{
    grid-column: span 1 / span 1
  }

  .lg\:tw-col-span-2{
    grid-column: span 2 / span 2
  }

  .lg\:tw-col-span-3{
    grid-column: span 3 / span 3
  }

  .lg\:tw-col-span-4{
    grid-column: span 4 / span 4
  }

  .lg\:tw-col-span-5{
    grid-column: span 5 / span 5
  }

  .lg\:tw-col-span-6{
    grid-column: span 6 / span 6
  }

  .lg\:tw-col-span-7{
    grid-column: span 7 / span 7
  }

  .lg\:tw-col-span-8{
    grid-column: span 8 / span 8
  }

  .lg\:tw-col-span-9{
    grid-column: span 9 / span 9
  }

  .lg\:tw-col-span-10{
    grid-column: span 10 / span 10
  }

  .lg\:tw-col-span-11{
    grid-column: span 11 / span 11
  }

  .lg\:tw-col-span-12{
    grid-column: span 12 / span 12
  }

  .lg\:tw-col-span-full{
    grid-column: 1 / -1
  }

  .lg\:tw-col-start-1{
    grid-column-start: 1
  }

  .lg\:tw-col-start-2{
    grid-column-start: 2
  }

  .lg\:tw-col-start-3{
    grid-column-start: 3
  }

  .lg\:tw-col-start-4{
    grid-column-start: 4
  }

  .lg\:tw-col-start-5{
    grid-column-start: 5
  }

  .lg\:tw-col-start-6{
    grid-column-start: 6
  }

  .lg\:tw-col-start-7{
    grid-column-start: 7
  }

  .lg\:tw-col-start-8{
    grid-column-start: 8
  }

  .lg\:tw-col-start-9{
    grid-column-start: 9
  }

  .lg\:tw-col-start-10{
    grid-column-start: 10
  }

  .lg\:tw-col-start-11{
    grid-column-start: 11
  }

  .lg\:tw-col-start-12{
    grid-column-start: 12
  }

  .lg\:tw-col-start-13{
    grid-column-start: 13
  }

  .lg\:tw-col-start-auto{
    grid-column-start: auto
  }

  .lg\:tw-col-end-1{
    grid-column-end: 1
  }

  .lg\:tw-col-end-2{
    grid-column-end: 2
  }

  .lg\:tw-col-end-3{
    grid-column-end: 3
  }

  .lg\:tw-col-end-4{
    grid-column-end: 4
  }

  .lg\:tw-col-end-5{
    grid-column-end: 5
  }

  .lg\:tw-col-end-6{
    grid-column-end: 6
  }

  .lg\:tw-col-end-7{
    grid-column-end: 7
  }

  .lg\:tw-col-end-8{
    grid-column-end: 8
  }

  .lg\:tw-col-end-9{
    grid-column-end: 9
  }

  .lg\:tw-col-end-10{
    grid-column-end: 10
  }

  .lg\:tw-col-end-11{
    grid-column-end: 11
  }

  .lg\:tw-col-end-12{
    grid-column-end: 12
  }

  .lg\:tw-col-end-13{
    grid-column-end: 13
  }

  .lg\:tw-col-end-auto{
    grid-column-end: auto
  }

  .lg\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .lg\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .lg\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .lg\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .lg\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .lg\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .lg\:tw-grid-rows-none{
    grid-template-rows: none
  }

  .lg\:tw-auto-rows-auto{
    grid-auto-rows: auto
  }

  .lg\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .lg\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .lg\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  .lg\:tw-row-auto{
    grid-row: auto
  }

  .lg\:tw-row-span-1{
    grid-row: span 1 / span 1
  }

  .lg\:tw-row-span-2{
    grid-row: span 2 / span 2
  }

  .lg\:tw-row-span-3{
    grid-row: span 3 / span 3
  }

  .lg\:tw-row-span-4{
    grid-row: span 4 / span 4
  }

  .lg\:tw-row-span-5{
    grid-row: span 5 / span 5
  }

  .lg\:tw-row-span-6{
    grid-row: span 6 / span 6
  }

  .lg\:tw-row-span-full{
    grid-row: 1 / -1
  }

  .lg\:tw-row-start-1{
    grid-row-start: 1
  }

  .lg\:tw-row-start-2{
    grid-row-start: 2
  }

  .lg\:tw-row-start-3{
    grid-row-start: 3
  }

  .lg\:tw-row-start-4{
    grid-row-start: 4
  }

  .lg\:tw-row-start-5{
    grid-row-start: 5
  }

  .lg\:tw-row-start-6{
    grid-row-start: 6
  }

  .lg\:tw-row-start-7{
    grid-row-start: 7
  }

  .lg\:tw-row-start-auto{
    grid-row-start: auto
  }

  .lg\:tw-row-end-1{
    grid-row-end: 1
  }

  .lg\:tw-row-end-2{
    grid-row-end: 2
  }

  .lg\:tw-row-end-3{
    grid-row-end: 3
  }

  .lg\:tw-row-end-4{
    grid-row-end: 4
  }

  .lg\:tw-row-end-5{
    grid-row-end: 5
  }

  .lg\:tw-row-end-6{
    grid-row-end: 6
  }

  .lg\:tw-row-end-7{
    grid-row-end: 7
  }

  .lg\:tw-row-end-auto{
    grid-row-end: auto
  }

  .lg\:tw-transform{
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .lg\:tw-transform-none{
    -webkit-transform: none;
            transform: none
  }

  .lg\:tw-origin-center{
    -webkit-transform-origin: center;
            transform-origin: center
  }

  .lg\:tw-origin-top{
    -webkit-transform-origin: top;
            transform-origin: top
  }

  .lg\:tw-origin-top-right{
    -webkit-transform-origin: top right;
            transform-origin: top right
  }

  .lg\:tw-origin-right{
    -webkit-transform-origin: right;
            transform-origin: right
  }

  .lg\:tw-origin-bottom-right{
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right
  }

  .lg\:tw-origin-bottom{
    -webkit-transform-origin: bottom;
            transform-origin: bottom
  }

  .lg\:tw-origin-bottom-left{
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left
  }

  .lg\:tw-origin-left{
    -webkit-transform-origin: left;
            transform-origin: left
  }

  .lg\:tw-origin-top-left{
    -webkit-transform-origin: top left;
            transform-origin: top left
  }

  .lg\:tw-scale-0{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:tw-scale-50{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:tw-scale-75{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:tw-scale-90{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:tw-scale-95{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:tw-scale-100{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:tw-scale-105{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:tw-scale-110{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:tw-scale-125{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:tw-scale-150{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:tw-scale-x-0{
    --transform-scale-x: 0
  }

  .lg\:tw-scale-x-50{
    --transform-scale-x: .5
  }

  .lg\:tw-scale-x-75{
    --transform-scale-x: .75
  }

  .lg\:tw-scale-x-90{
    --transform-scale-x: .9
  }

  .lg\:tw-scale-x-95{
    --transform-scale-x: .95
  }

  .lg\:tw-scale-x-100{
    --transform-scale-x: 1
  }

  .lg\:tw-scale-x-105{
    --transform-scale-x: 1.05
  }

  .lg\:tw-scale-x-110{
    --transform-scale-x: 1.1
  }

  .lg\:tw-scale-x-125{
    --transform-scale-x: 1.25
  }

  .lg\:tw-scale-x-150{
    --transform-scale-x: 1.5
  }

  .lg\:tw-scale-y-0{
    --transform-scale-y: 0
  }

  .lg\:tw-scale-y-50{
    --transform-scale-y: .5
  }

  .lg\:tw-scale-y-75{
    --transform-scale-y: .75
  }

  .lg\:tw-scale-y-90{
    --transform-scale-y: .9
  }

  .lg\:tw-scale-y-95{
    --transform-scale-y: .95
  }

  .lg\:tw-scale-y-100{
    --transform-scale-y: 1
  }

  .lg\:tw-scale-y-105{
    --transform-scale-y: 1.05
  }

  .lg\:tw-scale-y-110{
    --transform-scale-y: 1.1
  }

  .lg\:tw-scale-y-125{
    --transform-scale-y: 1.25
  }

  .lg\:tw-scale-y-150{
    --transform-scale-y: 1.5
  }

  .lg\:hover\:tw-scale-0:hover{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:hover\:tw-scale-50:hover{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:hover\:tw-scale-75:hover{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:hover\:tw-scale-90:hover{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:hover\:tw-scale-95:hover{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:hover\:tw-scale-100:hover{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:hover\:tw-scale-105:hover{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:hover\:tw-scale-110:hover{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:hover\:tw-scale-125:hover{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:hover\:tw-scale-150:hover{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:hover\:tw-scale-x-0:hover{
    --transform-scale-x: 0
  }

  .lg\:hover\:tw-scale-x-50:hover{
    --transform-scale-x: .5
  }

  .lg\:hover\:tw-scale-x-75:hover{
    --transform-scale-x: .75
  }

  .lg\:hover\:tw-scale-x-90:hover{
    --transform-scale-x: .9
  }

  .lg\:hover\:tw-scale-x-95:hover{
    --transform-scale-x: .95
  }

  .lg\:hover\:tw-scale-x-100:hover{
    --transform-scale-x: 1
  }

  .lg\:hover\:tw-scale-x-105:hover{
    --transform-scale-x: 1.05
  }

  .lg\:hover\:tw-scale-x-110:hover{
    --transform-scale-x: 1.1
  }

  .lg\:hover\:tw-scale-x-125:hover{
    --transform-scale-x: 1.25
  }

  .lg\:hover\:tw-scale-x-150:hover{
    --transform-scale-x: 1.5
  }

  .lg\:hover\:tw-scale-y-0:hover{
    --transform-scale-y: 0
  }

  .lg\:hover\:tw-scale-y-50:hover{
    --transform-scale-y: .5
  }

  .lg\:hover\:tw-scale-y-75:hover{
    --transform-scale-y: .75
  }

  .lg\:hover\:tw-scale-y-90:hover{
    --transform-scale-y: .9
  }

  .lg\:hover\:tw-scale-y-95:hover{
    --transform-scale-y: .95
  }

  .lg\:hover\:tw-scale-y-100:hover{
    --transform-scale-y: 1
  }

  .lg\:hover\:tw-scale-y-105:hover{
    --transform-scale-y: 1.05
  }

  .lg\:hover\:tw-scale-y-110:hover{
    --transform-scale-y: 1.1
  }

  .lg\:hover\:tw-scale-y-125:hover{
    --transform-scale-y: 1.25
  }

  .lg\:hover\:tw-scale-y-150:hover{
    --transform-scale-y: 1.5
  }

  .lg\:focus\:tw-scale-0:focus{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:focus\:tw-scale-50:focus{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:focus\:tw-scale-75:focus{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:focus\:tw-scale-90:focus{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:focus\:tw-scale-95:focus{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:focus\:tw-scale-100:focus{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:focus\:tw-scale-105:focus{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:focus\:tw-scale-110:focus{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:focus\:tw-scale-125:focus{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:focus\:tw-scale-150:focus{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:focus\:tw-scale-x-0:focus{
    --transform-scale-x: 0
  }

  .lg\:focus\:tw-scale-x-50:focus{
    --transform-scale-x: .5
  }

  .lg\:focus\:tw-scale-x-75:focus{
    --transform-scale-x: .75
  }

  .lg\:focus\:tw-scale-x-90:focus{
    --transform-scale-x: .9
  }

  .lg\:focus\:tw-scale-x-95:focus{
    --transform-scale-x: .95
  }

  .lg\:focus\:tw-scale-x-100:focus{
    --transform-scale-x: 1
  }

  .lg\:focus\:tw-scale-x-105:focus{
    --transform-scale-x: 1.05
  }

  .lg\:focus\:tw-scale-x-110:focus{
    --transform-scale-x: 1.1
  }

  .lg\:focus\:tw-scale-x-125:focus{
    --transform-scale-x: 1.25
  }

  .lg\:focus\:tw-scale-x-150:focus{
    --transform-scale-x: 1.5
  }

  .lg\:focus\:tw-scale-y-0:focus{
    --transform-scale-y: 0
  }

  .lg\:focus\:tw-scale-y-50:focus{
    --transform-scale-y: .5
  }

  .lg\:focus\:tw-scale-y-75:focus{
    --transform-scale-y: .75
  }

  .lg\:focus\:tw-scale-y-90:focus{
    --transform-scale-y: .9
  }

  .lg\:focus\:tw-scale-y-95:focus{
    --transform-scale-y: .95
  }

  .lg\:focus\:tw-scale-y-100:focus{
    --transform-scale-y: 1
  }

  .lg\:focus\:tw-scale-y-105:focus{
    --transform-scale-y: 1.05
  }

  .lg\:focus\:tw-scale-y-110:focus{
    --transform-scale-y: 1.1
  }

  .lg\:focus\:tw-scale-y-125:focus{
    --transform-scale-y: 1.25
  }

  .lg\:focus\:tw-scale-y-150:focus{
    --transform-scale-y: 1.5
  }

  .lg\:tw-rotate-0{
    --transform-rotate: 0
  }

  .lg\:tw-rotate-1{
    --transform-rotate: 1deg
  }

  .lg\:tw-rotate-2{
    --transform-rotate: 2deg
  }

  .lg\:tw-rotate-3{
    --transform-rotate: 3deg
  }

  .lg\:tw-rotate-6{
    --transform-rotate: 6deg
  }

  .lg\:tw-rotate-12{
    --transform-rotate: 12deg
  }

  .lg\:tw-rotate-45{
    --transform-rotate: 45deg
  }

  .lg\:tw-rotate-90{
    --transform-rotate: 90deg
  }

  .lg\:tw-rotate-180{
    --transform-rotate: 180deg
  }

  .lg\:tw--rotate-180{
    --transform-rotate: -180deg
  }

  .lg\:tw--rotate-90{
    --transform-rotate: -90deg
  }

  .lg\:tw--rotate-45{
    --transform-rotate: -45deg
  }

  .lg\:tw--rotate-12{
    --transform-rotate: -12deg
  }

  .lg\:tw--rotate-6{
    --transform-rotate: -6deg
  }

  .lg\:tw--rotate-3{
    --transform-rotate: -3deg
  }

  .lg\:tw--rotate-2{
    --transform-rotate: -2deg
  }

  .lg\:tw--rotate-1{
    --transform-rotate: -1deg
  }

  .lg\:hover\:tw-rotate-0:hover{
    --transform-rotate: 0
  }

  .lg\:hover\:tw-rotate-1:hover{
    --transform-rotate: 1deg
  }

  .lg\:hover\:tw-rotate-2:hover{
    --transform-rotate: 2deg
  }

  .lg\:hover\:tw-rotate-3:hover{
    --transform-rotate: 3deg
  }

  .lg\:hover\:tw-rotate-6:hover{
    --transform-rotate: 6deg
  }

  .lg\:hover\:tw-rotate-12:hover{
    --transform-rotate: 12deg
  }

  .lg\:hover\:tw-rotate-45:hover{
    --transform-rotate: 45deg
  }

  .lg\:hover\:tw-rotate-90:hover{
    --transform-rotate: 90deg
  }

  .lg\:hover\:tw-rotate-180:hover{
    --transform-rotate: 180deg
  }

  .lg\:hover\:tw--rotate-180:hover{
    --transform-rotate: -180deg
  }

  .lg\:hover\:tw--rotate-90:hover{
    --transform-rotate: -90deg
  }

  .lg\:hover\:tw--rotate-45:hover{
    --transform-rotate: -45deg
  }

  .lg\:hover\:tw--rotate-12:hover{
    --transform-rotate: -12deg
  }

  .lg\:hover\:tw--rotate-6:hover{
    --transform-rotate: -6deg
  }

  .lg\:hover\:tw--rotate-3:hover{
    --transform-rotate: -3deg
  }

  .lg\:hover\:tw--rotate-2:hover{
    --transform-rotate: -2deg
  }

  .lg\:hover\:tw--rotate-1:hover{
    --transform-rotate: -1deg
  }

  .lg\:focus\:tw-rotate-0:focus{
    --transform-rotate: 0
  }

  .lg\:focus\:tw-rotate-1:focus{
    --transform-rotate: 1deg
  }

  .lg\:focus\:tw-rotate-2:focus{
    --transform-rotate: 2deg
  }

  .lg\:focus\:tw-rotate-3:focus{
    --transform-rotate: 3deg
  }

  .lg\:focus\:tw-rotate-6:focus{
    --transform-rotate: 6deg
  }

  .lg\:focus\:tw-rotate-12:focus{
    --transform-rotate: 12deg
  }

  .lg\:focus\:tw-rotate-45:focus{
    --transform-rotate: 45deg
  }

  .lg\:focus\:tw-rotate-90:focus{
    --transform-rotate: 90deg
  }

  .lg\:focus\:tw-rotate-180:focus{
    --transform-rotate: 180deg
  }

  .lg\:focus\:tw--rotate-180:focus{
    --transform-rotate: -180deg
  }

  .lg\:focus\:tw--rotate-90:focus{
    --transform-rotate: -90deg
  }

  .lg\:focus\:tw--rotate-45:focus{
    --transform-rotate: -45deg
  }

  .lg\:focus\:tw--rotate-12:focus{
    --transform-rotate: -12deg
  }

  .lg\:focus\:tw--rotate-6:focus{
    --transform-rotate: -6deg
  }

  .lg\:focus\:tw--rotate-3:focus{
    --transform-rotate: -3deg
  }

  .lg\:focus\:tw--rotate-2:focus{
    --transform-rotate: -2deg
  }

  .lg\:focus\:tw--rotate-1:focus{
    --transform-rotate: -1deg
  }

  .lg\:tw-translate-x-0{
    --transform-translate-x: 0
  }

  .lg\:tw-translate-x-1{
    --transform-translate-x: 0.25rem
  }

  .lg\:tw-translate-x-2{
    --transform-translate-x: 0.5rem
  }

  .lg\:tw-translate-x-3{
    --transform-translate-x: 0.75rem
  }

  .lg\:tw-translate-x-4{
    --transform-translate-x: 1rem
  }

  .lg\:tw-translate-x-5{
    --transform-translate-x: 1.25rem
  }

  .lg\:tw-translate-x-6{
    --transform-translate-x: 1.5rem
  }

  .lg\:tw-translate-x-8{
    --transform-translate-x: 2rem
  }

  .lg\:tw-translate-x-10{
    --transform-translate-x: 2.5rem
  }

  .lg\:tw-translate-x-12{
    --transform-translate-x: 3rem
  }

  .lg\:tw-translate-x-16{
    --transform-translate-x: 4rem
  }

  .lg\:tw-translate-x-20{
    --transform-translate-x: 5rem
  }

  .lg\:tw-translate-x-24{
    --transform-translate-x: 6rem
  }

  .lg\:tw-translate-x-32{
    --transform-translate-x: 8rem
  }

  .lg\:tw-translate-x-40{
    --transform-translate-x: 10rem
  }

  .lg\:tw-translate-x-48{
    --transform-translate-x: 12rem
  }

  .lg\:tw-translate-x-56{
    --transform-translate-x: 14rem
  }

  .lg\:tw-translate-x-64{
    --transform-translate-x: 16rem
  }

  .lg\:tw-translate-x-px{
    --transform-translate-x: 1px
  }

  .lg\:tw--translate-x-1{
    --transform-translate-x: -0.25rem
  }

  .lg\:tw--translate-x-2{
    --transform-translate-x: -0.5rem
  }

  .lg\:tw--translate-x-3{
    --transform-translate-x: -0.75rem
  }

  .lg\:tw--translate-x-4{
    --transform-translate-x: -1rem
  }

  .lg\:tw--translate-x-5{
    --transform-translate-x: -1.25rem
  }

  .lg\:tw--translate-x-6{
    --transform-translate-x: -1.5rem
  }

  .lg\:tw--translate-x-8{
    --transform-translate-x: -2rem
  }

  .lg\:tw--translate-x-10{
    --transform-translate-x: -2.5rem
  }

  .lg\:tw--translate-x-12{
    --transform-translate-x: -3rem
  }

  .lg\:tw--translate-x-16{
    --transform-translate-x: -4rem
  }

  .lg\:tw--translate-x-20{
    --transform-translate-x: -5rem
  }

  .lg\:tw--translate-x-24{
    --transform-translate-x: -6rem
  }

  .lg\:tw--translate-x-32{
    --transform-translate-x: -8rem
  }

  .lg\:tw--translate-x-40{
    --transform-translate-x: -10rem
  }

  .lg\:tw--translate-x-48{
    --transform-translate-x: -12rem
  }

  .lg\:tw--translate-x-56{
    --transform-translate-x: -14rem
  }

  .lg\:tw--translate-x-64{
    --transform-translate-x: -16rem
  }

  .lg\:tw--translate-x-px{
    --transform-translate-x: -1px
  }

  .lg\:tw--translate-x-full{
    --transform-translate-x: -100%
  }

  .lg\:tw--translate-x-1\/2{
    --transform-translate-x: -50%
  }

  .lg\:tw-translate-x-1\/2{
    --transform-translate-x: 50%
  }

  .lg\:tw-translate-x-full{
    --transform-translate-x: 100%
  }

  .lg\:tw-translate-y-0{
    --transform-translate-y: 0
  }

  .lg\:tw-translate-y-1{
    --transform-translate-y: 0.25rem
  }

  .lg\:tw-translate-y-2{
    --transform-translate-y: 0.5rem
  }

  .lg\:tw-translate-y-3{
    --transform-translate-y: 0.75rem
  }

  .lg\:tw-translate-y-4{
    --transform-translate-y: 1rem
  }

  .lg\:tw-translate-y-5{
    --transform-translate-y: 1.25rem
  }

  .lg\:tw-translate-y-6{
    --transform-translate-y: 1.5rem
  }

  .lg\:tw-translate-y-8{
    --transform-translate-y: 2rem
  }

  .lg\:tw-translate-y-10{
    --transform-translate-y: 2.5rem
  }

  .lg\:tw-translate-y-12{
    --transform-translate-y: 3rem
  }

  .lg\:tw-translate-y-16{
    --transform-translate-y: 4rem
  }

  .lg\:tw-translate-y-20{
    --transform-translate-y: 5rem
  }

  .lg\:tw-translate-y-24{
    --transform-translate-y: 6rem
  }

  .lg\:tw-translate-y-32{
    --transform-translate-y: 8rem
  }

  .lg\:tw-translate-y-40{
    --transform-translate-y: 10rem
  }

  .lg\:tw-translate-y-48{
    --transform-translate-y: 12rem
  }

  .lg\:tw-translate-y-56{
    --transform-translate-y: 14rem
  }

  .lg\:tw-translate-y-64{
    --transform-translate-y: 16rem
  }

  .lg\:tw-translate-y-px{
    --transform-translate-y: 1px
  }

  .lg\:tw--translate-y-1{
    --transform-translate-y: -0.25rem
  }

  .lg\:tw--translate-y-2{
    --transform-translate-y: -0.5rem
  }

  .lg\:tw--translate-y-3{
    --transform-translate-y: -0.75rem
  }

  .lg\:tw--translate-y-4{
    --transform-translate-y: -1rem
  }

  .lg\:tw--translate-y-5{
    --transform-translate-y: -1.25rem
  }

  .lg\:tw--translate-y-6{
    --transform-translate-y: -1.5rem
  }

  .lg\:tw--translate-y-8{
    --transform-translate-y: -2rem
  }

  .lg\:tw--translate-y-10{
    --transform-translate-y: -2.5rem
  }

  .lg\:tw--translate-y-12{
    --transform-translate-y: -3rem
  }

  .lg\:tw--translate-y-16{
    --transform-translate-y: -4rem
  }

  .lg\:tw--translate-y-20{
    --transform-translate-y: -5rem
  }

  .lg\:tw--translate-y-24{
    --transform-translate-y: -6rem
  }

  .lg\:tw--translate-y-32{
    --transform-translate-y: -8rem
  }

  .lg\:tw--translate-y-40{
    --transform-translate-y: -10rem
  }

  .lg\:tw--translate-y-48{
    --transform-translate-y: -12rem
  }

  .lg\:tw--translate-y-56{
    --transform-translate-y: -14rem
  }

  .lg\:tw--translate-y-64{
    --transform-translate-y: -16rem
  }

  .lg\:tw--translate-y-px{
    --transform-translate-y: -1px
  }

  .lg\:tw--translate-y-full{
    --transform-translate-y: -100%
  }

  .lg\:tw--translate-y-1\/2{
    --transform-translate-y: -50%
  }

  .lg\:tw-translate-y-1\/2{
    --transform-translate-y: 50%
  }

  .lg\:tw-translate-y-full{
    --transform-translate-y: 100%
  }

  .lg\:hover\:tw-translate-x-0:hover{
    --transform-translate-x: 0
  }

  .lg\:hover\:tw-translate-x-1:hover{
    --transform-translate-x: 0.25rem
  }

  .lg\:hover\:tw-translate-x-2:hover{
    --transform-translate-x: 0.5rem
  }

  .lg\:hover\:tw-translate-x-3:hover{
    --transform-translate-x: 0.75rem
  }

  .lg\:hover\:tw-translate-x-4:hover{
    --transform-translate-x: 1rem
  }

  .lg\:hover\:tw-translate-x-5:hover{
    --transform-translate-x: 1.25rem
  }

  .lg\:hover\:tw-translate-x-6:hover{
    --transform-translate-x: 1.5rem
  }

  .lg\:hover\:tw-translate-x-8:hover{
    --transform-translate-x: 2rem
  }

  .lg\:hover\:tw-translate-x-10:hover{
    --transform-translate-x: 2.5rem
  }

  .lg\:hover\:tw-translate-x-12:hover{
    --transform-translate-x: 3rem
  }

  .lg\:hover\:tw-translate-x-16:hover{
    --transform-translate-x: 4rem
  }

  .lg\:hover\:tw-translate-x-20:hover{
    --transform-translate-x: 5rem
  }

  .lg\:hover\:tw-translate-x-24:hover{
    --transform-translate-x: 6rem
  }

  .lg\:hover\:tw-translate-x-32:hover{
    --transform-translate-x: 8rem
  }

  .lg\:hover\:tw-translate-x-40:hover{
    --transform-translate-x: 10rem
  }

  .lg\:hover\:tw-translate-x-48:hover{
    --transform-translate-x: 12rem
  }

  .lg\:hover\:tw-translate-x-56:hover{
    --transform-translate-x: 14rem
  }

  .lg\:hover\:tw-translate-x-64:hover{
    --transform-translate-x: 16rem
  }

  .lg\:hover\:tw-translate-x-px:hover{
    --transform-translate-x: 1px
  }

  .lg\:hover\:tw--translate-x-1:hover{
    --transform-translate-x: -0.25rem
  }

  .lg\:hover\:tw--translate-x-2:hover{
    --transform-translate-x: -0.5rem
  }

  .lg\:hover\:tw--translate-x-3:hover{
    --transform-translate-x: -0.75rem
  }

  .lg\:hover\:tw--translate-x-4:hover{
    --transform-translate-x: -1rem
  }

  .lg\:hover\:tw--translate-x-5:hover{
    --transform-translate-x: -1.25rem
  }

  .lg\:hover\:tw--translate-x-6:hover{
    --transform-translate-x: -1.5rem
  }

  .lg\:hover\:tw--translate-x-8:hover{
    --transform-translate-x: -2rem
  }

  .lg\:hover\:tw--translate-x-10:hover{
    --transform-translate-x: -2.5rem
  }

  .lg\:hover\:tw--translate-x-12:hover{
    --transform-translate-x: -3rem
  }

  .lg\:hover\:tw--translate-x-16:hover{
    --transform-translate-x: -4rem
  }

  .lg\:hover\:tw--translate-x-20:hover{
    --transform-translate-x: -5rem
  }

  .lg\:hover\:tw--translate-x-24:hover{
    --transform-translate-x: -6rem
  }

  .lg\:hover\:tw--translate-x-32:hover{
    --transform-translate-x: -8rem
  }

  .lg\:hover\:tw--translate-x-40:hover{
    --transform-translate-x: -10rem
  }

  .lg\:hover\:tw--translate-x-48:hover{
    --transform-translate-x: -12rem
  }

  .lg\:hover\:tw--translate-x-56:hover{
    --transform-translate-x: -14rem
  }

  .lg\:hover\:tw--translate-x-64:hover{
    --transform-translate-x: -16rem
  }

  .lg\:hover\:tw--translate-x-px:hover{
    --transform-translate-x: -1px
  }

  .lg\:hover\:tw--translate-x-full:hover{
    --transform-translate-x: -100%
  }

  .lg\:hover\:tw--translate-x-1\/2:hover{
    --transform-translate-x: -50%
  }

  .lg\:hover\:tw-translate-x-1\/2:hover{
    --transform-translate-x: 50%
  }

  .lg\:hover\:tw-translate-x-full:hover{
    --transform-translate-x: 100%
  }

  .lg\:hover\:tw-translate-y-0:hover{
    --transform-translate-y: 0
  }

  .lg\:hover\:tw-translate-y-1:hover{
    --transform-translate-y: 0.25rem
  }

  .lg\:hover\:tw-translate-y-2:hover{
    --transform-translate-y: 0.5rem
  }

  .lg\:hover\:tw-translate-y-3:hover{
    --transform-translate-y: 0.75rem
  }

  .lg\:hover\:tw-translate-y-4:hover{
    --transform-translate-y: 1rem
  }

  .lg\:hover\:tw-translate-y-5:hover{
    --transform-translate-y: 1.25rem
  }

  .lg\:hover\:tw-translate-y-6:hover{
    --transform-translate-y: 1.5rem
  }

  .lg\:hover\:tw-translate-y-8:hover{
    --transform-translate-y: 2rem
  }

  .lg\:hover\:tw-translate-y-10:hover{
    --transform-translate-y: 2.5rem
  }

  .lg\:hover\:tw-translate-y-12:hover{
    --transform-translate-y: 3rem
  }

  .lg\:hover\:tw-translate-y-16:hover{
    --transform-translate-y: 4rem
  }

  .lg\:hover\:tw-translate-y-20:hover{
    --transform-translate-y: 5rem
  }

  .lg\:hover\:tw-translate-y-24:hover{
    --transform-translate-y: 6rem
  }

  .lg\:hover\:tw-translate-y-32:hover{
    --transform-translate-y: 8rem
  }

  .lg\:hover\:tw-translate-y-40:hover{
    --transform-translate-y: 10rem
  }

  .lg\:hover\:tw-translate-y-48:hover{
    --transform-translate-y: 12rem
  }

  .lg\:hover\:tw-translate-y-56:hover{
    --transform-translate-y: 14rem
  }

  .lg\:hover\:tw-translate-y-64:hover{
    --transform-translate-y: 16rem
  }

  .lg\:hover\:tw-translate-y-px:hover{
    --transform-translate-y: 1px
  }

  .lg\:hover\:tw--translate-y-1:hover{
    --transform-translate-y: -0.25rem
  }

  .lg\:hover\:tw--translate-y-2:hover{
    --transform-translate-y: -0.5rem
  }

  .lg\:hover\:tw--translate-y-3:hover{
    --transform-translate-y: -0.75rem
  }

  .lg\:hover\:tw--translate-y-4:hover{
    --transform-translate-y: -1rem
  }

  .lg\:hover\:tw--translate-y-5:hover{
    --transform-translate-y: -1.25rem
  }

  .lg\:hover\:tw--translate-y-6:hover{
    --transform-translate-y: -1.5rem
  }

  .lg\:hover\:tw--translate-y-8:hover{
    --transform-translate-y: -2rem
  }

  .lg\:hover\:tw--translate-y-10:hover{
    --transform-translate-y: -2.5rem
  }

  .lg\:hover\:tw--translate-y-12:hover{
    --transform-translate-y: -3rem
  }

  .lg\:hover\:tw--translate-y-16:hover{
    --transform-translate-y: -4rem
  }

  .lg\:hover\:tw--translate-y-20:hover{
    --transform-translate-y: -5rem
  }

  .lg\:hover\:tw--translate-y-24:hover{
    --transform-translate-y: -6rem
  }

  .lg\:hover\:tw--translate-y-32:hover{
    --transform-translate-y: -8rem
  }

  .lg\:hover\:tw--translate-y-40:hover{
    --transform-translate-y: -10rem
  }

  .lg\:hover\:tw--translate-y-48:hover{
    --transform-translate-y: -12rem
  }

  .lg\:hover\:tw--translate-y-56:hover{
    --transform-translate-y: -14rem
  }

  .lg\:hover\:tw--translate-y-64:hover{
    --transform-translate-y: -16rem
  }

  .lg\:hover\:tw--translate-y-px:hover{
    --transform-translate-y: -1px
  }

  .lg\:hover\:tw--translate-y-full:hover{
    --transform-translate-y: -100%
  }

  .lg\:hover\:tw--translate-y-1\/2:hover{
    --transform-translate-y: -50%
  }

  .lg\:hover\:tw-translate-y-1\/2:hover{
    --transform-translate-y: 50%
  }

  .lg\:hover\:tw-translate-y-full:hover{
    --transform-translate-y: 100%
  }

  .lg\:focus\:tw-translate-x-0:focus{
    --transform-translate-x: 0
  }

  .lg\:focus\:tw-translate-x-1:focus{
    --transform-translate-x: 0.25rem
  }

  .lg\:focus\:tw-translate-x-2:focus{
    --transform-translate-x: 0.5rem
  }

  .lg\:focus\:tw-translate-x-3:focus{
    --transform-translate-x: 0.75rem
  }

  .lg\:focus\:tw-translate-x-4:focus{
    --transform-translate-x: 1rem
  }

  .lg\:focus\:tw-translate-x-5:focus{
    --transform-translate-x: 1.25rem
  }

  .lg\:focus\:tw-translate-x-6:focus{
    --transform-translate-x: 1.5rem
  }

  .lg\:focus\:tw-translate-x-8:focus{
    --transform-translate-x: 2rem
  }

  .lg\:focus\:tw-translate-x-10:focus{
    --transform-translate-x: 2.5rem
  }

  .lg\:focus\:tw-translate-x-12:focus{
    --transform-translate-x: 3rem
  }

  .lg\:focus\:tw-translate-x-16:focus{
    --transform-translate-x: 4rem
  }

  .lg\:focus\:tw-translate-x-20:focus{
    --transform-translate-x: 5rem
  }

  .lg\:focus\:tw-translate-x-24:focus{
    --transform-translate-x: 6rem
  }

  .lg\:focus\:tw-translate-x-32:focus{
    --transform-translate-x: 8rem
  }

  .lg\:focus\:tw-translate-x-40:focus{
    --transform-translate-x: 10rem
  }

  .lg\:focus\:tw-translate-x-48:focus{
    --transform-translate-x: 12rem
  }

  .lg\:focus\:tw-translate-x-56:focus{
    --transform-translate-x: 14rem
  }

  .lg\:focus\:tw-translate-x-64:focus{
    --transform-translate-x: 16rem
  }

  .lg\:focus\:tw-translate-x-px:focus{
    --transform-translate-x: 1px
  }

  .lg\:focus\:tw--translate-x-1:focus{
    --transform-translate-x: -0.25rem
  }

  .lg\:focus\:tw--translate-x-2:focus{
    --transform-translate-x: -0.5rem
  }

  .lg\:focus\:tw--translate-x-3:focus{
    --transform-translate-x: -0.75rem
  }

  .lg\:focus\:tw--translate-x-4:focus{
    --transform-translate-x: -1rem
  }

  .lg\:focus\:tw--translate-x-5:focus{
    --transform-translate-x: -1.25rem
  }

  .lg\:focus\:tw--translate-x-6:focus{
    --transform-translate-x: -1.5rem
  }

  .lg\:focus\:tw--translate-x-8:focus{
    --transform-translate-x: -2rem
  }

  .lg\:focus\:tw--translate-x-10:focus{
    --transform-translate-x: -2.5rem
  }

  .lg\:focus\:tw--translate-x-12:focus{
    --transform-translate-x: -3rem
  }

  .lg\:focus\:tw--translate-x-16:focus{
    --transform-translate-x: -4rem
  }

  .lg\:focus\:tw--translate-x-20:focus{
    --transform-translate-x: -5rem
  }

  .lg\:focus\:tw--translate-x-24:focus{
    --transform-translate-x: -6rem
  }

  .lg\:focus\:tw--translate-x-32:focus{
    --transform-translate-x: -8rem
  }

  .lg\:focus\:tw--translate-x-40:focus{
    --transform-translate-x: -10rem
  }

  .lg\:focus\:tw--translate-x-48:focus{
    --transform-translate-x: -12rem
  }

  .lg\:focus\:tw--translate-x-56:focus{
    --transform-translate-x: -14rem
  }

  .lg\:focus\:tw--translate-x-64:focus{
    --transform-translate-x: -16rem
  }

  .lg\:focus\:tw--translate-x-px:focus{
    --transform-translate-x: -1px
  }

  .lg\:focus\:tw--translate-x-full:focus{
    --transform-translate-x: -100%
  }

  .lg\:focus\:tw--translate-x-1\/2:focus{
    --transform-translate-x: -50%
  }

  .lg\:focus\:tw-translate-x-1\/2:focus{
    --transform-translate-x: 50%
  }

  .lg\:focus\:tw-translate-x-full:focus{
    --transform-translate-x: 100%
  }

  .lg\:focus\:tw-translate-y-0:focus{
    --transform-translate-y: 0
  }

  .lg\:focus\:tw-translate-y-1:focus{
    --transform-translate-y: 0.25rem
  }

  .lg\:focus\:tw-translate-y-2:focus{
    --transform-translate-y: 0.5rem
  }

  .lg\:focus\:tw-translate-y-3:focus{
    --transform-translate-y: 0.75rem
  }

  .lg\:focus\:tw-translate-y-4:focus{
    --transform-translate-y: 1rem
  }

  .lg\:focus\:tw-translate-y-5:focus{
    --transform-translate-y: 1.25rem
  }

  .lg\:focus\:tw-translate-y-6:focus{
    --transform-translate-y: 1.5rem
  }

  .lg\:focus\:tw-translate-y-8:focus{
    --transform-translate-y: 2rem
  }

  .lg\:focus\:tw-translate-y-10:focus{
    --transform-translate-y: 2.5rem
  }

  .lg\:focus\:tw-translate-y-12:focus{
    --transform-translate-y: 3rem
  }

  .lg\:focus\:tw-translate-y-16:focus{
    --transform-translate-y: 4rem
  }

  .lg\:focus\:tw-translate-y-20:focus{
    --transform-translate-y: 5rem
  }

  .lg\:focus\:tw-translate-y-24:focus{
    --transform-translate-y: 6rem
  }

  .lg\:focus\:tw-translate-y-32:focus{
    --transform-translate-y: 8rem
  }

  .lg\:focus\:tw-translate-y-40:focus{
    --transform-translate-y: 10rem
  }

  .lg\:focus\:tw-translate-y-48:focus{
    --transform-translate-y: 12rem
  }

  .lg\:focus\:tw-translate-y-56:focus{
    --transform-translate-y: 14rem
  }

  .lg\:focus\:tw-translate-y-64:focus{
    --transform-translate-y: 16rem
  }

  .lg\:focus\:tw-translate-y-px:focus{
    --transform-translate-y: 1px
  }

  .lg\:focus\:tw--translate-y-1:focus{
    --transform-translate-y: -0.25rem
  }

  .lg\:focus\:tw--translate-y-2:focus{
    --transform-translate-y: -0.5rem
  }

  .lg\:focus\:tw--translate-y-3:focus{
    --transform-translate-y: -0.75rem
  }

  .lg\:focus\:tw--translate-y-4:focus{
    --transform-translate-y: -1rem
  }

  .lg\:focus\:tw--translate-y-5:focus{
    --transform-translate-y: -1.25rem
  }

  .lg\:focus\:tw--translate-y-6:focus{
    --transform-translate-y: -1.5rem
  }

  .lg\:focus\:tw--translate-y-8:focus{
    --transform-translate-y: -2rem
  }

  .lg\:focus\:tw--translate-y-10:focus{
    --transform-translate-y: -2.5rem
  }

  .lg\:focus\:tw--translate-y-12:focus{
    --transform-translate-y: -3rem
  }

  .lg\:focus\:tw--translate-y-16:focus{
    --transform-translate-y: -4rem
  }

  .lg\:focus\:tw--translate-y-20:focus{
    --transform-translate-y: -5rem
  }

  .lg\:focus\:tw--translate-y-24:focus{
    --transform-translate-y: -6rem
  }

  .lg\:focus\:tw--translate-y-32:focus{
    --transform-translate-y: -8rem
  }

  .lg\:focus\:tw--translate-y-40:focus{
    --transform-translate-y: -10rem
  }

  .lg\:focus\:tw--translate-y-48:focus{
    --transform-translate-y: -12rem
  }

  .lg\:focus\:tw--translate-y-56:focus{
    --transform-translate-y: -14rem
  }

  .lg\:focus\:tw--translate-y-64:focus{
    --transform-translate-y: -16rem
  }

  .lg\:focus\:tw--translate-y-px:focus{
    --transform-translate-y: -1px
  }

  .lg\:focus\:tw--translate-y-full:focus{
    --transform-translate-y: -100%
  }

  .lg\:focus\:tw--translate-y-1\/2:focus{
    --transform-translate-y: -50%
  }

  .lg\:focus\:tw-translate-y-1\/2:focus{
    --transform-translate-y: 50%
  }

  .lg\:focus\:tw-translate-y-full:focus{
    --transform-translate-y: 100%
  }

  .lg\:tw-skew-x-0{
    --transform-skew-x: 0
  }

  .lg\:tw-skew-x-1{
    --transform-skew-x: 1deg
  }

  .lg\:tw-skew-x-2{
    --transform-skew-x: 2deg
  }

  .lg\:tw-skew-x-3{
    --transform-skew-x: 3deg
  }

  .lg\:tw-skew-x-6{
    --transform-skew-x: 6deg
  }

  .lg\:tw-skew-x-12{
    --transform-skew-x: 12deg
  }

  .lg\:tw--skew-x-12{
    --transform-skew-x: -12deg
  }

  .lg\:tw--skew-x-6{
    --transform-skew-x: -6deg
  }

  .lg\:tw--skew-x-3{
    --transform-skew-x: -3deg
  }

  .lg\:tw--skew-x-2{
    --transform-skew-x: -2deg
  }

  .lg\:tw--skew-x-1{
    --transform-skew-x: -1deg
  }

  .lg\:tw-skew-y-0{
    --transform-skew-y: 0
  }

  .lg\:tw-skew-y-1{
    --transform-skew-y: 1deg
  }

  .lg\:tw-skew-y-2{
    --transform-skew-y: 2deg
  }

  .lg\:tw-skew-y-3{
    --transform-skew-y: 3deg
  }

  .lg\:tw-skew-y-6{
    --transform-skew-y: 6deg
  }

  .lg\:tw-skew-y-12{
    --transform-skew-y: 12deg
  }

  .lg\:tw--skew-y-12{
    --transform-skew-y: -12deg
  }

  .lg\:tw--skew-y-6{
    --transform-skew-y: -6deg
  }

  .lg\:tw--skew-y-3{
    --transform-skew-y: -3deg
  }

  .lg\:tw--skew-y-2{
    --transform-skew-y: -2deg
  }

  .lg\:tw--skew-y-1{
    --transform-skew-y: -1deg
  }

  .lg\:hover\:tw-skew-x-0:hover{
    --transform-skew-x: 0
  }

  .lg\:hover\:tw-skew-x-1:hover{
    --transform-skew-x: 1deg
  }

  .lg\:hover\:tw-skew-x-2:hover{
    --transform-skew-x: 2deg
  }

  .lg\:hover\:tw-skew-x-3:hover{
    --transform-skew-x: 3deg
  }

  .lg\:hover\:tw-skew-x-6:hover{
    --transform-skew-x: 6deg
  }

  .lg\:hover\:tw-skew-x-12:hover{
    --transform-skew-x: 12deg
  }

  .lg\:hover\:tw--skew-x-12:hover{
    --transform-skew-x: -12deg
  }

  .lg\:hover\:tw--skew-x-6:hover{
    --transform-skew-x: -6deg
  }

  .lg\:hover\:tw--skew-x-3:hover{
    --transform-skew-x: -3deg
  }

  .lg\:hover\:tw--skew-x-2:hover{
    --transform-skew-x: -2deg
  }

  .lg\:hover\:tw--skew-x-1:hover{
    --transform-skew-x: -1deg
  }

  .lg\:hover\:tw-skew-y-0:hover{
    --transform-skew-y: 0
  }

  .lg\:hover\:tw-skew-y-1:hover{
    --transform-skew-y: 1deg
  }

  .lg\:hover\:tw-skew-y-2:hover{
    --transform-skew-y: 2deg
  }

  .lg\:hover\:tw-skew-y-3:hover{
    --transform-skew-y: 3deg
  }

  .lg\:hover\:tw-skew-y-6:hover{
    --transform-skew-y: 6deg
  }

  .lg\:hover\:tw-skew-y-12:hover{
    --transform-skew-y: 12deg
  }

  .lg\:hover\:tw--skew-y-12:hover{
    --transform-skew-y: -12deg
  }

  .lg\:hover\:tw--skew-y-6:hover{
    --transform-skew-y: -6deg
  }

  .lg\:hover\:tw--skew-y-3:hover{
    --transform-skew-y: -3deg
  }

  .lg\:hover\:tw--skew-y-2:hover{
    --transform-skew-y: -2deg
  }

  .lg\:hover\:tw--skew-y-1:hover{
    --transform-skew-y: -1deg
  }

  .lg\:focus\:tw-skew-x-0:focus{
    --transform-skew-x: 0
  }

  .lg\:focus\:tw-skew-x-1:focus{
    --transform-skew-x: 1deg
  }

  .lg\:focus\:tw-skew-x-2:focus{
    --transform-skew-x: 2deg
  }

  .lg\:focus\:tw-skew-x-3:focus{
    --transform-skew-x: 3deg
  }

  .lg\:focus\:tw-skew-x-6:focus{
    --transform-skew-x: 6deg
  }

  .lg\:focus\:tw-skew-x-12:focus{
    --transform-skew-x: 12deg
  }

  .lg\:focus\:tw--skew-x-12:focus{
    --transform-skew-x: -12deg
  }

  .lg\:focus\:tw--skew-x-6:focus{
    --transform-skew-x: -6deg
  }

  .lg\:focus\:tw--skew-x-3:focus{
    --transform-skew-x: -3deg
  }

  .lg\:focus\:tw--skew-x-2:focus{
    --transform-skew-x: -2deg
  }

  .lg\:focus\:tw--skew-x-1:focus{
    --transform-skew-x: -1deg
  }

  .lg\:focus\:tw-skew-y-0:focus{
    --transform-skew-y: 0
  }

  .lg\:focus\:tw-skew-y-1:focus{
    --transform-skew-y: 1deg
  }

  .lg\:focus\:tw-skew-y-2:focus{
    --transform-skew-y: 2deg
  }

  .lg\:focus\:tw-skew-y-3:focus{
    --transform-skew-y: 3deg
  }

  .lg\:focus\:tw-skew-y-6:focus{
    --transform-skew-y: 6deg
  }

  .lg\:focus\:tw-skew-y-12:focus{
    --transform-skew-y: 12deg
  }

  .lg\:focus\:tw--skew-y-12:focus{
    --transform-skew-y: -12deg
  }

  .lg\:focus\:tw--skew-y-6:focus{
    --transform-skew-y: -6deg
  }

  .lg\:focus\:tw--skew-y-3:focus{
    --transform-skew-y: -3deg
  }

  .lg\:focus\:tw--skew-y-2:focus{
    --transform-skew-y: -2deg
  }

  .lg\:focus\:tw--skew-y-1:focus{
    --transform-skew-y: -1deg
  }

  .lg\:tw-transition-none{
    -webkit-transition-property: none;
    transition-property: none
  }

  .lg\:tw-transition-all{
    -webkit-transition-property: all;
    transition-property: all
  }

  .lg\:tw-transition{
    -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
  }

  .lg\:tw-transition-colors{
    -webkit-transition-property: background-color, border-color, color, fill, stroke;
    transition-property: background-color, border-color, color, fill, stroke
  }

  .lg\:tw-transition-opacity{
    -webkit-transition-property: opacity;
    transition-property: opacity
  }

  .lg\:tw-transition-shadow{
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow
  }

  .lg\:tw-transition-transform{
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
  }

  .lg\:tw-ease-linear{
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear
  }

  .lg\:tw-ease-in{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .lg\:tw-ease-out{
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .lg\:tw-ease-in-out{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .lg\:tw-duration-75{
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms
  }

  .lg\:tw-duration-100{
    -webkit-transition-duration: 100ms;
            transition-duration: 100ms
  }

  .lg\:tw-duration-150{
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms
  }

  .lg\:tw-duration-200{
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms
  }

  .lg\:tw-duration-300{
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms
  }

  .lg\:tw-duration-500{
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms
  }

  .lg\:tw-duration-700{
    -webkit-transition-duration: 700ms;
            transition-duration: 700ms
  }

  .lg\:tw-duration-1000{
    -webkit-transition-duration: 1000ms;
            transition-duration: 1000ms
  }

  .lg\:tw-delay-75{
    -webkit-transition-delay: 75ms;
            transition-delay: 75ms
  }

  .lg\:tw-delay-100{
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms
  }

  .lg\:tw-delay-150{
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms
  }

  .lg\:tw-delay-200{
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms
  }

  .lg\:tw-delay-300{
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms
  }

  .lg\:tw-delay-500{
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms
  }

  .lg\:tw-delay-700{
    -webkit-transition-delay: 700ms;
            transition-delay: 700ms
  }

  .lg\:tw-delay-1000{
    -webkit-transition-delay: 1000ms;
            transition-delay: 1000ms
  }

  .lg\:tw-animate-none{
    -webkit-animation: none;
            animation: none
  }

  .lg\:tw-animate-spin{
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .lg\:tw-animate-ping{
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .lg\:tw-animate-pulse{
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .lg\:tw-animate-bounce{
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

@media (min-width: 1280px){
  .xl\:tw-container{
    width: 100%
  }

  @media (min-width: 640px){
    .xl\:tw-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .xl\:tw-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .xl\:tw-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .xl\:tw-container{
      max-width: 1280px
    }
  }

  .xl\:tw-space-y-0 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .xl\:tw-space-x-0 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .xl\:tw-space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .xl\:tw-space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-1 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-1 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-2 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-2 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-3 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-3 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-4 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-4 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-5 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-5 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-6 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-6 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-8 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-8 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-10 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-10 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-12 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-12 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-16 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-16 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-20 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-20 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-24 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-24 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-32 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-32 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-40 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-40 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-48 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-48 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-56 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-56 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-64 > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .xl\:tw--space-x-64 > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw--space-y-px > :not(template) ~ :not(template){
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .xl\:tw--space-x-px > :not(template) ~ :not(template){
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:tw-space-y-reverse > :not(template) ~ :not(template){
    --space-y-reverse: 1
  }

  .xl\:tw-space-x-reverse > :not(template) ~ :not(template){
    --space-x-reverse: 1
  }

  .xl\:tw-divide-y-0 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .xl\:tw-divide-x-0 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:tw-divide-y-2 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .xl\:tw-divide-x-2 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:tw-divide-y-4 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .xl\:tw-divide-x-4 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:tw-divide-y-8 > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .xl\:tw-divide-x-8 > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:tw-divide-y > :not(template) ~ :not(template){
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .xl\:tw-divide-x > :not(template) ~ :not(template){
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:tw-divide-y-reverse > :not(template) ~ :not(template){
    --divide-y-reverse: 1
  }

  .xl\:tw-divide-x-reverse > :not(template) ~ :not(template){
    --divide-x-reverse: 1
  }

  .xl\:tw-divide-transparent > :not(template) ~ :not(template){
    border-color: transparent
  }

  .xl\:tw-divide-tlg-green-dark > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--divide-opacity))
  }

  .xl\:tw-divide-tlg-green > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--divide-opacity))
  }

  .xl\:tw-divide-tlg-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--divide-opacity))
  }

  .xl\:tw-divide-black > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity))
  }

  .xl\:tw-divide-white > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity))
  }

  .xl\:tw-divide-gray-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity))
  }

  .xl\:tw-divide-red-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity))
  }

  .xl\:tw-divide-red-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity))
  }

  .xl\:tw-divide-red-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity))
  }

  .xl\:tw-divide-red-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity))
  }

  .xl\:tw-divide-red-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity))
  }

  .xl\:tw-divide-red-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity))
  }

  .xl\:tw-divide-red-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity))
  }

  .xl\:tw-divide-red-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity))
  }

  .xl\:tw-divide-red-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity))
  }

  .xl\:tw-divide-orange-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity))
  }

  .xl\:tw-divide-yellow-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity))
  }

  .xl\:tw-divide-green-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity))
  }

  .xl\:tw-divide-green-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity))
  }

  .xl\:tw-divide-green-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity))
  }

  .xl\:tw-divide-green-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity))
  }

  .xl\:tw-divide-green-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity))
  }

  .xl\:tw-divide-green-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity))
  }

  .xl\:tw-divide-green-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity))
  }

  .xl\:tw-divide-green-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity))
  }

  .xl\:tw-divide-green-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity))
  }

  .xl\:tw-divide-teal-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity))
  }

  .xl\:tw-divide-blue-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity))
  }

  .xl\:tw-divide-indigo-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity))
  }

  .xl\:tw-divide-purple-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-100 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-200 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-300 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-400 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-500 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-600 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-700 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-800 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity))
  }

  .xl\:tw-divide-pink-900 > :not(template) ~ :not(template){
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity))
  }

  .xl\:tw-divide-solid > :not(template) ~ :not(template){
    border-style: solid
  }

  .xl\:tw-divide-dashed > :not(template) ~ :not(template){
    border-style: dashed
  }

  .xl\:tw-divide-dotted > :not(template) ~ :not(template){
    border-style: dotted
  }

  .xl\:tw-divide-double > :not(template) ~ :not(template){
    border-style: double
  }

  .xl\:tw-divide-none > :not(template) ~ :not(template){
    border-style: none
  }

  .xl\:tw-divide-opacity-0 > :not(template) ~ :not(template){
    --divide-opacity: 0
  }

  .xl\:tw-divide-opacity-25 > :not(template) ~ :not(template){
    --divide-opacity: 0.25
  }

  .xl\:tw-divide-opacity-50 > :not(template) ~ :not(template){
    --divide-opacity: 0.5
  }

  .xl\:tw-divide-opacity-75 > :not(template) ~ :not(template){
    --divide-opacity: 0.75
  }

  .xl\:tw-divide-opacity-100 > :not(template) ~ :not(template){
    --divide-opacity: 1
  }

  .xl\:tw-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:tw-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:focus\:tw-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:focus\:tw-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:tw-appearance-none{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .xl\:tw-bg-fixed{
    background-attachment: fixed
  }

  .xl\:tw-bg-local{
    background-attachment: local
  }

  .xl\:tw-bg-scroll{
    background-attachment: scroll
  }

  .xl\:tw-bg-clip-border{
    background-clip: border-box
  }

  .xl\:tw-bg-clip-padding{
    background-clip: padding-box
  }

  .xl\:tw-bg-clip-content{
    background-clip: content-box
  }

  .xl\:tw-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  .xl\:tw-bg-transparent{
    background-color: transparent
  }

  .xl\:tw-bg-tlg-green-dark{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .xl\:tw-bg-tlg-green{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .xl\:tw-bg-tlg-orange-500{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .xl\:tw-bg-black{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:tw-bg-white{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-100{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-200{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-300{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-400{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-500{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-600{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-700{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-800{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .xl\:tw-bg-gray-900{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .xl\:tw-bg-red-100{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .xl\:tw-bg-red-200{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .xl\:tw-bg-red-300{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .xl\:tw-bg-red-400{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .xl\:tw-bg-red-500{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .xl\:tw-bg-red-600{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .xl\:tw-bg-red-700{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .xl\:tw-bg-red-800{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .xl\:tw-bg-red-900{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-100{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-200{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-300{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-400{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-500{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-600{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-700{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-800{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .xl\:tw-bg-orange-900{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-100{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-200{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-300{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-400{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-500{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-600{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-700{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-800{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .xl\:tw-bg-yellow-900{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .xl\:tw-bg-green-100{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .xl\:tw-bg-green-200{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .xl\:tw-bg-green-300{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .xl\:tw-bg-green-400{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .xl\:tw-bg-green-500{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .xl\:tw-bg-green-600{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .xl\:tw-bg-green-700{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .xl\:tw-bg-green-800{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .xl\:tw-bg-green-900{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-100{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-200{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-300{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-400{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-500{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-600{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-700{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-800{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .xl\:tw-bg-teal-900{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-100{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-200{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-300{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-400{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-500{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-600{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-700{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-800{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .xl\:tw-bg-blue-900{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-100{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-200{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-300{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-400{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-500{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-600{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-700{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-800{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .xl\:tw-bg-indigo-900{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-100{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-200{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-300{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-400{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-500{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-600{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-700{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-800{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .xl\:tw-bg-purple-900{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-100{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-200{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-300{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-400{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-500{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-600{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-700{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-800{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .xl\:tw-bg-pink-900{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-transparent:hover{
    background-color: transparent
  }

  .xl\:hover\:tw-bg-tlg-green-dark:hover{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-tlg-green:hover{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-tlg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-black:hover{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-white:hover{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-100:hover{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-200:hover{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-300:hover{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-400:hover{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-500:hover{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-600:hover{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-700:hover{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-800:hover{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-gray-900:hover{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-200:hover{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-300:hover{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-400:hover{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-500:hover{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-600:hover{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-700:hover{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-800:hover{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-red-900:hover{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-100:hover{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-200:hover{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-300:hover{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-400:hover{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-500:hover{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-600:hover{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-700:hover{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-800:hover{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-orange-900:hover{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-100:hover{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-200:hover{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-300:hover{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-400:hover{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-500:hover{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-600:hover{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-700:hover{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-800:hover{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-yellow-900:hover{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-100:hover{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-200:hover{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-300:hover{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-400:hover{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-500:hover{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-600:hover{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-700:hover{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-800:hover{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-green-900:hover{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-100:hover{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-200:hover{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-300:hover{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-400:hover{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-500:hover{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-600:hover{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-700:hover{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-800:hover{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-teal-900:hover{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-100:hover{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-200:hover{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-300:hover{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-400:hover{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-500:hover{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-600:hover{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-700:hover{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-800:hover{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-blue-900:hover{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-100:hover{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-200:hover{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-300:hover{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-400:hover{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-500:hover{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-600:hover{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-700:hover{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-800:hover{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-indigo-900:hover{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-100:hover{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-200:hover{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-300:hover{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-400:hover{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-500:hover{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-600:hover{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-700:hover{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-800:hover{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-purple-900:hover{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-100:hover{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-200:hover{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-300:hover{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-400:hover{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-500:hover{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-600:hover{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-700:hover{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-800:hover{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .xl\:hover\:tw-bg-pink-900:hover{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-transparent:focus{
    background-color: transparent
  }

  .xl\:focus\:tw-bg-tlg-green-dark:focus{
    --bg-opacity: 1;
    background-color: #76b84e;
    background-color: rgba(118, 184, 78, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-tlg-green:focus{
    --bg-opacity: 1;
    background-color: #a4ca61;
    background-color: rgba(164, 202, 97, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-tlg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ff7f41;
    background-color: rgba(255, 127, 65, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-black:focus{
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-white:focus{
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-100:focus{
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-200:focus{
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-300:focus{
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-400:focus{
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-500:focus{
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-600:focus{
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-700:focus{
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-800:focus{
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-gray-900:focus{
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-200:focus{
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-300:focus{
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-400:focus{
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-500:focus{
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-600:focus{
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-700:focus{
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-800:focus{
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-red-900:focus{
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-100:focus{
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-200:focus{
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-300:focus{
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-400:focus{
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-500:focus{
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-600:focus{
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-700:focus{
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-800:focus{
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-orange-900:focus{
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-100:focus{
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-200:focus{
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-300:focus{
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-400:focus{
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-500:focus{
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-600:focus{
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-700:focus{
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-800:focus{
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-yellow-900:focus{
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-100:focus{
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-200:focus{
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-300:focus{
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-400:focus{
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-500:focus{
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-600:focus{
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-700:focus{
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-800:focus{
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-green-900:focus{
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-100:focus{
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-200:focus{
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-300:focus{
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-400:focus{
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-500:focus{
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-600:focus{
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-700:focus{
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-800:focus{
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-teal-900:focus{
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-100:focus{
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-200:focus{
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-300:focus{
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-400:focus{
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-500:focus{
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-600:focus{
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-700:focus{
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-800:focus{
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-blue-900:focus{
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-100:focus{
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-200:focus{
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-300:focus{
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-400:focus{
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-500:focus{
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-600:focus{
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-700:focus{
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-800:focus{
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-indigo-900:focus{
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-100:focus{
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-200:focus{
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-300:focus{
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-400:focus{
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-500:focus{
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-600:focus{
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-700:focus{
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-800:focus{
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-purple-900:focus{
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-100:focus{
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-200:focus{
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-300:focus{
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-400:focus{
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-500:focus{
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-600:focus{
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-700:focus{
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-800:focus{
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity))
  }

  .xl\:focus\:tw-bg-pink-900:focus{
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity))
  }

  .xl\:tw-bg-none{
    background-image: none
  }

  .xl\:tw-bg-gradient-to-t{
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom, var(--gradient-color-stops));
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-tr{
    background-image: -webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom left, var(--gradient-color-stops));
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-r{
    background-image: -webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(left, var(--gradient-color-stops));
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-br{
    background-image: -webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top left, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-b{
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-bl{
    background-image: -webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(top right, var(--gradient-color-stops));
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-l{
    background-image: -webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(right, var(--gradient-color-stops));
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .xl\:tw-bg-gradient-to-tl{
    background-image: -webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image: -webkit-linear-gradient(bottom right, var(--gradient-color-stops));
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xl\:tw-from-transparent{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:tw-from-tlg-green-dark{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .xl\:tw-from-tlg-green{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .xl\:tw-from-tlg-orange-500{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .xl\:tw-from-black{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:tw-from-white{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:tw-from-gray-100{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:tw-from-gray-200{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:tw-from-gray-300{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:tw-from-gray-400{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:tw-from-gray-500{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:tw-from-gray-600{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:tw-from-gray-700{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:tw-from-gray-800{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:tw-from-gray-900{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:tw-from-red-100{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:tw-from-red-200{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:tw-from-red-300{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:tw-from-red-400{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:tw-from-red-500{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:tw-from-red-600{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:tw-from-red-700{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:tw-from-red-800{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:tw-from-red-900{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:tw-from-orange-100{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:tw-from-orange-200{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:tw-from-orange-300{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:tw-from-orange-400{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:tw-from-orange-500{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:tw-from-orange-600{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:tw-from-orange-700{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:tw-from-orange-800{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:tw-from-orange-900{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:tw-from-yellow-100{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:tw-from-yellow-200{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:tw-from-yellow-300{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:tw-from-yellow-400{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:tw-from-yellow-500{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:tw-from-yellow-600{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:tw-from-yellow-700{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:tw-from-yellow-800{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:tw-from-yellow-900{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:tw-from-green-100{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:tw-from-green-200{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:tw-from-green-300{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:tw-from-green-400{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:tw-from-green-500{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:tw-from-green-600{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:tw-from-green-700{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:tw-from-green-800{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:tw-from-green-900{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:tw-from-teal-100{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:tw-from-teal-200{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:tw-from-teal-300{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:tw-from-teal-400{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:tw-from-teal-500{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:tw-from-teal-600{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:tw-from-teal-700{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:tw-from-teal-800{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:tw-from-teal-900{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:tw-from-blue-100{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:tw-from-blue-200{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:tw-from-blue-300{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:tw-from-blue-400{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:tw-from-blue-500{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:tw-from-blue-600{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:tw-from-blue-700{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:tw-from-blue-800{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:tw-from-blue-900{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:tw-from-indigo-100{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:tw-from-indigo-200{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:tw-from-indigo-300{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:tw-from-indigo-400{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:tw-from-indigo-500{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:tw-from-indigo-600{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:tw-from-indigo-700{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:tw-from-indigo-800{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:tw-from-indigo-900{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:tw-from-purple-100{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:tw-from-purple-200{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:tw-from-purple-300{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:tw-from-purple-400{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:tw-from-purple-500{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:tw-from-purple-600{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:tw-from-purple-700{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:tw-from-purple-800{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:tw-from-purple-900{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:tw-from-pink-100{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:tw-from-pink-200{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:tw-from-pink-300{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:tw-from-pink-400{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:tw-from-pink-500{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:tw-from-pink-600{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:tw-from-pink-700{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:tw-from-pink-800{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:tw-from-pink-900{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:tw-via-transparent{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:tw-via-tlg-green-dark{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .xl\:tw-via-tlg-green{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .xl\:tw-via-tlg-orange-500{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .xl\:tw-via-black{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:tw-via-white{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:tw-via-gray-100{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:tw-via-gray-200{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:tw-via-gray-300{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:tw-via-gray-400{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:tw-via-gray-500{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:tw-via-gray-600{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:tw-via-gray-700{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:tw-via-gray-800{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:tw-via-gray-900{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:tw-via-red-100{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:tw-via-red-200{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:tw-via-red-300{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:tw-via-red-400{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:tw-via-red-500{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:tw-via-red-600{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:tw-via-red-700{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:tw-via-red-800{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:tw-via-red-900{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:tw-via-orange-100{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:tw-via-orange-200{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:tw-via-orange-300{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:tw-via-orange-400{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:tw-via-orange-500{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:tw-via-orange-600{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:tw-via-orange-700{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:tw-via-orange-800{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:tw-via-orange-900{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:tw-via-yellow-100{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:tw-via-yellow-200{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:tw-via-yellow-300{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:tw-via-yellow-400{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:tw-via-yellow-500{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:tw-via-yellow-600{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:tw-via-yellow-700{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:tw-via-yellow-800{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:tw-via-yellow-900{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:tw-via-green-100{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:tw-via-green-200{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:tw-via-green-300{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:tw-via-green-400{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:tw-via-green-500{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:tw-via-green-600{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:tw-via-green-700{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:tw-via-green-800{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:tw-via-green-900{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:tw-via-teal-100{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:tw-via-teal-200{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:tw-via-teal-300{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:tw-via-teal-400{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:tw-via-teal-500{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:tw-via-teal-600{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:tw-via-teal-700{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:tw-via-teal-800{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:tw-via-teal-900{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:tw-via-blue-100{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:tw-via-blue-200{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:tw-via-blue-300{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:tw-via-blue-400{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:tw-via-blue-500{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:tw-via-blue-600{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:tw-via-blue-700{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:tw-via-blue-800{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:tw-via-blue-900{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:tw-via-indigo-100{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:tw-via-indigo-200{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:tw-via-indigo-300{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:tw-via-indigo-400{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:tw-via-indigo-500{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:tw-via-indigo-600{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:tw-via-indigo-700{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:tw-via-indigo-800{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:tw-via-indigo-900{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:tw-via-purple-100{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:tw-via-purple-200{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:tw-via-purple-300{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:tw-via-purple-400{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:tw-via-purple-500{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:tw-via-purple-600{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:tw-via-purple-700{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:tw-via-purple-800{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:tw-via-purple-900{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:tw-via-pink-100{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:tw-via-pink-200{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:tw-via-pink-300{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:tw-via-pink-400{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:tw-via-pink-500{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:tw-via-pink-600{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:tw-via-pink-700{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:tw-via-pink-800{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:tw-via-pink-900{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:tw-to-transparent{
    --gradient-to-color: transparent
  }

  .xl\:tw-to-tlg-green-dark{
    --gradient-to-color: #76b84e
  }

  .xl\:tw-to-tlg-green{
    --gradient-to-color: #a4ca61
  }

  .xl\:tw-to-tlg-orange-500{
    --gradient-to-color: #ff7f41
  }

  .xl\:tw-to-black{
    --gradient-to-color: #000
  }

  .xl\:tw-to-white{
    --gradient-to-color: #fff
  }

  .xl\:tw-to-gray-100{
    --gradient-to-color: #f7fafc
  }

  .xl\:tw-to-gray-200{
    --gradient-to-color: #edf2f7
  }

  .xl\:tw-to-gray-300{
    --gradient-to-color: #e2e8f0
  }

  .xl\:tw-to-gray-400{
    --gradient-to-color: #cbd5e0
  }

  .xl\:tw-to-gray-500{
    --gradient-to-color: #a0aec0
  }

  .xl\:tw-to-gray-600{
    --gradient-to-color: #718096
  }

  .xl\:tw-to-gray-700{
    --gradient-to-color: #4a5568
  }

  .xl\:tw-to-gray-800{
    --gradient-to-color: #2d3748
  }

  .xl\:tw-to-gray-900{
    --gradient-to-color: #1a202c
  }

  .xl\:tw-to-red-100{
    --gradient-to-color: #fff5f5
  }

  .xl\:tw-to-red-200{
    --gradient-to-color: #fed7d7
  }

  .xl\:tw-to-red-300{
    --gradient-to-color: #feb2b2
  }

  .xl\:tw-to-red-400{
    --gradient-to-color: #fc8181
  }

  .xl\:tw-to-red-500{
    --gradient-to-color: #f56565
  }

  .xl\:tw-to-red-600{
    --gradient-to-color: #e53e3e
  }

  .xl\:tw-to-red-700{
    --gradient-to-color: #c53030
  }

  .xl\:tw-to-red-800{
    --gradient-to-color: #9b2c2c
  }

  .xl\:tw-to-red-900{
    --gradient-to-color: #742a2a
  }

  .xl\:tw-to-orange-100{
    --gradient-to-color: #fffaf0
  }

  .xl\:tw-to-orange-200{
    --gradient-to-color: #feebc8
  }

  .xl\:tw-to-orange-300{
    --gradient-to-color: #fbd38d
  }

  .xl\:tw-to-orange-400{
    --gradient-to-color: #f6ad55
  }

  .xl\:tw-to-orange-500{
    --gradient-to-color: #ed8936
  }

  .xl\:tw-to-orange-600{
    --gradient-to-color: #dd6b20
  }

  .xl\:tw-to-orange-700{
    --gradient-to-color: #c05621
  }

  .xl\:tw-to-orange-800{
    --gradient-to-color: #9c4221
  }

  .xl\:tw-to-orange-900{
    --gradient-to-color: #7b341e
  }

  .xl\:tw-to-yellow-100{
    --gradient-to-color: #fffff0
  }

  .xl\:tw-to-yellow-200{
    --gradient-to-color: #fefcbf
  }

  .xl\:tw-to-yellow-300{
    --gradient-to-color: #faf089
  }

  .xl\:tw-to-yellow-400{
    --gradient-to-color: #f6e05e
  }

  .xl\:tw-to-yellow-500{
    --gradient-to-color: #ecc94b
  }

  .xl\:tw-to-yellow-600{
    --gradient-to-color: #d69e2e
  }

  .xl\:tw-to-yellow-700{
    --gradient-to-color: #b7791f
  }

  .xl\:tw-to-yellow-800{
    --gradient-to-color: #975a16
  }

  .xl\:tw-to-yellow-900{
    --gradient-to-color: #744210
  }

  .xl\:tw-to-green-100{
    --gradient-to-color: #f0fff4
  }

  .xl\:tw-to-green-200{
    --gradient-to-color: #c6f6d5
  }

  .xl\:tw-to-green-300{
    --gradient-to-color: #9ae6b4
  }

  .xl\:tw-to-green-400{
    --gradient-to-color: #68d391
  }

  .xl\:tw-to-green-500{
    --gradient-to-color: #48bb78
  }

  .xl\:tw-to-green-600{
    --gradient-to-color: #38a169
  }

  .xl\:tw-to-green-700{
    --gradient-to-color: #2f855a
  }

  .xl\:tw-to-green-800{
    --gradient-to-color: #276749
  }

  .xl\:tw-to-green-900{
    --gradient-to-color: #22543d
  }

  .xl\:tw-to-teal-100{
    --gradient-to-color: #e6fffa
  }

  .xl\:tw-to-teal-200{
    --gradient-to-color: #b2f5ea
  }

  .xl\:tw-to-teal-300{
    --gradient-to-color: #81e6d9
  }

  .xl\:tw-to-teal-400{
    --gradient-to-color: #4fd1c5
  }

  .xl\:tw-to-teal-500{
    --gradient-to-color: #38b2ac
  }

  .xl\:tw-to-teal-600{
    --gradient-to-color: #319795
  }

  .xl\:tw-to-teal-700{
    --gradient-to-color: #2c7a7b
  }

  .xl\:tw-to-teal-800{
    --gradient-to-color: #285e61
  }

  .xl\:tw-to-teal-900{
    --gradient-to-color: #234e52
  }

  .xl\:tw-to-blue-100{
    --gradient-to-color: #ebf8ff
  }

  .xl\:tw-to-blue-200{
    --gradient-to-color: #bee3f8
  }

  .xl\:tw-to-blue-300{
    --gradient-to-color: #90cdf4
  }

  .xl\:tw-to-blue-400{
    --gradient-to-color: #63b3ed
  }

  .xl\:tw-to-blue-500{
    --gradient-to-color: #4299e1
  }

  .xl\:tw-to-blue-600{
    --gradient-to-color: #3182ce
  }

  .xl\:tw-to-blue-700{
    --gradient-to-color: #2b6cb0
  }

  .xl\:tw-to-blue-800{
    --gradient-to-color: #2c5282
  }

  .xl\:tw-to-blue-900{
    --gradient-to-color: #2a4365
  }

  .xl\:tw-to-indigo-100{
    --gradient-to-color: #ebf4ff
  }

  .xl\:tw-to-indigo-200{
    --gradient-to-color: #c3dafe
  }

  .xl\:tw-to-indigo-300{
    --gradient-to-color: #a3bffa
  }

  .xl\:tw-to-indigo-400{
    --gradient-to-color: #7f9cf5
  }

  .xl\:tw-to-indigo-500{
    --gradient-to-color: #667eea
  }

  .xl\:tw-to-indigo-600{
    --gradient-to-color: #5a67d8
  }

  .xl\:tw-to-indigo-700{
    --gradient-to-color: #4c51bf
  }

  .xl\:tw-to-indigo-800{
    --gradient-to-color: #434190
  }

  .xl\:tw-to-indigo-900{
    --gradient-to-color: #3c366b
  }

  .xl\:tw-to-purple-100{
    --gradient-to-color: #faf5ff
  }

  .xl\:tw-to-purple-200{
    --gradient-to-color: #e9d8fd
  }

  .xl\:tw-to-purple-300{
    --gradient-to-color: #d6bcfa
  }

  .xl\:tw-to-purple-400{
    --gradient-to-color: #b794f4
  }

  .xl\:tw-to-purple-500{
    --gradient-to-color: #9f7aea
  }

  .xl\:tw-to-purple-600{
    --gradient-to-color: #805ad5
  }

  .xl\:tw-to-purple-700{
    --gradient-to-color: #6b46c1
  }

  .xl\:tw-to-purple-800{
    --gradient-to-color: #553c9a
  }

  .xl\:tw-to-purple-900{
    --gradient-to-color: #44337a
  }

  .xl\:tw-to-pink-100{
    --gradient-to-color: #fff5f7
  }

  .xl\:tw-to-pink-200{
    --gradient-to-color: #fed7e2
  }

  .xl\:tw-to-pink-300{
    --gradient-to-color: #fbb6ce
  }

  .xl\:tw-to-pink-400{
    --gradient-to-color: #f687b3
  }

  .xl\:tw-to-pink-500{
    --gradient-to-color: #ed64a6
  }

  .xl\:tw-to-pink-600{
    --gradient-to-color: #d53f8c
  }

  .xl\:tw-to-pink-700{
    --gradient-to-color: #b83280
  }

  .xl\:tw-to-pink-800{
    --gradient-to-color: #97266d
  }

  .xl\:tw-to-pink-900{
    --gradient-to-color: #702459
  }

  .xl\:hover\:tw-from-transparent:hover{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:tw-from-tlg-green-dark:hover{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .xl\:hover\:tw-from-tlg-green:hover{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .xl\:hover\:tw-from-tlg-orange-500:hover{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .xl\:hover\:tw-from-black:hover{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:tw-from-white:hover{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:tw-from-gray-100:hover{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:hover\:tw-from-gray-200:hover{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:hover\:tw-from-gray-300:hover{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:hover\:tw-from-gray-400:hover{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:hover\:tw-from-gray-500:hover{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:hover\:tw-from-gray-600:hover{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:hover\:tw-from-gray-700:hover{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:hover\:tw-from-gray-800:hover{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:hover\:tw-from-gray-900:hover{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:hover\:tw-from-red-100:hover{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:hover\:tw-from-red-200:hover{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:hover\:tw-from-red-300:hover{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:hover\:tw-from-red-400:hover{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:hover\:tw-from-red-500:hover{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:hover\:tw-from-red-600:hover{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:hover\:tw-from-red-700:hover{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:hover\:tw-from-red-800:hover{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:hover\:tw-from-red-900:hover{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:hover\:tw-from-orange-100:hover{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:hover\:tw-from-orange-200:hover{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:hover\:tw-from-orange-300:hover{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:hover\:tw-from-orange-400:hover{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:hover\:tw-from-orange-500:hover{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:hover\:tw-from-orange-600:hover{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:hover\:tw-from-orange-700:hover{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:hover\:tw-from-orange-800:hover{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:hover\:tw-from-orange-900:hover{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:hover\:tw-from-yellow-100:hover{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:hover\:tw-from-yellow-200:hover{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:hover\:tw-from-yellow-300:hover{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:hover\:tw-from-yellow-400:hover{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:hover\:tw-from-yellow-500:hover{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:hover\:tw-from-yellow-600:hover{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:hover\:tw-from-yellow-700:hover{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:hover\:tw-from-yellow-800:hover{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:hover\:tw-from-yellow-900:hover{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:hover\:tw-from-green-100:hover{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:hover\:tw-from-green-200:hover{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:hover\:tw-from-green-300:hover{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:hover\:tw-from-green-400:hover{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:hover\:tw-from-green-500:hover{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:hover\:tw-from-green-600:hover{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:hover\:tw-from-green-700:hover{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:hover\:tw-from-green-800:hover{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:hover\:tw-from-green-900:hover{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:hover\:tw-from-teal-100:hover{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:hover\:tw-from-teal-200:hover{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:hover\:tw-from-teal-300:hover{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:hover\:tw-from-teal-400:hover{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:hover\:tw-from-teal-500:hover{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:hover\:tw-from-teal-600:hover{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:hover\:tw-from-teal-700:hover{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:hover\:tw-from-teal-800:hover{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:hover\:tw-from-teal-900:hover{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:hover\:tw-from-blue-100:hover{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:hover\:tw-from-blue-200:hover{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:hover\:tw-from-blue-300:hover{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:hover\:tw-from-blue-400:hover{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:hover\:tw-from-blue-500:hover{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:hover\:tw-from-blue-600:hover{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:hover\:tw-from-blue-700:hover{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:hover\:tw-from-blue-800:hover{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:hover\:tw-from-blue-900:hover{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:hover\:tw-from-indigo-100:hover{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:hover\:tw-from-indigo-200:hover{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:hover\:tw-from-indigo-300:hover{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:hover\:tw-from-indigo-400:hover{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:hover\:tw-from-indigo-500:hover{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:hover\:tw-from-indigo-600:hover{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:hover\:tw-from-indigo-700:hover{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:hover\:tw-from-indigo-800:hover{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:hover\:tw-from-indigo-900:hover{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:hover\:tw-from-purple-100:hover{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:hover\:tw-from-purple-200:hover{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:hover\:tw-from-purple-300:hover{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:hover\:tw-from-purple-400:hover{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:hover\:tw-from-purple-500:hover{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:hover\:tw-from-purple-600:hover{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:hover\:tw-from-purple-700:hover{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:hover\:tw-from-purple-800:hover{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:hover\:tw-from-purple-900:hover{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:hover\:tw-from-pink-100:hover{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:hover\:tw-from-pink-200:hover{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:hover\:tw-from-pink-300:hover{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:hover\:tw-from-pink-400:hover{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:hover\:tw-from-pink-500:hover{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:hover\:tw-from-pink-600:hover{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:hover\:tw-from-pink-700:hover{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:hover\:tw-from-pink-800:hover{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:hover\:tw-from-pink-900:hover{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:hover\:tw-via-transparent:hover{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:tw-via-tlg-green-dark:hover{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .xl\:hover\:tw-via-tlg-green:hover{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .xl\:hover\:tw-via-tlg-orange-500:hover{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .xl\:hover\:tw-via-black:hover{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:tw-via-white:hover{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:tw-via-gray-100:hover{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:hover\:tw-via-gray-200:hover{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:hover\:tw-via-gray-300:hover{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:hover\:tw-via-gray-400:hover{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:hover\:tw-via-gray-500:hover{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:hover\:tw-via-gray-600:hover{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:hover\:tw-via-gray-700:hover{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:hover\:tw-via-gray-800:hover{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:hover\:tw-via-gray-900:hover{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:hover\:tw-via-red-100:hover{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:hover\:tw-via-red-200:hover{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:hover\:tw-via-red-300:hover{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:hover\:tw-via-red-400:hover{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:hover\:tw-via-red-500:hover{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:hover\:tw-via-red-600:hover{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:hover\:tw-via-red-700:hover{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:hover\:tw-via-red-800:hover{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:hover\:tw-via-red-900:hover{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:hover\:tw-via-orange-100:hover{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:hover\:tw-via-orange-200:hover{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:hover\:tw-via-orange-300:hover{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:hover\:tw-via-orange-400:hover{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:hover\:tw-via-orange-500:hover{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:hover\:tw-via-orange-600:hover{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:hover\:tw-via-orange-700:hover{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:hover\:tw-via-orange-800:hover{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:hover\:tw-via-orange-900:hover{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:hover\:tw-via-yellow-100:hover{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:hover\:tw-via-yellow-200:hover{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:hover\:tw-via-yellow-300:hover{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:hover\:tw-via-yellow-400:hover{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:hover\:tw-via-yellow-500:hover{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:hover\:tw-via-yellow-600:hover{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:hover\:tw-via-yellow-700:hover{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:hover\:tw-via-yellow-800:hover{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:hover\:tw-via-yellow-900:hover{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:hover\:tw-via-green-100:hover{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:hover\:tw-via-green-200:hover{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:hover\:tw-via-green-300:hover{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:hover\:tw-via-green-400:hover{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:hover\:tw-via-green-500:hover{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:hover\:tw-via-green-600:hover{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:hover\:tw-via-green-700:hover{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:hover\:tw-via-green-800:hover{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:hover\:tw-via-green-900:hover{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:hover\:tw-via-teal-100:hover{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:hover\:tw-via-teal-200:hover{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:hover\:tw-via-teal-300:hover{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:hover\:tw-via-teal-400:hover{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:hover\:tw-via-teal-500:hover{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:hover\:tw-via-teal-600:hover{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:hover\:tw-via-teal-700:hover{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:hover\:tw-via-teal-800:hover{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:hover\:tw-via-teal-900:hover{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:hover\:tw-via-blue-100:hover{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:hover\:tw-via-blue-200:hover{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:hover\:tw-via-blue-300:hover{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:hover\:tw-via-blue-400:hover{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:hover\:tw-via-blue-500:hover{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:hover\:tw-via-blue-600:hover{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:hover\:tw-via-blue-700:hover{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:hover\:tw-via-blue-800:hover{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:hover\:tw-via-blue-900:hover{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:hover\:tw-via-indigo-100:hover{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:hover\:tw-via-indigo-200:hover{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:hover\:tw-via-indigo-300:hover{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:hover\:tw-via-indigo-400:hover{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:hover\:tw-via-indigo-500:hover{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:hover\:tw-via-indigo-600:hover{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:hover\:tw-via-indigo-700:hover{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:hover\:tw-via-indigo-800:hover{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:hover\:tw-via-indigo-900:hover{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:hover\:tw-via-purple-100:hover{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:hover\:tw-via-purple-200:hover{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:hover\:tw-via-purple-300:hover{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:hover\:tw-via-purple-400:hover{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:hover\:tw-via-purple-500:hover{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:hover\:tw-via-purple-600:hover{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:hover\:tw-via-purple-700:hover{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:hover\:tw-via-purple-800:hover{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:hover\:tw-via-purple-900:hover{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:hover\:tw-via-pink-100:hover{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:hover\:tw-via-pink-200:hover{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:hover\:tw-via-pink-300:hover{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:hover\:tw-via-pink-400:hover{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:hover\:tw-via-pink-500:hover{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:hover\:tw-via-pink-600:hover{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:hover\:tw-via-pink-700:hover{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:hover\:tw-via-pink-800:hover{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:hover\:tw-via-pink-900:hover{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:hover\:tw-to-transparent:hover{
    --gradient-to-color: transparent
  }

  .xl\:hover\:tw-to-tlg-green-dark:hover{
    --gradient-to-color: #76b84e
  }

  .xl\:hover\:tw-to-tlg-green:hover{
    --gradient-to-color: #a4ca61
  }

  .xl\:hover\:tw-to-tlg-orange-500:hover{
    --gradient-to-color: #ff7f41
  }

  .xl\:hover\:tw-to-black:hover{
    --gradient-to-color: #000
  }

  .xl\:hover\:tw-to-white:hover{
    --gradient-to-color: #fff
  }

  .xl\:hover\:tw-to-gray-100:hover{
    --gradient-to-color: #f7fafc
  }

  .xl\:hover\:tw-to-gray-200:hover{
    --gradient-to-color: #edf2f7
  }

  .xl\:hover\:tw-to-gray-300:hover{
    --gradient-to-color: #e2e8f0
  }

  .xl\:hover\:tw-to-gray-400:hover{
    --gradient-to-color: #cbd5e0
  }

  .xl\:hover\:tw-to-gray-500:hover{
    --gradient-to-color: #a0aec0
  }

  .xl\:hover\:tw-to-gray-600:hover{
    --gradient-to-color: #718096
  }

  .xl\:hover\:tw-to-gray-700:hover{
    --gradient-to-color: #4a5568
  }

  .xl\:hover\:tw-to-gray-800:hover{
    --gradient-to-color: #2d3748
  }

  .xl\:hover\:tw-to-gray-900:hover{
    --gradient-to-color: #1a202c
  }

  .xl\:hover\:tw-to-red-100:hover{
    --gradient-to-color: #fff5f5
  }

  .xl\:hover\:tw-to-red-200:hover{
    --gradient-to-color: #fed7d7
  }

  .xl\:hover\:tw-to-red-300:hover{
    --gradient-to-color: #feb2b2
  }

  .xl\:hover\:tw-to-red-400:hover{
    --gradient-to-color: #fc8181
  }

  .xl\:hover\:tw-to-red-500:hover{
    --gradient-to-color: #f56565
  }

  .xl\:hover\:tw-to-red-600:hover{
    --gradient-to-color: #e53e3e
  }

  .xl\:hover\:tw-to-red-700:hover{
    --gradient-to-color: #c53030
  }

  .xl\:hover\:tw-to-red-800:hover{
    --gradient-to-color: #9b2c2c
  }

  .xl\:hover\:tw-to-red-900:hover{
    --gradient-to-color: #742a2a
  }

  .xl\:hover\:tw-to-orange-100:hover{
    --gradient-to-color: #fffaf0
  }

  .xl\:hover\:tw-to-orange-200:hover{
    --gradient-to-color: #feebc8
  }

  .xl\:hover\:tw-to-orange-300:hover{
    --gradient-to-color: #fbd38d
  }

  .xl\:hover\:tw-to-orange-400:hover{
    --gradient-to-color: #f6ad55
  }

  .xl\:hover\:tw-to-orange-500:hover{
    --gradient-to-color: #ed8936
  }

  .xl\:hover\:tw-to-orange-600:hover{
    --gradient-to-color: #dd6b20
  }

  .xl\:hover\:tw-to-orange-700:hover{
    --gradient-to-color: #c05621
  }

  .xl\:hover\:tw-to-orange-800:hover{
    --gradient-to-color: #9c4221
  }

  .xl\:hover\:tw-to-orange-900:hover{
    --gradient-to-color: #7b341e
  }

  .xl\:hover\:tw-to-yellow-100:hover{
    --gradient-to-color: #fffff0
  }

  .xl\:hover\:tw-to-yellow-200:hover{
    --gradient-to-color: #fefcbf
  }

  .xl\:hover\:tw-to-yellow-300:hover{
    --gradient-to-color: #faf089
  }

  .xl\:hover\:tw-to-yellow-400:hover{
    --gradient-to-color: #f6e05e
  }

  .xl\:hover\:tw-to-yellow-500:hover{
    --gradient-to-color: #ecc94b
  }

  .xl\:hover\:tw-to-yellow-600:hover{
    --gradient-to-color: #d69e2e
  }

  .xl\:hover\:tw-to-yellow-700:hover{
    --gradient-to-color: #b7791f
  }

  .xl\:hover\:tw-to-yellow-800:hover{
    --gradient-to-color: #975a16
  }

  .xl\:hover\:tw-to-yellow-900:hover{
    --gradient-to-color: #744210
  }

  .xl\:hover\:tw-to-green-100:hover{
    --gradient-to-color: #f0fff4
  }

  .xl\:hover\:tw-to-green-200:hover{
    --gradient-to-color: #c6f6d5
  }

  .xl\:hover\:tw-to-green-300:hover{
    --gradient-to-color: #9ae6b4
  }

  .xl\:hover\:tw-to-green-400:hover{
    --gradient-to-color: #68d391
  }

  .xl\:hover\:tw-to-green-500:hover{
    --gradient-to-color: #48bb78
  }

  .xl\:hover\:tw-to-green-600:hover{
    --gradient-to-color: #38a169
  }

  .xl\:hover\:tw-to-green-700:hover{
    --gradient-to-color: #2f855a
  }

  .xl\:hover\:tw-to-green-800:hover{
    --gradient-to-color: #276749
  }

  .xl\:hover\:tw-to-green-900:hover{
    --gradient-to-color: #22543d
  }

  .xl\:hover\:tw-to-teal-100:hover{
    --gradient-to-color: #e6fffa
  }

  .xl\:hover\:tw-to-teal-200:hover{
    --gradient-to-color: #b2f5ea
  }

  .xl\:hover\:tw-to-teal-300:hover{
    --gradient-to-color: #81e6d9
  }

  .xl\:hover\:tw-to-teal-400:hover{
    --gradient-to-color: #4fd1c5
  }

  .xl\:hover\:tw-to-teal-500:hover{
    --gradient-to-color: #38b2ac
  }

  .xl\:hover\:tw-to-teal-600:hover{
    --gradient-to-color: #319795
  }

  .xl\:hover\:tw-to-teal-700:hover{
    --gradient-to-color: #2c7a7b
  }

  .xl\:hover\:tw-to-teal-800:hover{
    --gradient-to-color: #285e61
  }

  .xl\:hover\:tw-to-teal-900:hover{
    --gradient-to-color: #234e52
  }

  .xl\:hover\:tw-to-blue-100:hover{
    --gradient-to-color: #ebf8ff
  }

  .xl\:hover\:tw-to-blue-200:hover{
    --gradient-to-color: #bee3f8
  }

  .xl\:hover\:tw-to-blue-300:hover{
    --gradient-to-color: #90cdf4
  }

  .xl\:hover\:tw-to-blue-400:hover{
    --gradient-to-color: #63b3ed
  }

  .xl\:hover\:tw-to-blue-500:hover{
    --gradient-to-color: #4299e1
  }

  .xl\:hover\:tw-to-blue-600:hover{
    --gradient-to-color: #3182ce
  }

  .xl\:hover\:tw-to-blue-700:hover{
    --gradient-to-color: #2b6cb0
  }

  .xl\:hover\:tw-to-blue-800:hover{
    --gradient-to-color: #2c5282
  }

  .xl\:hover\:tw-to-blue-900:hover{
    --gradient-to-color: #2a4365
  }

  .xl\:hover\:tw-to-indigo-100:hover{
    --gradient-to-color: #ebf4ff
  }

  .xl\:hover\:tw-to-indigo-200:hover{
    --gradient-to-color: #c3dafe
  }

  .xl\:hover\:tw-to-indigo-300:hover{
    --gradient-to-color: #a3bffa
  }

  .xl\:hover\:tw-to-indigo-400:hover{
    --gradient-to-color: #7f9cf5
  }

  .xl\:hover\:tw-to-indigo-500:hover{
    --gradient-to-color: #667eea
  }

  .xl\:hover\:tw-to-indigo-600:hover{
    --gradient-to-color: #5a67d8
  }

  .xl\:hover\:tw-to-indigo-700:hover{
    --gradient-to-color: #4c51bf
  }

  .xl\:hover\:tw-to-indigo-800:hover{
    --gradient-to-color: #434190
  }

  .xl\:hover\:tw-to-indigo-900:hover{
    --gradient-to-color: #3c366b
  }

  .xl\:hover\:tw-to-purple-100:hover{
    --gradient-to-color: #faf5ff
  }

  .xl\:hover\:tw-to-purple-200:hover{
    --gradient-to-color: #e9d8fd
  }

  .xl\:hover\:tw-to-purple-300:hover{
    --gradient-to-color: #d6bcfa
  }

  .xl\:hover\:tw-to-purple-400:hover{
    --gradient-to-color: #b794f4
  }

  .xl\:hover\:tw-to-purple-500:hover{
    --gradient-to-color: #9f7aea
  }

  .xl\:hover\:tw-to-purple-600:hover{
    --gradient-to-color: #805ad5
  }

  .xl\:hover\:tw-to-purple-700:hover{
    --gradient-to-color: #6b46c1
  }

  .xl\:hover\:tw-to-purple-800:hover{
    --gradient-to-color: #553c9a
  }

  .xl\:hover\:tw-to-purple-900:hover{
    --gradient-to-color: #44337a
  }

  .xl\:hover\:tw-to-pink-100:hover{
    --gradient-to-color: #fff5f7
  }

  .xl\:hover\:tw-to-pink-200:hover{
    --gradient-to-color: #fed7e2
  }

  .xl\:hover\:tw-to-pink-300:hover{
    --gradient-to-color: #fbb6ce
  }

  .xl\:hover\:tw-to-pink-400:hover{
    --gradient-to-color: #f687b3
  }

  .xl\:hover\:tw-to-pink-500:hover{
    --gradient-to-color: #ed64a6
  }

  .xl\:hover\:tw-to-pink-600:hover{
    --gradient-to-color: #d53f8c
  }

  .xl\:hover\:tw-to-pink-700:hover{
    --gradient-to-color: #b83280
  }

  .xl\:hover\:tw-to-pink-800:hover{
    --gradient-to-color: #97266d
  }

  .xl\:hover\:tw-to-pink-900:hover{
    --gradient-to-color: #702459
  }

  .xl\:focus\:tw-from-transparent:focus{
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:tw-from-tlg-green-dark:focus{
    --gradient-from-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .xl\:focus\:tw-from-tlg-green:focus{
    --gradient-from-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .xl\:focus\:tw-from-tlg-orange-500:focus{
    --gradient-from-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .xl\:focus\:tw-from-black:focus{
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:tw-from-white:focus{
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:tw-from-gray-100:focus{
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:focus\:tw-from-gray-200:focus{
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:focus\:tw-from-gray-300:focus{
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:focus\:tw-from-gray-400:focus{
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:focus\:tw-from-gray-500:focus{
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:focus\:tw-from-gray-600:focus{
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:focus\:tw-from-gray-700:focus{
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:focus\:tw-from-gray-800:focus{
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:focus\:tw-from-gray-900:focus{
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:focus\:tw-from-red-100:focus{
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:focus\:tw-from-red-200:focus{
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:focus\:tw-from-red-300:focus{
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:focus\:tw-from-red-400:focus{
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:focus\:tw-from-red-500:focus{
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:focus\:tw-from-red-600:focus{
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:focus\:tw-from-red-700:focus{
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:focus\:tw-from-red-800:focus{
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:focus\:tw-from-red-900:focus{
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:focus\:tw-from-orange-100:focus{
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:focus\:tw-from-orange-200:focus{
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:focus\:tw-from-orange-300:focus{
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:focus\:tw-from-orange-400:focus{
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:focus\:tw-from-orange-500:focus{
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:focus\:tw-from-orange-600:focus{
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:focus\:tw-from-orange-700:focus{
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:focus\:tw-from-orange-800:focus{
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:focus\:tw-from-orange-900:focus{
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:focus\:tw-from-yellow-100:focus{
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:focus\:tw-from-yellow-200:focus{
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:focus\:tw-from-yellow-300:focus{
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:focus\:tw-from-yellow-400:focus{
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:focus\:tw-from-yellow-500:focus{
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:focus\:tw-from-yellow-600:focus{
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:focus\:tw-from-yellow-700:focus{
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:focus\:tw-from-yellow-800:focus{
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:focus\:tw-from-yellow-900:focus{
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:focus\:tw-from-green-100:focus{
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:focus\:tw-from-green-200:focus{
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:focus\:tw-from-green-300:focus{
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:focus\:tw-from-green-400:focus{
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:focus\:tw-from-green-500:focus{
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:focus\:tw-from-green-600:focus{
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:focus\:tw-from-green-700:focus{
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:focus\:tw-from-green-800:focus{
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:focus\:tw-from-green-900:focus{
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:focus\:tw-from-teal-100:focus{
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:focus\:tw-from-teal-200:focus{
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:focus\:tw-from-teal-300:focus{
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:focus\:tw-from-teal-400:focus{
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:focus\:tw-from-teal-500:focus{
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:focus\:tw-from-teal-600:focus{
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:focus\:tw-from-teal-700:focus{
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:focus\:tw-from-teal-800:focus{
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:focus\:tw-from-teal-900:focus{
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:focus\:tw-from-blue-100:focus{
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:focus\:tw-from-blue-200:focus{
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:focus\:tw-from-blue-300:focus{
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:focus\:tw-from-blue-400:focus{
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:focus\:tw-from-blue-500:focus{
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:focus\:tw-from-blue-600:focus{
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:focus\:tw-from-blue-700:focus{
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:focus\:tw-from-blue-800:focus{
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:focus\:tw-from-blue-900:focus{
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:focus\:tw-from-indigo-100:focus{
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:focus\:tw-from-indigo-200:focus{
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:focus\:tw-from-indigo-300:focus{
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:focus\:tw-from-indigo-400:focus{
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:focus\:tw-from-indigo-500:focus{
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:focus\:tw-from-indigo-600:focus{
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:focus\:tw-from-indigo-700:focus{
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:focus\:tw-from-indigo-800:focus{
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:focus\:tw-from-indigo-900:focus{
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:focus\:tw-from-purple-100:focus{
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:focus\:tw-from-purple-200:focus{
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:focus\:tw-from-purple-300:focus{
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:focus\:tw-from-purple-400:focus{
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:focus\:tw-from-purple-500:focus{
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:focus\:tw-from-purple-600:focus{
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:focus\:tw-from-purple-700:focus{
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:focus\:tw-from-purple-800:focus{
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:focus\:tw-from-purple-900:focus{
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:focus\:tw-from-pink-100:focus{
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:focus\:tw-from-pink-200:focus{
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:focus\:tw-from-pink-300:focus{
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:focus\:tw-from-pink-400:focus{
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:focus\:tw-from-pink-500:focus{
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:focus\:tw-from-pink-600:focus{
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:focus\:tw-from-pink-700:focus{
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:focus\:tw-from-pink-800:focus{
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:focus\:tw-from-pink-900:focus{
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:focus\:tw-via-transparent:focus{
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:tw-via-tlg-green-dark:focus{
    --gradient-via-color: #76b84e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(118, 184, 78, 0))
  }

  .xl\:focus\:tw-via-tlg-green:focus{
    --gradient-via-color: #a4ca61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(164, 202, 97, 0))
  }

  .xl\:focus\:tw-via-tlg-orange-500:focus{
    --gradient-via-color: #ff7f41;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 127, 65, 0))
  }

  .xl\:focus\:tw-via-black:focus{
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:tw-via-white:focus{
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:tw-via-gray-100:focus{
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0))
  }

  .xl\:focus\:tw-via-gray-200:focus{
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0))
  }

  .xl\:focus\:tw-via-gray-300:focus{
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0))
  }

  .xl\:focus\:tw-via-gray-400:focus{
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0))
  }

  .xl\:focus\:tw-via-gray-500:focus{
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0))
  }

  .xl\:focus\:tw-via-gray-600:focus{
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0))
  }

  .xl\:focus\:tw-via-gray-700:focus{
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0))
  }

  .xl\:focus\:tw-via-gray-800:focus{
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0))
  }

  .xl\:focus\:tw-via-gray-900:focus{
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0))
  }

  .xl\:focus\:tw-via-red-100:focus{
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0))
  }

  .xl\:focus\:tw-via-red-200:focus{
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0))
  }

  .xl\:focus\:tw-via-red-300:focus{
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0))
  }

  .xl\:focus\:tw-via-red-400:focus{
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0))
  }

  .xl\:focus\:tw-via-red-500:focus{
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0))
  }

  .xl\:focus\:tw-via-red-600:focus{
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0))
  }

  .xl\:focus\:tw-via-red-700:focus{
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0))
  }

  .xl\:focus\:tw-via-red-800:focus{
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0))
  }

  .xl\:focus\:tw-via-red-900:focus{
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0))
  }

  .xl\:focus\:tw-via-orange-100:focus{
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0))
  }

  .xl\:focus\:tw-via-orange-200:focus{
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0))
  }

  .xl\:focus\:tw-via-orange-300:focus{
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0))
  }

  .xl\:focus\:tw-via-orange-400:focus{
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0))
  }

  .xl\:focus\:tw-via-orange-500:focus{
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0))
  }

  .xl\:focus\:tw-via-orange-600:focus{
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0))
  }

  .xl\:focus\:tw-via-orange-700:focus{
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0))
  }

  .xl\:focus\:tw-via-orange-800:focus{
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0))
  }

  .xl\:focus\:tw-via-orange-900:focus{
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0))
  }

  .xl\:focus\:tw-via-yellow-100:focus{
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0))
  }

  .xl\:focus\:tw-via-yellow-200:focus{
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0))
  }

  .xl\:focus\:tw-via-yellow-300:focus{
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0))
  }

  .xl\:focus\:tw-via-yellow-400:focus{
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0))
  }

  .xl\:focus\:tw-via-yellow-500:focus{
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0))
  }

  .xl\:focus\:tw-via-yellow-600:focus{
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0))
  }

  .xl\:focus\:tw-via-yellow-700:focus{
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0))
  }

  .xl\:focus\:tw-via-yellow-800:focus{
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0))
  }

  .xl\:focus\:tw-via-yellow-900:focus{
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0))
  }

  .xl\:focus\:tw-via-green-100:focus{
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0))
  }

  .xl\:focus\:tw-via-green-200:focus{
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0))
  }

  .xl\:focus\:tw-via-green-300:focus{
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0))
  }

  .xl\:focus\:tw-via-green-400:focus{
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0))
  }

  .xl\:focus\:tw-via-green-500:focus{
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0))
  }

  .xl\:focus\:tw-via-green-600:focus{
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0))
  }

  .xl\:focus\:tw-via-green-700:focus{
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0))
  }

  .xl\:focus\:tw-via-green-800:focus{
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0))
  }

  .xl\:focus\:tw-via-green-900:focus{
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0))
  }

  .xl\:focus\:tw-via-teal-100:focus{
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0))
  }

  .xl\:focus\:tw-via-teal-200:focus{
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0))
  }

  .xl\:focus\:tw-via-teal-300:focus{
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0))
  }

  .xl\:focus\:tw-via-teal-400:focus{
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0))
  }

  .xl\:focus\:tw-via-teal-500:focus{
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0))
  }

  .xl\:focus\:tw-via-teal-600:focus{
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0))
  }

  .xl\:focus\:tw-via-teal-700:focus{
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0))
  }

  .xl\:focus\:tw-via-teal-800:focus{
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0))
  }

  .xl\:focus\:tw-via-teal-900:focus{
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0))
  }

  .xl\:focus\:tw-via-blue-100:focus{
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0))
  }

  .xl\:focus\:tw-via-blue-200:focus{
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0))
  }

  .xl\:focus\:tw-via-blue-300:focus{
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0))
  }

  .xl\:focus\:tw-via-blue-400:focus{
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0))
  }

  .xl\:focus\:tw-via-blue-500:focus{
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0))
  }

  .xl\:focus\:tw-via-blue-600:focus{
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0))
  }

  .xl\:focus\:tw-via-blue-700:focus{
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0))
  }

  .xl\:focus\:tw-via-blue-800:focus{
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0))
  }

  .xl\:focus\:tw-via-blue-900:focus{
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0))
  }

  .xl\:focus\:tw-via-indigo-100:focus{
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0))
  }

  .xl\:focus\:tw-via-indigo-200:focus{
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0))
  }

  .xl\:focus\:tw-via-indigo-300:focus{
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0))
  }

  .xl\:focus\:tw-via-indigo-400:focus{
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0))
  }

  .xl\:focus\:tw-via-indigo-500:focus{
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0))
  }

  .xl\:focus\:tw-via-indigo-600:focus{
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0))
  }

  .xl\:focus\:tw-via-indigo-700:focus{
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0))
  }

  .xl\:focus\:tw-via-indigo-800:focus{
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0))
  }

  .xl\:focus\:tw-via-indigo-900:focus{
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0))
  }

  .xl\:focus\:tw-via-purple-100:focus{
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0))
  }

  .xl\:focus\:tw-via-purple-200:focus{
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0))
  }

  .xl\:focus\:tw-via-purple-300:focus{
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0))
  }

  .xl\:focus\:tw-via-purple-400:focus{
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0))
  }

  .xl\:focus\:tw-via-purple-500:focus{
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0))
  }

  .xl\:focus\:tw-via-purple-600:focus{
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0))
  }

  .xl\:focus\:tw-via-purple-700:focus{
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0))
  }

  .xl\:focus\:tw-via-purple-800:focus{
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0))
  }

  .xl\:focus\:tw-via-purple-900:focus{
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0))
  }

  .xl\:focus\:tw-via-pink-100:focus{
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0))
  }

  .xl\:focus\:tw-via-pink-200:focus{
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0))
  }

  .xl\:focus\:tw-via-pink-300:focus{
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0))
  }

  .xl\:focus\:tw-via-pink-400:focus{
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0))
  }

  .xl\:focus\:tw-via-pink-500:focus{
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0))
  }

  .xl\:focus\:tw-via-pink-600:focus{
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0))
  }

  .xl\:focus\:tw-via-pink-700:focus{
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0))
  }

  .xl\:focus\:tw-via-pink-800:focus{
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0))
  }

  .xl\:focus\:tw-via-pink-900:focus{
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0))
  }

  .xl\:focus\:tw-to-transparent:focus{
    --gradient-to-color: transparent
  }

  .xl\:focus\:tw-to-tlg-green-dark:focus{
    --gradient-to-color: #76b84e
  }

  .xl\:focus\:tw-to-tlg-green:focus{
    --gradient-to-color: #a4ca61
  }

  .xl\:focus\:tw-to-tlg-orange-500:focus{
    --gradient-to-color: #ff7f41
  }

  .xl\:focus\:tw-to-black:focus{
    --gradient-to-color: #000
  }

  .xl\:focus\:tw-to-white:focus{
    --gradient-to-color: #fff
  }

  .xl\:focus\:tw-to-gray-100:focus{
    --gradient-to-color: #f7fafc
  }

  .xl\:focus\:tw-to-gray-200:focus{
    --gradient-to-color: #edf2f7
  }

  .xl\:focus\:tw-to-gray-300:focus{
    --gradient-to-color: #e2e8f0
  }

  .xl\:focus\:tw-to-gray-400:focus{
    --gradient-to-color: #cbd5e0
  }

  .xl\:focus\:tw-to-gray-500:focus{
    --gradient-to-color: #a0aec0
  }

  .xl\:focus\:tw-to-gray-600:focus{
    --gradient-to-color: #718096
  }

  .xl\:focus\:tw-to-gray-700:focus{
    --gradient-to-color: #4a5568
  }

  .xl\:focus\:tw-to-gray-800:focus{
    --gradient-to-color: #2d3748
  }

  .xl\:focus\:tw-to-gray-900:focus{
    --gradient-to-color: #1a202c
  }

  .xl\:focus\:tw-to-red-100:focus{
    --gradient-to-color: #fff5f5
  }

  .xl\:focus\:tw-to-red-200:focus{
    --gradient-to-color: #fed7d7
  }

  .xl\:focus\:tw-to-red-300:focus{
    --gradient-to-color: #feb2b2
  }

  .xl\:focus\:tw-to-red-400:focus{
    --gradient-to-color: #fc8181
  }

  .xl\:focus\:tw-to-red-500:focus{
    --gradient-to-color: #f56565
  }

  .xl\:focus\:tw-to-red-600:focus{
    --gradient-to-color: #e53e3e
  }

  .xl\:focus\:tw-to-red-700:focus{
    --gradient-to-color: #c53030
  }

  .xl\:focus\:tw-to-red-800:focus{
    --gradient-to-color: #9b2c2c
  }

  .xl\:focus\:tw-to-red-900:focus{
    --gradient-to-color: #742a2a
  }

  .xl\:focus\:tw-to-orange-100:focus{
    --gradient-to-color: #fffaf0
  }

  .xl\:focus\:tw-to-orange-200:focus{
    --gradient-to-color: #feebc8
  }

  .xl\:focus\:tw-to-orange-300:focus{
    --gradient-to-color: #fbd38d
  }

  .xl\:focus\:tw-to-orange-400:focus{
    --gradient-to-color: #f6ad55
  }

  .xl\:focus\:tw-to-orange-500:focus{
    --gradient-to-color: #ed8936
  }

  .xl\:focus\:tw-to-orange-600:focus{
    --gradient-to-color: #dd6b20
  }

  .xl\:focus\:tw-to-orange-700:focus{
    --gradient-to-color: #c05621
  }

  .xl\:focus\:tw-to-orange-800:focus{
    --gradient-to-color: #9c4221
  }

  .xl\:focus\:tw-to-orange-900:focus{
    --gradient-to-color: #7b341e
  }

  .xl\:focus\:tw-to-yellow-100:focus{
    --gradient-to-color: #fffff0
  }

  .xl\:focus\:tw-to-yellow-200:focus{
    --gradient-to-color: #fefcbf
  }

  .xl\:focus\:tw-to-yellow-300:focus{
    --gradient-to-color: #faf089
  }

  .xl\:focus\:tw-to-yellow-400:focus{
    --gradient-to-color: #f6e05e
  }

  .xl\:focus\:tw-to-yellow-500:focus{
    --gradient-to-color: #ecc94b
  }

  .xl\:focus\:tw-to-yellow-600:focus{
    --gradient-to-color: #d69e2e
  }

  .xl\:focus\:tw-to-yellow-700:focus{
    --gradient-to-color: #b7791f
  }

  .xl\:focus\:tw-to-yellow-800:focus{
    --gradient-to-color: #975a16
  }

  .xl\:focus\:tw-to-yellow-900:focus{
    --gradient-to-color: #744210
  }

  .xl\:focus\:tw-to-green-100:focus{
    --gradient-to-color: #f0fff4
  }

  .xl\:focus\:tw-to-green-200:focus{
    --gradient-to-color: #c6f6d5
  }

  .xl\:focus\:tw-to-green-300:focus{
    --gradient-to-color: #9ae6b4
  }

  .xl\:focus\:tw-to-green-400:focus{
    --gradient-to-color: #68d391
  }

  .xl\:focus\:tw-to-green-500:focus{
    --gradient-to-color: #48bb78
  }

  .xl\:focus\:tw-to-green-600:focus{
    --gradient-to-color: #38a169
  }

  .xl\:focus\:tw-to-green-700:focus{
    --gradient-to-color: #2f855a
  }

  .xl\:focus\:tw-to-green-800:focus{
    --gradient-to-color: #276749
  }

  .xl\:focus\:tw-to-green-900:focus{
    --gradient-to-color: #22543d
  }

  .xl\:focus\:tw-to-teal-100:focus{
    --gradient-to-color: #e6fffa
  }

  .xl\:focus\:tw-to-teal-200:focus{
    --gradient-to-color: #b2f5ea
  }

  .xl\:focus\:tw-to-teal-300:focus{
    --gradient-to-color: #81e6d9
  }

  .xl\:focus\:tw-to-teal-400:focus{
    --gradient-to-color: #4fd1c5
  }

  .xl\:focus\:tw-to-teal-500:focus{
    --gradient-to-color: #38b2ac
  }

  .xl\:focus\:tw-to-teal-600:focus{
    --gradient-to-color: #319795
  }

  .xl\:focus\:tw-to-teal-700:focus{
    --gradient-to-color: #2c7a7b
  }

  .xl\:focus\:tw-to-teal-800:focus{
    --gradient-to-color: #285e61
  }

  .xl\:focus\:tw-to-teal-900:focus{
    --gradient-to-color: #234e52
  }

  .xl\:focus\:tw-to-blue-100:focus{
    --gradient-to-color: #ebf8ff
  }

  .xl\:focus\:tw-to-blue-200:focus{
    --gradient-to-color: #bee3f8
  }

  .xl\:focus\:tw-to-blue-300:focus{
    --gradient-to-color: #90cdf4
  }

  .xl\:focus\:tw-to-blue-400:focus{
    --gradient-to-color: #63b3ed
  }

  .xl\:focus\:tw-to-blue-500:focus{
    --gradient-to-color: #4299e1
  }

  .xl\:focus\:tw-to-blue-600:focus{
    --gradient-to-color: #3182ce
  }

  .xl\:focus\:tw-to-blue-700:focus{
    --gradient-to-color: #2b6cb0
  }

  .xl\:focus\:tw-to-blue-800:focus{
    --gradient-to-color: #2c5282
  }

  .xl\:focus\:tw-to-blue-900:focus{
    --gradient-to-color: #2a4365
  }

  .xl\:focus\:tw-to-indigo-100:focus{
    --gradient-to-color: #ebf4ff
  }

  .xl\:focus\:tw-to-indigo-200:focus{
    --gradient-to-color: #c3dafe
  }

  .xl\:focus\:tw-to-indigo-300:focus{
    --gradient-to-color: #a3bffa
  }

  .xl\:focus\:tw-to-indigo-400:focus{
    --gradient-to-color: #7f9cf5
  }

  .xl\:focus\:tw-to-indigo-500:focus{
    --gradient-to-color: #667eea
  }

  .xl\:focus\:tw-to-indigo-600:focus{
    --gradient-to-color: #5a67d8
  }

  .xl\:focus\:tw-to-indigo-700:focus{
    --gradient-to-color: #4c51bf
  }

  .xl\:focus\:tw-to-indigo-800:focus{
    --gradient-to-color: #434190
  }

  .xl\:focus\:tw-to-indigo-900:focus{
    --gradient-to-color: #3c366b
  }

  .xl\:focus\:tw-to-purple-100:focus{
    --gradient-to-color: #faf5ff
  }

  .xl\:focus\:tw-to-purple-200:focus{
    --gradient-to-color: #e9d8fd
  }

  .xl\:focus\:tw-to-purple-300:focus{
    --gradient-to-color: #d6bcfa
  }

  .xl\:focus\:tw-to-purple-400:focus{
    --gradient-to-color: #b794f4
  }

  .xl\:focus\:tw-to-purple-500:focus{
    --gradient-to-color: #9f7aea
  }

  .xl\:focus\:tw-to-purple-600:focus{
    --gradient-to-color: #805ad5
  }

  .xl\:focus\:tw-to-purple-700:focus{
    --gradient-to-color: #6b46c1
  }

  .xl\:focus\:tw-to-purple-800:focus{
    --gradient-to-color: #553c9a
  }

  .xl\:focus\:tw-to-purple-900:focus{
    --gradient-to-color: #44337a
  }

  .xl\:focus\:tw-to-pink-100:focus{
    --gradient-to-color: #fff5f7
  }

  .xl\:focus\:tw-to-pink-200:focus{
    --gradient-to-color: #fed7e2
  }

  .xl\:focus\:tw-to-pink-300:focus{
    --gradient-to-color: #fbb6ce
  }

  .xl\:focus\:tw-to-pink-400:focus{
    --gradient-to-color: #f687b3
  }

  .xl\:focus\:tw-to-pink-500:focus{
    --gradient-to-color: #ed64a6
  }

  .xl\:focus\:tw-to-pink-600:focus{
    --gradient-to-color: #d53f8c
  }

  .xl\:focus\:tw-to-pink-700:focus{
    --gradient-to-color: #b83280
  }

  .xl\:focus\:tw-to-pink-800:focus{
    --gradient-to-color: #97266d
  }

  .xl\:focus\:tw-to-pink-900:focus{
    --gradient-to-color: #702459
  }

  .xl\:tw-bg-opacity-0{
    --bg-opacity: 0
  }

  .xl\:tw-bg-opacity-25{
    --bg-opacity: 0.25
  }

  .xl\:tw-bg-opacity-50{
    --bg-opacity: 0.5
  }

  .xl\:tw-bg-opacity-75{
    --bg-opacity: 0.75
  }

  .xl\:tw-bg-opacity-100{
    --bg-opacity: 1
  }

  .xl\:hover\:tw-bg-opacity-0:hover{
    --bg-opacity: 0
  }

  .xl\:hover\:tw-bg-opacity-25:hover{
    --bg-opacity: 0.25
  }

  .xl\:hover\:tw-bg-opacity-50:hover{
    --bg-opacity: 0.5
  }

  .xl\:hover\:tw-bg-opacity-75:hover{
    --bg-opacity: 0.75
  }

  .xl\:hover\:tw-bg-opacity-100:hover{
    --bg-opacity: 1
  }

  .xl\:focus\:tw-bg-opacity-0:focus{
    --bg-opacity: 0
  }

  .xl\:focus\:tw-bg-opacity-25:focus{
    --bg-opacity: 0.25
  }

  .xl\:focus\:tw-bg-opacity-50:focus{
    --bg-opacity: 0.5
  }

  .xl\:focus\:tw-bg-opacity-75:focus{
    --bg-opacity: 0.75
  }

  .xl\:focus\:tw-bg-opacity-100:focus{
    --bg-opacity: 1
  }

  .xl\:tw-bg-bottom{
    background-position: bottom
  }

  .xl\:tw-bg-center{
    background-position: center
  }

  .xl\:tw-bg-left{
    background-position: left
  }

  .xl\:tw-bg-left-bottom{
    background-position: left bottom
  }

  .xl\:tw-bg-left-top{
    background-position: left top
  }

  .xl\:tw-bg-right{
    background-position: right
  }

  .xl\:tw-bg-right-bottom{
    background-position: right bottom
  }

  .xl\:tw-bg-right-top{
    background-position: right top
  }

  .xl\:tw-bg-top{
    background-position: top
  }

  .xl\:tw-bg-repeat{
    background-repeat: repeat
  }

  .xl\:tw-bg-no-repeat{
    background-repeat: no-repeat
  }

  .xl\:tw-bg-repeat-x{
    background-repeat: repeat-x
  }

  .xl\:tw-bg-repeat-y{
    background-repeat: repeat-y
  }

  .xl\:tw-bg-repeat-round{
    background-repeat: round
  }

  .xl\:tw-bg-repeat-space{
    background-repeat: space
  }

  .xl\:tw-bg-auto{
    background-size: auto
  }

  .xl\:tw-bg-cover{
    background-size: cover
  }

  .xl\:tw-bg-contain{
    background-size: contain
  }

  .xl\:tw-border-collapse{
    border-collapse: collapse
  }

  .xl\:tw-border-separate{
    border-collapse: separate
  }

  .xl\:tw-border-transparent{
    border-color: transparent
  }

  .xl\:tw-border-tlg-green-dark{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .xl\:tw-border-tlg-green{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .xl\:tw-border-tlg-orange-500{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .xl\:tw-border-black{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .xl\:tw-border-white{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .xl\:tw-border-gray-100{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .xl\:tw-border-gray-200{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .xl\:tw-border-gray-300{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .xl\:tw-border-gray-400{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .xl\:tw-border-gray-500{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .xl\:tw-border-gray-600{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .xl\:tw-border-gray-700{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .xl\:tw-border-gray-800{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .xl\:tw-border-gray-900{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .xl\:tw-border-red-100{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .xl\:tw-border-red-200{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .xl\:tw-border-red-300{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .xl\:tw-border-red-400{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .xl\:tw-border-red-500{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .xl\:tw-border-red-600{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .xl\:tw-border-red-700{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .xl\:tw-border-red-800{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .xl\:tw-border-red-900{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .xl\:tw-border-orange-100{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .xl\:tw-border-orange-200{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .xl\:tw-border-orange-300{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .xl\:tw-border-orange-400{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .xl\:tw-border-orange-500{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .xl\:tw-border-orange-600{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .xl\:tw-border-orange-700{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .xl\:tw-border-orange-800{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .xl\:tw-border-orange-900{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .xl\:tw-border-yellow-100{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .xl\:tw-border-yellow-200{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .xl\:tw-border-yellow-300{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .xl\:tw-border-yellow-400{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .xl\:tw-border-yellow-500{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .xl\:tw-border-yellow-600{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .xl\:tw-border-yellow-700{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .xl\:tw-border-yellow-800{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .xl\:tw-border-yellow-900{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .xl\:tw-border-green-100{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .xl\:tw-border-green-200{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .xl\:tw-border-green-300{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .xl\:tw-border-green-400{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .xl\:tw-border-green-500{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .xl\:tw-border-green-600{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .xl\:tw-border-green-700{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .xl\:tw-border-green-800{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .xl\:tw-border-green-900{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .xl\:tw-border-teal-100{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .xl\:tw-border-teal-200{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .xl\:tw-border-teal-300{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .xl\:tw-border-teal-400{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .xl\:tw-border-teal-500{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .xl\:tw-border-teal-600{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .xl\:tw-border-teal-700{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .xl\:tw-border-teal-800{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .xl\:tw-border-teal-900{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .xl\:tw-border-blue-100{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .xl\:tw-border-blue-200{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .xl\:tw-border-blue-300{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .xl\:tw-border-blue-400{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .xl\:tw-border-blue-500{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .xl\:tw-border-blue-600{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .xl\:tw-border-blue-700{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .xl\:tw-border-blue-800{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .xl\:tw-border-blue-900{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .xl\:tw-border-indigo-100{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .xl\:tw-border-indigo-200{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .xl\:tw-border-indigo-300{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .xl\:tw-border-indigo-400{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .xl\:tw-border-indigo-500{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .xl\:tw-border-indigo-600{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .xl\:tw-border-indigo-700{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .xl\:tw-border-indigo-800{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .xl\:tw-border-indigo-900{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .xl\:tw-border-purple-100{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .xl\:tw-border-purple-200{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .xl\:tw-border-purple-300{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .xl\:tw-border-purple-400{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .xl\:tw-border-purple-500{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .xl\:tw-border-purple-600{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .xl\:tw-border-purple-700{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .xl\:tw-border-purple-800{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .xl\:tw-border-purple-900{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .xl\:tw-border-pink-100{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .xl\:tw-border-pink-200{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .xl\:tw-border-pink-300{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .xl\:tw-border-pink-400{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .xl\:tw-border-pink-500{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .xl\:tw-border-pink-600{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .xl\:tw-border-pink-700{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .xl\:tw-border-pink-800{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .xl\:tw-border-pink-900{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .xl\:hover\:tw-border-transparent:hover{
    border-color: transparent
  }

  .xl\:hover\:tw-border-tlg-green-dark:hover{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .xl\:hover\:tw-border-tlg-green:hover{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .xl\:hover\:tw-border-tlg-orange-500:hover{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .xl\:hover\:tw-border-black:hover{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .xl\:hover\:tw-border-white:hover{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-100:hover{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-200:hover{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-300:hover{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-400:hover{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-500:hover{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-600:hover{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-700:hover{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-800:hover{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .xl\:hover\:tw-border-gray-900:hover{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-100:hover{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-200:hover{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-300:hover{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-400:hover{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-500:hover{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-600:hover{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-700:hover{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-800:hover{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .xl\:hover\:tw-border-red-900:hover{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-100:hover{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-200:hover{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-300:hover{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-400:hover{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-500:hover{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-600:hover{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-700:hover{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-800:hover{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .xl\:hover\:tw-border-orange-900:hover{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-100:hover{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-200:hover{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-300:hover{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-400:hover{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-500:hover{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-600:hover{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-700:hover{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-800:hover{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .xl\:hover\:tw-border-yellow-900:hover{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-100:hover{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-200:hover{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-300:hover{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-400:hover{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-500:hover{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-600:hover{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-700:hover{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-800:hover{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .xl\:hover\:tw-border-green-900:hover{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-100:hover{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-200:hover{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-300:hover{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-400:hover{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-500:hover{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-600:hover{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-700:hover{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-800:hover{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .xl\:hover\:tw-border-teal-900:hover{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-100:hover{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-200:hover{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-300:hover{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-400:hover{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-500:hover{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-600:hover{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-700:hover{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-800:hover{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .xl\:hover\:tw-border-blue-900:hover{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-100:hover{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-200:hover{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-300:hover{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-400:hover{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-500:hover{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-600:hover{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-700:hover{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-800:hover{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .xl\:hover\:tw-border-indigo-900:hover{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-100:hover{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-200:hover{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-300:hover{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-400:hover{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-500:hover{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-600:hover{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-700:hover{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-800:hover{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .xl\:hover\:tw-border-purple-900:hover{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-100:hover{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-200:hover{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-300:hover{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-400:hover{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-500:hover{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-600:hover{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-700:hover{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-800:hover{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .xl\:hover\:tw-border-pink-900:hover{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .xl\:focus\:tw-border-transparent:focus{
    border-color: transparent
  }

  .xl\:focus\:tw-border-tlg-green-dark:focus{
    --border-opacity: 1;
    border-color: #76b84e;
    border-color: rgba(118, 184, 78, var(--border-opacity))
  }

  .xl\:focus\:tw-border-tlg-green:focus{
    --border-opacity: 1;
    border-color: #a4ca61;
    border-color: rgba(164, 202, 97, var(--border-opacity))
  }

  .xl\:focus\:tw-border-tlg-orange-500:focus{
    --border-opacity: 1;
    border-color: #ff7f41;
    border-color: rgba(255, 127, 65, var(--border-opacity))
  }

  .xl\:focus\:tw-border-black:focus{
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity))
  }

  .xl\:focus\:tw-border-white:focus{
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-100:focus{
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-200:focus{
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-300:focus{
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-400:focus{
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-500:focus{
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-600:focus{
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-700:focus{
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-800:focus{
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity))
  }

  .xl\:focus\:tw-border-gray-900:focus{
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-100:focus{
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-200:focus{
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-300:focus{
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-400:focus{
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-500:focus{
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-600:focus{
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-700:focus{
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-800:focus{
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity))
  }

  .xl\:focus\:tw-border-red-900:focus{
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-100:focus{
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-200:focus{
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-300:focus{
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-400:focus{
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-500:focus{
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-600:focus{
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-700:focus{
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-800:focus{
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity))
  }

  .xl\:focus\:tw-border-orange-900:focus{
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-100:focus{
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-200:focus{
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-300:focus{
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-400:focus{
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-500:focus{
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-600:focus{
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-700:focus{
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-800:focus{
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity))
  }

  .xl\:focus\:tw-border-yellow-900:focus{
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-100:focus{
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-200:focus{
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-300:focus{
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-400:focus{
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-500:focus{
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-600:focus{
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-700:focus{
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-800:focus{
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity))
  }

  .xl\:focus\:tw-border-green-900:focus{
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-100:focus{
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-200:focus{
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-300:focus{
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-400:focus{
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-500:focus{
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-600:focus{
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-700:focus{
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-800:focus{
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity))
  }

  .xl\:focus\:tw-border-teal-900:focus{
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-100:focus{
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-200:focus{
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-300:focus{
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-400:focus{
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-500:focus{
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-600:focus{
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-700:focus{
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-800:focus{
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity))
  }

  .xl\:focus\:tw-border-blue-900:focus{
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-100:focus{
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-200:focus{
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-300:focus{
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-400:focus{
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-500:focus{
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-600:focus{
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-700:focus{
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-800:focus{
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity))
  }

  .xl\:focus\:tw-border-indigo-900:focus{
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-100:focus{
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-200:focus{
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-300:focus{
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-400:focus{
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-500:focus{
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-600:focus{
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-700:focus{
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-800:focus{
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity))
  }

  .xl\:focus\:tw-border-purple-900:focus{
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-100:focus{
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-200:focus{
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-300:focus{
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-400:focus{
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-500:focus{
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-600:focus{
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-700:focus{
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-800:focus{
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity))
  }

  .xl\:focus\:tw-border-pink-900:focus{
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity))
  }

  .xl\:tw-border-opacity-0{
    --border-opacity: 0
  }

  .xl\:tw-border-opacity-25{
    --border-opacity: 0.25
  }

  .xl\:tw-border-opacity-50{
    --border-opacity: 0.5
  }

  .xl\:tw-border-opacity-75{
    --border-opacity: 0.75
  }

  .xl\:tw-border-opacity-100{
    --border-opacity: 1
  }

  .xl\:hover\:tw-border-opacity-0:hover{
    --border-opacity: 0
  }

  .xl\:hover\:tw-border-opacity-25:hover{
    --border-opacity: 0.25
  }

  .xl\:hover\:tw-border-opacity-50:hover{
    --border-opacity: 0.5
  }

  .xl\:hover\:tw-border-opacity-75:hover{
    --border-opacity: 0.75
  }

  .xl\:hover\:tw-border-opacity-100:hover{
    --border-opacity: 1
  }

  .xl\:focus\:tw-border-opacity-0:focus{
    --border-opacity: 0
  }

  .xl\:focus\:tw-border-opacity-25:focus{
    --border-opacity: 0.25
  }

  .xl\:focus\:tw-border-opacity-50:focus{
    --border-opacity: 0.5
  }

  .xl\:focus\:tw-border-opacity-75:focus{
    --border-opacity: 0.75
  }

  .xl\:focus\:tw-border-opacity-100:focus{
    --border-opacity: 1
  }

  .xl\:tw-rounded-none{
    border-radius: 0
  }

  .xl\:tw-rounded-sm{
    border-radius: 0.125rem
  }

  .xl\:tw-rounded{
    border-radius: 0.25rem
  }

  .xl\:tw-rounded-lg{
    border-radius: 0.5rem
  }

  .xl\:tw-rounded-full{
    border-radius: 9999px
  }

  .xl\:tw-rounded-t-none{
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .xl\:tw-rounded-r-none{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .xl\:tw-rounded-b-none{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:tw-rounded-l-none{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:tw-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .xl\:tw-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .xl\:tw-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .xl\:tw-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .xl\:tw-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .xl\:tw-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .xl\:tw-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .xl\:tw-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .xl\:tw-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .xl\:tw-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .xl\:tw-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .xl\:tw-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .xl\:tw-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .xl\:tw-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .xl\:tw-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:tw-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:tw-rounded-tl-none{
    border-top-left-radius: 0
  }

  .xl\:tw-rounded-tr-none{
    border-top-right-radius: 0
  }

  .xl\:tw-rounded-br-none{
    border-bottom-right-radius: 0
  }

  .xl\:tw-rounded-bl-none{
    border-bottom-left-radius: 0
  }

  .xl\:tw-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  .xl\:tw-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  .xl\:tw-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  .xl\:tw-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  .xl\:tw-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  .xl\:tw-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  .xl\:tw-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  .xl\:tw-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  .xl\:tw-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  .xl\:tw-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  .xl\:tw-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  .xl\:tw-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  .xl\:tw-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  .xl\:tw-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  .xl\:tw-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  .xl\:tw-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  .xl\:tw-border-solid{
    border-style: solid
  }

  .xl\:tw-border-dashed{
    border-style: dashed
  }

  .xl\:tw-border-dotted{
    border-style: dotted
  }

  .xl\:tw-border-double{
    border-style: double
  }

  .xl\:tw-border-none{
    border-style: none
  }

  .xl\:tw-border-0{
    border-width: 0
  }

  .xl\:tw-border-2{
    border-width: 2px
  }

  .xl\:tw-border-4{
    border-width: 4px
  }

  .xl\:tw-border-8{
    border-width: 8px
  }

  .xl\:tw-border{
    border-width: 1px
  }

  .xl\:tw-border-t-0{
    border-top-width: 0
  }

  .xl\:tw-border-r-0{
    border-right-width: 0
  }

  .xl\:tw-border-b-0{
    border-bottom-width: 0
  }

  .xl\:tw-border-l-0{
    border-left-width: 0
  }

  .xl\:tw-border-t-2{
    border-top-width: 2px
  }

  .xl\:tw-border-r-2{
    border-right-width: 2px
  }

  .xl\:tw-border-b-2{
    border-bottom-width: 2px
  }

  .xl\:tw-border-l-2{
    border-left-width: 2px
  }

  .xl\:tw-border-t-4{
    border-top-width: 4px
  }

  .xl\:tw-border-r-4{
    border-right-width: 4px
  }

  .xl\:tw-border-b-4{
    border-bottom-width: 4px
  }

  .xl\:tw-border-l-4{
    border-left-width: 4px
  }

  .xl\:tw-border-t-8{
    border-top-width: 8px
  }

  .xl\:tw-border-r-8{
    border-right-width: 8px
  }

  .xl\:tw-border-b-8{
    border-bottom-width: 8px
  }

  .xl\:tw-border-l-8{
    border-left-width: 8px
  }

  .xl\:tw-border-t{
    border-top-width: 1px
  }

  .xl\:tw-border-r{
    border-right-width: 1px
  }

  .xl\:tw-border-b{
    border-bottom-width: 1px
  }

  .xl\:tw-border-l{
    border-left-width: 1px
  }

  .xl\:tw-box-border{
    box-sizing: border-box
  }

  .xl\:tw-box-content{
    box-sizing: content-box
  }

  .xl\:tw-cursor-auto{
    cursor: auto
  }

  .xl\:tw-cursor-default{
    cursor: default
  }

  .xl\:tw-cursor-pointer{
    cursor: pointer
  }

  .xl\:tw-cursor-wait{
    cursor: wait
  }

  .xl\:tw-cursor-text{
    cursor: text
  }

  .xl\:tw-cursor-move{
    cursor: move
  }

  .xl\:tw-cursor-not-allowed{
    cursor: not-allowed
  }

  .xl\:tw-block{
    display: block
  }

  .xl\:tw-inline-block{
    display: inline-block
  }

  .xl\:tw-inline{
    display: inline
  }

  .xl\:tw-flex{
    display: flex
  }

  .xl\:tw-inline-flex{
    display: inline-flex
  }

  .xl\:tw-table{
    display: table
  }

  .xl\:tw-table-caption{
    display: table-caption
  }

  .xl\:tw-table-cell{
    display: table-cell
  }

  .xl\:tw-table-column{
    display: table-column
  }

  .xl\:tw-table-column-group{
    display: table-column-group
  }

  .xl\:tw-table-footer-group{
    display: table-footer-group
  }

  .xl\:tw-table-header-group{
    display: table-header-group
  }

  .xl\:tw-table-row-group{
    display: table-row-group
  }

  .xl\:tw-table-row{
    display: table-row
  }

  .xl\:tw-flow-root{
    display: flow-root
  }

  .xl\:tw-grid{
    display: grid
  }

  .xl\:tw-inline-grid{
    display: inline-grid
  }

  .xl\:tw-contents{
    display: contents
  }

  .xl\:tw-hidden{
    display: none
  }

  .xl\:tw-flex-row{
    flex-direction: row
  }

  .xl\:tw-flex-row-reverse{
    flex-direction: row-reverse
  }

  .xl\:tw-flex-col{
    flex-direction: column
  }

  .xl\:tw-flex-col-reverse{
    flex-direction: column-reverse
  }

  .xl\:tw-flex-wrap{
    flex-wrap: wrap
  }

  .xl\:tw-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  .xl\:tw-flex-no-wrap{
    flex-wrap: nowrap
  }

  .xl\:tw-place-items-auto{
    place-items: auto
  }

  .xl\:tw-place-items-start{
    place-items: start
  }

  .xl\:tw-place-items-end{
    place-items: end
  }

  .xl\:tw-place-items-center{
    place-items: center
  }

  .xl\:tw-place-items-stretch{
    place-items: stretch
  }

  .xl\:tw-place-content-center{
    place-content: center
  }

  .xl\:tw-place-content-start{
    place-content: start
  }

  .xl\:tw-place-content-end{
    place-content: end
  }

  .xl\:tw-place-content-between{
    place-content: space-between
  }

  .xl\:tw-place-content-around{
    place-content: space-around
  }

  .xl\:tw-place-content-evenly{
    place-content: space-evenly
  }

  .xl\:tw-place-content-stretch{
    place-content: stretch
  }

  .xl\:tw-place-self-auto{
    place-self: auto
  }

  .xl\:tw-place-self-start{
    place-self: start
  }

  .xl\:tw-place-self-end{
    place-self: end
  }

  .xl\:tw-place-self-center{
    place-self: center
  }

  .xl\:tw-place-self-stretch{
    place-self: stretch
  }

  .xl\:tw-items-start{
    align-items: flex-start
  }

  .xl\:tw-items-end{
    align-items: flex-end
  }

  .xl\:tw-items-center{
    align-items: center
  }

  .xl\:tw-items-baseline{
    align-items: baseline
  }

  .xl\:tw-items-stretch{
    align-items: stretch
  }

  .xl\:tw-content-center{
    align-content: center
  }

  .xl\:tw-content-start{
    align-content: flex-start
  }

  .xl\:tw-content-end{
    align-content: flex-end
  }

  .xl\:tw-content-between{
    align-content: space-between
  }

  .xl\:tw-content-around{
    align-content: space-around
  }

  .xl\:tw-content-evenly{
    align-content: space-evenly
  }

  .xl\:tw-self-auto{
    align-self: auto
  }

  .xl\:tw-self-start{
    align-self: flex-start
  }

  .xl\:tw-self-end{
    align-self: flex-end
  }

  .xl\:tw-self-center{
    align-self: center
  }

  .xl\:tw-self-stretch{
    align-self: stretch
  }

  .xl\:tw-justify-items-auto{
    justify-items: auto
  }

  .xl\:tw-justify-items-start{
    justify-items: start
  }

  .xl\:tw-justify-items-end{
    justify-items: end
  }

  .xl\:tw-justify-items-center{
    justify-items: center
  }

  .xl\:tw-justify-items-stretch{
    justify-items: stretch
  }

  .xl\:tw-justify-start{
    justify-content: flex-start
  }

  .xl\:tw-justify-end{
    justify-content: flex-end
  }

  .xl\:tw-justify-center{
    justify-content: center
  }

  .xl\:tw-justify-between{
    justify-content: space-between
  }

  .xl\:tw-justify-around{
    justify-content: space-around
  }

  .xl\:tw-justify-evenly{
    justify-content: space-evenly
  }

  .xl\:tw-justify-self-auto{
    justify-self: auto
  }

  .xl\:tw-justify-self-start{
    justify-self: start
  }

  .xl\:tw-justify-self-end{
    justify-self: end
  }

  .xl\:tw-justify-self-center{
    justify-self: center
  }

  .xl\:tw-justify-self-stretch{
    justify-self: stretch
  }

  .xl\:tw-flex-1{
    flex: 1 1
  }

  .xl\:tw-flex-auto{
    flex: 1 1 auto
  }

  .xl\:tw-flex-initial{
    flex: 0 1 auto
  }

  .xl\:tw-flex-none{
    flex: none
  }

  .xl\:tw-flex-grow-0{
    flex-grow: 0
  }

  .xl\:tw-flex-grow{
    flex-grow: 1
  }

  .xl\:tw-flex-shrink-0{
    flex-shrink: 0
  }

  .xl\:tw-flex-shrink{
    flex-shrink: 1
  }

  .xl\:tw-order-1{
    order: 1
  }

  .xl\:tw-order-2{
    order: 2
  }

  .xl\:tw-order-3{
    order: 3
  }

  .xl\:tw-order-4{
    order: 4
  }

  .xl\:tw-order-5{
    order: 5
  }

  .xl\:tw-order-6{
    order: 6
  }

  .xl\:tw-order-7{
    order: 7
  }

  .xl\:tw-order-8{
    order: 8
  }

  .xl\:tw-order-9{
    order: 9
  }

  .xl\:tw-order-10{
    order: 10
  }

  .xl\:tw-order-11{
    order: 11
  }

  .xl\:tw-order-12{
    order: 12
  }

  .xl\:tw-order-first{
    order: -9999
  }

  .xl\:tw-order-last{
    order: 9999
  }

  .xl\:tw-order-none{
    order: 0
  }

  .xl\:tw-float-right{
    float: right
  }

  .xl\:tw-float-left{
    float: left
  }

  .xl\:tw-float-none{
    float: none
  }

  .xl\:tw-clearfix:after{
    content: "";
    display: table;
    clear: both
  }

  .xl\:tw-clear-left{
    clear: left
  }

  .xl\:tw-clear-right{
    clear: right
  }

  .xl\:tw-clear-both{
    clear: both
  }

  .xl\:tw-clear-none{
    clear: none
  }

  .xl\:tw-font-sans{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .xl\:tw-font-serif{
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .xl\:tw-font-mono{
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .xl\:tw-font-hairline{
    font-weight: 100
  }

  .xl\:tw-font-thin{
    font-weight: 200
  }

  .xl\:tw-font-light{
    font-weight: 300
  }

  .xl\:tw-font-normal{
    font-weight: 400
  }

  .xl\:tw-font-medium{
    font-weight: 500
  }

  .xl\:tw-font-semibold{
    font-weight: 600
  }

  .xl\:tw-font-bold{
    font-weight: 700
  }

  .xl\:tw-font-extrabold{
    font-weight: 800
  }

  .xl\:tw-font-black{
    font-weight: 900
  }

  .xl\:hover\:tw-font-hairline:hover{
    font-weight: 100
  }

  .xl\:hover\:tw-font-thin:hover{
    font-weight: 200
  }

  .xl\:hover\:tw-font-light:hover{
    font-weight: 300
  }

  .xl\:hover\:tw-font-normal:hover{
    font-weight: 400
  }

  .xl\:hover\:tw-font-medium:hover{
    font-weight: 500
  }

  .xl\:hover\:tw-font-semibold:hover{
    font-weight: 600
  }

  .xl\:hover\:tw-font-bold:hover{
    font-weight: 700
  }

  .xl\:hover\:tw-font-extrabold:hover{
    font-weight: 800
  }

  .xl\:hover\:tw-font-black:hover{
    font-weight: 900
  }

  .xl\:focus\:tw-font-hairline:focus{
    font-weight: 100
  }

  .xl\:focus\:tw-font-thin:focus{
    font-weight: 200
  }

  .xl\:focus\:tw-font-light:focus{
    font-weight: 300
  }

  .xl\:focus\:tw-font-normal:focus{
    font-weight: 400
  }

  .xl\:focus\:tw-font-medium:focus{
    font-weight: 500
  }

  .xl\:focus\:tw-font-semibold:focus{
    font-weight: 600
  }

  .xl\:focus\:tw-font-bold:focus{
    font-weight: 700
  }

  .xl\:focus\:tw-font-extrabold:focus{
    font-weight: 800
  }

  .xl\:focus\:tw-font-black:focus{
    font-weight: 900
  }

  .xl\:tw-h-0{
    height: 0
  }

  .xl\:tw-h-1{
    height: 0.25rem
  }

  .xl\:tw-h-2{
    height: 0.5rem
  }

  .xl\:tw-h-3{
    height: 0.75rem
  }

  .xl\:tw-h-4{
    height: 1rem
  }

  .xl\:tw-h-5{
    height: 1.25rem
  }

  .xl\:tw-h-6{
    height: 1.5rem
  }

  .xl\:tw-h-8{
    height: 2rem
  }

  .xl\:tw-h-10{
    height: 2.5rem
  }

  .xl\:tw-h-12{
    height: 3rem
  }

  .xl\:tw-h-16{
    height: 4rem
  }

  .xl\:tw-h-20{
    height: 5rem
  }

  .xl\:tw-h-24{
    height: 6rem
  }

  .xl\:tw-h-32{
    height: 8rem
  }

  .xl\:tw-h-40{
    height: 10rem
  }

  .xl\:tw-h-48{
    height: 12rem
  }

  .xl\:tw-h-56{
    height: 14rem
  }

  .xl\:tw-h-64{
    height: 16rem
  }

  .xl\:tw-h-auto{
    height: auto
  }

  .xl\:tw-h-px{
    height: 1px
  }

  .xl\:tw-h-full{
    height: 100%
  }

  .xl\:tw-h-screen{
    height: 100vh
  }

  .xl\:tw-text-xs{
    font-size: 0.75rem
  }

  .xl\:tw-text-sm{
    font-size: 0.875rem
  }

  .xl\:tw-text-base{
    font-size: 1rem
  }

  .xl\:tw-text-lg{
    font-size: 1.125rem
  }

  .xl\:tw-text-xl{
    font-size: 1.25rem
  }

  .xl\:tw-text-2xl{
    font-size: 1.5rem
  }

  .xl\:tw-text-3xl{
    font-size: 1.875rem
  }

  .xl\:tw-text-4xl{
    font-size: 2.25rem
  }

  .xl\:tw-text-5xl{
    font-size: 3rem
  }

  .xl\:tw-text-6xl{
    font-size: 4rem
  }

  .xl\:tw-leading-none{
    line-height: 1
  }

  .xl\:tw-leading-tight{
    line-height: 1.25
  }

  .xl\:tw-leading-snug{
    line-height: 1.375
  }

  .xl\:tw-leading-normal{
    line-height: 1.5
  }

  .xl\:tw-leading-relaxed{
    line-height: 1.625
  }

  .xl\:tw-leading-loose{
    line-height: 2
  }

  .xl\:tw-list-inside{
    list-style-position: inside
  }

  .xl\:tw-list-outside{
    list-style-position: outside
  }

  .xl\:tw-list-none{
    list-style-type: none
  }

  .xl\:tw-list-disc{
    list-style-type: disc
  }

  .xl\:tw-list-decimal{
    list-style-type: decimal
  }

  .xl\:tw-m-0{
    margin: 0
  }

  .xl\:tw-m-1{
    margin: 0.25rem
  }

  .xl\:tw-m-2{
    margin: 0.5rem
  }

  .xl\:tw-m-3{
    margin: 0.75rem
  }

  .xl\:tw-m-4{
    margin: 1rem
  }

  .xl\:tw-m-5{
    margin: 1.25rem
  }

  .xl\:tw-m-6{
    margin: 1.5rem
  }

  .xl\:tw-m-8{
    margin: 2rem
  }

  .xl\:tw-m-10{
    margin: 2.5rem
  }

  .xl\:tw-m-12{
    margin: 3rem
  }

  .xl\:tw-m-16{
    margin: 4rem
  }

  .xl\:tw-m-20{
    margin: 5rem
  }

  .xl\:tw-m-24{
    margin: 6rem
  }

  .xl\:tw-m-32{
    margin: 8rem
  }

  .xl\:tw-m-40{
    margin: 10rem
  }

  .xl\:tw-m-48{
    margin: 12rem
  }

  .xl\:tw-m-56{
    margin: 14rem
  }

  .xl\:tw-m-64{
    margin: 16rem
  }

  .xl\:tw-m-auto{
    margin: auto
  }

  .xl\:tw-m-px{
    margin: 1px
  }

  .xl\:tw--m-1{
    margin: -0.25rem
  }

  .xl\:tw--m-2{
    margin: -0.5rem
  }

  .xl\:tw--m-3{
    margin: -0.75rem
  }

  .xl\:tw--m-4{
    margin: -1rem
  }

  .xl\:tw--m-5{
    margin: -1.25rem
  }

  .xl\:tw--m-6{
    margin: -1.5rem
  }

  .xl\:tw--m-8{
    margin: -2rem
  }

  .xl\:tw--m-10{
    margin: -2.5rem
  }

  .xl\:tw--m-12{
    margin: -3rem
  }

  .xl\:tw--m-16{
    margin: -4rem
  }

  .xl\:tw--m-20{
    margin: -5rem
  }

  .xl\:tw--m-24{
    margin: -6rem
  }

  .xl\:tw--m-32{
    margin: -8rem
  }

  .xl\:tw--m-40{
    margin: -10rem
  }

  .xl\:tw--m-48{
    margin: -12rem
  }

  .xl\:tw--m-56{
    margin: -14rem
  }

  .xl\:tw--m-64{
    margin: -16rem
  }

  .xl\:tw--m-px{
    margin: -1px
  }

  .xl\:tw-my-0{
    margin-top: 0;
    margin-bottom: 0
  }

  .xl\:tw-mx-0{
    margin-left: 0;
    margin-right: 0
  }

  .xl\:tw-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .xl\:tw-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .xl\:tw-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .xl\:tw-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .xl\:tw-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .xl\:tw-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .xl\:tw-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .xl\:tw-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  .xl\:tw-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .xl\:tw-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .xl\:tw-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .xl\:tw-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .xl\:tw-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .xl\:tw-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  .xl\:tw-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .xl\:tw-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .xl\:tw-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .xl\:tw-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  .xl\:tw-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .xl\:tw-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  .xl\:tw-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .xl\:tw-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  .xl\:tw-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .xl\:tw-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  .xl\:tw-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .xl\:tw-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  .xl\:tw-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .xl\:tw-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  .xl\:tw-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .xl\:tw-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  .xl\:tw-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .xl\:tw-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  .xl\:tw-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .xl\:tw-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  .xl\:tw-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  .xl\:tw-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  .xl\:tw-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  .xl\:tw-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  .xl\:tw--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .xl\:tw--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .xl\:tw--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .xl\:tw--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .xl\:tw--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .xl\:tw--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .xl\:tw--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .xl\:tw--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  .xl\:tw--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .xl\:tw--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .xl\:tw--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .xl\:tw--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .xl\:tw--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .xl\:tw--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  .xl\:tw--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .xl\:tw--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .xl\:tw--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .xl\:tw--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  .xl\:tw--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .xl\:tw--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  .xl\:tw--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .xl\:tw--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  .xl\:tw--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .xl\:tw--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  .xl\:tw--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .xl\:tw--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  .xl\:tw--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .xl\:tw--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  .xl\:tw--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .xl\:tw--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  .xl\:tw--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .xl\:tw--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  .xl\:tw--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .xl\:tw--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  .xl\:tw--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  .xl\:tw--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  .xl\:tw-mt-0{
    margin-top: 0
  }

  .xl\:tw-mr-0{
    margin-right: 0
  }

  .xl\:tw-mb-0{
    margin-bottom: 0
  }

  .xl\:tw-ml-0{
    margin-left: 0
  }

  .xl\:tw-mt-1{
    margin-top: 0.25rem
  }

  .xl\:tw-mr-1{
    margin-right: 0.25rem
  }

  .xl\:tw-mb-1{
    margin-bottom: 0.25rem
  }

  .xl\:tw-ml-1{
    margin-left: 0.25rem
  }

  .xl\:tw-mt-2{
    margin-top: 0.5rem
  }

  .xl\:tw-mr-2{
    margin-right: 0.5rem
  }

  .xl\:tw-mb-2{
    margin-bottom: 0.5rem
  }

  .xl\:tw-ml-2{
    margin-left: 0.5rem
  }

  .xl\:tw-mt-3{
    margin-top: 0.75rem
  }

  .xl\:tw-mr-3{
    margin-right: 0.75rem
  }

  .xl\:tw-mb-3{
    margin-bottom: 0.75rem
  }

  .xl\:tw-ml-3{
    margin-left: 0.75rem
  }

  .xl\:tw-mt-4{
    margin-top: 1rem
  }

  .xl\:tw-mr-4{
    margin-right: 1rem
  }

  .xl\:tw-mb-4{
    margin-bottom: 1rem
  }

  .xl\:tw-ml-4{
    margin-left: 1rem
  }

  .xl\:tw-mt-5{
    margin-top: 1.25rem
  }

  .xl\:tw-mr-5{
    margin-right: 1.25rem
  }

  .xl\:tw-mb-5{
    margin-bottom: 1.25rem
  }

  .xl\:tw-ml-5{
    margin-left: 1.25rem
  }

  .xl\:tw-mt-6{
    margin-top: 1.5rem
  }

  .xl\:tw-mr-6{
    margin-right: 1.5rem
  }

  .xl\:tw-mb-6{
    margin-bottom: 1.5rem
  }

  .xl\:tw-ml-6{
    margin-left: 1.5rem
  }

  .xl\:tw-mt-8{
    margin-top: 2rem
  }

  .xl\:tw-mr-8{
    margin-right: 2rem
  }

  .xl\:tw-mb-8{
    margin-bottom: 2rem
  }

  .xl\:tw-ml-8{
    margin-left: 2rem
  }

  .xl\:tw-mt-10{
    margin-top: 2.5rem
  }

  .xl\:tw-mr-10{
    margin-right: 2.5rem
  }

  .xl\:tw-mb-10{
    margin-bottom: 2.5rem
  }

  .xl\:tw-ml-10{
    margin-left: 2.5rem
  }

  .xl\:tw-mt-12{
    margin-top: 3rem
  }

  .xl\:tw-mr-12{
    margin-right: 3rem
  }

  .xl\:tw-mb-12{
    margin-bottom: 3rem
  }

  .xl\:tw-ml-12{
    margin-left: 3rem
  }

  .xl\:tw-mt-16{
    margin-top: 4rem
  }

  .xl\:tw-mr-16{
    margin-right: 4rem
  }

  .xl\:tw-mb-16{
    margin-bottom: 4rem
  }

  .xl\:tw-ml-16{
    margin-left: 4rem
  }

  .xl\:tw-mt-20{
    margin-top: 5rem
  }

  .xl\:tw-mr-20{
    margin-right: 5rem
  }

  .xl\:tw-mb-20{
    margin-bottom: 5rem
  }

  .xl\:tw-ml-20{
    margin-left: 5rem
  }

  .xl\:tw-mt-24{
    margin-top: 6rem
  }

  .xl\:tw-mr-24{
    margin-right: 6rem
  }

  .xl\:tw-mb-24{
    margin-bottom: 6rem
  }

  .xl\:tw-ml-24{
    margin-left: 6rem
  }

  .xl\:tw-mt-32{
    margin-top: 8rem
  }

  .xl\:tw-mr-32{
    margin-right: 8rem
  }

  .xl\:tw-mb-32{
    margin-bottom: 8rem
  }

  .xl\:tw-ml-32{
    margin-left: 8rem
  }

  .xl\:tw-mt-40{
    margin-top: 10rem
  }

  .xl\:tw-mr-40{
    margin-right: 10rem
  }

  .xl\:tw-mb-40{
    margin-bottom: 10rem
  }

  .xl\:tw-ml-40{
    margin-left: 10rem
  }

  .xl\:tw-mt-48{
    margin-top: 12rem
  }

  .xl\:tw-mr-48{
    margin-right: 12rem
  }

  .xl\:tw-mb-48{
    margin-bottom: 12rem
  }

  .xl\:tw-ml-48{
    margin-left: 12rem
  }

  .xl\:tw-mt-56{
    margin-top: 14rem
  }

  .xl\:tw-mr-56{
    margin-right: 14rem
  }

  .xl\:tw-mb-56{
    margin-bottom: 14rem
  }

  .xl\:tw-ml-56{
    margin-left: 14rem
  }

  .xl\:tw-mt-64{
    margin-top: 16rem
  }

  .xl\:tw-mr-64{
    margin-right: 16rem
  }

  .xl\:tw-mb-64{
    margin-bottom: 16rem
  }

  .xl\:tw-ml-64{
    margin-left: 16rem
  }

  .xl\:tw-mt-auto{
    margin-top: auto
  }

  .xl\:tw-mr-auto{
    margin-right: auto
  }

  .xl\:tw-mb-auto{
    margin-bottom: auto
  }

  .xl\:tw-ml-auto{
    margin-left: auto
  }

  .xl\:tw-mt-px{
    margin-top: 1px
  }

  .xl\:tw-mr-px{
    margin-right: 1px
  }

  .xl\:tw-mb-px{
    margin-bottom: 1px
  }

  .xl\:tw-ml-px{
    margin-left: 1px
  }

  .xl\:tw--mt-1{
    margin-top: -0.25rem
  }

  .xl\:tw--mr-1{
    margin-right: -0.25rem
  }

  .xl\:tw--mb-1{
    margin-bottom: -0.25rem
  }

  .xl\:tw--ml-1{
    margin-left: -0.25rem
  }

  .xl\:tw--mt-2{
    margin-top: -0.5rem
  }

  .xl\:tw--mr-2{
    margin-right: -0.5rem
  }

  .xl\:tw--mb-2{
    margin-bottom: -0.5rem
  }

  .xl\:tw--ml-2{
    margin-left: -0.5rem
  }

  .xl\:tw--mt-3{
    margin-top: -0.75rem
  }

  .xl\:tw--mr-3{
    margin-right: -0.75rem
  }

  .xl\:tw--mb-3{
    margin-bottom: -0.75rem
  }

  .xl\:tw--ml-3{
    margin-left: -0.75rem
  }

  .xl\:tw--mt-4{
    margin-top: -1rem
  }

  .xl\:tw--mr-4{
    margin-right: -1rem
  }

  .xl\:tw--mb-4{
    margin-bottom: -1rem
  }

  .xl\:tw--ml-4{
    margin-left: -1rem
  }

  .xl\:tw--mt-5{
    margin-top: -1.25rem
  }

  .xl\:tw--mr-5{
    margin-right: -1.25rem
  }

  .xl\:tw--mb-5{
    margin-bottom: -1.25rem
  }

  .xl\:tw--ml-5{
    margin-left: -1.25rem
  }

  .xl\:tw--mt-6{
    margin-top: -1.5rem
  }

  .xl\:tw--mr-6{
    margin-right: -1.5rem
  }

  .xl\:tw--mb-6{
    margin-bottom: -1.5rem
  }

  .xl\:tw--ml-6{
    margin-left: -1.5rem
  }

  .xl\:tw--mt-8{
    margin-top: -2rem
  }

  .xl\:tw--mr-8{
    margin-right: -2rem
  }

  .xl\:tw--mb-8{
    margin-bottom: -2rem
  }

  .xl\:tw--ml-8{
    margin-left: -2rem
  }

  .xl\:tw--mt-10{
    margin-top: -2.5rem
  }

  .xl\:tw--mr-10{
    margin-right: -2.5rem
  }

  .xl\:tw--mb-10{
    margin-bottom: -2.5rem
  }

  .xl\:tw--ml-10{
    margin-left: -2.5rem
  }

  .xl\:tw--mt-12{
    margin-top: -3rem
  }

  .xl\:tw--mr-12{
    margin-right: -3rem
  }

  .xl\:tw--mb-12{
    margin-bottom: -3rem
  }

  .xl\:tw--ml-12{
    margin-left: -3rem
  }

  .xl\:tw--mt-16{
    margin-top: -4rem
  }

  .xl\:tw--mr-16{
    margin-right: -4rem
  }

  .xl\:tw--mb-16{
    margin-bottom: -4rem
  }

  .xl\:tw--ml-16{
    margin-left: -4rem
  }

  .xl\:tw--mt-20{
    margin-top: -5rem
  }

  .xl\:tw--mr-20{
    margin-right: -5rem
  }

  .xl\:tw--mb-20{
    margin-bottom: -5rem
  }

  .xl\:tw--ml-20{
    margin-left: -5rem
  }

  .xl\:tw--mt-24{
    margin-top: -6rem
  }

  .xl\:tw--mr-24{
    margin-right: -6rem
  }

  .xl\:tw--mb-24{
    margin-bottom: -6rem
  }

  .xl\:tw--ml-24{
    margin-left: -6rem
  }

  .xl\:tw--mt-32{
    margin-top: -8rem
  }

  .xl\:tw--mr-32{
    margin-right: -8rem
  }

  .xl\:tw--mb-32{
    margin-bottom: -8rem
  }

  .xl\:tw--ml-32{
    margin-left: -8rem
  }

  .xl\:tw--mt-40{
    margin-top: -10rem
  }

  .xl\:tw--mr-40{
    margin-right: -10rem
  }

  .xl\:tw--mb-40{
    margin-bottom: -10rem
  }

  .xl\:tw--ml-40{
    margin-left: -10rem
  }

  .xl\:tw--mt-48{
    margin-top: -12rem
  }

  .xl\:tw--mr-48{
    margin-right: -12rem
  }

  .xl\:tw--mb-48{
    margin-bottom: -12rem
  }

  .xl\:tw--ml-48{
    margin-left: -12rem
  }

  .xl\:tw--mt-56{
    margin-top: -14rem
  }

  .xl\:tw--mr-56{
    margin-right: -14rem
  }

  .xl\:tw--mb-56{
    margin-bottom: -14rem
  }

  .xl\:tw--ml-56{
    margin-left: -14rem
  }

  .xl\:tw--mt-64{
    margin-top: -16rem
  }

  .xl\:tw--mr-64{
    margin-right: -16rem
  }

  .xl\:tw--mb-64{
    margin-bottom: -16rem
  }

  .xl\:tw--ml-64{
    margin-left: -16rem
  }

  .xl\:tw--mt-px{
    margin-top: -1px
  }

  .xl\:tw--mr-px{
    margin-right: -1px
  }

  .xl\:tw--mb-px{
    margin-bottom: -1px
  }

  .xl\:tw--ml-px{
    margin-left: -1px
  }

  .xl\:tw-max-h-full{
    max-height: 100%
  }

  .xl\:tw-max-h-screen{
    max-height: 100vh
  }

  .xl\:tw-max-w-xs{
    max-width: 20rem
  }

  .xl\:tw-max-w-sm{
    max-width: 24rem
  }

  .xl\:tw-max-w-md{
    max-width: 28rem
  }

  .xl\:tw-max-w-lg{
    max-width: 32rem
  }

  .xl\:tw-max-w-xl{
    max-width: 36rem
  }

  .xl\:tw-max-w-2xl{
    max-width: 42rem
  }

  .xl\:tw-max-w-3xl{
    max-width: 48rem
  }

  .xl\:tw-max-w-4xl{
    max-width: 56rem
  }

  .xl\:tw-max-w-5xl{
    max-width: 64rem
  }

  .xl\:tw-max-w-6xl{
    max-width: 72rem
  }

  .xl\:tw-max-w-full{
    max-width: 100%
  }

  .xl\:tw-min-h-0{
    min-height: 0
  }

  .xl\:tw-min-h-full{
    min-height: 100%
  }

  .xl\:tw-min-h-screen{
    min-height: 100vh
  }

  .xl\:tw-min-w-0{
    min-width: 0
  }

  .xl\:tw-min-w-full{
    min-width: 100%
  }

  .xl\:tw-object-contain{
    object-fit: contain
  }

  .xl\:tw-object-cover{
    object-fit: cover
  }

  .xl\:tw-object-fill{
    object-fit: fill
  }

  .xl\:tw-object-none{
    object-fit: none
  }

  .xl\:tw-object-scale-down{
    object-fit: scale-down
  }

  .xl\:tw-object-bottom{
    object-position: bottom
  }

  .xl\:tw-object-center{
    object-position: center
  }

  .xl\:tw-object-left{
    object-position: left
  }

  .xl\:tw-object-left-bottom{
    object-position: left bottom
  }

  .xl\:tw-object-left-top{
    object-position: left top
  }

  .xl\:tw-object-right{
    object-position: right
  }

  .xl\:tw-object-right-bottom{
    object-position: right bottom
  }

  .xl\:tw-object-right-top{
    object-position: right top
  }

  .xl\:tw-object-top{
    object-position: top
  }

  .xl\:tw-opacity-0{
    opacity: 0
  }

  .xl\:tw-opacity-25{
    opacity: 0.25
  }

  .xl\:tw-opacity-50{
    opacity: 0.5
  }

  .xl\:tw-opacity-75{
    opacity: 0.75
  }

  .xl\:tw-opacity-100{
    opacity: 1
  }

  .xl\:tw-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:tw-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:tw-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:focus\:tw-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:focus\:tw-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:focus\:tw-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:tw-overflow-auto{
    overflow: auto
  }

  .xl\:tw-overflow-hidden{
    overflow: hidden
  }

  .xl\:tw-overflow-visible{
    overflow: visible
  }

  .xl\:tw-overflow-scroll{
    overflow: scroll
  }

  .xl\:tw-overflow-x-auto{
    overflow-x: auto
  }

  .xl\:tw-overflow-y-auto{
    overflow-y: auto
  }

  .xl\:tw-overflow-x-hidden{
    overflow-x: hidden
  }

  .xl\:tw-overflow-y-hidden{
    overflow-y: hidden
  }

  .xl\:tw-overflow-x-visible{
    overflow-x: visible
  }

  .xl\:tw-overflow-y-visible{
    overflow-y: visible
  }

  .xl\:tw-overflow-x-scroll{
    overflow-x: scroll
  }

  .xl\:tw-overflow-y-scroll{
    overflow-y: scroll
  }

  .xl\:tw-scrolling-touch{
    -webkit-overflow-scrolling: touch
  }

  .xl\:tw-scrolling-auto{
    -webkit-overflow-scrolling: auto
  }

  .xl\:tw-overscroll-auto{
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto
  }

  .xl\:tw-overscroll-contain{
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
  }

  .xl\:tw-overscroll-none{
    -ms-scroll-chaining: none;
        overscroll-behavior: none
  }

  .xl\:tw-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  .xl\:tw-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  .xl\:tw-overscroll-y-none{
    overscroll-behavior-y: none
  }

  .xl\:tw-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  .xl\:tw-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  .xl\:tw-overscroll-x-none{
    overscroll-behavior-x: none
  }

  .xl\:tw-p-0{
    padding: 0
  }

  .xl\:tw-p-1{
    padding: 0.25rem
  }

  .xl\:tw-p-2{
    padding: 0.5rem
  }

  .xl\:tw-p-3{
    padding: 0.75rem
  }

  .xl\:tw-p-4{
    padding: 1rem
  }

  .xl\:tw-p-5{
    padding: 1.25rem
  }

  .xl\:tw-p-6{
    padding: 1.5rem
  }

  .xl\:tw-p-8{
    padding: 2rem
  }

  .xl\:tw-p-10{
    padding: 2.5rem
  }

  .xl\:tw-p-12{
    padding: 3rem
  }

  .xl\:tw-p-16{
    padding: 4rem
  }

  .xl\:tw-p-20{
    padding: 5rem
  }

  .xl\:tw-p-24{
    padding: 6rem
  }

  .xl\:tw-p-32{
    padding: 8rem
  }

  .xl\:tw-p-40{
    padding: 10rem
  }

  .xl\:tw-p-48{
    padding: 12rem
  }

  .xl\:tw-p-56{
    padding: 14rem
  }

  .xl\:tw-p-64{
    padding: 16rem
  }

  .xl\:tw-p-px{
    padding: 1px
  }

  .xl\:tw-py-0{
    padding-top: 0;
    padding-bottom: 0
  }

  .xl\:tw-px-0{
    padding-left: 0;
    padding-right: 0
  }

  .xl\:tw-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .xl\:tw-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .xl\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .xl\:tw-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .xl\:tw-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .xl\:tw-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .xl\:tw-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .xl\:tw-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  .xl\:tw-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .xl\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .xl\:tw-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .xl\:tw-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .xl\:tw-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:tw-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xl\:tw-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .xl\:tw-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .xl\:tw-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .xl\:tw-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  .xl\:tw-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .xl\:tw-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  .xl\:tw-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .xl\:tw-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  .xl\:tw-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .xl\:tw-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  .xl\:tw-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .xl\:tw-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  .xl\:tw-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .xl\:tw-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  .xl\:tw-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .xl\:tw-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  .xl\:tw-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .xl\:tw-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  .xl\:tw-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .xl\:tw-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  .xl\:tw-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  .xl\:tw-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  .xl\:tw-pt-0{
    padding-top: 0
  }

  .xl\:tw-pr-0{
    padding-right: 0
  }

  .xl\:tw-pb-0{
    padding-bottom: 0
  }

  .xl\:tw-pl-0{
    padding-left: 0
  }

  .xl\:tw-pt-1{
    padding-top: 0.25rem
  }

  .xl\:tw-pr-1{
    padding-right: 0.25rem
  }

  .xl\:tw-pb-1{
    padding-bottom: 0.25rem
  }

  .xl\:tw-pl-1{
    padding-left: 0.25rem
  }

  .xl\:tw-pt-2{
    padding-top: 0.5rem
  }

  .xl\:tw-pr-2{
    padding-right: 0.5rem
  }

  .xl\:tw-pb-2{
    padding-bottom: 0.5rem
  }

  .xl\:tw-pl-2{
    padding-left: 0.5rem
  }

  .xl\:tw-pt-3{
    padding-top: 0.75rem
  }

  .xl\:tw-pr-3{
    padding-right: 0.75rem
  }

  .xl\:tw-pb-3{
    padding-bottom: 0.75rem
  }

  .xl\:tw-pl-3{
    padding-left: 0.75rem
  }

  .xl\:tw-pt-4{
    padding-top: 1rem
  }

  .xl\:tw-pr-4{
    padding-right: 1rem
  }

  .xl\:tw-pb-4{
    padding-bottom: 1rem
  }

  .xl\:tw-pl-4{
    padding-left: 1rem
  }

  .xl\:tw-pt-5{
    padding-top: 1.25rem
  }

  .xl\:tw-pr-5{
    padding-right: 1.25rem
  }

  .xl\:tw-pb-5{
    padding-bottom: 1.25rem
  }

  .xl\:tw-pl-5{
    padding-left: 1.25rem
  }

  .xl\:tw-pt-6{
    padding-top: 1.5rem
  }

  .xl\:tw-pr-6{
    padding-right: 1.5rem
  }

  .xl\:tw-pb-6{
    padding-bottom: 1.5rem
  }

  .xl\:tw-pl-6{
    padding-left: 1.5rem
  }

  .xl\:tw-pt-8{
    padding-top: 2rem
  }

  .xl\:tw-pr-8{
    padding-right: 2rem
  }

  .xl\:tw-pb-8{
    padding-bottom: 2rem
  }

  .xl\:tw-pl-8{
    padding-left: 2rem
  }

  .xl\:tw-pt-10{
    padding-top: 2.5rem
  }

  .xl\:tw-pr-10{
    padding-right: 2.5rem
  }

  .xl\:tw-pb-10{
    padding-bottom: 2.5rem
  }

  .xl\:tw-pl-10{
    padding-left: 2.5rem
  }

  .xl\:tw-pt-12{
    padding-top: 3rem
  }

  .xl\:tw-pr-12{
    padding-right: 3rem
  }

  .xl\:tw-pb-12{
    padding-bottom: 3rem
  }

  .xl\:tw-pl-12{
    padding-left: 3rem
  }

  .xl\:tw-pt-16{
    padding-top: 4rem
  }

  .xl\:tw-pr-16{
    padding-right: 4rem
  }

  .xl\:tw-pb-16{
    padding-bottom: 4rem
  }

  .xl\:tw-pl-16{
    padding-left: 4rem
  }

  .xl\:tw-pt-20{
    padding-top: 5rem
  }

  .xl\:tw-pr-20{
    padding-right: 5rem
  }

  .xl\:tw-pb-20{
    padding-bottom: 5rem
  }

  .xl\:tw-pl-20{
    padding-left: 5rem
  }

  .xl\:tw-pt-24{
    padding-top: 6rem
  }

  .xl\:tw-pr-24{
    padding-right: 6rem
  }

  .xl\:tw-pb-24{
    padding-bottom: 6rem
  }

  .xl\:tw-pl-24{
    padding-left: 6rem
  }

  .xl\:tw-pt-32{
    padding-top: 8rem
  }

  .xl\:tw-pr-32{
    padding-right: 8rem
  }

  .xl\:tw-pb-32{
    padding-bottom: 8rem
  }

  .xl\:tw-pl-32{
    padding-left: 8rem
  }

  .xl\:tw-pt-40{
    padding-top: 10rem
  }

  .xl\:tw-pr-40{
    padding-right: 10rem
  }

  .xl\:tw-pb-40{
    padding-bottom: 10rem
  }

  .xl\:tw-pl-40{
    padding-left: 10rem
  }

  .xl\:tw-pt-48{
    padding-top: 12rem
  }

  .xl\:tw-pr-48{
    padding-right: 12rem
  }

  .xl\:tw-pb-48{
    padding-bottom: 12rem
  }

  .xl\:tw-pl-48{
    padding-left: 12rem
  }

  .xl\:tw-pt-56{
    padding-top: 14rem
  }

  .xl\:tw-pr-56{
    padding-right: 14rem
  }

  .xl\:tw-pb-56{
    padding-bottom: 14rem
  }

  .xl\:tw-pl-56{
    padding-left: 14rem
  }

  .xl\:tw-pt-64{
    padding-top: 16rem
  }

  .xl\:tw-pr-64{
    padding-right: 16rem
  }

  .xl\:tw-pb-64{
    padding-bottom: 16rem
  }

  .xl\:tw-pl-64{
    padding-left: 16rem
  }

  .xl\:tw-pt-px{
    padding-top: 1px
  }

  .xl\:tw-pr-px{
    padding-right: 1px
  }

  .xl\:tw-pb-px{
    padding-bottom: 1px
  }

  .xl\:tw-pl-px{
    padding-left: 1px
  }

  .xl\:tw-placeholder-transparent::-webkit-input-placeholder{
    color: transparent
  }

  .xl\:tw-placeholder-transparent::-moz-placeholder{
    color: transparent
  }

  .xl\:tw-placeholder-transparent:-ms-input-placeholder{
    color: transparent
  }

  .xl\:tw-placeholder-transparent::-ms-input-placeholder{
    color: transparent
  }

  .xl\:tw-placeholder-transparent::placeholder{
    color: transparent
  }

  .xl\:tw-placeholder-tlg-green-dark::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green-dark::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green-dark:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green-dark::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green-dark::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-green::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-tlg-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-black::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-black::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-black:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-black::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-black::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-white::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-white::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-white:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-white::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-white::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-100::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-200::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-300::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-400::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-500::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-600::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-700::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-800::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-gray-900::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-300::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-400::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-500::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-600::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-700::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-800::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-red-900::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-200::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-600::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-700::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-800::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-orange-900::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-100::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-200::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-300::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-400::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-500::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-600::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-700::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-800::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-yellow-900::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-100::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-200::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-300::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-400::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-500::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-600::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-700::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-800::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-green-900::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-100::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-200::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-300::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-400::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-500::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-600::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-700::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-800::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-teal-900::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-200::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-300::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-400::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-500::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-600::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-700::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-800::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-blue-900::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-100::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-200::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-300::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-400::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-500::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-600::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-700::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-800::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-indigo-900::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-100::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-200::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-300::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-400::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-500::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-600::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-700::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-800::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-purple-900::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-100::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-100::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-100:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-100::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-100::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-200::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-200::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-200:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-200::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-200::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-300::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-300::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-300:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-300::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-300::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-400::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-400::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-400:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-400::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-400::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-500::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-500::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-500:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-500::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-500::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-600::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-600::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-600:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-600::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-600::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-700::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-700::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-700:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-700::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-700::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-800::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-800::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-800:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-800::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-800::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-900::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-900::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-900:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-900::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-pink-900::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent
  }

  .xl\:focus\:tw-placeholder-transparent:focus::-moz-placeholder{
    color: transparent
  }

  .xl\:focus\:tw-placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent
  }

  .xl\:focus\:tw-placeholder-transparent:focus::-ms-input-placeholder{
    color: transparent
  }

  .xl\:focus\:tw-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  .xl\:focus\:tw-placeholder-tlg-green-dark:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green-dark:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green-dark:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green-dark:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green-dark:focus::placeholder{
    --placeholder-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-green:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-tlg-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-black:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-black:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-black:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-black:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-black:focus::placeholder{
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-white:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-white:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-white:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-white:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-white:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-red-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-orange-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-green-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-teal-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-purple-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-100:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-200:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-200:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-200:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-200:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-300:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-300:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-300:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-300:focus::placeholder{
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-400:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-400:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-400:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-400:focus::placeholder{
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-500:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-500:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-500:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-500:focus::placeholder{
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-600:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-600:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-600:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-600:focus::placeholder{
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-700:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-700:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-700:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-700:focus::placeholder{
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-800:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-800:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-800:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-800:focus::placeholder{
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-900:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-900:focus:-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-900:focus::-ms-input-placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:focus\:tw-placeholder-pink-900:focus::placeholder{
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity))
  }

  .xl\:tw-placeholder-opacity-0::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:tw-placeholder-opacity-0::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:tw-placeholder-opacity-0:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:tw-placeholder-opacity-0::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:tw-placeholder-opacity-0::placeholder{
    --placeholder-opacity: 0
  }

  .xl\:tw-placeholder-opacity-25::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:tw-placeholder-opacity-25::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:tw-placeholder-opacity-25:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:tw-placeholder-opacity-25::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:tw-placeholder-opacity-25::placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:tw-placeholder-opacity-50::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:tw-placeholder-opacity-50::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:tw-placeholder-opacity-50:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:tw-placeholder-opacity-50::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:tw-placeholder-opacity-50::placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:tw-placeholder-opacity-75::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:tw-placeholder-opacity-75::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:tw-placeholder-opacity-75:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:tw-placeholder-opacity-75::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:tw-placeholder-opacity-75::placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:tw-placeholder-opacity-100::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:tw-placeholder-opacity-100::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:tw-placeholder-opacity-100:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:tw-placeholder-opacity-100::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:tw-placeholder-opacity-100::placeholder{
    --placeholder-opacity: 1
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus:-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::-ms-input-placeholder{
    --placeholder-opacity: 0
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::placeholder{
    --placeholder-opacity: 0
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::placeholder{
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::placeholder{
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::-webkit-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus:-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::-ms-input-placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::placeholder{
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::-webkit-input-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus:-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::-ms-input-placeholder{
    --placeholder-opacity: 1
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::placeholder{
    --placeholder-opacity: 1
  }

  .xl\:tw-pointer-events-none{
    pointer-events: none
  }

  .xl\:tw-pointer-events-auto{
    pointer-events: auto
  }

  .xl\:tw-static{
    position: static
  }

  .xl\:tw-fixed{
    position: fixed
  }

  .xl\:tw-absolute{
    position: absolute
  }

  .xl\:tw-relative{
    position: relative
  }

  .xl\:tw-sticky{
    position: -webkit-sticky;
    position: sticky
  }

  .xl\:tw-inset-0{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .xl\:tw-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .xl\:tw-inset-y-0{
    top: 0;
    bottom: 0
  }

  .xl\:tw-inset-x-0{
    right: 0;
    left: 0
  }

  .xl\:tw-inset-y-auto{
    top: auto;
    bottom: auto
  }

  .xl\:tw-inset-x-auto{
    right: auto;
    left: auto
  }

  .xl\:tw-top-0{
    top: 0
  }

  .xl\:tw-right-0{
    right: 0
  }

  .xl\:tw-bottom-0{
    bottom: 0
  }

  .xl\:tw-left-0{
    left: 0
  }

  .xl\:tw-top-auto{
    top: auto
  }

  .xl\:tw-right-auto{
    right: auto
  }

  .xl\:tw-bottom-auto{
    bottom: auto
  }

  .xl\:tw-left-auto{
    left: auto
  }

  .xl\:tw-resize-none{
    resize: none
  }

  .xl\:tw-resize-y{
    resize: vertical
  }

  .xl\:tw-resize-x{
    resize: horizontal
  }

  .xl\:tw-resize{
    resize: both
  }

  .xl\:tw-shadow{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:tw-shadow-md{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:tw-shadow-lg{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:tw-shadow-xl{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:tw-shadow-2xl{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:tw-shadow-inner{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:tw-shadow-outline{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:tw-shadow-none{
    box-shadow: none
  }

  .xl\:hover\:tw-shadow:hover{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:tw-shadow-md:hover{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:tw-shadow-lg:hover{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:tw-shadow-xl:hover{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:tw-shadow-2xl:hover{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:hover\:tw-shadow-inner:hover{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:tw-shadow-outline:hover{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:hover\:tw-shadow-none:hover{
    box-shadow: none
  }

  .xl\:focus\:tw-shadow:focus{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:tw-shadow-md:focus{
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:tw-shadow-lg:focus{
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:tw-shadow-xl:focus{
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:tw-shadow-2xl:focus{
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:focus\:tw-shadow-inner:focus{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:tw-shadow-outline:focus{
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:focus\:tw-shadow-none:focus{
    box-shadow: none
  }

  .xl\:tw-fill-current{
    fill: currentColor
  }

  .xl\:tw-stroke-current{
    stroke: currentColor
  }

  .xl\:tw-stroke-0{
    stroke-width: 0
  }

  .xl\:tw-stroke-1{
    stroke-width: 1
  }

  .xl\:tw-stroke-2{
    stroke-width: 2
  }

  .xl\:tw-table-auto{
    table-layout: auto
  }

  .xl\:tw-table-fixed{
    table-layout: fixed
  }

  .xl\:tw-text-left{
    text-align: left
  }

  .xl\:tw-text-center{
    text-align: center
  }

  .xl\:tw-text-right{
    text-align: right
  }

  .xl\:tw-text-justify{
    text-align: justify
  }

  .xl\:tw-text-transparent{
    color: transparent
  }

  .xl\:tw-text-tlg-green-dark{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .xl\:tw-text-tlg-green{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .xl\:tw-text-tlg-orange-500{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .xl\:tw-text-black{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:tw-text-white{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:tw-text-gray-100{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .xl\:tw-text-gray-200{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .xl\:tw-text-gray-300{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .xl\:tw-text-gray-400{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .xl\:tw-text-gray-500{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .xl\:tw-text-gray-600{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .xl\:tw-text-gray-700{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .xl\:tw-text-gray-800{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .xl\:tw-text-gray-900{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .xl\:tw-text-red-100{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .xl\:tw-text-red-200{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .xl\:tw-text-red-300{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .xl\:tw-text-red-400{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .xl\:tw-text-red-500{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .xl\:tw-text-red-600{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .xl\:tw-text-red-700{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .xl\:tw-text-red-800{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .xl\:tw-text-red-900{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .xl\:tw-text-orange-100{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .xl\:tw-text-orange-200{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .xl\:tw-text-orange-300{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .xl\:tw-text-orange-400{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .xl\:tw-text-orange-500{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .xl\:tw-text-orange-600{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .xl\:tw-text-orange-700{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .xl\:tw-text-orange-800{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .xl\:tw-text-orange-900{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .xl\:tw-text-yellow-100{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .xl\:tw-text-yellow-200{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .xl\:tw-text-yellow-300{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .xl\:tw-text-yellow-400{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .xl\:tw-text-yellow-500{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .xl\:tw-text-yellow-600{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .xl\:tw-text-yellow-700{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .xl\:tw-text-yellow-800{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .xl\:tw-text-yellow-900{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .xl\:tw-text-green-100{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .xl\:tw-text-green-200{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .xl\:tw-text-green-300{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .xl\:tw-text-green-400{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .xl\:tw-text-green-500{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .xl\:tw-text-green-600{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .xl\:tw-text-green-700{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .xl\:tw-text-green-800{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .xl\:tw-text-green-900{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .xl\:tw-text-teal-100{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .xl\:tw-text-teal-200{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .xl\:tw-text-teal-300{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .xl\:tw-text-teal-400{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .xl\:tw-text-teal-500{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .xl\:tw-text-teal-600{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .xl\:tw-text-teal-700{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .xl\:tw-text-teal-800{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .xl\:tw-text-teal-900{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .xl\:tw-text-blue-100{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .xl\:tw-text-blue-200{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .xl\:tw-text-blue-300{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .xl\:tw-text-blue-400{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .xl\:tw-text-blue-500{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .xl\:tw-text-blue-600{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .xl\:tw-text-blue-700{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .xl\:tw-text-blue-800{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .xl\:tw-text-blue-900{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .xl\:tw-text-indigo-100{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .xl\:tw-text-indigo-200{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .xl\:tw-text-indigo-300{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .xl\:tw-text-indigo-400{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .xl\:tw-text-indigo-500{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .xl\:tw-text-indigo-600{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .xl\:tw-text-indigo-700{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .xl\:tw-text-indigo-800{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .xl\:tw-text-indigo-900{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .xl\:tw-text-purple-100{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .xl\:tw-text-purple-200{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .xl\:tw-text-purple-300{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .xl\:tw-text-purple-400{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .xl\:tw-text-purple-500{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .xl\:tw-text-purple-600{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .xl\:tw-text-purple-700{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .xl\:tw-text-purple-800{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .xl\:tw-text-purple-900{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .xl\:tw-text-pink-100{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .xl\:tw-text-pink-200{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .xl\:tw-text-pink-300{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .xl\:tw-text-pink-400{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .xl\:tw-text-pink-500{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .xl\:tw-text-pink-600{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .xl\:tw-text-pink-700{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .xl\:tw-text-pink-800{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .xl\:tw-text-pink-900{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .xl\:hover\:tw-text-transparent:hover{
    color: transparent
  }

  .xl\:hover\:tw-text-tlg-green-dark:hover{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .xl\:hover\:tw-text-tlg-green:hover{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .xl\:hover\:tw-text-tlg-orange-500:hover{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .xl\:hover\:tw-text-black:hover{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:hover\:tw-text-white:hover{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-100:hover{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-200:hover{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-300:hover{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-400:hover{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-500:hover{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-600:hover{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-700:hover{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-800:hover{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .xl\:hover\:tw-text-gray-900:hover{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-100:hover{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-200:hover{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-300:hover{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-400:hover{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-500:hover{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-600:hover{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-700:hover{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-800:hover{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .xl\:hover\:tw-text-red-900:hover{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-100:hover{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-200:hover{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-300:hover{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-400:hover{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-500:hover{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-600:hover{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-700:hover{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-800:hover{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .xl\:hover\:tw-text-orange-900:hover{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-100:hover{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-200:hover{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-300:hover{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-400:hover{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-500:hover{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-600:hover{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-700:hover{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-800:hover{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .xl\:hover\:tw-text-yellow-900:hover{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-100:hover{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-200:hover{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-300:hover{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-400:hover{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-500:hover{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-600:hover{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-700:hover{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-800:hover{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .xl\:hover\:tw-text-green-900:hover{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-100:hover{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-200:hover{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-300:hover{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-400:hover{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-500:hover{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-600:hover{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-700:hover{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-800:hover{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .xl\:hover\:tw-text-teal-900:hover{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-100:hover{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-200:hover{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-300:hover{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-400:hover{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-500:hover{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-600:hover{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-700:hover{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-800:hover{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .xl\:hover\:tw-text-blue-900:hover{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-100:hover{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-200:hover{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-300:hover{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-400:hover{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-500:hover{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-600:hover{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-700:hover{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-800:hover{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .xl\:hover\:tw-text-indigo-900:hover{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-100:hover{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-200:hover{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-300:hover{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-400:hover{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-500:hover{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-600:hover{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-700:hover{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-800:hover{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .xl\:hover\:tw-text-purple-900:hover{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-100:hover{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-200:hover{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-300:hover{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-400:hover{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-500:hover{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-600:hover{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-700:hover{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-800:hover{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .xl\:hover\:tw-text-pink-900:hover{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .xl\:focus\:tw-text-transparent:focus{
    color: transparent
  }

  .xl\:focus\:tw-text-tlg-green-dark:focus{
    --text-opacity: 1;
    color: #76b84e;
    color: rgba(118, 184, 78, var(--text-opacity))
  }

  .xl\:focus\:tw-text-tlg-green:focus{
    --text-opacity: 1;
    color: #a4ca61;
    color: rgba(164, 202, 97, var(--text-opacity))
  }

  .xl\:focus\:tw-text-tlg-orange-500:focus{
    --text-opacity: 1;
    color: #ff7f41;
    color: rgba(255, 127, 65, var(--text-opacity))
  }

  .xl\:focus\:tw-text-black:focus{
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:focus\:tw-text-white:focus{
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-100:focus{
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-200:focus{
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-300:focus{
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-400:focus{
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-500:focus{
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-600:focus{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-700:focus{
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-800:focus{
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity))
  }

  .xl\:focus\:tw-text-gray-900:focus{
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-100:focus{
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-200:focus{
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-300:focus{
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-400:focus{
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-500:focus{
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-600:focus{
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-700:focus{
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-800:focus{
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity))
  }

  .xl\:focus\:tw-text-red-900:focus{
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-100:focus{
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-200:focus{
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-300:focus{
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-400:focus{
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-500:focus{
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-600:focus{
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-700:focus{
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-800:focus{
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity))
  }

  .xl\:focus\:tw-text-orange-900:focus{
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-100:focus{
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-200:focus{
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-300:focus{
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-400:focus{
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-500:focus{
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-600:focus{
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-700:focus{
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-800:focus{
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity))
  }

  .xl\:focus\:tw-text-yellow-900:focus{
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-100:focus{
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-200:focus{
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-300:focus{
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-400:focus{
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-500:focus{
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-600:focus{
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-700:focus{
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-800:focus{
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity))
  }

  .xl\:focus\:tw-text-green-900:focus{
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-100:focus{
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-200:focus{
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-300:focus{
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-400:focus{
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-500:focus{
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-600:focus{
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-700:focus{
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-800:focus{
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity))
  }

  .xl\:focus\:tw-text-teal-900:focus{
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-100:focus{
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-200:focus{
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-300:focus{
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-400:focus{
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-500:focus{
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-600:focus{
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-700:focus{
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-800:focus{
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity))
  }

  .xl\:focus\:tw-text-blue-900:focus{
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-100:focus{
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-200:focus{
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-300:focus{
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-400:focus{
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-500:focus{
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-600:focus{
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-700:focus{
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-800:focus{
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity))
  }

  .xl\:focus\:tw-text-indigo-900:focus{
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-100:focus{
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-200:focus{
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-300:focus{
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-400:focus{
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-500:focus{
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-600:focus{
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-700:focus{
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-800:focus{
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity))
  }

  .xl\:focus\:tw-text-purple-900:focus{
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-100:focus{
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-200:focus{
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-300:focus{
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-400:focus{
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-500:focus{
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-600:focus{
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-700:focus{
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-800:focus{
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity))
  }

  .xl\:focus\:tw-text-pink-900:focus{
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity))
  }

  .xl\:tw-text-opacity-0{
    --text-opacity: 0
  }

  .xl\:tw-text-opacity-25{
    --text-opacity: 0.25
  }

  .xl\:tw-text-opacity-50{
    --text-opacity: 0.5
  }

  .xl\:tw-text-opacity-75{
    --text-opacity: 0.75
  }

  .xl\:tw-text-opacity-100{
    --text-opacity: 1
  }

  .xl\:hover\:tw-text-opacity-0:hover{
    --text-opacity: 0
  }

  .xl\:hover\:tw-text-opacity-25:hover{
    --text-opacity: 0.25
  }

  .xl\:hover\:tw-text-opacity-50:hover{
    --text-opacity: 0.5
  }

  .xl\:hover\:tw-text-opacity-75:hover{
    --text-opacity: 0.75
  }

  .xl\:hover\:tw-text-opacity-100:hover{
    --text-opacity: 1
  }

  .xl\:focus\:tw-text-opacity-0:focus{
    --text-opacity: 0
  }

  .xl\:focus\:tw-text-opacity-25:focus{
    --text-opacity: 0.25
  }

  .xl\:focus\:tw-text-opacity-50:focus{
    --text-opacity: 0.5
  }

  .xl\:focus\:tw-text-opacity-75:focus{
    --text-opacity: 0.75
  }

  .xl\:focus\:tw-text-opacity-100:focus{
    --text-opacity: 1
  }

  .xl\:tw-italic{
    font-style: italic
  }

  .xl\:tw-not-italic{
    font-style: normal
  }

  .xl\:tw-uppercase{
    text-transform: uppercase
  }

  .xl\:tw-lowercase{
    text-transform: lowercase
  }

  .xl\:tw-capitalize{
    text-transform: capitalize
  }

  .xl\:tw-normal-case{
    text-transform: none
  }

  .xl\:tw-underline{
    text-decoration: underline
  }

  .xl\:tw-line-through{
    text-decoration: line-through
  }

  .xl\:tw-no-underline{
    text-decoration: none
  }

  .xl\:hover\:tw-underline:hover{
    text-decoration: underline
  }

  .xl\:hover\:tw-line-through:hover{
    text-decoration: line-through
  }

  .xl\:hover\:tw-no-underline:hover{
    text-decoration: none
  }

  .xl\:focus\:tw-underline:focus{
    text-decoration: underline
  }

  .xl\:focus\:tw-line-through:focus{
    text-decoration: line-through
  }

  .xl\:focus\:tw-no-underline:focus{
    text-decoration: none
  }

  .xl\:tw-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .xl\:tw-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .xl\:tw-ordinal, .xl\:tw-slashed-zero, .xl\:tw-lining-nums, .xl\:tw-oldstyle-nums, .xl\:tw-proportional-nums, .xl\:tw-tabular-nums, .xl\:tw-diagonal-fractions, .xl\:tw-stacked-fractions{
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    -webkit-font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
            font-feature-settings: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .xl\:tw-normal-nums{
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant-numeric: normal
  }

  .xl\:tw-ordinal{
    --font-variant-numeric-ordinal: ordinal
  }

  .xl\:tw-slashed-zero{
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .xl\:tw-lining-nums{
    --font-variant-numeric-figure: lining-nums
  }

  .xl\:tw-oldstyle-nums{
    --font-variant-numeric-figure: oldstyle-nums
  }

  .xl\:tw-proportional-nums{
    --font-variant-numeric-spacing: proportional-nums
  }

  .xl\:tw-tabular-nums{
    --font-variant-numeric-spacing: tabular-nums
  }

  .xl\:tw-diagonal-fractions{
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .xl\:tw-stacked-fractions{
    --font-variant-numeric-fraction: stacked-fractions
  }

  .xl\:tw-tracking-tighter{
    letter-spacing: -0.05em
  }

  .xl\:tw-tracking-tight{
    letter-spacing: -0.025em
  }

  .xl\:tw-tracking-normal{
    letter-spacing: 0
  }

  .xl\:tw-tracking-wide{
    letter-spacing: 0.025em
  }

  .xl\:tw-tracking-wider{
    letter-spacing: 0.05em
  }

  .xl\:tw-tracking-widest{
    letter-spacing: 0.1em
  }

  .xl\:tw-select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none
  }

  .xl\:tw-select-text{
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text
  }

  .xl\:tw-select-all{
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all
  }

  .xl\:tw-select-auto{
    -webkit-user-select: auto;
       -moz-user-select: auto;
        -ms-user-select: auto;
            user-select: auto
  }

  .xl\:tw-align-baseline{
    vertical-align: baseline
  }

  .xl\:tw-align-top{
    vertical-align: top
  }

  .xl\:tw-align-middle{
    vertical-align: middle
  }

  .xl\:tw-align-bottom{
    vertical-align: bottom
  }

  .xl\:tw-align-text-top{
    vertical-align: text-top
  }

  .xl\:tw-align-text-bottom{
    vertical-align: text-bottom
  }

  .xl\:tw-visible{
    visibility: visible
  }

  .xl\:tw-invisible{
    visibility: hidden
  }

  .xl\:tw-whitespace-normal{
    white-space: normal
  }

  .xl\:tw-whitespace-no-wrap{
    white-space: nowrap
  }

  .xl\:tw-whitespace-pre{
    white-space: pre
  }

  .xl\:tw-whitespace-pre-line{
    white-space: pre-line
  }

  .xl\:tw-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  .xl\:tw-break-normal{
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .xl\:tw-break-words{
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .xl\:tw-break-all{
    word-break: break-all
  }

  .xl\:tw-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .xl\:tw-w-0{
    width: 0
  }

  .xl\:tw-w-1{
    width: 0.25rem
  }

  .xl\:tw-w-2{
    width: 0.5rem
  }

  .xl\:tw-w-3{
    width: 0.75rem
  }

  .xl\:tw-w-4{
    width: 1rem
  }

  .xl\:tw-w-5{
    width: 1.25rem
  }

  .xl\:tw-w-6{
    width: 1.5rem
  }

  .xl\:tw-w-8{
    width: 2rem
  }

  .xl\:tw-w-10{
    width: 2.5rem
  }

  .xl\:tw-w-12{
    width: 3rem
  }

  .xl\:tw-w-16{
    width: 4rem
  }

  .xl\:tw-w-20{
    width: 5rem
  }

  .xl\:tw-w-24{
    width: 6rem
  }

  .xl\:tw-w-32{
    width: 8rem
  }

  .xl\:tw-w-40{
    width: 10rem
  }

  .xl\:tw-w-48{
    width: 12rem
  }

  .xl\:tw-w-56{
    width: 14rem
  }

  .xl\:tw-w-64{
    width: 16rem
  }

  .xl\:tw-w-auto{
    width: auto
  }

  .xl\:tw-w-px{
    width: 1px
  }

  .xl\:tw-w-1\/2{
    width: 50%
  }

  .xl\:tw-w-1\/3{
    width: 33.33333%
  }

  .xl\:tw-w-2\/3{
    width: 66.66667%
  }

  .xl\:tw-w-1\/4{
    width: 25%
  }

  .xl\:tw-w-2\/4{
    width: 50%
  }

  .xl\:tw-w-3\/4{
    width: 75%
  }

  .xl\:tw-w-1\/5{
    width: 20%
  }

  .xl\:tw-w-2\/5{
    width: 40%
  }

  .xl\:tw-w-3\/5{
    width: 60%
  }

  .xl\:tw-w-4\/5{
    width: 80%
  }

  .xl\:tw-w-1\/6{
    width: 16.66667%
  }

  .xl\:tw-w-2\/6{
    width: 33.33333%
  }

  .xl\:tw-w-3\/6{
    width: 50%
  }

  .xl\:tw-w-4\/6{
    width: 66.66667%
  }

  .xl\:tw-w-5\/6{
    width: 83.33333%
  }

  .xl\:tw-w-1\/12{
    width: 8.33333%
  }

  .xl\:tw-w-2\/12{
    width: 16.66667%
  }

  .xl\:tw-w-3\/12{
    width: 25%
  }

  .xl\:tw-w-4\/12{
    width: 33.33333%
  }

  .xl\:tw-w-5\/12{
    width: 41.66667%
  }

  .xl\:tw-w-6\/12{
    width: 50%
  }

  .xl\:tw-w-7\/12{
    width: 58.33333%
  }

  .xl\:tw-w-8\/12{
    width: 66.66667%
  }

  .xl\:tw-w-9\/12{
    width: 75%
  }

  .xl\:tw-w-10\/12{
    width: 83.33333%
  }

  .xl\:tw-w-11\/12{
    width: 91.66667%
  }

  .xl\:tw-w-full{
    width: 100%
  }

  .xl\:tw-w-screen{
    width: 100vw
  }

  .xl\:tw-z-0{
    z-index: 0
  }

  .xl\:tw-z-10{
    z-index: 10
  }

  .xl\:tw-z-20{
    z-index: 20
  }

  .xl\:tw-z-30{
    z-index: 30
  }

  .xl\:tw-z-40{
    z-index: 40
  }

  .xl\:tw-z-50{
    z-index: 50
  }

  .xl\:tw-z-auto{
    z-index: auto
  }

  .xl\:tw-gap-0{
    grid-gap: 0;
    grid-gap: 0;
    gap: 0
  }

  .xl\:tw-gap-1{
    grid-gap: 0.25rem;
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .xl\:tw-gap-2{
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .xl\:tw-gap-3{
    grid-gap: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .xl\:tw-gap-4{
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem
  }

  .xl\:tw-gap-5{
    grid-gap: 1.25rem;
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .xl\:tw-gap-6{
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .xl\:tw-gap-8{
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem
  }

  .xl\:tw-gap-10{
    grid-gap: 2.5rem;
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .xl\:tw-gap-12{
    grid-gap: 3rem;
    grid-gap: 3rem;
    gap: 3rem
  }

  .xl\:tw-gap-16{
    grid-gap: 4rem;
    grid-gap: 4rem;
    gap: 4rem
  }

  .xl\:tw-gap-20{
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem
  }

  .xl\:tw-gap-24{
    grid-gap: 6rem;
    grid-gap: 6rem;
    gap: 6rem
  }

  .xl\:tw-gap-32{
    grid-gap: 8rem;
    grid-gap: 8rem;
    gap: 8rem
  }

  .xl\:tw-gap-40{
    grid-gap: 10rem;
    grid-gap: 10rem;
    gap: 10rem
  }

  .xl\:tw-gap-48{
    grid-gap: 12rem;
    grid-gap: 12rem;
    gap: 12rem
  }

  .xl\:tw-gap-56{
    grid-gap: 14rem;
    grid-gap: 14rem;
    gap: 14rem
  }

  .xl\:tw-gap-64{
    grid-gap: 16rem;
    grid-gap: 16rem;
    gap: 16rem
  }

  .xl\:tw-gap-px{
    grid-gap: 1px;
    grid-gap: 1px;
    gap: 1px
  }

  .xl\:tw-col-gap-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .xl\:tw-col-gap-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .xl\:tw-col-gap-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .xl\:tw-col-gap-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .xl\:tw-col-gap-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .xl\:tw-col-gap-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .xl\:tw-col-gap-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .xl\:tw-col-gap-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .xl\:tw-col-gap-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .xl\:tw-col-gap-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .xl\:tw-col-gap-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .xl\:tw-col-gap-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .xl\:tw-col-gap-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .xl\:tw-col-gap-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .xl\:tw-col-gap-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .xl\:tw-col-gap-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .xl\:tw-col-gap-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .xl\:tw-col-gap-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .xl\:tw-col-gap-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .xl\:tw-gap-x-0{
    grid-column-gap: 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0
  }

  .xl\:tw-gap-x-1{
    grid-column-gap: 0.25rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem
  }

  .xl\:tw-gap-x-2{
    grid-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
       -moz-column-gap: 0.5rem;
            column-gap: 0.5rem
  }

  .xl\:tw-gap-x-3{
    grid-column-gap: 0.75rem;
    grid-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem
  }

  .xl\:tw-gap-x-4{
    grid-column-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem
  }

  .xl\:tw-gap-x-5{
    grid-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem
  }

  .xl\:tw-gap-x-6{
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .xl\:tw-gap-x-8{
    grid-column-gap: 2rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem
  }

  .xl\:tw-gap-x-10{
    grid-column-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem
  }

  .xl\:tw-gap-x-12{
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .xl\:tw-gap-x-16{
    grid-column-gap: 4rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem
  }

  .xl\:tw-gap-x-20{
    grid-column-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem
  }

  .xl\:tw-gap-x-24{
    grid-column-gap: 6rem;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
       -moz-column-gap: 6rem;
            column-gap: 6rem
  }

  .xl\:tw-gap-x-32{
    grid-column-gap: 8rem;
    grid-column-gap: 8rem;
    -webkit-column-gap: 8rem;
       -moz-column-gap: 8rem;
            column-gap: 8rem
  }

  .xl\:tw-gap-x-40{
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    -webkit-column-gap: 10rem;
       -moz-column-gap: 10rem;
            column-gap: 10rem
  }

  .xl\:tw-gap-x-48{
    grid-column-gap: 12rem;
    grid-column-gap: 12rem;
    -webkit-column-gap: 12rem;
       -moz-column-gap: 12rem;
            column-gap: 12rem
  }

  .xl\:tw-gap-x-56{
    grid-column-gap: 14rem;
    grid-column-gap: 14rem;
    -webkit-column-gap: 14rem;
       -moz-column-gap: 14rem;
            column-gap: 14rem
  }

  .xl\:tw-gap-x-64{
    grid-column-gap: 16rem;
    grid-column-gap: 16rem;
    -webkit-column-gap: 16rem;
       -moz-column-gap: 16rem;
            column-gap: 16rem
  }

  .xl\:tw-gap-x-px{
    grid-column-gap: 1px;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
       -moz-column-gap: 1px;
            column-gap: 1px
  }

  .xl\:tw-row-gap-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .xl\:tw-row-gap-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .xl\:tw-row-gap-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .xl\:tw-row-gap-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .xl\:tw-row-gap-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .xl\:tw-row-gap-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .xl\:tw-row-gap-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .xl\:tw-row-gap-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .xl\:tw-row-gap-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .xl\:tw-row-gap-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .xl\:tw-row-gap-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .xl\:tw-row-gap-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .xl\:tw-row-gap-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .xl\:tw-row-gap-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .xl\:tw-row-gap-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .xl\:tw-row-gap-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .xl\:tw-row-gap-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .xl\:tw-row-gap-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .xl\:tw-row-gap-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .xl\:tw-gap-y-0{
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .xl\:tw-gap-y-1{
    grid-row-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .xl\:tw-gap-y-2{
    grid-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .xl\:tw-gap-y-3{
    grid-row-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .xl\:tw-gap-y-4{
    grid-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .xl\:tw-gap-y-5{
    grid-row-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .xl\:tw-gap-y-6{
    grid-row-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .xl\:tw-gap-y-8{
    grid-row-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .xl\:tw-gap-y-10{
    grid-row-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .xl\:tw-gap-y-12{
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .xl\:tw-gap-y-16{
    grid-row-gap: 4rem;
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .xl\:tw-gap-y-20{
    grid-row-gap: 5rem;
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .xl\:tw-gap-y-24{
    grid-row-gap: 6rem;
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .xl\:tw-gap-y-32{
    grid-row-gap: 8rem;
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .xl\:tw-gap-y-40{
    grid-row-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .xl\:tw-gap-y-48{
    grid-row-gap: 12rem;
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .xl\:tw-gap-y-56{
    grid-row-gap: 14rem;
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .xl\:tw-gap-y-64{
    grid-row-gap: 16rem;
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .xl\:tw-gap-y-px{
    grid-row-gap: 1px;
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .xl\:tw-grid-flow-row{
    grid-auto-flow: row
  }

  .xl\:tw-grid-flow-col{
    grid-auto-flow: column
  }

  .xl\:tw-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  .xl\:tw-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  .xl\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .xl\:tw-grid-cols-none{
    grid-template-columns: none
  }

  .xl\:tw-auto-cols-auto{
    grid-auto-columns: auto
  }

  .xl\:tw-auto-cols-min{
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .xl\:tw-auto-cols-max{
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .xl\:tw-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  .xl\:tw-col-auto{
    grid-column: auto
  }

  .xl\:tw-col-span-1{
    grid-column: span 1 / span 1
  }

  .xl\:tw-col-span-2{
    grid-column: span 2 / span 2
  }

  .xl\:tw-col-span-3{
    grid-column: span 3 / span 3
  }

  .xl\:tw-col-span-4{
    grid-column: span 4 / span 4
  }

  .xl\:tw-col-span-5{
    grid-column: span 5 / span 5
  }

  .xl\:tw-col-span-6{
    grid-column: span 6 / span 6
  }

  .xl\:tw-col-span-7{
    grid-column: span 7 / span 7
  }

  .xl\:tw-col-span-8{
    grid-column: span 8 / span 8
  }

  .xl\:tw-col-span-9{
    grid-column: span 9 / span 9
  }

  .xl\:tw-col-span-10{
    grid-column: span 10 / span 10
  }

  .xl\:tw-col-span-11{
    grid-column: span 11 / span 11
  }

  .xl\:tw-col-span-12{
    grid-column: span 12 / span 12
  }

  .xl\:tw-col-span-full{
    grid-column: 1 / -1
  }

  .xl\:tw-col-start-1{
    grid-column-start: 1
  }

  .xl\:tw-col-start-2{
    grid-column-start: 2
  }

  .xl\:tw-col-start-3{
    grid-column-start: 3
  }

  .xl\:tw-col-start-4{
    grid-column-start: 4
  }

  .xl\:tw-col-start-5{
    grid-column-start: 5
  }

  .xl\:tw-col-start-6{
    grid-column-start: 6
  }

  .xl\:tw-col-start-7{
    grid-column-start: 7
  }

  .xl\:tw-col-start-8{
    grid-column-start: 8
  }

  .xl\:tw-col-start-9{
    grid-column-start: 9
  }

  .xl\:tw-col-start-10{
    grid-column-start: 10
  }

  .xl\:tw-col-start-11{
    grid-column-start: 11
  }

  .xl\:tw-col-start-12{
    grid-column-start: 12
  }

  .xl\:tw-col-start-13{
    grid-column-start: 13
  }

  .xl\:tw-col-start-auto{
    grid-column-start: auto
  }

  .xl\:tw-col-end-1{
    grid-column-end: 1
  }

  .xl\:tw-col-end-2{
    grid-column-end: 2
  }

  .xl\:tw-col-end-3{
    grid-column-end: 3
  }

  .xl\:tw-col-end-4{
    grid-column-end: 4
  }

  .xl\:tw-col-end-5{
    grid-column-end: 5
  }

  .xl\:tw-col-end-6{
    grid-column-end: 6
  }

  .xl\:tw-col-end-7{
    grid-column-end: 7
  }

  .xl\:tw-col-end-8{
    grid-column-end: 8
  }

  .xl\:tw-col-end-9{
    grid-column-end: 9
  }

  .xl\:tw-col-end-10{
    grid-column-end: 10
  }

  .xl\:tw-col-end-11{
    grid-column-end: 11
  }

  .xl\:tw-col-end-12{
    grid-column-end: 12
  }

  .xl\:tw-col-end-13{
    grid-column-end: 13
  }

  .xl\:tw-col-end-auto{
    grid-column-end: auto
  }

  .xl\:tw-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .xl\:tw-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .xl\:tw-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .xl\:tw-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .xl\:tw-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .xl\:tw-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .xl\:tw-grid-rows-none{
    grid-template-rows: none
  }

  .xl\:tw-auto-rows-auto{
    grid-auto-rows: auto
  }

  .xl\:tw-auto-rows-min{
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .xl\:tw-auto-rows-max{
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .xl\:tw-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  .xl\:tw-row-auto{
    grid-row: auto
  }

  .xl\:tw-row-span-1{
    grid-row: span 1 / span 1
  }

  .xl\:tw-row-span-2{
    grid-row: span 2 / span 2
  }

  .xl\:tw-row-span-3{
    grid-row: span 3 / span 3
  }

  .xl\:tw-row-span-4{
    grid-row: span 4 / span 4
  }

  .xl\:tw-row-span-5{
    grid-row: span 5 / span 5
  }

  .xl\:tw-row-span-6{
    grid-row: span 6 / span 6
  }

  .xl\:tw-row-span-full{
    grid-row: 1 / -1
  }

  .xl\:tw-row-start-1{
    grid-row-start: 1
  }

  .xl\:tw-row-start-2{
    grid-row-start: 2
  }

  .xl\:tw-row-start-3{
    grid-row-start: 3
  }

  .xl\:tw-row-start-4{
    grid-row-start: 4
  }

  .xl\:tw-row-start-5{
    grid-row-start: 5
  }

  .xl\:tw-row-start-6{
    grid-row-start: 6
  }

  .xl\:tw-row-start-7{
    grid-row-start: 7
  }

  .xl\:tw-row-start-auto{
    grid-row-start: auto
  }

  .xl\:tw-row-end-1{
    grid-row-end: 1
  }

  .xl\:tw-row-end-2{
    grid-row-end: 2
  }

  .xl\:tw-row-end-3{
    grid-row-end: 3
  }

  .xl\:tw-row-end-4{
    grid-row-end: 4
  }

  .xl\:tw-row-end-5{
    grid-row-end: 5
  }

  .xl\:tw-row-end-6{
    grid-row-end: 6
  }

  .xl\:tw-row-end-7{
    grid-row-end: 7
  }

  .xl\:tw-row-end-auto{
    grid-row-end: auto
  }

  .xl\:tw-transform{
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .xl\:tw-transform-none{
    -webkit-transform: none;
            transform: none
  }

  .xl\:tw-origin-center{
    -webkit-transform-origin: center;
            transform-origin: center
  }

  .xl\:tw-origin-top{
    -webkit-transform-origin: top;
            transform-origin: top
  }

  .xl\:tw-origin-top-right{
    -webkit-transform-origin: top right;
            transform-origin: top right
  }

  .xl\:tw-origin-right{
    -webkit-transform-origin: right;
            transform-origin: right
  }

  .xl\:tw-origin-bottom-right{
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right
  }

  .xl\:tw-origin-bottom{
    -webkit-transform-origin: bottom;
            transform-origin: bottom
  }

  .xl\:tw-origin-bottom-left{
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left
  }

  .xl\:tw-origin-left{
    -webkit-transform-origin: left;
            transform-origin: left
  }

  .xl\:tw-origin-top-left{
    -webkit-transform-origin: top left;
            transform-origin: top left
  }

  .xl\:tw-scale-0{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:tw-scale-50{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:tw-scale-75{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:tw-scale-90{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:tw-scale-95{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:tw-scale-100{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:tw-scale-105{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:tw-scale-110{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:tw-scale-125{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:tw-scale-150{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:tw-scale-x-0{
    --transform-scale-x: 0
  }

  .xl\:tw-scale-x-50{
    --transform-scale-x: .5
  }

  .xl\:tw-scale-x-75{
    --transform-scale-x: .75
  }

  .xl\:tw-scale-x-90{
    --transform-scale-x: .9
  }

  .xl\:tw-scale-x-95{
    --transform-scale-x: .95
  }

  .xl\:tw-scale-x-100{
    --transform-scale-x: 1
  }

  .xl\:tw-scale-x-105{
    --transform-scale-x: 1.05
  }

  .xl\:tw-scale-x-110{
    --transform-scale-x: 1.1
  }

  .xl\:tw-scale-x-125{
    --transform-scale-x: 1.25
  }

  .xl\:tw-scale-x-150{
    --transform-scale-x: 1.5
  }

  .xl\:tw-scale-y-0{
    --transform-scale-y: 0
  }

  .xl\:tw-scale-y-50{
    --transform-scale-y: .5
  }

  .xl\:tw-scale-y-75{
    --transform-scale-y: .75
  }

  .xl\:tw-scale-y-90{
    --transform-scale-y: .9
  }

  .xl\:tw-scale-y-95{
    --transform-scale-y: .95
  }

  .xl\:tw-scale-y-100{
    --transform-scale-y: 1
  }

  .xl\:tw-scale-y-105{
    --transform-scale-y: 1.05
  }

  .xl\:tw-scale-y-110{
    --transform-scale-y: 1.1
  }

  .xl\:tw-scale-y-125{
    --transform-scale-y: 1.25
  }

  .xl\:tw-scale-y-150{
    --transform-scale-y: 1.5
  }

  .xl\:hover\:tw-scale-0:hover{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:hover\:tw-scale-50:hover{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:hover\:tw-scale-75:hover{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:hover\:tw-scale-90:hover{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:hover\:tw-scale-95:hover{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:hover\:tw-scale-100:hover{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:hover\:tw-scale-105:hover{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:hover\:tw-scale-110:hover{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:hover\:tw-scale-125:hover{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:hover\:tw-scale-150:hover{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:hover\:tw-scale-x-0:hover{
    --transform-scale-x: 0
  }

  .xl\:hover\:tw-scale-x-50:hover{
    --transform-scale-x: .5
  }

  .xl\:hover\:tw-scale-x-75:hover{
    --transform-scale-x: .75
  }

  .xl\:hover\:tw-scale-x-90:hover{
    --transform-scale-x: .9
  }

  .xl\:hover\:tw-scale-x-95:hover{
    --transform-scale-x: .95
  }

  .xl\:hover\:tw-scale-x-100:hover{
    --transform-scale-x: 1
  }

  .xl\:hover\:tw-scale-x-105:hover{
    --transform-scale-x: 1.05
  }

  .xl\:hover\:tw-scale-x-110:hover{
    --transform-scale-x: 1.1
  }

  .xl\:hover\:tw-scale-x-125:hover{
    --transform-scale-x: 1.25
  }

  .xl\:hover\:tw-scale-x-150:hover{
    --transform-scale-x: 1.5
  }

  .xl\:hover\:tw-scale-y-0:hover{
    --transform-scale-y: 0
  }

  .xl\:hover\:tw-scale-y-50:hover{
    --transform-scale-y: .5
  }

  .xl\:hover\:tw-scale-y-75:hover{
    --transform-scale-y: .75
  }

  .xl\:hover\:tw-scale-y-90:hover{
    --transform-scale-y: .9
  }

  .xl\:hover\:tw-scale-y-95:hover{
    --transform-scale-y: .95
  }

  .xl\:hover\:tw-scale-y-100:hover{
    --transform-scale-y: 1
  }

  .xl\:hover\:tw-scale-y-105:hover{
    --transform-scale-y: 1.05
  }

  .xl\:hover\:tw-scale-y-110:hover{
    --transform-scale-y: 1.1
  }

  .xl\:hover\:tw-scale-y-125:hover{
    --transform-scale-y: 1.25
  }

  .xl\:hover\:tw-scale-y-150:hover{
    --transform-scale-y: 1.5
  }

  .xl\:focus\:tw-scale-0:focus{
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:focus\:tw-scale-50:focus{
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:focus\:tw-scale-75:focus{
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:focus\:tw-scale-90:focus{
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:focus\:tw-scale-95:focus{
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:focus\:tw-scale-100:focus{
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:focus\:tw-scale-105:focus{
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:focus\:tw-scale-110:focus{
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:focus\:tw-scale-125:focus{
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:focus\:tw-scale-150:focus{
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:focus\:tw-scale-x-0:focus{
    --transform-scale-x: 0
  }

  .xl\:focus\:tw-scale-x-50:focus{
    --transform-scale-x: .5
  }

  .xl\:focus\:tw-scale-x-75:focus{
    --transform-scale-x: .75
  }

  .xl\:focus\:tw-scale-x-90:focus{
    --transform-scale-x: .9
  }

  .xl\:focus\:tw-scale-x-95:focus{
    --transform-scale-x: .95
  }

  .xl\:focus\:tw-scale-x-100:focus{
    --transform-scale-x: 1
  }

  .xl\:focus\:tw-scale-x-105:focus{
    --transform-scale-x: 1.05
  }

  .xl\:focus\:tw-scale-x-110:focus{
    --transform-scale-x: 1.1
  }

  .xl\:focus\:tw-scale-x-125:focus{
    --transform-scale-x: 1.25
  }

  .xl\:focus\:tw-scale-x-150:focus{
    --transform-scale-x: 1.5
  }

  .xl\:focus\:tw-scale-y-0:focus{
    --transform-scale-y: 0
  }

  .xl\:focus\:tw-scale-y-50:focus{
    --transform-scale-y: .5
  }

  .xl\:focus\:tw-scale-y-75:focus{
    --transform-scale-y: .75
  }

  .xl\:focus\:tw-scale-y-90:focus{
    --transform-scale-y: .9
  }

  .xl\:focus\:tw-scale-y-95:focus{
    --transform-scale-y: .95
  }

  .xl\:focus\:tw-scale-y-100:focus{
    --transform-scale-y: 1
  }

  .xl\:focus\:tw-scale-y-105:focus{
    --transform-scale-y: 1.05
  }

  .xl\:focus\:tw-scale-y-110:focus{
    --transform-scale-y: 1.1
  }

  .xl\:focus\:tw-scale-y-125:focus{
    --transform-scale-y: 1.25
  }

  .xl\:focus\:tw-scale-y-150:focus{
    --transform-scale-y: 1.5
  }

  .xl\:tw-rotate-0{
    --transform-rotate: 0
  }

  .xl\:tw-rotate-1{
    --transform-rotate: 1deg
  }

  .xl\:tw-rotate-2{
    --transform-rotate: 2deg
  }

  .xl\:tw-rotate-3{
    --transform-rotate: 3deg
  }

  .xl\:tw-rotate-6{
    --transform-rotate: 6deg
  }

  .xl\:tw-rotate-12{
    --transform-rotate: 12deg
  }

  .xl\:tw-rotate-45{
    --transform-rotate: 45deg
  }

  .xl\:tw-rotate-90{
    --transform-rotate: 90deg
  }

  .xl\:tw-rotate-180{
    --transform-rotate: 180deg
  }

  .xl\:tw--rotate-180{
    --transform-rotate: -180deg
  }

  .xl\:tw--rotate-90{
    --transform-rotate: -90deg
  }

  .xl\:tw--rotate-45{
    --transform-rotate: -45deg
  }

  .xl\:tw--rotate-12{
    --transform-rotate: -12deg
  }

  .xl\:tw--rotate-6{
    --transform-rotate: -6deg
  }

  .xl\:tw--rotate-3{
    --transform-rotate: -3deg
  }

  .xl\:tw--rotate-2{
    --transform-rotate: -2deg
  }

  .xl\:tw--rotate-1{
    --transform-rotate: -1deg
  }

  .xl\:hover\:tw-rotate-0:hover{
    --transform-rotate: 0
  }

  .xl\:hover\:tw-rotate-1:hover{
    --transform-rotate: 1deg
  }

  .xl\:hover\:tw-rotate-2:hover{
    --transform-rotate: 2deg
  }

  .xl\:hover\:tw-rotate-3:hover{
    --transform-rotate: 3deg
  }

  .xl\:hover\:tw-rotate-6:hover{
    --transform-rotate: 6deg
  }

  .xl\:hover\:tw-rotate-12:hover{
    --transform-rotate: 12deg
  }

  .xl\:hover\:tw-rotate-45:hover{
    --transform-rotate: 45deg
  }

  .xl\:hover\:tw-rotate-90:hover{
    --transform-rotate: 90deg
  }

  .xl\:hover\:tw-rotate-180:hover{
    --transform-rotate: 180deg
  }

  .xl\:hover\:tw--rotate-180:hover{
    --transform-rotate: -180deg
  }

  .xl\:hover\:tw--rotate-90:hover{
    --transform-rotate: -90deg
  }

  .xl\:hover\:tw--rotate-45:hover{
    --transform-rotate: -45deg
  }

  .xl\:hover\:tw--rotate-12:hover{
    --transform-rotate: -12deg
  }

  .xl\:hover\:tw--rotate-6:hover{
    --transform-rotate: -6deg
  }

  .xl\:hover\:tw--rotate-3:hover{
    --transform-rotate: -3deg
  }

  .xl\:hover\:tw--rotate-2:hover{
    --transform-rotate: -2deg
  }

  .xl\:hover\:tw--rotate-1:hover{
    --transform-rotate: -1deg
  }

  .xl\:focus\:tw-rotate-0:focus{
    --transform-rotate: 0
  }

  .xl\:focus\:tw-rotate-1:focus{
    --transform-rotate: 1deg
  }

  .xl\:focus\:tw-rotate-2:focus{
    --transform-rotate: 2deg
  }

  .xl\:focus\:tw-rotate-3:focus{
    --transform-rotate: 3deg
  }

  .xl\:focus\:tw-rotate-6:focus{
    --transform-rotate: 6deg
  }

  .xl\:focus\:tw-rotate-12:focus{
    --transform-rotate: 12deg
  }

  .xl\:focus\:tw-rotate-45:focus{
    --transform-rotate: 45deg
  }

  .xl\:focus\:tw-rotate-90:focus{
    --transform-rotate: 90deg
  }

  .xl\:focus\:tw-rotate-180:focus{
    --transform-rotate: 180deg
  }

  .xl\:focus\:tw--rotate-180:focus{
    --transform-rotate: -180deg
  }

  .xl\:focus\:tw--rotate-90:focus{
    --transform-rotate: -90deg
  }

  .xl\:focus\:tw--rotate-45:focus{
    --transform-rotate: -45deg
  }

  .xl\:focus\:tw--rotate-12:focus{
    --transform-rotate: -12deg
  }

  .xl\:focus\:tw--rotate-6:focus{
    --transform-rotate: -6deg
  }

  .xl\:focus\:tw--rotate-3:focus{
    --transform-rotate: -3deg
  }

  .xl\:focus\:tw--rotate-2:focus{
    --transform-rotate: -2deg
  }

  .xl\:focus\:tw--rotate-1:focus{
    --transform-rotate: -1deg
  }

  .xl\:tw-translate-x-0{
    --transform-translate-x: 0
  }

  .xl\:tw-translate-x-1{
    --transform-translate-x: 0.25rem
  }

  .xl\:tw-translate-x-2{
    --transform-translate-x: 0.5rem
  }

  .xl\:tw-translate-x-3{
    --transform-translate-x: 0.75rem
  }

  .xl\:tw-translate-x-4{
    --transform-translate-x: 1rem
  }

  .xl\:tw-translate-x-5{
    --transform-translate-x: 1.25rem
  }

  .xl\:tw-translate-x-6{
    --transform-translate-x: 1.5rem
  }

  .xl\:tw-translate-x-8{
    --transform-translate-x: 2rem
  }

  .xl\:tw-translate-x-10{
    --transform-translate-x: 2.5rem
  }

  .xl\:tw-translate-x-12{
    --transform-translate-x: 3rem
  }

  .xl\:tw-translate-x-16{
    --transform-translate-x: 4rem
  }

  .xl\:tw-translate-x-20{
    --transform-translate-x: 5rem
  }

  .xl\:tw-translate-x-24{
    --transform-translate-x: 6rem
  }

  .xl\:tw-translate-x-32{
    --transform-translate-x: 8rem
  }

  .xl\:tw-translate-x-40{
    --transform-translate-x: 10rem
  }

  .xl\:tw-translate-x-48{
    --transform-translate-x: 12rem
  }

  .xl\:tw-translate-x-56{
    --transform-translate-x: 14rem
  }

  .xl\:tw-translate-x-64{
    --transform-translate-x: 16rem
  }

  .xl\:tw-translate-x-px{
    --transform-translate-x: 1px
  }

  .xl\:tw--translate-x-1{
    --transform-translate-x: -0.25rem
  }

  .xl\:tw--translate-x-2{
    --transform-translate-x: -0.5rem
  }

  .xl\:tw--translate-x-3{
    --transform-translate-x: -0.75rem
  }

  .xl\:tw--translate-x-4{
    --transform-translate-x: -1rem
  }

  .xl\:tw--translate-x-5{
    --transform-translate-x: -1.25rem
  }

  .xl\:tw--translate-x-6{
    --transform-translate-x: -1.5rem
  }

  .xl\:tw--translate-x-8{
    --transform-translate-x: -2rem
  }

  .xl\:tw--translate-x-10{
    --transform-translate-x: -2.5rem
  }

  .xl\:tw--translate-x-12{
    --transform-translate-x: -3rem
  }

  .xl\:tw--translate-x-16{
    --transform-translate-x: -4rem
  }

  .xl\:tw--translate-x-20{
    --transform-translate-x: -5rem
  }

  .xl\:tw--translate-x-24{
    --transform-translate-x: -6rem
  }

  .xl\:tw--translate-x-32{
    --transform-translate-x: -8rem
  }

  .xl\:tw--translate-x-40{
    --transform-translate-x: -10rem
  }

  .xl\:tw--translate-x-48{
    --transform-translate-x: -12rem
  }

  .xl\:tw--translate-x-56{
    --transform-translate-x: -14rem
  }

  .xl\:tw--translate-x-64{
    --transform-translate-x: -16rem
  }

  .xl\:tw--translate-x-px{
    --transform-translate-x: -1px
  }

  .xl\:tw--translate-x-full{
    --transform-translate-x: -100%
  }

  .xl\:tw--translate-x-1\/2{
    --transform-translate-x: -50%
  }

  .xl\:tw-translate-x-1\/2{
    --transform-translate-x: 50%
  }

  .xl\:tw-translate-x-full{
    --transform-translate-x: 100%
  }

  .xl\:tw-translate-y-0{
    --transform-translate-y: 0
  }

  .xl\:tw-translate-y-1{
    --transform-translate-y: 0.25rem
  }

  .xl\:tw-translate-y-2{
    --transform-translate-y: 0.5rem
  }

  .xl\:tw-translate-y-3{
    --transform-translate-y: 0.75rem
  }

  .xl\:tw-translate-y-4{
    --transform-translate-y: 1rem
  }

  .xl\:tw-translate-y-5{
    --transform-translate-y: 1.25rem
  }

  .xl\:tw-translate-y-6{
    --transform-translate-y: 1.5rem
  }

  .xl\:tw-translate-y-8{
    --transform-translate-y: 2rem
  }

  .xl\:tw-translate-y-10{
    --transform-translate-y: 2.5rem
  }

  .xl\:tw-translate-y-12{
    --transform-translate-y: 3rem
  }

  .xl\:tw-translate-y-16{
    --transform-translate-y: 4rem
  }

  .xl\:tw-translate-y-20{
    --transform-translate-y: 5rem
  }

  .xl\:tw-translate-y-24{
    --transform-translate-y: 6rem
  }

  .xl\:tw-translate-y-32{
    --transform-translate-y: 8rem
  }

  .xl\:tw-translate-y-40{
    --transform-translate-y: 10rem
  }

  .xl\:tw-translate-y-48{
    --transform-translate-y: 12rem
  }

  .xl\:tw-translate-y-56{
    --transform-translate-y: 14rem
  }

  .xl\:tw-translate-y-64{
    --transform-translate-y: 16rem
  }

  .xl\:tw-translate-y-px{
    --transform-translate-y: 1px
  }

  .xl\:tw--translate-y-1{
    --transform-translate-y: -0.25rem
  }

  .xl\:tw--translate-y-2{
    --transform-translate-y: -0.5rem
  }

  .xl\:tw--translate-y-3{
    --transform-translate-y: -0.75rem
  }

  .xl\:tw--translate-y-4{
    --transform-translate-y: -1rem
  }

  .xl\:tw--translate-y-5{
    --transform-translate-y: -1.25rem
  }

  .xl\:tw--translate-y-6{
    --transform-translate-y: -1.5rem
  }

  .xl\:tw--translate-y-8{
    --transform-translate-y: -2rem
  }

  .xl\:tw--translate-y-10{
    --transform-translate-y: -2.5rem
  }

  .xl\:tw--translate-y-12{
    --transform-translate-y: -3rem
  }

  .xl\:tw--translate-y-16{
    --transform-translate-y: -4rem
  }

  .xl\:tw--translate-y-20{
    --transform-translate-y: -5rem
  }

  .xl\:tw--translate-y-24{
    --transform-translate-y: -6rem
  }

  .xl\:tw--translate-y-32{
    --transform-translate-y: -8rem
  }

  .xl\:tw--translate-y-40{
    --transform-translate-y: -10rem
  }

  .xl\:tw--translate-y-48{
    --transform-translate-y: -12rem
  }

  .xl\:tw--translate-y-56{
    --transform-translate-y: -14rem
  }

  .xl\:tw--translate-y-64{
    --transform-translate-y: -16rem
  }

  .xl\:tw--translate-y-px{
    --transform-translate-y: -1px
  }

  .xl\:tw--translate-y-full{
    --transform-translate-y: -100%
  }

  .xl\:tw--translate-y-1\/2{
    --transform-translate-y: -50%
  }

  .xl\:tw-translate-y-1\/2{
    --transform-translate-y: 50%
  }

  .xl\:tw-translate-y-full{
    --transform-translate-y: 100%
  }

  .xl\:hover\:tw-translate-x-0:hover{
    --transform-translate-x: 0
  }

  .xl\:hover\:tw-translate-x-1:hover{
    --transform-translate-x: 0.25rem
  }

  .xl\:hover\:tw-translate-x-2:hover{
    --transform-translate-x: 0.5rem
  }

  .xl\:hover\:tw-translate-x-3:hover{
    --transform-translate-x: 0.75rem
  }

  .xl\:hover\:tw-translate-x-4:hover{
    --transform-translate-x: 1rem
  }

  .xl\:hover\:tw-translate-x-5:hover{
    --transform-translate-x: 1.25rem
  }

  .xl\:hover\:tw-translate-x-6:hover{
    --transform-translate-x: 1.5rem
  }

  .xl\:hover\:tw-translate-x-8:hover{
    --transform-translate-x: 2rem
  }

  .xl\:hover\:tw-translate-x-10:hover{
    --transform-translate-x: 2.5rem
  }

  .xl\:hover\:tw-translate-x-12:hover{
    --transform-translate-x: 3rem
  }

  .xl\:hover\:tw-translate-x-16:hover{
    --transform-translate-x: 4rem
  }

  .xl\:hover\:tw-translate-x-20:hover{
    --transform-translate-x: 5rem
  }

  .xl\:hover\:tw-translate-x-24:hover{
    --transform-translate-x: 6rem
  }

  .xl\:hover\:tw-translate-x-32:hover{
    --transform-translate-x: 8rem
  }

  .xl\:hover\:tw-translate-x-40:hover{
    --transform-translate-x: 10rem
  }

  .xl\:hover\:tw-translate-x-48:hover{
    --transform-translate-x: 12rem
  }

  .xl\:hover\:tw-translate-x-56:hover{
    --transform-translate-x: 14rem
  }

  .xl\:hover\:tw-translate-x-64:hover{
    --transform-translate-x: 16rem
  }

  .xl\:hover\:tw-translate-x-px:hover{
    --transform-translate-x: 1px
  }

  .xl\:hover\:tw--translate-x-1:hover{
    --transform-translate-x: -0.25rem
  }

  .xl\:hover\:tw--translate-x-2:hover{
    --transform-translate-x: -0.5rem
  }

  .xl\:hover\:tw--translate-x-3:hover{
    --transform-translate-x: -0.75rem
  }

  .xl\:hover\:tw--translate-x-4:hover{
    --transform-translate-x: -1rem
  }

  .xl\:hover\:tw--translate-x-5:hover{
    --transform-translate-x: -1.25rem
  }

  .xl\:hover\:tw--translate-x-6:hover{
    --transform-translate-x: -1.5rem
  }

  .xl\:hover\:tw--translate-x-8:hover{
    --transform-translate-x: -2rem
  }

  .xl\:hover\:tw--translate-x-10:hover{
    --transform-translate-x: -2.5rem
  }

  .xl\:hover\:tw--translate-x-12:hover{
    --transform-translate-x: -3rem
  }

  .xl\:hover\:tw--translate-x-16:hover{
    --transform-translate-x: -4rem
  }

  .xl\:hover\:tw--translate-x-20:hover{
    --transform-translate-x: -5rem
  }

  .xl\:hover\:tw--translate-x-24:hover{
    --transform-translate-x: -6rem
  }

  .xl\:hover\:tw--translate-x-32:hover{
    --transform-translate-x: -8rem
  }

  .xl\:hover\:tw--translate-x-40:hover{
    --transform-translate-x: -10rem
  }

  .xl\:hover\:tw--translate-x-48:hover{
    --transform-translate-x: -12rem
  }

  .xl\:hover\:tw--translate-x-56:hover{
    --transform-translate-x: -14rem
  }

  .xl\:hover\:tw--translate-x-64:hover{
    --transform-translate-x: -16rem
  }

  .xl\:hover\:tw--translate-x-px:hover{
    --transform-translate-x: -1px
  }

  .xl\:hover\:tw--translate-x-full:hover{
    --transform-translate-x: -100%
  }

  .xl\:hover\:tw--translate-x-1\/2:hover{
    --transform-translate-x: -50%
  }

  .xl\:hover\:tw-translate-x-1\/2:hover{
    --transform-translate-x: 50%
  }

  .xl\:hover\:tw-translate-x-full:hover{
    --transform-translate-x: 100%
  }

  .xl\:hover\:tw-translate-y-0:hover{
    --transform-translate-y: 0
  }

  .xl\:hover\:tw-translate-y-1:hover{
    --transform-translate-y: 0.25rem
  }

  .xl\:hover\:tw-translate-y-2:hover{
    --transform-translate-y: 0.5rem
  }

  .xl\:hover\:tw-translate-y-3:hover{
    --transform-translate-y: 0.75rem
  }

  .xl\:hover\:tw-translate-y-4:hover{
    --transform-translate-y: 1rem
  }

  .xl\:hover\:tw-translate-y-5:hover{
    --transform-translate-y: 1.25rem
  }

  .xl\:hover\:tw-translate-y-6:hover{
    --transform-translate-y: 1.5rem
  }

  .xl\:hover\:tw-translate-y-8:hover{
    --transform-translate-y: 2rem
  }

  .xl\:hover\:tw-translate-y-10:hover{
    --transform-translate-y: 2.5rem
  }

  .xl\:hover\:tw-translate-y-12:hover{
    --transform-translate-y: 3rem
  }

  .xl\:hover\:tw-translate-y-16:hover{
    --transform-translate-y: 4rem
  }

  .xl\:hover\:tw-translate-y-20:hover{
    --transform-translate-y: 5rem
  }

  .xl\:hover\:tw-translate-y-24:hover{
    --transform-translate-y: 6rem
  }

  .xl\:hover\:tw-translate-y-32:hover{
    --transform-translate-y: 8rem
  }

  .xl\:hover\:tw-translate-y-40:hover{
    --transform-translate-y: 10rem
  }

  .xl\:hover\:tw-translate-y-48:hover{
    --transform-translate-y: 12rem
  }

  .xl\:hover\:tw-translate-y-56:hover{
    --transform-translate-y: 14rem
  }

  .xl\:hover\:tw-translate-y-64:hover{
    --transform-translate-y: 16rem
  }

  .xl\:hover\:tw-translate-y-px:hover{
    --transform-translate-y: 1px
  }

  .xl\:hover\:tw--translate-y-1:hover{
    --transform-translate-y: -0.25rem
  }

  .xl\:hover\:tw--translate-y-2:hover{
    --transform-translate-y: -0.5rem
  }

  .xl\:hover\:tw--translate-y-3:hover{
    --transform-translate-y: -0.75rem
  }

  .xl\:hover\:tw--translate-y-4:hover{
    --transform-translate-y: -1rem
  }

  .xl\:hover\:tw--translate-y-5:hover{
    --transform-translate-y: -1.25rem
  }

  .xl\:hover\:tw--translate-y-6:hover{
    --transform-translate-y: -1.5rem
  }

  .xl\:hover\:tw--translate-y-8:hover{
    --transform-translate-y: -2rem
  }

  .xl\:hover\:tw--translate-y-10:hover{
    --transform-translate-y: -2.5rem
  }

  .xl\:hover\:tw--translate-y-12:hover{
    --transform-translate-y: -3rem
  }

  .xl\:hover\:tw--translate-y-16:hover{
    --transform-translate-y: -4rem
  }

  .xl\:hover\:tw--translate-y-20:hover{
    --transform-translate-y: -5rem
  }

  .xl\:hover\:tw--translate-y-24:hover{
    --transform-translate-y: -6rem
  }

  .xl\:hover\:tw--translate-y-32:hover{
    --transform-translate-y: -8rem
  }

  .xl\:hover\:tw--translate-y-40:hover{
    --transform-translate-y: -10rem
  }

  .xl\:hover\:tw--translate-y-48:hover{
    --transform-translate-y: -12rem
  }

  .xl\:hover\:tw--translate-y-56:hover{
    --transform-translate-y: -14rem
  }

  .xl\:hover\:tw--translate-y-64:hover{
    --transform-translate-y: -16rem
  }

  .xl\:hover\:tw--translate-y-px:hover{
    --transform-translate-y: -1px
  }

  .xl\:hover\:tw--translate-y-full:hover{
    --transform-translate-y: -100%
  }

  .xl\:hover\:tw--translate-y-1\/2:hover{
    --transform-translate-y: -50%
  }

  .xl\:hover\:tw-translate-y-1\/2:hover{
    --transform-translate-y: 50%
  }

  .xl\:hover\:tw-translate-y-full:hover{
    --transform-translate-y: 100%
  }

  .xl\:focus\:tw-translate-x-0:focus{
    --transform-translate-x: 0
  }

  .xl\:focus\:tw-translate-x-1:focus{
    --transform-translate-x: 0.25rem
  }

  .xl\:focus\:tw-translate-x-2:focus{
    --transform-translate-x: 0.5rem
  }

  .xl\:focus\:tw-translate-x-3:focus{
    --transform-translate-x: 0.75rem
  }

  .xl\:focus\:tw-translate-x-4:focus{
    --transform-translate-x: 1rem
  }

  .xl\:focus\:tw-translate-x-5:focus{
    --transform-translate-x: 1.25rem
  }

  .xl\:focus\:tw-translate-x-6:focus{
    --transform-translate-x: 1.5rem
  }

  .xl\:focus\:tw-translate-x-8:focus{
    --transform-translate-x: 2rem
  }

  .xl\:focus\:tw-translate-x-10:focus{
    --transform-translate-x: 2.5rem
  }

  .xl\:focus\:tw-translate-x-12:focus{
    --transform-translate-x: 3rem
  }

  .xl\:focus\:tw-translate-x-16:focus{
    --transform-translate-x: 4rem
  }

  .xl\:focus\:tw-translate-x-20:focus{
    --transform-translate-x: 5rem
  }

  .xl\:focus\:tw-translate-x-24:focus{
    --transform-translate-x: 6rem
  }

  .xl\:focus\:tw-translate-x-32:focus{
    --transform-translate-x: 8rem
  }

  .xl\:focus\:tw-translate-x-40:focus{
    --transform-translate-x: 10rem
  }

  .xl\:focus\:tw-translate-x-48:focus{
    --transform-translate-x: 12rem
  }

  .xl\:focus\:tw-translate-x-56:focus{
    --transform-translate-x: 14rem
  }

  .xl\:focus\:tw-translate-x-64:focus{
    --transform-translate-x: 16rem
  }

  .xl\:focus\:tw-translate-x-px:focus{
    --transform-translate-x: 1px
  }

  .xl\:focus\:tw--translate-x-1:focus{
    --transform-translate-x: -0.25rem
  }

  .xl\:focus\:tw--translate-x-2:focus{
    --transform-translate-x: -0.5rem
  }

  .xl\:focus\:tw--translate-x-3:focus{
    --transform-translate-x: -0.75rem
  }

  .xl\:focus\:tw--translate-x-4:focus{
    --transform-translate-x: -1rem
  }

  .xl\:focus\:tw--translate-x-5:focus{
    --transform-translate-x: -1.25rem
  }

  .xl\:focus\:tw--translate-x-6:focus{
    --transform-translate-x: -1.5rem
  }

  .xl\:focus\:tw--translate-x-8:focus{
    --transform-translate-x: -2rem
  }

  .xl\:focus\:tw--translate-x-10:focus{
    --transform-translate-x: -2.5rem
  }

  .xl\:focus\:tw--translate-x-12:focus{
    --transform-translate-x: -3rem
  }

  .xl\:focus\:tw--translate-x-16:focus{
    --transform-translate-x: -4rem
  }

  .xl\:focus\:tw--translate-x-20:focus{
    --transform-translate-x: -5rem
  }

  .xl\:focus\:tw--translate-x-24:focus{
    --transform-translate-x: -6rem
  }

  .xl\:focus\:tw--translate-x-32:focus{
    --transform-translate-x: -8rem
  }

  .xl\:focus\:tw--translate-x-40:focus{
    --transform-translate-x: -10rem
  }

  .xl\:focus\:tw--translate-x-48:focus{
    --transform-translate-x: -12rem
  }

  .xl\:focus\:tw--translate-x-56:focus{
    --transform-translate-x: -14rem
  }

  .xl\:focus\:tw--translate-x-64:focus{
    --transform-translate-x: -16rem
  }

  .xl\:focus\:tw--translate-x-px:focus{
    --transform-translate-x: -1px
  }

  .xl\:focus\:tw--translate-x-full:focus{
    --transform-translate-x: -100%
  }

  .xl\:focus\:tw--translate-x-1\/2:focus{
    --transform-translate-x: -50%
  }

  .xl\:focus\:tw-translate-x-1\/2:focus{
    --transform-translate-x: 50%
  }

  .xl\:focus\:tw-translate-x-full:focus{
    --transform-translate-x: 100%
  }

  .xl\:focus\:tw-translate-y-0:focus{
    --transform-translate-y: 0
  }

  .xl\:focus\:tw-translate-y-1:focus{
    --transform-translate-y: 0.25rem
  }

  .xl\:focus\:tw-translate-y-2:focus{
    --transform-translate-y: 0.5rem
  }

  .xl\:focus\:tw-translate-y-3:focus{
    --transform-translate-y: 0.75rem
  }

  .xl\:focus\:tw-translate-y-4:focus{
    --transform-translate-y: 1rem
  }

  .xl\:focus\:tw-translate-y-5:focus{
    --transform-translate-y: 1.25rem
  }

  .xl\:focus\:tw-translate-y-6:focus{
    --transform-translate-y: 1.5rem
  }

  .xl\:focus\:tw-translate-y-8:focus{
    --transform-translate-y: 2rem
  }

  .xl\:focus\:tw-translate-y-10:focus{
    --transform-translate-y: 2.5rem
  }

  .xl\:focus\:tw-translate-y-12:focus{
    --transform-translate-y: 3rem
  }

  .xl\:focus\:tw-translate-y-16:focus{
    --transform-translate-y: 4rem
  }

  .xl\:focus\:tw-translate-y-20:focus{
    --transform-translate-y: 5rem
  }

  .xl\:focus\:tw-translate-y-24:focus{
    --transform-translate-y: 6rem
  }

  .xl\:focus\:tw-translate-y-32:focus{
    --transform-translate-y: 8rem
  }

  .xl\:focus\:tw-translate-y-40:focus{
    --transform-translate-y: 10rem
  }

  .xl\:focus\:tw-translate-y-48:focus{
    --transform-translate-y: 12rem
  }

  .xl\:focus\:tw-translate-y-56:focus{
    --transform-translate-y: 14rem
  }

  .xl\:focus\:tw-translate-y-64:focus{
    --transform-translate-y: 16rem
  }

  .xl\:focus\:tw-translate-y-px:focus{
    --transform-translate-y: 1px
  }

  .xl\:focus\:tw--translate-y-1:focus{
    --transform-translate-y: -0.25rem
  }

  .xl\:focus\:tw--translate-y-2:focus{
    --transform-translate-y: -0.5rem
  }

  .xl\:focus\:tw--translate-y-3:focus{
    --transform-translate-y: -0.75rem
  }

  .xl\:focus\:tw--translate-y-4:focus{
    --transform-translate-y: -1rem
  }

  .xl\:focus\:tw--translate-y-5:focus{
    --transform-translate-y: -1.25rem
  }

  .xl\:focus\:tw--translate-y-6:focus{
    --transform-translate-y: -1.5rem
  }

  .xl\:focus\:tw--translate-y-8:focus{
    --transform-translate-y: -2rem
  }

  .xl\:focus\:tw--translate-y-10:focus{
    --transform-translate-y: -2.5rem
  }

  .xl\:focus\:tw--translate-y-12:focus{
    --transform-translate-y: -3rem
  }

  .xl\:focus\:tw--translate-y-16:focus{
    --transform-translate-y: -4rem
  }

  .xl\:focus\:tw--translate-y-20:focus{
    --transform-translate-y: -5rem
  }

  .xl\:focus\:tw--translate-y-24:focus{
    --transform-translate-y: -6rem
  }

  .xl\:focus\:tw--translate-y-32:focus{
    --transform-translate-y: -8rem
  }

  .xl\:focus\:tw--translate-y-40:focus{
    --transform-translate-y: -10rem
  }

  .xl\:focus\:tw--translate-y-48:focus{
    --transform-translate-y: -12rem
  }

  .xl\:focus\:tw--translate-y-56:focus{
    --transform-translate-y: -14rem
  }

  .xl\:focus\:tw--translate-y-64:focus{
    --transform-translate-y: -16rem
  }

  .xl\:focus\:tw--translate-y-px:focus{
    --transform-translate-y: -1px
  }

  .xl\:focus\:tw--translate-y-full:focus{
    --transform-translate-y: -100%
  }

  .xl\:focus\:tw--translate-y-1\/2:focus{
    --transform-translate-y: -50%
  }

  .xl\:focus\:tw-translate-y-1\/2:focus{
    --transform-translate-y: 50%
  }

  .xl\:focus\:tw-translate-y-full:focus{
    --transform-translate-y: 100%
  }

  .xl\:tw-skew-x-0{
    --transform-skew-x: 0
  }

  .xl\:tw-skew-x-1{
    --transform-skew-x: 1deg
  }

  .xl\:tw-skew-x-2{
    --transform-skew-x: 2deg
  }

  .xl\:tw-skew-x-3{
    --transform-skew-x: 3deg
  }

  .xl\:tw-skew-x-6{
    --transform-skew-x: 6deg
  }

  .xl\:tw-skew-x-12{
    --transform-skew-x: 12deg
  }

  .xl\:tw--skew-x-12{
    --transform-skew-x: -12deg
  }

  .xl\:tw--skew-x-6{
    --transform-skew-x: -6deg
  }

  .xl\:tw--skew-x-3{
    --transform-skew-x: -3deg
  }

  .xl\:tw--skew-x-2{
    --transform-skew-x: -2deg
  }

  .xl\:tw--skew-x-1{
    --transform-skew-x: -1deg
  }

  .xl\:tw-skew-y-0{
    --transform-skew-y: 0
  }

  .xl\:tw-skew-y-1{
    --transform-skew-y: 1deg
  }

  .xl\:tw-skew-y-2{
    --transform-skew-y: 2deg
  }

  .xl\:tw-skew-y-3{
    --transform-skew-y: 3deg
  }

  .xl\:tw-skew-y-6{
    --transform-skew-y: 6deg
  }

  .xl\:tw-skew-y-12{
    --transform-skew-y: 12deg
  }

  .xl\:tw--skew-y-12{
    --transform-skew-y: -12deg
  }

  .xl\:tw--skew-y-6{
    --transform-skew-y: -6deg
  }

  .xl\:tw--skew-y-3{
    --transform-skew-y: -3deg
  }

  .xl\:tw--skew-y-2{
    --transform-skew-y: -2deg
  }

  .xl\:tw--skew-y-1{
    --transform-skew-y: -1deg
  }

  .xl\:hover\:tw-skew-x-0:hover{
    --transform-skew-x: 0
  }

  .xl\:hover\:tw-skew-x-1:hover{
    --transform-skew-x: 1deg
  }

  .xl\:hover\:tw-skew-x-2:hover{
    --transform-skew-x: 2deg
  }

  .xl\:hover\:tw-skew-x-3:hover{
    --transform-skew-x: 3deg
  }

  .xl\:hover\:tw-skew-x-6:hover{
    --transform-skew-x: 6deg
  }

  .xl\:hover\:tw-skew-x-12:hover{
    --transform-skew-x: 12deg
  }

  .xl\:hover\:tw--skew-x-12:hover{
    --transform-skew-x: -12deg
  }

  .xl\:hover\:tw--skew-x-6:hover{
    --transform-skew-x: -6deg
  }

  .xl\:hover\:tw--skew-x-3:hover{
    --transform-skew-x: -3deg
  }

  .xl\:hover\:tw--skew-x-2:hover{
    --transform-skew-x: -2deg
  }

  .xl\:hover\:tw--skew-x-1:hover{
    --transform-skew-x: -1deg
  }

  .xl\:hover\:tw-skew-y-0:hover{
    --transform-skew-y: 0
  }

  .xl\:hover\:tw-skew-y-1:hover{
    --transform-skew-y: 1deg
  }

  .xl\:hover\:tw-skew-y-2:hover{
    --transform-skew-y: 2deg
  }

  .xl\:hover\:tw-skew-y-3:hover{
    --transform-skew-y: 3deg
  }

  .xl\:hover\:tw-skew-y-6:hover{
    --transform-skew-y: 6deg
  }

  .xl\:hover\:tw-skew-y-12:hover{
    --transform-skew-y: 12deg
  }

  .xl\:hover\:tw--skew-y-12:hover{
    --transform-skew-y: -12deg
  }

  .xl\:hover\:tw--skew-y-6:hover{
    --transform-skew-y: -6deg
  }

  .xl\:hover\:tw--skew-y-3:hover{
    --transform-skew-y: -3deg
  }

  .xl\:hover\:tw--skew-y-2:hover{
    --transform-skew-y: -2deg
  }

  .xl\:hover\:tw--skew-y-1:hover{
    --transform-skew-y: -1deg
  }

  .xl\:focus\:tw-skew-x-0:focus{
    --transform-skew-x: 0
  }

  .xl\:focus\:tw-skew-x-1:focus{
    --transform-skew-x: 1deg
  }

  .xl\:focus\:tw-skew-x-2:focus{
    --transform-skew-x: 2deg
  }

  .xl\:focus\:tw-skew-x-3:focus{
    --transform-skew-x: 3deg
  }

  .xl\:focus\:tw-skew-x-6:focus{
    --transform-skew-x: 6deg
  }

  .xl\:focus\:tw-skew-x-12:focus{
    --transform-skew-x: 12deg
  }

  .xl\:focus\:tw--skew-x-12:focus{
    --transform-skew-x: -12deg
  }

  .xl\:focus\:tw--skew-x-6:focus{
    --transform-skew-x: -6deg
  }

  .xl\:focus\:tw--skew-x-3:focus{
    --transform-skew-x: -3deg
  }

  .xl\:focus\:tw--skew-x-2:focus{
    --transform-skew-x: -2deg
  }

  .xl\:focus\:tw--skew-x-1:focus{
    --transform-skew-x: -1deg
  }

  .xl\:focus\:tw-skew-y-0:focus{
    --transform-skew-y: 0
  }

  .xl\:focus\:tw-skew-y-1:focus{
    --transform-skew-y: 1deg
  }

  .xl\:focus\:tw-skew-y-2:focus{
    --transform-skew-y: 2deg
  }

  .xl\:focus\:tw-skew-y-3:focus{
    --transform-skew-y: 3deg
  }

  .xl\:focus\:tw-skew-y-6:focus{
    --transform-skew-y: 6deg
  }

  .xl\:focus\:tw-skew-y-12:focus{
    --transform-skew-y: 12deg
  }

  .xl\:focus\:tw--skew-y-12:focus{
    --transform-skew-y: -12deg
  }

  .xl\:focus\:tw--skew-y-6:focus{
    --transform-skew-y: -6deg
  }

  .xl\:focus\:tw--skew-y-3:focus{
    --transform-skew-y: -3deg
  }

  .xl\:focus\:tw--skew-y-2:focus{
    --transform-skew-y: -2deg
  }

  .xl\:focus\:tw--skew-y-1:focus{
    --transform-skew-y: -1deg
  }

  .xl\:tw-transition-none{
    -webkit-transition-property: none;
    transition-property: none
  }

  .xl\:tw-transition-all{
    -webkit-transition-property: all;
    transition-property: all
  }

  .xl\:tw-transition{
    -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
  }

  .xl\:tw-transition-colors{
    -webkit-transition-property: background-color, border-color, color, fill, stroke;
    transition-property: background-color, border-color, color, fill, stroke
  }

  .xl\:tw-transition-opacity{
    -webkit-transition-property: opacity;
    transition-property: opacity
  }

  .xl\:tw-transition-shadow{
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow
  }

  .xl\:tw-transition-transform{
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
  }

  .xl\:tw-ease-linear{
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear
  }

  .xl\:tw-ease-in{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .xl\:tw-ease-out{
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .xl\:tw-ease-in-out{
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .xl\:tw-duration-75{
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms
  }

  .xl\:tw-duration-100{
    -webkit-transition-duration: 100ms;
            transition-duration: 100ms
  }

  .xl\:tw-duration-150{
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms
  }

  .xl\:tw-duration-200{
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms
  }

  .xl\:tw-duration-300{
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms
  }

  .xl\:tw-duration-500{
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms
  }

  .xl\:tw-duration-700{
    -webkit-transition-duration: 700ms;
            transition-duration: 700ms
  }

  .xl\:tw-duration-1000{
    -webkit-transition-duration: 1000ms;
            transition-duration: 1000ms
  }

  .xl\:tw-delay-75{
    -webkit-transition-delay: 75ms;
            transition-delay: 75ms
  }

  .xl\:tw-delay-100{
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms
  }

  .xl\:tw-delay-150{
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms
  }

  .xl\:tw-delay-200{
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms
  }

  .xl\:tw-delay-300{
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms
  }

  .xl\:tw-delay-500{
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms
  }

  .xl\:tw-delay-700{
    -webkit-transition-delay: 700ms;
            transition-delay: 700ms
  }

  .xl\:tw-delay-1000{
    -webkit-transition-delay: 1000ms;
            transition-delay: 1000ms
  }

  .xl\:tw-animate-none{
    -webkit-animation: none;
            animation: none
  }

  .xl\:tw-animate-spin{
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .xl\:tw-animate-ping{
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .xl\:tw-animate-pulse{
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .xl\:tw-animate-bounce{
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}